export const mockExposure = {
  nonDeliveryExposure: 9999999,
  refundExposure: 2999,
  chargebackExposure: 3999,
  grossExposure: 6999,
  netExposure: null
};

export const mockExposureGetRes = (body, params, options) => {
  const { guid = ['empty'] } = options;
  return {
    [guid]: mockExposure
  };
};

export default mockExposure;
