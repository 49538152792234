import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import {
  getCentralTime,
  getLocaleString,
  getUserTimeZone,
  isEmpty,
  isEqual,
  isPastTimestamp,
  pad
} from './_helpers';
import { input } from './_styles';
import InputTimestamp from './InputTimestamp';
import { ToolTip } from './ToolTip';
import { Button } from './Button';
import { ErrorBox } from '../css/_styledFormComponents';
import { ComboBox } from './ComboBox';

class SelectDueDate extends React.Component {
  constructor (props) {
    super(props);
    this.mounted = false;
    const { allowedTimeList } = props;
    this.localIsCentralTime = ['CST', 'CDT'].includes(getUserTimeZone());
    this.weeksList = Array.from({ length: 52 }).map((item, index) => ({ title: `${index + 1}`, value: index + 1 }));
    this.daysList = Array.from({ length: 5 }).map((item, index) => ({ title: `${index + 1}`, value: index + 1 }));
    this.hoursList = Array.from({ length: 8 }).map((item, index) => ({ title: `${index + 1}`, value: index + 1 }));
    this.minutesList = [{ title: '30', value: 30 }];
    this.timeList = !isEmpty(allowedTimeList) ? allowedTimeList.reduce((acc, item) => {
      const hourString = pad(item.value);
      const options = [
        { value: `${hourString}:00` },
        ...(item.value !== 17 ? [{ value: `${hourString}:30` }] : [])
      ];
      return acc.concat(options);
    }, []) : [];
    this.clearedFormState = {
      dateSelectorTextCT: '',
      showTimeZoneText: false, // If user's current timezone is NOT central
      existingValueIsOverdue: false,
      useExistingValue: false,
      componentErrorArray: [],
      hasInvalidComboBoxFields: false, // If any combobox fields are invalid
      inputTimestampInProgress: false, // Updating InputTimestamp date/time
      useLocalInputTime: false,
      currentInputTimestamp: '',
      currentWeeks: null,
      currentDays: null,
      currentHours: null,
      currentMinutes: null,
      currentInputTimestampIsValid: false,
      currentWeeksIsValid: false,
      currentDaysIsValid: false,
      currentHoursIsValid: false,
      currentMinutesIsValid: false
    };
    this.state = {
      ...this.clearedFormState,
      showComponentError: false
    };
  }

  componentDidMount () {
    this.mounted = true;
    this.prefillTimestamp();
  }

  componentDidUpdate (prevProps) {
    const { value } = this.props;
    if (!isEqual(value, prevProps.value)) {
      this.prefillTimestamp();
    }
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  updateState = (state, callback = null) => {
    this.mounted && this.setState(state, callback);
  }

  prefillTimestamp = () => {
    const { required, value } = this.props;
    /**
     * The `value` prop MAY be overdue (since calcs are based on the next valid work day)
     * but the field should still be shown as "valid"
     * with the "overdue" text (if applicable).
     * If the field is updated, the previous existing `value` will no longer be considered valid.
     */
    const existingValueIsOverdue = this.isExistingValueOverdue();
    this.updateState({
      existingValueIsOverdue,
      useExistingValue: true,
      currentInputTimestamp: !isEmpty(value) ? value : null,
      currentInputTimestampIsValid: required ? !isEmpty(value) : true
    }, () => this.setComboBoxValues(existingValueIsOverdue, { useExistingValue: true }));
  }

  isExistingValueOverdue = () => {
    const { value } = this.props;
    const valueIso = !isEmpty(value) ? DateTime.fromISO(value).setZone('America/Chicago').toMillis() : 0;
    const nowIso = !isEmpty(value) ? DateTime.now().setZone('America/Chicago').toMillis() : 0;
    return !isEmpty(value)
      // Is value (ms) less than current time (in CT) ms
      ? valueIso < nowIso
      : false;
  }

  handleChangeComboBox = (options) => {
    const { id, value, valid } = options || {};
    const type = id.split('-time-selector-').pop();
    const typeMap = {
      weeks: 'currentWeeks',
      days: 'currentDays',
      hours: 'currentHours',
      minutes: 'currentMinutes'
    };
    const key = typeMap[type];
    const isValid = !isEmpty(value) ? valid : typeof value === 'undefined'; // cleared value
    this.updateState({
      dateSelectorTextCT: '',
      existingValueIsOverdue: false,
      useExistingValue: false,
      [key]: !isEmpty(value) && valid ? value : null,
      [`${key}IsValid`]: isValid,
      useLocalInputTime: true
    }, this.setUpdatedTimestamp);
  }

  setUpdatedTimestamp = () => {
    const { required } = this.props;
    const { currentMinutes } = this.state;
    const { date: localDateStringNow } = getCentralTime();
    const hoursToAdd = this.getTotalHoursToAdd();
    const hasHoursToAdd = (!isEmpty(hoursToAdd) && hoursToAdd > 0);
    const hasMinutesToAdd = (!isEmpty(currentMinutes) && currentMinutes > 0);
    let newIsoString = '';
    if (hasHoursToAdd || hasMinutesToAdd) {
      const newDate = new Date(localDateStringNow);
      const newDateWithTimeAdded = this.addRemainingTime(
        newDate,
        hoursToAdd,
        currentMinutes
      );
      newIsoString = this.getIsoString(newDateWithTimeAdded);
    }
    this.updateState({
      currentInputTimestamp: 'cleared'
    }, () => {
      this.updateState({
        currentInputTimestamp: newIsoString,
        currentInputTimestampIsValid: required ? !isEmpty(newIsoString) : true
      }, required && isEmpty(newIsoString)
        ? this.prefillTimestamp
        : this.handleValidate);
    });
  }

  getTotalHoursToAdd = () => {
    const { currentWeeks, currentDays, currentHours } = this.state;
    let newHours = currentHours > 0 ? currentHours : 0;
    const hoursPerDay = 8;
    if (currentWeeks > 0) {
      const daysPerWeek = 5;
      newHours += (currentWeeks * daysPerWeek * hoursPerDay);
    }
    if (currentDays > 0) {
      newHours += currentDays * hoursPerDay;
    }
    return newHours;
  }

  addRemainingTime = (startingLocalDate, hours, minutes) => {
    // Adds weeks/days/hours/minutes to current local time (now)
    let currentLocalDate = new Date(startingLocalDate);
    const currentIsValid = this.isTimestampValid(startingLocalDate);
    if (!currentIsValid) {
      currentLocalDate = this.getNextWorkDay(startingLocalDate);
    } else {
      currentLocalDate = this.roundTime(currentLocalDate);
    }
    if (!isEmpty(hours) && hours > 0) {
      currentLocalDate = this.handleAddHoursToDate(currentLocalDate, hours);
    }
    if (!isEmpty(minutes) && minutes > 0) {
      let tempDateWithMinutes = new Date(currentLocalDate);
      tempDateWithMinutes.setMinutes(
        tempDateWithMinutes.getMinutes() + (minutes || 0),
        0,
        0
      );
      const tempIsValid = this.isTimestampValid(tempDateWithMinutes);
      if (!tempIsValid) {
        tempDateWithMinutes = this.getNextWorkDay(currentLocalDate);
        tempDateWithMinutes.setMinutes(
          tempDateWithMinutes.getMinutes() + (minutes || 0),
          0,
          0
        );
      }
      const rounded = this.roundTime(tempDateWithMinutes);
      currentLocalDate = rounded;
    }
    return new Date(currentLocalDate);
  }

  handleAddHoursToDate = (startingLocalDate, hours) => {
    let remainingHours = hours || 0;
    let currentLocalDate = new Date(startingLocalDate);
    let isTempValid = true;
    while (!isEmpty(remainingHours) && remainingHours > 0) {
      // end of current work day
      const workDayEndDate = this.getWorkEndTime(currentLocalDate);
      // check if work end is same as current time
      const isDayOver = workDayEndDate.getTime() === currentLocalDate.getTime();
      // do we have at least 8 hours in remainingHours for the next day
      const nextDayHours = remainingHours >= 8 ? 8 : remainingHours;
      // number of hours left in current work day -OR- number of hours to take from next day
      const hoursDiff = (isDayOver)
        ? nextDayHours
        : this.getHoursDiff(workDayEndDate, currentLocalDate);
      const useRemaining = hoursDiff > remainingHours;
      const hoursToAdd = useRemaining ? remainingHours : hoursDiff;
      if (hoursToAdd > 0) {
        // add hours to current local time
        const tempDate = isDayOver
          ? this.getNextWorkDay(new Date(currentLocalDate))
          : new Date(currentLocalDate);
        const isHalfHour = tempDate.getMinutes() === 30;
        const tempDateWithTimeAdded = this.addHours(tempDate, hoursToAdd, isHalfHour ? 30 : 0);
        isTempValid = this.isTimestampValid(tempDateWithTimeAdded);
        currentLocalDate = new Date(tempDateWithTimeAdded);
        remainingHours -= hoursToAdd;
      } else {
        remainingHours = 0;
      }
      if (remainingHours > 0 || (remainingHours === 0 && !isTempValid)) {
        // If temp date with the added hours makes time invalid (eg, 5:30)
        // set to next workday with residual time added
        currentLocalDate = this.getNextWorkDay(currentLocalDate, { includeHalfHour: !isTempValid });
      }
    }
    return new Date(currentLocalDate);
  }

  getWorkEndTime = (date) => { // Sets current date to 5PM
    const workDayEndDate = new Date(date);
    workDayEndDate.setHours(17, 0, 0, 0);
    return new Date(workDayEndDate);
  }

  getHoursDiff = (workDayEndDate, currentLocalDate) => {
    // Difference in hours between current day (or start of next work day) and 5 PM
    const workEndTime = this.getTimeValue(workDayEndDate);
    const currentTime = this.getTimeValue(currentLocalDate);
    const hoursDiff = Math.round(
      Math.abs(workEndTime - currentTime) / 3600000
    );
    return hoursDiff;
  }

  getIsoString = (localDate) => {
    const newIsoString = new Date(
      new Date(localDate)
        .toLocaleString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          timeZone: 'America/Chicago',
          timeZoneName: 'short',
          hour: '2-digit',
          minute: '2-digit'
        })
    ).toISOString();
    return newIsoString;
  }

  handleChangeInputTimestamp = (id, value, valid, options) => {
    const { time, date } = options || {};
    const { required } = this.props;
    const inProgress = !valid && (!isEmpty(time) || !isEmpty(date));
    if (valid) {
      const newIsoString = this.getIsoString(value);
      const startingValidDay = this.getNextValidStartTime(options);
      const startingValidDayIsoString = startingValidDay.toISOString();
      const sameIsoString = (!isEmpty(newIsoString) &&
        isEqual(startingValidDayIsoString, newIsoString));
      const currentInputTimestampIsValid = !sameIsoString || (isEmpty(newIsoString) && !required);
      this.updateState({
        existingValueIsOverdue: false,
        useExistingValue: false,
        inputTimestampInProgress: false,
        useLocalInputTime: false,
        currentInputTimestamp: newIsoString,
        currentInputTimestampIsValid
      }, currentInputTimestampIsValid
        ? this.setComboBoxValues
        : () => this.handleValidate({ date, time, sameIsoString }));
    } else {
      this.updateState({
        existingValueIsOverdue: false,
        useExistingValue: false,
        useLocalInputTime: false,
        inputTimestampInProgress: inProgress,
        ...(!inProgress && { currentInputTimestamp: '' }),
        currentInputTimestampIsValid: (isEmpty(date) && isEmpty(time) && !required) || false,
        currentWeeks: null,
        currentDays: null,
        currentHours: null,
        currentMinutes: null,
        currentWeeksIsValid: true,
        currentDaysIsValid: true,
        currentHoursIsValid: true,
        currentMinutesIsValid: true
      }, () => this.handleValidate(options));
    }
  }

  getNextValidStartTime = (options) => {
    const { time, date, value } = options || {};
    const sameDay = !isEmpty(date) && !isEmpty(time) && this.isSameDate(value);
    const isWithinWorkHours = !this.isOutsideWorkHours();
    const startingValidDay = sameDay || (!sameDay && isWithinWorkHours)
      /**
       * Round current time to the next valid work hour IF:
       * input date === today's date, OR
       * input date !== today's date AND current time within work hours
       */
      ? this.roundTime(new Date())
      : this.getNextWorkDay();
    return startingValidDay;
  }

  isSameDate = (date1, date2) => { // Checks if 2 dates are the same day (does not check time)
    const { date: dateString1 } = getCentralTime(date1);
    const { date: dateString2 } = getCentralTime(date2); // uses today if not provided
    const [date1String] = !isEmpty(date1) ? (dateString1 || '').split(', ') : [];
    const [date2String] = (dateString2 || '').split(', ');
    return !isEmpty(date1String) && !isEmpty(date2String) && isEqual(date1String, date2String);
  }

  setComboBoxValues = (isOverdue, options) => {
    // If user selects a date/time in the input date/time selector, convert that timestamp
    // into weeks/days/hours/minutes to show in the comboboxes
    const { currentInputTimestamp } = this.state;
    if (!isOverdue && !isEmpty(currentInputTimestamp)) {
      const { date: inputDateString } = getCentralTime(currentInputTimestamp);

      // Is the input date selected the same as today
      const selectedInputDate = new Date(inputDateString);
      const isInputDateEqualToday = this.isSameDate(selectedInputDate);

      // Get the time difference between the user's input date selected, and the start of its day -
      // either 9 AM if NOT today, or next valid work hour if it IS today
      const {
        hours: inputHoursToAdd,
        minutes: inputMinutesToAdd
      } = this.getTimeDiff(selectedInputDate, { sameDay: isInputDateEqualToday, isInitial: true });

      let currentDateToTest = new Date(inputDateString);
      let sameDay = isInputDateEqualToday;
      let totalHours = 0;
      let totalMinutes = 0;
      while (!sameDay) {
        // Subtract current day by 1 day to get the previous day
        const prevTime = currentDateToTest.setDate(currentDateToTest.getDate() - 1);
        const prevDate = new Date(prevTime);
        const isHalfHour = prevDate.getMinutes() === 30;
        prevDate.setHours(
          9, // Set hour to 9 AM
          // If current time is at 30-min, when adding
          // hours to the time, update minutes to be 30
          // EG, If now is 11:30 & adding 1 hour, should show as 12:30
          isHalfHour ? 30 : 0,
          0,
          0
        );
        const newPrevDate = new Date(prevDate);
        const isWeekend = this.isWeekend(newPrevDate);
        const prevDateEqualsToday = this.isSameDate(newPrevDate);
        if (!isWeekend) {
          if (prevDateEqualsToday) {
            // Don't include today's hours if now is past work hours
            const endWorkDay = this.getWorkEndTime(newPrevDate);
            const isPastWorkHours = Date.now() > this.getTimeValue(endWorkDay);
            // If the previous day now matches today, get today's hours/minutes to add
            const {
              hours: todayHoursToAdd,
              minutes: todayMinutesToAdd
            } = !isPastWorkHours
              ? this.getTimeDiff(newPrevDate, {
                sameDay: prevDateEqualsToday
              })
              : {};
            totalHours += todayHoursToAdd || 0;
            totalMinutes += todayMinutesToAdd || 0;
            sameDay = true;
          } else {
            totalHours += 8; // Add one full work day (1 work day = 8 hours)
          }
        } else {
          sameDay = this.isSameDate(newPrevDate);
        }
        currentDateToTest = newPrevDate;
      }
      totalHours += inputHoursToAdd || 0;
      totalMinutes += inputMinutesToAdd || 0;

      // Converts total hours/minutes to weeks/days/hours to show in comboboxes
      const convertedValues = this.convertValues(totalHours, totalMinutes);
      const {
        minutesValue,
        hoursValue,
        daysValue,
        weeksValue
      } = convertedValues || {};

      this.updateState({
        currentWeeks: weeksValue > 0 ? weeksValue : null,
        currentDays: daysValue > 0 ? daysValue : null,
        currentHours: hoursValue > 0 ? hoursValue : null,
        currentMinutes: minutesValue > 0 ? minutesValue : null,
        currentWeeksIsValid: true,
        currentDaysIsValid: true,
        currentHoursIsValid: true,
        currentMinutesIsValid: true
      }, () => this.handleValidate(options));
    } else {
      this.updateState({
        currentWeeks: null,
        currentDays: null,
        currentHours: null,
        currentMinutes: null,
        currentWeeksIsValid: true,
        currentDaysIsValid: true,
        currentHoursIsValid: true,
        currentMinutesIsValid: true
      }, () => this.handleValidate(options));
    }
  }

  convertValues = (startHours, startMinutes) => {
    // Converts total hours/minutes to weeks/days/hours to show in comboboxes
    let remainingHours = startHours || 0;
    let remainingMinutes = startMinutes || 0;
    let remainingDays = 0;

    let minutesValue = 0;
    let hoursValue = 0;
    let daysValue = 0;
    let weeksValue = 0;

    while (remainingMinutes >= 60) {
      remainingHours += 1;
      remainingMinutes -= 60;
    }
    if (remainingMinutes < 60) {
      minutesValue += remainingMinutes;
      remainingMinutes = 0;
    }

    while (remainingHours >= 8) { // 8 hours in 1 work day
      remainingDays += 1;
      remainingHours -= 8;
    }
    if (remainingHours < 8 && remainingHours > 0) {
      hoursValue += remainingHours;
      remainingHours = 0;
    }

    while (remainingDays >= 5) { // 5 days in 1 work week
      weeksValue += 1;
      remainingDays -= 5;
    }
    if (remainingDays < 5) {
      daysValue += remainingDays;
      remainingDays = 0;
    }

    const newComboBoxValues = {
      minutesValue,
      hoursValue,
      daysValue,
      weeksValue
    };
    return newComboBoxValues;
  }

  getTimeValue = (dateInstance, type) => {
    const timeValue = !isEmpty(dateInstance) ? dateInstance.getTime() : 0;
    return timeValue;
  }

  getTimeDiff = (localDate, options) => {
    const { sameDay, isInitial } = options || {};
    // Time difference between the passed in date & the start of its day, or
    // the next valid work hour if same day)
    const inputDate = new Date(localDate);
    let startDate = new Date(localDate);

    const nowIsWithinWorkHours = !this.isOutsideWorkHours();
    const currentDate = new Date();
    const currentTimeRounded = this.roundTime(currentDate);

    if (sameDay) { // The input date selected is the same as today
      const beforeWorkHours = this.isBeforeWorkHours();
      const currentTimeAfterOriginalInput = this.isNowAfterOriginalInput();

      if (beforeWorkHours) {
        // if before work hours -> set to 9 am
        startDate.setHours(9, 0, 0, 0);
        /**
         * Include today as 1 work day (8 hours) if !isInitial (input date selected !== equal today)
         * and it's before work hours
         */
        !isInitial && startDate.setHours(startDate.getHours() + 8, 0, 0, 0);
      } else if (nowIsWithinWorkHours && ( // same day, and within work hours
        currentTimeAfterOriginalInput
      )) {
        // Set input date to the end of the work day
        inputDate.setHours(17, 0, 0, 0);
      } else {
        startDate = currentTimeRounded;
      }
    } else { // Not same day, set the start date of 9 AM of the current input date selected
      startDate.setHours(9, 0, 0, 0);
    }

    const newStartDate = new Date(startDate);
    let newInputDate = new Date(inputDate);

    const roundTimeSameAsStart = this.getTimeValue(currentTimeRounded) ===
      this.getTimeValue(newStartDate);

    if (!isInitial && sameDay && nowIsWithinWorkHours && roundTimeSameAsStart) {
      // Set input date to the end of the work day
      const tempDate = new Date(newInputDate);
      newInputDate = new Date(tempDate.setHours(17, 0, 0, 0));
    }

    // Get difference between of start of input date to the end of input date
    // Divide by 60000 to get minutes (1 minute = 60s = 60000ms)
    const inputTimeValue = this.getTimeValue(newInputDate, 'dateSelected');
    const startTimeValue = this.getTimeValue(newStartDate, 'dateStarted');
    const startingMinutesDiff = Math.abs(inputTimeValue - startTimeValue) / 60000;

    const { totalHours, totalMinutes, minutesDiff } = this.getMinutesDiff(startingMinutesDiff);
    return {
      hours: totalHours,
      minutes: totalMinutes,
      minutesDiff
    };
  }

  isNowAfterOriginalInput = () => { // Is now AFTER the selected timestamp?
    const { currentInputTimestamp } = this.state;
    return Date.now() > this.getTimeValue(
      new Date(currentInputTimestamp)
    );
  }

  getMinutesDiff = (startingMinutesDiff) => {
    let minutesDiff = startingMinutesDiff || 0;
    let totalMinutes = 0;
    let totalHours = 0;
    if (minutesDiff >= 60) { // More than 1 hour
      while (minutesDiff >= 60) { // Convert minutes to hours
        const remainingMinutes = minutesDiff % 60;
        if (remainingMinutes === 30) {
          totalMinutes += remainingMinutes;
          minutesDiff -= remainingMinutes;
        } else if (remainingMinutes === 0) { // Adding whole hours
          const incHours = minutesDiff / 60;
          totalHours += incHours;
          minutesDiff = 0;
        } else if (`${remainingMinutes}`.includes('.')) { // Partial hours/minutes (eg , 2.5 hours)
          const [h, m] = (`${remainingMinutes}`).split('.').map(v => parseInt(v, 10));
          totalHours += !isEmpty(h) ? h : 0;
          totalMinutes += !isEmpty(m) ? m : m;
          minutesDiff = 0;
        } else {
          // Else unexpected minutes, not divisible by 60.
          // This should not happen, but if passed in via `value` prop, will populate
          // unexpected combobox values BUT does not block user
          const hours = !isEmpty(minutesDiff) ? minutesDiff / 60 : 0;
          const rHours = Math.floor(hours);
          const minutes = (hours - rHours) * 60;
          const rMinutes = Math.round(minutes);
          totalHours += rHours > 0 ? rHours : 0;
          totalMinutes += rMinutes > 0 ? rMinutes : 0;
          minutesDiff = 0;
        }
      }
    } else { // Should only be 30 minutes (since that is the only valid option)
      totalMinutes += minutesDiff;
    }
    return { totalHours, totalMinutes, minutesDiff };
  }

  roundTime = (localDate) => { // Minutes must be rounded to the nearest 30-minute increment
    const { minutes: minuteValueCT, date: startDate } = getCentralTime(localDate);
    const currentLocalDate = new Date(startDate);
    const roundedValue = v => Math.round(v / 30) * 30;
    const roundUpHour = v => (roundedValue(v) === 60);
    const roundDownHour = v => (roundedValue(v) === 0);
    const roundToHalfHour = v => (roundedValue(v) === 30);
    if (roundDownHour(minuteValueCT)) {
      currentLocalDate.setHours(currentLocalDate.getHours(), 0, 0, 0);
    } else if (roundUpHour(minuteValueCT)) {
      currentLocalDate.setHours(currentLocalDate.getHours() + 1, 0, 0, 0);
    } else if (roundToHalfHour(minuteValueCT)) {
      currentLocalDate.setMinutes(30, 0, 0);
    }
    const newLocalDate = new Date(currentLocalDate);
    return newLocalDate;
  }

  isTimestampValid = (localDate) => { // Checks if date is within work hours and is a weekday
    const isOutside = this.isOutsideWorkHours(localDate);
    const isWeekend = this.isWeekend(localDate);
    return !isOutside && !isWeekend;
  }

  isWeekend = dateInstance => (!isEmpty(dateInstance) && [0, 6].includes(dateInstance.getDay()));

  addDay = (currentDate) => { // Adds 1 full day (24 hours) to current date
    const dateValue = currentDate.getDate();
    const daysToAdd = 1;
    const timeValue = currentDate.setDate(dateValue + daysToAdd);
    return new Date(timeValue);
  };

  addHours = (currentDate, hoursToAdd, minutesToAdd) => {
    const additionalHours = hoursToAdd || 0;
    const timeValue = currentDate.setHours(
      currentDate.getHours() + additionalHours,
      minutesToAdd || 0,
      0,
      0
    );
    return new Date(timeValue);
  };

  isOutsideWorkHours = (localDate) => {
    // Checks if current date (converted to central time hours) is outside M-F 9 AM - 5 PM CT
    const after5pmCT = this.isAfterWorkHours(localDate);
    const before9amCT = this.isBeforeWorkHours(localDate);
    return after5pmCT || before9amCT;
  }

  isAfterWorkHours = (localDate) => {
    const { hours: hourValueCT, minutes: minuteValueCT, isPM } = getCentralTime(localDate);
    const after5pmCT = (isPM &&
      // not 12 pm
      hourValueCT !== 12 &&
      // after 5 pm
      hourValueCT >= 5 && minuteValueCT > 0);
    return after5pmCT;
  }

  isBeforeWorkHours = (localDate) => {
    // Checks if current date (converted to central time hours) is before 9 AM CT
    const { hours: hourValueCT, minutes: minuteValueCT, isPM } = getCentralTime(localDate);
    const before9amCT = (!isPM && ( // AM hours
      // before 9 AM
      (hourValueCT < 9 && minuteValueCT > 0) ||
      // past 12 AM
      (hourValueCT === 12)
    ));
    return before9amCT;
  }

  getNextWorkDay = (localDate, options) => {
    /**
      * Gets the next valid work date/time,
      * either during work hours, or the next valid weekday at 9 AM CT
     */
    const { includeHalfHour } = options || {};
    const { date: centralTimeString, hours: hourValueCT, isPM } = getCentralTime(localDate);
    let currentLocalDate = new Date(centralTimeString);
    const hoursToAdd = !isPM && hourValueCT < 9 // AM hours - before 9 AM CT
      ? Math.abs(hourValueCT - 9)
      : 0;
    let nextDay = hoursToAdd
      ? this.addHours(currentLocalDate, hoursToAdd, 0)
      : this.addDay(currentLocalDate);
    while (this.isWeekend(nextDay)) {
      nextDay = this.addDay(nextDay);
    }
    const prettyString = getLocaleString(new Date(nextDay));
    nextDay = new Date(prettyString);
    const setMinutes = includeHalfHour ? (nextDay.getMinutes() === 30 && 30) || 0 : 0;
    nextDay.setHours(9, !isEmpty(setMinutes) ? setMinutes : 0, 0, 0);
    currentLocalDate = new Date(nextDay);
    return currentLocalDate;
  }

  handleClearAll = () => {
    const { required } = this.props;
    if (required) {
      this.updateState({
        inputTimestampInProgress: false,
        currentInputTimestamp: 'cleared'
      }, this.prefillTimestamp);
    } else { // not required, field should be valid
      this.updateState({
        ...this.clearedFormState,
        currentInputTimestamp: 'cleared',
        currentInputTimestampIsValid: true,
        currentWeeks: null,
        currentWeeksIsValid: true,
        currentDays: null,
        currentDaysIsValid: true,
        currentHours: null,
        currentHoursIsValid: true,
        currentMinutes: null,
        currentMinutesIsValid: true
      }, () => {
        this.updateState({
          currentInputTimestamp: null
        }, this.handleValidate);
      });
    }
  }

  handleValidate = (options) => {
    const {
      date,
      sameIsoString,
      time,
      useExistingValue
    } = options || {};
    const { required, value } = this.props;
    const isTimeValid = this.isValidTimeInCT(options);
    if (isTimeValid) {
      this.updateState((prevState) => {
        const {
          inputTimestampInProgress,
          currentInputTimestamp,
          currentInputTimestampIsValid,
          currentWeeksIsValid,
          currentDaysIsValid,
          currentHoursIsValid,
          currentMinutesIsValid
        } = prevState || {};
        const hasInvalidComboBoxFields = !currentWeeksIsValid || !currentDaysIsValid ||
          !currentHoursIsValid || !currentMinutesIsValid;
        const isPast = isPastTimestamp(date, time);
        const showComponentError = required
          ? ((isEmpty(currentInputTimestamp) && !useExistingValue) ||
            (!isEmpty(currentInputTimestamp) && !currentInputTimestampIsValid)) &&
            !isEmpty(value)
          : !currentInputTimestampIsValid && (isPast || sameIsoString);
        return {
          hasInvalidComboBoxFields: hasInvalidComboBoxFields || false,
          showComponentError,
          showTimeZoneText: !showComponentError && !this.localIsCentralTime &&
            !inputTimestampInProgress,
          componentErrorArray: [
            ...(showComponentError ? [`Must be a future work date/time ${sameIsoString ? 'of at least 30 minutes' : 'in 30-minute increments. Examples: 2:30 PM CT, 3:00 PM CT'}`] : [])
          ]
        };
      }, () => this.handleConvertTimestamp(options));
    } else { // Selected is NOT valid
      const nowInCentral = DateTime.now().setZone('America/Chicago').toLocaleString(DateTime.DATETIME_SHORT);
      this.updateState({
        currentInputTimestampIsValid: false,
        showTimeZoneText: false,
        showComponentError: true,
        componentErrorArray: [`Selected timestamp is not valid in Central Time (Current Central Time: ${nowInCentral})`]
      }, () => this.handleConvertTimestamp(options));
    }
  }

  isValidTimeInCT = (options) => {
    // If user's time is NOT in CT, check if the selected IS still within central time hours
    const { useExistingValue } = options || {};
    const { currentInputTimestamp, inputTimestampInProgress } = this.state;
    const checkCentralTime = !this.localIsCentralTime && !isEmpty(currentInputTimestamp) &&
      !inputTimestampInProgress && !useExistingValue;
    if (checkCentralTime) {
      const nowDate = DateTime.now().setZone('America/Chicago');
      const nowTime = nowDate.toMillis();
      const localIso = (new Date(currentInputTimestamp)).toISOString();
      const selectedDate = DateTime.fromISO(localIso).setZone('America/Chicago', { keepLocalTime: true });
      const selectedTime = selectedDate.toMillis();
      return selectedTime > nowTime;
    }
    return true;
  }

  handleConvertTimestamp = (options) => {
    const { useExistingValue } = options || {};
    const { currentInputTimestamp, showTimeZoneText } = this.state;
    const callbackValue = !isEmpty(currentInputTimestamp)
      ? this.getCallbackValue(currentInputTimestamp)
      : null;
    const newValue = useExistingValue ? currentInputTimestamp : callbackValue;
    this.updateState({
      callbackValue: newValue,
      dateSelectorTextCT: showTimeZoneText && !isEmpty(newValue) ? getCentralTime(newValue).date : ''
    }, this.handleCallback);
  }

  handleCallback = () => {
    const { id, callback, required } = this.props;
    const { callbackValue, currentInputTimestampIsValid } = this.state;
    const cbOptions = {
      id,
      value: callbackValue,
      valid: currentInputTimestampIsValid && (!required ||
        (required && !isEmpty(callbackValue) && currentInputTimestampIsValid))
    };
    callback && callback(cbOptions);
  }

  getCallbackValue = (isoString) => {
    const localDateIso = !isEmpty(isoString) ? (new Date(isoString)).toISOString() : null;
    return this.localIsCentralTime
      ? localDateIso
      // Converts local date to CT, but keeps the same time
      // Since the time shown in InputTimestamp is CT hours
      // but the Date methods convert to local time
      : (localDateIso && new Date(
        DateTime
          .fromISO(localDateIso)
          .setZone('America/Chicago', { keepLocalTime: true })
          .toISO()
      ).toISOString()) || null;
  }

  render () {
    const {
      boxStyle,
      businessHoursOnly,
      dateType,
      disabled,
      id,
      label,
      required,
      roundMinutes,
      tooltip,
      showOverdue,
      value,
      weekdaysOnly,
      wrapperStyle
    } = this.props;
    const {
      existingValueIsOverdue,
      useExistingValue,
      hasInvalidComboBoxFields,
      dateSelectorTextCT,
      showTimeZoneText,
      useLocalInputTime,
      inputTimestampInProgress,
      componentErrorArray,
      currentInputTimestamp,
      currentWeeks,
      currentDays,
      currentHours,
      currentMinutes,
      currentInputTimestampIsValid,
      currentWeeksIsValid,
      currentDaysIsValid,
      currentHoursIsValid,
      currentMinutesIsValid,
      showComponentError
    } = this.state;
    const disableTimestamp = disabled || hasInvalidComboBoxFields;
    const inputErrorStyle = {
      color: 'var(--color-warning)',
      backgroundColor: 'var(--color-warning-bg)',
      ...(boxStyle !== 'inside' && { borderColor: 'var(--color-warning)' })
    };
    const timestampProps = {
      boxStyle,
      roundMinutes,
      allowToday: true,
      label: (
        <div style={{ fontSize: '1.1rem', lineHeight: '1.5' }}>
          <em style={{ color: 'var(--color-dark-grey)', margin: '0 0.5em' }}>-OR- Select Timestamp (Central Time)</em>
          {showOverdue && existingValueIsOverdue && <span style={{ color: 'var(--color-warning)', fontSize: '1rem', fontWeight: 'bold' }}>&nbsp;OVERDUE</span>}
        </div>
      ),
      callback: this.handleChangeInputTimestamp,
      dateType,
      disabled: disableTimestamp,
      weekdaysOnly,
      businessHoursOnly,
      useTimeValidationMessage: true,
      dateErrorMessage: 'Must be a future work day',
      id,
      isTimestamp: true,
      useFieldWrapperStyle: true,
      noValidate: true,
      timeList: this.timeList,
      useLocal: useLocalInputTime,
      value: currentInputTimestamp,
      customInputStyle: {
        ...input.errorShake // animate inputs on combobox change
      },
      childStyles: {
        date: { ...(showComponentError && inputErrorStyle) },
        time: { ...(showComponentError && inputErrorStyle) }
      },
      wrapperStyle: {
        flex: '1',
        borderColor: 'transparent',
        ...(!disabled && {
          ...(!inputTimestampInProgress && {
            ...(currentInputTimestampIsValid || (!required && isEmpty(currentInputTimestamp) &&
              !showComponentError)
              ? { backgroundColor: 'var(--color-healthy-bg)' }
              : { ...(!isEmpty(currentInputTimestamp) && inputErrorStyle) }
            )
          }),
          ...(showComponentError && inputErrorStyle)
        })
      }
    };
    const comboBoxDisabled = disabled || inputTimestampInProgress ||
      (!useExistingValue && !currentInputTimestampIsValid);
    const sharedComboBoxProps = {
      callback: this.handleChangeComboBox,
      disabled: comboBoxDisabled,
      useBlockForm: boxStyle === 'inside',
      fieldType: 'combobox',
      type: 'text',
      formField: true,
      wrapperStyle: { flex: '25%', minWidth: '115px' }
    };
    const comboBoxStyles = {
      initial: {
        ...(boxStyle === 'inside' && {
          minHeight: 'auto',
          borderColor: comboBoxDisabled ? 'var(--color-air)' : 'var(--color-hr)'
        })
      },
      error: inputErrorStyle
    };
    return (
      <div
        id={`${id}-select-due-date-wrapper`}
        className="select-due-date"
        style={{
          ...(boxStyle === 'inside'
            ? {
              ...input.innerWrap,
              marginTop: '-1px',
              ...(!disabled && !showComponentError &&
                !inputTimestampInProgress && !hasInvalidComboBoxFields && {
                ...(currentInputTimestampIsValid && { backgroundColor: 'var(--color-healthy-bg)' })
              })
            }
            : input.wrap),
          ...wrapperStyle,
          ...(disabled && boxStyle === 'inside' && input.innerWrapDisabled),
          ...(showComponentError && {
            backgroundColor: 'var(--color-warning-bg)',
            borderColor: 'var(--color-warning)',
            borderWidth: '1px',
            borderStyle: 'solid',
            zIndex: 2
          })
        }}
      >
        {label && (
          <label style={{ ...(boxStyle === 'inside' ? { ...input.labelInside, ...(showComponentError && !disabled && { color: 'var(--color-warning)' }) } : input.label) }} htmlFor={id}>
            {required && <span style={input.label_required}>* </span>}
            {label}
            {!isEmpty(tooltip) && <span style={input.timestampTooltip}><ToolTip infoTip infoTipDisplay={{ top: '-3px', margin: 0 }} isHtml={typeof tooltip !== 'string'}>{tooltip}</ToolTip></span>}
            <Button
              type="text"
              id="clear-all"
              style={{
                height: 'auto',
                float: 'right',
                fontSize: '1rem',
                padding: '0',
                margin: 'auto 0'
              }}
              onClick={this.handleClearAll}
              disabled={disabled}
            >
              {required && !isEmpty(value)
                ? <ToolTip inline wrapperStyle={{ left: '0' }} text="Clear">Clearing will reset to initial timestamp</ToolTip>
                : <>Clear</>
              }
            </Button>
          </label>
        )}
        <div style={{
          display: 'flex',
          width: '100%',
          flexWrap: 'wrap',
          ...(boxStyle === 'inside' && !disabled && { ...(showComponentError && { color: 'var(--color-warning)' }) })
        }}
        >
          <em
            style={{
              fontSize: '1.1rem',
              color: 'var(--color-dark-grey)',
              margin: '0 0.5em',
              lineHeight: '1.5'
            }}
          >
            {/* eslint-disable-next-line max-len */}
            Calculated based on Central Time (CT) work hours (Mon - Fri 9 AM - 5 PM CT) in 30-minute increments
          </em>
          <div style={{ display: 'flex', flex: '100%', flexWrap: 'wrap' }}>
            <ComboBox
              {...sharedComboBoxProps}
              id={`${id}-time-selector-weeks`}
              label="Weeks"
              list={this.weeksList}
              selected={currentWeeks}
              editable
              validationType="number" // uses type="number" validation for each new entry
              boxStyles={{
                ...comboBoxStyles.initial,
                ...(!isEmpty(currentWeeks) && !currentWeeksIsValid && comboBoxStyles.error)
              }}
            />
            <ComboBox
              {...sharedComboBoxProps}
              id={`${id}-time-selector-days`}
              label="Days"
              list={this.daysList}
              selected={currentDays}
              boxStyles={{
                ...comboBoxStyles.initial,
                ...(!isEmpty(currentDays) && !currentDaysIsValid && comboBoxStyles.error)
              }}
            />
            <ComboBox
              {...sharedComboBoxProps}
              id={`${id}-time-selector-hours`}
              label="Hours"
              list={this.hoursList}
              selected={currentHours}
              boxStyles={{
                ...comboBoxStyles.initial,
                ...(!isEmpty(currentHours) && !currentHoursIsValid && comboBoxStyles.error)
              }}
            />
            <ComboBox
              {...sharedComboBoxProps}
              id={`${id}-time-selector-minutes`}
              label="Minutes"
              list={this.minutesList}
              selected={currentMinutes}
              boxStyles={{
                ...comboBoxStyles.initial,
                ...(!isEmpty(currentMinutes) && !currentMinutesIsValid && comboBoxStyles.error)
              }}
            />
          </div>
          {currentInputTimestamp !== 'cleared' && (
            <div style={{
              ...timestampProps.wrapperStyle,
              display: 'flex',
              flex: '100%',
              flexWrap: 'wrap'
            }}
            >
              <InputTimestamp {...timestampProps} />
              <span
                style={{
                  fontSize: '1.2rem',
                  paddingTop: timestampProps.label ? '30px' : '5px',
                  paddingRight: '2px',
                  ...(timestampProps.disabled && boxStyle === 'inside' && input.innerWrapDisabled)
                }}
              >
                CT
              </span>
            </div>
          )}
        </div>
        {showTimeZoneText && !isEmpty(dateSelectorTextCT) && (
          <em
            style={{
              fontSize: '1.1rem',
              color: 'var(--color-dark-grey)',
              margin: '0 0.5em',
              lineHeight: '1.5'
            }}
          >
            You have selected:
            <strong>{` ${dateSelectorTextCT}`}</strong>
          </em>
        )}
        <ErrorBox
          className="errors"
          id={`${id}-error`}
          error={showComponentError}
          $boxStyle={boxStyle}
        >
          {!isEmpty(componentErrorArray) && componentErrorArray.join(',')}
        </ErrorBox>
      </div>
    );
  }
}

SelectDueDate.propTypes = {
  allowedTimeList: PropTypes.oneOfType([PropTypes.array]),
  boxStyle: PropTypes.string,
  businessHoursOnly: PropTypes.bool,
  callback: PropTypes.func,
  childStyles: PropTypes.shape({
    date: PropTypes.oneOfType([PropTypes.object]),
    time: PropTypes.oneOfType([PropTypes.object])
  }),
  dateType: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  roundMinutes: PropTypes.bool,
  showOverdue: PropTypes.bool,
  tooltip: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  value: PropTypes.string,
  weekdaysOnly: PropTypes.bool,
  wrapperStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

SelectDueDate.defaultProps = {
  allowedTimeList: [], // OPTIONAL array of { title, value } where `value` = military (24-hour) time
  boxStyle: null, // pass `inside` if label should be inside
  businessHoursOnly: false,
  callback: null,
  childStyles: {
    date: {},
    time: {}
  },
  dateType: 'futureDate', // or `date` for past dates only
  disabled: false,
  id: null,
  label: null,
  required: false,
  roundMinutes: false, // type="time"/"futureTime" if only 30-min increments allowed (eg, 2:30, 3)
  showOverdue: false,
  tooltip: null,
  value: null, // timestamp format
  weekdaysOnly: false, // OPTIONAL for type="date"/"futureDate"
  wrapperStyle: {}
};

export default SelectDueDate;
