import { riskRuleGuids } from '../../../guids';

export const mockClosingEntity = {
  [riskRuleGuids.default]: {
    header: {
      label: 'day',
      lines: [],
      biaxial: null,
      currency: false,
      biaxialCurrency: false,
      showLegend: true
    },
    data: [
      {
        day: '2021-05-17',
        closed: '2'
      },
      {
        day: '2021-05-18',
        closed: '1'
      },
      {
        day: '2021-05-19',
        closed: '0'
      },
      {
        day: '2021-05-20',
        closed: '1'
      },
      {
        day: '2021-05-21',
        closed: '1'
      },
      {
        day: '2021-05-22',
        closed: '0'
      },
      {
        day: '2021-05-23',
        closed: '1'
      },
      {
        day: '2021-05-24',
        closed: '1'
      },
      {
        day: '2021-05-25',
        closed: '2'
      },
      {
        day: '2021-05-26',
        closed: '1'
      },
      {
        day: '2021-05-27',
        closed: '2'
      },
      {
        day: '2021-05-28',
        closed: '1'
      },
      {
        day: '2021-05-29',
        closed: '1'
      },
      {
        day: '2021-05-30',
        closed: '1'
      }
    ]
  },
  [riskRuleGuids.riskRule01]: {
    header: {
      label: 'day',
      lines: [],
      biaxial: null,
      currency: false,
      biaxialCurrency: false,
      showLegend: true
    },
    data: [
      {
        day: '2021-05-17',
        closed: '0'
      },
      {
        day: '2021-05-18',
        closed: '1'
      },
      {
        day: '2021-05-19',
        closed: '1'
      },
      {
        day: '2021-05-20',
        closed: '0'
      },
      {
        day: '2021-05-21',
        closed: '2'
      },
      {
        day: '2021-05-22',
        closed: '2'
      },
      {
        day: '2021-05-23',
        closed: '0'
      },
      {
        day: '2021-05-24',
        closed: '3'
      },
      {
        day: '2021-05-25',
        closed: '1'
      },
      {
        day: '2021-05-26',
        closed: '2'
      },
      {
        day: '2021-05-27',
        closed: '1'
      },
      {
        day: '2021-05-28',
        closed: '3'
      },
      {
        day: '2021-05-29',
        closed: '1'
      },
      {
        day: '2021-05-30',
        closed: '0'
      }
    ]
  },
  [riskRuleGuids.riskRule02]: {
    header: {
      label: 'day',
      lines: [],
      biaxial: null,
      currency: false,
      biaxialCurrency: false,
      showLegend: true
    },
    data: [
      {
        day: '2021-05-17',
        closed: '0'
      },
      {
        day: '2021-05-18',
        closed: '1'
      },
      {
        day: '2021-05-19',
        closed: '1'
      },
      {
        day: '2021-05-20',
        closed: '0'
      },
      {
        day: '2021-05-21',
        closed: '2'
      },
      {
        day: '2021-05-22',
        closed: '2'
      },
      {
        day: '2021-05-23',
        closed: '0'
      },
      {
        day: '2021-05-24',
        closed: '3'
      },
      {
        day: '2021-05-25',
        closed: '1'
      },
      {
        day: '2021-05-26',
        closed: '2'
      },
      {
        day: '2021-05-27',
        closed: '1'
      },
      {
        day: '2021-05-28',
        closed: '3'
      },
      {
        day: '2021-05-29',
        closed: '1'
      },
      {
        day: '2021-05-30',
        closed: '0'
      }
    ]
  },
  [riskRuleGuids.riskRule03]: {
    header: {
      label: 'day',
      lines: [],
      biaxial: null,
      currency: false,
      biaxialCurrency: false,
      showLegend: true
    },
    data: [
      {
        day: '2021-05-17',
        closed: '0'
      },
      {
        day: '2021-05-18',
        closed: '1'
      },
      {
        day: '2021-05-19',
        closed: '1'
      },
      {
        day: '2021-05-20',
        closed: '0'
      },
      {
        day: '2021-05-21',
        closed: '2'
      },
      {
        day: '2021-05-22',
        closed: '2'
      },
      {
        day: '2021-05-23',
        closed: '0'
      },
      {
        day: '2021-05-24',
        closed: '3'
      },
      {
        day: '2021-05-25',
        closed: '1'
      },
      {
        day: '2021-05-26',
        closed: '2'
      },
      {
        day: '2021-05-27',
        closed: '1'
      },
      {
        day: '2021-05-28',
        closed: '3'
      },
      {
        day: '2021-05-29',
        closed: '1'
      },
      {
        day: '2021-05-30',
        closed: '0'
      }
    ]
  }
};

export default mockClosingEntity;
