import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { DataBoxList } from '../css/_styledComponents';
import {
  isEmpty,
  isBool,
  columnHideList,
  dataTitles,
  getType,
  getGridColCount
} from './_helpers';
import { ToolTip } from './ToolTip';

export default function DataList ({
  data = null,
  hiddenColumns = [],
  hideEmptyValues = false,
  sort = false,
  ...rest
}) {
  const [dataArray, setDataArray] = useState(null);
  const [rowLength, setRowLength] = useState(0);
  const stack = useRef();

  useEffect(() => {
    window.addEventListener('resize', updateRowLength);
    setData();
    return () => {
      window.removeEventListener('resize', updateRowLength);
    };
  }, []);

  useEffect(() => {
    setData();
  }, [data]);

  useEffect(() => {
    updateRowLength();
  }, [dataArray]);

  const updateRowLength = () => {
    const parent = stack.current;
    if (parent) {
      const length = getGridColCount(parent);
      setRowLength(length);
    }
  };

  const setData = () => {
    const dataType = getType(data);
    const dArray = dataType === 'array'
      ? [...data]
      : (dataType === 'object' && Object.entries(data)) ?? [];
    const filteredArray = !isEmpty(dArray)
      ? dArray.filter(([key, value]) => {
        const notHiddenColumn = ![
          ...columnHideList,
          ...(!isEmpty(hiddenColumns) ? hiddenColumns : [])
        ].includes(key);
        const notEmpty = hideEmptyValues ? !isEmpty(value) || isBool(value) : true;
        return notHiddenColumn && notEmpty;
      })
      : [];
    const sortedArray = sort ? filteredArray.sort() : filteredArray;
    setDataArray(!isEmpty(sortedArray) ? sortedArray : null);
  };

  return (
    <DataBoxList {...rest} ref={stack} className={`x${rowLength}`}>
      { dataArray && dataArray.map(([key, value, meta], i) => (
        <dl key={`${key}_${i.toString()}`} {...!isEmpty(meta?.rowStyle) ? { style: meta.rowStyle } : ''}>
          <dt {...!isEmpty(meta?.keyStyle) ? { style: meta.keyStyle } : ''}>
            {dataTitles(key)}
            {meta?.tooltip ? <ToolTip infoTip inline>{meta?.tooltip}</ToolTip> : null}
          </dt>
          <dd {...!isEmpty(meta?.valueStyle) ? { style: meta.valueStyle } : ''}>
            {value}
          </dd>
        </dl>
      ))}
    </DataBoxList>
  );
}

DataList.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  fullWidth: PropTypes.bool, // used in DataBoxList
  useHoverStyle: PropTypes.bool, // used in DataBoxList
  hiddenColumns: PropTypes.oneOfType([PropTypes.array]),
  hideEmptyValues: PropTypes.bool,
  sort: PropTypes.bool
};
