import { sortData } from '@f1/shared/src/_helpers';
import { mockPartners } from './mockPartners';

export const mockPartnerGet = [ // api response
  mockPartners.partner01.response,
  mockPartners.partner02.response,
  mockPartners.partner03.response,
  mockPartners.partner04.response,
  mockPartners.partner05.response,
  mockPartners.partner06.response,
  mockPartners.partner07.response
];

export const mockPartnerGetFormatted = sortData([ // deserialized
  mockPartners.partner01.deserialized,
  mockPartners.partner02.deserialized,
  mockPartners.partner03.deserialized,
  mockPartners.partner04.deserialized,
  mockPartners.partner05.deserialized,
  mockPartners.partner06.deserialized,
  mockPartners.partner07.deserialized
], 'title');
