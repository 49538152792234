import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { getMockEmployee } from '../../_mockHelpers';

const getMockAssessment = () => {
  const mockEmployee = getMockEmployee();
  return {
    type: getMockValue({ type: 'list', list: ['GRIP', 'BRAM', 'Chargebacks'] }),
    value: getMockValue({ type: 'number' }),
    assessmentDate: getMockValue({ type: 'date' }),
    noticeDate: getMockValue({ type: 'date' }),
    employeeWhoReported: {
      employeeId: mockEmployee.employeeId,
      employeeFirstName: mockEmployee.firstName,
      employeeLastName: mockEmployee.lastName,
      employeeEmail: mockEmployee.email
    }
  };
};

export const mockV2RiskAssessmentGet = (body, params, options) => {
  const { guid } = options || {};
  const length = getMockValue({ min: 2, max: 4 });
  return {
    [guid]: { riskAssessmentList: Array.from(({ length })).map(getMockAssessment) }
  };
};

export const mockV2RiskAssessmentPut = (body, params, options) => {
  const { guid } = options || {};
  const riskAssessmentId = getMockValue({ type: 'string' });
  return {
    [guid]: { riskAssessmentId }
  };
};
