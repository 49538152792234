import {
  isEmpty
} from '@f1/shared/src/_helpers';

const RelationshipForestTemplate = {
  frontend: (schema, version) => {
    if (version === '1.0') {
      const deserialize = downlines => downlines.reduce((acc, d) => acc.concat(({
        relationshipId: d.relationshipId,
        relationshipName: d.relationshipName,
        relationshipCode: d.relationshipCode,
        bankName: d.bankName || 'Unknown Bank',
        processorName: d.processorName || 'Unknown Processor',
        repaySortCode: d.repaySortCode,
        downlines: !isEmpty(d.downlines) ? deserialize(d.downlines) : [],
        ...(!isEmpty(d?.inactive) ? {
          inactive: d.inactive
        } : {})
      })), []);
      // use the above deserialize function, to recursively convert all downlines
      return { relationshipName: 'F1root', downlines: deserialize(schema) };
    }
    return schema || [];
  }
};

export default RelationshipForestTemplate;
