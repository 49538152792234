import { transformData } from '@f1/shared/src/_helpers';
import { riskRuleGuids, employeeGuids } from '../../guids';
import { mockRiskCategories } from '../landingPage/alerts/riskCategory/mockRiskCategories';
import { mockRiskSubcategories } from '../landingPage/alerts/riskSubcategory/mockRiskSubcategories';
import { mockEmployees } from '../../employee/mockEmployees';
import riskRuleTemplate from './templates/riskRulesTemplate';

const formatGetData = data => (data ? transformData({
  data,
  toSchema: 'frontend',
  template: riskRuleTemplate,
  version: '1.0'
}) : {});

const formatEditableData = data => (data ? transformData({
  data,
  toSchema: 'editableRule',
  template: riskRuleTemplate,
  version: '1.0'
}) : {});

export const mockRiskRules = {
  default: {
    name: 'Risk Rule Default',
    description: 'default rule',
    sql: 'select * from important where times >= good\ninsert null to randomTable',
    status: 'ENABLED',
    priority: 'severity_5',
    employeeGroupId: employeeGuids.default,
    autoCloseDays: 14,
    frequencyExpression: '0 0 * * *',
    primaryKeyType: 'MERCHANT_GUID',
    riskCategory: mockRiskCategories.riskCategory01.riskCategory,
    riskSubcategory: mockRiskSubcategories.riskSubcategory01.riskSubcategory,
    riskRuleId: riskRuleGuids.default,
    createdBy: {
      employeeId: `${mockEmployees.employee01.employeeId}`,
      employeeFirstName: `${mockEmployees.employee01.firstName}`,
      employeeLastName: `${mockEmployees.employee01.lastName}`,
      employeeEmail: `${mockEmployees.employee01.email}`
    },
    sandbox: false
  },
  riskRule01: {
    name: 'Risk Rule 01',
    description: 'first rule',
    sql: 'select gold',
    status: 'ENABLED',
    priority: 'severity_3',
    employeeGroupId: employeeGuids.default,
    autoCloseDays: 12,
    frequencyExpression: '0 17 * * 5',
    primaryKeyType: 'MERCHANT_EIN_ID',
    riskCategory: mockRiskCategories.riskCategory01.riskCategory,
    riskSubcategory: mockRiskSubcategories.riskSubcategory02.riskSubcategory,
    riskRuleId: riskRuleGuids.riskRule01,
    createdBy: {
      employeeId: `${mockEmployees.employee01.employeeId}`,
      employeeFirstName: `${mockEmployees.employee01.firstName}`,
      employeeLastName: `${mockEmployees.employee01.lastName}`,
      employeeEmail: `${mockEmployees.employee01.email}`
    },
    sandbox: true
  },
  riskRule02: {
    name: 'Risk Rule 02',
    description: 'second rule',
    sql: 'select silver',
    status: 'ENABLED',
    priority: 'severity_1',
    employeeGroupId: employeeGuids.employee02,
    autoCloseDays: 12,
    frequencyExpression: '0 5 31 * *',
    primaryKeyType: 'PARTNER_ID',
    riskCategory: mockRiskCategories.riskCategory02.riskCategory,
    riskSubcategory: mockRiskSubcategories.riskSubcategory03.riskSubcategory,
    riskRuleId: riskRuleGuids.riskRule02,
    createdBy: {
      employeeId: `${mockEmployees.employee02.employeeId}`,
      employeeFirstName: `${mockEmployees.employee02.firstName}`,
      employeeLastName: `${mockEmployees.employee02.lastName}`,
      employeeEmail: `${mockEmployees.employee02.email}`
    },
    sandbox: false
  },
  riskRule03: {
    name: 'Risk Rule 03',
    description: 'third rule',
    sql: 'select bronze',
    status: 'ENABLED',
    priority: 'unprioritized',
    employeeGroupId: employeeGuids.employee02,
    autoCloseDays: 12,
    eventDriven: {
      processorName: null,
      dataType: 'SETTLEMENT'
    },
    primaryKeyType: 'PERSON_ID',
    riskCategory: mockRiskCategories.riskCategory02.riskCategory,
    riskSubcategory: mockRiskSubcategories.riskSubcategory04.riskSubcategory,
    riskRuleId: riskRuleGuids.riskRule03,
    createdBy: {
      employeeId: `${mockEmployees.employee02.employeeId}`,
      employeeFirstName: `${mockEmployees.employee02.firstName}`,
      employeeLastName: `${mockEmployees.employee02.lastName}`,
      employeeEmail: `${mockEmployees.employee02.email}`
    },
    sandbox: false
  },
  riskRule04: {
    name: 'Risk Rule 04',
    description: 'fourth rule',
    sql: 'select disabled',
    status: 'DISABLED',
    priority: 'severity_5',
    employeeGroupId: employeeGuids.employee02,
    autoCloseDays: 12,
    frequencyExpression: '0 20 * * *',
    primaryKeyType: 'MERCHANT_GUID',
    riskCategory: mockRiskCategories.riskCategory02.riskCategory,
    riskSubcategory: mockRiskSubcategories.riskSubcategory05.riskSubcategory,
    riskRuleId: riskRuleGuids.riskRule04,
    createdBy: {
      employeeId: `${mockEmployees.employee02.employeeId}`,
      employeeFirstName: `${mockEmployees.employee02.firstName}`,
      employeeLastName: `${mockEmployees.employee02.lastName}`,
      employeeEmail: `${mockEmployees.employee02.email}`
    },
    sandbox: false
  },
  riskRule05: {
    name: 'Risk Rule 05',
    description: 'fifth rule',
    sql: 'select sandbox',
    status: 'ENABLED',
    priority: 'severity_3',
    employeeGroupId: employeeGuids.employee02,
    autoCloseDays: 12,
    frequencyExpression: '0 20 * * *',
    primaryKeyType: 'MERCHANT_EIN_ID',
    riskCategory: mockRiskCategories.riskCategory02.riskCategory,
    riskSubcategory: mockRiskSubcategories.riskSubcategory06.riskSubcategory,
    riskRuleId: riskRuleGuids.riskRule05,
    createdBy: {
      employeeId: `${mockEmployees.employee02.employeeId}`,
      employeeFirstName: `${mockEmployees.employee02.firstName}`,
      employeeLastName: `${mockEmployees.employee02.lastName}`,
      employeeEmail: `${mockEmployees.employee02.email}`
    },
    sandbox: true
  }
};

export const mockRiskRuleGet = (body, params, options) => {
  const { allRuleStatuses = false, sandboxRules = false } = params;
  const rules = [
    mockRiskRules.default,
    mockRiskRules.riskRule01,
    mockRiskRules.riskRule02,
    mockRiskRules.riskRule03
  ];
  if (allRuleStatuses && sandboxRules) {
    return {
      rules: [
        ...rules,
        mockRiskRules.riskRule04,
        mockRiskRules.riskRule05
      ]
    };
  }
  if (allRuleStatuses && !sandboxRules) {
    return {
      rules: [
        ...rules,
        mockRiskRules.riskRule04
      ]
    };
  }
  if (!allRuleStatuses && sandboxRules) {
    return {
      rules: [
        ...rules,
        mockRiskRules.riskRule05
      ]
    };
  }
  return { rules };
};

export const mockRiskRuleGetFormatted = formatGetData(mockRiskRuleGet(null,
  { allRuleStatuses: false, sandboxRules: false },
  null));
export const mockRiskRuleConvertToEditable = formatEditableData(mockRiskRuleGetFormatted[0]);
