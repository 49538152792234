export const treeStyleCSS = {
  toolbar: {
    overflow: 'hidden',
    borderBottom: '1px solid var(--color-row-even)',
    padding: '20px 20px 5px',
    position: 'relative',
    zIndex: '2'
  }
};

export default treeStyleCSS;
