import { isBool, monthsDropdown } from '@f1/shared/src/_helpers';

export const initialState = {
  employeeData: {
    employees: [],
    employeeGroups: []
  },
  merchantData: {
    nestedMerchants: []
  },
  varListPolling: {
    pollingOn: false
  },
  partnerData: {
    allPartners: [],
    getPartnersInProgress: false,
    relationshipMap: []
  },
  relationshipData: {
    allRelationships: [],
    allGatewayInfoList: [],
    relationshipForestTimestamp: 0,
    relationshipForest: {}
  },
  ticketData: {},
  riskData: {
    allRiskCategories: [],
    allCurrentRiskSubcategories: [],
    allAlerts: {},
    allEmployees: true,
    sandboxAlerts: false,
    showAlertsTable: true
  },
  residualsData: {
    month: monthsDropdown[new Date().getMonth()]?.value || '',
    year: new Date().getFullYear()
  },
  apiVersion: {
    useV2: true
  }
};

function dataUpdate (state = initialState, actions) {
  const timestamp = actions.timestamp || Date.now();
  switch (actions.type) {
    case 'DATA_UPDATE':
      return {
        ...state,
        ...(actions.employeeData && {
          employeeData: {
            ...state.employeeData,
            timestamp,
            ...actions.employeeData.employees && {
              employees: [...actions.employeeData.employees]
            },
            ...actions.employeeData.employeeGroups && {
              employeeGroups: [...actions.employeeData.employeeGroups]
            }
          }
        }),
        ...(actions.merchantData && {
          merchantData: {
            ...state.merchantData,
            timestamp,
            ...actions.merchantData.nestedMerchants && {
              nestedMerchants: [...actions.merchantData.nestedMerchants]
            }
          }
        }),
        ...(actions.partnerData && {
          partnerData: {
            ...state.partnerData,
            timestamp,
            ...(isBool(actions.partnerData.getPartnersInProgress) && {
              getPartnersInProgress: actions.partnerData.getPartnersInProgress
            }),
            ...actions.partnerData.allPartners && {
              allPartners: [...actions.partnerData.allPartners]
            },
            ...actions.partnerData.relationshipMap && {
              relationshipMap: [...actions.partnerData.relationshipMap]
            }
          }
        }),
        ...(actions.relationshipData && {
          relationshipData: {
            ...state.relationshipData,
            timestamp,
            ...actions.relationshipData.allRelationships && {
              allRelationships: [...actions.relationshipData.allRelationships]
            },
            ...actions.relationshipData.allGatewayInfoList && {
              allGatewayInfoList: [...actions.relationshipData.allGatewayInfoList]
            },
            ...(actions.relationshipData.relationshipForest ||
              actions.relationshipData.relationshipForestTimestamp) && {
              relationshipForestTimestamp: actions.relationshipData.relationshipForestTimestamp,
              relationshipForest: { ...actions.relationshipData.relationshipForest }
            }
          }
        }),
        ...(actions.ticketData && {
          ticketData: {
            timestamp
          }
        }),
        ...(actions.riskData && {
          riskData: {
            ...state.riskData,
            timestamp,
            ...actions.riskData.allRiskCategories && {
              allRiskCategories: [...actions.riskData.allRiskCategories]
            },
            ...actions.riskData.allCurrentRiskSubcategories && {
              allCurrentRiskSubcategories: [...actions.riskData.allCurrentRiskSubcategories]
            },
            ...actions.riskData.allAlerts && {
              allAlerts: { ...actions.riskData.allAlerts }
            },
            ...isBool(actions.riskData.showAlertsTable) && {
              showAlertsTable: actions.riskData.showAlertsTable
            },
            ...isBool(actions.riskData.allEmployees) && {
              allEmployees: actions.riskData.allEmployees
            },
            ...isBool(actions.riskData.sandboxAlerts) && {
              sandboxAlerts: actions.riskData.sandboxAlerts
            },
            ...actions.riskData.allRiskRules && {
              allRiskRules: [...actions.riskData.allRiskRules]
            }
          }
        }),
        ...(actions.residualsData && {
          residualsData: {
            ...state.residualsData,
            ...actions.residualsData.month && {
              month: actions.residualsData.month
            },
            ...actions.residualsData.year && {
              year: actions.residualsData.year
            }
          }
        }),
        ...(actions.apiVersion && {
          apiVersion: {
            ...state.apiVersion,
            ...isBool(actions.apiVersion?.useV2) && {
              useV2: actions.apiVersion?.useV2
            }
          }
        }),
        ...actions.varListPolling && {
          varListPolling: {
            ...state.varListPolling,
            ...actions.varListPolling
          }
        }
      };
    default:
      return state;
  }
}

export default dataUpdate;
