import React from 'react';
import { PropTypes } from 'prop-types';
import {
  transformData,
  endpoint,
  isEmpty,
  sharedGetInnerAlertBarState
} from '../../_helpers';
import { AlertBar, Spinner, Button } from '../../../index';
import { FormAssistant } from '../../FormAssistant';
import { crabTaskPendFieldsPost, crabExternalCommunicationFields } from '../../_crabFields';
import pendListTemplate from '../../data/sharedBoarding/templates/pendListTemplate';
import crabExternalCommunicationTemplate from '../../data/sharedBoarding/templates/crabExternalCommunicationTemplate';

export class ChangeStatusForm extends React.Component {
  constructor (props) {
    super(props);
    this.mounted = false;
    const { existingTaskPendFields } = props;
    this.formId = 'changeStatusForm';
    this.state = {
      spinnerLoading: false,
      alertBarType: 'closed',
      alertBarMessage: '',
      alertBarTimeout: true,
      statusRequestSuccess: false,
      externalCommRequestSuccess: false,
      existingTaskPendFields
    };
    this.formComponents = [
      { // POST /v1/application/task/pend
        componentType: 'combobox',
        initialValue: existingTaskPendFields.pendStatus?.value,
        props: {
          ...crabTaskPendFieldsPost.pendStatus,
          list: crabTaskPendFieldsPost.pendStatus.list.filter(item => item.partnerCanEdit),
          disabled: true,
          required: true
        }
      },
      { // PUT /v1/application/externalCommunication
        componentType: 'input',
        props: crabExternalCommunicationFields.description
      }
    ];
  }

  componentDidMount () {
    this.mounted = true;
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  updateState = (state, callback = null) => {
    this.mounted && this.setState(state, callback);
  }

  handleFormChange = (newFormState, id, options) => {
    const formChanged = JSON.stringify(this.state) !== JSON.stringify(newFormState);
    if (formChanged) {
      const { valuesForBackend } = options || {};
      this.updateState({ ...newFormState, ...(valuesForBackend && { valuesForBackend }) });
    }
  }

  handleSubmit = async () => {
    const { pendId, callback } = this.props;
    const {
      valuesForBackend,
      statusRequestSuccess,
      externalCommRequestSuccess,
      description: externalCommDescription
    } = this.state;
    if (!isEmpty(pendId)) {
      this.handleInnerAlertBar({ type: 'closed', data: '' });
      this.updateState({ spinnerLoading: true });
      const requests = [
        ...(!statusRequestSuccess ? [this.handleTaskPendChange] : []),
        ...(!isEmpty(externalCommDescription) && !externalCommRequestSuccess
          ? [this.handleAddExternalCommunication]
          : [])
      ];
      Promise.allSettled(requests.map(req => req()))
        .then((results) => {
          const errorMessages = results.filter(result => result.value.status === 'error').map(result => result.value.message).join(' ');
          if (isEmpty(errorMessages)) {
            const refreshData = results.map(result => result.value.type);
            const shouldRefreshPendDetails = refreshData.includes('taskPend');
            const isAddressed = valuesForBackend?.pendStatus === 'addressed';
            const cbOptions = {
              updatedPendDetails: {
                applicationPendId: pendId,
                pendStatus: valuesForBackend?.pendStatus
              },
              refreshPendDetails: shouldRefreshPendDetails,
              ...(shouldRefreshPendDetails && isAddressed && {
                needToValidateSubmit: true
              }),
              refreshExternalComm: refreshData.includes('externalComm')
            };
            callback && callback(cbOptions);
          } else {
            this.handleInnerAlertBar({ type: 'warning', data: errorMessages });
          }
        });
    }
  }

  handleTaskPendChange = () => new Promise(async (resolve) => {
    const { axiosRequest, pendId } = this.props;
    const { existingTaskPendFields, valuesForBackend } = this.state;
    this.updateState({ spinnerLoading: true });
    const requestBody = transformData({
      data: {
        description: existingTaskPendFields.description.value, // original task pend description
        pendStatus: valuesForBackend.pendStatus, // updated pendStatus
        userType: 'partner'
      },
      toSchema: 'backendPost',
      template: pendListTemplate,
      version: '1.0'
    });
    const requestOptions = {
      fullPageLoad: false,
      method: 'post',
      url: `${endpoint.crab.v1.application.taskPend}`,
      requestGuid: { applicationPendId: pendId }
    };
    const apiRes = await axiosRequest(requestOptions, requestBody);
    this.updateState({ ...apiRes?.state });
    if (apiRes?.errorDetails instanceof Error) {
      const { alertBarMessage: errorMessage } = sharedGetInnerAlertBarState({ type: 'warning', data: apiRes.errorDetails, axiosRequest });
      resolve({ status: 'error', message: `Error updating status: ${errorMessage}` });
    } else {
      this.updateState({ statusRequestSuccess: true });
      resolve({ status: 'success', type: 'taskPend' });
    }
  })

  handleAddExternalCommunication = () => new Promise(async (resolve) => {
    const { axiosRequest, pendId } = this.props;
    const { valuesForBackend } = this.state;
    this.updateState({ spinnerLoading: true });
    const requestBody = transformData({
      data: { description: valuesForBackend.description },
      toSchema: 'backend',
      template: crabExternalCommunicationTemplate,
      version: '1.0'
    });
    const requestOptions = {
      fullPageLoad: false,
      method: 'put',
      url: `${endpoint.crab.v1.application.externalCommunication}`,
      requestGuid: { applicationPendId: pendId }
    };
    const apiRes = await axiosRequest(requestOptions, requestBody);
    this.updateState({ ...apiRes?.state });
    if (apiRes?.errorDetails instanceof Error) {
      const { alertBarMessage: errorMessage } = sharedGetInnerAlertBarState({ type: 'warning', data: apiRes.errorDetails, axiosRequest });
      resolve({ status: 'error', message: `Error adding message: ${errorMessage}` });
    } else {
      this.updateState({ externalCommRequestSuccess: true });
      resolve({ status: 'success', type: 'externalComm' });
    }
  })

  handleInnerAlertBar = (options) => {
    const { type, data, additionalState = {} } = options || {};
    const { axiosRequest } = this.props;
    const alertBarState = sharedGetInnerAlertBarState({ type, data, axiosRequest });
    this.updateState({ ...alertBarState, ...additionalState });
  }

  render () {
    const {
      [this.formId]: formValid,
      spinnerLoading,
      alertBarType,
      alertBarMessage,
      alertBarTimeout
    } = this.state;
    return (
      <div id={this.formId} style={{ width: '100%', padding: '0 1em' }}>
        <AlertBar
          options={{
            barStyle: alertBarType,
            message: alertBarMessage,
            timeout: alertBarTimeout,
            customWarnStyle: { width: '100%', height: '100%' }
          }}
          callback={this.updateState}
          useInnerAlertBar
        />
        <Spinner loading={spinnerLoading} />
        <FormAssistant
          id={this.formId}
          formComponents={this.formComponents}
          callback={this.handleFormChange}
        />
        <Button
          id="submit"
          onClick={this.handleSubmit}
          disabled={!formValid}
          style={{ marginTop: '0.5em' }}
        >
          Submit
        </Button>
      </div>
    );
  }
}

ChangeStatusForm.propTypes = {
  pendId: PropTypes.string,
  existingTaskPendFields: PropTypes.shape({
    pendStatus: PropTypes.shape({ value: PropTypes.string }),
    description: PropTypes.shape({ value: PropTypes.string })
  }),
  axiosRequest: PropTypes.func,
  callback: PropTypes.func
};

ChangeStatusForm.defaultProps = {
  pendId: '',
  existingTaskPendFields: {
    pendStatus: { value: '' },
    description: { value: '' }
  },
  axiosRequest: () => {},
  callback: null
};

export default ChangeStatusForm;
