import React from 'react';
import PropTypes from 'prop-types';
import { Button } from './Button';
import { ComboBox } from './ComboBox';
import { isEmpty, sortData } from './_helpers';
import { icons } from '../images/_icons';
import { paginationCSS } from './_styles';

class Pagination extends React.Component {
  constructor (props) {
    super(props);
    this.mounted = false;
    const {
      perPage,
      pageNum
    } = props;
    this.perPageValues = [
      ...new Set([ // dedupe
        ...(!isEmpty(perPage) ? [Number(perPage)] : []),
        25,
        50,
        100,
        200
      ])
    ];
    this.defaultPerPageList = this.perPageValues.map(v => ({ title: `${v}`, value: v }));
    this.state = {
      perPageList: sortData(this.defaultPerPageList, 'value'),
      perPage,
      pageNum
    };
  }

  componentDidMount () {
    this.mounted = true;
  }

  componentDidUpdate (prevProps) {
    const {
      pageNum,
      data
    } = this.props;
    if (pageNum !== prevProps.pageNum) {
      this.handleClick(pageNum);
    }
    if (prevProps.data.length !== data.length) {
      this.handleClick(1);
    }
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  updateState = (state) => {
    this.mounted && this.setState(state);
  }

  handleClick = (number) => {
    const {
      callback
    } = this.props;
    this.updateState({ pageNum: number });
    const response = { pageNum: number };
    callback(response);
  }

  handlePerPage = (number) => {
    const {
      callback
    } = this.props;
    this.updateState({ pageNum: 1, perPage: number });
    const response = { pageNum: 1, perPage: number };
    callback(response);
  }

  showing = () => {
    const {
      data,
      wrapperStyle
    } = this.props;
    const {
      perPage,
      pageNum
    } = this.state;
    const pFrom = (pageNum * perPage) - perPage + 1;
    const toMax = pageNum * perPage > data.length ? data.length : pageNum * perPage;
    const pTo = data.length <= perPage ? data.length : toMax;
    return (
      <div className="paginationShowing" style={{ ...paginationCSS.showing, ...wrapperStyle }}>
        Displaying
        { data.length <= perPage ? (
          ` All Results`
        ) : (
          <span>
            <strong>
              {` ${pFrom}-${pTo} `}
            </strong>
            of
            <strong>
              {` ${data.length}`}
            </strong>
          </span>
        )}
      </div>
    );
  }

  createRange = (from, to) => {
    const range = [];
    for (let i = from; i <= to; i += 1) {
      range.push(i);
    }
    return range;
  };

  pageLink = (number) => {
    const {
      pageNum
    } = this.state;
    return (
      <Button
        key={number}
        style={{
          width: '32px',
          height: '30px',
          margin: '6px 0 0 0',
          padding: '1px'
        }}
        onClick={() => this.handleClick(number)}
        active={pageNum === number}
        {...pageNum !== number && {
          type: 'text'
        }}
      >
        {number.toString()}
      </Button>
    );
  }

  ellipsis = (start, end) => {
    if (start === end) {
      return this.pageLink(start);
    }
    return (
      <Button
        className="ellipsis"
        key={start}
        style={{
          width: '32px',
          height: '30px',
          margin: '8px 0 0 0',
          padding: '1px'
        }}
        onClick={/* istanbul ignore next */() => this.handleClick(start)}
        type="text"
      >
        ...
      </Button>
    );
  }

  pageNumbers = () => {
    const {
      data
    } = this.props;
    const {
      perPage,
      pageNum
    } = this.state;
    const siblings = 1;
    const boundaries = 1;
    const paginationList = [];
    const totalPages = Math.ceil(data.length / perPage);
    if (3 + (2 * siblings) + (2 * boundaries) >= totalPages) {
      paginationList.push(...this.createRange(1, totalPages).map(this.pageLink));
    } else {
      const Group3Start = totalPages + 1 - boundaries;
      const mainPagesStart = Math.min(
        Math.max(
          pageNum - siblings,
          boundaries + 2
        ),
        Group3Start - (2 * siblings) - 2
      );
      const mainPagesEnd = mainPagesStart + (2 * siblings);
      paginationList.push(
        ...this.createRange(1, boundaries).map(this.pageLink)
      );
      paginationList.push(
        this.ellipsis(mainPagesStart - 1, boundaries + 1)
      );
      paginationList.push(
        ...this.createRange(mainPagesStart, mainPagesEnd).map(this.pageLink)
      );
      paginationList.push(
        this.ellipsis(mainPagesEnd + 1, Group3Start - 1)
      );
      paginationList.push(
        ...this.createRange(Group3Start, totalPages).map(this.pageLink)
      );
    }
    return paginationList;
  }

  render () {
    const {
      children,
      data,
      wrapperStyle
    } = this.props;
    const {
      perPageList,
      perPage,
      pageNum
    } = this.state;
    const totalPages = Math.ceil(data.length / perPage);
    return (
      <React.Fragment>
        <div className="pagination" style={{ ...paginationCSS.base, ...wrapperStyle }} role="article" aria-label="Pagination">
          { (data.length > perPage || data.length > 25) && (
            <div className="perPageWrapper" style={{ display: 'flex', alignItems: 'center', height: '42px' }}>
              { data.length > perPage && (
                <div className="perPageDisplay" style={{ display: 'flex' }}>
                  <Button
                    style={{
                      width: '32px',
                      height: '42px',
                      lineHeight: '1',
                      padding: '1px'
                    }}
                    onClick={() => this.handleClick(pageNum === 1 ? totalPages : pageNum - 1)}
                    icon={icons.arrowDoubleLeft()}
                    type="text"
                    aria-label="Previous Page"
                  >
                    &nbsp;
                  </Button>
                  {this.pageNumbers()}
                  <Button
                    style={{
                      width: '32px',
                      height: '42px',
                      lineHeight: '1',
                      padding: '1px'
                    }}
                    onClick={() => this.handleClick(pageNum === totalPages ? 1 : pageNum + 1)}
                    icon={icons.arrowDoubleRight()}
                    type="text"
                    aria-label="Next Page"
                  >
                    &nbsp;
                  </Button>
                </div>
              )}
            </div>
          )}
          { data.length > 25 && (
            <div style={{ marginTop: '7px' }}>
              <ComboBox
                label="Per page"
                className="perPage"
                labelPos="left"
                list={perPageList}
                selected={perPage}
                containerStyles={{ flex: '1' }}
                wrapperStyle={{ minWidth: '75px', ...wrapperStyle }}
                callback={this.handlePerPage}
                size="sm"
              />
            </div>
          )}
          {this.showing()}
          {children}
        </div>
      </React.Fragment>
    );
  }
}

Pagination.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]),
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]),
  perPage: PropTypes.number,
  pageNum: PropTypes.number,
  wrapperStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  callback: PropTypes.func
};

Pagination.defaultProps = {
  children: null,
  data: [],
  perPage: 25,
  pageNum: 1,
  wrapperStyle: {},
  callback: () => {}
};

export default Pagination;
