import { pad } from '@f1/shared/src/_helpers';
import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';

export const mockV2FeeGet = (body, params, options) => {
  const { length, guid = '' } = options || {};
  const mockData = {
    relationshipRatesAndFees: Array.from({ length: length || 30 })
      .map((row, index) => scheduleAEntryObject({ index, decimal: index % 2 === 0 }))
  };
  return { [guid]: mockData };
};

const scheduleAEntryObject = options => ({
  feeName: `Mock Fee Name ${pad(options.index + 1)}`, // used in FTs
  feeRate: getMockValue({ type: 'number', min: 1000000, max: 9999999 }) / (options.decimal ? 10000000 : 100),
  feeSharingType: getMockValue({ type: 'list', list: ['pass_through', 'shared_with_partner', 'not_shared_with_partner'] })
});

export default mockV2FeeGet;
