import * as d3 from 'd3';
import React from 'react';
import { PropTypes } from 'prop-types';
import {
  isEmpty,
  colorBlender
} from '../_helpers';
import { ToolTip } from '../ToolTip';
import { Spinner } from '../Spinner';

class D3Radial extends React.Component {
  constructor (props) {
    super(props);
    this.mounted = false;
    this.state = {
      spinnerLoading: false,
      initWidth: 340,
      initHeight: 220,
      baseFontSize: 11,
      margin: 34,
      hovered: {
        type: '',
        data: null
      },
      tooltipStyle: {
        x: 0,
        y: 0
      },
      arc: null,
      chartRadius: 0,
      numArcs: 0,
      arcWidth: 0,
      arcPadding: 0,
      dataToRenderOrig: []
    };
    this.element = React.createRef();
  }

  componentDidMount () {
    this.mounted = true;
    this.updateState({ spinnerLoading: true });
    setTimeout(() => {
      this.createD3Radial();
    }, 3000); // to allow axes to render
  }

  componentDidUpdate (prevProps) {
    const { chartData } = this.props;
    if (!isEmpty(prevProps.chartData) && !isEmpty(chartData) &&
    JSON.stringify(chartData) !== JSON.stringify(prevProps.chartData)) {
      this.updateState({ spinnerLoading: true });
      this.createD3Radial();
    }
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  updateState = (state) => {
    this.mounted && this.setState(state);
  }

  handleResize = () => {
    const { current: currentElement } = this.element;
    if (currentElement) {
      const { initHeight, initWidth } = this.state;
      const aspect = initWidth / initHeight;
      const svg = d3.select(currentElement);
      const targetWidth = parseInt(currentElement.offsetWidth || 0, 10);
      svg.attr('height', Math.round(targetWidth / aspect));
      this.updateState({
        width: targetWidth,
        height: parseInt(currentElement.offsetHeight || 0, 10)
      });
      this.setFontSize();
    }
  }

  setFontSize = () => { // method to keep font size the same as it scales
    const { baseFontSize } = this.state;
    const { current: currentElement } = this.element;
    const newFontSize = baseFontSize * (375 / currentElement.offsetWidth || 0);
    if (currentElement) {
      d3.select(currentElement)
        .selectAll('.tick')
        .style('font-size', `${newFontSize}px`);
      d3.select(currentElement)
        .selectAll('.label')
        .style('font-size', `${newFontSize}px`);
    }
  }

  handleMouseMove = (e) => {
    const {
      tooltipStyle
    } = this.state;
    this.updateState({
      tooltipStyle: {
        ...tooltipStyle,
        x: e.clientX,
        y: e.clientY
      }
    });
  }

  handleMouseOver = (e, d) => {
    const {
      chartData
    } = this.props;
    const {
      header = {}
    } = chartData;
    const {
      label
    } = header || {};
    const {
      dataToRenderOrig,
      tooltipStyle
    } = this.state;
    const value = d.label === 'Chargebacks'
      ? dataToRenderOrig.filter(item => d.label === 'Chargebacks')[0].value
      : d.actualValue;
    const tooltipValue = d.label === 'Chargebacks'
      ? `${value > 0 ? (value * 100).toFixed(2) : 0}%`
      : `${value.toFixed(2)}% of $${d.maxValue}`;

    this.updateState({
      tooltipStyle: {
        ...tooltipStyle,
        revealed: true
      },
      hovered: {
        type: 'radial',
        data: {
          ...d,
          header: label,
          name: d.label,
          value: tooltipValue
        }
      }
    });
  }

  handleMouseOut = () => {
    this.updateState({
      hovered: {
        type: '',
        data: null
      }
    });
  }

  percentToRatio = (d) => {
    const percent = d.value;
    // only for chargeback, if bar is less than this percent, just make it red.
    if (d.label === 'Chargebacks') {
      const newMin = 90;
      if (percent < newMin) { return 1; }
      return 1 - ((percent - newMin) / (100 - newMin));
    }
    return (percent / 100);
  };

  // this will blend the data color to a new color based on value
  // i.e. color turns more RED as approaches limit.  or more green as it gets better, etc.
  mixColor = (from, to, d) => colorBlender(from, to, this.percentToRatio(d))

  // Arc length
  arcTween = (d, i) => {
    const {
      arc
    } = this.state;
    const interpolate = d3.interpolate(0, Math.min(Math.abs(d.value), 100));
    return t => arc(interpolate(t), i);
  }

  rad2deg = (angle) => {
    const { PI } = Math;
    return angle * 180 / PI;
  }

  getInnerRadius = (d, index) => {
    const {
      chartRadius,
      numArcs,
      arcWidth,
      arcPadding
    } = this.state;
    return chartRadius - (numArcs - (index + 1)) * (arcWidth + arcPadding);
  }

  getOuterRadius = (d, index) => {
    const {
      arcWidth,
      arcPadding
    } = this.state;
    return this.getInnerRadius(d, index) + arcWidth - arcPadding;
  }

  createD3Radial = () => {
    const { current: currentElement } = this.element;
    const {
      initWidth,
      initHeight,
      baseFontSize,
      margin
    } = this.state;
    const {
      chartData = {},
      options = {}
    } = this.props;
    const {
      showTicks = false,
      maxDegree = 270,
      drawBorder = false,
      showPercent = false
    } = options;
    const {
      data = []
    } = chartData;
    if (currentElement !== null && !isEmpty(data)) {
      const dataToRenderOrig = data.rings;
      this.updateState({ dataToRenderOrig });
      const dataToRender = [];
      const bgArcData = [];
      dataToRenderOrig.forEach((obj) => {
        const percent = obj.value > 1.0 ? 100 : Math.round((obj.value * 100) * 100) / 100;
        const inverted = (1 - obj.value) * 100;
        dataToRender.push({
          ...obj,
          value: obj.label === 'Chargebacks' ? inverted : percent
        });
        bgArcData.push({
          ...obj,
          value: 100,
          actualValue: obj.label === 'Chargebacks' ? inverted : percent
        });
      });
      const width = initWidth;
      const height = initHeight;
      const chartRadius = height / 2 - margin;

      if (showTicks) {
        // if showing ticks, make margin larger
        this.updateState({ margin: 40 });
      }

      const svg = d3.select(currentElement)
        .html(null)
        .append('svg')
        .attr('width', '100%')
        .attr('height', '100%')
        .attr('viewBox', `0 0 ${width} ${height}`)
        .attr('preserveAspectRatio', 'xMinYMin')
        .append('g')
        // instead of {width / 2}, do {width / 1.5}, to allow more room on left
        // since radial bar labels float off to the left.
        .attr('transform', `translate(${width / 1.5},${height / 2})`);

      const { PI } = Math;
      const arcMinRadius = 10;
      const arcPadding = 1;
      const labelPadding = -5;

      const maxRange = maxDegree * PI / 180;

      const scale = d3.scaleLinear()
        .domain([0, 100])
        .range([0, maxRange]);

      const keys = dataToRender.map((d, i) => d.label);
      // number of arcs
      const numArcs = keys.length;
      const arcWidth = (chartRadius - arcMinRadius - numArcs * (arcPadding * 2)) / numArcs;
      const arc = d3.arc()
        .innerRadius(this.getInnerRadius)
        .outerRadius(this.getOuterRadius)
        .startAngle(0)
        .endAngle(scale);

      this.updateState({
        arc,
        chartRadius,
        numArcs,
        arcWidth,
        arcPadding
      });

      const radialAxis = svg.append('g')
        .attr('class', 'r axis')
        .selectAll('g')
        .data(dataToRender)
        .enter()
        .append('g');

      const circles = radialAxis.append('circle')
        .attr('r', (d, i) => this.getOuterRadius(d, i) + arcPadding)
        .attr('fill', 'none');

      if (drawBorder) {
        circles
          .attr('stroke', '#f1f1f1')
          .attr('stroke-width', '2px')
          .attr('pointer-events', 'none');
      }

      radialAxis.append('text')
        .attr('class', 'label')
        .attr('text-anchor', 'end')
        .attr('font-size', `${baseFontSize}px`)
        .attr('x', labelPadding)
        .attr('y', (d, i) => -this.getOuterRadius(d, i) + (arcWidth / 2) + 5) // + 5 to offset font height
        .text(d => `${d.label}`);

      if (showTicks) {
        const numTicks = 10;
        const ticks = scale.ticks(numTicks).slice(0, -1);
        const tickPos = chartRadius + arcWidth + arcPadding + 5;
        const axialAxis = svg.append('g')
          .attr('class', 'a axis')
          .selectAll('g')
          .data(ticks)
          .enter()
          .append('g')
          .attr('transform', d => `rotate(${this.rad2deg(scale(d)) - 90})`);
        axialAxis.append('line')
          .attr('x2', this.getOuterRadius(null, dataToRender.length - 1) + 4)
          .attr('stroke', '#cccccc')
          .attr('stroke-width', '1px');

        axialAxis.append('text')
          .attr('x', tickPos)
          .attr('class', 'tick')
          .attr('text-anchor', 'end')
          .attr('transform', d => `rotate(3)translate(16,0)`)
          .attr('fill', '#6e6e6e')
          .text(d => d);
      }
      // render circle in center, to cover any ticks being rendered
      svg.append('circle')
        .attr('cx', 0)
        .attr('cy', 0)
        .attr('r', (this.getInnerRadius(null, 0) - 1))
        .style('fill', 'var(--color-bg)')
        .attr('pointer-events', 'none');

      if (showPercent) {
        // render percent in center
        svg.append('text')
          .attr('class', 'centerValue')
          .attr('text-anchor', 'middle')
          .attr('dominant-baseline', 'middle')
          .text(`${(data.centerText.value * 100).toFixed(2)}%`);
      }

      // background arcs
      const bgArcs = svg.append('g')
        .attr('class', 'bgArcs')
        .selectAll('path')
        .data(bgArcData)
        .enter()
        .append('path')
        .attr('class', 'arc')
        .attr('opacity', '0.4')
        .style('fill', (d, i) => '#dddddd')
        .attr('pointer-events', 'none');

      bgArcs.transition()
        .delay((d, i) => i * 100)
        .duration(500)
        .attrTween('d', this.arcTween);

      // data arcs
      const arcs = svg.append('g')
        .attr('class', 'data')
        .selectAll('path')
        .data(dataToRender)
        .enter()
        .append('path')
        .attr('class', 'arc')
        .attr('pointer-events', 'none')
        .style('fill', (d, i) => (d.label === 'Chargebacks'
          ? colorBlender('#ff0000', d.color, this.percentToRatio(d))
          : colorBlender('#00ff00', d.color, this.percentToRatio(d))));

      arcs.transition()
        .delay((d, i) => i * 200)
        .duration(1000)
        .attrTween('d', this.arcTween);

      // tooltip arcs
      const tooltipArcs = svg.append('g')
        .attr('class', 'data')
        .selectAll('path')
        .data(bgArcData)
        .enter()
        .append('path')
        .attr('class', 'tooltipArc')
        .attr('opacity', '0')
        .style('fill', (d, i) => '#00dddd');

      tooltipArcs.transition()
        .duration(0)
        .attrTween('d', this.arcTween);

      tooltipArcs
        .on('mouseover', this.handleMouseOver)
        .on('mousemove', this.handleMouseMove)
        .on('mouseout', this.handleMouseOut);

      d3.select(window).on(`resize.${currentElement}`, this.handleResize);
      this.setFontSize();
    }
    this.updateState({ spinnerLoading: false });
  }

  render () {
    const { chartData } = this.props;
    const {
      tooltipStyle,
      hovered,
      spinnerLoading
    } = this.state;
    return (
      <div
        style={{ width: '100%', textAlign: 'center' }}
        className="d3wrapper"
        ref={this.element}
      >
        <Spinner loading={spinnerLoading} />
        {!isEmpty(hovered.data) && (
          <ToolTip
            d3Data={hovered}
            d3Position={tooltipStyle}
            element={this.element.current}
            options={{ ...chartData.header, colorMap: chartData.colors }}
          />
        )}
      </div>
    );
  }
}

D3Radial.propTypes = {
  chartData: PropTypes.oneOfType([PropTypes.object]),
  options: PropTypes.oneOfType([PropTypes.object])
};

D3Radial.defaultProps = {
  chartData: {},
  options: {}
};

export default D3Radial;
