import { getMockPageData, getMockValue, mockPagingInfo } from '../_mockDataHelpers';

const createMockDataAchFunding = () => Array.from({ length: 30 }).map((item, index) => ({
  achFundingId: `mock-ach-funding-id-${index + 1}`,
  amount: getMockValue({ type: 'price' }),
  bankAccountLastFour: getMockValue({
    type: 'number',
    min: 1000,
    max: 9999,
    stringify: true
  }),
  bankName: getMockValue({ type: 'bankName' }),
  bankRouting: getMockValue({ type: 'routingNumber' }),
  depositDate: getMockValue({ type: 'date' }),
  merchant: {
    dbaName: `Mock DBA Name ${index + 1}`,
    merchantGuid: getMockValue({ type: 'guid' }),
    mid: getMockValue({ type: 'mid' })
  },
  relationship: {
    relationshipCode: getMockValue({
      type: 'number',
      min: 10000,
      max: 299999,
      stringify: true
    }),
    relationshipId: '21912d04-21a1-4ad3-a075-eb2f1c5f7ea8', // Relationship with Transaction Report Data
    relationshipName: `Relationship with Transaction Report Data`
  },
  type: getMockValue({ type: 'list', list: ['deposit', 'withdraw'] })
}));

// Create list of ALL mock data ONCE so paged data doesn't get recreated multiple times
const mockAllData = createMockDataAchFunding();

export const mockAchFundingGet = (body, params, options) => {
  const {
    totalNumberOfRecords = mockPagingInfo.totalNumberOfRecords,
    pageIndex = mockPagingInfo.pageIndex
  } = params || {};
  const { guid } = options || {};
  const pageData = getMockPageData({
    allData: mockAllData,
    pageIndex,
    pageSize: 25,
    optionsCopy: { ...params, pageSize: 25 }
  });
  return {
    [guid]: {
      achFundingDetailsList: pageData,
      pagingInfo: {
        totalNumberOfRecords,
        pageIndex,
        pageSize: 25
      }
    }
  };
};

export default mockAchFundingGet;
