import { getMockPageData, getMockValue, mockPagingInfo } from '../_mockDataHelpers';
import { sortData } from '../../_helpers';

export const mockTransactionReportTxnDetailsGet = (body, params, options) => {
  const { guid = '' } = options;
  const { transactionType = '' } = params;
  switch (transactionType) {
    case 'auth':
      return { [guid]: mockTransactionReportTxnDetailsAuthorizationData };
    case 'sale':
      return { [guid]: mockTransactionReportTxnDetailsSalesData };
    case 'refund':
      return { [guid]: mockTransactionReportTxnDetailsRefundData };
    default:
      return { [guid]: mockTransactionReportTxnDetailsChargebacksData };
  }
};

export const mockTransactionReportTxnBatchDetailsGet = (body, params, options) => {
  const { guid = '' } = options || {};
  return {
    [guid]: {
      transactionTable: [{
        header: [
          {
            order: 1,
            key: 'mid',
            englishName: 'MID'
          },
          {
            order: 2,
            key: 'dba',
            englishName: 'Dba Name'
          },
          {
            order: 3,
            key: 'batchDate',
            englishName: 'Batch Date'
          },
          {
            order: 4,
            key: 'batchId',
            englishName: 'Batch Id'
          },
          {
            order: 5,
            key: 'txnCountInBatch',
            englishName: 'Transaction Count in Batch'
          },
          {
            order: 6,
            key: 'volumeInBatch',
            englishName: 'Volume in Batch'
          }
        ],
        data: [
          {
            mid: getMockValue({
              type: 'number', stringify: true, min: 111111111111, max: 999999999999
            }),
            dba: getMockValue({ type: 'string' }),
            batchDate: '12/12/2020',
            batchId: '34291131',
            txnCountInBatch: 1,
            volumeInBatch: 1729
          },
          {
            mid: getMockValue({
              type: 'number', stringify: true, min: 111111111111, max: 999999999999
            }),
            dba: getMockValue({ type: 'string' }),
            batchDate: '12/12/2020',
            batchId: '42702398',
            txnCountInBatch: 1,
            volumeInBatch: 2000
          },
          {
            mid: getMockValue({
              type: 'number', stringify: true, min: 111111111111, max: 999999999999
            }),
            dba: getMockValue({ type: 'string' }),
            batchDate: '12/12/2020',
            batchId: '57854582',
            txnCountInBatch: 9,
            volumeInBatch: 50000
          }
        ]
      }],
      pagingInfo: {
        totalNumberOfRecords: 1,
        pageIndex: 1,
        pageSize: 1
      }
    }
  };
};

const commonMerchData = {
  allowPortalAccess: true,
  alternateGateway: null,
  applicationId: null,
  businessStartDate: null,
  callCenterProvider: null,
  chargebackProvider: null,
  crm: null,
  daysForDelivery: null,
  dbaName: getMockValue({ type: 'string' }),
  fulfillment: null,
  highRiskRegistration: null,
  hoursOfOperation: null,
  legalName: getMockValue({ type: 'string' }),
  mccCode: '5968',
  merchantAcceptanceDate: null,
  merchantDescriptor: 'V529MH92cFaupH desc',
  merchantEinId: 'f669adc7-f227-44db-af2e-cb5451f19cbc',
  merchantGuid: getMockValue({ type: 'string' }),
  mid: getMockValue({
    type: 'number', stringify: true, min: 111111111111, max: 999999999999
  }),
  optOutAmexMarketingCommunication: null,
  organizationalStructure: null,
  owner1Id: null,
  pastBankruptcy: null,
  pastCardTermination: null,
  previousProcessor: null,
  productVertical: null,
  publisherAuthorizationPlatform: null,
  publisherName: null,
  publisherNumber: null,
  publisherOfficeNumber: null,
  relationshipId: getMockValue({ type: 'string' }),
  reservesIndicator: 'yes',
  riskLevel: null,
  servicesSold: null,
  statementDelivery: null,
  statusForMerchantInBank: 'Open',
  subPortfolio: null,
  terminalMerchant: null,
  tin: null,
  tradeProductPurchased: null,
  tradeSupplierContact: null,
  tradeSupplierName: null,
  tradeSupplierPhone: null,
  typeOfOwnership: null,
  uploadedDocsS3Key: null
};

export const mockTransactionReportTxnDetailsAuthorizationData = {
  transactionTable: [{
    header: [
      {
        order: 1,
        key: 'merchant.mid',
        englishName: 'MID'
      },
      {
        order: 2,
        key: 'merchant.legalName',
        englishName: 'Legal Name'
      },
      {
        order: 3,
        key: 'transactionDateAuth',
        englishName: 'Transaction Date'
      },
      {
        order: 4,
        key: 'transactionTimestampAuth',
        englishName: 'Transaction Timestamp'
      },
      {
        order: 5,
        key: 'authorizationResponse',
        englishName: 'Authorization Response'
      },
      {
        order: 6,
        key: 'preauthorizationRequestAmount',
        englishName: 'Preauthorization Request Amount'
      },
      {
        order: 7,
        key: 'batchAssociationDate',
        englishName: 'Batch Association Date'
      },
      {
        order: 8,
        key: 'cardEntryMethod',
        englishName: 'Card Entry Method'
      },
      {
        order: 9,
        key: 'cashAdvanceAmount',
        englishName: 'Cash Advance Amount'
      },
      {
        order: 10,
        key: 'avsResponseCode',
        englishName: 'AVS Response Code'
      },
      {
        order: 11,
        key: 'cvvPresenceIndicator',
        englishName: 'CVV Presence Indicator'
      },
      {
        order: 12,
        key: 'cvvResponse',
        englishName: 'CVV Response'
      },
      {
        order: 13,
        key: 'card.cardNumber.cardNumber',
        englishName: 'Card Number'
      },
      {
        order: 14,
        key: 'card.cardBrand',
        englishName: 'Card Brand'
      }
    ],
    data: [
      {
        txn: {
          authorizationResponse: '00',
          authorizationTransactionId: '5bd7-281-0209-49b9-b524-5e89ea404aca',
          avsResponseCode: null,
          batchAssociationDate: '2020-07-02',
          cardEntryMethod: 'E-Commerce',
          card: {
            cardBrand: 'OTHER',
            cardNumber: {
              cardNumber: '901397XXXXXX7524',
              cardNumberId: '5421ea7c-4cc0-48fa-b794-819708080f20'
            }
          },
          cashAdvanceAmount: null,
          cvvPresenceIndicator: 'true',
          cvvResponse: null,
          merchant: { ...commonMerchData },
          mid: getMockValue({
            type: 'number', stringify: true, min: 111111111111, max: 999999999999
          }),
          preauthorizationRequestAmount: 1729,
          transactionDateAuth: '2020-07-25',
          transactionTimestampAuth: '2020-07-18T09:36:57.950Z'
        }

      },
      {
        txn: {
          authorizationResponse: '00',
          authorizationTransactionId: '5bd7-281-0209-49b9-b524-5e89ea404aca',
          avsResponseCode: null,
          batchAssociationDate: '2020-08-02',
          cardEntryMethod: 'E-Commerce',
          card: {
            cardBrand: 'DISCOVER',
            cardNumber: {
              cardNumber: '901397XXXXXX7526',
              cardNumberId: '5421ea7c-4cc0-48fa-b794-819708080f20'
            }
          },
          cashAdvanceAmount: null,
          cvvPresenceIndicator: 'true',
          cvvResponse: null,
          merchant: { ...commonMerchData },
          mid: getMockValue({
            type: 'number', stringify: true, min: 111111111111, max: 999999999999
          }),
          preauthorizationRequestAmount: 1729,
          transactionDateAuth: '2020-08-25',
          transactionTimestampAuth: '2020-08-18T09:36:57.950Z'
        }

      },
      {
        txn: {
          authorizationResponse: '00',
          authorizationTransactionId: '5bd7-281-0209-49b9-b524-5e89ea404aca',
          avsResponseCode: null,
          batchAssociationDate: '2020-09-02',
          cardEntryMethod: 'E-Commerce',
          card: {
            cardBrand: 'VISA',
            cardNumber: {
              cardNumber: '901397XXXXXX7594',
              cardNumberId: '5421ea7c-4cc0-48fa-b794-819708080f20'
            }
          },
          cashAdvanceAmount: null,
          cvvPresenceIndicator: 'true',
          cvvResponse: null,
          merchant: { ...commonMerchData },
          mid: getMockValue({
            type: 'number', stringify: true, min: 111111111111, max: 999999999999
          }),
          preauthorizationRequestAmount: 1729,
          transactionDateAuth: '2020-09-25',
          transactionTimestampAuth: '2020-09-18T09:36:57.950Z'
        }

      },
      {
        txn: {
          authorizationResponse: '00',
          authorizationTransactionId: '5bd7-281-0209-49b9-b524-5e89ea404aca',
          avsResponseCode: null,
          batchAssociationDate: '2020-10-02',
          cardEntryMethod: 'E-Commerce',
          card: {
            cardBrand: 'MASTERCARD',
            cardNumber: {
              cardNumber: '901397XXXXXX6764',
              cardNumberId: '5421ea7c-4cc0-48fa-b794-819708080f20'
            }
          },
          cashAdvanceAmount: null,
          cvvPresenceIndicator: 'true',
          cvvResponse: null,
          merchant: { ...commonMerchData },
          mid: getMockValue({
            type: 'number', stringify: true, min: 111111111111, max: 999999999999
          }),
          preauthorizationRequestAmount: 1729,
          transactionDateAuth: '2020-10-25',
          transactionTimestampAuth: '2020-10-18T09:36:57.950Z'
        }

      },
      {
        txn: {
          authorizationResponse: '00',
          authorizationTransactionId: '5bd7-281-0209-49b9-b524-5e89ea404aca',
          avsResponseCode: null,
          batchAssociationDate: '2020-11-02',
          cardEntryMethod: 'E-Commerce',
          card: {
            cardBrand: 'OTHER',
            cardNumber: {
              cardNumber: '901397XXXXXX7777',
              cardNumberId: '5421ea7c-4cc0-48fa-b794-819708080f20'
            }
          },
          cashAdvanceAmount: null,
          cvvPresenceIndicator: 'true',
          cvvResponse: null,
          merchant: { ...commonMerchData },
          mid: getMockValue({
            type: 'number', stringify: true, min: 111111111111, max: 999999999999
          }),
          preauthorizationRequestAmount: 1729,
          transactionDateAuth: '2020-11-25',
          transactionTimestampAuth: '2020-11-18T09:36:57.950Z'
        }
      }
    ]
  }],
  pagingInfo: {
    totalNumberOfRecords: 1,
    pageIndex: 1,
    pageSize: 1
  }
};

export const mockTransactionReportTxnDetailsSalesData = {
  transactionTable: [{
    header: [
      {
        order: 1,
        key: 'merchant.mid',
        englishName: 'MID'
      },
      {
        order: 2,
        key: 'merchant.legalName',
        englishName: 'Legal Name'
      },
      {
        order: 3,
        key: 'purchaseAmount',
        englishName: 'Purchase Amount'
      },
      {
        order: 4,
        key: 'authorizationCodeSt',
        englishName: 'Authorization Code'
      },
      {
        order: 5,
        key: 'authorizationAmount',
        englishName: 'Authorization Amount'
      },
      {
        order: 6,
        key: 'transactionDate',
        englishName: 'Transaction Date'
      },
      {
        order: 7,
        key: 'status',
        englishName: 'Status'
      },
      {
        order: 8,
        key: 'achDate',
        englishName: 'ACH Date'
      },
      {
        order: 9,
        key: 'achTimestamp',
        englishName: 'ACH Timestamp'
      },
      {
        order: 10,
        key: 'associationDate',
        englishName: 'Association Date'
      },
      {
        order: 11,
        key: 'card.cardBrand',
        englishName: 'Card Brand'
      },
      {
        order: 12,
        key: 'card.cardNumber.cardNumber',
        englishName: 'Card Number'
      },
      {
        order: 13,
        key: 'transactionType',
        englishName: 'Transaction Type'
      },
      {
        order: 14,
        key: 'cardEntryMethod',
        englishName: 'Card Entry Method'
      },
      {
        order: 15,
        key: 'avsResponseCode',
        englishName: 'AVS Response Code'
      },
      {
        order: 16,
        key: 'batchIdFt',
        englishName: 'Batch ID'
      }
    ],
    data: [
      {
        txn: {
          achDate: '2020-07-02',
          achTimestamp: '2020-07-13T01:56:24.950Z',
          associationDate: '2020-07-25',
          authorizationAmount: 308.85,
          authorizationCodeSt: 'B4SPSo',
          avsResponseCode: null,
          batchIdFt: '34291131', // required value for testing
          cardEntryMethod: 'E-commerce',
          card: {
            cardBrand: 'OTHER',
            cardNumber: {
              cardNumber: '901397XXXXXX7524',
              cardNumberId: '5421ea7c-4cc0-48fa-b794-819708080f20'
            }
          },
          merchant: { ...commonMerchData },
          mid: '11111111111111', // required value for testing
          purchaseAmount: 1729,
          salesTransactionId: '5bd7-281-0209-49b9-b524-5e89ea404aca',
          status: 'Paid',
          transactionDate: '2020-07-25',
          transactionType: 'Capture'
        }

      },
      {
        txn: {
          achDate: '2020-08-25',
          achTimestamp: '2020-08-18T09:36:57.950Z',
          associationDate: '2020-08-02',
          authorizationAmount: 308.85,
          authorizationCodeSt: 'B4SPSo',
          avsResponseCode: null,
          batchIdFt: '20793406',
          cardEntryMethod: 'E-commerce',
          card: {
            cardBrand: 'DISCOVER',
            cardNumber: {
              cardNumber: '901397XXXXXX7526',
              cardNumberId: '5421ea7c-4cc0-48fa-b794-819708080f20'
            }
          },
          merchant: { ...commonMerchData },
          mid: getMockValue({
            type: 'number', stringify: true, min: 111111111111, max: 999999999999
          }),
          purchaseAmount: 1729,
          salesTransactionId: '5bd7-281-0209-49b9-b524-5e89ea404aca',
          status: 'Paid',
          transactionDate: '2020-08-25',
          transactionType: 'Capture'
        }

      },
      {
        txn: {
          achDate: '2020-09-02',
          achTimestamp: '2020-09-18T09:36:57.950Z',
          associationDate: '2020-09-25',
          authorizationAmount: 308.85,
          authorizationCodeSt: 'B4SPSo',
          avsResponseCode: null,
          batchIdFt: '20793406',
          cardEntryMethod: 'E-commerce',
          card: {
            cardBrand: 'VISA',
            cardNumber: {
              cardNumber: '901397XXXXXX7594',
              cardNumberId: '5421ea7c-4cc0-48fa-b794-819708080f20'
            }
          },
          merchant: { ...commonMerchData },
          mid: getMockValue({
            type: 'number', stringify: true, min: 111111111111, max: 999999999999
          }),
          purchaseAmount: 1729,
          salesTransactionId: '5bd7-281-0209-49b9-b524-5e89ea404aca',
          status: 'Paid',
          transactionDate: '2020-09-25',
          transactionType: 'Capture'
        }

      },
      {
        txn: {
          achDate: '2020-10-02',
          achTimestamp: '2020-10-18T09:36:57.950Z',
          associationDate: '2020-10-25',
          authorizationAmount: 308.85,
          authorizationCodeSt: 'B4SPSo',
          avsResponseCode: null,
          batchIdFt: '20793406',
          cardEntryMethod: 'E-commerce',
          card: {
            cardBrand: 'MASTERCARD',
            cardNumber: {
              cardNumber: '901397XXXXXX6764',
              cardNumberId: '5421ea7c-4cc0-48fa-b794-819708080f20'
            }
          },
          merchant: { ...commonMerchData },
          mid: getMockValue({
            type: 'number', stringify: true, min: 111111111111, max: 999999999999
          }),
          purchaseAmount: 1729,
          salesTransactionId: '5bd7-281-0209-49b9-b524-5e89ea404aca',
          status: 'Paid',
          transactionDate: '2020-10-25',
          transactionType: 'Capture'
        }

      },
      {
        txn: {
          achDate: '2020-11-02',
          achTimestamp: '2020-11-18T09:36:57.950Z',
          associationDate: '2020-11-25',
          authorizationAmount: 308.85,
          authorizationCodeSt: 'B4SPSo',
          avsResponseCode: null,
          batchIdFt: '20793406',
          cardEntryMethod: 'E-commerce',
          card: {
            cardBrand: 'OTHER',
            cardNumber: {
              cardNumber: '901397XXXXXX7777',
              cardNumberId: '5421ea7c-4cc0-48fa-b794-819708080f20'
            }
          },
          merchant: { ...commonMerchData },
          mid: getMockValue({
            type: 'number', stringify: true, min: 111111111111, max: 999999999999
          }),
          purchaseAmount: 1729,
          salesTransactionId: '5bd7-281-0209-49b9-b524-5e89ea404aca',
          status: 'Paid',
          transactionDate: '2020-11-25',
          transactionType: 'Capture'
        }

      }
    ]
  }],
  pagingInfo: {
    totalNumberOfRecords: 1,
    pageIndex: 1,
    pageSize: 1
  }
};

export const mockTransactionReportTxnDetailsRefundData = {
  transactionTable: [{
    header: [
      {
        order: 1,
        key: 'merchant.mid',
        englishName: 'MID'
      },
      {
        order: 2,
        key: 'merchant.legalName',
        englishName: 'Legal Name'
      },
      {
        order: 3,
        key: 'purchaseAmount',
        englishName: 'Purchase Amount'
      },
      {
        order: 4,
        key: 'authorizationCodeSt',
        englishName: 'Authorization Code'
      },
      {
        order: 5,
        key: 'authorizationAmount',
        englishName: 'Authorization Amount'
      },
      {
        order: 6,
        key: 'transactionDate',
        englishName: 'Transaction Date'
      },
      {
        order: 7,
        key: 'status',
        englishName: 'Status'
      },
      {
        order: 8,
        key: 'achDate',
        englishName: 'ACH Date'
      },
      {
        order: 9,
        key: 'achTimestamp',
        englishName: 'ACH Timestamp'
      },
      {
        order: 10,
        key: 'associationDate',
        englishName: 'Association Date'
      },
      {
        order: 11,
        key: 'card.cardBrand',
        englishName: 'Card Brand'
      },
      {
        order: 12,
        key: 'card.cardNumber.cardNumber',
        englishName: 'Card Number'
      },
      {
        order: 13,
        key: 'transactionType',
        englishName: 'Transaction Type'
      },
      {
        order: 14,
        key: 'cardEntryMethod',
        englishName: 'Card Entry Method'
      },
      {
        order: 15,
        key: 'avsResponseCode',
        englishName: 'AVS Response Code'
      },
      {
        order: 16,
        key: 'batchIdFt',
        englishName: 'Batch ID'
      }
    ],
    data: [
      {
        txn: {
          achDate: '2020-07-02',
          achTimestamp: '2020-07-13T01:56:24.950Z',
          associationDate: '2020-07-25',
          authorizationAmount: -308.85,
          authorizationCodeSt: 'mqfHJg',
          avsResponseCode: null,
          batchIdFt: '21308740',
          cardEntryMethod: 'E-commerce',
          card: {
            cardBrand: 'OTHER',
            cardNumber: {
              cardNumber: '901397XXXXXX7524',
              cardNumberId: '5421ea7c-4cc0-48fa-b794-819708080f20'
            }
          },
          merchant: { ...commonMerchData },
          mid: getMockValue({
            type: 'number', stringify: true, min: 111111111111, max: 999999999999
          }),
          purchaseAmount: 1729,
          refundTransactionId: '5bd7-281-0209-49b9-b524-5e89ea404aca',
          status: 'Paid',
          transactionDate: '2020-07-25',
          transactionType: 'Capture'
        }

      },
      {
        txn: {
          achDate: '2020-08-25',
          achTimestamp: '2020-08-18T09:36:57.950Z',
          associationDate: '2020-08-02',
          authorizationAmount: -308.85,
          authorizationCodeSt: 'mqfHJg',
          avsResponseCode: null,
          batchIdFt: '21308740',
          cardEntryMethod: 'E-commerce',
          card: {
            cardBrand: 'DISCOVER',
            cardNumber: {
              cardNumber: '901397XXXXXX7526',
              cardNumberId: '5421ea7c-4cc0-48fa-b794-819708080f20'
            }
          },
          merchant: { ...commonMerchData },
          mid: getMockValue({
            type: 'number', stringify: true, min: 111111111111, max: 999999999999
          }),
          purchaseAmount: 1729,
          refundTransactionId: '5bd7-281-0209-49b9-b524-5e89ea404aca',
          status: 'Paid',
          transactionDate: '2020-08-25',
          transactionType: 'Capture'
        }

      },
      {
        txn: {
          achDate: '2020-09-02',
          achTimestamp: '2020-09-18T09:36:57.950Z',
          associationDate: '2020-09-25',
          authorizationAmount: -308.85,
          authorizationCodeSt: 'mqfHJg',
          avsResponseCode: null,
          batchIdFt: '21308740',
          cardEntryMethod: 'E-commerce',
          card: {
            cardBrand: 'VISA',
            cardNumber: {
              cardNumber: '901397XXXXXX7594',
              cardNumberId: '5421ea7c-4cc0-48fa-b794-819708080f20'
            }
          },
          merchant: { ...commonMerchData },
          mid: getMockValue({
            type: 'number', stringify: true, min: 111111111111, max: 999999999999
          }),
          purchaseAmount: 1729,
          refundTransactionId: '5bd7-281-0209-49b9-b524-5e89ea404aca',
          status: 'Paid',
          transactionDate: '2020-09-25',
          transactionType: 'Capture'
        }

      },
      {
        txn: {
          achDate: '2020-10-02',
          achTimestamp: '2020-10-18T09:36:57.950Z',
          associationDate: '2020-10-25',
          authorizationAmount: -308.85,
          authorizationCodeSt: 'mqfHJg',
          avsResponseCode: null,
          batchIdFt: '21308740',
          cardEntryMethod: 'E-commerce',
          card: {
            cardBrand: 'MASTERCARD',
            cardNumber: {
              cardNumber: '901397XXXXXX6764',
              cardNumberId: '5421ea7c-4cc0-48fa-b794-819708080f20'
            }
          },
          merchant: { ...commonMerchData },
          mid: getMockValue({
            type: 'number', stringify: true, min: 111111111111, max: 999999999999
          }),
          purchaseAmount: 1729,
          refundTransactionId: '5bd7-281-0209-49b9-b524-5e89ea404aca',
          status: 'Paid',
          transactionDate: '2020-10-25',
          transactionType: 'Capture'
        }

      },
      {
        txn: {
          achDate: '2020-11-02',
          achTimestamp: '2020-11-18T09:36:57.950Z',
          associationDate: '2020-11-25',
          authorizationAmount: -308.85,
          authorizationCodeSt: 'mqfHJg',
          avsResponseCode: null,
          batchIdFt: '21308740',

          cardEntryMethod: 'E-commerce',
          card: {
            cardBrand: 'OTHER',
            cardNumber: {
              cardNumber: '901397XXXXXX7777',
              cardNumberId: '5421ea7c-4cc0-48fa-b794-819708080f20'
            }
          },
          merchant: { ...commonMerchData },
          mid: getMockValue({
            type: 'number', stringify: true, min: 111111111111, max: 999999999999
          }),
          purchaseAmount: 1729,
          refundTransactionId: '5bd7-281-0209-49b9-b524-5e89ea404aca',
          status: 'Paid',
          transactionDate: '2020-11-25',
          transactionType: 'Capture'
        }

      }
    ]
  }],
  pagingInfo: {
    totalNumberOfRecords: 1,
    pageIndex: 1,
    pageSize: 1
  }
};

export const mockTransactionReportTxnDetailsChargebacksData = {
  transactionTable: [{
    header: [
      {
        order: 1,
        key: 'merchant.mid',
        englishName: 'MID'
      },
      {
        order: 2,
        key: 'merchant.legalName',
        englishName: 'Legal Name'
      },
      {
        order: 3,
        key: 'transactionDateCb',
        englishName: 'Transaction Date'
      },
      {
        order: 4,
        key: 'receivedDate',
        englishName: 'Received Date'
      },
      {
        order: 5,
        key: 'transactionTimestampCb',
        englishName: 'Transaction Timestamp'
      },
      {
        order: 6,
        key: 'receivedTimestamp',
        englishName: 'Received Timestamp'
      },
      {
        order: 7,
        key: 'transactionAmount',
        englishName: 'Transaction Amount'
      },
      {
        order: 8,
        key: 'chargebackAmount',
        englishName: 'Chargeback Amount'
      },
      {
        order: 9,
        key: 'chargebackStatus',
        englishName: 'Chargeback Status'
      },
      {
        order: 10,
        key: 'cbReferenceNumber',
        englishName: 'Reference Number'
      },
      {
        order: 11,
        key: 'statusDate',
        englishName: 'Status Date'
      },
      {
        order: 12,
        key: 'card.cardBrand',
        englishName: 'Card Brand'
      },
      {
        order: 13,
        key: 'card.cardNumber.cardNumber',
        englishName: 'Card Number'
      },
      {
        order: 14,
        key: 'chargebackReasonCode',
        englishName: 'Chargeback Reason Code'
      },
      {
        order: 15,
        key: 'descriptionCb',
        englishName: 'Description CB'
      }
    ],
    data: [
      {
        txn: {
          card: {
            cardBrand: 'OTHER',
            cardNumber: {
              cardNumber: '901397XXXXXX7524',
              cardNumberId: '5421ea7c-4cc0-48fa-b794-819708080f20'
            }
          },
          cbReferenceNumber: '28763590562033092227516',
          chargebackAmount: 1729,
          chargebackReasonCode: null,
          chargebackStatus: 'DISPUTE',
          chargebackTransactionId: '5bd7-281-0209-49b9-b524-5e89ea404aca',
          descriptionCb: 'Nonreceipt of Merchandise',
          merchant: { ...commonMerchData },
          mid: getMockValue({
            type: 'number', stringify: true, min: 111111111111, max: 999999999999
          }),
          receivedDate: '2020-07-02',
          receivedTimestamp: '2020-07-18T09:36:57.950Z',
          statusDate: '2020-07-25',
          transactionAmount: -308.85,
          transactionDateCb: '2020-07-25',
          transactionTimestampCb: '2020-07-13T01:56:24.950Z'
        }

      },
      {
        txn: {
          card: {
            cardBrand: 'DISCOVER',
            cardNumber: {
              cardNumber: '901397XXXXXX7526',
              cardNumberId: '5421ea7c-4cc0-48fa-b794-819708080f20'
            }
          },
          cbReferenceNumber: '28763590562033092227555',
          chargebackAmount: 1729,
          chargebackReasonCode: null,
          chargebackStatus: 'Stand',
          chargebackTransactionId: '5bd7-281-0209-49b9-b524-5e89ea404aca',
          descriptionCb: 'Nonreceipt of Merchandise',
          merchant: { ...commonMerchData },
          mid: getMockValue({
            type: 'number', stringify: true, min: 111111111111, max: 999999999999
          }),
          receivedDate: '2020-08-02',
          receivedTimestamp: '2020-08-18T09:36:57.950Z',
          statusDate: '2020-08-25',
          transactionAmount: -308.85,
          transactionDateCb: '2020-08-25',
          transactionTimestampCb: '2020-08-13T01:56:24.950Z'
        }

      },
      {
        txn: {
          card: {
            cardBrand: 'VISA',
            cardNumber: {
              cardNumber: '901397XXXXXX7594',
              cardNumberId: '5421ea7c-4cc0-48fa-b794-819708080f20'
            }
          },
          cbReferenceNumber: '28763590562033092228932',
          chargebackAmount: 1729,
          chargebackReasonCode: null,
          chargebackStatus: 'Stand',
          chargebackTransactionId: '5bd7-281-0209-49b9-b524-5e89ea404aca',
          descriptionCb: 'Nonreceipt of Merchandise',
          merchant: { ...commonMerchData },
          mid: getMockValue({
            type: 'number', stringify: true, min: 111111111111, max: 999999999999
          }),
          receivedDate: '2020-09-02',
          receivedTimestamp: '2020-09-18T09:36:57.950Z',
          statusDate: '2020-09-25',
          transactionAmount: -308.85,
          transactionDateCb: '2020-09-25',
          transactionTimestampCb: '2020-09-13T01:56:24.950Z'
        }

      },
      {
        txn: {
          card: {
            cardBrand: 'MASTERCARD',
            cardNumber: {
              cardNumber: '901397XXXXXX6764',
              cardNumberId: '5421ea7c-4cc0-48fa-b794-819708080f20'
            }
          },
          cbReferenceNumber: '28763590562033092228888',
          chargebackAmount: 1729,
          chargebackReasonCode: null,
          chargebackStatus: 'Stand',
          chargebackTransactionId: '5bd7-281-0209-49b9-b524-5e89ea404aca',
          descriptionCb: 'Nonreceipt of Merchandise',
          merchant: { ...commonMerchData },
          mid: getMockValue({
            type: 'number', stringify: true, min: 111111111111, max: 999999999999
          }),
          receivedDate: '2020-10-02',
          receivedTimestamp: '2020-10-18T09:36:57.950Z',
          statusDate: '2020-10-25',
          transactionAmount: 308.85,
          transactionDateCb: '2020-10-25',
          transactionTimestampCb: '2020-10-13T01:56:24.950Z'
        }

      },
      {
        txn: {
          card: {
            cardBrand: 'OTHER',
            cardNumber: {
              cardNumber: '901397XXXXXX7777',
              cardNumberId: '5421ea7c-4cc0-48fa-b794-819708080f20'
            }
          },
          cbReferenceNumber: '28763590562033092222830',
          chargebackAmount: 1729,
          chargebackReasonCode: null,
          chargebackStatus: 'Stand',
          chargebackTransactionId: '5bd7-281-0209-49b9-b524-5e89ea404aca',
          descriptionCb: 'Nonreceipt of Merchandise',
          merchant: { ...commonMerchData },
          mid: getMockValue({
            type: 'number', stringify: true, min: 111111111111, max: 999999999999
          }),
          receivedDate: '2020-11-02',
          receivedTimestamp: '2020-11-18T09:36:57.950Z',
          statusDate: '2020-11-25',
          transactionAmount: 308.85,
          transactionDateCb: '2020-11-25',
          transactionTimestampCb: '2020-11-13T01:56:24.950Z'
        }

      }
    ]
  }],
  pagingInfo: {
    totalNumberOfRecords: 1,
    pageIndex: 1,
    pageSize: 1
  }
};

export const mockBatchDetailsGet = (body, params, options) => {
  const { guid = '' } = options;
  const mockData = mockTransactionReportTxnDetailsBatchData(
    body,
    params,
    { ...options, mockAllBatchDetails: mockAllBatchDetails() }
  );
  return { [guid]: mockData };
};

export const mockAllBatchDetails = (options) => {
  const length = options?.length || 30;
  const dataElementNameList = [
    { key: 'mid', value: 'MID', mock: { type: 'mid' } },
    { key: 'dba', value: 'DBA', mock: { type: 'string' } },
    { key: 'batchDate', value: 'Batch Date', mock: { type: 'date' } },
    { key: 'batchId', value: 'Batch ID', mock: { type: 'string' } },
    { key: 'txnCountInBatch', value: 'Transaction Count in Batch', mock: { type: 'number' } },
    { key: 'volumeInBatch', value: 'Volume in Batch', mock: { type: 'number', max: 100000 } }
  ];
  return {
    transactionTable: {
      header: generateHeaders(dataElementNameList),
      data: sortData(Array.from({ length }).map((item, index) => {
        const dataRow = createMockTableRow({ index, list: dataElementNameList });
        return dataRow;
      }), 'batchDate', { direction: 'desc' })
    }
  };
};

export const mockTransactionReportTxnDetailsBatchData = (body, params, options) => {
  const {
    totalNumberOfRecords = mockPagingInfo.totalNumberOfRecords,
    pageIndex = mockPagingInfo.pageIndex
  } = params || {};
  const { mockAllBatchDetails: allBatchDetails } = options || {};
  const { transactionTable } = allBatchDetails || {};
  const { data, header } = transactionTable || {};
  const pageData = getMockPageData({
    allData: data,
    pageIndex,
    pageSize: 25,
    optionsCopy: { ...params, pageSize: 25 }
  });
  return {
    transactionTable: {
      header,
      data: pageData
    },
    pagingInfo: {
      totalNumberOfRecords,
      pageIndex,
      pageSize: 25
    }
  };
};

const createMockTableRow = (options) => {
  const { index, list } = options || {};
  return list.reduce((acc, aListItem) => {
    if (aListItem.key === 'dba') {
      return {
        ...acc, [aListItem.key]: `${index} DBA ${getMockValue(aListItem.mock)}`
      };
    }
    return {
      ...acc, [aListItem.key]: getMockValue(aListItem.mock)
    };
  }, {});
};

const generateHeaders = (nameList) => {
  const formattedHeaderList = nameList.map((listItem, index) => (
    { order: index, key: listItem.key, englishName: listItem.value }));
  return formattedHeaderList;
};
