import {
  snakeToTitle,
  formatDateForFEView,
  isEmpty,
  sortData,
  ignoreCase,
  capitalize
} from '../../../_helpers';
import { formatDescriptionText } from '../../../_templateHelpers';

const crabExternalCommunicationTemplate = {
  frontend: (schema, version) => {
    if (version === '1.0') {
      const {
        employeeList,
        externalCommunicationList,
        requestGuidType,
        userEmail,
        userType,
        showMessageLink
      } = schema;
      if (
        isEmpty(externalCommunicationList) || !(externalCommunicationList instanceof Array)
      ) return [];
      const formattedList = externalCommunicationList.map((extComm) => {
        const isTicketView = requestGuidType === 'ticketId';
        const fromUser = getFromUser(extComm, { userType, isTicketView });
        const markAsYou = getMarkAsYou(extComm, {
          userType,
          isTicketView,
          userEmail,
          employeeList
        });
        if (isTicketView) {
          return {
            communicationId: extComm?.ticketExternalCommunicationId,
            description: extComm?.externalCommunicationDescription,
            displayedDescription: formatDescriptionText(extComm?.externalCommunicationDescription),
            timestamp: extComm?.ticketExternalCommunicationCreatedTimestamp,
            from: fromUser,
            markAsYou
          };
        }
        return {
          communicationId: extComm?.applicationExternalCommunicationId,
          description: extComm?.description,
          displayedDescription: formatDescriptionText(extComm?.description),
          timestamp: extComm?.applicationExternalCommunicationCreatedTimestamp,
          from: fromUser,
          showMessageButton: {
            title: 'Pend Message',
            value: showMessageLink ? extComm?.applicationPendId || null : null
          },
          markAsYou,

          applicationPendId: extComm?.applicationPendId || null,
          applicationId: extComm?.applicationId || null
        };
      });
      return sortData(formattedList, 'timestamp', { direction: 'desc' });
    }
    return schema;
  },
  frontendDownloadPackage: (schema, version) => {
    if (version === '1.0') {
      const { backendData } = schema || {};
      const { externalCommunicationList } = backendData || {};
      const formattedData = !isEmpty(externalCommunicationList)
        ? externalCommunicationList.map((backendItem) => {
          const formattedItem = {
            'External Communication ID': backendItem?.applicationExternalCommunicationId,
            'Pend ID': backendItem?.applicationId,
            'Created Timestamp': formatDateForFEView(backendItem?.applicationExternalCommunicationCreatedTimestamp, { includeTime: true }),
            Description: backendItem?.description,
            'Sent By - Employee First Name': backendItem?.employeeWhoCreated?.firstName,
            'Sent By - Employee Email': backendItem?.employeeWhoCreated?.email,
            'Sent By - Employee Department': snakeToTitle(backendItem?.employee?.department || ''),
            'Sent By - Employee ID': backendItem?.employee?.employeeId,
            'Sent By - Portal User Email': backendItem?.portalUser?.email,
            'Sent By - Portal User ID': backendItem?.portalUser?.portalUserId
          };
          return formattedItem;
        })
        : [{ 'No external communications found': '' }];
      return {
        sheets: [
          { title: 'External Communications', data: sortData(formattedData, 'Created Timestamp', { direction: 'desc' }) }
        ]
      };
    }
    return schema;
  },
  backend: (schema, version) => {
    if (version === '1.0') {
      const { description } = schema;
      return {
        description
      };
    }
    return schema;
  }
};

const getMarkAsYou = (data, options) => {
  const { employee, portalUser } = data || {};
  const {
    employeeList,
    userEmail,
    userType,
    isTicketView
  } = options || {};
  const isEmployee = userType === 'employee';
  if (isTicketView) {
    if (isEmployee) {
      const employeeMatch = (employeeList || []).find(emp => (
        emp.email === userEmail
      )) || {};
      return ( // Employee tickets data returns `employeeId` (instead of `email`)
        !isEmpty(employee?.employeeId) && employeeMatch?.employeeId === employee?.employeeId
      ) || false;
    }
  }
  return ignoreCase(userEmail) === ignoreCase(employee?.email || '') ||
    ignoreCase(userEmail) === ignoreCase(portalUser?.email || '');
};

const getFromUser = (data, options) => {
  const { employee, portalUser } = data || {};
  const { isTicketView, userType } = options || {};
  const isPortalUser = userType === 'partner';
  if (isTicketView) {
    return portalUser?.email || capitalize(ignoreCase(employee?.firstName || ''));
  }
  const deptEnumToTitleMap = {
    app_review: 'App Review',
    credit: 'Credit',
    operations: 'Operations',
    operations_legacy: 'Operations'
  };
  const deptEnum = ignoreCase(employee?.department || '');
  const fromUser = isPortalUser
    ? deptEnumToTitleMap[deptEnum] || snakeToTitle(deptEnum)
    : capitalize(ignoreCase(employee?.firstName || ''));
  return !isEmpty(portalUser) ? portalUser?.email : fromUser;
};

export default crabExternalCommunicationTemplate;
