import {
  relationshipGuids
} from '../guids';

export const mockRelationshipHealthGet = {
  [relationshipGuids.default]: {
    healthyCount: 1,
    healthyMerchants: [{
      legalName: 'testLegalName',
      mids: [{
        mid: 'yrLSuhxhE8sDhqd',
        dbaName: 'Merchant with Transaction Report Data',
        merchantEmail: 'f1paymentsengineering+yrlsuhxhe8sdhqd@gmail.com',
        merchantPhoneNumber: '+15125536624',
        partnerName: 'Partner with Transaction Report Data',
        partnerEmail: 'createtestusershandler_partnertransactionreportdata@gmail.com',
        partnerPhoneNumber: '+15125536624',
        relationshipCode: '12345'
      }],
      visa: {
        salesCount: 2,
        chargebackCount: 2,
        chargebackRatio: 1
      },
      mastercard: {
        salesCount: 2,
        chargebackCount: 2,
        chargebackRatio: 1
      },
      total: {
        salesCount: 2,
        chargebackCount: 2,
        chargebackRatio: 1
      }
    }],
    inDangerCount: 1,
    inDangerMerchants: [{
      legalName: 'testLegalName',
      mids: [{
        mid: 'yrLSuhxhE8sDhqd',
        dbaName: 'Merchant with Transaction Report Data',
        merchantEmail: 'f1paymentsengineering+yrlsuhxhe8sdhqd@gmail.com',
        merchantPhoneNumber: '+15125536624',
        partnerName: 'Partner with Transaction Report Data',
        partnerEmail: 'createtestusershandler_partnertransactionreportdata@gmail.com',
        partnerPhoneNumber: '+15125536624',
        relationshipCode: '12345'
      }],
      visa: {
        salesCount: 2,
        chargebackCount: 2,
        chargebackRatio: 1
      },
      mastercard: {
        salesCount: 2,
        chargebackCount: 2,
        chargebackRatio: 1
      },
      total: {
        salesCount: 2,
        chargebackCount: 2,
        chargebackRatio: 1
      }
    }],
    onProgramCount: 1,
    onProgramMerchants: [{
      legalName: 'testLegalName',
      mids: [{
        mid: 'yrLSuhxhE8sDhqd',
        dbaName: 'Merchant with Transaction Report Data',
        merchantEmail: 'f1paymentsengineering+yrlsuhxhe8sdhqd@gmail.com',
        merchantPhoneNumber: '+15125536624',
        partnerName: 'Partner with Transaction Report Data',
        partnerEmail: 'createtestusershandler_partnertransactionreportdata@gmail.com',
        partnerPhoneNumber: '+15125536624',
        relationshipCode: '12345'
      }],
      visa: {
        salesCount: 2,
        chargebackCount: 2,
        chargebackRatio: 1
      },
      mastercard: {
        salesCount: 2,
        chargebackCount: 2,
        chargebackRatio: 1
      },
      total: {
        salesCount: 2,
        chargebackCount: 2,
        chargebackRatio: 1
      }
    }],
    unhealthyCount: 1,
    unhealthyMerchants: [{
      legalName: 'testLegalName',
      mids: [{
        mid: 'yrLSuhxhE8sDhqd',
        dbaName: 'Merchant with Transaction Report Data',
        merchantEmail: 'f1paymentsengineering+yrlsuhxhe8sdhqd@gmail.com',
        merchantPhoneNumber: '+15125536624',
        partnerName: 'Partner with Transaction Report Data',
        partnerEmail: 'createtestusershandler_partnertransactionreportdata@gmail.com',
        partnerPhoneNumber: '+15125536624',
        relationshipCode: '12345'
      }],
      visa: {
        salesCount: 2,
        chargebackCount: 2,
        chargebackRatio: 1
      },
      mastercard: {
        salesCount: 2,
        chargebackCount: 2,
        chargebackRatio: 1
      },
      total: {
        salesCount: 2,
        chargebackCount: 2,
        chargebackRatio: 1
      }
    }]
  }
};

export const mockRelationshipHealthFormatted = {
  healthyCount: mockRelationshipHealthGet[relationshipGuids.default].healthyCount,
  healthyMerchants: [{
    mids: [{
      legalName: mockRelationshipHealthGet[relationshipGuids.default].healthyMerchants[0].legalName,
      status: 'healthyMerchants',
      mid: mockRelationshipHealthGet[relationshipGuids.default].healthyMerchants[0].mids[0].mid,
      dbaName:
        mockRelationshipHealthGet[relationshipGuids.default].healthyMerchants[0].mids[0].dbaName,
      merchantEmail:
          mockRelationshipHealthGet[relationshipGuids.default]
            .healthyMerchants[0].mids[0].merchantEmail,
      merchantPhoneNumber:
          mockRelationshipHealthGet[relationshipGuids.default]
            .healthyMerchants[0].mids[0].merchantPhoneNumber,
      partnerName:
          mockRelationshipHealthGet[relationshipGuids.default]
            .healthyMerchants[0].mids[0].partnerName,
      partnerEmail:
          mockRelationshipHealthGet[relationshipGuids.default]
            .healthyMerchants[0].mids[0].partnerEmail,
      partnerPhoneNumber:
          mockRelationshipHealthGet[relationshipGuids.default]
            .healthyMerchants[0].mids[0].partnerPhoneNumber,
      relationshipCode:
          mockRelationshipHealthGet[relationshipGuids.default]
            .healthyMerchants[0].mids[0].relationshipCode,
      visaSalesCount: mockRelationshipHealthGet[relationshipGuids.default]
        .healthyMerchants[0].visa.salesCount,
      visaChargebackCount: mockRelationshipHealthGet[relationshipGuids.default]
        .healthyMerchants[0].visa.chargebackCount,
      visaChargebackRatio: mockRelationshipHealthGet[relationshipGuids.default]
        .healthyMerchants[0].visa.chargebackRatio,
      mastercardSalesCount: mockRelationshipHealthGet[relationshipGuids.default]
        .healthyMerchants[0].mastercard.salesCount,
      mastercardChargebackCount:
          mockRelationshipHealthGet[relationshipGuids.default]
            .healthyMerchants[0].mastercard.chargebackCount,
      mastercardChargebackRatio:
          mockRelationshipHealthGet[relationshipGuids.default]
            .healthyMerchants[0].mastercard.chargebackRatio,
      totalSalesCount:
          mockRelationshipHealthGet[relationshipGuids.default]
            .healthyMerchants[0].total.salesCount,
      totalChargebackCount:
          mockRelationshipHealthGet[relationshipGuids.default]
            .healthyMerchants[0].total.chargebackCount,
      totalChargebackRatio:
          mockRelationshipHealthGet[relationshipGuids.default]
            .healthyMerchants[0].total.chargebackRatio
    }]
  }],
  inDangerCount: mockRelationshipHealthGet[relationshipGuids.default].inDangerCount,
  inDangerMerchants: [{
    mids: [{
      legalName:
        mockRelationshipHealthGet[relationshipGuids.default].inDangerMerchants[0].legalName,
      status: 'inDanger',
      mid: mockRelationshipHealthGet[relationshipGuids.default].inDangerMerchants[0].mids[0].mid,
      dbaName:
        mockRelationshipHealthGet[relationshipGuids.default].inDangerMerchants[0].mids[0].dbaName,
      merchantEmail:
          mockRelationshipHealthGet[relationshipGuids.default]
            .inDangerMerchants[0].mids[0].merchantEmail,
      merchantPhoneNumber:
          mockRelationshipHealthGet[relationshipGuids.default]
            .inDangerMerchants[0].mids[0].merchantPhoneNumber,
      partnerName:
          mockRelationshipHealthGet[relationshipGuids.default]
            .inDangerMerchants[0].mids[0].partnerName,
      partnerEmail:
          mockRelationshipHealthGet[relationshipGuids.default]
            .inDangerMerchants[0].mids[0].partnerEmail,
      partnerPhoneNumber:
          mockRelationshipHealthGet[relationshipGuids.default]
            .inDangerMerchants[0].mids[0].partnerPhoneNumber,
      relationshipCode:
          mockRelationshipHealthGet[relationshipGuids.default]
            .inDangerMerchants[0].mids[0].relationshipCode,
      visaSalesCount: mockRelationshipHealthGet[relationshipGuids.default]
        .inDangerMerchants[0].visa.salesCount,
      visaChargebackCount: mockRelationshipHealthGet[relationshipGuids.default]
        .inDangerMerchants[0].visa.chargebackCount,
      visaChargebackRatio: mockRelationshipHealthGet[relationshipGuids.default]
        .inDangerMerchants[0].visa.chargebackRatio,
      mastercardSalesCount: mockRelationshipHealthGet[relationshipGuids.default]
        .inDangerMerchants[0].mastercard.salesCount,
      mastercardChargebackCount:
          mockRelationshipHealthGet[relationshipGuids.default]
            .inDangerMerchants[0].mastercard.chargebackCount,
      mastercardChargebackRatio:
          mockRelationshipHealthGet[relationshipGuids.default]
            .inDangerMerchants[0].mastercard.chargebackRatio,
      totalSalesCount:
          mockRelationshipHealthGet[relationshipGuids.default]
            .inDangerMerchants[0].total.salesCount,
      totalChargebackCount:
          mockRelationshipHealthGet[relationshipGuids.default]
            .inDangerMerchants[0].total.chargebackCount,
      totalChargebackRatio:
          mockRelationshipHealthGet[relationshipGuids.default]
            .inDangerMerchants[0].total.chargebackRatio
    }]
  }],
  onProgramCount: mockRelationshipHealthGet[relationshipGuids.default].onProgramCount,
  onProgramMerchants: [{
    mids: [{
      legalName:
        mockRelationshipHealthGet[relationshipGuids.default].onProgramMerchants[0].legalName,
      status: 'onAProgram',
      mid: mockRelationshipHealthGet[relationshipGuids.default].onProgramMerchants[0].mids[0].mid,
      dbaName:
        mockRelationshipHealthGet[relationshipGuids.default].onProgramMerchants[0].mids[0].dbaName,
      merchantEmail:
          mockRelationshipHealthGet[relationshipGuids.default]
            .onProgramMerchants[0].mids[0].merchantEmail,
      merchantPhoneNumber:
          mockRelationshipHealthGet[relationshipGuids.default]
            .onProgramMerchants[0].mids[0].merchantPhoneNumber,
      partnerName:
          mockRelationshipHealthGet[relationshipGuids.default]
            .onProgramMerchants[0].mids[0].partnerName,
      partnerEmail:
          mockRelationshipHealthGet[relationshipGuids.default]
            .onProgramMerchants[0].mids[0].partnerEmail,
      partnerPhoneNumber:
          mockRelationshipHealthGet[relationshipGuids.default]
            .onProgramMerchants[0].mids[0].partnerPhoneNumber,
      relationshipCode:
          mockRelationshipHealthGet[relationshipGuids.default]
            .onProgramMerchants[0].mids[0].relationshipCode,
      visaSalesCount: mockRelationshipHealthGet[relationshipGuids.default]
        .onProgramMerchants[0].visa.salesCount,
      visaChargebackCount: mockRelationshipHealthGet[relationshipGuids.default]
        .onProgramMerchants[0].visa.chargebackCount,
      visaChargebackRatio: mockRelationshipHealthGet[relationshipGuids.default]
        .onProgramMerchants[0].visa.chargebackRatio,
      mastercardSalesCount: mockRelationshipHealthGet[relationshipGuids.default]
        .onProgramMerchants[0].mastercard.salesCount,
      mastercardChargebackCount:
          mockRelationshipHealthGet[relationshipGuids.default]
            .onProgramMerchants[0].mastercard.chargebackCount,
      mastercardChargebackRatio:
          mockRelationshipHealthGet[relationshipGuids.default]
            .onProgramMerchants[0].mastercard.chargebackRatio,
      totalSalesCount:
          mockRelationshipHealthGet[relationshipGuids.default]
            .onProgramMerchants[0].total.salesCount,
      totalChargebackCount:
          mockRelationshipHealthGet[relationshipGuids.default]
            .onProgramMerchants[0].total.chargebackCount,
      totalChargebackRatio:
          mockRelationshipHealthGet[relationshipGuids.default]
            .onProgramMerchants[0].total.chargebackRatio
    }]
  }],
  unhealthyCount: mockRelationshipHealthGet[relationshipGuids.default].unhealthyCount,
  unhealthyMerchants: [{
    mids: [{
      legalName: mockRelationshipHealthGet[relationshipGuids.default]
        .unhealthyMerchants[0].legalName,
      status: 'closeToClosure',
      mid: mockRelationshipHealthGet[relationshipGuids.default].unhealthyMerchants[0].mids[0].mid,
      dbaName:
        mockRelationshipHealthGet[relationshipGuids.default].unhealthyMerchants[0].mids[0].dbaName,
      merchantEmail:
          mockRelationshipHealthGet[relationshipGuids.default]
            .unhealthyMerchants[0].mids[0].merchantEmail,
      merchantPhoneNumber:
          mockRelationshipHealthGet[relationshipGuids.default]
            .unhealthyMerchants[0].mids[0].merchantPhoneNumber,
      partnerName:
          mockRelationshipHealthGet[relationshipGuids.default]
            .unhealthyMerchants[0].mids[0].partnerName,
      partnerEmail:
          mockRelationshipHealthGet[relationshipGuids.default]
            .unhealthyMerchants[0].mids[0].partnerEmail,
      partnerPhoneNumber:
          mockRelationshipHealthGet[relationshipGuids.default]
            .unhealthyMerchants[0].mids[0].partnerPhoneNumber,
      relationshipCode:
          mockRelationshipHealthGet[relationshipGuids.default]
            .unhealthyMerchants[0].mids[0].relationshipCode,
      visaSalesCount: mockRelationshipHealthGet[relationshipGuids.default]
        .unhealthyMerchants[0].visa.salesCount,
      visaChargebackCount: mockRelationshipHealthGet[relationshipGuids.default]
        .unhealthyMerchants[0].visa.chargebackCount,
      visaChargebackRatio: mockRelationshipHealthGet[relationshipGuids.default]
        .unhealthyMerchants[0].visa.chargebackRatio,
      mastercardSalesCount: mockRelationshipHealthGet[relationshipGuids.default]
        .unhealthyMerchants[0].mastercard.salesCount,
      mastercardChargebackCount:
          mockRelationshipHealthGet[relationshipGuids.default]
            .unhealthyMerchants[0].mastercard.chargebackCount,
      mastercardChargebackRatio:
          mockRelationshipHealthGet[relationshipGuids.default]
            .unhealthyMerchants[0].mastercard.chargebackRatio,
      totalSalesCount:
          mockRelationshipHealthGet[relationshipGuids.default]
            .unhealthyMerchants[0].total.salesCount,
      totalChargebackCount:
          mockRelationshipHealthGet[relationshipGuids.default]
            .unhealthyMerchants[0].total.chargebackCount,
      totalChargebackRatio:
          mockRelationshipHealthGet[relationshipGuids.default]
            .unhealthyMerchants[0].total.chargebackRatio
    }]
  }]
};

export const mockRelationshipHealthGetRes = (body, params, options) => {
  const { guid = `"{}"` } = options || {};
  const mockData = mockRelationshipHealthGet[guid] ||
    mockRelationshipHealthGet[relationshipGuids.default];
  // defaults to the "all" tickets data if the guid isn't in the map
  return { [guid]: mockData };
};
