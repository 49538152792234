import {
  capitalize,
  ignoreCase,
  isEmpty
} from '../../../_helpers';
import { toFrontendValue } from '../../../_templateHelpers';
import crabExternalCommunicationTemplate from './crabExternalCommunicationTemplate';
import { crabTaskPendFieldsPost } from '../../../_crabFields';

const taskPendDetailTemplate = {
  frontend: (schema, version) => {
    if (version === '1.0') {
      const { pendFields, pendExternalCommunications, userType } = schema;
      if (isEmpty(pendFields)) return schema;
      const pendStatus = ignoreCase(pendFields?.pendStatus || '');
      const negativeActionInputList = !isEmpty(pendFields?.negativeActionList)
        ? pendFields?.negativeActionList
          .reduce((acc, action) => ([...acc, `${action.categoryValue} → ${action.subCategoryValue}`]), []).join(', ')
        : [];
      const pendDetails = {
        formFields: {
          description: toFrontendValue(pendFields?.description, crabTaskPendFieldsPost.description),
          pendStatus: toFrontendValue(pendStatus, crabTaskPendFieldsPost.pendStatus),
          ...(userType === 'employee' && {
            requiresNewSignature: toFrontendValue(
              pendFields?.requiresNewSignature || false,
              crabTaskPendFieldsPost.requiresNewSignature
            )
          })
        },
        infoFields: {
          createdBy: userType === 'employee'
            ? capitalize(ignoreCase(pendFields?.employeeWhoCreated?.firstName || '-'))
            : capitalize(ignoreCase(pendFields?.employeeWhoCreated?.department)),
          pendId: pendFields?.applicationPendId,
          ...(userType === 'employee' && { taskId: pendFields?.applicationTaskId }),
          negativeActionList: negativeActionInputList, // value for inputbox
          negativeActionInputList, // list for inputbox
          created: pendFields?.pendCreatedTimestamp,
          closed: pendFields?.pendClosedTimestamp
        },
        otherData: {
          pendExternalCommunications: crabExternalCommunicationTemplate.frontend({
            externalCommunicationList: pendExternalCommunications
          }, '1.0'),
          published: pendFields?.published
        }
      };
      return pendDetails;
    }
    return schema;
  }
};

export default taskPendDetailTemplate;
