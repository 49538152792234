import React from 'react';
import PropTypes from 'prop-types';
import { pageContentCSS } from './_styles';

export class PageContent extends React.PureComponent {
  render () {
    const { children, customStyle, fullWidth } = this.props;
    return (
      <div
        className="pageContent"
        style={
          { ...pageContentCSS.base, ...(fullWidth && { maxWidth: '100%' }), ...customStyle }
        }
      >
        {children}
      </div>
    );
  }
}

PageContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]),
  customStyle: PropTypes.oneOfType([PropTypes.object]),
  fullWidth: PropTypes.bool

};
PageContent.defaultProps = {
  children: null,
  customStyle: {},
  fullWidth: false
};

export default PageContent;
