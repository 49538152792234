import { pad } from '@f1/shared/src/_helpers';

export const merchantGuids = {
  default: 'e6af3315-0fb7-4f43-ab1a-61a34900e804', // Merchant with Transaction Report Data
  closed: 'closed-merchant-guid-1', // Closed Merchant Account
  merchant01: 'GuidType_sqlTest_merchant', // ZL2xIwX4Du9R9Bl (Repay processor)
  merchant03: 'merchant-guid-3', // Fake merchant created for unit test
  merchant04: 'mock-netevia-merchant-1', // Mock Netevia Merchant 1
  merchant05: 'mock-netevia-merchant-elevated', // Mock Netevia Merchant elevated
  merchant06: 'mock-priority-axiom-elevated-merchant', // Mock Merchant - Priority / Axiom / Elevated
  merchant07: 'mock-priority-wells-fargo-elevated-merchant', // Mock Merchant - Priority / Wells Fargo / Elevated
  merchant08: 'mock-micamp-mavric-elevated-merchant', // Mock Micamp - Elevated
  merchant09: 'mock-repay-merchant-1' // Mock Repay Merchant 1
};

export const partnerGuids = {
  all: `"{}"`, // get data: all partners
  default: '0d4fc94e-2874-46d3-a091-addb5fe8f2e7', // Partner with Transaction Report Data
  partner02: 'c4ee47aa-a783-4198-a15e-0c6c646b53e2', // 100
  partner03: 'mock-partner-guid-03', // Mock Partner with Extraordinarily Long Name
  partner04: '3d8ab314-70f1-465f-9726-85b54c2d0c3e', // GetGuidDaoSqlTest_46HFHCVl_0
  partner05: 'ft_createRelationship_ppid_2', // CrmV1PutRelationshipHandlerDefinitions_bNhaZvnX_6
  partner06: 'ft_createRelationship_pid_2', // CrmV1PutRelationshipHandlerDefinitions_bNhaZvnX_5
  partner07: 'CrmV2GetRes_partnerId_1', // CrmV2GetReservesHandlerDefinitions_kMT4uqYQ_0
  partner08: '9ff9fffc-3c57-4988-8c28-fea42e2d35c2',
  partner09: '37b887c6-251e-4544-9f58-a2c5e70fcea1' // FE - Partner with no crab config options
};

export const relationshipGuids = {
  default: '21912d04-21a1-4ad3-a075-eb2f1c5f7ea8', // Relationship with Transaction Report Data
  relationship02: '96be2bda-7249-46af-88db-c4d7822aef8c', // 0CsKQFgXSYEb4XH
  relationship03: 'ft_createRelationship_rid_2', // VLtC1vgFn7OpZFR
  relationship04: '6179b932-db4a-4ffe-8727-079cb6b9395d', // CrmCreateRelationshipFT
  relationship05: 'ChargebackDaoSqlTest_relationshipId', // 02X60txdbGGHNSJ
  relationship06: 'mock-relationship-guid-06', // Mock Relationship with Extraordinarily Long Name
  relationship07: '6265d4b8-f259-4425-bfe2-7d26a35283b4', // ufiHqFshpF
  relationship08: 'CrmV2GetRes_relationshipId_11', // CrmV2GetRes_relationshipName_11
  relationship09: 'CrmV2GetRes_relationshipId_13', // CrmV2GetRes_relationshipName_13
  relationship10: 'CrmV2GetRes_relationshipId_12', // CrmV2GetRes_relationshipName_12
  relationship11: 'mock-preferred-repay-relationship-1', // Mock Preferred Risk - Repay Relationship
  relationship12: 'mock-netevia-evolve-relationship-1', // Mock Netevia Evolve Relationship
  relationship13: 'mock-netevia-esquire-relationship-1', // Mock Netevia Esquire Relationship
  relationship14: 'mock-elevated-repay-relationship-1', // Mock Elevated Risk - Repay Relationship
  relationship15: '17f4bbdf-c436-4cc0-b404-665521bf479b', // FE - Netevia Elevated
  relationship20: 'ee9ba2a9-60e7-4653-9411-17fc08e6f514', // FE - Repay Elevated - Fluid Pay
  relationship21: '719be7ed-5801-49e9-acc7-d6ba8c96945d', // FE - Micamp Elevated - Mavric
  relationship22: '0876ef90-9245-47ad-8813-3a9ad1e40604', // FE - Priority / Elevated
  relationship23: '43334724-d030-40f2-bd63-ed83b53d0e54', // FE - Priority / Axiom / Elevated
  relationship24: 'cc7d8717-aa0d-4757-8fd0-1fb4a140c9d1', // FE - Priority / Wells Fargo / Elevated
  relationship25: 'c1b5519e-6515-46e9-9b9b-840b3df0adb1' // FE - Repay/Preferred - no crab config options
};

// hey you modifying this code, are you adding an employee group?
// if so, please check out this PR to make sure you are adding it everywhere needed
// BIRB 6957. Please add in all places that PR adds to
export const employeeGuids = {
  default: 'ace00001-7454-11ea-bc55-0242ac177781', // Taylor Swift
  employee01: 'ace00001-7454-11ea-bc55-0242ac177781', // Taylor Swift
  employee02: 'ace00002-7454-11ea-bc55-0242ac177782', // James Taylor
  employee03: 'ace00003-7454-11ea-bc55-0242ac177783', // Jesse James
  employee04: 'ace00004-7454-11ea-bc55-0242ac177784', // Musashi Miyamoto
  employee05: 'ace00005-7454-11ea-bc55-0242ac177785', // Sasaki Kojiro
  employee06: 'ace00006-7454-11ea-bc55-0242ac177786', // Shingen Takeda
  employee07: 'ace00007-7454-11ea-bc55-0242ac177787', // George Marooney
  employee08: 'ace00007-7454-11ea-bc55-0242ac177788', // Claudio Sanchez,
  employee09: 'ace00007-7454-11ea-bc55-0242ac177789', // Marty Byrde
  employee10: '21da3249-27ea-4657-9dd0-0ebfcb3ee6c1', // Bruce Wayne
  employee11: 'ae812346-9547-4132-8438-fee2ce2606ae', // Fred Flintstone
  employee12: '2e0a6bb0-e9b9-4188-93ff-395962e82d6a', // Barney Fife
  employee13: 'mock-employee-id-13', // Bob Barker
  employee14: '931f8675-9aaa-428d-880f-05e44389a0fd', // Jerry's Player
  employee15: '4790a3e6-5378-4dc8-9ebb-60206aa09ec8', // Jerry Maguire
  employee16: 'mock-employee-id-16', // Yankee Doodle
  employee17: 'mock-employee-id-17', // User Disabled
  employee18: 'mock-employee-id-18' // User Disabled
};

export const ticketGuids = {
  default: 't000001-3456-4d08-882f-bc65dde39801',
  ticket01: 't000001-3456-4d08-882f-bc65dde39801',
  ticket02: 't000001-3456-4d08-882f-bc65dde39802',
  ticket03: 't000001-3456-4d08-882f-bc65dde39803',
  ticket04: 't000001-3456-4d08-882f-bc65dde39804',
  ticket05: 't000001-3456-4d08-882f-bc65dde39805',
  ticket06: 't000001-3456-4d08-882f-bc65dde39806',
  ticket07: 't000001-3456-4d08-882f-bc65dde39807',
  ticket08: 't000001-3456-4d08-882f-bc65dde39808',
  ticket09: 't000001-3456-4d08-882f-bc65dde39809',
  ticket10: 't000001-3456-4d08-882f-bc65dde39810',
  ticket11: 't000001-3456-4d08-882f-bc65dde39811',
  ticket12: 't000001-3456-4d08-882f-bc65dde39812',
  ticket13: 't000001-3456-4d08-882f-bc65dde39813',
  ticket14: 't000001-3456-4d08-882f-bc65dde39814',
  ticket15: 't000001-3456-4d08-882f-bc65dde39815',
  ticket16: 't000001-3456-4d08-882f-bc65dde39816',
  ticket17: 't000001-3456-4d08-882f-bc65dde39817',
  all: `"{}"`, // get all tickets
  merchantGuid: merchantGuids.default,
  partnerId: partnerGuids.default,
  relationshipId: relationshipGuids.default
};

export const highRiskAppGuids = (i, options) => {
  // will dynamically create a mock guid or a map of mock guids
  const {
    type = '', // one of: '', 'task', or 'note'
    guidOnly = false,
    guidMap = {}
  } = options || {};
  const padded = pad(i);
  const guidType = type ? `${type}-` : '';
  const mockGuid = `high-risk-app-${guidType}guid-${padded}`;
  if (guidOnly) { // if we only want the guid to be returned
    return mockGuid;
  }
  if (i > 0) { // dynamically creates guid map
    const newGuid = { [`app${padded}`]: mockGuid };
    return {
      ...guidMap,
      ...newGuid,
      ...highRiskAppGuids(i - 1, { ...options, guidMap: { ...guidMap, ...newGuid } })
    };
  }
  return guidMap;
};

export const alertGuids = {
  default: 'a0000000-0000-0000-0000-000000000001',
  alert02: 'a0000000-0000-0000-0000-000000000002',
  alert03: 'a0000000-0000-0000-0000-000000000003',
  alert04: 'a0000000-0000-0000-0000-000000000004',
  alert05: 'a0000000-0000-0000-0000-000000000005',
  alert06: 'a0000000-0000-0000-0000-000000000006',
  alert07: 'a0000000-0000-0000-0000-000000000007',
  alert08: 'a0000000-0000-0000-0000-000000000008',
  alert09: 'a0000000-0000-0000-0000-000000000009',
  alert10: 'a0000000-0000-0000-0000-000000000010',
  alert11: 'a0000000-0000-0000-0000-000000000011'
};

export const riskRuleGuids = {
  default: 'r0000000-0000-0000-0000-000000000000',
  riskRule01: 'r0000000-0000-0000-0000-000000000001',
  riskRule02: 'r0000000-0000-0000-0000-000000000002',
  riskRule03: 'r0000000-0000-0000-0000-000000000003',
  riskRule04: 'r0000000-0000-0000-0000-000000000004',
  riskRule05: 'r0000000-0000-0000-0000-000000000005'
};

export const riskCategoryGuids = {
  default: 'rc000-00000-00',
  riskCategory01: 'rc000-00000-01'
};

export const residualTaskGuids = {
  default: '0000-0000-00000-00000',
  residualTask01: '0000-0000-00000-00001',
  residualTask02: '0000-0000-00000-00002',
  residualTask03: '0000-0000-00000-00003',
  residualTask04: '0000-0000-00000-00004',
  residualTask05: '0000-0000-00000-00005',
  residualTask06: '0000-0000-00000-00006',
  residualTask07: '0000-0000-00000-00007',
  residualTask08: '0000-0000-00000-00008',
  residualTask09: '0000-0000-00000-00009',
  residualTask10: '0000-0000-00000-00010',
  residualTask11: '0000-0000-00000-00011',
  residualTask12: '0000-0000-00000-00012'
};
