export const mockAllTimeHistoryV2 = {
  salesCardPresentRatio: {
    monthToDate: {
      cardPresent: 0.25,
      cardNotPresent: 0.75
    },
    previousMonth: {
      cardPresent: 0.5,
      cardNotPresent: 0.5
    },
    allTime: {
      cardPresent: 0.75,
      cardNotPresent: 0.25
    }
  },
  authorizationApprovedRatio: {
    monthToDate: {
      approved: 0.63,
      declined: 0.37
    },
    previousMonth: {
      approved: 0.81,
      declined: 0.19
    },
    allTime: {
      approved: 0.01,
      declined: 0.99
    }
  },
  foreignCardTransactionRatio: {
    monthToDate: {
      domestic: 0.98,
      foreign: 0.02
    },
    previousMonth: {
      domestic: 0.71,
      foreign: 0.29
    },
    allTime: {
      domestic: 0.45,
      foreign: 0.55
    }
  }
};

export const mockAllTimeHistoryV2GetRes = (body, params, options) => {
  const { guid = ['empty'] } = options;
  return {
    [guid]: mockAllTimeHistoryV2
  };
};

export default mockAllTimeHistoryV2;
