import { apiCall } from './_api';
import {
  getType,
  getQueryParamString,
  envIsLocalOnly,
  isEmpty,
  useStubData
} from './_helpers';

export const apiGetAllPageData = async (options, utils, stubDataOptions) => {
  // when using pagingInfo (pageSize and pageIndex) in query params for api call,
  // this will make ALL axiosRequest to get all data for all pages

  // If multiPageCall is passed in, we start the pageIndex as 1. NOTE: We have to call this method
  // twice in the parent.
  const {
    dataKey = '', // REQUIRED IF the actual data object in the API response is the only data point
    useResponseKeys = false, // Use IF the data object in the API res has multiple data points
    axiosRequestOptions = { // REQUIRED - the same options used when calling axiosRequest(options)
      fullPageLoad: false,
      url: '',
      method: 'get',
      tokenRequired: true
    },
    firstPage = false,
    multiCall = false,
    pageQueryParams = { // pagingInfo URI params
      // OPTIONAL - we currently fetch ALL records at once, but this supports custom page fetching
      totalNumberOfRecords: null,
      pageIndex: !firstPage && multiCall ? 1 : 0,
      pageSize: (envIsLocalOnly() && useStubData)
        ? 10
        : 1500 // can pass a higher num if we want more records at once
    },
    customQueryParams = [
      // include any additional (NON-paging info) params objects here,
      // which should include 'key' and 'value' properties
      // example: { key: <param_key_name>, value: <param_value> }
    ],
    currentData = [], // OR {} if using `useResponseKeys`
    ...rest
  } = options || {};
  const pagingInfoQueryParams = [
    // pagingInfo for the URI params for every api call that uses it
    { key: 'pageIndex', value: pageQueryParams.pageIndex || 0 },
    { key: 'pageSize', value: pageQueryParams.pageSize }
  ];
  const queryParams = [
    ...customQueryParams, // additional URI params must be FIRST
    ...pagingInfoQueryParams
  ];
  const paramString = getQueryParamString(queryParams);
  const apiRes = await apiCall({
    ...axiosRequestOptions,
    ...(envIsLocalOnly() && useStubData && {
      stubData: stubDataOptions?.stubData || null,
      config: {
        params: queryParams.reduce((acc, obj) => ({ ...acc, [obj.key]: obj.value }), {})
      }
    }),
    url: `${axiosRequestOptions.url}${paramString}`
  }, utils);
  if (!isEmpty(apiRes.data?.pagingInfo) ||
    (!isEmpty(currentData) && apiRes?.errorDetails instanceof Error)
  ) {
    if (apiRes?.errorDetails instanceof Error) {
      // if making multiple axiosRequest calls and one errors, this will return
      // the response and the current data for calls that did succeed
      return {
        ...rest,
        ...apiRes
      };
    }
    const {
      pagingInfo: apiPagingInfo = {},
      ...apiData // rest is the actual api data & should be returned as a key/value pair
    } = apiRes.data || {};
    const hasMoreRecords = (apiPagingInfo.totalNumberOfRecords -
      ((apiPagingInfo.pageIndex + 1) * pageQueryParams.pageSize)) > 0;
    const mergedData = getMergedData(dataKey, apiData, { useResponseKeys, prevData: currentData });
    const dataResponse = {
      ...(useResponseKeys ? { allPagedDataObjects: mergedData } : { [dataKey]: mergedData })
    };
    if (hasMoreRecords) {
      if (firstPage && multiCall) {
        return {
          ...apiRes,
          ...rest,
          data: {
            ...dataResponse,
            pagingInfo: apiPagingInfo
          }
        };
      }
      return apiGetAllPageData({
        ...options,
        ...apiRes,
        currentData: mergedData,
        pageQueryParams: {
          totalNumberOfRecords: apiPagingInfo.totalNumberOfRecords,
          pageIndex: apiPagingInfo.pageIndex + 1, // increase by one to get the next page
          pageSize: pageQueryParams.pageSize
        }
      }, utils, stubDataOptions);
    }
    return {
      ...apiRes,
      ...rest,
      data: {
        ...dataResponse,
        pagingInfo: apiPagingInfo
      }
    };
  }
  // for backwards compatibility, return the standard axiosRequest result if no pagingInfo exists
  return {
    ...rest,
    ...apiRes
  };
};

const getMergedData = (dataKey, apiData, options) => {
  const { useResponseKeys, prevData } = options || {};
  if (useResponseKeys) {
    const responseKeys = [...new Set([
      ...(!isEmpty(apiData) ? Object.keys(apiData) : []),
      ...(!isEmpty(prevData) ? Object.keys(prevData) : [])
    ])];
    const mergedResponseData = responseKeys.reduce((acc, resKey) => {
      const {
        header // is an OBJECT if graph data, ARRAY if table data
      } = apiData[resKey] || {};
      const currentKeyData = getDataResponseValue(resKey, apiData[resKey]);
      const prevKeyData = getDataResponseValue(resKey, prevData);
      return {
        ...acc,
        [resKey]: {
          ...(!isEmpty(prevData) && {
            // spread any other prev existing data objects
            ...prevData[resKey]
          }),
          ...(!isEmpty(header) && { // if header exists, should be the same for all responses
            header
          }),
          data: [
            ...(!isEmpty(currentKeyData) ? currentKeyData : []),
            ...(!isEmpty(prevKeyData) ? prevKeyData : [])
          ]
        }
      };
    }, {});
    return mergedResponseData;
  }
  const currentData = getDataArray(dataKey, apiData) || [];
  return (!isEmpty(prevData) ? prevData : []).concat(currentData);
};

const getDataResponseValue = (resKey, apiData) => {
  const { data } = apiData || {};
  if (getType(data) === 'array' || getType(apiData) === 'array') {
    return (getType(data) === 'array' && data) || (getType(apiData) === 'array' && apiData) || [];
  }
  return typeof data === 'undefined' && typeof apiData === 'undefined'
    ? []
    : getDataResponseValue(resKey, apiData[resKey]);
};

const getDataArray = (dataKey, apiData) => {
  let dataArray;
  if (Array.isArray(apiData[dataKey])) {
    dataArray = apiData[dataKey]
      ? apiData[dataKey].reduce((acc, arr) => acc.concat(arr), [])
      : [];
  } else {
    dataArray = apiData[dataKey];
  }
  return dataArray;
};

export default apiGetAllPageData;
