const now = new Date();
now.setDate(now.getDate() - 14);
const incrementDate = () => {
  now.setDate(now.getDate() + 1);
  return now.toLocaleDateString();
};

export const mockRiskCategoryMetricGet = {
  header: {
    label: 'day',
    lines: ['total'],
    biaxial: ['total'],
    currency: null,
    biaxialCurrency: null
  },
  data: [
    {
      total: 0,
      CrmV2GetRiskSubCatMetricFTRCV3: 0,
      CrmV2GetRiskSubCatMetricFTRCV2: 0,
      getTicketsWithRiskSubcategoryRC: 0,
      CrmV2GetRiskSubCatMetricFTRCV1: 0,
      getTicketsWithRiskSubcategoryRC1: 0,
      CrmV2GetRiskCategoryMetricFTRCV1: 0,
      getTicketsWithRiskCategoryRC: 0,
      day: now.toLocaleDateString()
    },
    {
      total: 0,
      CrmV2GetRiskSubCatMetricFTRCV3: 0,
      CrmV2GetRiskSubCatMetricFTRCV2: 0,
      getTicketsWithRiskSubcategoryRC: 0,
      CrmV2GetRiskSubCatMetricFTRCV1: 0,
      getTicketsWithRiskSubcategoryRC1: 0,
      CrmV2GetRiskCategoryMetricFTRCV1: 0,
      getTicketsWithRiskCategoryRC: 0,
      day: incrementDate()
    },
    {
      total: 0,
      CrmV2GetRiskSubCatMetricFTRCV3: 0,
      CrmV2GetRiskSubCatMetricFTRCV2: 0,
      getTicketsWithRiskSubcategoryRC: 0,
      CrmV2GetRiskSubCatMetricFTRCV1: 0,
      getTicketsWithRiskSubcategoryRC1: 0,
      CrmV2GetRiskCategoryMetricFTRCV1: 0,
      getTicketsWithRiskCategoryRC: 0,
      day: incrementDate()
    },
    {
      total: 0,
      CrmV2GetRiskSubCatMetricFTRCV3: 0,
      CrmV2GetRiskSubCatMetricFTRCV2: 0,
      getTicketsWithRiskSubcategoryRC: 0,
      CrmV2GetRiskSubCatMetricFTRCV1: 0,
      getTicketsWithRiskSubcategoryRC1: 0,
      CrmV2GetRiskCategoryMetricFTRCV1: 0,
      getTicketsWithRiskCategoryRC: 0,
      day: incrementDate()
    },
    {
      total: 0,
      CrmV2GetRiskSubCatMetricFTRCV3: 0,
      CrmV2GetRiskSubCatMetricFTRCV2: 0,
      getTicketsWithRiskSubcategoryRC: 0,
      CrmV2GetRiskSubCatMetricFTRCV1: 0,
      getTicketsWithRiskSubcategoryRC1: 0,
      CrmV2GetRiskCategoryMetricFTRCV1: 0,
      getTicketsWithRiskCategoryRC: 0,
      day: incrementDate()
    },
    {
      total: 0,
      CrmV2GetRiskSubCatMetricFTRCV3: 0,
      CrmV2GetRiskSubCatMetricFTRCV2: 0,
      getTicketsWithRiskSubcategoryRC: 0,
      CrmV2GetRiskSubCatMetricFTRCV1: 0,
      getTicketsWithRiskSubcategoryRC1: 0,
      CrmV2GetRiskCategoryMetricFTRCV1: 0,
      getTicketsWithRiskCategoryRC: 0,
      day: incrementDate()
    },
    {
      total: 0,
      CrmV2GetRiskSubCatMetricFTRCV3: 0,
      CrmV2GetRiskSubCatMetricFTRCV2: 0,
      getTicketsWithRiskSubcategoryRC: 0,
      CrmV2GetRiskSubCatMetricFTRCV1: 0,
      getTicketsWithRiskSubcategoryRC1: 0,
      CrmV2GetRiskCategoryMetricFTRCV1: 0,
      getTicketsWithRiskCategoryRC: 0,
      day: incrementDate()
    },
    {
      total: 0,
      CrmV2GetRiskSubCatMetricFTRCV3: 0,
      CrmV2GetRiskSubCatMetricFTRCV2: 0,
      getTicketsWithRiskSubcategoryRC: 0,
      CrmV2GetRiskSubCatMetricFTRCV1: 0,
      getTicketsWithRiskSubcategoryRC1: 0,
      CrmV2GetRiskCategoryMetricFTRCV1: 0,
      getTicketsWithRiskCategoryRC: 0,
      day: incrementDate()
    },
    {
      total: 0,
      CrmV2GetRiskSubCatMetricFTRCV3: 0,
      CrmV2GetRiskSubCatMetricFTRCV2: 0,
      getTicketsWithRiskSubcategoryRC: 0,
      CrmV2GetRiskSubCatMetricFTRCV1: 0,
      getTicketsWithRiskSubcategoryRC1: 0,
      CrmV2GetRiskCategoryMetricFTRCV1: 0,
      getTicketsWithRiskCategoryRC: 0,
      day: incrementDate()
    },
    {
      total: 0,
      CrmV2GetRiskSubCatMetricFTRCV3: 0,
      CrmV2GetRiskSubCatMetricFTRCV2: 0,
      getTicketsWithRiskSubcategoryRC: 0,
      CrmV2GetRiskSubCatMetricFTRCV1: 0,
      getTicketsWithRiskSubcategoryRC1: 0,
      CrmV2GetRiskCategoryMetricFTRCV1: 0,
      getTicketsWithRiskCategoryRC: 0,
      day: incrementDate()
    },
    {
      total: 0,
      CrmV2GetRiskSubCatMetricFTRCV3: 0,
      CrmV2GetRiskSubCatMetricFTRCV2: 0,
      getTicketsWithRiskSubcategoryRC: 0,
      CrmV2GetRiskSubCatMetricFTRCV1: 0,
      getTicketsWithRiskSubcategoryRC1: 0,
      CrmV2GetRiskCategoryMetricFTRCV1: 0,
      getTicketsWithRiskCategoryRC: 0,
      day: incrementDate()
    },
    {
      total: 2,
      CrmV2GetRiskSubCatMetricFTRCV3: 0,
      CrmV2GetRiskSubCatMetricFTRCV2: 0,
      getTicketsWithRiskSubcategoryRC: 2,
      CrmV2GetRiskSubCatMetricFTRCV1: 0,
      getTicketsWithRiskSubcategoryRC1: 0,
      CrmV2GetRiskCategoryMetricFTRCV1: 0,
      getTicketsWithRiskCategoryRC: 0,
      day: incrementDate()
    },
    {
      total: 24,
      CrmV2GetRiskSubCatMetricFTRCV3: 4,
      CrmV2GetRiskSubCatMetricFTRCV2: 4,
      getTicketsWithRiskSubcategoryRC: 8,
      CrmV2GetRiskSubCatMetricFTRCV1: 2,
      getTicketsWithRiskSubcategoryRC1: 2,
      CrmV2GetRiskCategoryMetricFTRCV1: 2,
      getTicketsWithRiskCategoryRC: 2,
      day: incrementDate()
    },
    {
      total: 0,
      CrmV2GetRiskSubCatMetricFTRCV3: 0,
      CrmV2GetRiskSubCatMetricFTRCV2: 0,
      getTicketsWithRiskSubcategoryRC: 0,
      CrmV2GetRiskSubCatMetricFTRCV1: 0,
      getTicketsWithRiskSubcategoryRC1: 0,
      CrmV2GetRiskCategoryMetricFTRCV1: 0,
      getTicketsWithRiskCategoryRC: 0,
      day: incrementDate()
    }
  ]
};

export default mockRiskCategoryMetricGet;
