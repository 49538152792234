import { baseFormFields, baseRepayFields } from '@f1/shared/src/_boardingToolFields';
import { businessTabFormFieldsCrab } from '@f1/shared/src/_crabFields';
import sharedFormFields from '@f1/shared/src/_formFields';
import { sortData, stateCodes } from '@f1/shared/src/_helpers';
import validations from '@f1/shared/src/_validations';

export const globalFormProps = {
  input: {
    wrapperStyle: {
      flex: '33%',
      minWidth: '150px',
      margin: 'unset',
      marginLeft: '-1px'
    },
    required: true,
    boxStyle: 'inside',
    clearAutofillOnMount: true
  },
  dropdown: {
    wrapperStyle: {
      flex: '33%',
      minWidth: '150px',
      margin: 'unset'
    },
    required: true,
    boxStyle: 'inside'
  },
  comboBox: {
    wrapperStyle: {
      flex: '33%',
      minWidth: '150px'
    },
    required: true
  },
  multiSelect: {
    wrapperStyle: {
      flex: '33%',
      minWidth: '150px',
      margin: 'unset'
    },
    required: true,
    boxStyle: 'inside'
  },
  radio: {
    direction: 'horizontal',
    shape: 'square',
    size: 'sm',
    boxStyle: 'inside',
    wrapperStyle: {
      margin: 'unset',
      flex: '33%',
      minWidth: '150px'
    },
    required: true
  },
  checkbox: {
    wrapperStyle: {
      flex: 'auto',
      maxWidth: 'fit-content',
      marginBottom: '5px',
      lineHeight: '1.5',
      padding: '0 5px'
    },
    required: true
  },
  checkboxList: {
    required: true
  },
  timestamp: { // for InputTimestamp fields
    isTimestamp: true,
    required: true,
    valid: false,
    value: ''
  }
};

export const bankNameList = [
  { title: 'Axiom', value: 'AXIOM' },
  { title: 'Cross River', value: 'CROSS_RIVER' },
  { title: 'Cynergy', value: 'CYNERGY' },
  { title: 'Esquire', value: 'ESQUIRE' },
  { title: 'Evolve', value: 'EVOLVE' },
  { title: 'Fifth Third', value: 'FIFTH_THIRD' },
  { title: 'Merrick', value: 'MERRICK' },
  { title: 'MVB', value: 'MVB' },
  { title: 'PB&T', value: 'PUEBLO_BANK_AND_TRUST' },
  { title: 'Synovus', value: 'SYNOVUS' },
  { title: 'Vimas', value: 'VIMAS' },
  { title: 'Wells Fargo', value: 'WELLS_FARGO' }
];

export const processorNameList = [
  { title: 'Aurora', value: 'AURORA' },
  { title: 'Cardworks', value: 'CARDWORKS' },
  { title: 'First Data', value: 'FIRST_DATA' },
  { title: 'Merchant Industry', value: 'MERCHANT_INDUSTRY' },
  { title: 'MiCamp Processing - Maverick', value: 'MICAMP_PROCESSING_MAVERICK' },
  { title: 'Netevia', value: 'NETEVIA' },
  { title: 'Payroc', value: 'PAYROC' },
  { title: 'Priority', value: 'PRIORITY' },
  { title: 'RS2', value: 'RS2' },
  { title: 'REPAY', value: 'REPAY' },
  { title: 'Trinity', value: 'TRINITY' },
  { title: 'TSYS', value: 'TSYS' },
  { title: 'World Pay', value: 'WORLD_PAY' }
];

export const paymentGatewayList = [
  { title: 'Fluid Pay', value: 'FLUID_PAY' },
  { title: 'Lightspeed', value: 'LIGHTSPEED' },
  { title: 'MultiPASS', value: 'MULTIPASS' },
  { title: 'NMI', value: 'NMI' },
  { title: 'Omaha', value: 'OMAHA' },
  { title: 'Payeezy', value: 'PAYEEZY' },
  { title: 'SwipeSimple', value: 'SWIPE_SIMPLE' }
];

export const ticketCategoryList = [
  { title: 'New/Existing Partner Hierarchy Request', value: 'hierarchy' },
  { title: 'Partner DDA Change', value: 'ddaChange' },
  { title: 'Entity Change/Tax ID Change', value: 'taxId' },
  { title: 'Risk Profile Change or Addition', value: 'riskChange' },
  { title: 'Processor Change or Addition', value: 'processorChange' }
];

export const sharedRelationshipFormProps = {
  gatewayAffiliateId: {
    id: 'gatewayAffiliateId',
    label: 'Gateway Affiliate ID',
    type: 'text',
    list: [],
    fieldType: 'combobox',
    required: true,
    useBlockForm: true
  },
  relationshipId: {
    id: 'relationshipId',
    label: 'Relationship Id',
    type: 'text',
    fieldType: 'input',
    required: false
  },
  repaySortCode: {
    label: 'Repay Sort Code',
    type: 'text',
    id: 'repaySortCode',
    list: [
      { title: 'G0010001001001', value: 'G0010001001001' }, // All others
      { title: 'G0012001001001', value: 'G0012001001001' }, // UP
      { title: 'G0013001001001', value: 'G0013001001001' }, // Vitreous Alliance
      { title: 'G0014001001001', value: 'G0014001001001' } // Turn-Key Payment Solutions
    ],
    fieldType: 'combobox',
    useBlockForm: true,
    required: true
  }
};

// agentChain must be 6 digits and all numerical (may start with 0 so it must use type string)
export const agentChainValidation = value => RegExp(/^[0-9]{6}$/).test(value);

export const relationshipV3FormFields = {
  relationshipCode: {
    label: 'Relationship Code',
    type: 'text',
    id: 'relationshipCode',
    customValidation: value => RegExp(/^[1-2]([0-9]{4,5})$/).test(value),
    errorMessage: 'Must be 5 or 6 digits, and start with 1 or 2',
    // The matching validation with occur on FormAssistant callback
    required: true,
    useBlockForm: true,
    fieldType: 'input',
    tooltip: 'Must be unique'
  },
  relationshipName: {
    label: 'Relationship Name',
    type: 'text',
    id: 'relationshipName',
    required: true,
    wrapperStyle: { flex: '100%' },
    fieldType: 'input',
    tooltip: 'Must be unique'
  },
  parentRelationshipId: {
    label: 'Parent Relationship',
    type: 'text',
    id: 'parentRelationshipId',
    useBlockForm: true,
    required: true,
    wrapperStyle: { flex: '100%' },
    fieldType: 'combobox',
    list: [] // will be defined before render
  },
  childPartnerId: {
    label: 'Child Partner',
    type: 'text',
    id: 'childPartnerId',
    required: true,
    useBlockForm: true,
    wrapperStyle: { flex: '100%' },
    fieldType: 'combobox',
    list: [] // will be defined before render
  },
  bankName: {
    id: 'bankName',
    label: 'Bank',
    type: 'text',
    required: true,
    useBlockForm: true,
    wrapperStyle: { flex: '100%' },
    fieldType: 'combobox',
    list: bankNameList
  },
  processorName: {
    label: 'Processor',
    type: 'text',
    id: 'processorName',
    required: true,
    useBlockForm: true,
    list: processorNameList,
    fieldType: 'combobox',
    controllerStateOverrides: processorNameList.reduce((acc, p) => ({
      ...acc,
      [p.value]: {
        paymentGateway: undefined,
        paymentGatewayIsValid: p.value === 'REPAY'
      }
    }), {}),
    controls: {
      REPAY: {
        repaySortCode: {
          ...sharedRelationshipFormProps.repaySortCode
        }
      }
    }
  },
  processorFrontEnd: {
    label: 'Processor Front End',
    type: 'text',
    id: 'processorFrontEnd',
    fieldType: 'input',
    required: false
  },
  paymentGateway: {
    label: 'Payment Gateway',
    type: 'paymentGateway',
    id: 'paymentGateway',
    fieldType: 'combobox',
    useBlockForm: true,
    required: false,
    list: paymentGatewayList
  },
  riskProfile: {
    label: 'Risk Profile',
    type: 'text',
    id: 'riskProfile',
    required: true,
    useBlockForm: true,
    fieldType: 'combobox',
    list: [
      { title: 'Preferred', value: 'preferred' },
      { title: 'Standard', value: 'standard' },
      { title: 'Elevated', value: 'elevated' }
    ]
  },
  agentChain: {
    label: 'Agent Chain',
    type: 'text',
    id: 'agentChain',
    fieldType: 'input',
    required: false,
    notRequiredOnEditIfEmpty: true, // not required on EDIT, unless a value already exists
    customValidation: agentChainValidation,
    errorMessage: 'Must be 6 digits long',
    controlledBy: { // Required if the processor_name is REPAY
      controllerId: 'processorName',
      controllerValue: 'REPAY',
      overrides: {
        required: true
      },
      defaults: {
        required: false
      }
    }
  },
  inactive: {
    id: 'inactive',
    name: 'inactive',
    label: 'Inactive?',
    type: 'radio',
    fieldType: 'radio',
    fields: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' }
    ],
    required: true
  }
};

export const merchantV2GeneralFormFields = {
  // General Info
  mid: {
    id: 'mid',
    label: 'MID',
    type: 'mid',
    fieldType: 'input'
  },
  discoverMid: {
    id: 'discoverMid',
    label: 'Discover MID',
    type: 'text',
    fieldType: 'input',
    customValidation: value => RegExp(/^[0-9]{15}$/).test(value),
    errorMessage: 'Must be 15 digits',
    required: false
  },
  descriptor: {
    id: 'descriptor',
    label: 'Descriptor',
    type: 'text',
    fieldType: 'input'
  },
  descriptorShort: {
    id: 'descriptorShort',
    label: 'Descriptor Short',
    type: 'text',
    fieldType: 'input',
    required: false,
    customValidation: val => val && val.length <= 22,
    errorMessage: 'Must be 22 characters or less'
  },
  dbaName: {
    id: 'dbaName',
    label: 'DBA Name',
    type: 'text',
    fieldType: 'input'
  },
  legalName: {
    id: 'legalName',
    label: 'Legal Name',
    type: 'text',
    fieldType: 'input'
  },
  highRiskRegistration: {
    id: 'highRiskRegistration',
    label: 'HR Registration',
    type: 'radio',
    fieldType: 'radio',
    required: false,
    fields: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' }
    ]
  },
  relationshipId: {
    id: 'relationshipId',
    label: 'Relationship ID',
    type: 'text',
    fieldType: 'combobox',
    list: [{ value: '', title: '' }],
    useBlockForm: true,
    formField: true,
    required: true,
    wrapperStyle: {
      ...globalFormProps.dropdown,
      margin: 'unset',
      flex: '100%'
    }
  },
  mccCode: {
    ...sharedFormFields.mccCode,
    required: true,
    fieldType: 'combobox',
    validationType: 'mcc',
    editable: true,
    formField: true,
    useBlockForm: true,
    wrapperStyle: { ...globalFormProps.comboBox.wrapperStyle, flex: '100%' }
  },
  reservesIndicator: {
    id: 'reservesIndicator',
    label: 'Reserves Indicator',
    type: 'text',
    required: false,
    fieldType: 'input'
  },
  ein: {
    ...sharedFormFields.ein,
    required: true
  },
  crm: {
    id: 'crm',
    label: 'CRM',
    type: 'text',
    required: false,
    fieldType: 'combobox',
    editable: true,
    formField: true,
    useBlockForm: true,
    list: sortData([
      { title: 'CorCRM', value: 'CorCRM' },
      { title: 'Konnektive', value: 'Konnektive' },
      { title: 'Limitless CRM', value: 'Limitless CRM' },
      { title: 'Not Indicated', value: 'Not Indicated' },
      { title: 'Phenom CRM', value: 'Phenom CRM' },
      { title: 'Response CRM', value: 'Response CRM' },
      { title: 'Sticky.io', value: 'Sticky.io' },
      { title: 'Velox', value: 'Velox' }
    ], 'title')
  },
  chargebackProvider: {
    id: 'chargebackProvider',
    label: 'Chargeback Provider',
    type: 'text',
    fieldType: 'combobox',
    required: false,
    editable: true,
    formField: true,
    useBlockForm: true,
    list: sortData([
      { title: 'Pending', value: 'Pending' },
      { title: 'Midigator LLC', value: 'Midigator LLC' },
      { title: 'Chargebacks 911', value: 'Chargebacks 911' },
      { title: 'Chargeback 360', value: 'Chargeback 360' },
      { title: 'Chargeback Hero', value: 'Chargeback Hero' },
      { title: 'Xcaliber', value: 'Xcaliber' },
      { title: 'Chargeback Defense', value: 'Chargeback Defense' },
      { title: 'Transact Clear', value: 'Transact Clear' },
      { title: 'Chargeback Help', value: 'Chargeback Help' },
      { title: 'Adtracktiv', value: 'Adtracktiv' },
      { title: 'Square One', value: 'Square One' },
      { title: 'Enablest LLC', value: 'Enablest LLC' },
      { title: 'Chargeback Gurus', value: 'Chargeback Gurus' },
      { title: 'RIISK Pmt', value: 'RIISK Pmt' },
      { title: 'Chargeback Wizard', value: 'Chargeback Wizard' },
      { title: 'Focus Support', value: 'Focus Support' }
    ], 'title')
  },
  fulfillment: {
    id: 'fulfillment',
    label: 'Fulfillment',
    type: 'text',
    required: false,
    fieldType: 'combobox',
    editable: true,
    formField: true,
    useBlockForm: true,
    list: sortData([
      { title: 'Affiliate Fulfillment LLC', value: 'Affiliate Fulfillment LLC' },
      { title: 'Alchemy Nutra', value: 'Alchemy Nutra' },
      { title: 'Amware Fulfillment', value: 'Amware Fulfillment' },
      { title: 'Autoship Logistics LLC', value: 'Autoship Logistics LLC' },
      { title: 'Avanti Fulfillment', value: 'Avanti Fulfillment' },
      { title: 'Avarice Group', value: 'Avarice Group' },
      { title: 'DSY Logistics', value: 'DSY Logistics' },
      { title: 'Delivery Group Inc.', value: 'Delivery Group Inc.' },
      { title: 'Equinox', value: 'Equinox' },
      { title: 'Evolucity Commerce', value: 'Evolucity Commerce' },
      { title: 'Focus Fulfillment', value: 'Focus Fulfillment' },
      { title: 'Force Shipping', value: 'Force Shipping' },
      { title: 'Fulfillment Lab', value: 'Fulfillment Lab' },
      { title: 'Fulfillment Ninjas Hub', value: 'Fulfillment Ninjas Hub' },
      { title: 'Fulfillment X', value: 'Fulfillment X' },
      { title: 'Fulfillment.com', value: 'Fulfillment.com' },
      { title: 'Full Stack Fulfillment', value: 'Full Stack Fulfillment' },
      { title: 'Pending', value: 'Pending' },
      { title: 'Push Innovation', value: 'Push Innovation' },
      { title: 'QDMS', value: 'QDMS' },
      { title: 'Quickbox', value: 'Quickbox' },
      { title: 'Rapid Fulfillment', value: 'Rapid Fulfillment' },
      { title: 'Rocket Systems Inc.', value: 'Rocket Systems Inc.' },
      { title: 'Save Rack', value: 'Save Rack' },
      { title: 'TFA', value: 'TFA' },
      { title: 'TFL', value: 'TFL' },
      { title: 'Target Fulfillment', value: 'Target Fulfillment' },
      { title: 'The Fulfillment Alliance', value: 'The Fulfillment Alliance' },
      { title: 'The Fulfillment Lab', value: 'The Fulfillment Lab' },
      { title: 'Trapeze', value: 'Trapeze' },
      { title: 'Worldwide Shipping', value: 'Worldwide Shipping' },
      { title: 'YourLabelHere.com', value: 'YourLabelHere.com' }
    ], 'title')
  },
  callCenterProvider: {
    id: 'callCenterProvider',
    label: 'Call Center Provider',
    type: 'text',
    required: false,
    fieldType: 'combobox',
    editable: true,
    formField: true,
    useBlockForm: true,
    list: sortData([
      { title: 'ASK Telemarketing', value: 'ASK Telemarketing' },
      { title: 'Aegus Group', value: 'Aegus Group' },
      { title: 'Alpha Connect', value: 'Alpha Connect' },
      { title: 'Argo Marketing Group', value: 'Argo Marketing Group' },
      { title: 'Ask Telemarketing', value: 'Ask Telemarketing' },
      { title: 'Avarice Group', value: 'Avarice Group' },
      { title: 'Aventus LLC', value: 'Aventus LLC' },
      { title: 'BDP Call Center', value: 'BDP Call Center' },
      { title: 'Call Center Pros', value: 'Call Center Pros' },
      { title: 'Cogent', value: 'Cogent' },
      { title: 'Customer Service Solutions', value: 'Customer Service Solutions' },
      { title: 'DR Republic', value: 'DR Republic' },
      { title: 'DialedIn Solutions', value: 'DialedIn Solutions' },
      { title: 'FCCS S.R.L.', value: 'FCCS S.R.L.' },
      { title: 'Focus Commerce', value: 'Focus Commerce' },
      { title: 'Focus Support', value: 'Focus Support' },
      { title: 'GetLive.Help', value: 'GetLive.Help' },
      { title: 'Happy Customers', value: 'Happy Customers' },
      { title: 'Immunis Global Inc', value: 'Immunis Global Inc' },
      { title: 'InFocu5', value: 'InFocu5' },
      { title: 'J&C Marketing LLC', value: 'J&C Marketing LLC' },
      { title: 'Koala Customer Care', value: 'Koala Customer Care' },
      { title: 'LogiCall', value: 'LogiCall' },
      { title: 'Metrics Call Services', value: 'Metrics Call Services' },
      { title: 'Outsource South America', value: 'Outsource South America' },
      { title: 'Pacific Peacock Enterprise', value: 'Pacific Peacock Enterprise' },
      { title: 'Pending', value: 'Pending' },
      { title: 'Profit Expansion Group', value: 'Profit Expansion Group' },
      { title: 'Push Innovation Live', value: 'Push Innovation Live' },
      { title: 'Rapid Phone Center', value: 'Rapid Phone Center' },
      { title: 'Sales Retention Services', value: 'Sales Retention Services' },
      { title: 'Smart Contact Hub', value: 'Smart Contact Hub' },
      { title: 'Techcel Outsourcing', value: 'Techcel Outsourcing' },
      { title: 'Total Client Connect', value: 'Total Client Connect' },
      { title: 'Trapeze Labs', value: 'Trapeze Labs' },
      { title: 'Voice Connect', value: 'Voice Connect' },
      { title: 'W2 Online Group', value: 'W2 Online Group' },
      { title: 'iVoice Outsourcing', value: 'iVoice Outsourcing' }
    ], 'title')
  },
  amexServiceEstablishmentNumber: {
    id: 'amexServiceEstablishmentNumber',
    label: 'Amex Service Establishment Number',
    type: 'text',
    required: false,
    fieldType: 'input'
  },
  organizationalStructure: {
    type: 'text',
    fieldType: 'combobox',
    id: 'organizationalStructure',
    label: 'Organizational Structure',
    required: false,
    formField: true,
    useBlockForm: true,
    list: businessTabFormFieldsCrab.businessInformationSection.organizationalStructure.list
  },
  merchantAcceptanceDate: {
    type: 'date',
    fieldType: 'input',
    id: 'merchantAcceptanceDate',
    label: 'Merchant Acceptance Date',
    dateOnly: true,
    required: false
  },
  businessStartYear: {
    type: 'text',
    fieldType: 'input',
    id: 'businessStartYear',
    label: 'Business Start Year',
    required: false,
    customValidation: value => RegExp(/^[0-9]{4}$/).test(value),
    errorMessage: 'Must be a valid year'
  },
  monthAndYearStarted: {
    type: 'text',
    fieldType: 'selectYearMonth',
    id: 'monthAndYearStarted',
    label: 'Year/Month Started',
    required: false,
    formField: true,
    useBlockForm: true,
    valueContainerStyles: { minHeight: '30px' },
    wrapperStyle: { flex: '33%', marginBottom: '-2px' }
  },
  stockSymbol: {
    type: 'text',
    fieldType: 'input',
    id: 'stockSymbol',
    label: 'Stock Symbol',
    required: false
  },
  market: {
    type: 'text',
    fieldType: 'input',
    id: 'market',
    label: 'Market',
    required: false
  },
  policyException: {
    type: 'radio',
    fieldType: 'radio',
    id: 'policyException',
    name: 'policyException',
    label: 'Policy Exception',
    fields: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
    required: false,
    controllerStateOverrides: {
      yes: { policyExceptionReason: '', policyExceptionReasonIsValid: false },
      no: { policyExceptionReason: '', policyExceptionReasonIsValid: true }
    },
    controls: {
      yes: {
        policyExceptionReason: {
          type: 'textarea',
          fieldType: 'input',
          id: 'policyExceptionReason',
          label: 'Policy Exception Reason',
          required: true, // only required if `policyException` is `true`
          wrapperStyle: { flex: '100%', marginRight: 0, minHeight: 0 }
        }
      }
    }
  }
};

export const merchantV2RepayOnlyFields = {
  chargebackNotificationEmailAddress: {
    ...baseRepayFields.chargebackNotificationEmailAddress,
    required: false
  }
};

export const merchantV2SeasonMonthsList = [
  { title: 'January', value: 'seasonalBusinessJan' },
  { title: 'February', value: 'seasonalBusinessFeb' },
  { title: 'March', value: 'seasonalBusinessMar' },
  { title: 'April', value: 'seasonalBusinessApr' },
  { title: 'May', value: 'seasonalBusinessMay' },
  { title: 'June', value: 'seasonalBusinessJun' },
  { title: 'July', value: 'seasonalBusinessJul' },
  { title: 'August', value: 'seasonalBusinessAug' },
  { title: 'September', value: 'seasonalBusinessSep' },
  { title: 'October', value: 'seasonalBusinessOct' },
  { title: 'November', value: 'seasonalBusinessNov' },
  { title: 'December', value: 'seasonalBusinessDec' }
];

export const merchantV2PaymentSummaryMainFormFields = {
  // Payment Summary
  seasonalBusiness: {
    id: 'seasonalBusiness',
    name: 'seasonalBusiness',
    label: 'Seasonal Business?',
    type: 'radio',
    fieldType: 'radio',
    required: false,
    fields: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
    controllerStateOverrides: {
      yes: { seasonMonths: [], seasonMonthsIsValid: false },
      no: { seasonMonths: [], seasonMonthsIsValid: true }
    },
    controls: {
      yes: {
        seasonMonths: {
          type: 'mini',
          labelPos: 'top',
          boxStyle: 'inside',
          id: 'seasonMonths',
          label: 'Seasonal Months of Operation',
          fieldType: 'checkboxList',
          isCheckboxList: true,
          list: merchantV2SeasonMonthsList,
          required: true,
          containerStyle: { flex: '100%' },
          backendFormat: (array, options) => {
            const { list } = options || {};
            const dataValueMap = array.reduce((acc, item) => ({ ...acc, ...item }), {});
            return list.reduce((acc, item) => {
              const { value } = item || {};
              return { ...acc, [value]: dataValueMap[value] || false };
            }, {});
          }
        }
      }
    }
  },
  averageTransactionAmount: {
    id: 'averageTransactionAmount',
    label: 'Average Transaction Amount',
    type: 'price',
    required: false,
    prefix: '$',
    fieldType: 'input'
  },
  highestTransactionAmount: {
    id: 'highestTransactionAmount',
    label: 'Highest Transaction Amount',
    type: 'price',
    required: false,
    prefix: '$',
    fieldType: 'input'
  },
  currentAllowedMonthlyVolume: {
    id: 'currentAllowedMonthlyVolume',
    label: 'Current Allowed Monthly Volume',
    type: 'price',
    priceToNumber: true, // convert from string to number type for BE value
    prefix: '$',
    fieldType: 'input'
  },
  cardsSwiped: {
    id: 'cardsSwiped',
    label: 'Cards Swiped',
    type: 'number',
    required: false,
    fieldType: 'input'
  },
  telephoneOrder: {
    id: 'telephoneOrder',
    label: 'Telephone Order',
    type: 'rationalNumber',
    required: false,
    fieldType: 'input'
  },
  ecommerce: {
    id: 'ecommerce',
    label: 'Ecommerce',
    type: 'rationalNumber',
    required: false,
    fieldType: 'input'
  },
  fundingChoices: {
    id: 'fundingChoices',
    label: 'Funding Choices',
    type: 'text',
    required: false,
    formField: true,
    useBlockForm: true,
    fieldType: 'combobox',
    list: [
      { title: 'Standard', value: 'standard' },
      { title: 'Next Day of Funding', value: 'next_day_funding' }
    ]
  },
  perMonthFunding: {
    id: 'perMonthFunding',
    label: 'Per Month Funding',
    type: 'text',
    required: false,
    fieldType: 'input'
  },
  visaCard: {
    id: 'visaCard',
    name: 'visaCard',
    label: 'Visa Card',
    type: 'radio',
    fieldType: 'radio',
    required: false,
    fields: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }]
  },
  masterCard: {
    id: 'masterCard',
    name: 'masterCard',
    label: 'Mastercard',
    type: 'radio',
    fieldType: 'radio',
    required: false,
    fields: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }]
  },
  discoverCard: {
    id: 'discoverCard',
    name: 'discoverCard',
    label: 'Discover Card',
    type: 'radio',
    fieldType: 'radio',
    required: false,
    fields: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }]
  },
  amexOptBlue: {
    id: 'amexOptBlue',
    name: 'amexOptBlue',
    label: 'American Express OptBlue Card',
    type: 'radio',
    fieldType: 'radio',
    required: false,
    fields: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }]
  },
  debitCardsOnly: {
    id: 'debitCardsOnly',
    name: 'debitCardsOnly',
    label: 'Debit Cards Only',
    type: 'radio',
    fieldType: 'radio',
    required: false,
    fields: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }]
  },
  otherCard: {
    id: 'otherCard',
    name: 'otherCard',
    label: 'Other Card',
    type: 'radio',
    fieldType: 'radio',
    required: false,
    fields: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }]
  },
  masterCardNobRegStatus: {
    id: 'masterCardNobRegStatus',
    label: 'Mastercard NOB Registration Status',
    type: 'text',
    required: false,
    fieldType: 'input'
  },
  guarantorSignedDate: {
    id: 'guarantorSignedDate',
    label: 'Guarantor Signed Date',
    required: false,
    type: 'date',
    fieldType: 'input'
  },
  amexAcceptance: {
    id: 'amexAcceptance',
    label: 'American Express Acceptance',
    required: false,
    type: 'date',
    fieldType: 'input',
    dateOnly: true
  },
  annualVolume: {
    id: 'annualVolume',
    label: 'Annual Volume',
    required: false,
    type: 'price',
    prefix: '$',
    fieldType: 'input'
  },
  imprintWithCardPresent: {
    id: 'imprintWithCardPresent',
    label: 'Imprint with Card Present',
    required: false,
    type: 'rationalNumber',
    fieldType: 'input'
  },
  currentHoldbackRatio: {
    id: 'currentHoldbackRatio',
    label: 'Current Holdback Percent',
    required: false,
    suffix: '%',
    type: 'percent',
    fieldType: 'input',
    isRatio: true
  },
  currentHoldbackPeriod: {
    id: 'currentHoldbackPeriod',
    label: 'Current Holdback Period',
    required: false,
    type: 'text',
    fieldType: 'input'
  },
  reserveHoldbackRatio: {
    id: 'reserveHoldbackRatio',
    label: 'Reserve Holdback Percent',
    required: false,
    suffix: '%',
    type: 'percent',
    fieldType: 'input',
    isRatio: true
  },
  reserveHoldbackDays: {
    id: 'reserveHoldbackDays',
    label: 'Reserve Holdback Days',
    required: false,
    type: 'text',
    fieldType: 'input'
  },
  averageMonthlyVolume: {
    id: 'averageMonthlyVolume',
    label: 'Average Monthly Volume',
    type: 'price',
    required: false,
    prefix: '$',
    fieldType: 'input'
  },
  averageMonthlyVolumeAmex: {
    type: 'price',
    prefix: '$',
    fieldType: 'input',
    priceToNumber: true, // convert from string to number type for BE value
    id: 'averageMonthlyVolumeAmex',
    label: 'Average Monthly Volume (Amex)',
    required: false
  },
  amexNumber: {
    type: 'text',
    fieldType: 'input',
    id: 'amexNumber',
    label: 'Amex Number',
    required: false
  },
  amexRetainedEsa: {
    type: 'radio',
    fieldType: 'radio',
    id: 'amexRetainedEsa',
    name: 'amexRetainedEsa',
    label: 'Amex Retained ESA',
    fields: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
    required: false
  },
  waiveCardBrandFees: {
    type: 'radio',
    fieldType: 'radio',
    id: 'waiveCardBrandFees',
    name: 'waiveCardBrandFees',
    label: 'Waive Card Brand Fees',
    fields: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
    required: false
  },
  waiveDuesAndAssessments: {
    type: 'radio',
    fieldType: 'radio',
    id: 'waiveDuesAndAssessments',
    name: 'waiveDuesAndAssessments',
    label: 'Waive Dues & Assessments',
    fields: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
    required: false
  },
  electronicBenefitsTransfer: {
    type: 'radio',
    fieldType: 'radio',
    id: 'electronicBenefitsTransfer',
    name: 'electronicBenefitsTransfer',
    label: 'Electronic Benefits Transfer',
    fields: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
    required: false,
    controllerStateOverrides: {
      yes: { fnsEbtNumber: '', fnsEbtNumberIsValid: false },
      no: { fnsEbtNumber: '', fnsEbtNumberIsValid: true }
    },
    controls: {
      yes: {
        fnsEbtNumber: {
          type: 'text',
          fieldType: 'input',
          id: 'fnsEbtNumber',
          label: 'FNS EBT Number',
          required: true // only required if `electronicBenefitsTransfer` is `true`
        }
      }
    }
  },
  hrBillingModel: {
    id: 'hrBillingModel',
    label: 'HR Billing Model',
    type: 'text',
    list: [
      { title: 'Straight Sale', value: 'Straight Sale' },
      { title: 'SS Continuity', value: 'SS Continuity' },
      { title: 'Trial', value: 'Trial' }
    ],
    fieldType: 'combobox',
    required: false,
    formField: true,
    useBlockForm: true
  },
  hrBusinessType: {
    id: 'hrBusinessType',
    label: 'HR Business Type',
    type: 'text',
    list: [
      { title: 'Nutra', value: 'Nutra' },
      { title: 'Non Nutra', value: 'Non Nutra' }
    ],
    fieldType: 'combobox',
    required: false,
    formField: true,
    useBlockForm: true
  },
  hrBusinessSubtype1: {
    id: 'hrBusinessSubtype1',
    label: 'HR Business SubType 1',
    type: 'text',
    list: [
      { title: 'BizOps', value: 'BizOps' },
      { title: 'Convenient Store', value: 'Convenient Store' },
      { title: 'Gadgets', value: 'Gadgets' },
      { title: 'Health Consulting', value: 'Health Consulting' },
      { title: 'Hydro Equipment', value: 'Hydro Equipment' },
      { title: 'Misc. Ingestible', value: 'Misc. Ingestible' },
      { title: 'Misc. Services', value: 'Misc. Services' },
      { title: 'Multiple SubTypes', value: 'Multiple SubTypes' },
      { title: 'Muscle', value: 'Muscle' },
      { title: 'No Limit Zone', value: 'No Limit Zone' },
      { title: 'Nutra', value: 'Nutra' },
      { title: 'Physical Non-Nutra', value: 'Physical Non-Nutra' },
      { title: 'Plus Choice Wellness ProWeight Loss', value: 'Plus Choice Wellness ProWeight Loss' },
      { title: 'Restaurant', value: 'Restaurant' },
      { title: 'Savings Club', value: 'Savings Club' },
      { title: 'Skin Serum', value: 'Skin Serum' },
      { title: 'Topical', value: 'Topical' },
      { title: 'Weight Loss', value: 'Weight Loss' },
      { title: 'Wellness', value: 'Wellness' }
    ],
    fieldType: 'combobox',
    required: false,
    formField: true,
    useBlockForm: true
  },
  hrBusinessSubtype2: {
    id: 'hrBusinessSubtype2',
    label: 'HR Business SubType 2',
    type: 'text',
    fieldType: 'input',
    required: false
  },
  cashDiscount: {
    id: 'cashDiscount',
    name: 'cashDiscount',
    label: 'Cash Discount',
    type: 'radio',
    required: false,
    fieldType: 'radio',
    fields: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }]
  },
  suppressStatement: {
    id: 'suppressStatement',
    name: 'suppressStatement',
    label: 'Suppress Statement',
    type: 'radio',
    required: false,
    fieldType: 'radio',
    fields: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
    tooltip: 'The entire statement is not suppressed, only select fields on the statement'
  },
  combinedAch: {
    id: 'combinedAch',
    name: 'combinedAch',
    label: 'Combined ACH',
    type: 'radio',
    required: true,
    fieldType: 'radio',
    fields: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
    initialValue: 'no'
  },
  discountType: {
    id: 'discountType',
    name: 'discountType',
    label: 'Discount Type',
    type: 'combobox',
    fieldType: 'combobox',
    formField: true,
    useBlockForm: true,
    required: true,
    list: [{ title: 'Monthly', value: 'monthly' }, { title: 'Daily', value: 'daily' }],
    initialValue: 'monthly'
  }
};

export const merchantV2PaymentSummaryOtherFields = {
  // Payment Summary (not handled in FormAssistant)
  netAch: {
    ...globalFormProps.radio,
    id: 'netAch',
    name: 'netAch',
    label: 'Net ACH',
    type: 'radio',
    fieldType: 'radio',
    required: true,
    wrapperStyle: { ...globalFormProps.radio.wrapperStyle, margin: '0 -1px' },
    fields: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }]
  }
};

const baseMerchantV2ClosedFields = { // master list of closure fields, only use as base fields
  closedDate: {
    id: 'closedDate',
    label: 'Closed Date',
    type: 'date',
    required: true, // BE requires this on closed merchant
    fieldType: 'input'
  },
  closureReason: {
    id: 'closureReason',
    label: 'Closure Reason',
    type: 'text',
    fieldType: 'combobox',
    useBlockForm: true,
    formField: true,
    required: true, // BE requires this on closed merchant
    list: [
      { title: 'ACH Rejects', value: 'ACH Rejects' },
      { title: 'Bank Request', value: 'Bank Request' },
      { title: 'Brand Request', value: 'Brand Request' },
      { title: 'CB', value: 'CB' },
      { title: 'Fraud', value: 'Fraud' },
      { title: 'MATCH', value: 'Match' },
      { title: 'Merchant Requested', value: 'Merchant Requested' },
      { title: 'Non-Compliance', value: 'Non-Compliance' },
      { title: 'Not Indicated', value: 'Not Indicated' },
      { title: 'Refunds/Rejected ACH', value: 'Refunds/RejectedACH' },
      { title: 'Risk Termination', value: 'Risk Termination' }
    ]
  },
  riskTermination: {
    id: 'riskTermination',
    name: 'riskTermination',
    label: 'Risk Termination',
    type: 'radio',
    required: true, // BE requires this on closed merchant
    fieldType: 'radio',
    fields: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
    controllerStateOverrides: {
      yes: { riskDetails: '', riskDetailsIsValid: false },
      no: { riskDetails: '', riskDetailsIsValid: true }
    }
  }
};

export const merchantV2StatusOtherFormFields = {
  riskDetails: {
    id: 'riskDetails',
    label: 'Risk Details',
    type: 'text',
    fieldType: 'input',
    required: true // BE requires this if `riskTermination` is true
  }
};

export const merchantV2StatusFormFields = {
  // Status Info
  approvedDate: {
    id: 'approvedDate',
    label: 'Approved Date',
    type: 'date',
    fieldType: 'input',
    required: true
  },
  status: {
    id: 'status',
    label: 'Status',
    type: 'text',
    list: [
      { title: 'Open', value: 'Open' },
      { title: 'Closed', value: 'Closed' },
      { title: 'Soft Close', value: 'Soft Close' }
    ],
    fieldType: 'combobox',
    useBlockForm: true,
    formField: true,
    required: true,
    controls: {
      Closed: {
        closedDate: baseMerchantV2ClosedFields.closedDate,
        closureReason: baseMerchantV2ClosedFields.closureReason,
        riskTermination: baseMerchantV2ClosedFields.riskTermination
      },
      'Soft Close': {
        closedDate: baseMerchantV2ClosedFields.closedDate,
        closureReason: baseMerchantV2ClosedFields.closureReason,
        riskTermination: baseMerchantV2ClosedFields.riskTermination
      }
    }
  }
};

export const merchantV2BillingAddressSubFields = {
  // Contact info - `billingAddress`
  billingAddressAutocomplete: {
    id: 'billingAddressAutocomplete',
    fieldType: 'addressAutocomplete',
    isAddressAutocomplete: true,
    boxStyle: 'inside',
    required: true,
    baseAddressFields: {
      billingAddressLine1: {
        type: 'text',
        fieldType: 'input',
        id: 'billingAddressLine1',
        label: 'Address Line 1',
        required: true,
        wrapperStyle: { flex: '100%' }
      },
      billingAddressLine2: {
        type: 'text',
        fieldType: 'input',
        id: 'billingAddressLine2',
        label: 'Address Line 2',
        required: false,
        wrapperStyle: { flex: '100%' }
      },
      billingAddressCity: {
        type: 'text',
        fieldType: 'input',
        id: 'billingAddressCity',
        label: 'City',
        required: true
      },
      billingAddressState: {
        ...sharedFormFields.state,
        id: 'billingAddressState',
        label: 'State',
        required: true,
        useBlockForm: true
      },
      billingAddressPostalCode: {
        ...sharedFormFields.zip,
        id: 'billingAddressPostalCode',
        label: 'Zip Code',
        required: true
      },
      billingAddressCountry: {
        ...sharedFormFields.countryDropdown,
        id: 'billingAddressCountry',
        label: 'Country',
        required: true,
        useBlockForm: true
      }
    }
  }
};

export const merchantV2ContactInfoFields = {
  // Contact Info
  contactAddressAutocomplete: {
    id: 'contactAddressAutocomplete',
    fieldType: 'addressAutocomplete',
    isAddressAutocomplete: true,
    boxStyle: 'inside',
    required: true,
    baseAddressFields: {
      contactAddress: {
        id: 'contactAddress',
        label: 'Address Line 1',
        type: 'text',
        required: true,
        wrapperStyle: { flex: '100%' },
        fieldType: 'input'
      },
      contactAddress2: {
        id: 'contactAddress2',
        label: 'Address Line 2',
        type: 'text',
        required: false,
        wrapperStyle: { flex: '100%' },
        fieldType: 'input'
      },
      contactCity: {
        id: 'contactCity',
        label: 'City',
        type: 'text',
        required: true,
        fieldType: 'input'
      },
      contactState: {
        ...sharedFormFields.state,
        id: 'contactState',
        required: true,
        useBlockForm: true
      },
      contactZip: {
        ...sharedFormFields.zip,
        id: 'contactZip',
        required: true
      },
      contactCountry: {
        id: 'contactCountry',
        label: 'Country',
        type: 'text',
        required: false,
        fieldType: 'combobox',
        useBlockForm: true
      }
    }
  },
  contactEmail: {
    id: 'contactEmail',
    label: 'Contact Email',
    type: 'email',
    required: true,
    fieldType: 'input'
  },
  contactPhone: {
    id: 'contactPhone',
    label: 'Contact Phone Number',
    type: 'tel',
    required: true,
    fieldType: 'input'
  },
  contactName: {
    id: 'contactName',
    label: 'Contact Name',
    type: 'text',
    required: true,
    fieldType: 'input'
  },
  url: {
    id: 'url',
    label: 'URL',
    type: 'url',
    required: false,
    fieldType: 'input'
  },
  addressType: {
    id: 'addressType',
    label: 'Address Type',
    type: 'text',
    fieldType: 'combobox',
    formField: true,
    useBlockForm: true,
    list: [
      { title: 'Mailing', value: 'Mailing' },
      { title: 'Location', value: 'Location' },
      { title: 'Physical', value: 'Physical' },
      { title: 'Billing', value: 'Billing' }
    ],
    required: false
  },
  faxNumber: {
    id: 'faxNumber',
    label: 'Fax Number',
    type: 'tel',
    required: false,
    fieldType: 'input'
  },
  billingAddressSectionHeader: {
    fieldType: 'formSectionHeader',
    id: `billingAddressSectionHeader`,
    title: 'Billing Address',
    isSubSection: true,
    wrapperStyle: { borderTop: '1px solid black' }
  },
  setBillingAddress: { // FE-only radio
    id: 'setBillingAddress',
    name: 'setBillingAddress',
    label: 'Set billing address',
    type: 'radio',
    fieldType: 'radio',
    required: false,
    wrapperStyle: { flex: '100%' },
    fields: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
    controls: {
      yes: merchantV2BillingAddressSubFields
    }
  }
};

const achInfoBaseFields = {
  nameOnAccount: {
    id: 'nameOnAccount',
    label: 'Name On Account',
    type: 'text',
    fieldType: 'input'
  },
  accountNumber: {
    id: 'accountNumber',
    label: 'Account Number',
    type: 'bankaccount',
    fieldType: 'input'
  },
  routingNumber: {
    id: 'routingNumber',
    label: 'Routing Number',
    type: 'bankrouting',
    fieldType: 'input'
  },
  nameOfBank: {
    id: 'nameOfBank',
    label: 'Name of Bank',
    type: 'text',
    fieldType: 'input'
  }
};

export const merchantV2AchInfoFields = {
  nameOnAccount: achInfoBaseFields.nameOnAccount,
  accountNumber: achInfoBaseFields.accountNumber,
  routingNumber: achInfoBaseFields.routingNumber,
  nameOfBank: achInfoBaseFields.nameOfBank,
  bankContact: {
    id: 'bankContact',
    label: 'Bank Contact',
    type: 'text',
    fieldType: 'input',
    required: false
  },
  bankPhone: {
    id: 'bankPhone',
    label: 'Bank Phone',
    type: 'tel',
    fieldType: 'input',
    required: false
  },
  dateOpened: {
    id: 'dateOpened',
    label: 'Date Opened',
    type: 'date',
    fieldType: 'input',
    required: false
  }
};

export const merchantV2AchAccountSubFields = {
  debitOnly: {
    nameOnAccountDebitOnly: { ...achInfoBaseFields.nameOnAccount, id: 'nameOnAccountDebitOnly' },
    accountNumberDebitOnly: { ...achInfoBaseFields.accountNumber, id: 'accountNumberDebitOnly' },
    routingNumberDebitOnly: { ...achInfoBaseFields.routingNumber, id: 'routingNumberDebitOnly' },
    nameOfBankDebitOnly: { ...achInfoBaseFields.nameOfBank, id: 'nameOfBankDebitOnly' }
  },
  chargebackOnly: {
    nameOnAccountChargebackOnly: { ...achInfoBaseFields.nameOnAccount, id: 'nameOnAccountChargebackOnly' },
    accountNumberChargebackOnly: { ...achInfoBaseFields.accountNumber, id: 'accountNumberChargebackOnly' },
    routingNumberChargebackOnly: { ...achInfoBaseFields.routingNumber, id: 'routingNumberChargebackOnly' },
    nameOfBankChargebackOnly: { ...achInfoBaseFields.nameOfBank, id: 'nameOfBankChargebackOnly' }
  }
};

export const merchantV2AchAccountFields = (relationship, options) => {
  const { crabConfigurationOptions } = relationship || {};
  const { debitOnlyHidden, debitOnlyRequired } = options || {};
  const { ddaForChargebacksOnly } = crabConfigurationOptions || {};
  const hasOptions = !debitOnlyHidden || ddaForChargebacksOnly;
  return hasOptions
    ? {
      selectAchAccountRadio: { // FE-only radio
        id: 'selectAchAccountRadio',
        name: 'selectAchAccountRadio',
        label: 'Use a separate bank for',
        type: 'radio',
        fieldType: 'radio',
        wrapperStyle: { margin: 'unset' },
        fields: [ // FE-only values
          ...(!debitOnlyHidden ? [{ label: 'Debit transactions', value: 'use_debit_only' }] : []),
          ...(ddaForChargebacksOnly ? [{ label: 'DDA (Chargebacks Only)', value: 'use_chargeback_only' }] : [])
        ],
        required: false,
        controllerStateOverrides: {
          null: Object.values(merchantV2AchAccountSubFields) // reset all ach fields
            .reduce((acc, formObjs) => ([{ ...acc, ...formObjs }]), [])
            .reduce((acc, formObj) => ({
              ...acc,
              [formObj.id]: '',
              [`${formObj.id}IsValid`]: true
            }), {}),
          use_debit_only: {
          // when using debitOnly, the chargebackOnly fields should reset
            ...Object.entries(merchantV2AchAccountSubFields.chargebackOnly)
              .reduce((acc, formObj) => ({
                ...acc,
                [formObj.id]: '',
                [`${formObj.id}IsValid`]: true
              }), {}),
            // debitOnly fields are now required, reset them too
            ...Object.entries(merchantV2AchAccountSubFields.debitOnly)
              .reduce((acc, formObj) => ({
                ...acc,
                [formObj.id]: '',
                [`${formObj.id}IsValid`]: false
              }), {})
          },
          use_chargeback_only: {
          // when using chargebackOnly, the debitOnly fields should reset
            ...Object.entries(merchantV2AchAccountSubFields.debitOnly)
              .reduce((acc, formObj) => ({
                ...acc,
                [formObj.id]: '',
                [`${formObj.id}IsValid`]: true
              }), {}),
            // chargebackOnly fields are now required, reset them too
            ...Object.entries(merchantV2AchAccountSubFields.chargebackOnly)
              .reduce((acc, formObj) => ({
                ...acc,
                [formObj.id]: '',
                [`${formObj.id}IsValid`]: false
              }), {})
          }
        },
        controls: {
          use_debit_only: {
            debitAchInfoSectionHeader: { // debitOnly
              fieldType: 'formSectionHeader',
              id: `debitAchInfoSectionHeader`,
              title: 'ACH Information (Debit Only)',
              isSubSection: true,
              wrapperStyle: { borderTop: '1px solid black' }
            },
            ...merchantV2AchAccountSubFields.debitOnly
          },
          use_chargeback_only: {
            chargebackOnlySectionHeader: {
              fieldType: 'formSectionHeader',
              id: `chargebackOnlySectionHeader`,
              title: 'ACH Information (DDA For Chargebacks Only)',
              isSubSection: true,
              wrapperStyle: { borderTop: '1px solid black' }
            },
            ...merchantV2AchAccountSubFields.chargebackOnly
          }
        },
        ...(debitOnlyRequired && {
          tooltip: 'Required for this processor',
          required: true,
          disabled: true
        })
      }
    }
    : {};
};

export const merchantV2NmiFormFields = {
  nmiName: {
    id: 'nmiName',
    label: 'NMI Name',
    type: 'text',
    fieldType: 'input',
    required: true
  },
  nmiId: {
    id: 'nmiId',
    label: 'NMI Id',
    type: 'text',
    fieldType: 'input',
    required: true
  }
};

export const merchantV2PersonFormFields = {
  firstName: {
    id: 'firstName',
    label: 'First Name',
    type: 'text',
    fieldType: 'input'
  },
  lastName: {
    id: 'lastName',
    label: 'Last Name',
    type: 'text',
    fieldType: 'input'
  },
  dob: {
    id: 'dob',
    label: 'Date of Birth',
    type: 'date',
    fieldType: 'input'
  },
  ssn: {
    id: 'ssn',
    label: 'SSN',
    type: 'ssn',
    fieldType: 'input'
  },
  ownershipPercentage: {
    id: 'ownershipPercentage',
    label: 'Ownership Percentage',
    suffix: '%',
    type: 'percent',
    isRatio: true,
    fieldType: 'input'
  },
  homeStreetAddress: {
    id: 'homeStreetAddress',
    label: 'Home Street Address',
    type: 'text',
    required: false,
    fieldType: 'input'
  },
  city: {
    id: 'city',
    label: 'City',
    type: 'text',
    required: false,
    fieldType: 'input'
  },
  state: {
    ...sharedFormFields.state,
    required: false,
    fieldType: 'combobox',
    useBlockForm: true
  },
  zip: {
    ...sharedFormFields.zip,
    required: false
  },
  emailAddress: {
    id: 'emailAddress',
    label: 'Email Address',
    type: 'email',
    fieldType: 'input'
  },
  businessPhoneNumber: {
    id: 'businessPhoneNumber',
    label: 'Business Phone Number',
    type: 'tel',
    required: false,
    fieldType: 'input'
  },
  mobileNumber: {
    id: 'mobileNumber',
    label: 'Mobile Number',
    type: 'tel',
    required: false,
    fieldType: 'input'
  },
  county: {
    id: 'county',
    label: 'County',
    type: 'text',
    required: false,
    fieldType: 'input'
  },
  type: {
    id: 'type',
    label: 'Type',
    type: 'text',
    list: [
      { title: 'Primary', value: 'Primary' },
      { title: 'Beneficiary', value: 'Beneficiary' }
    ],
    fieldType: 'combobox',
    useBlockForm: true,
    required: false
  },
  title: {
    ...baseFormFields.title,
    id: 'title',
    label: 'Title',
    required: false
  },
  fullName: {
    id: 'fullName',
    label: 'Full Name',
    type: 'text',
    required: false,
    fieldType: 'input'
  }
};

const partnerV2FormWrapperStyles = {
  wrapperStyle: { flex: '100%' }
};

export const partnerV2FormFields = {
  partnerHeader: {
    id: 'partnerHeader',
    fieldType: 'formSectionHeader',
    title: 'Partner'
  },
  name: {
    id: 'name',
    label: 'Name',
    type: 'text',
    fieldType: 'input',
    ...partnerV2FormWrapperStyles
  },
  businessDba: {
    id: 'businessDba',
    label: 'Business DBA',
    type: 'text',
    fieldType: 'input',
    ...partnerV2FormWrapperStyles
  },
  businessLegal: {
    id: 'businessLegal',
    label: 'Business Legal',
    type: 'text',
    fieldType: 'input',
    ...partnerV2FormWrapperStyles
  },
  partnerBusinessCode: {
    id: 'partnerBusinessCode',
    label: 'Partner Business Code',
    type: 'text',
    fieldType: 'input',
    errorMessage: 'Must be unique, must be 4 digits, and must start with 1',
    ...partnerV2FormWrapperStyles
  },
  ein: {
    ...sharedFormFields.ein,
    required: true,
    ...partnerV2FormWrapperStyles
  },
  riskReferralContact: {
    id: 'riskReferralContact',
    label: 'Risk Referral Contact',
    type: 'text',
    fieldType: 'input',
    required: false,
    ...partnerV2FormWrapperStyles
  },
  subname: {
    id: 'subname',
    label: 'Subname',
    type: 'text',
    fieldType: 'input',
    required: false,
    ...partnerV2FormWrapperStyles
  },
  proposedOutputsHeader: {
    id: 'proposedOutputsHeader',
    fieldType: 'formSectionHeader',
    title: 'Proposed Output'
  },
  averageApplicationsPerMonth: {
    id: 'averageApplicationsPerMonth',
    label: 'Average Apps Per Month',
    type: 'number',
    fieldType: 'input',
    ...partnerV2FormWrapperStyles
  },
  averageVolumePerMidPerMonth: {
    id: 'averageVolumePerMidPerMonth',
    label: 'Average Volume Per MID Per Month',
    type: 'price',
    fieldType: 'input',
    ...partnerV2FormWrapperStyles
  },
  crabConfigurationOptionsHeader: {
    id: 'crabConfigurationOptionsHeader',
    fieldType: 'formSectionHeader',
    title: 'CRAB Configuration Options'
  },
  ddaForChargebacksOnly: {
    id: 'ddaForChargebacksOnly',
    name: 'ddaForChargebacksOnly',
    label: 'DDA For Chargebacks Only',
    type: 'radio',
    fieldType: 'radio',
    required: true,
    fields: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
    tooltip: `The partner's Repay apps can use a DDA for chargebacks only`
  },
  merchantChargebackNotificationEmail: {
    ...baseRepayFields.chargebackNotificationEmailAddress,
    ...partnerV2FormWrapperStyles,
    id: 'merchantChargebackNotificationEmail',
    label: 'Merchant Chargeback Notification Email (Default)',
    required: false
  },
  suppressStatementsDefault: {
    ...baseRepayFields.suppressStatement,
    ...partnerV2FormWrapperStyles,
    id: 'suppressStatementsDefault',
    label: 'Suppress Statement (Default)',
    tooltip: `Sets the default value for the "Suppress Statement" field on all new MPAs for the partner's Repay apps.`,
    required: false
  },
  contactInfoHeader: {
    id: 'contactInfoHeader',
    fieldType: 'formSectionHeader',
    title: 'Contact Information'
  },
  ownerName: {
    id: 'ownerName',
    label: 'Owner Name',
    type: 'text',
    fieldType: 'input',
    ...partnerV2FormWrapperStyles
  },
  address: {
    id: 'address',
    label: 'Address',
    type: 'text',
    fieldType: 'input',
    ...partnerV2FormWrapperStyles
  },
  address2: {
    id: 'address2',
    label: 'Address 2',
    type: 'text',
    fieldType: 'input',
    required: false,
    ...partnerV2FormWrapperStyles
  },
  city: {
    id: 'city',
    label: 'City',
    type: 'text',
    fieldType: 'input'
  },
  state: {
    id: 'state',
    label: 'State',
    type: 'text',
    fieldType: 'combobox',
    list: stateCodes.map(val => ({ title: val, value: val })),
    useBlockForm: true
  },
  zip: {
    ...sharedFormFields.zip,
    required: true
  },
  portalEmail: {
    id: 'portalEmail',
    label: 'Portal Email',
    type: 'email',
    fieldType: 'input',
    ...partnerV2FormWrapperStyles
  },
  portalPhone: {
    id: 'portalPhone',
    label: 'Portal Phone',
    type: 'tel',
    fieldType: 'input',
    ...partnerV2FormWrapperStyles
  },
  supportEmail: {
    id: 'supportEmail',
    label: 'Support Email',
    type: 'email',
    fieldType: 'input',
    ...partnerV2FormWrapperStyles
  },
  supportPhone: {
    id: 'supportPhone',
    label: 'Support Phone',
    type: 'tel',
    fieldType: 'input',
    ...partnerV2FormWrapperStyles
  },
  ownerEmail: {
    id: 'ownerEmail',
    label: 'Owner Email',
    type: 'email',
    fieldType: 'input',
    ...partnerV2FormWrapperStyles
  },
  ownerPhone: {
    id: 'ownerPhone',
    label: 'Owner Phone',
    type: 'tel',
    fieldType: 'input',
    ...partnerV2FormWrapperStyles
  },
  residualEmail: {
    id: 'residualEmail',
    label: 'Residual Email',
    type: 'email',
    fieldType: 'input',
    ...partnerV2FormWrapperStyles
  },
  closureEmail: {
    id: 'closureEmail',
    label: 'Closure Email',
    type: 'email',
    fieldType: 'input',
    ...partnerV2FormWrapperStyles
  },
  url: {
    id: 'url',
    label: 'URL',
    type: 'url',
    fieldType: 'input',
    required: false,
    ...partnerV2FormWrapperStyles
  },
  assignedRm: {
    id: 'assignedRm',
    label: 'Relationship Manager',
    type: 'string',
    fieldType: 'combobox',
    useBlockForm: true,
    required: false
  }
};

export const ticketFormFields = {
  assignedEmployeeId: {
    id: 'assignedEmployeeId',
    label: 'Assignee',
    type: 'text',
    list: [],
    fieldType: 'combobox',
    wrapperStyle: {
      ...globalFormProps.dropdown.wrapperStyle,
      margin: '-1px 0',
      flex: '50%'
    },
    useBlockForm: true,
    required: false
  },
  informOwnerViaEmail: {
    id: 'informOwnerViaEmail',
    name: 'informOwnerViaEmail',
    label: 'Inform Assignee via Email',
    tooltip: 'The assigned employee will receive an email with the ticket details and a link to the ticket',
    type: 'mini',
    fieldType: 'checkbox',
    required: false,
    wrapperStyle: { flex: '100%', margin: '0.5em' },
    height: 'auto'
  },
  description: {
    id: 'description',
    label: 'Description',
    type: 'textarea',
    fieldType: 'input',
    required: false,
    wrapperStyle: {
      flex: '100%',
      marginRight: 0,
      minHeight: 0
    }
  },
  employeeGroupIds: {
    id: 'employeeGroupIds',
    type: 'mini',
    list: [],
    fieldType: 'checkboxList',
    label: 'Who Can See This',
    required: true,
    labelPos: 'left',
    boxStyle: 'inside'
  },
  merchantGuid: {
    id: 'merchantGuid',
    label: 'Merchant',
    type: 'text',
    list: [],
    fieldType: 'combobox',
    required: false,
    useBlockForm: true
  },
  name: {
    id: 'name',
    label: 'Title',
    type: 'text',
    fieldType: 'input',
    wrapperStyle: {
      ...globalFormProps.input.wrapperStyle,
      flex: '100%'
    }
  },
  partnerId: {
    id: 'partnerId',
    label: 'Partner',
    type: 'text',
    list: [],
    fieldType: 'combobox',
    required: false,
    useBlockForm: true
  },
  priority: {
    id: 'priority',
    label: 'Priority',
    type: 'text',
    list: [
      { title: 'Severity 5', value: 'severity_5' },
      { title: 'Severity 4', value: 'severity_4' },
      { title: 'Severity 3', value: 'severity_3' },
      { title: 'Severity 2', value: 'severity_2' },
      { title: 'Severity 1', value: 'severity_1' },
      { title: 'Unprioritized', value: 'unprioritized' }
    ],
    fieldType: 'combobox',
    useBlockForm: true,
    wrapperStyle: {
      ...globalFormProps.dropdown.wrapperStyle,
      flex: '50%'
    }
  },
  relationshipId: {
    id: 'relationshipId',
    label: 'Relationship',
    type: 'text',
    list: [],
    fieldType: 'combobox',
    required: false,
    useBlockForm: true
  },
  status: {
    id: 'status',
    label: 'Status',
    type: 'text',
    list: [
      { title: 'Completed', value: 'completed', isComplete: true },
      { title: 'Duplicate', value: 'duplicate', isComplete: true },
      { title: 'In Progress', value: 'in_progress' },
      { title: 'Open', value: 'open' },
      { title: 'Snoozed', value: 'snoozed' },
      { title: 'Will Not Complete', value: 'will_not_complete', isComplete: true },
      { title: 'Waiting on Partner', value: 'waiting_on_partner' },
      { title: 'Waiting on Bank', value: 'waiting_on_bank' }
    ],
    fieldType: 'combobox',
    useBlockForm: true
  },
  requiredCompletionTime: {
    id: 'requiredCompletionTime',
    label: 'Required Completion Time',
    type: 'text',
    fieldType: 'input',
    valid: true
  },
  wakeUpDate: {
    id: 'wakeUpDate',
    label: 'Wake-Up Date',
    type: 'futureDate',
    fieldType: 'input',
    customValidation: validations.futureDate.test
  },
  snoozeReason: {
    id: 'snoozeReason',
    label: 'Reason for Snooze',
    type: 'textarea',
    fieldType: 'input'
  },
  ticketBusinessCode: {
    id: 'ticketBusinessCode',
    label: 'Ticket Number',
    type: 'text',
    fieldType: 'input'
  },
  category: {
    id: 'category',
    label: 'Category',
    type: 'text',
    list: [
      { title: 'Risk Unqueued', value: 'riskUnqueued' }
    ],
    fieldType: 'dropdown',
    required: false,
    wrapperStyle: {
      ...globalFormProps.dropdown.wrapperStyle,
      flex: '50%'
    }
  },
  createdBy: {
    id: 'createdBy',
    label: 'Created By',
    type: 'text',
    fieldType: 'input',
    valid: true // field is generated by the BE and never displayed
  },
  riskRuleId: {
    id: 'riskRuleId',
    label: 'Risk Rule',
    type: 'text',
    fieldType: 'input',
    required: false,
    wrapperStyle: {
      ...globalFormProps.input.wrapperStyle,
      flex: '100%'
    }
  },
  classification: {
    id: 'classification',
    label: 'Classification',
    type: 'text',
    list: [
      { title: 'True Positive', value: 'true_positive' },
      { title: 'False Positive', value: 'false_positive' },
      { title: 'True Negative', value: 'true_negative' },
      { title: 'False Negative', value: 'false_negative' }
    ],
    fieldType: 'combobox',
    useBlockForm: true,
    required: false,
    wrapperStyle: {
      ...globalFormProps.dropdown.wrapperStyle,
      flex: '100%'
    }
  }
};

export const userFormFields = {
  firstName: {
    id: 'firstName',
    label: 'First Name / Given Name',
    type: 'text',
    fieldType: 'input',
    required: true,
    wrapperStyle: {
      ...globalFormProps.input.wrapperStyle,
      flex: '100%'
    }
  },
  lastName: {
    id: 'lastName',
    label: 'Last Name / Family Name',
    type: 'text',
    fieldType: 'input',
    required: true,
    wrapperStyle: {
      ...globalFormProps.input.wrapperStyle,
      flex: '100%'
    }
  },
  email: {
    id: 'email',
    label: 'Email',
    type: 'email',
    fieldType: 'input',
    required: true,
    wrapperStyle: {
      ...globalFormProps.input.wrapperStyle,
      flex: '100%'
    }
  },
  manager: {
    id: 'manager',
    label: 'Manager',
    type: 'text',
    list: [],
    formField: true,
    useBlockForm: true,
    fieldType: 'combobox',
    required: true,
    wrapperStyle: {
      ...globalFormProps.dropdown.comboBox,
      flex: '100%'
    }
  },
  crabEscalationEmployee: {
    id: 'crabEscalationEmployee',
    label: 'Preferred CRAB Escalation Employee',
    type: 'text',
    useBlockForm: true,
    list: [],
    formField: true,
    fieldType: 'combobox',
    required: false,
    wrapperStyle: {
      ...globalFormProps.dropdown.comboBox,
      flex: '100%'
    }
  },
  triageEmployee: {
    fieldType: 'checkbox',
    type: 'mini',
    height: 'auto',
    name: 'triageEmployee',
    id: 'triageEmployee',
    label: 'Make this employee the default owner of tickets in their group(s)?',
    wrapperStyle: { margin: '0.5em 0.5em 0.5em 0.5em' }
  }
};

export const noteFormFields = {
  note: {
    id: 'note',
    label: 'Note',
    type: 'textarea',
    fieldType: 'input'
  },
  addToRelatedMerchants: {
    id: 'addToRelatedMerchants',
    name: 'addToRelatedMerchants',
    label: 'Add to Related Merchants',
    type: 'checkbox',
    fieldType: 'checkbox',
    labelStyle: { fontSize: '1.2rem' },
    required: false,
    wrapperStyle: { width: 'fit-content' }
  },
  addToRelatedRelationships: {
    id: 'addToRelatedRelationships',
    name: 'addToRelatedRelationships',
    label: 'Add to Related Relationships',
    type: 'checkbox',
    fieldType: 'checkbox',
    labelStyle: { fontSize: '1.2rem' },
    required: false,
    wrapperStyle: { width: 'fit-content' }
  },
  addToAllWithTheSameDba: {
    id: 'addToAllWithTheSameDba',
    name: 'addToAllWithTheSameDba',
    label: 'Add to all with the same DBA',
    type: 'checkbox',
    fieldType: 'checkbox',
    labelStyle: { fontSize: '1.2rem' },
    required: false,
    wrapperStyle: { width: 'fit-content' }
  },
  addToAllWithTheSameLegalName: {
    id: 'addToAllWithTheSameLegalName',
    name: 'addToAllWithTheSameLegalName',
    label: 'Add to all with the same Legal Name',
    type: 'checkbox',
    fieldType: 'checkbox',
    labelStyle: { fontSize: '1.2rem' },
    required: false,
    wrapperStyle: { width: 'fit-content' }
  },
  allowedEmployeeGroup: {
    id: 'allowedEmployeeGroup',
    name: 'allowedEmployeeGroup',
    label: 'Make this note visible to my department only',
    labelStyle: { fontSize: '1.2rem' },
    required: false,
    wrapperStyle: { width: 'fit-content' }
  }
};

export const riskAssessmentsFormFields = {
  riskAssessment: {
    id: 'riskAssessment',
    assessmentType: {
      id: 'assessmentType',
      label: 'Type',
      type: 'text',
      list: [
        { title: 'GRIP', value: 'GRIP' },
        { title: 'BRAM', value: 'BRAM' },
        { title: 'Chargebacks', value: 'Chargebacks' }
      ],
      useBlockForm: true,
      fieldType: 'dropdown'
    },
    assessmentValue: {
      id: 'assessmentValue',
      label: 'Value',
      type: 'price',
      prefix: '$',
      priceToNumber: true, // convert from string to number type for BE value
      fieldType: 'input',
      required: false
    },
    assessmentDate: {
      id: 'assessmentDate',
      label: 'Assessment Date',
      type: 'date',
      fieldType: 'input',
      dateOnly: true,
      required: false
    },
    noticeDate: {
      id: 'noticeDate',
      label: 'Notice Date',
      type: 'date',
      fieldType: 'input',
      dateOnly: true,
      required: false
    }
  }
};

export const merchantCloseFormFields = {
  closureReason: {
    id: 'closureReason',
    label: 'Closure Reason',
    type: 'text',
    list: [
      { title: 'ACH Rejects', value: 'ACH Rejects' },
      { title: 'Bank Request', value: 'Bank Request' },
      { title: 'Brand Request', value: 'Brand Request' },
      { title: 'Collections', value: 'Collections' },
      { title: 'Excessive Chargebacks', value: 'Excessive Chargebacks' },
      { title: 'MATCH', value: 'MATCH' },
      { title: 'Merchant Request', value: 'Merchant Request' },
      { title: 'Non-Compliance', value: 'Non-Compliance' }
    ],
    fieldType: 'dropdown',
    required: true
  },
  status: {
    id: 'status',
    label: 'Status',
    type: 'text',
    list: [
      { title: 'Closed', value: 'Closed' },
      { title: 'Soft Close', value: 'Soft Close' }
    ],
    fieldType: 'dropdown',
    required: true
  },
  riskTermination: {
    id: 'riskTermination',
    name: 'riskTermination',
    label: 'Risk Termination?',
    type: 'radio',
    fieldType: 'radio',
    fields: [
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' }
    ],
    required: true
  },
  riskDetails: {
    id: 'riskDetails',
    label: 'Risk Details',
    type: 'text',
    list: [
      { title: 'ACH Rejects', value: 'ACH Rejects' },
      { title: 'AMEX', value: 'AMEX' },
      { title: 'Bank Request', value: 'Bank Request' },
      { title: 'Discover', value: 'Discover' },
      { title: 'Failed to provide CHGBK reduction plan', value: 'Failed to provide CHGBK reduction plan' },
      { title: 'FCT (foreign card transactions)', value: 'FCT (foreign card transactions)' },
      { title: 'Gateway Non-Compliance', value: 'Gateway Non-Compliance' },
      { title: 'Inactive URL', value: 'Inactive URL' },
      { title: 'Ingredient Violation', value: 'Ingredient Violation' },
      { title: 'Laundering (MTL)', value: 'Laundering (MTL)' },
      { title: 'Mastercard', value: 'Mastercard' },
      { title: 'Mastercard Excessive Chargeback Program', value: 'Mastercard Excessive Chargeback Program' },
      { title: 'MATCH', value: 'MATCH' },
      { title: 'Merchant Requested Closure', value: 'Merchant Requested Closure' },
      { title: 'Negative Balance', value: 'Negative Balance' },
      { title: 'NOB Non-Compliant', value: 'NOB Non-Compliant' },
      { title: 'Non-Processing', value: 'Non-Processing' },
      { title: 'Prohibited Product', value: 'Prohibited Product' },
      { title: 'Retro-MATCH', value: 'Retro-MATCH' },
      { title: 'Suspicious Card Activity', value: 'Suspicious Card Activity' },
      { title: 'Visa', value: 'Visa' },
      { title: 'Visa Excessive Chargeback Program', value: 'Visa Excessive Chargeback Program' }
    ],
    fieldType: 'dropdown',
    required: true
  },
  waveEtfFee: {
    id: 'waveEtfFee',
    name: 'waveEtfFee',
    label: 'Waive ETF Fee?',
    type: 'radio',
    fieldType: 'radio',
    fields: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' }
    ],
    required: true
  },
  etfFeeAmountDropdown: {
    id: 'etfFeeAmount',
    label: 'ETF Fee Amount',
    type: 'text',
    list: [
      // ETF list defined in component
    ],
    fieldType: 'dropdown'
  },
  etfFeeAmountInput: {
    id: 'etfFeeAmount',
    label: 'ETF Fee Amount',
    type: 'rationalNumber',
    fieldType: 'input'
  },
  matchReason: {
    id: 'matchReason',
    label: 'Match Reason',
    type: 'text',
    fieldType: 'input'
  },
  referringParty: {
    id: 'referringParty',
    label: 'Referring Party',
    type: 'text',
    fieldType: 'input'
  },
  dateOfMatchPlacement: {
    id: 'dateOfMatchPlacement',
    label: 'Date of Match Placement',
    type: 'date',
    fieldType: 'input',
    dateOnly: true
  },
  partnerClosureMessage: {
    id: 'partnerClosureMessage',
    label: 'Closure Message (Shown to Partners)',
    type: 'textarea',
    fieldType: 'input',
    required: false
  }
};

export const merchantCloseFormFieldsV2 = [
  {
    componentType: 'combobox',
    props: {
      ...globalFormProps.comboBox,
      ...merchantCloseFormFields.closureReason,
      wrapperStyle: {
        borderWidth: '0.5px',
        width: '100%',
        marginLeft: '1px'
      },
      useBlockForm: true
    }
  },
  {
    componentType: 'combobox',
    props: {
      ...globalFormProps.comboBox,
      ...merchantCloseFormFields.status,
      wrapperStyle: {
        borderWidth: '0.5px',
        width: '100%',
        marginLeft: '1px'
      },
      useBlockForm: true
    }
  },
  {
    componentType: 'radio',
    initialValue: 'No', // default value for the FE is No
    props: {
      ...globalFormProps.radio,
      ...merchantCloseFormFields.riskTermination,
      wrapperStyle: {
        borderWidth: '0.5px',
        width: '100%',
        marginLeft: '1px'
      }
    }
  },
  {
    componentType: 'combobox',
    props: {
      ...globalFormProps.comboBox,
      ...merchantCloseFormFields.riskDetails,
      wrapperStyle: {
        borderWidth: '0.5px',
        width: '100%',
        marginLeft: '1px'
      },
      useBlockForm: true
    }
  },
  {
    componentType: 'input',
    props: {
      ...globalFormProps.input,
      ...merchantCloseFormFields.matchReason,
      required: false,
      wrapperStyle: {
        borderWidth: '0.5px',
        marginLeft: '1px'
      },
      controlledBy: {
        controllerId: 'closureReason',
        controllerValue: 'MATCH',
        overrides: {
          required: true
        }
      }
    }
  },
  {
    componentType: 'input',
    props: {
      ...globalFormProps.input,
      ...merchantCloseFormFields.referringParty,
      required: false,
      wrapperStyle: {
        borderWidth: '0.5px',
        marginLeft: '1px'
      },
      controlledBy: {
        controllerId: 'closureReason',
        controllerValue: 'MATCH',
        overrides: {
          required: true
        }
      }
    }
  },
  {
    componentType: 'input',
    props: {
      ...globalFormProps.input,
      ...merchantCloseFormFields.dateOfMatchPlacement,
      required: false,
      wrapperStyle: {
        borderWidth: '0.5px',
        marginLeft: '1px'
      },
      controlledBy: {
        controllerId: 'closureReason',
        controllerValue: 'MATCH',
        overrides: {
          required: true
        }
      }
    }
  },
  {
    componentType: 'input',
    props: {
      ...merchantCloseFormFields.partnerClosureMessage,
      boxStyle: 'inside',
      wrapperStyle: {
        borderWidth: '0.5px',
        marginLeft: '1px',
        flex: '100%'
      }
    }
  }
];

export const riskRulesFormFields = {
  name: {
    id: 'name',
    label: 'Name',
    type: 'text',
    fieldType: 'input',
    required: true
  },
  description: {
    id: 'description',
    label: 'Description',
    type: 'textarea',
    fieldType: 'input',
    required: true
  },
  riskCategory: {
    id: 'riskCategory',
    label: 'Risk Category',
    type: 'text',
    list: [
      // list defined within component
    ],
    fieldType: 'dropdown',
    required: true
  },
  riskSubcategory: {
    id: 'riskSubcategory',
    label: 'Risk Subcategory',
    type: 'text',
    list: [
      // list defined within component
    ],
    fieldType: 'dropdown'
  },
  ruleStatus: {
    id: 'ruleStatus',
    label: 'Status',
    type: 'text',
    list: [
      { title: 'Enabled', value: 'enabled' },
      { title: 'Disabled', value: 'disabled' },
      { title: 'Discontinued', value: 'discontinued' }
    ],
    fieldType: 'dropdown',
    required: true
  },
  priority: {
    id: 'priority',
    label: 'Priority',
    type: 'text',
    list: [
      { title: 'Severity 5', value: 'severity_5' },
      { title: 'Severity 4', value: 'severity_4' },
      { title: 'Severity 3', value: 'severity_3' },
      { title: 'Severity 2', value: 'severity_2' },
      { title: 'Severity 1', value: 'severity_1' },
      { title: 'Unprioritized', value: 'unprioritized' }
    ],
    fieldType: 'dropdown'
  },
  autoCloseDays: {
    id: 'autoCloseDays',
    label: 'Close automatically in days',
    type: 'rationalNumber',
    fieldType: 'input',
    required: true
  },
  frequencyType: {
    id: 'frequencyType',
    label: 'When to run?',
    type: 'text',
    list: [
      { title: 'Run at a set time', value: 'frequencyExpression' },
      { title: 'Run when data loads', value: 'eventDriven' }
    ],
    fieldType: 'dropdown',
    required: true
  },
  frequencyExpression: {
    id: 'frequencyExpression',
    type: 'text',
    fieldType: 'dropdown'
  },
  processorName: {
    id: 'processorName',
    label: 'Processor Name',
    type: 'text',
    list: [
      // defined within component
    ],
    fieldType: 'dropdown'
  },
  dataType: {
    id: 'dataType',
    label: 'Data Type',
    type: 'text',
    list: [
      { title: 'Any', value: 'ALL' },
      { title: 'Authorization', value: 'AUTHORIZATION' },
      { title: 'Settlement', value: 'SETTLEMENT' },
      { title: 'Chargeback', value: 'CHARGEBACK' },
      { title: 'Reserve', value: 'RESERVE' },
      { title: 'ACH Reject', value: 'ACH_REJECT' },
      { title: 'ACH Deposit', value: 'ACH_DEPOSIT' },
      { title: 'Live Auth Data', value: 'LIVE_AUTH_DATA' }
    ],
    fieldType: 'dropdown'
  },
  primaryKeyType: {
    id: 'primaryKeyType',
    label: 'Primary Key Type',
    type: 'text',
    list: [
      { title: 'BIN ID', value: 'BIN_ID' },
      { title: 'Merchant GUID', value: 'MERCHANT_GUID' },
      { title: 'Merchant EIN', value: 'MERCHANT_EIN_ID' },
      { title: 'Partner ID', value: 'PARTNER_ID' },
      { title: 'Person ID', value: 'PERSON_ID' }
    ],
    required: true
  },
  sql: {
    id: 'sql',
    label: 'Rule SQL',
    type: 'textarea',
    fieldType: 'input',
    required: true,
    inputStyle: { minHeight: '200px' }
  }
};

export const binIdValidation = value => RegExp(/^[0-9]{6,8}$/).test(value);

export const binStatusFormFields = [
  {
    componentType: 'input',
    props: {
      ...globalFormProps.input,
      label: 'BIN ID',
      type: 'text',
      id: 'binId',
      required: true,
      customValidation: binIdValidation,
      errorMessage: 'Must be 6 to 8 digits long'
    }
  },
  {
    componentType: 'radio',
    initialValue: 'Y', // default value for the FE is Y
    props: {
      ...globalFormProps.radio,
      name: 'prohibited',
      id: 'prohibited',
      label: 'Prohibited',
      fields: [
        { label: 'Yes', value: 'Y' },
        { label: 'No', value: 'N' }
      ],
      required: true,
      wrapperStyle: { marginRight: 0 }
    }
  },
  {
    componentType: 'input',
    props: {
      ...globalFormProps.input,
      label: 'Description',
      type: 'textarea',
      id: 'description',
      required: false,
      errorMessage: 'Must be at least 10 characters'
    }
  }
];
