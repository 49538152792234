import { openDB } from 'idb';

const dbName = 'localStorage-DB';
const dbTableName = 'keyValuesStore';

export const upgradeFunc = (db, oldVersion, newVersion, transaction, event) => {
  // Called if this version of the database has never been opened before.
  // Use it to specify the schema for the database.
  db.createObjectStore(dbTableName);
};

const dbPromise = () => openDB(dbName, 1, {
  // OPTIONAL
  upgrade: upgradeFunc
  // blocked (currentVersion, blockedVersion, event) {
  //   // …
  // },
  // blocking (currentVersion, blockedVersion, event) {
  //   // …
  // },
  // terminated () {
  //   // …
  // }
});

const get = async (key, options = {}) => (await dbPromise())
  .get(options.tableName ?? dbTableName, key);

const set = async (key, value, options = {}) => (await dbPromise())
  .put(options.tableName ?? dbTableName, value, key);

const del = async (key, options = {}) => (await dbPromise())
  .delete(options.tableName ?? dbTableName, key);

const clear = async (key, options = {}) => (await dbPromise())
  .clear(options.tableName ?? dbTableName);

const keys = async (options = {}) => (await dbPromise())
  .getAllKeys(options.tableName ?? dbTableName);

export const Storage = {
  get,
  set,
  del,
  clear,
  keys
};

export default Storage;
