import React from 'react';
import PropTypes from 'prop-types';
import { formStylesCSS } from './_formStyles';

class FormCustomSection extends React.PureComponent {
  render () {
    const {
      ariaLabel,
      children,
      id,
      className,
      isInfoSection,
      isSubSection,
      wrapperStyle
    } = this.props;
    return (
      <div
        id={id}
        className={`customSection ${className}`}
        role="article"
        aria-label={ariaLabel}
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          ...formStylesCSS.formContentWrapper,
          ...(isInfoSection && {
            borderColor: 'none',
            borderWidth: '0',
            borderStyle: 'none'
          }),
          ...(isSubSection && { marginTop: '1px' }),
          ...wrapperStyle
        }}
      >
        { children }
      </div>
    );
  }
}

FormCustomSection.propTypes = {
  children: PropTypes.node,
  isSubSection: PropTypes.bool,
  isInfoSection: PropTypes.bool,
  wrapperStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  id: PropTypes.string
};

FormCustomSection.defaultProps = {
  children: null,
  isSubSection: false, // is this section a sub-section of a form section?
  isInfoSection: false, // custom section with no form fields (ie, buttons, text, etc)
  wrapperStyle: {},
  className: '',
  ariaLabel: null,
  id: null
};

export default FormCustomSection;
