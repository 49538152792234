import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from './_helpers';
import { NoData } from './NoData';

export class DataListInfo extends React.PureComponent {
  render () {
    const {
      id,
      data,
      style,
      title
    } = this.props;
    return (
      <div id={id} className="data-list-info-wrapper" style={{ display: 'flex', flexDirection: 'column', ...style }}>
        <hr />
        {!isEmpty(title) && (<div style={{ fontSize: '2rem', fontWeight: 'bold', margin: '0.4em' }}>{title}</div>)}
        {!isEmpty(data)
          ? (
            <ol className="data-list-info" style={{ fontSize: '1.4rem', margin: '0.4em' }}>
              {data.map(item => (
                <li key={item.title} style={{ marginBottom: '0.5em' }}>
                  <div className="title" style={{ fontWeight: 'bold', marginLeft: '1em' }}>{item.title}</div>
                  {!isEmpty(item.description) && (<div className="description" style={{ marginLeft: '2.5em' }}>{item.description}</div>)}
                </li>
              ))}
            </ol>
          )
          : <NoData wrapperStyles={{ margin: '0.4em' }} />}
        <hr />
      </div>
    );
  }
}

DataListInfo.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array]),
  id: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object]),
  title: PropTypes.string
};

DataListInfo.defaultProps = {
  data: [],
  id: null,
  style: {},
  title: null
};

export default DataListInfo;
