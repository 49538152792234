import { getFormattedRelationship, isEmpty, pad } from '../../_helpers';
import { prevetAppFormFields } from '../../_prevetFields';
import { sharedFormFields } from '../../_formFields';
import { createMockFileList } from '../sharedBoarding/mockBoardingApplicationFiles';
import { getMockPageData, getMockValue, mockPagingInfo } from '../_mockDataHelpers';
import { allStatusEnums } from '../sharedBoarding/templates/prevetTemplate';

export const mockPrevetsGet = (body, params, options) => {
  const {
    appCompletionStatus = 'uncompleted_only',
    totalNumberOfRecords = mockPagingInfo.totalNumberOfRecords,
    pageIndex = mockPagingInfo.pageIndex
  } = params || {};
  const { mockAllPrevets = [] } = options || {};
  const statusFilter = allStatusEnums[appCompletionStatus];
  const apps = mockAllPrevets
    .filter(app => statusFilter.includes(app.prevet.prevetStatus));
  const pageData = getMockPageData({
    allData: apps,
    pageIndex,
    pageSize: 25,
    optionsCopy: { ...params, pageSize: 25 }
  });
  return {
    prevets: pageData,
    pagingInfo: {
      totalNumberOfRecords,
      pageIndex,
      pageSize: 25
    }
  };
};

export const createMockPrevets = (options) => {
  const length = options?.length || 100;
  return Array.from({ length }).map((item, index) => (
    createMockPrevetRow(index, options)
  ));
};

export const createMockPrevetRow = (prevetIndex, options) => {
  const {
    staticApps = {},
    mockGuidToRelationshipMap,
    relationshipList,
    appReviewEmployeeList,
    userType
  } = options || {};
  const id = `${prevetIndex}`;
  const mockRelationshipId = mockGuidToRelationshipMap?.[id] || getMockValue({
    type: 'list',
    list: relationshipList
  });
  const mockRelationshipMatch = relationshipList?.find(r => r.value === mockRelationshipId) || {};
  const hasStaticAppData = !isEmpty(staticApps[id]);
  const mockRelationship = getFormattedRelationship(hasStaticAppData
    ? staticApps[id].meta?.relationship || mockRelationshipMatch
    : mockRelationshipMatch);
  const mockRandomPrevetStatus = getMockValue({ type: 'list', list: ['waiting_on_app_review', 'proceed', 'decline', 'abandoned'] });
  const mockPrevetStatus = hasStaticAppData
    ? staticApps[id].meta?.prevetStatus || mockRandomPrevetStatus
    : mockRandomPrevetStatus;
  const mockIsCompleted = ['proceed', 'decline', 'abandoned'].includes(mockPrevetStatus);
  // Split eligibleBanks list to avoid dupes
  const mockEligibleBanks1 = getMockValue({ type: 'list', list: ['CYNERGY', 'CROSS_RIVER', 'FIFTH_THIRD', 'SYNOVUS', 'VIMAS'] });
  const mockEligibleBanks2 = getMockValue({ type: 'list', list: ['WELLS_FARGO', 'MVB', 'ESQUIRE', 'AXIOM', 'EVOLVE', 'MERRICK'] });
  const mockPrevetId = `mock-prevet-id-${id}`;
  const randomAppReviewEmployee = userType === 'employee' ? getMockValue({ type: 'list', list: appReviewEmployeeList }) : '';
  const mockAppReviewEmployeeMatch = userType === 'employee'
    ? appReviewEmployeeList?.find(item => item?.value === randomAppReviewEmployee) || {}
    : {};
  const mockPrevet = {
    prevet: {
      prevetId: mockPrevetId,
      prevetBusinessNumber: id,
      prevetName: `Mock prevet app name ${id}`,
      relationship: {
        relationshipId: mockRelationship.relationshipId,
        relationshipName: mockRelationship.relationshipName,
        relationshipCode: mockRelationship.relationshipCode,
        processorName: mockRelationship.processorName,
        bankName: mockRelationship.bankName,
        riskProfile: mockRelationship.riskProfile
      },
      partner: {
        partnerId: '0d4fc94e-2874-46d3-a091-addb5fe8f2e7',
        partnerBusinessCode: '1000',
        partnerName: 'Partner with Transaction Report Data'
      },
      prevetSubmittedTimestamp: getMockValue({ type: 'isoDate' }),
      prevetCompletedTimestamp: mockIsCompleted ? getMockValue({ type: 'isoDate' }) : null,
      prevetStatus: mockPrevetStatus,
      prevetRecommendation: getMockValue({ type: 'description', length: 'long' }),
      eligibleBanks: mockPrevetStatus === 'proceed' ? [mockEligibleBanks1, mockEligibleBanks2] : [],
      prevetJson: createMockPrevetJson({ staticApp: staticApps[id] }),
      employeeOnly: userType === 'employee'
        ? {
          riskProfile: mockRelationship.riskProfile,
          assignedAppReviewEmployee: {
            employeeId: mockAppReviewEmployeeMatch.employeeId,
            firstName: mockAppReviewEmployeeMatch.firstName,
            lastName: mockAppReviewEmployeeMatch.lastName,
            email: mockAppReviewEmployeeMatch.email
          }
        }
        : null,
      ...(staticApps[id] && { ...staticApps[id].meta })
    },
    filesList: createMockFileList({
      guidKey: 'prevetId',
      guid: mockPrevetId,
      staticFiles: staticApps[id] ? staticApps[id].filesList : undefined
    })
  };
  return mockPrevet;
};

export const createMockPrevetJson = (options) => {
  const { staticApp } = options || {};
  const { ownershipType } = prevetAppFormFields.additionalInfoSection || {};
  const mockBusinessAddress = getMockValue({ type: 'address' });
  const mockBusinessInfo = {
    legalName: getMockValue({ type: 'businessName' }),
    dbaName: getMockValue({ type: 'businessName' }),
    physicalAddress: mockBusinessAddress.fullAddress,
    city: mockBusinessAddress.cityName,
    state: mockBusinessAddress.stateCode,
    zip: mockBusinessAddress.zipCode,
    businessEmailAddress: getMockValue({ type: 'email', userType: 'external' }),
    url: getMockValue({ type: 'urlRequiredProtocol' }),
    productsAndServicesSold: getMockValue({ type: 'description' }),
    preferredMcc: getMockValue({ type: 'list', list: sharedFormFields.mccCode.list }),
    averageTimeToDelivery: getMockValue({ type: 'number', min: 1 }),
    ...(staticApp && staticApp.json && { ...staticApp.json.businessInfo })
  };
  const mockOwnershipType = getMockValue({ type: 'list', list: ownershipType.list });
  const mockAdditionalInfo = {
    stateIncorporated: getMockValue({ type: 'address' }).stateCode,
    monthAndYearStarted: `${getMockValue({ type: 'number', min: 1970, max: 2020 })}-${pad(getMockValue({ type: 'number', min: 1, max: 12 }))}`,
    ownershipType: ownershipType.list.reduce((acc, o) => ({
      ...acc,
      [o.value]: false,
      [mockOwnershipType]: true
    }), {}),
    averageMonthlySalesVolume: getMockValue({ type: 'number', min: 1000, max: 3000 }),
    averageTicket: getMockValue({ type: 'number', min: 1 }),
    highestTicketAmount: getMockValue({ type: 'number', min: 100, max: 300 }),
    equipmentOrECommPlatform: getMockValue({ type: 'description' }),
    averagePercentageOfRefunds: getMockValue({ type: 'ratio' }),
    averagePercentageOfChargebacks: getMockValue({ type: 'ratio' }),
    averagePercentageOfInternationalSales: getMockValue({ type: 'ratio' }),
    recurringTransactions: getMockValue({ type: 'boolean' }),
    salesMakeup: { // Total should equal 1
      swipedPercentage: 0.1,
      keyedPercentage: 0.2,
      mailOrderPercentage: 0.3,
      internetPercentage: 0.4
    },
    ...(staticApp && staticApp.json && { ...staticApp.json.additionalInfo })
  };
  return {
    businessInfo: mockBusinessInfo,
    additionalInfo: mockAdditionalInfo
  };
};

export const mockPrevetPut = (body, params, options) => {
  const { guid } = options || {};
  return { [guid]: { prevetId: 'mock-prevet-id-1' } };
};
