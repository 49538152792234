import { getMockValue } from '../_mockDataHelpers';

export const mockTicketsExternalCommunicationGet = (body, params, options) => {
  const { guid = 'empty' } = options;
  return {
    [guid]: {
      externalCommunicationList: [
        {
          externalCommunicationDescription: 'testDesc',
          ticketId: '12',
          ticketExternalCommunicationId: '11',
          ticketExternalCommunicationCreatedTimestamp: '2021-01-16 00:23:04Z',
          employee: {
            employeeId: '1234',
            firstName: 'testEmployee'
          },
          portalUser: {
            portalUserId: '5678',
            email: 'portalUserEmail@test.com'
          }
        },
        {
          externalCommunicationDescription: getMockValue({ type: 'string' }),
          ticketId: getMockValue({ type: 'string' }),
          ticketExternalCommunicationId: getMockValue({ type: 'string' }),
          ticketExternalCommunicationCreatedTimestamp: getMockValue({ type: 'isoDate' }),
          employee: {
            employeeId: getMockValue({ type: 'string' }),
            firstName: getMockValue({ type: 'string' })
          },
          portalUser: {
            portalUserId: getMockValue({ type: 'string' }),
            email: getMockValue({ type: 'email' })
          }
        }
      ]
    }
  };
};

export const mockTicketsExternalCommunicationPutRes = (body, params, options) => {
  const { guid = 'empty' } = options;
  return {
    [guid]: {
      ticketExternalCommunicationId: '55'
    }
  };
};

export default mockTicketsExternalCommunicationGet;
