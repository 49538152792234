import { isEmpty } from '../../_helpers';

export const filePreloadTemplate = {
  frontend: (schema, version) => {
    if (version === '1.0') {
      const {
        responseArray, // BE data
        requiredFilesList
      } = schema || {};
      if (isEmpty(responseArray)) { return []; }
      const formattedData = responseArray
        .filter(file => !(file?.fileRequired === false && isEmpty(file?.fileData)))
        .map((resFile) => {
          const requiredFileMatch = requiredFilesList
            .find(reqFile => (reqFile.replaceFileName === true && reqFile.useFileNameInput !== true
              ? resFile.fileName.startsWith(reqFile.backendKey)
              : reqFile.name === resFile.fileName)) || {};
          return {
            // backendKey is a reference key for a specific file type,
            // so we have a reference to it on FE on s3 response
            backendKey: requiredFileMatch?.backendKey,
            fileName: resFile.fileName,
            // TODO: remove the below conditional check when all files go to using s3Key
            ...(
              resFile.s3Key
                ? { s3Key: resFile.s3Key }
                : { absolutePath: resFile.absolutePath }
            ),
            uploadLink: resFile.url // to use for inserting this file into S3 at the specified path
          };
        });
      return formattedData;
    }
    return schema;
  },
  backend: (schema, version) => {
    if (version === '1.0') {
      const requestBody = {
        files: schema.map(file => file.name)
      };
      return requestBody;
    }
    return schema;
  }
};

export default filePreloadTemplate;
