export const initialState = {
  nestedMenus: {
    partnerTree: ''
  }
};

function appState (state = initialState, actions) {
  switch (actions.type) {
    case 'APP_STATE':
      return {
        ...state,
        ...(actions.nestedMenus && {
          nestedMenus: {
            ...state.nestedMenus,
            ...actions.nestedMenus
          }
        })
      };
    default:
      return state;
  }
}

export default appState;
