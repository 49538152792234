import React from 'react';
import { PropTypes } from 'prop-types';
import {
  ToolTipTip,
  ToolTipD3
} from '../index';
import {
  isEmpty
} from './_helpers';
import { Icon } from '../css/_styledComponents';
import { icons } from '../images/_icons';

export class ToolTip extends React.Component {
  constructor (props) {
    super(props);
    this.mounted = false;
    this.container = React.createRef();
    this.tooltip = React.createRef();
    this.tooltipD3 = React.createRef();
    this.state = {
      direction: [],
      tooltipStyle: {
        x: 0,
        y: 0,
        width: null,
        height: null,
        revealed: true
      }
    };
  }

  componentDidMount () {
    this.mounted = true;
  }

  componentDidUpdate (prevProps) {
    const { d3Position } = this.props;
    if (JSON.stringify(d3Position) !== JSON.stringify(prevProps.d3Position)) {
      this.setTooltipPosition();
    }
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  updateState = (state) => {
    this.mounted && this.setState(state);
  }

  setSide = (toggle) => {
    const {
      direction
    } = this.state;
    const newDirection = [...direction];

    if (toggle === 'verticalCenter' || toggle === 'horizontalCenter') {
      if (toggle === 'horizontalCenter') {
        'right' > -1 && newDirection.splice('right', 1);
        'left' > -1 && newDirection.splice('left', 1);
      } else {
        'top' > -1 && newDirection.splice('top', 1);
        'bottom' > -1 && newDirection.splice('bottom', 1);
      }
    } else {
      const opposite = () => {
        switch (toggle) {
          case 'right':
            return 'left';
          case 'left':
            return 'right';
          case 'top':
            return 'bottom';
          case 'bottom':
            return 'top';
          default:
            return 'center';
        }
      };
      if (newDirection.indexOf(toggle) === -1) {
        const flip = newDirection.indexOf(opposite());
        flip > -1 && newDirection.splice(flip, 1);
        newDirection.push(toggle);
      }
    }
    !isEmpty(newDirection) && this.updateState({ direction: newDirection });
  }

  setTooltipPosition = (e) => {
    const {
      tooltipStyle,
      direction
    } = this.state;
    const {
      tipPosition,
      d3Data,
      d3Position
    } = this.props;
    const isD3 = !isEmpty(d3Data);
    const tooltipWrapper = isD3
      ? this.tooltipD3.current.tip.current
      : this.tooltip.current.tip.current;
    const rect = tooltipWrapper.getBoundingClientRect();
    const borderPadding = 10;
    let positionArray;
    if (!isEmpty(direction)) {
      positionArray = direction;
    } else {
      positionArray = tipPosition ? tipPosition.split('-') : [];
    }
    const { width } = rect;
    const { height } = rect;
    let xOffset = width / 2;
    let yOffset = height / 2;
    const newState = {};
    newState.type = positionArray.join('');
    if (positionArray.indexOf('left') !== -1) {
      xOffset = width + borderPadding;
    }
    if (positionArray.indexOf('right') !== -1) {
      xOffset = -borderPadding;
    }
    if (positionArray.indexOf('top') !== -1) {
      yOffset = height + borderPadding;
    }
    if (positionArray.indexOf('bottom') !== -1) {
      yOffset = -borderPadding;
    }
    const parentContainer = {
      height: (window.innerHeight || document.documentElement.clientHeight),
      width: (window.innerWidth || document.documentElement.clientWidth),
      rect: document.documentElement.getBoundingClientRect(),
      isBrowser: true,
      scrollY: window.scrollY,
      scrollX: window.scrollX
    };
    const modalContainer = this.container.current.closest('#siteModal .innerWrap') ||
    this.container.current.closest('#modal .innerWrap');
    if (modalContainer) { // this tooltip is a child of the siteModal
      const modalContainerRect = modalContainer.getBoundingClientRect();
      parentContainer.rect = modalContainerRect;
      parentContainer.height = modalContainerRect.height;
      parentContainer.width = modalContainerRect.width;
      parentContainer.isBrowser = false;
      parentContainer.scrollY = modalContainer.scrollTop || 0;
      parentContainer.scrollX = modalContainer.scrollLeft || 0;
    }
    const sidebarContainer = this.container.current.closest('#sidebarContent .content.sidebarContent');
    if (sidebarContainer) { // this tooltip is a child of the sidepanel
      const sidebarContainerRect = sidebarContainer.getBoundingClientRect();
      parentContainer.rect = sidebarContainerRect;
      parentContainer.height = sidebarContainerRect.height;
      parentContainer.width = sidebarContainerRect.width;
      parentContainer.isBrowser = false;
      parentContainer.scrollY = 0;
      parentContainer.scrollX = 0;
    }

    // account for window scroll position when positioning tooltip
    const dsOffsetX = isD3 ? 0 : parentContainer.scrollX;
    const dsOffsetY = isD3 ? 0 : parentContainer.scrollY;
    const mouseX = isD3 ? d3Position.x
      : (e.clientX - (parentContainer.isBrowser ? window.scrollX : parentContainer.rect.x));
    const mouseY = isD3 ? d3Position.y
      : (e.clientY - (parentContainer.isBrowser ? window.scrollY : parentContainer.rect.y));
    const tipOffsetFromMouse = { y: yOffset, x: xOffset };

    const x = mouseX - tipOffsetFromMouse.x + dsOffsetX; // NON D3, ADDING scroll to this
    const y = mouseY - tipOffsetFromMouse.y + dsOffsetY;
    newState.x = x;
    newState.y = y;
    if (width > parentContainer.width - (borderPadding * 2)) {
      // wider than browser
      newState.x = borderPadding;
      newState.width = `calc(100vw - ${borderPadding * 2}px)`;
    } else if (mouseX < mouseX - x + borderPadding) {
      this.setSide('right'); // hitting left of page, flip to right
      newState.x = borderPadding;
    } else if (mouseX > (parentContainer.width) - (width - tipOffsetFromMouse.x + borderPadding)) {
      this.setSide('left'); // hitting right of page, flip to left
      newState.x = (
        (parentContainer.width) - (width - tipOffsetFromMouse.x) -
          tipOffsetFromMouse.x - borderPadding
      );
    } else {
      this.setSide('horizontalCenter');
    }
    if (height > parentContainer.height - (borderPadding * 2)) {
      // taller than browser
      newState.y = borderPadding;
      newState.height = `calc(${parentContainer.height} - ${borderPadding * 2}px)`;
      const body = document.querySelector('body');
      if (body) body.style.overflow = 'hidden';
    } else if (mouseY < mouseY - y + borderPadding) {
      this.setSide('bottom'); // hitting top of page, flip to bottom
      newState.y = borderPadding;
    } else if (mouseY > parentContainer.height - (height - tipOffsetFromMouse.y + borderPadding)) {
      this.setSide('top'); // hitting bottom of page, flip to top
      newState.y = (
        parentContainer.height - (height - tipOffsetFromMouse.y) -
          tipOffsetFromMouse.y - borderPadding
      );
    } else {
      this.setSide('verticalCenter');
    }
    this.updateState({
      tooltipStyle: {
        ...tooltipStyle,
        ...newState
      }
    });
  }

  handleMouseOver = (d) => {
    const {
      tooltipStyle
    } = this.state;
    this.updateState({
      tooltipStyle: {
        ...tooltipStyle,
        revealed: true
      }
    });
  }

  handleMouseMove = (e) => {
    this.setTooltipPosition(e);
  }

  handleMouseOut = (d) => {
    const {
      tooltipStyle
    } = this.state;
    this.updateState({
      d3Data: {
        type: '',
        data: null
      },
      tooltipStyle: {
        ...tooltipStyle,
        revealed: false
      }
    });
  }

  handleTouchEnd = (e, i) => {
    const {
      tooltipStyle: {
        revealed
      }
    } = this.state;
    if (revealed !== null) {
      this.handleMouseOut(e);
    } else {
      this.handleMouseOver(e);
    }
  }

  render () {
    const {
      tooltipStyle
    } = this.state;
    const {
      text,
      children,
      d3Data,
      element,
      options,
      infoTip,
      inline,
      iconColor,
      infoTipDisplay,
      isHtml,
      htmlDisplayStyle,
      wrapperStyle
    } = this.props;
    return (
      <div
        className="tooltip"
        ref={this.container}
        onMouseEnter={e => this.handleMouseOver(e)}
        onMouseMove={e => this.handleMouseMove(e)}
        onMouseLeave={e => this.handleMouseOut(e)}
        onTouchEnd={e => this.handleTouchEnd(e)}
        style={{
          ...(inline && {
            display: 'inline-block',
            position: 'relative',
            left: '5px',
            ...(infoTip && {
              top: '-1px'
            })
          }),
          ...wrapperStyle
        }}
      >
        { ((text && children) || infoTip) ? (
          <React.Fragment>
            <ToolTipTip
              ref={this.tooltip}
              tooltipStyle={tooltipStyle}
              isHtml={isHtml}
            >
              {/* the children will be the html */}
              {children}
            </ToolTipTip>
            {infoTip && (
              <Icon
                className="hasTip"
                icon={
                  infoTipDisplay?.backgroundImage
                    ? infoTipDisplay.backgroundImage
                    : icons.info.src_color
                }
                $useMask
                {...iconColor && {
                  color: iconColor
                }}
                style={{
                  ...(!inline && {
                    position: 'absolute',
                    right: '0',
                    top: '2px'
                  }),
                  minWidth: '15px',
                  minHeight: '15px',
                  width: '15px',
                  height: '15px',
                  ...infoTipDisplay
                }}
              />
            )}
            { htmlDisplayStyle && (
              <div
                className="hasTip"
                style={htmlDisplayStyle}
              />
            )}
            {(!infoTip && !htmlDisplayStyle) && (
              text
            )}
          </React.Fragment>
        ) : (
          !isEmpty(d3Data) && (
            <ToolTipD3
              ref={this.tooltipD3}
              d3Data={d3Data}
              tooltipStyle={tooltipStyle}
              element={element}
              options={options}
            />
          )
        )}
      </div>
    );
  }
}

ToolTip.propTypes = {
  text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  children: PropTypes.node,
  tipPosition: PropTypes.string,
  d3Data: PropTypes.oneOfType([PropTypes.object]),
  d3Position: PropTypes.oneOfType([PropTypes.object]),
  options: PropTypes.oneOfType([PropTypes.object]),
  element: PropTypes.oneOfType([PropTypes.object]),
  infoTip: PropTypes.bool,
  inline: PropTypes.bool,
  infoTipDisplay: PropTypes.oneOfType([PropTypes.object]),
  iconColor: PropTypes.string,
  isHtml: PropTypes.bool,
  htmlDisplayStyle: PropTypes.oneOfType([PropTypes.object]),
  wrapperStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

ToolTip.defaultProps = {
  text: null,
  children: null,
  tipPosition: 'bottom',
  d3Data: {},
  d3Position: {},
  options: {},
  element: {},

  // set "infoTip" to true, and instead of passing in text,
  // it will just render an "info" icon which will trigger this tooltip
  infoTip: false,

  // set "inline" to true to make the tooltip wrapper + icon be set to inline-block
  inline: false,

  // "infoTipDisplay", just allows you to pass in any custom css to "infoTip"
  // REQUIRED infoTip be true.  Can be used to do things like use a custom icon.
  infoTipDisplay: {},
  iconColor: null,

  // pass this IF the tooltip TEXT being passed in IS HTML, AND
  // Only if you do NOT want the default tooltip wrapper (the rounded blue box)
  isHtml: false,

  // almost the SAME as "infoTipDisplay".
  // Differences:
  // 1) replaces ALL the css infoTip nirmally has
  // 2) CANNOT be used WITH infoTip, infoTip will override this entirely.
  htmlDisplayStyle: null,
  wrapperStyle: {}
};

export default ToolTip;
