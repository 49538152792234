import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { mockPartnerV2DetailsGetRes } from '../../partner/v2/mockPartnersV2Get';

const getMockKnownSpecialty = () => {
  const mockYearMonthList = ['2020-08', '2020-07', '2020-06'];
  const mockPartnerRateList = Object.entries(mockPartnerV2DetailsGetRes)
    .map(([partnerId, partnerData]) => ({
      partner: {
        partnerId,
        partnerName: partnerData.partner.name
      },
      transactionalDataList: mockYearMonthList.map(yearMonth => ({
        yearMonth,
        salesCount: getMockValue({ type: 'number', min: 1, max: 20 }),
        refundCount: getMockValue({ type: 'number', min: 1, max: 20 }),
        chargebackCount: getMockValue({ type: 'number', min: 1, max: 20 }),
        salesVolume: getMockValue({ type: 'number', min: 10000, max: 90000 }),
        refundVolume: getMockValue({ type: 'number', min: 10000, max: 90000 }),
        chargebackVolume: getMockValue({ type: 'number', min: 10000, max: 90000 }),
        chargebackCountRatio: getMockValue({ type: 'ratio', maxDecimalCount: 4 }),
        chargebackVolumeRatio: getMockValue({ type: 'ratio', maxDecimalCount: 4 }),
        refundCountRatio: getMockValue({ type: 'ratio', maxDecimalCount: 4 }),
        refundVolumeRatio: getMockValue({ type: 'ratio', maxDecimalCount: 4 }),
        openProcessingMids: getMockValue({ type: 'number', min: 1, max: 20 }),
        openNonProcessingMids: getMockValue({ type: 'number', min: 1, max: 20 }),
        riskClosures: getMockValue({ type: 'number', min: 1, max: 20 }),
        merchantRequestedClosures: getMockValue({ type: 'number', min: 1, max: 20 }),
        approvalRate: getMockValue({ type: 'ratio', maxDecimalCount: 4 }),
        pendRate: getMockValue({ type: 'ratio', maxDecimalCount: 4 }),
        pendsResolvedInRoundOneRate: getMockValue({ type: 'ratio', maxDecimalCount: 4 }),
        pendsResolvedInRoundTwoRate: getMockValue({ type: 'ratio', maxDecimalCount: 4 }),
        pendsResolvedInRoundThreeRate: getMockValue({ type: 'ratio', maxDecimalCount: 4 })
      })),
      pastTwoMonthAverage: {
        chargebackCountRatio: getMockValue({ type: 'ratio', maxDecimalCount: 4 }),
        chargebackVolumeRatio: getMockValue({ type: 'ratio', maxDecimalCount: 4 }),
        refundCountRatio: getMockValue({ type: 'ratio', maxDecimalCount: 4 }),
        refundVolumeRatio: getMockValue({ type: 'ratio', maxDecimalCount: 4 })
      }
    }));
  const mockIndustryRate = mockYearMonthList.map(yearMonth => ({
    yearMonth,
    salesCount: getMockValue({ type: 'number', min: 1, max: 20 }),
    refundCount: getMockValue({ type: 'number', min: 1, max: 20 }),
    chargebackCount: getMockValue({ type: 'number', min: 1, max: 20 }),
    salesVolume: getMockValue({ type: 'number', min: 10000, max: 90000 }),
    refundVolume: getMockValue({ type: 'number', min: 10000, max: 90000 }),
    chargebackVolume: getMockValue({ type: 'number', min: 10000, max: 90000 }),
    chargebackCountRatio: getMockValue({ type: 'ratio', maxDecimalCount: 4 }),
    chargebackVolumeRatio: getMockValue({ type: 'ratio', maxDecimalCount: 4 }),
    refundCountRatio: getMockValue({ type: 'ratio', maxDecimalCount: 4 }),
    refundVolumeRatio: getMockValue({ type: 'ratio', maxDecimalCount: 4 })
  }));
  return {
    partnerRateList: mockPartnerRateList,
    industryRate: mockIndustryRate
  };
};

export const mockTransactionReportRiskPartnerSpecialty = (body, params, options) => {
  const mockKnownSpecialty = getMockKnownSpecialty();
  return {
    knownSpecialty: mockKnownSpecialty
  };
};

export default mockTransactionReportRiskPartnerSpecialty;
