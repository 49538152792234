import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from '../../routing/withRouter';
import {
  getParams,
  ignoreCase,
  isEmpty,
  isEqual,
  sortData
} from '../../_helpers';
import {
  DataTable,
  Button,
  MultiSelect,
  Input,
  Spinner,
  CustomApiErr,
  ComboBox,
  RadioSwitch
} from '../../../index';
import { filterBarCSS } from '../../_styles';
import { crabAppStatusList } from '../../_crabFields';
import {
  isAppReviewEmployee,
  filterAppOnLoad
} from '../../data/sharedBoarding/templates/crabV1ApplicationTemplate';

export class BoundApplicationList extends React.Component {
  constructor (props) {
    super(props);
    this.mounted = false;
    const {
      ignoreUriParam,
      location,
      userType,
      employeeGroupList
    } = props;
    const { id: queriedAppId } = ignoreUriParam ? {} : getParams();
    this.completedAppStatuses = crabAppStatusList
      .filter(item => item.isCompleted === true)
      .map(item => item.value);
    this.defaultAppCompletionStatus = !isEmpty(queriedAppId) || (!isEmpty(location.state?.status) && this.completedAppStatuses.includes(location.state?.status)) ? 'all' : 'uncompleted_only';
    this.masterAppStatusList = [...crabAppStatusList];
    this.isAppReview = userType === 'employee' ? isAppReviewEmployee(employeeGroupList) : false;
    this.masterDefaultFilters = {
      partner: {
        relationshipTree: {
          type: 'multiSelect',
          filterKey: 'relationshipCode', // should match table column
          allChecked: true,
          listKey: 'relationshipTreeList', // should match list key name in state
          checkedItems: [],
          allItems: [],
          title: 'All'
        }
      },
      employee: {
        relationship: {
          type: 'comboBox',
          filterKey: 'relationship',
          searchByColumns: ['relationshipId'],
          selected: [],
          allItems: []
        },
        assignedEmployee: {
          type: 'comboBox',
          filterKey: 'assignedEmployee',
          selected: [],
          allItems: []
        }
      }
    };
    this.unassignedAppReviewOption = { title: 'Unassigned (App Review)', value: 'unassignedAppReview' };

    // Keep for viewing legacy apps
    this.unassignedCreditOption = { title: 'Unassigned (Credit)', value: 'unassignedCredit' };
    this.unassignedOpsOption = { title: 'Unassigned (Ops)', value: 'unassignedOps' };

    this.defaultFilters = {
      status: {
        type: 'comboBox',
        filterKey: 'status',
        selected: [],
        allItems: []
      },
      search: {
        type: 'input',
        filterKey: 'searchAll',
        title: '',
        value: ''
      },
      ...this.masterDefaultFilters[userType]
    };
    this.statusList = {
      uncompleted_only: this.masterAppStatusList.filter(
        item => (item.isCompleted !== true || item.isApprovedAndOpen === true)
      ),
      all: this.masterAppStatusList
    };
    this.downloadFileMap = {
      uncompleted_only: 'Open',
      all: 'All'
    };
    this.state = {
      spinnerLoading: false,
      err: false,
      status: null,
      allData: [],
      tableData: [],
      hiddenColumns: [],
      tablePrimaryKey: null,
      queriedAppId: !isEmpty(queriedAppId) ? queriedAppId : null,
      showAppCompletionStatusToggle: false,
      appCompletionStatus: this.defaultAppCompletionStatus,
      relationshipList: [],
      statusList: this.statusList[this.defaultAppCompletionStatus],
      relationshipTreeList: [],
      keepInitialFilters: null,
      initialFiltersOnLoad: [],
      filterKeys: [],
      filters: {
        ...this.defaultFilters
      }
    };
  }

  componentDidMount () {
    this.mounted = true;
    this.setAllData({ initialize: true });
  }

  componentDidUpdate (prevProps, prevState) {
    const { appCompletionStatus, queriedAppId } = this.state;
    const {
      riskProfileFilter,
      data,
      initialAppCompletionStatus,
      importFilters,
      navigate
    } = this.props;
    const appCompletionChanged = !isEqual(prevState.appCompletionStatus, appCompletionStatus);
    const filteredDataOnLoadChanged = JSON.stringify(prevProps.data?.filteredDataOnLoad) !==
      JSON.stringify(data?.filteredDataOnLoad);
    const relationshipListChanged = JSON.stringify(data?.dropLists?.relationshipList) !==
      JSON.stringify(prevProps.data?.dropLists?.relationshipList);
    if (JSON.stringify(data?.tableData) !== JSON.stringify(prevProps.data?.tableData) ||
    filteredDataOnLoadChanged || appCompletionChanged ||
    JSON.stringify(prevProps.data?.hiddenColumns) !== JSON.stringify(data?.hiddenColumns)) {
      this.setAllData({ appCompletionChanged });
    }
    if (!isEqual(importFilters, prevProps.importFilters)) {
      this.updateState({
        ...(!isEmpty(initialAppCompletionStatus) && {
          appCompletionStatus: initialAppCompletionStatus,
          showAppCompletionStatusToggle: false
        })
      }, () => {
        this.setAllData({ appCompletionChanged: true });
        this.updateState({ showAppCompletionStatusToggle: true });
      });
    }
    if (!isEqual(queriedAppId, prevState.queriedAppId) && isEmpty(queriedAppId)) {
      navigate(window.location.pathname.split('?')[0], { replace: true });
    }
    if (!isEmpty(riskProfileFilter) && relationshipListChanged) {
      this.handleRelationshipListChange();
    }
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  updateState = (state, callback = null) => {
    this.mounted && this.setState(state, callback);
  }

  handleRelationshipListChange = () => {
    // For employees, relationship list changes when risk profile radio switch changes
    const { data, userType } = this.props;
    const { dropLists = {} } = data || {};
    this.updateState({
      relationshipList: dropLists?.relationshipList
    }, userType === 'employee' ? () => this.clearFilters('relationship') : null);
  }

  getDefaultAppCompletion = (appRow) => {
    const { status } = appRow || {};
    const { queriedAppId, appCompletionStatus } = this.state;
    let newAppCompletionStatus = `${appCompletionStatus}`;
    let showAppCompletionStatusToggle = true;
    if (!isEmpty(queriedAppId)) {
      if (!isEmpty(appRow)) {
        const closedApp = this.statusList.all
          .find(statusItem => statusItem.title === status);
        if (!isEmpty(closedApp)) {
          newAppCompletionStatus = 'all';
        }
      } else { // queried app is not in the table data yet
        showAppCompletionStatusToggle = false;
      }
    }
    return {
      showAppCompletionStatusToggle,
      appCompletionStatus: newAppCompletionStatus,
      statusList: this.statusList[newAppCompletionStatus]
    };
  }

  setAllData = (options) => {
    const { initialize, appCompletionChanged } = options || {};
    const { importFilters, data } = this.props;
    const {
      tableData = [],
      filteredDataOnLoad = [],
      tablePrimaryKey = '',
      hiddenColumns = [],
      dropLists = {},
      multiSelectLists = {}
    } = data || {};
    const {
      assignedEmployeeList = [],
      relationshipList = []
    } = dropLists || {};
    const { queriedAppId, keepInitialFilters } = this.state;
    const filterByQueriedAppId = !isEmpty(queriedAppId);
    const queriedAppRowMatch = filterByQueriedAppId && !isEmpty(tableData)
      ? tableData.find(row => row.applicationId === queriedAppId)
      : {};
    const {
      showAppCompletionStatusToggle,
      statusList: currentStatusList,
      appCompletionStatus: currentAppCompletionStatus
    } = this.getDefaultAppCompletion(queriedAppRowMatch);
    const { allTableData, initialTableData, initialFilters } = this.getInitialData(tableData, {
      filteredDataOnLoad,
      currentAppCompletionStatus,
      filterByQueriedAppId,
      queriedAppRowMatch,
      assignedEmployeeList,
      statusList: currentStatusList
    });
    const shouldFilterData = !filterByQueriedAppId &&
      (appCompletionChanged || (keepInitialFilters && !initialize));
    this.updateState((prevState) => {
      const waitToSetFilters = filterByQueriedAppId && isEmpty(queriedAppRowMatch);
      const newFilterKeys = prevState.keepInitialFilters || waitToSetFilters
        ? prevState.filterKeys
        : [
          ...(!isEmpty(initialFilters?.relationship) ? ['relationship'] : []),
          ...(!isEmpty(initialFilters?.relationshipTree) ? ['relationshipTree'] : []),
          ...(!isEmpty(initialFilters?.status) ? ['status'] : []),
          ...(!isEmpty(initialFilters?.search) ? ['search'] : []),
          ...(!isEmpty(initialFilters?.assignedEmployee) ? ['assignedEmployee'] : [])
        ];
      const newFilters = prevState.keepInitialFilters || waitToSetFilters
        ? prevState.filters
        : {
          ...prevState.filters,
          ...(!isEmpty(multiSelectLists?.relationshipTree?.allItems) &&
        prevState.filters?.relationshipTree && {
            relationshipTree: {
              ...prevState.filters?.relationshipTree,
              allChecked: true,
              allItems: multiSelectLists.relationshipTree.allItems,
              checkedItems: initialFilters?.relationshipTree ||
              multiSelectLists.relationshipTree?.checkedItems || []
            }
          }),
          ...(!isEmpty(currentStatusList) && prevState.filters?.status && {
            status: {
              ...prevState.filters?.status,
              ...initialFilters?.status,
              allItems: currentStatusList
            }
          }),
          ...(!isEmpty(initialFilters?.search) && prevState.filters?.search && {
            search: {
              ...prevState.filters?.search,
              ...initialFilters?.search
            }
          }),
          ...(!isEmpty(assignedEmployeeList) && prevState.filters?.assignedEmployee && {
            assignedEmployee: {
              ...prevState.filters?.assignedEmployee,
              selected: initialFilters?.assignedEmployee || [],
              allItems: assignedEmployeeList
            }
          })
        };
      const newKeepInitialFilters = prevState.keepInitialFilters === null &&
        !appCompletionChanged && !waitToSetFilters
        ? true // on load, keep any initial filters in place
        : prevState.keepInitialFilters;
      const dataListMap = {
        ...(initialize && {
          ...(!isEmpty(multiSelectLists?.relationshipTree?.allItems) && {
            relationshipTreeList: multiSelectLists.relationshipTree.allItems // set filter bar list
          }),
          ...(!isEmpty(relationshipList) && {
            relationshipList: sortData(relationshipList.map(r => ({ ...r, title: `${r.relationshipCode} - ${r.title}` })), 'title')
          }),
          ...(!isEmpty(assignedEmployeeList) && {
            assignedEmployeeList: [
              this.unassignedAppReviewOption
            ]
              .concat(assignedEmployeeList)
          })
        }),
        ...((appCompletionChanged ||
          prevState.appCompletionStatus !== currentAppCompletionStatus) && {
          statusList: currentStatusList
        })
      };
      return {
        showAppCompletionStatusToggle,
        appCompletionStatus: currentAppCompletionStatus,
        keepInitialFilters: newKeepInitialFilters,
        allData: allTableData,
        tableData: initialTableData,
        tablePrimaryKey,
        hiddenColumns,

        // set filters
        filterKeys: newFilterKeys,
        filters: newFilters,
        ...(!isEmpty(queriedAppRowMatch) && { queriedAppId: '' }),
        initialFiltersOnLoad: initialFilters,

        // set lists for multiselect/combobox
        ...dataListMap
      };
    }, shouldFilterData ? this.filterData : null);
    !isEmpty(importFilters) && this.setFilterComboBox(importFilters);
  }

  getInitialData = (data, options) => {
    const {
      filteredDataOnLoad,
      assignedEmployeeList,
      statusList,
      currentAppCompletionStatus,
      queriedAppRowMatch,
      filterByQueriedAppId
    } = options || {};
    const {
      applyAssignedToFilters,
      alertBar,
      allAppsLoaded,
      userEmail,
      navigate
    } = this.props;
    const initialFilters = {};
    let initialData = !isEmpty(data) ? [...data] : [];
    const employeeGroupReviews = this.isAppReview;
    const filterOpenAppsEmployeeView = employeeGroupReviews && currentAppCompletionStatus === 'uncompleted_only';
    if (filterByQueriedAppId) {
      initialData = !isEmpty(queriedAppRowMatch) ? [{ ...queriedAppRowMatch }] : [];
      if (!isEmpty(queriedAppRowMatch)) {
        const filteredStatusItems = this.masterAppStatusList
          .filter(item => item.title === queriedAppRowMatch.status);
        initialFilters.search = {
          title: queriedAppRowMatch.applicationName,
          value: queriedAppRowMatch.applicationName
        };
        initialFilters.status = {
          selected: !isEmpty(filteredStatusItems) ? filteredStatusItems : []
        };
      } else if (allAppsLoaded && isEmpty(queriedAppRowMatch)) { // queried app does not exist
        initialData = !isEmpty(data) ? [...data] : [];
        alertBar('warning', 'Sorry, that application was not found in the application list.');
        navigate(window.location.pathname.split('?')[0], { replace: true });
        this.handleCallback({ queriedAppNotFound: true });
      }
    } else if (filterOpenAppsEmployeeView) {
      const defaultAssignedEmployee = !isEmpty(assignedEmployeeList)
        ? assignedEmployeeList.find(emp => emp?.email === userEmail) || {}
        : {};
      const filteredStatusItems = this.masterAppStatusList
        .filter(item => (
          (this.isAppReview && item.isAppReviewStatusFilter)
        ));
      const defaultStatuses = !isEmpty(statusList)
        ? statusList.filter(item => filteredStatusItems.some(s => s.value === item.value)) || {}
        : [];
      if (this.isAppReview) {
        initialData = filteredDataOnLoad || [];
      }
      initialFilters.assignedEmployee = applyAssignedToFilters ? [
        ...(this.isAppReview ? [this.unassignedAppReviewOption] : []),
        ...(!isEmpty(defaultAssignedEmployee) ? [defaultAssignedEmployee] : [])
      ] : [];
      initialFilters.status = {
        selected: !isEmpty(defaultStatuses) ? defaultStatuses : []
      };
    }
    return {
      allTableData: data,
      initialFilters,
      initialTableData: initialData
    };
  }

  handleCallback = (options) => {
    const { callback } = this.props;
    callback && callback(options);
  }

  radioSwitchCallback = (name, value, checked) => {
    const { toggleCompletedCallback } = this.props;
    const cbOptions = { clearSelected: true, firstPage: true, [name]: value };
    this.updateState({ [name]: value, keepInitialFilters: null }, () => {
      this.clearFilters(null, toggleCompletedCallback
        ? () => toggleCompletedCallback(cbOptions)
        : null);
    });
  }

  setFilterMultiSelect = (list = [], id) => {
    const { [`${id}List`]: multiSelectMasterList } = this.state;
    const isDefaultOption = list.some(item => item.guid === 'all') || false;
    if (isDefaultOption) {
      this.clearFilters(id);
    } else {
      const newCheckedItems = list.map((item) => {
        // get current item from master list to retain other fields (eg, relationshipCode, etc)
        const match = multiSelectMasterList?.find(masterItem => masterItem?.value ===
          item.guid) || {};
        return { ...match, value: item.guid, title: item.dba };
      });
      this.updateState(prevState => ({
        ...prevState,
        filterKeys: !prevState.filterKeys.includes(id) && !isEmpty(id)
          ? prevState.filterKeys.concat(id)
          : prevState.filterKeys,
        filters: {
          ...prevState.filters,
          [id]: {
            ...prevState.filters[id],
            type: 'multiSelect',
            allChecked: false,
            checkedItems: newCheckedItems,
            title: list.map(item => item.dba).join(', '),
            filterKey: this.defaultFilters[id]?.filterKey || id
          }
        }
      }), this.filterData);
    }
  }

  setFilterComboBox = (options) => {
    const { id, value } = options || {};
    const isDefaultOption = isEmpty(value);
    if (isDefaultOption) {
      this.clearFilters(id);
    } else {
      this.updateState(prevState => ({
        ...prevState,
        filterKeys: !prevState.filterKeys.includes(id) && !isEmpty(id)
          ? prevState.filterKeys.concat(id)
          : prevState.filterKeys,
        filters: {
          ...prevState.filters,
          [id]: {
            ...prevState.filters[id],
            type: 'comboBox',
            selected: value,
            filterKey: this.defaultFilters[id]?.filterKey || id
          }
        }
      }), this.filterData);
    }
  }

  setFilterInput = (id, value, valid) => {
    const newVal = ignoreCase(value);
    if (isEmpty(newVal)) {
      this.clearFilters(id);
    } else {
      this.updateState(prevState => ({
        ...prevState,
        filterKeys: !prevState.filterKeys.includes(id) && !isEmpty(id)
          ? prevState.filterKeys.concat(id)
          : prevState.filterKeys,
        filters: {
          ...prevState.filters,
          [id]: {
            ...prevState.filters[id],
            type: 'input',
            title: newVal,
            value: newVal,
            filterKey: this.defaultFilters[id]?.filterKey || id
          }
        }
      }), this.filterData);
    }
  }

  clearFilters = (id, clearAllFiltersCallback = null) => {
    const { allData } = this.state;
    if (typeof id === 'string' && !isEmpty(id)) { // clearing one specific filter
      this.updateState(prevState => ({
        ...prevState,
        filterKeys: prevState.filterKeys.filter(k => k !== id),
        filters: {
          ...prevState.filters,
          [id]: {
            ...this.defaultFilters[id],
            ...(prevState.filters[id]?.type === 'multiSelect' && {
              checkedItems: (prevState[prevState.filters[id]?.listKey] || [])
                .filter(item => isEmpty(item.parent))
            }),
            ...prevState.filters[id]?.allItems && { allItems: prevState.filters[id].allItems },
            ...(prevState.filters[id]?.type === 'comboBox' && { selected: [] })
          }
        }
      }), this.filterData);
    } else { // clear all
      this.updateState((prevState) => {
        const multiSelectDefaults = Object.entries(this.defaultFilters)
          .filter(([key, multiSelectObj]) => multiSelectObj.type === 'multiSelect')
          .reduce((acc, [key, multiSelectObj]) => ({
            ...acc,
            [key]: {
              ...multiSelectObj,
              ...prevState.filters[key]?.allItems && { allItems: prevState.filters[key].allItems },
              checkedItems: (prevState[prevState.filters[key]?.listKey] || [])
                .filter(item => isEmpty(item.parent)) || []
            }
          }), {});
        const comboBoxDefaults = Object.entries(this.defaultFilters)
          .filter(([key, comboBoxObj]) => comboBoxObj.type === 'comboBox')
          .reduce((acc, [key, comboBoxObj]) => ({
            ...acc,
            [key]: {
              ...comboBoxObj,
              ...prevState.filters[key]?.allItems && { allItems: prevState.filters[key].allItems },
              selected: []
            }
          }), {});
        return {
          ...prevState,
          allData,
          tableData: allData,
          filterKeys: [],
          filters: { ...this.defaultFilters, ...multiSelectDefaults, ...comboBoxDefaults }
        };
      }, clearAllFiltersCallback || null);
    }
  }

  filterData = () => {
    const {
      allData,
      hiddenColumns,
      filterKeys,
      filters
    } = this.state;
    const { userType } = this.props;
    if (isEmpty(filterKeys)) {
      this.updateState({ tableData: allData });
    } else {
      let newTableData = [...allData];
      filterKeys.forEach((filterKey, i) => {
        const {
          title,
          type,
          filterKey: filterKeyName,
          checkedItems = [],
          selected = [],
          allItems = [],
          searchByColumns = [] // column names to search on for dropdowns
        } = filters?.[filterKey] || {};
        if (isEmpty(newTableData) && i > 0) {
          newTableData = [];
        } else {
          newTableData = newTableData.filter((row) => {
            if (type === 'comboBox') {
              const columnMatch = !isEmpty(selected)
                ? selected.some((sel) => {
                  const { title: selectedTitle, value: selectedValue } = sel || {};
                  const isAssignedToFilter = filterKeyName === 'assignedEmployee';
                  const appReviewColVisible = !hiddenColumns.includes('Assigned To (App Review)');

                  // Keep for viewing legacy apps
                  const creditColVisible = !hiddenColumns.includes('Assigned To (Credit)');
                  const opsColVisible = !hiddenColumns.includes('Assigned To (Ops)');

                  const unassignedMatch = isAssignedToFilter && !isEmpty(selectedValue) && (
                    ( // Keep for viewing legacy apps
                      creditColVisible &&
                      isEqual(selectedValue, this.unassignedCreditOption.value) &&
                      isEmpty(row.assignedCreditEmployeeId)
                    ) || (
                      appReviewColVisible &&
                      isEqual(selectedValue, this.unassignedAppReviewOption.value) &&
                      isEmpty(row.assignedAppReviewEmployeeId)
                    ) || ( // Keep for viewing legacy apps
                      opsColVisible &&
                      isEqual(selectedValue, this.unassignedOpsOption.value) &&
                      isEmpty(row.assignedOperationsEmployeeId)
                    )
                  );
                  const assignedToMatch = isAssignedToFilter && !isEmpty(selectedValue) && (
                    ( // Keep for viewing legacy apps
                      creditColVisible &&
                      ignoreCase(selectedValue) === ignoreCase(row.assignedCreditEmployeeId)
                    ) || (
                      appReviewColVisible &&
                      ignoreCase(selectedValue) === ignoreCase(row.assignedAppReviewEmployeeId)
                    ) || ( // Keep for viewing legacy apps
                      opsColVisible &&
                      ignoreCase(selectedValue) === ignoreCase(row.assignedOperationsEmployeeId)
                    )
                  );
                  const titleMatch = !isEmpty(selectedTitle) &&
                    ignoreCase(selectedTitle) === ignoreCase(row[filterKeyName]);
                  const valueMatch = !isEmpty(selectedValue) &&
                    ignoreCase(selectedValue) === ignoreCase(row[filterKeyName]);
                  const columnHidden = hiddenColumns.includes(filterKeyName);
                  return unassignedMatch || assignedToMatch ||
                    ((titleMatch || valueMatch) && !columnHidden);
                })
                : false;
              const searchByMatch = !isEmpty(searchByColumns)
                ? searchByColumns.some((c) => {
                  const hasSelected = selected.some((sel) => {
                    const titleMatch = sel?.title === row[c];
                    const valueMatch = sel?.value === row[c];
                    return titleMatch || valueMatch;
                  });
                  return !isEmpty(row[c]) && hasSelected;
                })
                : false;
              return columnMatch || searchByMatch;
            }
            if (type === 'multiSelect') {
              const allMatches = checkedItems.reduce((acc, item) => {
                const { value: itemValue } = item || {};
                const currentItem = allItems.find(current => current?.value === itemValue) || {};
                const { isParent = false } = currentItem || {};
                const matches = isParent
                  // if checked item is a parent, also include its children
                  ? allItems.filter(current => current.parent?.value === itemValue ||
                  current?.value === itemValue)
                  : [{ ...currentItem }];
                return acc.concat(...matches);
              }, []);
              const columnValue = ignoreCase(row[filterKeyName]);
              const checked = allMatches
                .some(item => columnValue?.includes(ignoreCase(item.title)) ||
                ignoreCase(item.title).startsWith(columnValue));
              return checked;
            }
            // default case is for type 'input'
            return Object.values(row)
              .some(rowVal => (ignoreCase(rowVal).includes(ignoreCase(title))));
          });
        }
      });
      const filteredData = userType === 'employee' ? this.getReviewedByApps(newTableData) : newTableData;
      this.updateState({ tableData: filteredData });
    }
  }

  getReviewedByApps = (data) => {
    const {
      appCompletionStatus,
      filters: currentFilters,
      initialFiltersOnLoad,
      statusList
    } = this.state;
    const { userEmail, employeeGroupList } = this.props;
    if (this.isAppReview) {
      // On initial load, filter out apps that are NOT reviewed by the current employee
      // If risk profile filter changes, check if user did not change their default filters
      const selectedAssignedValues = (currentFilters?.assignedEmployee?.selected || [])
        .map(item => item.value);
      const defaultAssignedSelected = (initialFiltersOnLoad?.assignedEmployee || [])
        .map(item => item.value);
      const isFilteredOnDefaultAssignedTo = defaultAssignedSelected
        .every(value => selectedAssignedValues.includes(value));
      const selectedStatusValues = (currentFilters?.status?.selected || []).map(item => item.value);
      const startingStatusList = appCompletionStatus === 'all'
        ? statusList || []
        : initialFiltersOnLoad?.status?.selected || [];
      const statusArray = this.isAppReview
        ? startingStatusList.map(item => item.value)
        : [];
      const isFilteredOnDefaultStatus = !isEmpty(statusArray)
        ? statusArray.every(value => selectedStatusValues.includes(value))
        : [];
      if (isFilteredOnDefaultAssignedTo && isFilteredOnDefaultStatus) {
        const filtered = data.filter((item) => {
          const {
            assignedAppReviewEmployee,
            applicationStatus,
            relationship
          } = item?.appPackage || {};
          return filterAppOnLoad({
            employeeGroupList,
            relationship,
            appStatus: applicationStatus,
            userEmail,
            assignedAppReviewEmployee
          });
        });
        return filtered;
      }
    }
    return data;
  }

  handleAction = (actionType, rowId, rowData) => {
    const { deleteAppCallback } = this.props;
    if (actionType === 'deleteApp') {
      deleteAppCallback && deleteAppCallback(rowData);
    }
  }

  render () {
    const {
      spinnerLoading,
      err,
      status,
      allData,
      tableData,
      hiddenColumns,
      showAppCompletionStatusToggle,
      appCompletionStatus,
      tablePrimaryKey,
      relationshipTreeList,
      relationshipList,
      assignedEmployeeList,
      statusList,
      filters,
      filterKeys
    } = this.state;
    const {
      userType,
      riskProfileFilter,
      toggleCompletedCallback,
      allAppsLoaded,
      id,
      rowCallback
    } = this.props;
    return (
      <div
        id={id}
        className="application-list"
        role="article"
        aria-label="Application list"
        style={{
          height: '100%',
          width: '100%',
          position: 'relative'
        }}
      >
        <Spinner loading={spinnerLoading} />
        <div className="filter-bar-wrapper" style={{ ...filterBarCSS.wrapper, position: 'relative' }}>
          {!allAppsLoaded && (<Spinner loading={!allAppsLoaded} />)}
          <div
            className="filters"
            style={{
              ...filterBarCSS.filters,
              gap: '0.2em',
              alignItems: 'flex-start'
            }}
          >
            {!isEmpty(statusList) && (
              <ComboBox
                label="Status"
                placeholder="All"
                id="status"
                type="status"
                height={30}
                wrapperStyle={filterBarCSS.dropdown}
                list={sortData(statusList, 'title')}
                selected={filters.status?.selected}
                callback={this.setFilterComboBox}
                displaySearch
                isMulti
                formField
              />
            )}
            {userType === 'employee' && (
              <ComboBox
                label="Relationship"
                placeholder="All"
                id="relationship"
                type="relationship"
                height={30}
                wrapperStyle={filterBarCSS.dropdown}
                list={relationshipList}
                selected={filters.relationship?.selected}
                callback={this.setFilterComboBox}
                displaySearch
                isMulti
                formField
              />
            )}
            {!isEmpty(relationshipTreeList) && (
              <MultiSelect
                id="relationshipTree"
                label="Relationship"
                title={filters.relationshipTree?.title || 'All'}
                selected={filters.relationshipTree?.title || 'All'}
                wrapperStyle={filterBarCSS.multiSelect}
                height={30}
                list={relationshipTreeList}
                checkbox
                allChecked={filters.relationshipTree?.allChecked || false}
                checkedItems={filters.relationshipTree?.checkedItems}
                type="guid"
                callback={this.setFilterMultiSelect}
                searchKeys={[{ title: 'relationship name', value: 'title' }, { title: 'code', value: 'relationshipCode' }]}
              />
            )}
            {!isEmpty(assignedEmployeeList) && (
              <ComboBox
                label="Assigned To"
                placeholder="All"
                id="assignedEmployee"
                type="employee"
                height={30}
                wrapperStyle={filterBarCSS.dropdown}
                list={assignedEmployeeList}
                selected={filters.assignedEmployee?.selected}
                callback={this.setFilterComboBox}
                displaySearch
                isMulti
                formField
              />
            )}
            {showAppCompletionStatusToggle && !isEmpty(toggleCompletedCallback) && (
              <RadioSwitch
                radio={{
                  name: 'appCompletionStatus',
                  options: [
                    { value: 'uncompleted_only', text: 'Open', checked: appCompletionStatus === 'uncompleted_only' },
                    { value: 'all', text: 'All', checked: appCompletionStatus === 'all' }]
                }}
                theme={{ backgroundColor: 'var(--color-bg)' }}
                wrapStyle={{ marginTop: '24px', flex: '1' }}
                callback={this.radioSwitchCallback}
              />
            )}
            <Input
              label="Search"
              id="search"
              type="text"
              value={filters.search?.value}
              noValidate
              icon={filterBarCSS.searchIcon}
              wrapperStyle={filterBarCSS.searchInput}
              height={30}
              callback={this.setFilterInput}
            />
          </div>
          {!isEmpty(filterKeys) && (
            <Button
              id="clear-all-filters-button"
              style={{
                ...filterBarCSS.clearButton,
                height: '20px'
              }}
              type="text"
              onClick={this.clearFilters}
            >
              Clear Filters
            </Button>
          )}
        </div>
        <div className="application-list-wrapper">
          {!isEmpty(allData)
            ? (
              <DataTable
                hiddenColumns={hiddenColumns}
                primaryKey={tablePrimaryKey}
                // For employees, `passedData` should be ALL the table data
                // and `filtered` is the initial table data on load (with Status filtered)
                // this is needed so the download button works correctly
                passedData={userType === 'employee' ? allData : tableData}
                filtered={userType === 'employee' ? tableData : null}
                {...rowCallback && { rowCallback }}
                activeHighlight
                sortByEmptyFirst={userType === 'partner'}
                direction="desc"
                defaultSortCol="submitted"
                dataIsIncomplete={!allAppsLoaded}
                actionCallback={this.handleAction}
                dataGroup={userType === 'employee'
                  ? `${appCompletionStatus}_${riskProfileFilter}`
                  : appCompletionStatus
                }
                downloadFile={{
                  fileName: this.downloadFileMap[appCompletionStatus]
                    ? `Applications - ${this.downloadFileMap[appCompletionStatus]}${userType === 'employee' && !isEmpty(riskProfileFilter) ? (' - ').concat(riskProfileFilter) : ''}`
                    : `Applications${userType === 'employee' && !isEmpty(riskProfileFilter) ? (' - ').concat(riskProfileFilter) : ''}`
                }}
              />
            )
            : <CustomApiErr customErr={err} status={status} loading={spinnerLoading} />
          }
        </div>
      </div>
    );
  }
}

BoundApplicationList.propTypes = {
  id: PropTypes.string,
  riskProfileFilter: PropTypes.string,
  userEmail: PropTypes.string,
  employeeGroupList: PropTypes.oneOfType([PropTypes.array]),
  data: PropTypes.shape({
    tableData: PropTypes.oneOfType([PropTypes.array]),
    filteredDataOnLoad: PropTypes.oneOfType([PropTypes.array]),
    tablePrimaryKey: PropTypes.string,
    hiddenColumns: PropTypes.oneOfType([PropTypes.array]),
    dropLists: PropTypes.shape({
      relationshipList: PropTypes.oneOfType([PropTypes.array])
    }),
    multiSelectLists: PropTypes.shape({
      relationshipTree: PropTypes.oneOfType([PropTypes.object])
    })
  }),
  userType: PropTypes.oneOf(['partner', 'employee']),
  ignoreUriParam: PropTypes.bool,
  allAppsLoaded: PropTypes.bool,
  applyAssignedToFilters: PropTypes.bool,
  toggleCompletedCallback: PropTypes.func,
  callback: PropTypes.func,
  rowCallback: PropTypes.func,
  alertBar: PropTypes.func,
  importFilters: PropTypes.oneOfType([PropTypes.object]),
  initialAppCompletionStatus: PropTypes.string,
  location: PropTypes.shape({
    state: PropTypes.shape({
      status: PropTypes.string
    })
  }),
  navigate: PropTypes.func,
  deleteAppCallback: PropTypes.func
};

BoundApplicationList.defaultProps = {
  id: '',
  riskProfileFilter: '',
  userEmail: null,
  employeeGroupList: null,
  data: {
    tableData: [],
    filteredDataOnLoad: [], // Apps for employees who review crab on load
    hiddenColumns: [],
    tablePrimaryKey: '',
    dropLists: {
      relationshipList: []
    },
    multiSelectLists: {
      relationshipTree: {}
    }
  },
  userType: 'partner',
  ignoreUriParam: false,
  allAppsLoaded: false,
  applyAssignedToFilters: false, // employees only - set default filters for "assigned to" dropdown
  toggleCompletedCallback: null,
  callback: null,
  rowCallback: null,
  alertBar: () => {},
  importFilters: {},
  initialAppCompletionStatus: '',
  location: {
    state: {
      status: ''
    }
  },
  navigate: () => {},
  deleteAppCallback: () => {}
};

const ApplicationList = withRouter(BoundApplicationList);

export default withRouter(ApplicationList);
