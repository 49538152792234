import { createMockFile } from '@f1/shared/src/data/sharedBoarding/mockBoardingApplicationFiles';
import { domainName } from '@f1/shared/src/_helpers';
// import mockPDF from '../crab/mockPDF.pdf';

const mockCrabV1ToMpa = (body, params, options) => {
  const { guid = '' } = options || {};
  const staticFile = {
    fileName: 'mock-mpa-to-pdf',
    tags: [{ tagObject: 'uploaded_by_system' }, { tagObject: 'electronic_mpa' }],
    // url: mockPDF,
    url: `https://www.dev.${domainName}.com/signedMpa_1.pdf`,
    guidType: 'applicationMpaId',
    guidValue: guid
  };
  return {
    [guid]: { mpaPdf: createMockFile(1, { ...options, staticFile }) }
  };
};

export default mockCrabV1ToMpa;
