import React from 'react';
import PropTypes from 'prop-types';
import { tabContentCSS } from './_styles';

class TabContent extends React.PureComponent {
  render () {
    const {
      title,
      children,
      customStyle,
      id,
      customSectionStyle
    } = this.props;
    return (
      <div
        id={id}
        aria-label={title ? `${title} Content` : null}
        {...(title && { role: 'article' })}
        className="tabContent"
        style={{ ...tabContentCSS.tabContent, ...customStyle }}
      >
        <div style={{ ...tabContentCSS.section, ...customSectionStyle }}>
          { children }
        </div>
      </div>
    );
  }
}

TabContent.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  title: PropTypes.string,
  customStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  customSectionStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

TabContent.defaultProps = {
  children: null,
  id: null,
  title: null,
  customStyle: {},
  customSectionStyle: {}
};

export default TabContent;
