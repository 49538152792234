import React from 'react';
import PropTypes from 'prop-types';
import { isBool, isEmpty, isEqual } from '../../_helpers';
import { buildFormComponents, valueExists } from '../../_formHelpers';
import {
  FormAssistant,
  FormContainer,
  FormSectionHeader,
  Spinner
} from '../../../index';
import FormCustomSectionConfirmFields from '../../FormCustomSectionConfirmFields';
import FormCustomSectionPercentages from '../../FormCustomSectionPercentages';
import FormCustomSection from '../../FormCustomSection';
import { requireCardNotPresent } from '../../_crabPriorityHelpers';

class PriorityTab extends React.Component {
  constructor (props) {
    super(props);
    this.mounted = false;
    const {
      onlyUseFeeData,
      fields,
      isPublicRequest,
      data,
      disableFormFields
    } = props;
    this.tabKey = isPublicRequest ? 'processorFieldsTab' : 'priorityTab';
    this.cardNotPresentRef = React.createRef();
    this.defaultBuildOptions = { disableFormFields };

    this.showBusinessInformation = !onlyUseFeeData &&
      !isEmpty(fields?.priorityBusinessInformationSection);
    this.priorityBusinessInformationSection = this.showBusinessInformation
      ? buildFormComponents(
        data?.priorityBusinessInformationSection || {},
        fields?.priorityBusinessInformationSection,
        this.defaultBuildOptions
      )
      : {};

    this.showAchInfoAndFunding = !onlyUseFeeData &&
    !isEmpty(fields?.priorityAchInfoAndFundingSection);
    this.priorityAchInfoAndFundingSection = this.showAchInfoAndFunding
      ? buildFormComponents(
        data?.priorityAchInfoAndFundingSection || {},
        fields?.priorityAchInfoAndFundingSection,
        this.defaultBuildOptions
      )
      : {};

    this.showConfirmFundingAccountNumber = !onlyUseFeeData && this.showAchInfoAndFunding &&
    !isEmpty(fields?.priorityConfirmBillingAccountSection);
    this.priorityConfirmBillingAccountSection = data?.priorityConfirmBillingAccountSection;

    this.showFeaturesSection = !onlyUseFeeData &&
      !isEmpty(fields?.priorityFeaturesSection);
    this.priorityFeaturesSection = this.showFeaturesSection
      ? buildFormComponents(
        data?.priorityFeaturesSection || {},
        {
          ...fields?.priorityFeaturesSection,
          sponsorBanks: {
            ...fields?.priorityFeaturesSection?.sponsorBanks,
            ...(valueExists(
              data?.priorityFeaturesSection?.sponsorBanks,
              fields?.priorityFeaturesSection?.sponsorBanks
            ) && { disabled: true })
          }
        },
        this.defaultBuildOptions
      )
      : {};

    this.showPaymentProcessingAndSales = !onlyUseFeeData &&
      !isEmpty(fields?.priorityPaymentProcessingAndSalesSection);
    this.priorityPaymentProcessingAndSalesSection = this.showPaymentProcessingAndSales
      ? buildFormComponents(
        data?.priorityPaymentProcessingAndSalesSection || {},
        fields?.priorityPaymentProcessingAndSalesSection,
        this.defaultBuildOptions
      )
      : {};

    this.showCardNotPresent = !onlyUseFeeData &&
      !isEmpty(fields?.priorityCardNotPresentSection);
    this.priorityCardNotPresentSectionOptional = this.showCardNotPresent
      ? this.getPriorityCardNotPresentFields({ required: false })
      : {};
    this.priorityCardNotPresentSectionRequired = this.showCardNotPresent
      ? this.getPriorityCardNotPresentFields({ required: true })
      : {};
    this.showBillingMethod = !onlyUseFeeData && this.showCardNotPresent &&
      !isEmpty(fields?.priorityBillingMethodSection);
    this.priorityBillingMethodSection = this.showBillingMethod
      ? data?.priorityBillingMethodSection || {} // custom percents
      : {};

    this.showRatesAndFees = !isEmpty(fields?.priorityRatesAndFeesSection);
    this.priorityRatesAndFeesSection = this.showRatesAndFees
      ? buildFormComponents(
        data?.priorityRatesAndFeesSection || {},
        fields?.priorityRatesAndFeesSection,
        this.defaultBuildOptions
      )
      : {};

    this.showOtherFees = !isEmpty(fields?.priorityOtherFeesSection);
    this.priorityOtherFeesSection = this.showOtherFees
      ? buildFormComponents(
        data?.priorityOtherFeesSection || {},
        fields?.priorityOtherFeesSection,
        this.defaultBuildOptions
      )
      : {};

    this.state = {
      cardNotPresentRequired: this.isCardNotPresentRequired()
    };
  }

  componentDidMount () {
    this.mounted = true;
  }

  componentDidUpdate (prevProps) {
    const { otherTabData } = this.props;
    const orderChannelChanged = !isEqual(
      otherTabData?.orderChannelSection?.valuesForBackend,
      prevProps?.otherTabData?.orderChannelSection?.valuesForBackend
    );
    if (orderChannelChanged) {
      this.validateCardNotPresent();
    }
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  updateState = (state, callback = null) => {
    this.mounted && this.setState(state, callback);
  }

  handleFormChange = (newFormState, id, options) => {
    const { valueChangedData, valuesForBackend } = options || {};
    const {
      [id]: currentSectionState = {},
      priorityCardNotPresentSection,
      priorityAchInfoAndFundingSection
    } = this.state;
    const sectionChanged = isEmpty(currentSectionState) || // on load, or if a field changed
      (!isEmpty(valueChangedData) &&
      !isEqual(valueChangedData.frontendValue, currentSectionState?.[valueChangedData.id]));
    if (sectionChanged) {
      const billingMethodChanged = id === 'priorityBillingMethodSection';
      const priorityAchInfoAndFundingChanged = id === 'priorityAchInfoAndFundingSection' &&
        !isEqual(currentSectionState?.billingSameAsPrimary, newFormState?.billingSameAsPrimary);
      const cardNotPresentChanged = id === 'priorityCardNotPresentSection' &&
        !isEqual(currentSectionState?.useCardNotPresent, newFormState?.useCardNotPresent);
      this.priorityConfirmBillingAccountSection = priorityAchInfoAndFundingChanged && priorityAchInfoAndFundingSection?.billingSameAsPrimary === 'yes' &&
        newFormState?.billingSameAsPrimary === 'no'
        ? {}
        : this.priorityConfirmBillingAccountSection;
      if (billingMethodChanged) {
        this.priorityBillingMethodSection = { ...valuesForBackend };
      }
      this.updateState(prevState => ({
        [id]: {
          ...prevState[id],
          ...newFormState,
          ...(valuesForBackend && { valuesForBackend })
        },
        sectionsValid: {
          ...prevState.sectionsValid,
          [id]: isBool(newFormState?.fullSectionValid)
            ? newFormState.fullSectionValid
            : newFormState[id],
          ...(cardNotPresentChanged && newFormState?.useCardNotPresent === false && {
            /**
             * If NOT using "card not present", reset billingMethod section validity to true
             * since it no longer appears, and we don't need to know if its fields are valid.
             */
            priorityBillingMethodSection: true
          }),
          ...(priorityAchInfoAndFundingChanged && newFormState?.billingSameAsPrimary === 'yes' && {
            priorityConfirmBillingAccountSection: true
          })
        },
        sectionsInProgress: {
          ...prevState.sectionsInProgress,
          ...(typeof prevState[id] !== 'undefined' && {
            [id]: newFormState.formInProgress,
            ...((!priorityCardNotPresentSection?.useCardNotPresent ||
              (cardNotPresentChanged && newFormState?.useCardNotPresent === false)) && {
              /**
               * If NOT using "card not present", then the form should not register as in progress
               */
              priorityCardNotPresentSection: false
            }),
            ...(priorityAchInfoAndFundingChanged && newFormState?.billingSameAsPrimary === 'yes' && {
              priorityConfirmBillingAccountSection: false
            })
          })
        }
      }), () => this.handleCallback(id));
    }
  }

  handleCallback = (sectionId) => {
    const { callback } = this.props;
    const { [sectionId]: currentSectionState, sectionsValid, sectionsInProgress } = this.state;
    if (!isEmpty(currentSectionState)) {
      const cbOptions = {
        sectionChangedData: { sectionId, sectionState: currentSectionState },
        tabKey: this.tabKey,
        tabData: { [sectionId]: currentSectionState },
        tabValid: !isEmpty(sectionsValid) &&
          Object.values(sectionsValid).every(val => val === true),
        tabInProgress: !isEmpty(sectionsInProgress) &&
          Object.values(sectionsInProgress).some(val => val === true)
      };
      callback && callback(cbOptions);
    }
  }

  getPriorityCardNotPresentFields = (options) => {
    const { required } = options || {};
    const { data, fields } = this.props;
    const newFields = {
      ...fields?.priorityCardNotPresentSection,
      ...(required && {
        useCardNotPresent: {
          ...fields?.priorityCardNotPresentSection?.useCardNotPresent,
          label: 'Card Not Present (Required)',
          required: true,
          disabled: true,
          value: true
        }
      })
    };
    const currentData = data?.priorityCardNotPresentSection || {};
    return buildFormComponents(
      currentData,
      newFields,
      this.defaultBuildOptions
    );
  }

  validateCardNotPresent = () => {
    const { priorityCardNotPresentSection } = this.state;
    const { onlyUseFeeData } = this.props;
    if (!onlyUseFeeData) {
      const { useCardNotPresent } = priorityCardNotPresentSection || {};
      const isRequired = this.isCardNotPresentRequired();
      this.updateState({
        cardNotPresentRequired: isRequired
      }, () => {
        this.cardNotPresentRef && this.cardNotPresentRef.current.updateValues({
          ...priorityCardNotPresentSection,
          useCardNotPresent: isRequired
            ? true
          // Else set to prior value if it exists
            : useCardNotPresent || false,
          useCardNotPresentIsValid: true
        });
      });
    }
  }

  isCardNotPresentRequired = () => {
    const { onlyUseFeeData, otherTabData } = this.props;
    const { orderChannelSection } = otherTabData || {};
    const backendData = orderChannelSection?.valuesForBackend || {};
    const options = { onlyUseFeeData };
    return requireCardNotPresent(backendData, options);
  }

  render () {
    const {
      allowEmpty,
      fields,
      isPublicRequest,
      spinnerLoading,
      validateFields,
      wrapperStyle
    } = this.props;
    const {
      cardNotPresentRequired,
      priorityCardNotPresentSection,
      priorityAchInfoAndFundingSection
    } = this.state;
    const useCardNotPresent = priorityCardNotPresentSection?.useCardNotPresent === true;
    return (
      <FormContainer id={this.tabKey} type="formWithSections" wrapperStyle={wrapperStyle}>
        <Spinner loading={spinnerLoading} />
        {this.showBusinessInformation && (
          <>
            <FormSectionHeader title="Business Information" />
            <FormAssistant
              componentLabelInside
              id={fields?.priorityBusinessInformationSection?.id}
              formComponents={this.priorityBusinessInformationSection}
              callback={this.handleFormChange}
              allowEmpty={allowEmpty}
              validateFields={validateFields}
            />
          </>
        )}
        {this.showAchInfoAndFunding && (
          <>
            <FormSectionHeader title="ACH Info & Funding" />
            <FormAssistant
              componentLabelInside
              id={fields?.priorityAchInfoAndFundingSection?.id}
              formComponents={this.priorityAchInfoAndFundingSection}
              callback={this.handleFormChange}
              allowEmpty={allowEmpty}
              validateFields={validateFields}
            />
            {this.showConfirmFundingAccountNumber && priorityAchInfoAndFundingSection
              ?.valuesForBackend?.billingSameAsPrimary === false && (
              <FormCustomSectionConfirmFields
                componentLabelInside
                id={fields?.priorityConfirmBillingAccountSection?.id}
                fields={fields?.priorityConfirmBillingAccountSection}
                callback={this.handleFormChange}
                data={this.priorityConfirmBillingAccountSection}
                defaultBuildOptions={this.defaultBuildOptions}
                allowEmpty={allowEmpty}
                validateFields={validateFields}
              />
            )}
          </>
        )}
        {this.showFeaturesSection && (
          <>
            <FormSectionHeader title="Features" />
            <FormAssistant
              componentLabelInside
              id={fields?.priorityFeaturesSection?.id}
              formComponents={this.priorityFeaturesSection}
              callback={this.handleFormChange}
              allowEmpty={allowEmpty}
              validateFields={validateFields}
            />
          </>
        )}
        {this.showPaymentProcessingAndSales && (
          <>
            <FormSectionHeader title="Payment Processing & Sales" />
            <FormAssistant
              componentLabelInside
              id={fields?.priorityPaymentProcessingAndSalesSection?.id}
              formComponents={this.priorityPaymentProcessingAndSalesSection}
              callback={this.handleFormChange}
              allowEmpty={allowEmpty}
              validateFields={validateFields}
            />
          </>
        )}
        {this.showCardNotPresent && (
          <>
            {cardNotPresentRequired
              ? (
                <FormAssistant
                  ref={this.cardNotPresentRef}
                  componentLabelInside
                  id={fields?.priorityCardNotPresentSection?.id}
                  formComponents={this.priorityCardNotPresentSectionRequired} // required
                  callback={this.handleFormChange}
                  allowEmpty={allowEmpty}
                  validateFields={validateFields}
                />
              )
              : (
                <>
                  <FormAssistant
                    ref={this.cardNotPresentRef}
                    componentLabelInside
                    id={fields?.priorityCardNotPresentSection?.id}
                    formComponents={this.priorityCardNotPresentSectionOptional} // optional
                    callback={this.handleFormChange}
                    allowEmpty={allowEmpty}
                    validateFields={validateFields}
                  />
                  {!useCardNotPresent && (
                    <FormCustomSection isInfoSection wrapperStyle={{ fontSize: '1.3rem', padding: '0 5px' }}>
                      <em>Select the checkbox above if applicable</em>
                    </FormCustomSection>
                  )}
                </>
              )}
            {this.showBillingMethod && useCardNotPresent && (
              <FormCustomSectionPercentages
                componentLabelInside
                id={fields?.priorityBillingMethodSection?.id}
                title="Billing Method"
                fields={fields?.priorityBillingMethodSection}
                callback={this.handleFormChange}
                data={this.priorityBillingMethodSection}
                defaultBuildOptions={this.defaultBuildOptions}
                allowEmpty={allowEmpty}
                validateFields={validateFields}
                {...(isPublicRequest && { wrapperStyle: { marginBottom: '-1px' }, inputWrapperStyle: { margin: '-1px' } })}
              />
            )}
          </>
        )}
        {this.showRatesAndFees && (
          <>
            <FormSectionHeader title="Fees" />
            <FormAssistant
              componentLabelInside
              id={fields?.priorityRatesAndFeesSection?.id}
              formComponents={this.priorityRatesAndFeesSection}
              callback={this.handleFormChange}
              allowEmpty={allowEmpty}
              validateFields={validateFields}
            />
          </>
        )}
        {this.showOtherFees && (
          <>
            <FormSectionHeader title="Other Fees" />
            <FormAssistant
              componentLabelInside
              id={fields?.priorityOtherFeesSection?.id}
              formComponents={this.priorityOtherFeesSection}
              callback={this.handleFormChange}
              allowEmpty={allowEmpty}
              validateFields={validateFields}
            />
          </>
        )}
      </FormContainer>
    );
  }
}

PriorityTab.propTypes = {
  onlyUseFeeData: PropTypes.bool,
  allowEmpty: PropTypes.bool,
  data: PropTypes.oneOfType([PropTypes.object]),
  otherTabData: PropTypes.oneOfType([PropTypes.object]),
  fields: PropTypes.oneOfType([PropTypes.object]),
  callback: PropTypes.func,
  disableFormFields: PropTypes.bool,
  spinnerLoading: PropTypes.bool,
  validateFields: PropTypes.bool,
  isPublicRequest: PropTypes.bool,
  wrapperStyle: PropTypes.oneOfType([PropTypes.object])
};

PriorityTab.defaultProps = {
  onlyUseFeeData: false, // MECO -> Merchant -> Fees, Partner -> Merchant Details -> Fees
  allowEmpty: false,
  data: {},
  otherTabData: {},
  fields: {},
  callback: () => {},
  disableFormFields: false,
  spinnerLoading: false,
  validateFields: false,
  isPublicRequest: false,
  wrapperStyle: {}
};

export default PriorityTab;
