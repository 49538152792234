import { isEmpty, url } from '../_helpers';

export const mockFileAttachToResourcePut = (body, params, options) => {
  const { guid = '' } = options || {};
  const mockFiles = [
    { fileName: 'mstile-150x150.png', absolutePath: '/', url: `${url}/dummyUploadLink` },
    { fileName: 'mstile-310x150.png', absolutePath: '/', url: `${url}/dummyUploadLink` },
    { fileName: 'mstile-310x310.png', absolutePath: '/', url: `${url}/dummyUploadLink` }
  ];
  const { files = [] } = body || {};
  const filesToMap = !isEmpty(files) ? files : mockFiles;
  return {
    [guid]: {
      files: filesToMap.map(file => ({
        fileName: file.fileName || 'cake.png',
        relativePath: file.relativePath || '/COMMON/',
        url: `${url}/dummyUploadLink` // this is the uploadLink
      }))
    }
  };
};

export default mockFileAttachToResourcePut;
