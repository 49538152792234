export const mockNegativeActionGet = () => {
  const mockData = [
    { categoryType: 'category', value: 'A reason to decline' }, // needed for tests
    { categoryType: 'category', value: 'more reasons' }, // needed for tests
    { categoryType: 'sub_category', value: 'a sub category decline' } // needed for tests
  ];
  return { negativeActionList: mockData };
};

export default mockNegativeActionGet;
