import { getFormattedRelationship } from '../../_helpers';
import { crabAppStatusList } from '../../_crabFields';
import { getMockValue, mockPagingInfo, getMockPageData } from '../_mockDataHelpers';
import { mockMpaMetadataList } from '../sharedBoarding/mockBoardingApplicationMpaList';
import { mockBoardingApplicationEventHistoryFull } from '../sharedBoarding/mockBoardingApplicationEventHistory';
import { buildOwners } from '../sharedBoarding/mockBoardingApplicationJson';

export const createMockApplications = (options) => {
  const length = options?.length || 100;
  const mockApps = Array.from({ length }).map((item, index) => {
    const dataRow = createMockApplicationRow(index + 1, {
      ...options,
      isCompleted: index > length / 2
    });
    return dataRow;
  });
  return mockApps;
};

const createMockEmployeeObject = (options) => {
  const { employee, employeeGroup, userType } = options || {};
  return {
    employeeId: userType === 'employee' ? employee.employeeId : getMockValue({ type: 'guid' }),
    department: employeeGroup,
    firstName: userType === 'employee' ? employee.firstName : null, // required for employee, null for partner
    email: userType === 'employee' ? employee.email : null // required for employee, null for partner
  };
};

export const createMockApplicationRow = (id, options) => {
  const {
    isCompleted,
    staticApps,
    mockGuidToRelationshipMap,
    mockGuidToPartnerMap, // employee only
    mockAppDetailsOverrides,
    employeeList = { appReview: [] }, // employee only
    relationshipList,
    userType, // one of: employee, partner
    isAppDetailsCall = false
  } = options || {};
  const { // Any overrides we want set for app details mock data
    applicationStatus,
    assignedAppReviewEmployeeId,
    dbaNameList,
    signatureType,
    underwritingRiskLevel
  } = mockAppDetailsOverrides || {};
  const mockRelationshipId = mockGuidToRelationshipMap?.[id] || getMockValue({
    type: 'list',
    list: relationshipList
  });
  const mockRelationship = relationshipList?.find(r => r.value === mockRelationshipId) || {};
  const mockAppStatus = applicationStatus || staticApps?.[id]?.applicationStatus || getMockValue({
    type: 'list',
    list: isCompleted
      ? crabAppStatusList
        .filter(item => item.isCompleted === true)
      : crabAppStatusList
        .filter(item => item.isCompleted !== true)
  });
  const randomAppReviewEmployee = userType === 'employee'
    ? assignedAppReviewEmployeeId || getMockValue({ type: 'list', list: employeeList.appReview })
    : '';
  const mockAppReviewEmployeeMatch = userType === 'employee'
    ? employeeList.appReview?.find(item => item?.value === randomAppReviewEmployee) || {}
    : {};
  const mockSignatureType = signatureType || (staticApps && staticApps[id]?.signatureType
    ? staticApps[id].signatureType
    : getMockValue({ type: 'list', list: ['electronic_signature', 'wet_signature'] }));
  const mockRequiresNewSignature = staticApps && staticApps[id]?.requiresNewSignature
    ? staticApps[id].signatureType
    : false;
  const mockFormattedRelationship = getFormattedRelationship(mockRelationship);
  const { riskProfile, processName } = mockFormattedRelationship || {};
  const assignedAppReviewEmployee = createMockEmployeeObject({ userType, employee: mockAppReviewEmployeeMatch, employeeGroup: 'APP_REVIEW' });
  const mockAppGroup = 'App Review';
  return {
    applicationId: `${id}`,
    applicationBusinessNumber: `${id + 1}`,
    applicationName: userType === 'employee'
      ? `Mock Application Name ${id} (${mockAppGroup} - ${riskProfile}/${processName})`
      : `Mock Application Name ${id}`,
    legalName: getMockValue({ type: 'businessName' }),
    dbaNameList: dbaNameList || Array.from({ length: getMockValue({ type: 'number', min: 1, max: riskProfile === 'preferred' ? 1 : 3 }) }).map(aDbaName => getMockValue({ type: 'businessName' })),
    relationship: {
      relationshipId: mockFormattedRelationship?.value,
      relationshipName: mockFormattedRelationship?.title,
      relationshipCode: mockFormattedRelationship?.relationshipCode,
      processName,
      bankName: mockFormattedRelationship?.bankName,
      riskProfile,
      childPartnerId: mockFormattedRelationship?.childPartnerId || ''
    },
    partner: {
      partnerId: '0d4fc94e-2874-46d3-a091-addb5fe8f2e7',
      partnerBusinessCode: '1000',
      partnerName: 'Partner with Transaction Report Data',
      ...(mockGuidToPartnerMap && mockGuidToPartnerMap[id])
    },
    applicationCreatedTimestamp: getMockValue({ type: 'isoDate' }),
    applicationSubmittedTimestamp: getMockValue({ type: 'isoDate' }),
    applicationCompletedTimestamp: isCompleted ? getMockValue({ type: 'isoDate', allowNull: true }) : null,
    applicationStatus: mockAppStatus,
    signatureType: mockSignatureType,
    requiresNewSignature: mockRequiresNewSignature,
    submittedToBank: false,
    ...(userType === 'employee' && {
      employeeOnlyFields: {
        assignedAppReviewEmployee,
        underwritingRiskLevel: underwritingRiskLevel || getMockValue({ type: 'list', list: ['level_1', 'level_2', 'level_3', 'level_4'] }),
        ...(staticApps && staticApps[id] && {
          ...(typeof staticApps[id].assignedAppReviewEmployee !== 'undefined' && { assignedAppReviewEmployee: staticApps[id].assignedAppReviewEmployee })
        }),
        gdsDecision: getMockValue({ type: 'list', list: ['declined', 'refer_recommended_declined', 'refer', 'approved'] })
      }
    }),
    ...(isAppDetailsCall && { applicationApiBoardedToProcessorTimestamp: mockAppStatus === 'api_boarded_to_processor' ? getMockValue({ type: 'isoDate' }) : null }),
    declinedReason: mockAppStatus === 'declined' ? getMockValue({ type: 'list', list: ['prohibited_vertical', 'illicit_activity', 'match', 'existing_account_with_negative_history', 'ofac', 'excessive_risk_profile', 'derogatory_credit'] }) : '',
    ...(staticApps && staticApps[id] && { ...staticApps[id] })
  };
};

export const createMockApplicationDetails = (guid, options) => {
  const {
    mockAppMatch,
    relationshipList,
    mockGuidToRelationshipMap,
    mockGuidToPartnerMap,
    staticApps,
    appsWithStaticMpas
  } = options || {};
  const mockAppMetadata = mockAppMatch || createMockApplicationRow(guid, {
    ...options,
    relationshipList,
    mockGuidToRelationshipMap,
    mockGuidToPartnerMap,
    staticApps,
    isAppDetailsCall: true
  });
  const isApplicationApiBoardedToProcessorTimestamp = mockAppMetadata?.applicationStatus === 'api_boarded_to_processor';
  const mockAppDetails = {
    applicationDetails: {
      applicationLevelFields: mockApplicationLevelFields(),
      applicationMetadata: isApplicationApiBoardedToProcessorTimestamp
        ? {
          ...mockAppMetadata,
          applicationApiBoardedToProcessorTimestamp: getMockValue({ type: 'isoDate' })
        }
        : mockAppMetadata,
      applicationMpasMetadataList: mockMpaMetadataList({
        relationship: mockAppMetadata.relationship || {},
        ...(appsWithStaticMpas && { staticMpasFromApp: appsWithStaticMpas[guid] || {} })
      }),
      applicationEventHistory: mockBoardingApplicationEventHistoryFull({ guid, length: 20 })
    }
  };
  return mockAppDetails;
};

export const mockApplicationLevelFields = options => ({
  legalBusinessName: getMockValue({ type: 'businessName' }),
  // TODO BIRB-5468 Remove this param for appType
  ...buildOwners('lowRisk')
});

export const mockCrabV1ApplicationGet = (body, params, options) => {
  const {
    appCompletionStatus = 'uncompleted_only',
    totalNumberOfRecords = mockPagingInfo.totalNumberOfRecords,
    pageIndex = mockPagingInfo.pageIndex
  } = params || {};
  const { mockAllApps } = options || {};
  const completedAppStatuses = ['approved', 'withdrawn', 'declined'];
  const apps = appCompletionStatus === 'uncompleted_only'
    ? mockAllApps
      .filter(app => !completedAppStatuses.some(s => app.applicationStatus === s))
    : mockAllApps;
  const pageData = getMockPageData({
    allData: apps,
    pageIndex,
    pageSize: 25,
    optionsCopy: { ...params, pageSize: 25 }
  });
  return {
    applications: pageData,
    pagingInfo: {
      totalNumberOfRecords,
      pageIndex,
      pageSize: 25
    }
  };
};
