import { addressAutocompleteIdMap } from './_addressAutocompleteHelpers';
import {
  countryCodes,
  dataExists,
  ignoreCase,
  isBool,
  isEmpty
} from './_helpers';
import { toBackendValue, toFrontendValue } from './_templateHelpers';
import validations from './_validations';

export const buildFormComponents = (existingData = {}, formHelperFields, options) => {
  const {
    isControlledField = false, // if the field exists under `controls`
    setInitialValue = {
      // key/value pairs of [id]: <default_value> for fields that load a dynamic initial value
    },
    customProps = {
      // if a field has any custom props to override any defaults that get set,
      // add them here. should be in key/value pairs of id/object of custom props. example:
      // [id]: { // custom props here, in an object - example:
      // list: []
      // }
    },
    disableFormFields = false
  } = options || {};
  const formFields = !isEmpty(formHelperFields)
    ? formHelperFields
    : { missingFormHelperField: { id: 'missingFormHelperField' } };
  const components = Object.entries(formFields)
    .filter(([fieldId]) => fieldId !== 'id')
    .reduce((acc, [fieldId, fieldHelperObject]) => {
      // fieldHelperObject should not contain additional nested fieldHelperObjects
      const defaultValue = setInitialValue[fieldId] || fieldHelperObject?.initialValue;

      // When loading data into a form, if `valuesForBackend` exists, use that value
      let existingValue = existingData?.valuesForBackend?.[fieldId];
      // Else use the `existingData` value which should already be the BE value on load
      if (existingValue === undefined) { existingValue = existingData?.[fieldId]; }
      // else if it's REQUIRED and empty, set it to the default, if one exists.
      const isRequired = !Object.prototype.hasOwnProperty.call(formFields[fieldId], 'required') || formFields[fieldId]?.required;

      if (isRequired && isEmpty(existingValue) && !isEmpty(defaultValue)) {
        existingValue = defaultValue;
      }

      const convertValue = (v) => {
        /**
         * For checkboxList items ON LOAD ONLY, if the value is not an array,
         * but is an object - eg, { january: true, february: false }
         * it needs to be converted to one - eg [{ january: true }, { february: false }]
         * for the CheckboxList component to handle the data properly on LOAD
         */
        const isCheckboxList = fieldHelperObject?.fieldType === 'checkboxList' && !isEmpty(v) && !Array.isArray(v);
        if (isCheckboxList) {
          const converted = Object.entries(v).map(([key, value]) => ({ [key]: value }));
          return converted;
        }
        return v;
      };

      const valueToFormat = !isEmpty(existingData) &&
      existingData?.[fieldId] !== undefined // needed for defaultValues to work
        ? convertValue(existingValue)
        : defaultValue;
      const customFieldProps = {
        ...(!isEmpty(customProps[fieldId]) && { ...customProps[fieldId] })
      };
      const fieldIsRequired = isFormFieldRequired({
        ...fieldHelperObject,
        // by default, all fields are considered required unless passed in fieldHelperObject object
        ...(fieldHelperObject?.required === undefined && { required: true })
      },
      {
        overrideProps: customProps[fieldId] || {}
      });
      const initialValueField = getInitialValue({
        customFieldProps,
        existingData,
        fieldHelperObject,
        valueToFormat
      });
      const hasInitialValue = valueExists(initialValueField?.initialValue, {
        ...fieldHelperObject,
        required: fieldIsRequired
      });
      const controlledComponents = fieldHelperObject?.controls
        ? formatControlledFields(existingData, fieldHelperObject.controls, options)
        : {};
      const defaultValueOverrides = (fieldHelperObject?.valid === true &&
        !isEmpty(fieldHelperObject?.value) && // Field has a hardcoded default value
        initialValueField?.initialValue !== fieldHelperObject?.value) || false;
      const component = {
        componentType: fieldHelperObject?.fieldType,
        ...(fieldHelperObject?.children && { children: fieldHelperObject?.children }),
        ...(hasInitialValue && initialValueField),
        props: {
          required: fieldIsRequired,
          ...fieldHelperObject,
          ...(disableFormFields && { disabled: true }),
          ...customFieldProps,
          ...(!isEmpty(controlledComponents) && { controls: controlledComponents }),
          ...(isControlledField && initialValueField?.initialValue !== undefined &&
            fieldHelperObject?.fieldType === 'input' && {
            ...(initialValueField?.initialValue === defaultValue && {
              // For `controls` input fields that have a default value, the `value` prop
              // needs to be set here so the Input component can register the default value
              value: defaultValue
            })
          }),
          ...(defaultValueOverrides && {
            ...(valueExists(initialValueField?.initialValue, fieldHelperObject)
              ? { // If value is different than hardcoded (ie, user changed it), set the BE value
                // to be the new value
                value: initialValueField?.initialValue
              }
              : {
                // If field has a default value/valid set, but was cleared & field is now
                // empty, reset the value/valid property
                value: undefined,
                valid: undefined
              })
          })
        }
      };
      return acc.concat(component);
    }, []);
  return components;
};

const getInitialValue = (options) => {
  const {
    customFieldProps,
    existingData,
    fieldHelperObject,
    valueToFormat
  } = options || {};
  const { baseAddressFields, id: compId, isAddressAutocomplete } = fieldHelperObject || {};
  let initValue;
  if (isAddressAutocomplete) {
    const existingAddressData = !isEmpty(baseAddressFields)
      ? Object.values(baseAddressFields).reduce((acc, field) => {
        const { id: fieldId } = field || {};
        const dataObj = getAddressDataObj(existingData);
        const addressDataObj = dataExists(dataObj[compId]) ? dataObj[compId] : dataObj;
        const fieldInitVal = dataExists(addressDataObj[fieldId])
          ? addressDataObj[fieldId]
          : undefined;
        const fieldCustomFieldProps = (customFieldProps && customFieldProps[fieldId]) || {};
        return {
          ...acc,
          ...(typeof fieldInitVal !== 'undefined' && {
            valuesForBackend: { ...acc.valuesForBackend, [fieldId]: fieldInitVal },
            frontendValues: {
              ...acc.frontendValues,
              [fieldId]: toFrontendValue(fieldInitVal, {
                ...field,
                ...fieldCustomFieldProps
              })
            }
          })
        };
      }, {})
      : {};
    initValue = !isEmpty(existingAddressData) ? existingAddressData : initValue;
  } else {
    initValue = valueToFormat !== undefined
      ? toFrontendValue(valueToFormat, {
        ...fieldHelperObject,
        ...customFieldProps
      })
      : initValue;
  }
  return typeof initValue !== 'undefined' ? { initialValue: initValue } : {};
};

export const getAddressDataObj = (data) => {
  // Returns the base data object for addressAutocomplete addressFields
  const { valuesForBackend, frontendValues } = data || {};
  return typeof data === 'undefined' ? {} : valuesForBackend || frontendValues || data || {};
};

export const addressAutocompleteValid = (data, props) => {
  // Checks if all address fields rendered in AddressAutocomplete are valid
  /**
   * Possible `data` formats
   * { [compId]: { addressLine1: 'val1', addressLine2: 'val2' } }
   * -OR-
   * { addressLine1: 'val1', addressLine2: 'val2' }
   */
  const { baseAddressFields } = props || {};
  const isValid = !isEmpty(baseAddressFields)
    ? Object.values(baseAddressFields).every(addressField => (
      isAddressFieldValid(data, addressField, props)
    ))
    : false;
  return isValid;
};

const isAddressFieldValid = (data, addressField, compProps, options) => {
  // Returns whether a single baseAddressField is valid when using addressAutocomplete
  const {
    id: addressFieldId,
    fieldType: addressFieldType,
    required: addressFieldRequired
  } = addressField || {};
  const { id: compId } = compProps || {};
  const { allowEmpty } = options || {};
  const addressFieldComp = { componentType: addressFieldType, props: addressField };
  const addressDataObj = (data && dataExists(data[compId]) ? data[compId] : data) || {};
  const addressFieldValue = dataExists(addressDataObj[addressFieldId])
    ? addressDataObj[addressFieldId]
    : undefined;
  if (!addressFieldRequired && !dataExists(addressDataObj[addressFieldId])) { return true; }
  return addressFieldRequired || typeof addressFieldValue !== 'undefined'
    ? isInitialValueValid(addressFieldComp, addressFieldValue)
    : allowEmpty || false;
};

export const isInitialValueValid = (component, currentValue) => {
  const { componentType, props } = component || {};
  const {
    customValidation,
    id,
    editable, // ComboBox
    isAddressAutocomplete,
    list,
    required,
    type,
    valid,
    validationType // ComboBox
  } = props || {};
  const hasValue = valueExists(currentValue, props || {});
  if (isBool(valid)) {
    return valid;
  }
  if (isAddressAutocomplete) {
    const addressData = getAddressDataObj(currentValue);
    return addressAutocompleteValid(addressData, props);
  }
  const isDropdown = ['dropdown', 'combobox'].includes(ignoreCase(componentType));
  if (isDropdown) {
    const initialArray = Array.isArray(currentValue)
      ? currentValue
      : [{ value: currentValue }];
    const hasCustomValidationType = !isEmpty(validations[validationType]);
    if (hasCustomValidationType) {
      return initialArray.every(
        initItem => validations[validationType].test(initItem.value)
      );
    }
    if (editable) { // combobox-  any new value entered is valid
      return true;
    }
    const dropdownList = isEmpty(list) && addressAutocompleteIdMap.country.includes(id)
      ? countryCodes : list;
    const allValid = !isEmpty(dropdownList) && !isEmpty(initialArray)
      ? initialArray.every(initItem => dropdownList.find(li => li.value === initItem.value))
      : false;
    return allValid;
  }
  if (!isEmpty(customValidation)) {
    // always use customValidation FIRST if it exists
    return customValidation(currentValue, props || {});
  }
  const hasValidationType = !isEmpty(type) && !isEmpty(validations[type]);
  // optional fields with no value should NOT go into the below block
  if ((hasValue || required) && hasValidationType) {
    // then use comp's standard validation
    return validations[type].test(currentValue);
  }
  return hasValue ? true : !required;
};

export const isFormFieldRequired = (formHelper, options) => {
  const { overrideProps = {} } = options || {};
  return isBool(overrideProps?.required) ? overrideProps.required : formHelper?.required;
};

export const getFieldsWithOverrides = (options) => {
  // Handles fields that are required for new apps, but may not exist for legacy apps
  const {
    allowOverrides,
    data,
    formFields,
    keys
  } = options || {};
  const fieldOverrides = allowOverrides // requirement to allow override props
    ? ((keys || []).reduce((acc, key) => (
      // Check for the fields that are currently empty in existing data,
      // and update their `required` prop to be `false`
      !isEmpty(data) && isEmpty(data[key]) && !isBool(data[key])
        ? { ...acc, [key]: { ...formFields ? formFields[key] : {}, required: false } }
        : acc
    ), {}))
    : {};
  return { ...formFields, ...fieldOverrides };
};

export const valueExists = (value, formHelper, options) => {
  const {
    baseAddressFields,
    fieldType,
    isAddressAutocomplete,
    isCheckboxList,
    required
  } = formHelper || {};
  const { allowEmpty } = options || {};
  if (isBool(value)) { return true; }
  let exists = !isEmpty(value);
  const isRequiredCheckboxList = fieldType === 'checkboxList' && isCheckboxList && required;
  if (exists && isRequiredCheckboxList) {
    // If checkboxList is required, at least one item must be checked
    // for the value to be considered valid
    const checkedValues = Array.isArray(value)
      // Eg. [{ visa: true }, { mastercard: true }]
      ? value.reduce((acc, item) => acc.concat(Object.values(item)), [])
      : Object.values(value); // Eg. { visa: true, mastercard: true }
    exists = !isEmpty(checkedValues) ? checkedValues.includes(true) : false;
  }
  if ((fieldType === 'addressAutocomplete' || isAddressAutocomplete) && typeof value !== 'undefined') {
    const addressData = getAddressDataObj(value);
    const results = Object.values(baseAddressFields).reduce((acc, addressField) => {
      const isValid = isAddressFieldValid(
        addressData,
        {
          ...addressField,
          // If empty values allowed, mark fields as optional so they will be considered
          // valid to save form (with incomplete fields)
          ...(allowEmpty && { required: false })
        },
        formHelper,
        options
      );
      return {
        ...acc,
        ...(isValid && { valid: [...acc.valid, addressField] }),
        ...(!isValid && { invalid: [...acc.invalid, addressField] })
      };
    }, { valid: [], invalid: [] });
    return allowEmpty
      // If empty is allowed, just check if invalid values exist
      ? !isEmpty(results.invalid)
      // Else just check if any data exists
      : dataExists(addressData);
  }
  return exists;
};

export const getCheckedItemKeys = (value) => {
  // For checkboxList value, this will return an array of keys of only items that ARE checked
  if (isEmpty(value)) { return []; }
  if (Array.isArray(value)) { // eg. [{ key1: true }, { key2: false }]
    return value.reduce((acc, item) => (!isEmpty(item) && Object.values(item).includes(true)
      ? acc.concat(...Object.keys(item))
      : acc), []);
  }
  // else is an object, eg. { key1: true, key2: false }
  return Object.entries(value).reduce((acc, [key, isChecked]) => (isChecked
    ? acc.concat(key)
    : acc), []);
};

const formatControlledFields = (backendData = {}, controls = {}, options = {}) => {
  const controlledFormComponents = Object.entries(controls)
    .reduce((acc, [controlKey, controlFields]) => ({
      ...acc,
      [controlKey]: buildFormComponents(backendData, controlFields, {
        ...options,
        isControlledField: true
      })
    }), {});
  return controlledFormComponents;
};

// Checks if the form `data` on load is valid for a custom list item
export const isCustomListValid = (dataArray = []) => {
  let dataValidOnLoad = false;
  if (!isEmpty(dataArray)) {
    dataValidOnLoad = dataArray.every(item => item[item.customKey] === true);
  }
  return dataValidOnLoad;
};

export const formatTabData = (fieldState, fieldsObject, KEY, options) => {
  const {
    customSectionError = '' // custom error message to display for a custom section's fields
  } = options || {};
  const fieldEntries = fieldState ? Object.entries(fieldState) : [];
  const nextFields = { ...fieldsObject } || {};
  const { formComponents } = nextFields || [];
  const hasCustomListData = !isEmpty(fieldsObject?.customListData);
  if ((!isEmpty(formComponents) || hasCustomListData) && !isEmpty(fieldEntries)) {
    fieldEntries
      .forEach(([key, value]) => {
        const isValid = /IsValid$/.test(key);
        if (key !== KEY && key !== 'formInProgress' && !isEmpty(key)) {
          const compIndex = !isEmpty(formComponents) ? formComponents
            .findIndex(comp => comp.props.id === (isValid ? key.replace('IsValid', '') : key)) : -1;
          if (compIndex !== -1) {
            const temp = {
              ...formComponents[compIndex],
              props: {
                ...formComponents[compIndex].props,
                ...(isValid ? { valid: value } : { value }),
                setError: {
                  ...(customSectionError && { message: customSectionError })
                }
              }
            };
            formComponents[compIndex] = temp;
          }
        }
      });
    return nextFields;
  }
  return {};
};

export const handleConditional = (
  // This currently is only handling when CONDITIONAL field
  // and TARGET field are in the same section...
  sectionData, // that sectionData that would be passed to a FormAssistant
  conditional, // { key: publicCompany, value: string }
  target // { key: stockSymbol, value: bool }
) => {
  if (isEmpty(sectionData) || isEmpty(conditional) || isEmpty(target)) return {};
  const targetIndex = [...sectionData.formComponents]
    .findIndex(comp => comp.props.id === target?.key);

  const conditionalIndex = [...sectionData.formComponents]
    .findIndex(comp => comp.props.id === conditional?.key);

  const newComps = [...sectionData.formComponents] || [];
  if (targetIndex !== -1 && conditionalIndex !== -1 && !isEmpty(newComps)) {
    const meetsReq = sectionData
      .formComponents[conditionalIndex].props?.value === conditional?.value;
    newComps[targetIndex] = {
      ...sectionData.formComponents[targetIndex],
      props: {
        ...sectionData.formComponents[targetIndex].props,
        required: meetsReq ? target.value : !target.value
      }
    };
  }
  return {
    ...sectionData,
    formComponents: newComps
  };
};

export const formatTabCallbackData = (tabKey, fullFormState, constructorRefs) => {
  if (!isEmpty(tabKey) && !isEmpty(fullFormState) && !isEmpty(constructorRefs)) {
    const isTabValid = Object.entries(fullFormState)
      .every(([sectionKey, sectionObject]) => !isEmpty(sectionObject) &&
      sectionObject[sectionKey]);
    const newTabData = Object.entries(fullFormState)
      .reduce((acc, [sectionKey, sectionObject]) => {
        const cRef = constructorRefs[sectionKey];
        return {
          ...acc,
          [sectionKey]: !isEmpty(sectionObject) && !isEmpty(cRef)
            ? formatTabData(sectionObject, cRef, cRef.id)
            : {}
        };
      }, {});
    const options = {
      tabKey,
      tabData: newTabData,
      tabValid: isTabValid
    };
    return options;
  }
  return {};
};

// creates options object for a field that controls the visibility of possible options
// Eg: Dropdown has different Input fields that appear based on the Dropdown selection
// this will create a map of all the possible conditional options
// Eg. Returns { option1Id: { id: 'option1IdOption' formComponents: [], ... } }
export const buildConditionalOptions = (
  backendData, // OPTIONAL, if there is any data to pre-populate with
  formHelperFields, // REQUIRED the options form helpers to populate
  defaultBuildOptions = {} // OPTIONAL, any custom disabling of fields
) => {
  if (!isEmpty(formHelperFields)) {
    const optionsArray = Object.entries(formHelperFields)
      .filter(([optionKey]) => optionKey !== 'id')
      .reduce((acc, [optionKey, optionObject]) => {
        const formComponents = buildFormComponents(
          !isEmpty(backendData) ? backendData : {},
          { [optionObject.id]: optionObject },
          defaultBuildOptions
        );
        return {
          ...acc,
          // 'Option' is appended to the id here so it doesn't conflict with the actual field id
          // when being used with FormAssistant
          [`${optionObject.id}Option`]: {
            ...defaultSectionOptions,
            id: `${optionObject.id}Option`,
            formComponents
          }
        };
      }, {});
    return optionsArray;
  }
  return {};
};

export const getRequiredWithEmptyValues = (options) => {
  // Returns form field title (Title Case key) and value (camelCase key)
  // for fields that are required and empty
  const { formFields, valuesForBackend } = options || {};
  const requiredAndEmptyFields = !isEmpty(valuesForBackend)
    ? Object.entries(valuesForBackend).reduce((acc, [key, value]) => {
      const formField = formFields[key];
      const { label, required } = formField || {};
      const hasValue = valueExists(value, formField);
      return required && !hasValue
        ? acc.concat({ title: label, value: key })
        : acc;
    }, [])
    : null;
  return requiredAndEmptyFields;
};

// Form Props

// for forms using FormAssistant where the form has labels inside the element boxes
export const defaultSectionOptions = { componentLabelInside: true };

const globalFormProps = {
  input: {
    wrapperStyle: {
      flex: '33%',
      minWidth: '150px',
      margin: 'unset',
      marginLeft: '-1px'
    },
    required: true,
    boxStyle: 'inside',
    clearAutofillOnMount: true
  },
  dropdown: {
    wrapperStyle: {
      flex: '33%',
      minWidth: '150px',
      margin: 'unset'
    },
    required: true,
    boxStyle: 'inside'
  },
  timestamp: { // for InputTimestamp fields
    isTimestamp: true,
    required: true,
    valid: false,
    value: ''
  },
  radio: {
    direction: 'horizontal',
    shape: 'square',
    size: 'sm',
    boxStyle: 'inside',
    wrapperStyle: {
      margin: 'unset',
      flex: '33%',
      minWidth: '150px'
    },
    required: true
  },
  checkbox: {
    type: 'mini',
    labelPos: 'left',
    boxStyle: 'inside',
    wrapperStyle: {
      flex: 'auto',
      maxWidth: 'fit-content',
      height: 'unset',
      marginBottom: 'unset',
      lineHeight: '1.5',
      padding: '0 5px'
    },
    required: true
  },
  checkboxList: {
    type: 'mini',
    labelPos: 'top',
    boxStyle: 'inside',
    containerStyle: {
      alignContent: 'baseline',
      flex: '33%',
      margin: '-1px'
    },
    wrapperStyle: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    required: true
  }
};

const getControlsArray = (comp, controlsEntries) => {
  const arr = !isEmpty(controlsEntries)
    ? controlsEntries.reduce((entriesAcc, [controlKey, controlsArr]) => (
      entriesAcc.concat(
        ...controlsArr.map(subControl => ({
          ...subControl,
          props: {
            ...subControl?.props,
            toggledBy: { // this field is toggled by
              toggleId: comp?.props?.id, // id of the field
              toggleValue: controlKey // value that toggles the field
            },
            controlledBy: {
              controllerId: comp?.props?.id,
              controllerValue: controlKey
            }
          }
        }))
      )
    ), [])
    : [];
  return arr;
};

export const mergeAllFormComponents = (components = [], prevComponents = []) => {
  // Returns flat list of ALL components, including deeply-nested controls fields
  // Note: this CAN include fields with the same IDs (nested under different objects)
  const mergedComponents = (components || []).reduce((acc, comp) => {
    const { controls } = (comp && comp.props) || {};
    const controlsEntries = Object.entries(controls || {});
    const controlsArray = getControlsArray(comp, controlsEntries);
    const nextComps = !isEmpty(controlsArray)
      ? mergeAllFormComponents(controlsArray, prevComponents || [])
      : prevComponents || [];
    return acc.concat(
      { ...comp },
      ...(!isEmpty(nextComps) ? nextComps : [])
    );
  }, []);
  return mergedComponents;
};

export const getValueChangedData = (frontendValue, fieldId, field) => {
  const backendValue = toBackendValue(frontendValue, field || {});
  return { id: fieldId, frontendValue, backendValue };
};

export default globalFormProps;
