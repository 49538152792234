import { isEmpty } from '../../_helpers';
import { getMockValue } from '../_mockDataHelpers';

const staticReserveDetails = {
  '0d4fc94e-2874-46d3-a091-addb5fe8f2e7': { // Partner with Transaction Report Data
    // a positive (big and small value)and small negative
    chart: [
      {
        month: 'Feb',
        reserveBalance: 10000,
        heldBalance: 10000,
        totalMerchantPosition: 400000
      },
      {
        month: 'Mar',
        reserveBalance: -190000,
        heldBalance: -190000,
        totalMerchantPosition: -90000
      },
      {
        month: 'Apr',
        reserveBalance: 500000,
        heldBalance: 500000,
        totalMerchantPosition: 400000
      }
    ],
    table: []
  },
  'c4ee47aa-a783-4198-a15e-0c6c646b53e2': { // 100
    // small chart values but large line values
    chart: [
      {
        month: 'Feb',
        reserveBalance: 1000,
        heldBalance: -1000,
        totalMerchantPosition: 400000
      },
      {
        month: 'Mar',
        reserveBalance: -1000,
        heldBalance: 1000,
        totalMerchantPosition: -400000
      },
      {
        month: 'Apr',
        reserveBalance: 1000,
        heldBalance: -1000,
        totalMerchantPosition: 400000
      }
    ],
    table: []
  },
  'mock-partner-guid-03': { // Mock Partner with Extraordinarily Long Name
    // this is how the "old" mock data looks before BE updated to use bar charts
    header: {
      biaxial: ['reserveBalance', 'heldBalance', 'totalMerchantPosition'],
      biaxialCurrency: true,
      currency: true,
      label: 'month',
      lines: ['reserveBalance', 'heldBalance', 'totalMerchantPosition']
    },
    chart: [
      {
        heldBalance: '695251.86',
        month: '2024-04',
        reserveBalance: '3788.25',
        totalMerchantPosition: '699040.11'
      },
      {
        heldBalance: '805429.87',
        month: '2024-07',
        reserveBalance: '23905.40',
        totalMerchantPosition: '829335.27'
      },
      {
        heldBalance: '588387.54',
        month: '2024-02',
        reserveBalance: '3788.25',
        totalMerchantPosition: '592175.79'
      },
      {
        heldBalance: '671229.34',
        month: '2024-03',
        reserveBalance: '3788.25',
        totalMerchantPosition: '675017.59'
      },
      {
        heldBalance: '800221.40',
        month: '2024-05',
        reserveBalance: '8853.53',
        totalMerchantPosition: '809074.93'
      },
      {
        heldBalance: '564660.76',
        month: '2024-01',
        reserveBalance: '3788.25',
        totalMerchantPosition: '568449.01'
      },
      {
        heldBalance: '817932.38',
        month: '2024-06',
        reserveBalance: '9486.32',
        totalMerchantPosition: '827418.70'
      },
      {
        heldBalance: '825436.41',
        month: '2024-09',
        reserveBalance: '23984.74',
        totalMerchantPosition: '849421.15'
      },
      {
        heldBalance: '807837.75',
        month: '2024-08',
        reserveBalance: '23905.40',
        totalMerchantPosition: '831743.15'
      }
    ]
  }
};

export const mockReportReserveDetailsGet = (body, params, options) => {
  const { guid = 'testGuid' } = options || {};
  const numberProps = { min: -800000, max: 800000 };
  return {
    [guid]: {
      chart: {
        header: isEmpty(staticReserveDetails?.[guid]?.header)
          ? {
            label: 'month',
            lines: ['totalMerchantPosition'],
            biaxial: ['totalMerchantPosition'],
            currency: true,
            biaxialCurrency: true
          }
          : staticReserveDetails?.[guid]?.header,
        data:
        isEmpty(staticReserveDetails?.[guid]?.chart) ? ([
          {
            month: 'Feb',
            reserveBalance: getMockValue(numberProps),
            heldBalance: getMockValue(numberProps),
            totalMerchantPosition: getMockValue(numberProps)
          },
          {
            month: 'Mar',
            reserveBalance: getMockValue(numberProps),
            heldBalance: getMockValue(numberProps),
            totalMerchantPosition: getMockValue(numberProps)
          },
          {
            month: 'Apr',
            reserveBalance: getMockValue(numberProps),
            heldBalance: getMockValue(numberProps),
            totalMerchantPosition: getMockValue(numberProps)
          },
          {
            month: 'May',
            reserveBalance: getMockValue(numberProps),
            heldBalance: getMockValue(numberProps),
            totalMerchantPosition: getMockValue(numberProps)
          },
          {
            month: 'Jun',
            reserveBalance: getMockValue(numberProps),
            heldBalance: getMockValue(numberProps),
            totalMerchantPosition: getMockValue(numberProps)
          },
          {
            month: 'Jul',
            reserveBalance: getMockValue(numberProps),
            heldBalance: getMockValue(numberProps),
            totalMerchantPosition: getMockValue(numberProps)
          },
          {
            month: 'Aug',
            reserveBalance: getMockValue(numberProps),
            heldBalance: getMockValue(numberProps),
            totalMerchantPosition: getMockValue(numberProps)
          }
        ])
          : staticReserveDetails[guid]?.chart
      },
      table: isEmpty(staticReserveDetails[guid]?.table)
        ? Array.from({ length: 10 }).map(row => (
          {
            date: getMockValue({ type: 'date' }),
            reserveBalance: getMockValue({ type: 'number', min: 10000, max: 9999999 }),
            heldBalance: getMockValue({ type: 'number', min: 10000, max: 9999999 }),
            totalMerchantPosition: getMockValue({ type: 'number', min: 10000, max: 9999999 })
          }
        ))
        : staticReserveDetails[guid]?.table
    }
  };
};

export const mockReportReserveCurrentGet = (body, params, options) => {
  const { guid = 'testGuid' } = options || {};
  return {
    [guid]: {
      reservesTabularData: Array.from({ length: 10 }).map(row => (
        {
          mid: getMockValue({ type: 'mid' }),
          relationshipName: getMockValue({ type: 'string' }),
          reserveBalance: getMockValue({ type: 'number', min: 10000, max: 9999999 }),
          heldBalance: getMockValue({ type: 'number', min: 10000, max: 9999999 }),
          totalMerchantPosition: getMockValue({ type: 'number', min: 10000, max: 9999999 })

        }
      ))
    }
  };
};

export default mockReportReserveDetailsGet;
