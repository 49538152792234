import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from './_helpers';
import { withRouter } from './routing/withRouter';

export class BoundScrollToTop extends React.Component {
  componentDidMount () {
    this.jumpToHash();
  }

  componentDidUpdate (prevProps) {
    const {
      location
    } = this.props;
    const hash = location.hash ? location.hash.substr(1) : null;
    if (!isEmpty(hash)) {
      this.jumpToHash();
    } else if (location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  jumpToHash = () => {
    const { location } = this.props;
    const hash = location.hash ? location.hash.substr(1) : null;
    let count = 0;
    if (!isEmpty(hash)) {
      let elem = document.getElementById(hash);
      const interval = setInterval(() => {
        count += 1;
        elem = document.getElementById(hash);
        if (elem) {
          let filterbaroffset = 0;
          const filterBar = document.querySelector('#filterBar .fixed');
          if (filterBar) {
            filterbaroffset = filterBar.getBoundingClientRect().height;
          }
          const topPos = elem.getBoundingClientRect().top + window.scrollY - 70 - filterbaroffset;
          window.scrollTo({ left: 0, top: topPos, behavior: 'smooth' });
          clearInterval(interval);
        } else if (count >= 40) {
          // try to find it for 20 seconds (for slow loading pages), then give up.
          clearInterval(interval);
        }
      }, 500);
    }
  }

  render () {
    const { children } = this.props;
    return (
      <div>{children}</div>
    );
  }
}

BoundScrollToTop.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  children: PropTypes.oneOfType([PropTypes.array])
};

BoundScrollToTop.defaultProps = {
  location: {},
  children: []
};

const ScrollToTop = connect()(BoundScrollToTop);

export default withRouter(ScrollToTop);
