import React from 'react';
import PropTypes from 'prop-types';
import { dataBox } from './_styles';
import { formStylesCSS } from './_formStyles';

export class FormContainer extends React.PureComponent {
  render () {
    const {
      children,
      className,
      id,
      label,
      wrapperStyle,
      type,
      formSectionStyle
    } = this.props;
    const wrapperClassNames = [
      ...(className ? [className] : []),
      ...(type === 'formWithSections' ? ['formWrapper'] : []),
      ...(type === 'dataBox' ? ['dataBox'] : [])
    ].join(' ');
    return (
      <div
        id={id}
        className={wrapperClassNames}
        {...type === 'formWithSections' && { role: 'article' }}
        aria-label={label || id}
        style={{
          ...(type === 'dataBox' && { ...dataBox.box }),
          ...(type === 'formWithSections' && { ...formStylesCSS.formWrapper, border: '1px solid black' }),
          ...wrapperStyle
        }}
      >
        <div
          {...type === 'formWithSections' && { className: 'formSection' }}
          style={{
            ...(type === 'dataBox' && {
              display: 'flex',
              flexDirection: 'column',
              width: '100%'
            }),
            ...(type === 'formWithSections' && {
              ...formStylesCSS.formSection,
              ...formSectionStyle
            })
          }}
        >
          { children }
        </div>
      </div>
    );
  }
}

FormContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  wrapperStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  formSectionStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

FormContainer.defaultProps = {
  children: null,
  className: null,
  wrapperStyle: {},
  label: null, // used for aria-label prop
  id: null,
  type: null,
  formSectionStyle: {}
};

export default FormContainer;
