import { transformData } from '../../_helpers';
import crabV1ApplicationSubmitValidationTemplate from '../sharedBoarding/templates/crabV1ApplicationSubmitValidationTemplate';

export const mockCrabV1SubmitValidationGet = (body, params, options) => {
  const { guid = 'emptyGuid' } = options;
  return ({
    [guid]: staticMap[guid] || {
      mpas
    }
  });
};

const staticMap = {
  1: { // App 01
    mpas: [
      {
        mpaId: '0',
        validMpaFields: false,
        allRequiredFiles: true,
        allPendsAddressed: true
      },
      {
        mpaId: '1',
        validMpaFields: true,
        allRequiredFiles: true,
        allPendsAddressed: true
      }
    ]
  },
  3: { // App 03 (FT)
    mpas: [
      {
        mpaId: '0',
        validMpaFields: true,
        allRequiredFiles: true,
        allPendsAddressed: true
      },
      {
        mpaId: '1',
        validMpaFields: true,
        allRequiredFiles: true,
        allPendsAddressed: true
      }
    ]
  },
  7: { // App 07 (Ready to submit - preferred)
    mpas: [
      {
        mpaId: '0',
        validMpaFields: true,
        allRequiredFiles: true,
        allPendsAddressed: true
      },
      {
        mpaId: '1',
        validMpaFields: true,
        allRequiredFiles: true,
        allPendsAddressed: true
      }
    ]
  },
  8: { // App 08 (Ready to submit - preferred) FT
    mpas: [
      {
        mpaId: '0',
        validMpaFields: true,
        allRequiredFiles: true,
        allPendsAddressed: true
      },
      {
        mpaId: '1',
        validMpaFields: true,
        allRequiredFiles: true,
        allPendsAddressed: true
      }
    ]
  },
  9: { // App 09 (Ready to submit - no pends - preferred) FT
    mpas: [
      {
        mpaId: '0',
        validMpaFields: true,
        allRequiredFiles: true,
        allPendsAddressed: true
      },
      {
        mpaId: '1',
        validMpaFields: true,
        allRequiredFiles: true,
        allPendsAddressed: true
      }
    ]
  },
  10: {
    mpas: [
      {
        mpaId: '0',
        validMpaFields: true,
        allRequiredFiles: true,
        allPendsAddressed: true
      },
      {
        mpaId: '1',
        validMpaFields: false,
        allRequiredFiles: true,
        allPendsAddressed: false
      }
    ]
  },
  13: {
    mpas: [
      {
        mpaId: '0',
        validMpaFields: true,
        allRequiredFiles: false,
        allPendsAddressed: true
      }
    ]
  },
  14: {
    mpas: [
      {
        mpaId: '0',
        validMpaFields: true,
        allRequiredFiles: true,
        allPendsAddressed: true
      }
    ]
  },
  17: {
    mpas: [
      {
        mpaId: '0',
        validMpaFields: false,
        allRequiredFiles: true,
        allPendsAddressed: true
      }
    ]
  },
  18: {
    mpas: [
      {
        mpaId: '0',
        validMpaFields: true,
        allRequiredFiles: true,
        allPendsAddressed: true
      },
      {
        mpaId: '1',
        validMpaFields: true,
        allRequiredFiles: true,
        allPendsAddressed: true
      }
    ]
  },
  19: {
    mpas: [
      {
        mpaId: '0',
        validMpaFields: true,
        allRequiredFiles: true,
        allPendsAddressed: true
      },
      {
        mpaId: '1',
        validMpaFields: true,
        allRequiredFiles: true,
        allPendsAddressed: true
      }
    ]
  },
  22: { // App 22 - can request signature only, has NO files
    mpas: [
      {
        mpaId: '0',
        validMpaFields: true,
        allRequiredFiles: false,
        allPendsAddressed: true
      }
    ]
  },
  23: { // App 23 - can request signature and submit, has required files
    mpas: [
      {
        mpaId: '0',
        validMpaFields: true,
        allRequiredFiles: true,
        allPendsAddressed: true
      }
    ]
  },
  24: { // App 24 - ready to go, all required files, but already has signed MPAs
    mpas: [
      {
        mpaId: '0',
        validMpaFields: true,
        allRequiredFiles: true,
        allPendsAddressed: true
      },
      {
        mpaId: '1',
        validMpaFields: true,
        allRequiredFiles: true,
        allPendsAddressed: true
      }
    ]
  }
};

const mpas = Array.from({ length: 2 }).map((value, index) => {
  //  valid MPA form only = index === 0;
  //  valid MPA & pends complete = index === 1;
  //  pends NOT complete only = index === 0;
  const mpa = {
    mpaId: index.toString(),
    validMpaFields: index === 0 || index === 1,
    allRequiredFiles: index === 0 || index === 1,
    allPendsAddressed: index === 0
  };
  return mpa;
});

export const mockCrabV1SubmitValidationFormatted = transformData({
  data: mockCrabV1SubmitValidationGet({}, {}, { guid: 'testGuid' }).testGuid,
  toSchema: 'frontend',
  template: crabV1ApplicationSubmitValidationTemplate,
  version: '1.0'
});

export default mockCrabV1SubmitValidationGet;
