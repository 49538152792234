import {
  getNow,
  isEmpty,
  isPastTimestamp,
  pad,
  stateCodes,
  isValidUrl
} from './_helpers';
import { passwordErrorMessages } from './_globals';

export const allowedDates = (options) => {
  const { futureDatesOnly = false, allowToday = false } = options || {};
  const dtToday = getNow();
  let month = dtToday.getMonth() + 1;
  let day = dtToday.getDate();
  const year = dtToday.getFullYear();
  if (month < 10) { month = `0${month.toString()}`; }
  if (day < 10) { day = `0${day.toString()}`; }
  if (futureDatesOnly) {
    !allowToday && dtToday.setDate(dtToday.getDate() + 1);
    day = dtToday.getDate();
    return {
      min: `${year}-${pad(month)}-${pad(day)}`, // tomorrow, no past dates
      max: `${year + 100}-${pad(month)}-${pad(day)}` // 100 years max
    };
  }
  return {
    min: `${year - 100}-${pad(month)}-${pad(day)}`, // 100 years max
    max: `${year}-${pad(month)}-${pad(day)}` // today, no future dates
  };
};

const isDuringBusinessHours = (time) => { // 9 AM - 5 PM
  const [h, m] = (time || '').split(':').map(v => parseInt(v, 10));
  return h >= 9 && (h < 17 || (h === 17 && m === 0));
};

const isTimeRounded = (time, options) => {
  const { allowedOptions = [0, 30] } = options || {}; // default rounds 30-min increment
  const [h, m] = (time || '').split(':').map(v => parseInt(v, 10));
  return !isEmpty(h) && !isEmpty(m) ? (allowedOptions || []).includes(m) : false;
};

const invalidSsnList = [
  // any sequence of the same digit
  '000000000',
  '111111111',
  '222222222',
  '333333333',
  '444444444',
  '555555555',
  '666666666',
  '777777777',
  '888888888',
  '999999999',
  // completely sequential
  '123456789'
];

// when adding a new validation type, things to take note of:
// likely need a new entry in the toBackendValue transformation method in _templateHelpers.js
// if this is going to modify the html type of an input box
// please add the return type in setType in Input.js.js
const standardValidations = {
  textarea: {
    test: v => !isEmpty(v) && v.trim().length > 9,
    minlength: 10,
    message: 'Must be at least 10 characters'
  },
  email: {
    // html5 email input, has built in validation, so we dont NEED to supply a pattern
    test: v => RegExp(/^\S+@\S+[.][0-9a-zA-Z]+$/).test(v),
    pattern: '^\\S+@\\S+[.][0-9a-zA-Z]+$',
    message: 'Must be a valid email address'
  },
  password: {
    test: v => RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*])[A-Za-z\d~!@#$%^&*?&#]{10,}$/).test(v),
    pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[~!@#$%^&*])[A-Za-z\\d~!@#$%^&*?&#]{10,}$',
    message: passwordErrorMessages
  },
  tel: {
    test: v => RegExp(/^[+]?1?[-. ]?\(?([2-9][0-9][0-9])\)?[-. ]?(\d{3})[-. ]?(\d{4})$/).test(v),
    pattern: '^[+]?1?[\\-. ]?\\(?([2-9][0-9][0-9])\\)?[\\-. ]?(\\d{3})[\\-. ]?(\\d{4})$',
    message: 'Must be 10 digits with valid area code. Example: 555-555-5555'
  },
  price: {
    test: v => RegExp(/^(\$\d+|\d+)(,\d{3})*(\.\d{1,2})?$/g).test(parseFloat(v)),
    pattern: '^(\\$\\d+|\\d+)(,\\d{3})*(\\.\\d{1,2})?$',
    step: 0.01,
    message: 'Must be a price'
  },
  text: {
    test: v => !isEmpty(v) && typeof v === 'string' && v.trim().length > 0,
    minlength: 1,
    pattern: '.*\\w.*', // must have at least 1 non space character
    message: 'Must be at least 1 non-space character'
  },
  url: {
    test: v => isValidUrl(v),
    pattern: '^(?:(http|https)?:\\/\\/)?(?:[\\w-]+\\.)+([a-z]|[A-Z]|[0-9]){2,6}?(\\/.*)?$',
    message: 'Must be a valid URL'
  },
  urlRequiredProtocol: {
    test: v => isValidUrl(v, { requireProtocol: true }),
    pattern: 'https?:\\/\\/(www\\.)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\\.[a-z]{2,24}(:[0-9]{1,5})?(\\/.*)?$',
    message: 'Must be a valid URL with Protocol (http(s)://)'
  },
  bankaccount: {
    test: v => RegExp(/^[0-9]{5,17}$/g).test(v),
    pattern: '^[0-9]{5,17}$',
    message: 'Must be a valid account number (up to 17 digits only)'
  },
  bankrouting: {
    test: v => RegExp(/^\d{9}$/g).test(v),
    pattern: '^\\d{9}$',
    message: 'Must be a valid routing number (9 digits only)'
  },
  mid: {
    test: v => !isEmpty(v) && v.trim().length > 11 && RegExp(/^[0-9]*$/g).test(v),
    minlength: 12,
    pattern: '^(\\d{12,})?$',
    message: 'Must be at least a 12-digit number'
  },
  chaincode: {
    test: v => !isEmpty(v) && v.trim().length > 5,
    minlength: 6,
    pattern: '.*\\w.*\\w.*\\w.*\\w.*\\w.*\\w.*', // must also have at least 6 non space characters
    message: 'Must be at least 6 characters'
  },
  stateCode: {
    test: v => !isEmpty(v) && stateCodes.indexOf(v.toUpperCase()) > -1,
    minlength: 2,
    pattern: '^(([Aa][EeLlKkSsZzRr])|([Cc][AaOoTt])|([Dd][EeCc])|([Ff][MmLl])|([Gg][AaUu])|([Hh][Ii])|([Ii][DdLlNnAa])|([Kk][SsYy])|([Ll][Aa])|([Mm][EeHhDdAaIiNnSsOoTt])|([Nn][EeVvHhJjMmYyCcDd])|([Mm][Pp])|([Oo][HhKkRr])|([Pp][WwAaRr])|([Rr][Ii])|([Ss][CcDd])|([Tt][NnXx])|([Uu][Tt])|([Vv][TtIiAa])|([Ww][AaVvIiYy]))$',
    message: 'Must be a valid 2 character state code'
  },
  date: {
    test: (v) => {
      if (!isEmpty(v) && RegExp(/^\d{4}-?\d{2}-?\d{2}$/).test(v)) {
        const currentDate = getNow();
        const inputDate = getNow(v.concat('T00:00:00.00'));
        const notFutureDate = currentDate >= inputDate;
        const isWithin100Years = (currentDate.getFullYear() - 100) <= inputDate.getFullYear();
        if (notFutureDate && isWithin100Years) {
          return true;
        }
        return false;
      }
      return false;
    },
    min: allowedDates().min,
    max: allowedDates().max,
    message: 'Must be a valid date (no future dates)'
  },
  futureDate: {
    test: (v, options) => {
      const { allowToday } = options || {};
      if (!isEmpty(v) && RegExp(/^\d{4}-?\d{2}-?\d{2}$/).test(v)) {
        const currentDate = allowToday
          ? getNow(
            (getNow()).setHours(0, 0, 0, 0)
          )
          : getNow();
        const inputDate = getNow(v.concat('T00:00:00.00'));
        const notPastDate = currentDate <= inputDate;
        const isWithin100Years = (currentDate.getFullYear() + 100) >= inputDate.getFullYear();
        if (notPastDate && isWithin100Years) {
          return true;
        }
        return false;
      }
      return false;
    },
    min: allowedDates({ futureDatesOnly: true }).min,
    max: allowedDates({ futureDatesOnly: true }).max,
    message: 'Must be a valid date (no past dates)'
  },
  ssn: {
    test: (v) => {
      const isValidFormat = RegExp(/^\d{3}-?\d{2}-?\d{4}$/).test(v);
      const val = (`${v}`).replace(/-/g, '');
      return isValidFormat && !invalidSsnList.includes(val);
    },
    pattern: '^\\d{3}-?\\d{2}-?\\d{4}$',
    message: 'Must be a valid SSN'
  },
  ein: {
    test: (v) => {
      const isValidFormat = RegExp(/^[0-9]{9}$/).test(v);
      const val = (`${v}`).replace(/-/g, '');
      return isValidFormat && !invalidSsnList.includes(val);
    },
    minlength: 9,
    pattern: '^(\\d{9})?$',
    message: 'Must be a valid EIN'
  },
  tin: {
    test: v => RegExp(/^\d{2}-?\d{7}$/).test(v),
    pattern: '^\\d{2}-?\\d{7}$',
    message: 'Must be a valid TIN'
  },
  time: {
    test: (time, options) => {
      const { date } = options || {};
      if (!isEmpty(time) && RegExp(/^\d{2}:?\d{2}$/).test(time)) {
        const now = Date.now();
        const dateToTest = validations.date.test(date) ? date : allowedDates().max;
        const inputDate = getNow(`${dateToTest} ${time}`);
        const isValid = inputDate.getTime() <= now;
        return isValid;
      }
      return false;
    },
    pattern: '^\\d{2}:?\\d{2}$',
    message: 'Must be a valid time (not in future)'
  },
  futureTime: {
    test: (time, options) => {
      const { businessHoursOnly, date, roundMinutes } = options || {};
      if (!isEmpty(time) && RegExp(/^\d{2}:?\d{2}$/).test(time)) {
        const dateToTest = RegExp(/^\d{4}-?\d{2}-?\d{2}$/).test(date) ? date : allowedDates().max;
        const isPast = isPastTimestamp(dateToTest, time);
        const isValid = !isPast &&
          (businessHoursOnly ? isDuringBusinessHours(time) : true) &&
          (roundMinutes ? isTimeRounded(time) : true);
        return isValid;
      }
      return false;
    },
    pattern: '^\\d{2}:?\\d{2}$',
    message: 'Must be a valid time (not in past)'
  },
  anyTime: {
    test: (time, options) => !isEmpty(time) && RegExp(/(1[0-2]|0?[1-9]):([0-5][0-9])/).test(time),
    pattern: '^\\(1[0-2]|0?[1-9]):([0-5][0-9])',
    message: 'Must be a valid time'
  },
  percent: {
    test: v => RegExp(/^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/).test(parseFloat(v)),
    pattern: '^(100(\\.0{1,2})?|[1-9]?\\d(\\.\\d{1,2})?)$',
    min: 0,
    max: 100,
    step: 0.01,
    message: 'Must be a valid percentage (up to 2 decimal places)'
  },
  percentFivePrecision: {
    test: v => RegExp(/^(100(\.0{1,5})?|[1-9]?\d(\.\d{1,5})?)$/).test(parseFloat(v)),
    pattern: '^(100(\\.0{1,5})?|[1-9]?\\d(\\.\\d{1,5})?)$',
    min: 0,
    max: 100,
    step: 0.00001,
    message: 'Must be a valid percentage (up to 5 decimal places)'
  },
  ratioSevenPrecision: {
    test: v => RegExp(/^(0(\.\d{1,7})?|1(\.0{1,7})?)$$/).test(parseFloat(v)),
    pattern: '^(0(\\.\\d{1,7})?|1(\\.0{1,7})?)$',
    min: 0,
    max: 1,
    step: 0.0000001,
    message: 'Must be a valid ratio (up to 7 decimal places)'
  },
  ratioTwoPrecision: {
    test: v => RegExp(/^(0(\.\d{1,2})?|1(\.0{1,2})?)$$/).test(parseFloat(v)),
    pattern: '^(0(\\.\\d{1,2})?|1(\\.0{1,2})?)$',
    min: 0,
    max: 1,
    step: 0.01,
    message: 'Must be a valid ratio (up to 2 decimal places)'
  },
  percentOwnership: {
    test: v => v >= 25 && v <= 100,
    min: 25,
    max: 100,
    message: 'Must be a valid percentage from 25 to 100'
  },
  number: {
    test: v => RegExp(/^\d+$/).test(v),
    pattern: '^\\d+$',
    message: 'Must be a whole number'
  },
  rationalNumber: {
    test: v => RegExp(/^\d+(?:\.[0-9]{1,2})?$/).test(v),
    step: 0.01,
    pattern: '^\\d+(?:\\.[0-9]{1,2})?$',
    message: 'Must be a rational number (up to 2 decimal places)'
  },
  zipcode: {
    test: v => RegExp(/^\d{5}$|^\d{5}-\d{4}$/).test(v),
    pattern: '^\\d{5}$|^\\d{5}-\\d{4}$',
    message: 'Must be a valid ZIP Code'
  },
  f1Email: {
    test: v => RegExp(/^.+?@f1payments.com$/).test(v) || RegExp(/^.+?@corviapay.com$/).test(v) || RegExp(/^.+?@mvbbanking.com$/).test(v),
    pattern: '^.+?@f1payments.com$|^.+?@corviapay.com$|^.+?@mvbbanking.com$',
    message: 'Must be a Corvia email'
  },
  corviaEmail: {
    test: v => RegExp(/^.+?@corviapay.com$/i).test(v),
    pattern: '^.+?@corviapay.com$',
    message: 'Must be a Corvia email'
  },
  mcc: {
    test: v => RegExp(/^[0-9]{4}$/).test(v),
    pattern: '^[0-9]{4}$',
    message: 'Must be 4 digits only'
  },
  onlyAlpha: {
    test: v => RegExp(/^[A-Za-z]+$/).test(v),
    pattern: '^[A-Za-z]+$',
    message: 'Only letters allowed'
  },
  noValidate: {
    pattern: '.+$|^$'
  }
};

const customValidations = {
  dollarAndSevenPrecision: {
    test: v => standardValidations.price.test(v) || standardValidations.ratioSevenPrecision.test(v),
    step: 0.0000001,
    pattern: '^(\\$\\d+|\\d+)(,\\d{3})*(\\.\\d{1,2})?$|^(0(\\.\\d{1,7})?|1(\\.0{1,7})?)$',
    message: 'Dollar amounts must be a valid price. Ratios can have a max of 7 decimal places'
  }
};

const validations = {
  ...standardValidations,
  ...customValidations
};

export default validations;
