import { isEmpty } from '@f1/shared/src/_helpers';

export const initialState = {
  user: {
    accessToken: {
      csrfToken: '',
      lastClickTimestamp: 0,
      exp: 0
    },
    employeeGroupList: [],
    identityToken: {
      email: '',
      emailVerified: false,
      phoneNumber: '',
      firstName: '',
      lastName: ''
    },
    emailVerificationRequired: false,
    resetPassword: false,
    isInternal: false
  },
  isAuthenticated: false
};

function authenticate (state = initialState, action) {
  switch (action.type) {
    case 'AUTHENTICATE':
      return {
        ...state,
        ...(action.payload === 'reset'
          ? { ...initialState }
          : {
            isAuthenticated: typeof action.payload === 'object' && Object.keys(action.payload).length !== 0,
            user: {
              ...state.user,
              ...!isEmpty(action.payload) && { ...action.payload }
            }
          })
      };
    case 'RESET_PASSWORD':
      return {
        ...state,
        user: {
          ...state.user,
          resetPassword: typeof action.payload === 'boolean'
            ? action.payload
            : state.user.resetPassword
        }
      };
    default:
      return state;
  }
}
export default authenticate;
