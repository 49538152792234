import { isEmpty, domainName } from '@f1/shared/src/_helpers';
import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { crabAppStatusList, crabMasterTaskList } from '@f1/shared/src/_crabFields';
import { getTaskItemMatch } from '@f1/shared/src/data/sharedBoarding/templates/crabV1ApplicationTemplate';
import { createMockFileList } from '@f1/shared/src/data/sharedBoarding/mockBoardingApplicationFiles';
import { mockRiskExposureInputs } from '@f1/shared/src/data/crab/mockCrabV1ApplicationMpaDetail';
import { createMockProhibitedEntities } from '../risk/prohibitedEntities';
import { merchantGuids } from '../guids';
// import mockPDFWetSigned from './mockPDFSigned.pdf';

const appReviewTasks = crabMasterTaskList
  .filter(item => item.isAppReviewTask).map(item => item.value);

const getMockTaskItemAndIndex = (taskEnum) => {
  const taskItem = getTaskItemMatch(taskEnum);
  const taskIndex = crabMasterTaskList.findIndex(
    item => item.value === taskEnum
  );
  return { taskItem, taskIndex };
};

const mockFilesWithTagsMap = {
  gds: [{
    fileName: 'experianResults.pdf',
    tags: [{ tagObject: 'experian_report_individual' }, { tagObject: 'uploaded_by_system' }],
    url: `https://www.dev.${domainName}.com/experianResults.pdf`,
    guidType: 'applicationMpaId',
    guidValue: '0'
  }, {
    fileName: 'giactReport.pdf',
    tags: [{ tagObject: 'giact_report' }, { tagObject: 'uploaded_by_system' }],
    url: `https://www.dev.${domainName}.com/giactReport.pdf`,
    guidType: 'applicationMpaId',
    guidValue: '0'
  }, {
    fileName: 'matchReport.pdf',
    tags: [{ tagObject: 'match_report' }, { tagObject: 'uploaded_by_system' }],
    url: `https://www.dev.${domainName}.com/matchReport.pdf`,
    guidType: 'applicationMpaId',
    guidValue: '0'
  }, {
    fileName: 'riskExposureFile.pdf',
    tags: [{ tagObject: 'risk_exposure' }],
    url: `https://www.dev.${domainName}.com/riskExposureFile.pdf`,
    guidType: 'applicationMpaId',
    guidValue: '0'
  }],
  kyb_know_your_business: [
    {
      fileName: 'processingStatements_1.pdf',
      tags: [{ tagObject: 'processing_statements' }],
      url: `https://www.dev.${domainName}.com/processingStatements_1.pdf`,
      guidType: 'applicationMpaId',
      guidValue: 'mock-mpa-id-1'
    }
  ],
  kyc_know_your_customer: [
    {
      fileName: 'bankStatements_1.pdf',
      tags: [{ tagObject: 'bank_statements' }],
      url: `https://www.dev.${domainName}.com/bankStatements_1.pdf`,
      guidType: 'applicationMpaId',
      guidValue: 'mock-mpa-id-2'
    }
  ],
  banking_validation: [
    {
      fileName: 'bankStatements_1.pdf',
      tags: [{ tagObject: 'bank_statements' }, { tagObject: 'uploaded_by_partner' }],
      url: `https://www.dev.${domainName}.com/bankStatements_1.pdf`,
      guidType: 'applicationMpaId',
      guidValue: 'mock-mpa-id-3'
    },
    {
      fileName: 'bankStatements_2.pdf',
      tags: [{ tagObject: 'bank_statements' }, { tagObject: 'uploaded_by_employee' }],
      url: `https://www.dev.${domainName}.com/bankStatements_2.pdf`,
      guidType: 'applicationTaskId',
      guidValue: 'mock-task-id-1'
    },
    {
      fileName: 'bankStatements_3.pdf',
      tags: [{ tagObject: 'bank_statements' }],
      url: `https://www.dev.${domainName}.com/bankStatements_3.pdf`,
      guidType: 'applicationTaskId',
      guidValue: 'mock-task-id-2'
    }
  ],
  match: [
    {
      fileName: 'matchReport_1.pdf',
      tags: [{ tagObject: 'match_report' }],
      url: `https://www.dev.${domainName}.com/matchReport_1.pdf`,
      guidType: 'applicationTaskId',
      guidValue: 'mock-task-id-3'
    }
  ],
  owner_credit_bureau_data: [
    {
      fileName: 'experianReportIndividual_1.pdf',
      tags: [{ tagObject: 'experian_report_individual' }],
      url: `https://www.dev.${domainName}.com/experianReportIndividual_1.pdf`,
      guidType: 'applicationTaskId',
      guidValue: 'mock-task-id-4'
    }
  ],
  dba_credit_bureau_data: [
    {
      fileName: 'experianReportBusiness_1.pdf',
      tags: [{ tagObject: 'experian_report_business' }],
      url: `https://www.dev.${domainName}.com/experianReportBusiness_1.pdf`,
      guidType: 'applicationTaskId',
      guidValue: 'mock-task-id-5'
    }
  ],
  illicit_activity_review: [{
    fileName: 'illicitActivityReport.pdf',
    tags: [{ tagObject: 'illicit_activity_review' }],
    url: `https://www.dev.${domainName}.com/illicitActivityReport.pdf`,
    guidType: 'applicationMpaId',
    guidValue: '1'
  }],
  ofac: [
    {
      fileName: 'giactReport_1.pdf',
      tags: [{ tagObject: 'giact_report' }],
      url: `https://www.dev.${domainName}.com/giactReport_1.pdf`,
      guidType: 'applicationTaskId',
      guidValue: 'mock-task-id-6'
    }
  ],
  mpa_validation: [
    {
      fileName: 'electronicMpa_1.pdf',
      tags: [{ tagObject: 'electronic_mpa' }],
      url: `https://www.dev.${domainName}.com/electronicMpa_1.pdf`,
      guidType: 'applicationMpaId',
      guidValue: '0' // required value for files to match up.
    },
    {
      fileName: 'signedMpa_1.pdf',
      tags: [{ tagObject: 'signed_mpa' }],
      // url: mockPDFWetSigned,
      url: `https://www.dev.${domainName}.com/signedMpa_1.pdf`,
      guidType: 'applicationMpaId',
      guidValue: '1' // required value for files to match up.
    }
  ],
  mpa_post_new_signature_verification: [
    {
      fileName: 'electronicMpa_1.pdf',
      tags: [{ tagObject: 'electronic_mpa' }],
      url: `https://www.dev.${domainName}.com/electronicMpa_1.pdf`,
      guidType: 'applicationMpaId',
      guidValue: 'mock-mpa-id-6'
    },
    {
      fileName: 'signedMpa_1.pdf',
      tags: [{ tagObject: 'signed_mpa' }],
      // url: mockPDFWetSigned,
      url: `https://www.dev.${domainName}.com/signedMpa_1.pdf`,
      guidType: 'applicationMpaId',
      guidValue: 'mock-mpa-id-7'
    }
  ],
  additional_document_review: [
    {
      fileName: 'other_1.pdf',
      tags: [{ tagObject: 'other' }, { tagObject: 'uploaded_by_partner' }],
      url: `https://www.dev.${domainName}.com/other_1.pdf`,
      guidType: 'applicationTaskId',
      guidValue: 'mock-task-id-11'
    },
    {
      fileName: 'businessDocument_1.pdf',
      tags: [{ tagObject: 'other' }, { tagObject: 'uploaded_by_employee' }],
      url: `https://www.dev.${domainName}.com/businessDocument_1.pdf`,
      guidType: 'applicationMpaId',
      guidValue: 'mock-mpa-id-10'
    },
    {
      fileName: 'merchantDocument_1.pdf',
      tags: [{ tagObject: 'other' }],
      url: `https://www.dev.${domainName}.com/merchantDocument_1.pdf`,
      guidType: 'applicationMpaId',
      guidValue: 'mock-mpa-id-11'
    },
    {
      fileName: 'photos_1.png',
      tags: [{ tagObject: 'other' }, { tagObject: 'uploaded_by_employee' }],
      url: `https://www.dev.${domainName}.com/photos_1.png`
    }
  ],
  due_diligence_review: [
    {
      fileName: 'processingStatements_1.pdf',
      tags: [{ tagObject: 'processing_statements' }],
      url: `https://www.dev.${domainName}.com/processingStatements_1.pdf`,
      guidType: 'applicationMpaId',
      guidValue: 'mock-mpa-id-1'
    }
  ],
  corvia_documents_review: [
    {
      fileName: 'reserve_form_1.pdf',
      tags: [{ tagObject: 'reserve_form' }],
      url: `https://www.dev.${domainName}.com/creserve_form_1.pdf`,
      guidType: 'applicationMpaId',
      guidValue: 'mock-mpa-id-1'
    },
    {
      fileName: 'attestation_1.pdf',
      tags: [{ tagObject: 'attestation' }],
      url: `https://www.dev.${domainName}.com/attestation_1.pdf`,
      guidType: 'applicationMpaId',
      guidValue: 'mock-mpa-id-1'
    }
  ],
  business_financial_review: [
    {
      fileName: 'business_financial_review.pdf',
      tags: [{ tagObject: 'business_financial_review' }],
      url: `https://www.dev.${domainName}.com/business_financial_review.pdf`,
      guidType: 'applicationMpaId',
      guidValue: 'mock-mpa-id-1'
    },
    {
      fileName: 'bank_statements.pdf',
      tags: [{ tagObject: 'bank_statements' }],
      url: `https://www.dev.${domainName}.com/bank_statements.pdf`,
      guidType: 'applicationMpaId',
      guidValue: 'mock-mpa-id-1'
    },
    {
      fileName: 'processing_statements.pdf',
      tags: [{ tagObject: 'processing_statements' }],
      url: `https://www.dev.${domainName}.com/processing_statements.pdf`,
      guidType: 'applicationMpaId',
      guidValue: 'mock-mpa-id-1'
    },
    {
      fileName: 'business_financials.pdf',
      tags: [{ tagObject: 'business_financials' }],
      url: `https://www.dev.${domainName}.com/business_financials.pdf`,
      guidType: 'applicationMpaId',
      guidValue: 'mock-mpa-id-1'
    }
  ],
  negative_option_billing_requirements: [
    {
      fileName: 'customer_receipt_email_template_1.pdf',
      tags: [{ tagObject: 'customer_receipt_email_template' }],
      url: `https://www.dev.${domainName}.com/customer_receipt_email_template_1.pdf`,
      guidType: 'applicationMpaId',
      guidValue: 'mock-mpa-id-1'
    },
    {
      fileName: 'cancellation_confirmation_email_template_1.pdf',
      tags: [{ tagObject: 'cancellation_confirmation_email_template' }],
      url: `https://www.dev.${domainName}.com/cancellation_confirmation_email_template_1.pdf`,
      guidType: 'applicationMpaId',
      guidValue: 'mock-mpa-id-1'
    },
    {
      fileName: 'unsuccessful_auth_receipt_form_template_1.pdf',
      tags: [{ tagObject: 'unsuccessful_auth_receipt_form_template' }],
      url: `https://www.dev.${domainName}.com/unsuccessful_auth_receipt_form_template_1.pdf`,
      guidType: 'applicationMpaId',
      guidValue: 'mock-mpa-id-1'
    },
    {
      fileName: 'explicit_consent_form_template_1.pdf',
      tags: [{ tagObject: 'explicit_consent_form_template' }],
      url: `https://www.dev.${domainName}.com/explicit_consent_form_template_1.pdf`,
      guidType: 'applicationMpaId',
      guidValue: 'mock-mpa-id-1'
    },
    {
      fileName: 'maintenance_page_screenshot_1.pdf',
      tags: [{ tagObject: 'maintenance_page_screenshot' }],
      url: `https://www.dev.${domainName}.com/maintenance_page_screenshot_1.pdf`,
      guidType: 'applicationMpaId',
      guidValue: 'mock-mpa-id-1'
    },
    {
      fileName: 'nob_addendum_1.pdf',
      tags: [{ tagObject: 'nob_addendum' }],
      url: `https://www.dev.${domainName}.com/nob_addendum_1.pdf`,
      guidType: 'applicationMpaId',
      guidValue: 'mock-mpa-id-1'
    },
    {
      fileName: 'nutra_questionnaire_1.pdf',
      tags: [{ tagObject: 'nutra_questionnaire' }],
      url: `https://www.dev.${domainName}.com/nutra_questionnaire_1.pdf`,
      guidType: 'applicationMpaId',
      guidValue: 'mock-mpa-id-1'
    },
    {
      fileName: 'product_country_compliance_1.pdf',
      tags: [{ tagObject: 'product_country_compliance' }],
      url: `https://www.dev.${domainName}.com/product_country_compliance_1.pdf`,
      guidType: 'applicationMpaId',
      guidValue: 'mock-mpa-id-1'
    }
  ]
};

const staticTaskApps = {
  /**
   * Specific mock apps that we want specific task data for.
   */
  6: { // App 06 - Repay/Preferred
    taskData: {
      kyb_know_your_business: {
        applicationTaskStatus: 'pended',
        pendIdList: ['mock-application-pend-id-1']
      },
      kyc_know_your_customer: {
        applicationTaskCompletedTimestamp: getMockValue({ type: 'isoDate' })
      },
      banking_validation: {
        noteIdList: []
      }
    }
  },
  13: { // App 13 - All tasks approved but missing signature
    taskData: appReviewTasks.reduce((acc, taskValue) => ({ ...acc, [taskValue]: { applicationTaskStatus: taskValue === 'mpa_post_new_signature_verification' ? 'initial' : 'approved' } }), {})
  },
  21: { // App 21 Withdrawn App
    taskData: {
      kyb_know_your_business: {
        applicationTaskStatus: 'rejected',
        negativeActionList: ['A reason to decline']
      },
      additional_document_review: {
        applicationTaskStatus: 'pended'
      },
      banking_validation: {
        applicationTaskStatus: 'approved'
      }
    }
  },
  34: { // App 34 - App for testing Application Files Only (for FT)
    taskData: {} // should have no tasks
  },
  38: { // App 21 Withdrawn App
    taskData: {
      additional_document_review: {
        applicationTaskStatus: 'will_not_complete'
      },
      banking_validation: {
        applicationTaskStatus: 'initial'
      }
    }
  }
};

export const createMockMatchDataList = (guid, options) => {
  const { length } = options || {};
  const mockMatchDataList = Array.from({ length: length || 3 }).map((item, index) => ({
    mpa: {
      applicationMpaId: `${index + 1}`,
      dbaName: `Mock DBA ${index + 1}: ${getMockValue({ type: 'description' })}`
    },
    numberOfPotentialMatches: index === 0
      ? 0 // to test 0 result view
      : getMockValue({ min: 1 }), // else at least 1 for FT
    ...(index === 0 && { numberOfPotentialMatches: 0 }),
    matchResultsId: `mock-match-results-id-${index + 1}`
  }));
  return mockMatchDataList;
};

export const createRelatedApplications = (guid, options) => {
  const { length } = options || {};
  const mockRelatedApplicationsList = Array.from({ length: length || 3 }).map((item, index) => ({
    applicationId: `${index + 1}`,
    applicationBusinessId: getMockValue({ type: 'number', stringify: true }),
    applicationStatus: getMockValue({ type: 'list', list: crabAppStatusList }),
    applicationSubmittedTimestamp: getMockValue({ type: 'isoDate' })
  }));
  return mockRelatedApplicationsList;
};

export const createRelatedMerchants = (guid, options) => {
  const { length } = options || {};
  const mockRelatedMerchantsList = Array.from({ length: length || 3 }).map((item, index) => ({
    merchantGuid: merchantGuids.merchant01,
    dbaName: `Mock DBA ${index + 1}: ${getMockValue({ type: 'description' })}`,
    status: getMockValue({ type: 'list', list: ['Open', 'Closed', 'Soft Close'] }),
    approvedDate: getMockValue({ type: 'date' }),
    closedDate: getMockValue({ type: 'date' }),
    closureReason: getMockValue({
      type: 'list',
      list: [
        'Merchant Request',
        'Brand Request',
        'MATCH',
        'Bank Request',
        'Excessive Chargebacks',
        'Non-Compliance',
        'ACH Rejects',
        'Collections'
      ]
    })
  }));
  return mockRelatedMerchantsList;
};

const createMockProhibitedEntitiesRequest = (index) => {
  const mockPerson = getMockValue({ type: 'user' });
  const mockSignerAddress = getMockValue({ type: 'address' });
  const mockBusinessAddress = getMockValue({ type: 'address' });
  const requestObject = {
    ssn: getMockValue({ type: 'ssn' }),
    ein: getMockValue({ type: 'ssn' }),
    personName: mockPerson.fullName,
    legalName: `Mock Legal Name ${index + 1}`,
    fundingAccount: getMockValue({ type: 'string' }),
    signerAddressObject: {
      addressLine1: mockSignerAddress.fullAddress,
      addressLine2: null,
      city: mockSignerAddress.cityName,
      state: mockSignerAddress.stateCode,
      postalCode: mockSignerAddress.zipCode,
      country: 'US'
    },
    businessAddressObject: {
      addressLine1: mockBusinessAddress.fullAddress,
      addressLine2: null,
      city: mockBusinessAddress.cityName,
      state: mockBusinessAddress.stateCode,
      postalCode: mockBusinessAddress.zipCode,
      country: 'US'
    }
  };
  return requestObject;
};

export const createMockProhibitedEntitiesList = (options) => {
  const { length } = options || {};
  return Array.from({ length: length || 3 }).map((item, index) => ({
    request: createMockProhibitedEntitiesRequest(index),
    responseList: createMockProhibitedEntities({ length: 3 }),
    dbaName: `Mock DBA Name ${index + 1}`,
    applicationMpaId: '1'
  }));
};

export const createMockTask = (guid, options) => {
  const {
    pendIdList, // overrides (optional) - static data for tests
    applicationTaskStatus, // overrides (optional) - static data for tests
    negativeActionList, // overrides (optional) - static data for tests
    mockEmployeeGroup,
    taskItem,
    taskIndex
  } = options || {};
  const taskNameValue = taskItem.value;
  const { [guid]: guidData } = staticTaskApps || {};
  const { taskData = {} } = guidData || {};
  const mockTask = {
    applicationTaskId: `mock-application-task-id-${taskIndex + 1}`,
    applicationTaskName: taskNameValue,
    applicationId: guid,
    applicationTaskStatus: applicationTaskStatus || 'approved',
    applicationTaskCreatedTimestamp: getMockValue({ type: 'isoDate' }),
    applicationTaskCompletedTimestamp: null,
    employeeGroup: mockEmployeeGroup,
    negativeActionList: negativeActionList || [],
    pendIdList: pendIdList || [],
    noteIdList: ['mock-note-id-application-id-1'],
    filesList: createMockFileList({ staticFiles: mockFilesWithTagsMap[taskNameValue] }),
    ...taskData[taskNameValue]
  };
  return mockTask;
};

export const createMockTaskList = (guid, options) => {
  const { length, staticTaskEnums } = options || {};
  const { [guid]: guidData } = staticTaskApps || {};
  const { employeeGroup: staticEmployeeGroup } = guidData || {};
  let mockEmployeeGroup = guidData ? staticEmployeeGroup : null;
  /**
   * If the app does not have any static tasks set, then set
   * an employee group for the mock app, so the task data knows
   * which employee group's tasks to return
   */
  if (isEmpty(mockEmployeeGroup)) {
    mockEmployeeGroup = 'APP_REVIEW';
  }
  const staticTaskData = typeof guidData !== 'undefined' ? guidData.taskData : undefined;
  const hasStaticTasks = typeof staticTaskData !== 'undefined';
  const mockAllTasks = (hasStaticTasks && !isEmpty(staticTaskData)) || !hasStaticTasks
    ? (
      length
        ? crabMasterTaskList.filter(
          item => (!isEmpty(staticTaskEnums)
            ? staticTaskEnums.includes(item.value)
            : item.isAppReviewTask)
        ).slice(0, length)
        : crabMasterTaskList
    ).map((taskItem, taskIndex) => ({
      applicationTask: createMockTask(guid, {
        ...options,
        mockEmployeeGroup,
        taskItem,
        taskIndex
      })
    }))
    : [];
  const mockTaskListAppReview = mockAllTasks.filter(
    t => appReviewTasks.includes(t.applicationTask.applicationTaskName)
  );
  return mockTaskListAppReview;
};

export const mockEmployeeCrabV1ApplicationTaskProhibitedEntitiesGet = (body, params, options) => {
  const { guid } = options || {};
  const taskEnum = 'prohibited_entities';
  const {
    taskItem,
    taskIndex
  } = getMockTaskItemAndIndex(taskEnum);
  const mockEmployeeGroup = 'APP_REVIEW';
  const mockMatchTask = {
    applicationTask: createMockTask(guid, {
      mockEmployeeGroup,
      taskItem,
      taskIndex
    }),
    prohibitedEntities: createMockProhibitedEntitiesList(options)
  };
  return { [guid]: { task: mockMatchTask } };
};

export const mockEmployeeCrabV1ApplicationTaskGet = (body, params, options) => {
  const { guid } = options || {};
  const mockTaskList = createMockTaskList(guid, options);
  return { [guid]: { tasks: mockTaskList } };
};

export const mockEmployeeCrabV1ApplicationTaskMatchGet = (body, params, options) => {
  const { guid } = options || {};
  const taskEnum = 'match';
  const {
    taskItem,
    taskIndex
  } = getMockTaskItemAndIndex(taskEnum);
  const mockEmployeeGroup = 'APP_REVIEW';
  const mockMatchTask = {
    applicationTask: createMockTask(guid, {
      mockEmployeeGroup,
      taskItem,
      taskIndex
    }),
    matchDataList: createMockMatchDataList(guid)
  };
  return { [guid]: { task: mockMatchTask } };
};

export const mockEmployeeCrabV1ApplicationTaskRelatedPersonsGet = (body, params, options) => {
  const { guid } = options || {};
  const { employeeGroup } = params || {};
  const taskEnum = 'related_person';
  const {
    taskItem,
    taskIndex
  } = getMockTaskItemAndIndex(taskEnum);
  const mockRelatedPersonsTask = {
    applicationTask: createMockTask(guid, {
      mockEmployeeGroup: employeeGroup,
      taskItem,
      taskIndex
    }),
    relatedApplications: createRelatedApplications(guid),
    relatedMerchants: createRelatedMerchants(guid)
  };
  return { [guid]: { task: mockRelatedPersonsTask } };
};

export const mockEmployeeCrabV1ApplicationTaskWebsiteReviewGet = (body, params, options) => {
  const { guid, length } = options || {};
  const { employeeGroup } = params || {};
  const mockEmployeeGroup = employeeGroup || 'APP_REVIEW';
  const taskEnum = 'website_html_review';
  const {
    taskItem,
    taskIndex
  } = getMockTaskItemAndIndex(taskEnum);
  const mockWebsiteReviewTask = ['APP_REVIEW'].includes(mockEmployeeGroup) ? {
    applicationTask: createMockTask(guid, {
      mockEmployeeGroup,
      taskItem,
      taskIndex
    }),
    htmlReviewData: Array.from({ length: length || getMockValue({ type: 'number', min: 3, max: 3 }) }, (v, i) => scannedInformationElement(i))
  } : {};
  return { [guid]: { task: mockWebsiteReviewTask } };
};

const scannedInformationElement = (index) => {
  const mockDomain = getMockValue({ type: 'urlRequiredProtocol' });
  const mockFlaggedWords = ['trial', 'recurring', 'membership', 'subscription', 'negative option billing'];
  return {
    applicationMpaId: index,
    dbaName: getMockValue({ type: 'businessName' }),
    domainScanned: mockDomain,
    ...(index === 1 && { forbiddenUrl: `${mockDomain}/mock-forbidden-url` }),
    ...(index === 2 && { brokenUrl: `${mockDomain}/mock-broken-url` }),
    urlToFlaggedWordList: index === 0 ? Array.from({ length: getMockValue({ type: 'number', min: 1, max: 5 }) }).map((urlItem, urlIndex) => ({
      url: `${mockDomain}/path-${urlIndex + 1}`,
      numberOfHits: getMockValue({ type: 'number' }),
      flaggedList: [...new Set(Array.from({ length: getMockValue({ type: 'number', min: 1, max: mockFlaggedWords.length }) }).map((v, i) => getMockValue({ type: 'list', list: mockFlaggedWords })))]
    })) : []
  };
};

export const mockEmployeeCrabV1ApplicationTaskWhoisReviewGet = (body, params, options) => {
  const { guid } = options || {};
  const { employeeGroup } = params || {};
  const taskEnum = 'whois_review';
  const {
    taskItem,
    taskIndex
  } = getMockTaskItemAndIndex(taskEnum);
  const mockWebsiteReviewTask = {
    applicationTask: createMockTask(guid, {
      mockEmployeeGroup: employeeGroup,
      taskItem,
      taskIndex
    }),
    whoisData: Array.from({ length: getMockValue({ type: 'number', min: 4, max: 4 }) }, (v, i) => whoisElement(i, guid))
  };
  return { [guid]: { task: mockWebsiteReviewTask } };
};

const whoisElement = (index, guid) => {
  const mockAddressFields = getMockValue({ type: 'address' });
  return {
    applicationMpa: {
      applicationMpaId: index,
      dbaName: getMockValue({ type: 'string' })
    },
    domainName: index < 3 && guid !== '10' ? getMockValue({ type: 'url' }) : '',
    registrant: index < 3 && guid !== '10'
      ? {
        name: getMockValue({ type: 'string' }),
        organization: getMockValue({ type: 'string' }),
        phone: getMockValue({ type: 'phone' }),
        mailingAddress: {
          addressLine1: mockAddressFields.fullAddress,
          addressLine2: getMockValue({ type: 'string' }),
          city: mockAddressFields.cityName,
          state: mockAddressFields.stateCode,
          postalCode: mockAddressFields.zipCode,
          country: 'US' // FE default (string) 2 Chars CountryCode
        }
      }
      : null
  };
};

export const mockEmployeeCrabV1ApplicationTaskRiskExposureGet = (body, params, options) => {
  const { guid } = options || {};
  const riskFormulaTypeEnum = ['corvia', 'priority', 'repay'];
  const riskExposure = Array.from({ length: getMockValue({ type: 'number', min: 2, max: 3 }) }).map((exposureRow, index) => ({
    mpaApplication: {
      mpaApplicationId: `${index}`,
      dbaName: getMockValue({ type: 'string' })
    },
    riskExposureInputs: mockRiskExposureInputs(),
    riskExposureOutputs: riskFormulaTypeEnum.map(rowType => riskExposureRow(rowType))
  }));
  const mockEmployeeGroup = 'APP_REVIEW';
  const taskEnum = 'risk_exposure';
  const {
    taskItem,
    taskIndex
  } = getMockTaskItemAndIndex(taskEnum);
  const mockRiskExposureTask = {
    applicationTask: createMockTask(guid, {
      mockEmployeeGroup,
      taskItem,
      taskIndex
    }),
    riskExposure
  };
  return { [guid]: { task: mockRiskExposureTask } };
};

const riskExposureRow = rowType => ({
  returnsRiskExposure: getMockValue({ type: 'number' }),
  chargebackRiskExposure: getMockValue({ type: 'number' }),
  deliveryRiskExposure: getMockValue({ type: 'number' }),
  feeRiskExposure: getMockValue({ type: 'number' }),
  grossRiskExposure: getMockValue({ type: 'number' }),
  riskFormulaType: rowType
});
