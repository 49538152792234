export const userDetailsEmail = 'portal_user@f1payments.com';

export const mockUserDetailsGet = (body, params) => {
  const { userEmail = '' } = params || {};
  return {
    email: userEmail,
    phone: '+12223334444',
    createdAccount: true,
    verifiedPhone: true,
    verifiedEmail: false,
    lastSignTimestamp: '2021-01-16 00:23:04Z'
  };
};
