import { isEmpty } from '../../_helpers';
import { getTagObjects } from '../sharedBoarding/templates/filesWithTagsTemplate';

export const fileAttachToResourceTemplate = {
  backend: (schema, version) => { // PUT
    if (version === '1.0') {
      if (isEmpty(schema)) { return {}; }
      const requestBody = {
        files: schema.map(file => ({
          fileName: file.name,
          relativePath: isEmpty(file.path) ? buildRelativePath(file) : file.path
        }))
      };
      return requestBody;
    }
    if (version === '2.0') {
      const { files, defaultTagsOnAdd } = schema || {};
      if (isEmpty(files)) { return {}; }
      const requestBody = {
        files: files.map(file => ({
          fileName: file.name,
          tags: getTagObjects({
            addingNewFiles: true,
            tags: [
              ...(!isEmpty(file.tagList) ? file.tagList : []),
              // Any other tags that should be automatically added under the covers
              ...(!isEmpty(defaultTagsOnAdd) ? defaultTagsOnAdd : [])
            ]
          })
        }))
      };
      return requestBody;
    }
    if (version === '3.0') {
      const { files, defaultTagsOnAdd } = schema || {};
      const requestBody = isEmpty(files)
        ? {}
        : {
          files: files.map(file => ({
            fileName: file.name,
            s3Key: file.s3Key,
            tags: getTagObjects({
              addingNewFiles: true,
              tags: [
                ...(!isEmpty(file.tagList) ? file.tagList : []),
                // Any other tags that should be automatically added under the covers
                ...(!isEmpty(defaultTagsOnAdd) ? defaultTagsOnAdd : [])
              ]
            })
          }))
        };
      return requestBody;
    }
    return schema;
  }
};

export const buildRelativePath = (file) => {
  const { customPathPrefix = '', customPath = '' } = file || {};
  return `${cleanFilePath(customPathPrefix)}${cleanFilePath(customPath)}`;
};

export const cleanFilePath = (path) => {
  let tempPath = path || '';
  if (!isEmpty(path)) {
    tempPath = checkForOpeningPathSeparator(path);
  }
  return tempPath.endsWith('/') ? tempPath.substring(0, tempPath.length - 1) : tempPath;
};

export const checkForOpeningPathSeparator = path => (!isEmpty(path) && !path.startsWith('/') ? `/${path}` : path);
