import * as d3 from 'd3';
import React from 'react';
import { PropTypes } from 'prop-types';
import { isEmpty } from '../_helpers';
import { ToolTip } from '../ToolTip';
import { Button } from '../Button';
import { ToggleSwitch } from '../ToggleSwitch';
import { icons } from '../../images/_icons';
import { treeStyleCSS } from './_styles';

import { Spinner } from '../Spinner';

class D3Tree extends React.Component {
  constructor (props) {
    super(props);
    this.mounted = false;
    this.state = {
      treeNode: null,
      spinnerLoading: false,
      treeIndex: 1,
      updateTree: null,
      initWidth: 1180,
      initHeight: 360,
      margin: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      boxW: 160,
      boxH: 60,
      duration: 750,
      hovered: {
        type: '',
        data: null
      },
      tooltipStyle: {
        x: 0,
        y: 0
      },
      canZoom: false
    };
    this.element = React.createRef();
  }

  componentDidMount () {
    this.mounted = true;
    this.updateState({ spinnerLoading: true });
    setTimeout(() => {
      this.createD3Tree();
    }, 3000); // to allow tree to render
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  updateState = (state) => {
    this.mounted && this.setState(state);
  }

  handleResize = (options) => {
    const {
      manual = false,
      inbound = false
    } = options || {};
    const { current: currentElement } = this.element;
    if (currentElement) {
      const {
        rootTree,
        canZoom
      } = this.state;
      if (!canZoom || manual) {
        const svgWrap = currentElement.querySelector('svg');
        // if it's the root node, set depth to 1 instead of 0, so newHeight doesnt multipley by 0.
        const depth = this.countLevels(rootTree) === 0 ? 1 : this.countLevels(rootTree);
        const scale = this.getTransforms().k;
        const newHeight = ((depth + 1) * 180 + 20) * scale;
        if (svgWrap) {
          svgWrap.style.height = `${newHeight}px`;
        }
        svgWrap.addEventListener('transitionend', () => this.transitionCallback('zoomFit', inbound));
      }
    }
  }

  handleMouseMove = (e) => {
    const {
      tooltipStyle
    } = this.state;
    this.updateState({
      tooltipStyle: {
        ...tooltipStyle,
        x: e.clientX,
        y: e.clientY
      }
    });
  }

  handleMouseOver = (e, d) => {
    // only show tooltip if node has children under it (designated by a height greater than 0)
    if (d.height > 0) {
      const {
        tooltipStyle
      } = this.state;
      const {
        chartData: {
          header: {
            label
          }
        }
      } = this.props || {};
      this.updateState({
        tooltipStyle: {
          ...tooltipStyle,
          revealed: true
        },
        hovered: {
          type: 'tree',
          data: {
            childLevels: d.height,
            header: label
          }
        }
      });
    }
  }

  handleMouseOut = (d) => {
    this.updateState({
      hovered: {
        type: '',
        header: null,
        data: null
      }
    });
  }

  handleClick = (e, d) => {
    if (!d.children && !d.dChildren) {
      return;
    }
    const dCopy = d;
    if (dCopy.children) {
      dCopy.dChildren = dCopy.children;
      dCopy.children = null;
    } else {
      dCopy.children = dCopy.dChildren;
      dCopy.dChildren = null;
    }
    this.updateTree(dCopy);
    this.handleResize();
  }

  countLevels = (data) => {
    const array = [];
    const countChildren = (d, depth = 0) => {
      const { children } = d || [];
      let foundOne = false;
      children && children.forEach((child, i) => {
        if (child.children) {
          array[depth] = 1;
          foundOne = true;
          child.children && countChildren(child, depth + 1);
        } else if (!foundOne) {
          array[depth] = 0;
        }
      });
    };
    countChildren(data);
    return array.length;
  }

  updateTree = (d) => {
    const {
      updateTree
    } = this.state;
    updateTree(d);
  }

  bezierCurveGenerator = (d, type) => {
    const {
      source
    } = this.state;
    let s;
    let t;
    if (type === 'enter') {
      s = { x: source.x0, y: source.y0 };
      t = { x: source.x0, y: source.y0 };
    }
    if (type === 'update') {
      s = d;
      t = d.parent;
    }
    if (type === 'exit') {
      s = source;
      t = source;
    }
    const line = d3.linkVertical()
      .x(d2 => d2.x)
      .y(d2 => d2.y + (d.parent.depth === 0 ? 0 : 60));
    return line({ source: s, target: t });
  }

  renderLineOnEnter = d => this.bezierCurveGenerator(d, 'enter')

  renderLineOnUpdate = d => this.bezierCurveGenerator(d, 'update')

  renderLineOnExit = d => this.bezierCurveGenerator(d, 'exit')

  createD3Tree = async () => {
    const { current: currentElement } = this.element;
    const {
      initWidth,
      initHeight,
      margin,
      boxW,
      boxH,
      duration
    } = this.state;
    const {
      chartData = {}
    } = this.props;
    const {
      data = []
    } = chartData;
    if (currentElement !== null) {
      const width = initWidth - margin.right - margin.left;
      const height = initHeight - margin.top - margin.bottom;

      // Assigns parent, children, height, depth
      const rootTree = d3.hierarchy(data, d => d.downlines);
      rootTree.x0 = height / 2;
      rootTree.y0 = 0;

      let zoomEnabled = false;

      // declares a tree layout and assigns the size
      const tree = d3.tree()
        .nodeSize([width, width])
        .separation((a, b) => (a.parent === b.parent ? 0.145 : 0.145));

      const zoomed = (e) => { svg.select('.treeWrapper').attr('transform', e.transform); };

      const isZoomEnabled = () => zoomEnabled;

      const zoomListener = d3
        .zoom()
        .scaleExtent([0.1, 3])
        .filter(isZoomEnabled)
        .on('zoom', zoomed);

      const svg = d3.select(currentElement)
        .append('svg')
        .style('width', '100%')
        .style('transition', 'all 1s ease')
        .style('height', height + margin.top + margin.bottom)
        .style('min-height', `${initHeight}px`);
        // .attr('viewBox', `0 0 ${initWidth} ${initHeight}`)
        // .attr('preserveAspectRatio', 'xMinYMin');

      const setPosition = /* istanbul ignore next */() => {
        const position = this.getTransforms();
        const transform = d3.zoomIdentity
          .translate(position.x, position.y)
          .scale(position.k);
        svg.call(zoomListener.transform, transform);
      };

      // append the svg object to the current element
      // appends a 'group' element to 'svg'
      // moves the 'group' element to the top of the box
      svg
        .on('mousedown', setPosition)
        .on('mouseup', setPosition)
        .on('touchstart', setPosition)
        .on('touchend', setPosition)
        .on('wheel', setPosition)
        .call(zoomListener);

      const innerWrap = svg
        .append('g')
        .attr('class', 'treeWrapper')
        .attr('transform', `translate(${initWidth / 2}, ${margin.top + boxH}) scale(0.5)`);

      // Collapse the node and all it's children
      const collapse = (d) => {
        const dCopy = d;
        if (d.children) {
          dCopy.dChildren = dCopy.children;
          dCopy.dChildren.forEach(collapse);
          dCopy.children = null;
        }
      };

      const toggleZoom = (state) => { zoomEnabled = state; };

      await this.updateState({
        rootTree,
        zoomListener,
        toggleZoom,
        treeNode: innerWrap.node()
      });

      // Collapse after the second level
      rootTree.children.forEach(collapse);

      const update = (source) => {
        this.updateState({
          source
        });

        // Assigns the x and y position for the nodes
        const {
          treeId
        } = this.props;
        const treeData = tree(rootTree);

        // Compute the new tree layout.
        const nodes = treeData.descendants();
        const links = treeData.descendants().slice(1);

        // Normalize for fixed-depth.
        nodes.forEach((d) => {
        // using Object.assign() to resolve no-re-assign linting errors
          Object.assign(d, { y: d.depth * 180 });
        });

        // ****************** Nodes section ***************************

        // Update the nodes...
        const node = innerWrap.selectAll('foreignObject.node')
          .data(nodes, (d) => {
            if (!d.id) {
              const {
                treeIndex
              } = this.state;
              this.updateState({ treeIndex: treeIndex + 1 });
              Object.assign(d, { id: treeIndex });
            }
            return d.id;
          });

        // Enter any new modes at the parent's previous position.
        const nodeEnter = node.enter()
          .append('foreignObject')
          .attr('class', 'node')
          .attr('style', 'z-index: 5')
          .attr('transform', d => `translate(${source.x0},${source.y0})`) // makes children transitionly appear from the clicked node
          .attr('width', boxW)
          .attr('height', d => ((!isEmpty(d.children) || !isEmpty(d.dChildren)) ? boxH + 25 : boxH))
          .attr('x', -boxW / 2)
          .attr('y', d => (d.parent ? 0 : -boxW / 2 + 20)) // 0, except root needs adjusting
          .on('click', this.handleClick)
          .on('mousemove', this.handleMouseMove)
          .on('touchmove', this.handleMouseMove)
          .on('mouseenter', this.handleMouseOver)
          .on('touchstart', this.handleMouseOver)
          .on('mouseleave', this.handleMouseOut)
          .on('touchend', this.handleMouseOut);

        // create custom html
        nodeEnter.append('xhtml:div')
          .attr('class', d => (d.depth === 0 ? 'd3TreeWrap root' : 'd3TreeWrap'))
          .html(d => ((d.depth === 0 && treeId === 'relationshipTree') ? '<div class="logo"></div>' : this.getHtml(d)));

        // UPDATE
        const nodeUpdate = nodeEnter.merge(node);

        // Transition to the proper position for the node
        nodeUpdate.transition()
          .duration(duration)
          .attr('transform', d => `translate(${d.x},${d.y})`);

        const colorCheck = d => (d.dChildren ? 'lightsteelblue' : '#fff');
        const boxHCheck = d => (d.dChildren ? boxH + 50 : boxH);

        // Update the node attributes and style
        nodeUpdate.select('foreignObject')
          .attr('height', boxHCheck)
          .style('background', colorCheck)
          .attr('cursor', 'pointer');

        // Remove any exiting nodes
        node.exit().transition()
          .duration(duration)
          .attr('transform', `translate(${source.x},${source.y})`)
          .remove()
          .select('foreignObject')
          .attr('width', boxW)
          .attr('height', boxH)
          .attr('stroke', 'black')
          .attr('stroke-width', 1)
          .style('background', colorCheck)
          .attr('cursor', 'pointer');

        // ****************** links section ***************************

        // Update the links...
        const link = innerWrap.selectAll('path.link')
          .data(links, d => d.id);

        // Enter any new links at the parent's previous position.
        const linkEnter = link
          .enter()
          .insert('path', 'foreignObject')
          .attr('class', 'link')
          .attr('d', this.renderLineOnEnter)
          .attr('fill', 'none')
          .attr('stroke', 'black')
          .attr('stroke-width', 1);

        // UPDATE
        const linkUpdate = linkEnter.merge(link);

        // Transition back to the parent element position
        linkUpdate.transition()
          .duration(duration)
          .attr('d', this.renderLineOnUpdate);

        // Remove any exiting links
        link.exit().transition()
          .duration(duration)
          .attr('d', this.renderLineOnExit)
          .remove();

        // Store the old positions for transition.
        nodes.forEach((d) => {
          Object.assign(d, {
            x0: d.x,
            y0: d.y
          });
        });
      };
      this.updateState({ updateTree: update });
      update(rootTree);
      d3.select(window).on(`resize.${currentElement}`, this.handleResize);
      // give it a second to render, then fit to container
      setTimeout(() => { this.zoomFit(); }, 1000);
    }
    this.updateState({ spinnerLoading: false });
  }

  getHtml = (d) => {
    const {
      chartData: {
        header: {
          useExpander
        }
      },
      treeId
    } = this.props;
    const hasChildren = !isEmpty(d.children) || !isEmpty(d.dChildren);
    if (treeId === 'relationshipTree') {
      return (`
      <div class="${(hasChildren && !useExpander) ? 'd3TreeBox expandable' : 'd3TreeBox'}">
        <div class="leafName">${d.data.relationshipName}</div>
        <div class="leafCode">Code ${d.data.relationshipCode || '12345'}</div>
        <div class="leafBtm">
          <div class="leafBank">${d.data.bankName}</div>
          <div class="leafProcessor">${d.data.processorName}</div>
        </div>
      </div>
      ${(hasChildren && useExpander) ? '<div class="expandNodes">...</div>' : ''}
    `);
    }
    return (`<div>Tree HTML TBD</div>`);
  }

  zoomFit = (options) => {
    const {
      manual = false,
      inbound = false
    } = options || {};
    const {
      margin,
      initWidth,
      initHeight,
      treeNode
    } = this.state;
    const { current } = this.element;
    const bounds = treeNode.getBBox();
    if (
      isEmpty(current) ||
      bounds.width === 0 ||
      bounds.height === 0
    ) return; // nothing to fit
    const fullWidth = treeNode.parentElement.clientWidth;
    const fullHeight = treeNode.parentElement.clientHeight;
    const midX = bounds.x + bounds.width / 2;
    const midY = bounds.y + bounds.height / 2;
    const widthScale = (initWidth - margin.left - margin.right) / bounds.width;
    const heightScale = (initHeight - margin.top - margin.bottom) / bounds.height;
    const scale = (bounds.width > bounds.height) ? widthScale : heightScale;
    // Apply the above transforms.
    // first have to set it to the current state
    const originalTransforms = this.getTransforms();
    this.transformTree({
      x: originalTransforms.x,
      y: originalTransforms.y,
      k: originalTransforms.k
    });
    // then move to zoomed position and scale
    this.transformTree({
      x: fullWidth / 2 - scale * midX,
      y: fullHeight / 2 - scale * midY,
      k: scale
    }, { animate: true });
    manual && this.handleResize({ manual: true });
    treeNode.addEventListener('transitionend', () => this.transitionCallback('handleResize', inbound));
  }

  transitionCallback = (runMethod, inbound) => {
    !inbound && setTimeout(() => {
      // when all node transitions are done, check container size.
      // !inbound === but only do this one, so we dont loop.
      runMethod === 'handleResize' && this.handleResize({ inbound: true });
      runMethod === 'zoomFit' && this.zoomFit({ inbound: true });
    }, 100); // give it a fraction, it doesnt always catch otherwise.
  }

  getTransforms = () => {
    // get current transforms of the tree.
    const { treeNode } = this.state;
    if (treeNode) {
      const startingPos = treeNode.getAttribute('transform');
      const coords = (/\(([^)]+)\)/).exec(startingPos)[1].split(',');
      const scale = startingPos.includes('scale') ? (/scale\(([^)]+)\)/).exec(startingPos)[1] : 1;
      return {
        x: coords[0],
        y: coords[1],
        k: scale
      };
    }
    return true;
  }

  zoomToggle = (state) => {
    const {
      toggleZoom
    } = this.state;
    this.updateState({
      canZoom: state
    });
    toggleZoom(state);
  }

  transformTree = (translate, options) => {
    const {
      animate = false
    } = options || {};
    const {
      zoomListener,
      duration
    } = this.state;
    if (zoomListener?.transform) {
      const { current } = this.element;
      const svg = d3.select(current);
      const transform = d3.zoomIdentity
        .translate(translate.x, translate.y)
        .scale(translate.k);
      if (animate) {
        svg
          .transition()
          .duration(duration)
          .call(zoomListener.transform, transform);
      } else {
        svg
          .call(zoomListener.transform, transform);
      }
    }
  }

  render () {
    const { chartData } = this.props;
    const {
      spinnerLoading,
      tooltipStyle,
      hovered
    } = this.state;
    return (
      <div
        style={{ width: '100%', height: '100%' }}
        className="d3wrapper"
        ref={this.element}
      >
        <div
          className="treeTools"
          style={treeStyleCSS.toolbar}
        >
          <ToggleSwitch
            name="pan_zoom"
            id="pan_zoom"
            label="Pan/Zoom"
            value="enablePanZoom"
            checked={false}
            callback={this.zoomToggle}
            wrapperStyle={{ marginBottom: '10px', float: 'left' }}
          />
          <ToolTip
            text={(
              <Button
                id="zoomExtents"
                icon={icons.zoom()}
                type="text"
                size="md"
                onClick={() => this.zoomFit({ manual: true })}
                style={{ float: 'right' }}
              />
            )}
          >
            Zoom Extents
          </ToolTip>
        </div>
        <Spinner loading={spinnerLoading} />
        {!isEmpty(hovered.data) && (
          <ToolTip
            d3Data={hovered}
            d3Position={tooltipStyle}
            element={this.element.current}
            options={{ ...chartData.header, colorMap: chartData.colors }}
          />
        )}
      </div>
    );
  }
}

D3Tree.propTypes = {
  chartData: PropTypes.oneOfType([PropTypes.object]),
  treeId: PropTypes.string
};

D3Tree.defaultProps = {
  chartData: {},
  treeId: null
};

export default D3Tree;
