import { endpoint, trainingEndpoint, url } from '../_helpers';

import { getMockDataFromMap } from '../_testHelpers';
import { mockCaptchaSiteKey } from './parameter/captchaSiteKey';
import { mockGeolocationApiKey } from './parameter/geolocationApiKey';
import { mockBoardingApplicationFilesGet } from './sharedBoarding/mockBoardingApplicationFiles';
import { mockCrabExternalCommunicationPut } from './sharedBoarding/mockBoardingApplicationExternalCommunication';
import { mockDefaultPostRes } from './_mockDataHelpers';
import { mockTicketsExternalCommunicationGet, mockTicketsExternalCommunicationPutRes } from './tickets/mockTicketsExternalCommunication';
import { mockCrabV1SubmitValidationGet } from './crab/mockCrabV1SubmitValidation';
import { mockMerchantLedgerGet } from './reports/merchantLedger';
import { mockFileV3CacheUploadLinkGet } from './file/mockFiles';
import { mockLessonPlanGet, mockLessonPlanAttachToResourceRes, mockLessonPlanPUTRes } from './training/mockLessonPlan';
import { mockGeocodedLocation } from './geocodedLocation/geocodedLocation';
import { mockTransactionProfitabilityDetail, mockTransactionProfitabilitySummary } from './common/transactionProfitability';

export const getMockData = options => getMockDataFromMap({ ...options, map: sharedStubDataMap });

export const sharedStubDataMap = {
  [endpoint.parameter.captchaSiteKey]: {
    GET: mockCaptchaSiteKey
  },
  [endpoint.parameter.geolocationApiKey]: {
    GET: mockGeolocationApiKey
  },
  [endpoint.geocodedLocation.geocodedLocation]: {
    POST: mockGeocodedLocation
  },
  [`${url}/log`]: {
    PUT: {}
  },
  [`${url}/dummyUploadLink`]: {
    PUT: {}
  },
  [endpoint.partnerPortal.externalCommunication]: {
    GET: (body, params, options) => mockTicketsExternalCommunicationGet(body, params, options),
    PUT: (body, params, options) => mockTicketsExternalCommunicationPutRes(body, params, options)
  },
  [endpoint.crab.v1.application.externalCommunication]: {
    PUT: mockCrabExternalCommunicationPut
  },
  [endpoint.crab.v1.file.applicationPackage]: {
    GET: (body, params, options) => mockBoardingApplicationFilesGet(body, params, options)
  },
  [endpoint.crab.v1.application.submitValidation]: {
    GET: (body, params, options) => mockCrabV1SubmitValidationGet(body, params, options)
  },
  [endpoint.crab.v1.application.submit]: {
    POST: mockDefaultPostRes
  },
  [endpoint.crab.v1.application.sendForElectronicSignature]: {
    POST: mockDefaultPostRes
  },
  [endpoint.crab.v1.application.submitRescind]: {
    POST: mockDefaultPostRes
  },
  [endpoint.report.ledger]: {
    GET: (body, params, options) => mockMerchantLedgerGet(body, params, options)
  },
  [endpoint.file.v3DummyUploadUrl]: {
    PUT: {}
  },
  [endpoint.file.v3.cacheUploadLink]: {
    GET: mockFileV3CacheUploadLinkGet
  },
  [endpoint.file.v3.root]: {
    POST: mockDefaultPostRes,
    DELETE: mockDefaultPostRes
  },
  [endpoint.file.v3.attachToResource]: {
    PUT: mockDefaultPostRes
  },
  // Training
  [trainingEndpoint.lessonPlan]: {
    GET: mockLessonPlanGet,
    PUT: mockLessonPlanPUTRes,
    POST: (body, params, options) => mockDefaultPostRes(body, params, options),
    DELETE: (body, params, options) => mockDefaultPostRes(body, params, options)
  },
  [trainingEndpoint.lessonPlanFileAttachToResource]: {
    PUT: (body, params, options) => mockLessonPlanAttachToResourceRes(body, params, options)
  },
  [trainingEndpoint.lessonPlanFile]: {
    DELETE: mockDefaultPostRes
  },
  // Open to public (no token required)
  [endpoint.crab.webform.boarding.v1.application]: {
    POST: mockDefaultPostRes
  },
  [endpoint.crab.webform.boarding.v1.cacheUploadLink]: {
    GET: mockFileV3CacheUploadLinkGet
  },
  // Common between portal & meco
  [endpoint.transactionProfitability.detail]: {
    GET: mockTransactionProfitabilityDetail
  },
  [endpoint.transactionProfitability.summary]: {
    GET: mockTransactionProfitabilitySummary
  }
};

export default sharedStubDataMap;
