import {
  dataExists,
  getFormattedRelationship,
  getType,
  ignoreCase,
  transformData,
  isBool,
  isEmpty,
  isEqual
} from '../../../_helpers';

import { seasonalBusinessMonthFields } from '../../../_boardingToolFields';
import filesWithTagsTemplate from './filesWithTagsTemplate';
import {
  useDefaultProcessor
} from './crabV1ApplicationTemplate';
import { allCrabRestrictedTags, useRepayAchAccountRadio, getCrabFormFields } from '../../../_crabFields';
import {
  cleanFieldsForBackend,
  isPriorityFirstDataBank,
  isPriorityTsysBank,
  toBackendValue,
  toFrontendValue
} from '../../../_templateHelpers';
import { buildPriorityTabData, priorityFieldsBackend } from '../../../_crabPriorityHelpers';

export const sharedBoardingTemplate = {
  // shared template used for boarding forms (eg, high-risk v2, low-risk)
  frontend: (schema, version) => {
    if (version === '1.0') {
      const {
        originalBackendJsonForSave,
        applicationJson, // BE data: array of appJson objects OR a single appJson object
        relationship, // BE data { relationship: { relationshipName, relationshipId } }
        options // { signatureType, appType, tabFormFields, disableFormFields }
      } = schema;
      const parsedJson = typeof applicationJson === 'string' && !isEmpty(applicationJson)
        ? JSON.parse(applicationJson)
        : applicationJson;
      const unNestingMpas = !isEmpty(parsedJson?.[0]?.mpa)
        ? applicationJson.map(anMpa => ({
          ...anMpa,
          ...anMpa.mpa
        }))
        : parsedJson;
      const originalBackendJson = {
        // unformatted BE applicationJson, needed for backendSave/backend transform
        originalBackendJson: !isEmpty(originalBackendJsonForSave)
          ? originalBackendJsonForSave
          : parsedJson
      };
      if (Array.isArray(unNestingMpas)) {
        const formatted = {
          ...schema,
          ...originalBackendJson,
          mpaList: formatMpas(unNestingMpas, { ...options, relationship })
        };
        return formatted;
      }
      const formatted = formatMpas([unNestingMpas], { ...options, relationship });
      return {
        ...(formatted[0] || {}),
        ...originalBackendJson
      };
    }
    return schema;
  },
  frontendMpaDetail: (schema, version) => { // (crab) GET /v1/application/mpa/detail
    if (version === '1.0') {
      const { mpaDetails = {}, options } = schema || {};
      const tabFormFields = getCrabFormFields(options?.relationship || {});
      const formattedMpa = !isEmpty(mpaDetails?.mpa) ? transformData({
        data: {
          applicationJson: {
            ...mpaDetails?.mpa,
            applicationMpaId: mpaDetails?.applicationMpaId || false,
            signatureType: ignoreCase(options?.signatureType || ''),
            fileList: mpaDetails?.fileList || []
          },
          relationship: options?.relationship || {},
          // should match format for POST /mpa
          originalBackendJsonForSave: createMpaPostRequestBody(mpaDetails),
          options: { ...options, tabFormFields }
        },
        toSchema: 'frontend',
        template: sharedBoardingTemplate,
        version: '1.0'
      }) : [];
      return formattedMpa;
    }
    return schema;
  },
  frontendMpaMetadata: (schema, version) => {
    // GET /v1/application/mpa/detail  - Employee only fields
    if (version === '1.0') {
      const { backendData, options } = schema || {};
      const { userType } = options || {};
      if (userType === 'employee') {
        const { mpaDetails, employeeOnlyMpaMetadata } = backendData || {};
        const {
          policyException, // already gone through toBe conversion (formAssistant)
          riskExposureInputs // need toBe conversion (no formAssistant)
        } = employeeOnlyMpaMetadata || {};
        return {
          dbaName: mpaDetails?.dbaName,
          applicationMpaId: mpaDetails?.applicationMpaId,
          // Form fields
          policyExceptionDescription: policyException?.description || '',
          riskExposureInputs: formatRiskExposureInputsFe(riskExposureInputs || {})
        };
      }
      return {};
    }
    return schema;
  },
  frontendTemplateDetail: (schema, version) => { // GET /v1/application/template/detail
    if (version === '1.0') {
      const {
        options = {},
        applicationTemplateMpa = {}
      } = schema;
      const {
        /** Other relevant options:
         * templateRelationship, // To determine if `neteviaFields` data should be formatted
         */
        relationship
      } = options || {};
      if (isEmpty(applicationTemplateMpa)) return schema;
      const tabFormFields = getCrabFormFields(relationship || {});
      const formatted = {
        ...schema,
        mpaList: formatMpas([applicationTemplateMpa], {
          ...options,
          isLoadingTemplate: true,
          tabFormFields
        })
      };
      return formatted;
    }
    return schema;
  },
  backendSaveFees: (schema, version) => { // Employee (edit access) only - Updating Fees tab only
    const {
      relationship = {},
      updatedMpa = {}, // new FE mpa to be converted to BE
      appType,
      clearPii = false, // don't save PII fields
      originalBackendJson = {}
    } = schema || {};

    if (version === '1.0') {
      const { merchantFees = {} } = updatedMpa;
      const formattedRelationship = getFormattedRelationship(relationship || {});
      const { processName } = formattedRelationship || {};
      const buildMpa = {
        ...(!isEmpty(merchantFees) && merchantFees)
      };
      const formattedMpa = formatAppMpaJson(buildMpa, appType, {
        relationship: formattedRelationship,
        clearPii,
        saveApp: true,
        originalBackendJson,
        onlyUseFeeData: true
      });
      const {
        repayFields = {},
        priorityFields = {},
        neteviaFields = {}
      } = formattedMpa || {};
      if (processName === 'netevia') {
        return {
          neteviaFields: {
            ratesAndFees: neteviaFields.ratesAndFees,
            fees: neteviaFields.fees
          }
        };
      }
      if (processName === 'priority') {
        const isPriorityTsys = isPriorityTsysBank(formattedRelationship);
        const isPriorityFirstData = isPriorityFirstDataBank(formattedRelationship);
        return !isEmpty(priorityFields) && (isPriorityTsys || isPriorityFirstData)
          ? {
            priorityFields: {
              ...(isPriorityTsys && {
                tsysRatesAndFees: priorityFields.tsysRatesAndFees
              }),
              ...(isPriorityFirstData && {
                firstDataRatesAndFees: priorityFields.firstDataRatesAndFees
              })
            }
          }
          : null;
      }
      return {
        repayFields: {
          ratesAndFees: repayFields.ratesAndFees
        }
      };
    }
    return schema;
  },
  backendTemplatePut: (schema, version) => { // PUT /v1/application/template
    if (version === '1.0') {
      const {
        relationship,
        appSignatureType,
        mpaData,
        cloneName,
        appType,
        mpaBackendData
      } = schema || {};
      const mpaDataForBackend = {
        ...mpaData
      };
      const formattedCloneMpa = // BE clears PII fields on template creation
        formatAppMpaJson(mpaDataForBackend, appType, {
          relationship,
          appSignatureType,
          originalBackendJson: mpaBackendData?.mpa
        });
      return {
        applicationTemplateName: cloneName,
        applicationTemplateMpa: formattedCloneMpa
      };
    }
    return schema;
  },
  backendSave: (schema, version) => { // POST /v1/application/mpa
    const {
      // allMpas = [], list of all mpas for the application already formatted for BE
      updatedMpa = {}, // new FE mpa to be converted to BE
      // mpaId = null,
      guid,
      appType,
      clearPii = false, // don't save PII fields
      // original json object for one mpa, or an array of original json objects for multiple mpas
      originalBackendJson = {},
      relationship = {},
      appSignatureType
    } = schema || {};
    if (version === '1.0') {
      const formattedMpa = formatAppMpaJson(updatedMpa, appType, {
        clearPii,
        saveApp: true,
        originalBackendJson,
        relationship,
        appSignatureType
      });
      if (guid === 'applicationMpaId') {
        return {
          applicationFields: createMpaPostRequestBody({
            mpa: formattedMpa
          })
        };
      }
    }
    return schema;
  },
  backendMpaMetadata: (schema, version) => { // POST /v1/application/mpa/metadata
    // Employee only (App review only)
    if (version === '1.0') {
      const {
        originalMetadataRes, // original successful apiRes.data for GET /mpa/detail

        // Only pass these fields if they were modified:
        policyExceptionDescription,
        riskExposureInputs
      } = schema || {};
      const isModified = v => (typeof v !== 'undefined'); // to handle null or other falsy value
      const dataToFormat = {
        ...(originalMetadataRes && { ...originalMetadataRes.employeeOnlyMpaMetadata }),
        ...(isModified(policyExceptionDescription) && {
          policyException: !isEmpty(policyExceptionDescription)
            ? { description: policyExceptionDescription }
            : null
        }),
        ...(isModified(riskExposureInputs) && {
          riskExposureInputs: formatRiskExposureInputsBe(riskExposureInputs || {})
        })
      };
      const dataFormatted = Object.entries(dataToFormat).reduce((acc, [dataKey, dataValue]) => {
        if (!isEmpty(dataValue) && getType(dataValue) === 'object') {
          // For objects that have key/value pairs,
          // check if all `values` within the object are empty. eg, { foo: null, bar: null }
          const allValuesEmpty = Object.values(dataValue).every(v => (v === null || typeof v === 'undefined'));
          // If all empty, BE expects `null` as the value for top-level object, eg, [dataKey]: null
          // (instead of the top-level object with all nested key/values with `null` values)
          return { ...acc, [dataKey]: allValuesEmpty ? null : dataValue };
        }
        return { ...acc, [dataKey]: dataValue };
      }, {});
      return {
        employeeOnlyMpaMetadata: dataFormatted
      };
    }
    return schema;
  }
};

const createMpaPostRequestBody = (backendData = {}) => {
  // Create the details under the `applicationFields` object
  const requestBody = {
    mpa: backendData.mpa
  };
  return requestBody;
};

export const formatAppMpaJson = (frontendData, appType, options) => {
  // formats FE mpa data to a single BE mpa object
  const {
    // clearPii = false, // pass true if we don't want to save PII (eg, templates)
    onlyUseFeeData = false,
    saveApp = false,
    originalBackendJson = {},
    relationship = {} // required
    // appSignatureType = '' // required if using digitizedAddendums
  } = options || {};
  const {
    // Business sections
    businessInformationSection = {},
    paymentProcessingAndSalesSection = {},
    businessStreetAddressSection = {},
    billingAddressSection = {},
    orderChannelSection = {},
    paymentMethodsAcceptedSection = {},
    // Fees sections
    accountUpdaterSection = {},
    declineRecoverySection = {},
    gatewaySection = {},
    mobileProcessingSection = {},
    otherFeesSection = {},
    ratesAndFeesSection = {},
    pinDebitFeesSection = {},
    tieredRatesSection = {},
    wirelessProcessingSection = {},
    rewardPaySection = {},
    cashRewardsSection = {},
    municipalitySection = {},
    enhancedInterchangeServiceSetupSection = {},
    softwareIntegrationSection = {},
    tipConfigSection = {},

    // Addendums
    alwaysShownControls = {},
    neteviaReserveFormFields = {},
    outsideLegalReviewFormFields = {},

    // Netevia only - neteviaFields
    neteviaCardNotPresentSection = {},
    neteviaBillingMethodsSection = {},
    neteviaFeesSection = {},

    // Application level fields
    corporateInformationSection = {},
    corporateOwnerListSection = {}
  } = frontendData || {};
  const formattedRelationship = getFormattedRelationship(relationship || {});
  const { riskProfile = '', processName = '' } = formattedRelationship || {};
  const isNetevia = ignoreCase(processName) === 'netevia';
  const isRepay = ignoreCase(processName) === 'repay';
  const isPriorityTsys = isPriorityTsysBank(formattedRelationship);
  const isPriorityFirstData = isPriorityFirstDataBank(formattedRelationship);
  const useRepayFields = isRepay;
  const isDefaultProcessor = useDefaultProcessor(relationship, {});
  const usePriorityFields = !isDefaultProcessor && (
    isPriorityTsys || isPriorityFirstData
  );
  const useNetevia = isNetevia && !isDefaultProcessor;
  const useAddendum = (!isDefaultProcessor && (useNetevia || ['standard', 'elevated'].includes(ignoreCase(riskProfile))));
  const formatOptions = { ...options, relationship: formattedRelationship, appType };

  const formattedAchInfoAndFunding = achInfoBackend(frontendData, formatOptions);

  const { mpa: originalBackendMpa = {} } = originalBackendJson || {};

  const backendAppLevelData = applicationLevelFieldsBackend(
    { ...corporateInformationSection, corporateOwnerListSection },
    { ...formatOptions, useAddressAutocomplete: true, clearPii: false }
  );

  const {
    digitizedAddendums,
    priorityFields,
    neteviaFields,
    repayFields,
    ratesAndFees,
    paymentCardAcceptance,
    ...restOfOriginalMpa
  } = originalBackendMpa || {};
  // Start with original BE application JSON data, so when users are saving
  // without going to the other tabs, we still send the original BE-returned data objects
  const originalBackendData = !isEmpty(originalBackendJson?.mpa)
    ? {
      ...restOfOriginalMpa,
      // Only include original BE `digitizedAddendums` if addendums data applies
      ...(useAddendum && { digitizedAddendums }),
      // Only include original BE `neteviaFields` if isNetevia and is not default
      ...(useNetevia && { neteviaFields }),
      ...(usePriorityFields && { priorityFields }),
      ...(useRepayFields && { repayFields })
    }
    : { ...originalBackendJson };

  // For `ratesAndFees` check if `valuesForBackend` exists. If so, format the data,
  // else, default `ratesAndFees` BE data will be sent via `originalBackendJson`
  const formattedRatesAndFees = ratesAndFeesSection?.valuesForBackend !== undefined
    ? {
      ratesAndFees: ratesFeesBackend({
        ratesFeesData: ratesAndFeesSection?.valuesForBackend,
        tieredRatesData: tieredRatesSection?.valuesForBackend,
        accountUpdaterData: accountUpdaterSection?.valuesForBackend,
        mobileProcessingData: mobileProcessingSection?.valuesForBackend,
        gatewayData: gatewaySection?.valuesForBackend,
        wirelessData: wirelessProcessingSection?.valuesForBackend,
        otherFeesData: otherFeesSection?.valuesForBackend,
        pinDebitFeesData: pinDebitFeesSection?.valuesForBackend,
        declineRecoveryData: declineRecoverySection?.valuesForBackend,
        rewardPayData: rewardPaySection?.valuesForBackend,
        cashRewardsData: cashRewardsSection?.valuesForBackend,
        municipalityData: municipalitySection?.valuesForBackend,
        enhancedInterchangeServiceSetupData:
          enhancedInterchangeServiceSetupSection?.valuesForBackend,
        softwareIntegrationData: softwareIntegrationSection?.valuesForBackend,
        tipConfigData: tipConfigSection?.valuesForBackend
      },
      {
        ...formatOptions,
        onlyUseFeeData,
        achInfoAndFunding: {
          ...originalBackendJson?.mpa?.achInfoAndFunding,
          ...formattedAchInfoAndFunding?.achInfoAndFunding
        }
      })
    }
    : {};

  const formattedPaymentCardData = paymentCardsBackend(
    paymentMethodsAcceptedSection?.valuesForBackend,
    formatOptions
  );
  const formatted = {
    ...originalBackendData,
    businessInformation: {
      ...businessInfoBackend(
        businessInformationSection?.valuesForBackend, {
          ...formatOptions,
          businessStreetAddressData: businessStreetAddressSection?.valuesForBackend,
          billingAddressData: billingAddressSection?.valuesForBackend
        }
      ),
      legalBusinessName: backendAppLevelData?.legalBusinessName
    },
    applicationLevelFields: backendAppLevelData,
    owners: backendAppLevelData.owners,
    paymentProcessingAndSales: paymentProcessingBackend(
      paymentProcessingAndSalesSection?.valuesForBackend, {
        ...formatOptions,
        saveApp,
        orderChannelData: orderChannelSection?.valuesForBackend
      }
    ),
    ...formattedAchInfoAndFunding,
    // end business/fees fields

    ...(useAddendum &&
      // For `digitizedAddendums` check if `valuesForBackend` exists. If so, format the data,
      // else, default `digitizedAddendums` BE data will be sent via `originalBackendJson`
      (!isEmpty(alwaysShownControls?.valuesForBackend) ||
      !isEmpty(outsideLegalReviewFormFields?.valuesForBackend) ||
      !isEmpty(neteviaReserveFormFields?.valuesForBackend)) && {
      digitizedAddendums: {
        corvia: {
          ...alwaysShownControlsBackend(alwaysShownControls?.valuesForBackend, formatOptions),
          ...outsideLegalReviewBackend(outsideLegalReviewFormFields
            ?.valuesForBackend, formatOptions)
        },
        netevia: {
          ...neteviaReserveBackend(neteviaReserveFormFields?.valuesForBackend, formatOptions)
        }
      }
    }),

    ...(isNetevia && (!isEmpty(neteviaCardNotPresentSection?.valuesForBackend) ||
      !isEmpty(neteviaBillingMethodsSection?.valuesForBackend) ||
      !isEmpty(neteviaFeesSection?.valuesForBackend)) && {
      // For `neteviaFields` check if `valuesForBackend` exists. If so, format the data,
      // else, default `neteviaFields` BE data will be sent via `originalBackendJson`
      neteviaFields: {
        // gets cardNotPresent and fees data
        ...neteviaFieldsBackend({
          cardNotPresentData: neteviaCardNotPresentSection?.valuesForBackend,
          billingData: neteviaBillingMethodsSection?.valuesForBackend,
          feesData: neteviaFeesSection?.valuesForBackend
        },
        formatOptions),
        ...(useNetevia && {
          ...(!onlyUseFeeData && { ...formattedPaymentCardData }),
          ...formattedRatesAndFees
        })
      }
    }),
    ...(usePriorityFields && { ...priorityFieldsBackend(frontendData, formatOptions) }),
    ...(useRepayFields && !isDefaultProcessor && {
      repayFields: repayFieldsBackend(frontendData, {
        ...formatOptions,
        formattedPaymentCardData,
        formattedRatesAndFees
      })
    })
  };
  return formatted;
};

const outsideLegalReviewBackend = (data, options) => {
  const { originalBackendJson = {}, appSignatureType } = options || {};
  const isUsingOutsideLegal = data?.usingOutsideLegalReviewForm || false;
  const outsideLegalReviewData = isEmpty(data)
    ? { // If no data, send original backend data
      usingOutsideLegalReviewForm:
        originalBackendJson?.digitizedAddendums?.usingOutsideLegalReviewForm,
      ...(appSignatureType === 'electronic_signature' && {
        outsideLegalReviewFormFields:
            originalBackendJson?.digitizedAddendums?.outsideLegalReviewFormFields
      })
    }
    : {
      usingOutsideLegalReviewForm: isUsingOutsideLegal,
      ...(isUsingOutsideLegal && appSignatureType === 'electronic_signature' && {
        outsideLegalReviewFormFields: {
          nameOfAttorney: data?.nameOfAttorney,
          nameOfLawFirm: data?.nameOfLawFirm,
          name: data?.name,
          title: data?.title
        }
      })
    };
  return outsideLegalReviewData;
};

export const neteviaReserveBackend = (data, options) => {
  const { originalBackendJson = {}, appSignatureType, relationship } = options || {};
  if (appSignatureType === 'electronic_signature' && relationship?.processName === 'netevia') {
    if (isEmpty(data)) { // If no data, send original backend data
      const originalNeteviaData = {
        neteviaReserveFormFields: {
          initialCheckAmount:
            originalBackendJson?.digitizedAddendums?.neteviaReserveFormFields?.initialCheckAmount,
          withholdingRatio:
            originalBackendJson?.digitizedAddendums?.neteviaReserveFormFields?.withholdingRatio,
          ownershipType:
            originalBackendJson?.digitizedAddendums?.neteviaReserveFormFields?.ownershipType
        }
      };
      return originalNeteviaData;
    }
    const reserveAccountRadioValue = data?.initialCheckOrWitholdingRadio;
    const newNeteviaData = {
      neteviaReserveFormFields: {
        withholdingRatio: null,
        initialCheckAmount: null,
        ...(!isEmpty(reserveAccountRadioValue) && {
          ...(reserveAccountRadioValue === 'initialCheckAmountRadio' && { initialCheckAmount: data?.initialCheckAmount }),
          ...(reserveAccountRadioValue === 'withholdingRatioRadio' && { withholdingRatio: data?.withholdingRatio })
        }),
        ownershipType: data?.ownershipType
      }
    };
    return newNeteviaData;
  }
  return {};
};

const alwaysShownControlsBackend = (data, options) => {
  const { originalBackendJson = {}, relationship = {} } = options || {};
  const { digitizedAddendums = {} } = originalBackendJson || {};
  const { riskProfile } = relationship;
  const {
    usingNobAddendum = false,
    usingCorviaProductCountryCompliance = false,
    usingCorviaReserveForm
  } = digitizedAddendums;
  const previousData = {
    usingNobAddendum,
    usingCorviaProductCountryCompliance,
    usingCorviaReserveForm
  };
  const alwaysShownControlsData = {
    ...previousData,
    // weird logic here is because for high risk, it is always true, for low, it is always false
    // but for standard, it is what they select, first part of ternary does standard
    // second part does both low and high
    usingCorviaReserveForm: ignoreCase(riskProfile) === 'standard' ? data?.usingCorviaReserveForm : ignoreCase(riskProfile) === 'elevated',
    usingNobAddendum: data?.usingNobAddendum,
    usingCorviaProductCountryCompliance: data?.usingCorviaProductCountryCompliance
  };
  return alwaysShownControlsData;
};

export const repayFieldsBackend = (frontendSections, options) => {
  const {
    generalSection
  } = frontendSections || {};
  const {
    onlyUseFeeData,
    formattedPaymentCardData,
    formattedRatesAndFees
  } = options || {};
  const { valuesForBackend } = generalSection || {};
  const topLevelData = onlyUseFeeData
    ? {}
    : {
      ...formattedPaymentCardData,
      ...(!isEmpty(valuesForBackend?.chargebackNotificationEmailAddress) && {
        chargebackNotificationEmailAddress: valuesForBackend?.chargebackNotificationEmailAddress
      }),
      statementDistributionMethod: valuesForBackend?.statementDistributionMethod,
      statementDestination: valuesForBackend?.statementDestination,
      ...(isBool(valuesForBackend?.suppressStatement) && {
        suppressStatement: valuesForBackend?.suppressStatement
      }),
      combinedAch: valuesForBackend?.combinedAch,
      discountType: valuesForBackend?.discountType,
      netAch: valuesForBackend?.netAch,
      terminalName: valuesForBackend?.terminalName,
      serviceLevel: valuesForBackend?.serviceLevel
    };
  const achInfoAndFundingData = onlyUseFeeData
    ? {}
    : {
      achInfoAndFunding: getRepayAchInfoAndFundingBackend(frontendSections, options)
    };
  return {
    ...topLevelData,
    ...achInfoAndFundingData,
    ...formattedRatesAndFees
  };
};

const getRepayAchInfoAndFundingBackend = (frontendData, options) => {
  const { repayAchInfoAndFundingSection, repayAchInfoConfirmSection } = frontendData || {};
  const { relationship } = options || {};
  const {
    // if using checkbox for debits only
    repayUseDebitCheckbox,
    bankName,
    nameOnAccount,

    // if using radio for debit/dda (for chargebacks only)
    selectAchAccountRadio,
    bankNameDebitOnly,
    nameOnAccountDebitOnly,
    bankNameDdaForChargebacks,
    nameOnAccountDdaForChargebacks
  } = repayAchInfoAndFundingSection?.valuesForBackend || {};
  const {
    // confirm account/routing number section
    routingNumber,
    accountNumber
  } = repayAchInfoConfirmSection?.valuesForBackend || {};
  const useAchAccountRadio = useRepayAchAccountRadio(relationship);
  if (useAchAccountRadio) {
    const useDebitOnly = selectAchAccountRadio === 'use_debit_only';
    const useDdaForChargebacksOnly = selectAchAccountRadio === 'use_dda_for_chargebacks_only';
    return {
      ...(useDebitOnly && {
        debitOnly: {
          bankName: bankNameDebitOnly,
          nameOnAccount: nameOnAccountDebitOnly,
          routingNumber,
          accountNumber
        }
      }),
      ...(useDdaForChargebacksOnly && {
        chargebackOnly: {
          bankName: bankNameDdaForChargebacks,
          nameOnAccount: nameOnAccountDdaForChargebacks,
          routingNumber,
          accountNumber
        }
      })
    };
  }
  return {
    ...(repayUseDebitCheckbox && {
      debitOnly: {
        nameOnAccount,
        routingNumber,
        accountNumber,
        bankName
      }
    })
  };
};

// Formats FE `businessInformation` data for BE
export const businessInfoBackend = (data, options) => {
  const {
    businessStreetAddressData = {},
    billingAddressData = {}
  } = options || {};
  const { merchantBusinessAddressAutocomplete } = businessStreetAddressData || {};
  const businessStreetAddressValues = { ...merchantBusinessAddressAutocomplete };
  const { merchantBillingAddressAutocomplete } = billingAddressData || {};
  const billingAddressValues = { ...merchantBillingAddressAutocomplete };
  const businessInfoData = {
    dbaName: data?.dbaName,
    descriptor: data?.descriptor,
    telephoneNumber: data?.telephoneNumber,
    faxNumber: data?.faxNumber,
    businessStreetAddress: getAddress(businessStreetAddressValues),
    merchantWebsite: data?.merchantWebsite,
    typeOfProductsOrServicesSold: data?.typeOfProductsOrServicesSold,
    taxpayerIdentificationNumber: data?.taxpayerIdentificationNumber,
    contactEmailAddress: data?.contactEmailAddress,
    billingAddress: getAddress(billingAddressData?.billingAddressSameAs === true
      ? businessStreetAddressValues || {}
      : billingAddressValues || {}),
    organizationalStructure: data?.organizationalStructure,
    preferredMccCode: data?.preferredMccCode,
    contactFullName: data?.contactFullName,
    establishedYear: data?.establishedYear,
    publicCompany: data?.publicCompany,
    publicCompanyInfo: {
      stockSymbol: data?.stockSymbol || null,
      market: data?.market || null
    }
  };
  return cleanFieldsForBackend(businessInfoData, options);
};

export const applicationLevelFieldsBackend = (data, options) => {
  const applicationLevelFieldsData = {
    legalBusinessName: data?.legalBusinessName,
    ...(ownersBackend(
      data?.corporateOwnerListSection?.valuesForBackend,
      {
        ...options,
        beneficialOwnersData: data?.corporateOwnerListSection?.beneficialOwners
      }
    ))
  };
  return cleanFieldsForBackend(applicationLevelFieldsData, options);
};

// Formats FE `paymentProcessingAndSales` data for BE
export const paymentProcessingBackend = (data, options) => {
  const {
    saveApp,
    orderChannelData = {}
  } = options || {};
  const {
    emvOrCardPresentSwiped,
    telephoneOrder,
    eCommerce
  } = orderChannelData || {};
  const allowEmptyOrderChannel = saveApp &&
    (emvOrCardPresentSwiped === 0 || isEmpty(emvOrCardPresentSwiped)) &&
    (telephoneOrder === 0 || isEmpty(telephoneOrder)) &&
    (eCommerce === 0 || isEmpty(eCommerce));
  const paymentData = {
    seasonalBusiness: data?.seasonalBusiness,
    seasonMonths: data?.seasonMonths || null,
    averageTransactionAmount: data?.averageTransactionAmount,
    highestTransactionAmount: data?.highestTransactionAmount,
    orderChannel: {
      emvOrCardPresentSwiped: allowEmptyOrderChannel ? null : emvOrCardPresentSwiped,
      telephoneOrder: allowEmptyOrderChannel ? null : telephoneOrder,
      eCommerce: allowEmptyOrderChannel ? null : eCommerce
    },
    monthlyTotalProcessingVolume: data?.monthlyTotalProcessingVolume,
    monthlyAmexProcessingVolume: data?.monthlyAmexProcessingVolume
  };
  return cleanFieldsForBackend(paymentData, options);
};

// Formats FE `owners` data for BE
const ownersBackend = (data, options) => {
  const {
    relationship,
    beneficialOwnersData = []
  } = options || {};
  const { riskProfile, processName } = relationship || {};
  const isPriority = processName === 'priority';
  const isElevatedRisk = riskProfile === 'elevated';
  const formatOwner = (ownerObject, ownerType) => {
    const isControlOwner = ownerType === 'controllingOwner';
    const formattedOwner = {
      firstName: ownerObject?.firstName,
      lastName: ownerObject?.lastName,
      dob: ownerObject?.dob,
      ssn: ownerObject?.ssn,
      ownershipPercentage: ownerObject?.ownershipPercentage,
      ...getOwnerAddressData(ownerObject, ownerType, options),
      emailAddress: ownerObject?.emailAddress || null,
      businessPhoneNumber: ownerObject?.businessPhoneNumber || null,
      mobileNumber: ownerObject?.mobileNumber || null,
      county: ownerObject?.county || null,
      type: ownerObject?.type || null,
      title: ownerObject?.title,
      ...(isPriority && {
        priorityOwnerFields: {
          controlPerson: isElevatedRisk && isControlOwner
            ? true
            : ownerObject?.controlPersonPriority,
          guarantor: isElevatedRisk && isControlOwner
            ? true
            : ownerObject?.guarantorPriority,
          pullCreditReport: isElevatedRisk
            ? true
            : ownerObject?.pullCreditReportPriority,
          usCitizen: ownerObject?.usCitizenPriority,
          country: ownerObject?.countryPriority,
          governmentIssuedId: {
            idType: ownerObject?.idTypePriority,
            stateIssued: ownerObject?.stateIssuedPriority,
            idNumber: ownerObject?.idNumberPriority
          }
        }
      })
    };
    return formattedOwner;
  };
  const beneficialOwnersFormatted = !isEmpty(beneficialOwnersData)
    ? beneficialOwnersData.map(owner => formatOwner(owner?.valuesForBackend || {}, 'beneficialOwners'))
    : [];
  const controlOwnerFormatted = formatOwner(data || {}, 'controllingOwner');
  const ownersData = {
    owners: {
      controllingOwner: controlOwnerFormatted,
      beneficialOwners: beneficialOwnersFormatted
    }
  };
  return cleanFieldsForBackend(ownersData, options);
};

// Formats FE `achInfo` data for BE
export const achInfoBackend = (frontendSections, options) => {
  const {
    achInformationAndFundingChoicesSection,
    businessAccountNumberSection,
    corporateInformationSection
  } = frontendSections || {};
  const { relationship } = options || {};
  const formattedRelationship = getFormattedRelationship(relationship);
  const { riskProfile } = formattedRelationship || {};
  const achInfoData = { ...achInformationAndFundingChoicesSection?.valuesForBackend };
  const confirmAccountData = { ...businessAccountNumberSection?.valuesForBackend };
  const corporationData = { ...corporateInformationSection?.valuesForBackend };
  const creditAndDebitFormatted = {
    nameOnAccount: achInfoData.nameOnAccountSameAsLegalName
      ? corporationData.legalBusinessName
      : achInfoData?.nameOnAccount,
    routingNumber: confirmAccountData?.routingNumber,
    accountNumber: confirmAccountData?.accountNumber,
    bankName: achInfoData?.bankName
  };
  const fundingChoiceOption = ['elevated', 'standard'].includes(riskProfile) ? 'standard' : achInfoData?.fundingChoice;
  const achData = {
    achInfoAndFunding: {
      creditAndDebit: creditAndDebitFormatted,
      fundingChoices: {
        fundingChoice: fundingChoiceOption
      }
    }
  };
  /**
   * Pass clearPii=false here, all MPAs for an app must be the same legal corp
   * and thus have the banking info
  */
  return cleanFieldsForBackend(achData, { ...options, clearPii: false });
};

// Formats FE `paymentCards` data for BE
export const paymentCardsBackend = (data, options) => {
  const isPinDebitOnly = data?.debitCardsOnly === true;
  const paymentDataLowRisk = {
    paymentCardAcceptance: {
      visa: isPinDebitOnly ? false : data?.paymentCardsAccepted?.visa || false,
      mastercard: isPinDebitOnly ? false : data?.paymentCardsAccepted?.mastercard || false,
      discover: isPinDebitOnly ? false : data?.paymentCardsAccepted?.discover || false,
      amexOptBlue: isPinDebitOnly ? false : data?.paymentCardsAccepted?.amexOptBlue || false,
      debitCardsOnly: data?.debitCardsOnly,
      electronicBenefitsTransfer: data?.electronicBenefitsTransfer,
      amexRetainedESA: isPinDebitOnly ? false : data?.amexRetainedESA,
      fnsEbtNumber: data?.fnsEbtNumber || null,
      amexNumber: !isPinDebitOnly &&
        data?.paymentCardsAccepted?.amexOptBlue && !isEmpty(data?.amexNumber)
        ? data?.amexNumber
        : null
    }
  };
  return cleanFieldsForBackend(paymentDataLowRisk, options);
};

export const neteviaFieldsBackend = (valuesForBackend, options) => {
  const { cardNotPresentData, billingData, feesData } = valuesForBackend || {};
  const neteviaBackendData = {
    cardNotPresent: {
      internetBusinessType: cardNotPresentData?.internetBusinessType,
      internetBusinessTypeOther: cardNotPresentData?.internetBusinessType?.other === true
        ? cardNotPresentData?.internetBusinessTypeOther
        : null,
      encryptionVendorsAndControls: cardNotPresentData?.encryptionVendorsAndControls,
      advertisingAndPromotionMethod: cardNotPresentData?.advertisingAndPromotionMethod,
      billingMethods: {
        monthly: !isEmpty(billingData?.monthly) ? billingData?.monthly : null,
        yearly: !isEmpty(billingData?.yearly) ? billingData?.yearly : null,
        quarterly: !isEmpty(billingData?.quarterly) ? billingData?.quarterly : null,
        oneTime: !isEmpty(billingData?.oneTime) ? billingData?.oneTime : null,
        hourly: !isEmpty(billingData?.hourly) ? billingData?.hourly : null
      },
      vendors: cardNotPresentData?.vendors,
      fulfillmentVendor: cardNotPresentData?.fulfillmentVendor,
      saleProcessDescription: cardNotPresentData?.saleProcessDescription
    },
    fees: {
      authVisaMastercardDiscover: feesData?.authVisaMastercardDiscover,
      authAmex: feesData?.authAmex,
      pciAnnual: feesData?.pciAnnual,
      reporting1099K: feesData?.reporting1099K,
      earlyTermination: feesData?.earlyTermination,
      ebtPerTxn: feesData?.ebtPerTxn,
      regulatoryAssistancePerMonth: feesData?.regulatoryAssistancePerMonth,
      webMonitoringSetup: feesData?.webMonitoringSetup
    }
  };
  return cleanFieldsForBackend(neteviaBackendData, options);
};

export const priceFieldsExpander = (title, data) => {
  const checkRateTypes = [
    'regulatedCheckCard',
    'unregulatedCheckCard',
    'qualifiedRate',
    'midQualifiedRate',
    'nonQualifiedRate',
    'premiumRate'
  ];
  const cardTypes = [
    'visaMastercardDiscover',
    'americanExpress'
  ];
  const caseChanger = {
    regulatedCheckCard: 'RegulatedCheckCard',
    unregulatedCheckCard: 'UnregulatedCheckCard',
    qualifiedRate: 'QualifiedRate',
    midQualifiedRate: 'MidQualifiedRate',
    nonQualifiedRate: 'NonQualifiedRate',
    premiumRate: 'PremiumRate',
    visaMastercardDiscover: 'VisaMastercardDiscover',
    americanExpress: 'AmericanExpress',
    volumeRatio: 'VolumeRatio',
    perTransaction: 'PerTransaction'
  };
  const pricingTypeBuilder = {
    [title]: {}
  };

  checkRateTypes.forEach((aCheckRate) => {
    pricingTypeBuilder[title][aCheckRate] = {};
    cardTypes.forEach((aCardType) => {
      const volumeKey = `${title}${caseChanger[aCheckRate]}${caseChanger[aCardType]}${caseChanger.volumeRatio}`;
      const perTransactionKey = `${title}${caseChanger[aCheckRate]}${caseChanger[aCardType]}${caseChanger.perTransaction}`;
      pricingTypeBuilder[title][aCheckRate][aCardType] = {
        volumeRatio: formatFeeValue(data?.[volumeKey]),
        perTransaction: formatFeeValue(data?.[perTransactionKey])
      };
    });
  });
  return pricingTypeBuilder;
};

// Formats FE `ratesAndFees` data for BE
const formatFeeValue = value => (!isEmpty(value) ? value : null);

export const formatPricing = (ratioData, transactionData, options) => {
  const { monthlyFeeData, isNetevia = false } = options || {};
  return {
    volumeRatio: formatFeeValue(ratioData),
    ...isNetevia ? {} : { perTransaction: formatFeeValue(transactionData) },
    ...(monthlyFeeData !== undefined && {
      monthlyFee: formatFeeValue(monthlyFeeData)
    })
  };
};

const ratesFeesBackend = (data, options) => {
  const {
    onlyUseFeeData, // If Business tab data is unavailable
    achInfoAndFunding = {}, // Business tab data
    relationship = {},
    appType
  } = options || {};
  const {
    ratesFeesData = {},
    pinDebitFeesData = {},
    tieredRatesData = {},
    accountUpdaterData = {},
    mobileProcessingData = {},
    gatewayData = {},
    wirelessData = {},
    otherFeesData = {},
    declineRecoveryData = {}
  } = data || {};
  const { processName } = relationship || {};
  const isNetevia = processName === 'netevia';
  const isRepay = processName === 'repay';
  const pricingTypeValue = ratesFeesData?.pricingType;
  const {
    cashDiscountPricingWaiveFees,
    flatPricingWaiveFees,
    visaMastercardRate_volumeRatio: visaMcVolumeRatio,
    visaMastercardRate_perTransaction: visaMastercardRatePerTransaction,
    amexRate_volumeRatio: amexRateVolumeRatio,
    amexRate_perTransaction: amexRatePerTransaction,
    discoverRate_volumeRatio: discoverRateVolumeRatio,
    discoverRate_perTransaction: discoverRatePerTransaction,
    cashDiscountPricing_volumeRatio: cashDiscountPricingVolumeRatio,
    cashDiscountPricing_perTransaction: cashDiscountPricingPerTransaction,
    flatPricing_volumeRatio: flatPricingVolumeRatio,
    flatPricing_perTransaction: flatPricingPerTransaction
  } = ratesFeesData || {};
  const {
    pinBasedDebitEnabled,
    pinDebitFees_volumeRatio: pinDebitFeesVolumeRatio,
    pinDebitFees_perTransaction: pinDebitFeesPerTransaction,
    pinDebitFees_monthlyFee: pinDebitFeesMonthlyFee
  } = pinDebitFeesData || {};
  const {
    visaMastercard_qualifiedRate_volumeRatio: visaMastercardQualifiedRateVolumeRatio,
    visaMastercard_qualifiedRate_perTransaction: visaMastercardQualifiedRatePerTransaction,
    visaMastercard_midQualifiedRate_volumeRatio: visaMastercardMidQualifiedRateVolumeRatio,
    visaMastercard_midQualifiedRate_perTransaction: visaMastercardMidQualifiedRatePerTransaction,
    visaMastercard_nonQualifiedRate_volumeRatio: visaMastercardNonQualifiedRateVolumeRatio,
    visaMastercard_nonQualifiedRate_perTransaction: visaMastercardNonQualifiedRatePerTransaction,

    amex_qualifiedRate_volumeRatio: amexQualifiedRateVolumeRatio,
    amex_qualifiedRate_perTransaction: amexQualifiedRatePerTransaction,
    amex_midQualifiedRate_volumeRatio: amexMidQualifiedRateVolumeRatio,
    amex_midQualifiedRate_perTransaction: amexMidQualifiedRatePerTransaction,
    amex_nonQualifiedRate_volumeRatio: amexNonQualifiedRateVolumeRatio,
    amex_nonQualifiedRate_perTransaction: amexNonQualifiedRatePerTransaction,

    discover_qualifiedRate_volumeRatio: discoverQualifiedRateVolumeRatio,
    discover_qualifiedRate_perTransaction: discoverQualifiedRatePerTransaction,
    discover_midQualifiedRate_volumeRatio: discoverMidQualifiedRateVolumeRatio,
    discover_midQualifiedRate_perTransaction: discoverMidQualifiedRatePerTransaction,
    discover_nonQualifiedRate_volumeRatio: discoverNonQualifiedRateVolumeRatio,
    discover_nonQualifiedRate_perTransaction: discoverNonQualifiedRatePerTransaction,

    debit_qualifiedRate_volumeRatio: debitQualifiedRateVolumeRatio,
    debit_qualifiedRate_perTransaction: debitQualifiedRatePerTransaction,
    debit_midQualifiedRate_volumeRatio: debitMidQualifiedRateVolumeRatio,
    debit_midQualifiedRate_perTransaction: debitMidQualifiedRatePerTransaction,
    debit_nonQualifiedRate_volumeRatio: debitNonQualifiedRateVolumeRatio,
    debit_nonQualifiedRate_perTransaction: debitNonQualifiedRatePerTransaction
  } = tieredRatesData || {};
  const isTieredRates = pricingTypeValue === 'tiered_rates';
  const hasAccountUpdater = accountUpdaterData?.accountUpdaterEnabled === true;
  const hasMobileProcessing = mobileProcessingData?.mobileProcessingEnabled === true;
  const hasGateway = gatewayData?.gatewayEnabled === true;
  const hasWirelessProcessing = wirelessData?.wirelessProcessingEnabled === true;
  const hasDeclineRecovery = declineRecoveryData?.declineRecoveryEnabled === true;
  const hasPinBasedDebit = isRepay
    ? (!isTieredRates && pinBasedDebitEnabled === true) || false
    : pinBasedDebitEnabled === true; // FE Only
  const accountUpdaterSection = isNetevia
    ? {}
    : {
      accountUpdaterEnabled: hasAccountUpdater,
      accountUpdater: {
        setup: hasAccountUpdater
          ? formatFeeValue(accountUpdaterData?.setup)
          : null,
        monthly: hasAccountUpdater
          ? formatFeeValue(accountUpdaterData?.monthly)
          : null,
        ...(appType === 'lowRisk' && {
          perTransaction: hasAccountUpdater
            ? formatFeeValue(accountUpdaterData?.perTransaction)
            : null
        })
      }
    };
  const mobileProcessingSection = isNetevia
    ? {}
    : {
      mobileProcessingEnabled: hasMobileProcessing,
      mobileProcessing: {
        setup: hasMobileProcessing ? formatFeeValue(mobileProcessingData?.setup) : null,
        monthly: hasMobileProcessing ? formatFeeValue(mobileProcessingData?.monthly) : null,
        perUser: hasMobileProcessing ? formatFeeValue(mobileProcessingData?.perUser) : null,
        ...(appType === 'lowRisk' && {
          perTransaction: hasMobileProcessing
            ? formatFeeValue(mobileProcessingData?.perTransaction) : null
        })
      }
    };
  const gatewaySection = {
    gatewayEnabled: hasGateway,
    gateway: {
      ...(isNetevia
        ? {}
        : {
          gatewayName: hasGateway ? gatewayData?.gatewayName : null,
          gatewayVaultTokenizationMonthly: hasGateway
            ? formatFeeValue(gatewayData?.gatewayVaultTokenizationMonthly)
            : null,
          gatewayVaultTokenizationPerTransaction: hasGateway
            ? formatFeeValue(gatewayData?.gatewayVaultTokenizationPerTransaction)
            : null,
          setup: hasGateway
            ? formatFeeValue(gatewayData?.setup)
            : null
        }),
      ...(appType === 'lowRisk' && {
        monthly: hasGateway ? formatFeeValue(gatewayData?.monthly) : null,
        perTransaction: hasGateway ? formatFeeValue(gatewayData?.perTransaction) : null
      })
    }
  };
  const wirelessProcessingSection = {
    wirelessProcessingEnabled: hasWirelessProcessing,
    wirelessProcessing: {
      setup: hasWirelessProcessing ? formatFeeValue(wirelessData?.setup) : null,
      monthly: hasWirelessProcessing ? formatFeeValue(wirelessData?.monthly) : null,
      ...(appType === 'lowRisk' && {
        perTransaction: hasWirelessProcessing ? formatFeeValue(wirelessData?.perTransaction) : null
      })
    }
  };
  const cashDiscountPricingSection = isNetevia
    ? {}
    : {
      cashDiscountPricing: pricingTypeValue === 'cash_discount_pricing'
        ? {
          ...formatPricing(
            cashDiscountPricingVolumeRatio,
            cashDiscountPricingPerTransaction
          ),
          ...(isRepay && {
            waiveCardBrandFees: isBool(cashDiscountPricingWaiveFees?.waiveCardBrandFees)
              ? cashDiscountPricingWaiveFees?.waiveCardBrandFees
              : null,
            waiveDuesAndAssessments: isBool(cashDiscountPricingWaiveFees?.waiveDuesAndAssessments)
              ? cashDiscountPricingWaiveFees?.waiveDuesAndAssessments
              : null
          })
        }
        : null
    };
  const flatPricingSection = {
    flatPricing: pricingTypeValue === 'flat_pricing'
      ? {
        ...formatPricing(
          flatPricingVolumeRatio,
          flatPricingPerTransaction
        ),
        ...(isRepay && {
          waiveCardBrandFees: isBool(flatPricingWaiveFees?.waiveCardBrandFees)
            ? flatPricingWaiveFees?.waiveCardBrandFees
            : null,
          waiveDuesAndAssessments: isBool(flatPricingWaiveFees?.waiveDuesAndAssessments)
            ? flatPricingWaiveFees?.waiveDuesAndAssessments
            : null
        })
      }
      : null
  };
  const declineRecoverySection = isNetevia
    ? {}
    : {
      declineRecoveryEnabled: hasDeclineRecovery,
      declineRecovery: {
        recoveryFee: hasDeclineRecovery ? formatFeeValue(declineRecoveryData?.recoveryFee) : null,
        transactionFee: hasDeclineRecovery
          ? formatFeeValue(declineRecoveryData?.transactionFee) : null,
        monthlyFee: hasDeclineRecovery ? formatFeeValue(declineRecoveryData?.monthlyFee) : null,
        other: hasDeclineRecovery ? formatFeeValue(declineRecoveryData?.other) : null
      }
    };
  const hasInterchangePlus = pricingTypeValue === 'interchange_plus';
  const hasBundled = isNetevia ? false : pricingTypeValue === 'bundled';
  const hasInterchangeBundled = hasInterchangePlus || hasBundled;
  const fundingChoice = onlyUseFeeData // Business tab fundingChoice data not available
    ? ''
    : achInfoAndFunding?.fundingChoices?.fundingChoice;
  const nextDayFundingBatchFee = otherFeesData?.nextDayFundingBatchFee;
  const ratesAndFeesData = {
    ...(appType === 'lowRisk' && {
      // lowRisk pricing
      pricing: {
        pricingType: pricingTypeValue,
        visaMastercardRate: hasInterchangeBundled
          ? formatPricing(visaMcVolumeRatio, visaMastercardRatePerTransaction, { isNetevia })
          : null,
        amexRate: hasInterchangeBundled
          ? formatPricing(amexRateVolumeRatio, amexRatePerTransaction, { isNetevia })
          : null,
        discoverRate: hasInterchangeBundled && !isNetevia
          ? formatPricing(discoverRateVolumeRatio, discoverRatePerTransaction)
          : null,
        pinDebitFees: hasPinBasedDebit
          ? formatPricing(pinDebitFeesVolumeRatio, pinDebitFeesPerTransaction, {
            monthlyFeeData: pinDebitFeesMonthlyFee
          })
          : null,
        tieredRates: isTieredRates
          ? {
            visaMastercard: {
              qualifiedRate: formatPricing(
                visaMastercardQualifiedRateVolumeRatio,
                visaMastercardQualifiedRatePerTransaction
              ),
              midQualifiedRate: formatPricing(
                visaMastercardMidQualifiedRateVolumeRatio,
                visaMastercardMidQualifiedRatePerTransaction
              ),
              nonQualifiedRate: formatPricing(
                visaMastercardNonQualifiedRateVolumeRatio,
                visaMastercardNonQualifiedRatePerTransaction
              )
            },
            amex: {
              qualifiedRate: formatPricing(
                amexQualifiedRateVolumeRatio,
                amexQualifiedRatePerTransaction
              ),
              midQualifiedRate: formatPricing(
                amexMidQualifiedRateVolumeRatio,
                amexMidQualifiedRatePerTransaction
              ),
              nonQualifiedRate: formatPricing(
                amexNonQualifiedRateVolumeRatio,
                amexNonQualifiedRatePerTransaction
              )
            },
            discover: {
              qualifiedRate: formatPricing(
                isNetevia
                  ? visaMastercardQualifiedRateVolumeRatio
                  : discoverQualifiedRateVolumeRatio,
                isNetevia
                  ? visaMastercardQualifiedRatePerTransaction
                  : discoverQualifiedRatePerTransaction
              ),
              midQualifiedRate: formatPricing(
                isNetevia
                  ? visaMastercardMidQualifiedRateVolumeRatio
                  : discoverMidQualifiedRateVolumeRatio,
                isNetevia
                  ? visaMastercardMidQualifiedRatePerTransaction
                  : discoverMidQualifiedRatePerTransaction
              ),
              nonQualifiedRate: formatPricing(
                isNetevia
                  ? visaMastercardNonQualifiedRateVolumeRatio
                  : discoverNonQualifiedRateVolumeRatio,
                isNetevia
                  ? visaMastercardNonQualifiedRatePerTransaction
                  : discoverNonQualifiedRatePerTransaction
              )
            },
            debit: {
              qualifiedRate: formatPricing(
                debitQualifiedRateVolumeRatio,
                debitQualifiedRatePerTransaction
              ),
              midQualifiedRate: formatPricing(
                debitMidQualifiedRateVolumeRatio,
                debitMidQualifiedRatePerTransaction
              ),
              nonQualifiedRate: formatPricing(
                debitNonQualifiedRateVolumeRatio,
                debitNonQualifiedRatePerTransaction
              )
            }
          }
          : null,
        ...cashDiscountPricingSection,
        ...flatPricingSection
      },

      ...declineRecoverySection,
      ...accountUpdaterSection,
      ...mobileProcessingSection,
      ...gatewaySection,
      ...wirelessProcessingSection,

      // Other fees
      ...(isNetevia
        ? {}
        : {
          bankSponsorFee: formatFeeValue(otherFeesData?.bankSponsorFee),
          verifiAlert: formatFeeValue(otherFeesData?.verifiAlert),
          onlinePortalAccessMonthly: formatFeeValue(otherFeesData?.onlinePortalAccessMonthly),
          amexSponsorFee: formatFeeValue(otherFeesData?.amexSponsorFee),
          rapidDisputeResolution: formatFeeValue(otherFeesData?.rapidDisputeResolution),
          declineTransaction: formatFeeValue(otherFeesData?.declineTransaction),
          terminalFee: formatFeeValue(otherFeesData?.terminalFee),
          governmentCompliance: formatFeeValue(otherFeesData?.governmentCompliance),
          setupFee: formatFeeValue(otherFeesData?.setupFee),
          verifiedByVisa: formatFeeValue(otherFeesData?.verifiedByVisa),
          reversal: formatFeeValue(otherFeesData?.reversal),
          refundTransaction: formatFeeValue(otherFeesData?.refundTransaction),
          posFee: formatFeeValue(otherFeesData?.posFee),
          ethocaAlert: formatFeeValue(otherFeesData?.ethocaAlert),
          preArbitration: formatFeeValue(otherFeesData?.preArbitration),
          monthlyFee: formatFeeValue(otherFeesData?.monthlyFee),
          mastercardSecureCode: formatFeeValue(otherFeesData?.mastercardSecureCode),
          arbitration: formatFeeValue(otherFeesData?.arbitration),
          cardBrandRegistration: formatFeeValue(otherFeesData?.cardBrandRegistration),
          monthlyRegistrationFee: formatFeeValue(otherFeesData?.monthlyRegistrationFee),
          riskAnalysis: formatFeeValue(otherFeesData?.riskAnalysis),
          webMonitoring: formatFeeValue(otherFeesData?.webMonitoring)
        }),
      monthlyFee: formatFeeValue(otherFeesData?.monthlyFee),
      statementFee: formatFeeValue(otherFeesData?.statementFee),
      retrievals: formatFeeValue(otherFeesData?.retrievals),
      batchFee: formatFeeValue(otherFeesData?.batchFee),
      minimumMonthlyFee: formatFeeValue(otherFeesData?.minimumMonthlyFee),
      chargebacks: formatFeeValue(otherFeesData?.chargebacks),
      pciNonComplianceFeeMonthly: formatFeeValue(otherFeesData?.pciNonComplianceFeeMonthly),
      annualFee: formatFeeValue(otherFeesData?.annualFee),
      avsTransaction: formatFeeValue(otherFeesData?.avsTransaction),
      perAchReject: formatFeeValue(otherFeesData?.perAchReject),
      pciComplianceMonthly: formatFeeValue(otherFeesData?.pciComplianceMonthly),
      voiceAuthorizationTransaction: formatFeeValue(otherFeesData?.voiceAuthorizationTransaction),
      tinMismatchMonthly: formatFeeValue(otherFeesData?.tinMismatchMonthly),
      nextDayFundingBatchFee: onlyUseFeeData || fundingChoice === 'next_day_funding'
        ? formatFeeValue(nextDayFundingBatchFee)
        : null
    })
  };
  return cleanFieldsForBackend(ratesAndFeesData, options);
};

const formatMpas = (backendData = [], options) => {
  const {
    signatureType,
    useFileList = false, // if files are provided as part of the MPA api response
    disableUpdateFile = false,
    userType,
    isLoadingTemplate = false, // portal - load mpa template
    templateRelationship = {}, // portal - load mpa template
    relationship = {},
    requiresNewSignature
  } = options || {};
  const formattedRelationship = getFormattedRelationship(relationship || {});
  const { riskProfile = '', processName = '' } = formattedRelationship;
  const formatOptions = { ...options, relationship: formattedRelationship };

  // Processor checks
  const isNetevia = ignoreCase(processName) === 'netevia';
  const isRepay = ignoreCase(processName) === 'repay';

  // Processor/bank combos
  const isPriorityTsys = isPriorityTsysBank(formattedRelationship);
  const isPriorityFirstData = isPriorityFirstDataBank(formattedRelationship);

  const formattedTemplateRelationship = isLoadingTemplate
    ? getFormattedRelationship(templateRelationship || {})
    : {};
  const {
    processName: templateProcessName
  } = isLoadingTemplate ? formattedTemplateRelationship || {} : {};

  const isPriorityTsysTemplate = isPriorityTsysBank(formattedTemplateRelationship);
  const isPriorityFirstDataTemplate = isPriorityFirstDataBank(formattedTemplateRelationship);

  const isLoadingPriorityTemplate = (isPriorityTsysTemplate ||
    isPriorityFirstDataTemplate);
  const isLoadingNeteviaTemplate = templateProcessName === 'netevia';
  const isLoadingRepayTemplate = templateProcessName === 'repay';

  const isDefaultProcessor = (!isLoadingTemplate && useDefaultProcessor(relationship)) ||
  (isLoadingTemplate && useDefaultProcessor(formattedTemplateRelationship));

  const usePriorityTab = !isDefaultProcessor && (
    isPriorityTsys || isPriorityFirstData
  );
  const useNeteviaTab = !isDefaultProcessor && isNetevia;
  const useAddendum = (!isDefaultProcessor && (useNeteviaTab || ['standard', 'elevated'].includes(ignoreCase(riskProfile))));

  const formattedMpas = !isEmpty(backendData) ? backendData.map((mpa, index) => {
    const {
      // General MPA details
      applicationMpaId = '',
      fileList,

      // Business
      businessInformation = {},
      paymentProcessingAndSales = {},
      achInfoAndFunding = {},
      paymentCardAcceptance = {},

      // Addendums
      digitizedAddendums = {},

      // Netevia only
      neteviaFields = {},

      // Priority only
      priorityFields = {},

      // Repay only
      repayFields = {},

      // all MPAs will have these
      applicationLevelFields = {}
    } = mpa || {};
    const { owners } = applicationLevelFields;
    const businessData = {
      businessInformation,
      paymentProcessingAndSales,
      ownerInfo: {
        control: owners?.controllingOwner || {}, // controllingOwner
        beneficial: owners?.beneficialOwners || []
      },
      achInfo: achInfoAndFunding,
      paymentCardInfo: paymentCardAcceptance
    };
    const formattedFiles = useFileList
      ? transformData({
        data: {
          filesList: fileList,
          userType,
          disableDelete: disableUpdateFile || false,
          ...(userType === 'employee' && {
            disableEdit: disableUpdateFile || false,
            restrictedTagList: allCrabRestrictedTags()
          })
        },
        toSchema: 'frontend',
        template: filesWithTagsTemplate
      })
      : undefined;
    const addendumsData = {
      digitizedAddendums
    };
    const neteviaData = isLoadingTemplate
      ? { // If loading mpa template, include neteviaFields if template relationship is netevia
        ...(isLoadingNeteviaTemplate ? { neteviaFields } : {})
      }
      : { // Else loading existing mpa & app relationship is netevia, include neteviaFields
        ...(useNeteviaTab && { neteviaFields })
      };
    const priorityData = isLoadingTemplate
      ? { // If loading mpa template, include if template relationship is priority & bank eligible
        ...(isLoadingPriorityTemplate ? { priorityFields } : {})
      }
      : { // Else loading existing mpa & app relationship is priority & bank eligible
        ...(usePriorityTab && { priorityFields })
      };
    const repayData = isLoadingTemplate
      ? { // If loading mpa template, include repayFields if template relationship is repay
        ...(isLoadingRepayTemplate ? { repayFields } : {})
      }
      : { // Else loading existing mpa & app relationship is repay, include repayFields
        ...(isRepay && { repayFields })
      };
    const repayTabData = isRepay
      ? buildRepayTabData(repayData, formatOptions)
      : {};
    const neteviaTabData = useNeteviaTab ? buildNeteviaTabData(neteviaData, formatOptions) : {};
    const priorityTabData = usePriorityTab
      ? buildPriorityTabData(priorityData, { ...formatOptions, businessData })
      : {};
    const corporationTab = buildCorporationTabData(
      { applicationLevelFields, owners, businessInformation },
      formatOptions
    );
    const formattedMpa = {
      mpaId: index,
      ...(useFileList && { mpaId: applicationMpaId }),
      mpaName: businessInformation?.dbaName || '', // used to display in ApplicationDetails
      signatureType,
      requiresNewSignature,
      applicationMpaId,
      corporationTab,
      businessTab: buildBusinessTabData({ ...businessData, ...applicationLevelFields }, {
        ...formatOptions,
        ...(!isEmpty(formatOptions?.relationship) && {
          relationshipId: formatOptions.relationship.relationshipId
        })
      }),
      uploadsTab: buildUploadsTabData({ signatureType }, formatOptions),
      ...(useNeteviaTab && { neteviaTab: neteviaTabData }),
      ...(usePriorityTab && { priorityTab: priorityTabData }),
      ...(isRepay && { repayTab: repayTabData }),
      ...(useAddendum &&
        { addendumsTab: buildAddendumsTabData(addendumsData, { ...formatOptions, mpa }) }),
      formattedFiles
    };
    return formattedMpa;
  }) : [];
  return formattedMpas;
};

export const buildCorporationTabData = (backendData = {}, options) => {
  const { applicationLevelFields } = backendData || {};
  const { legalBusinessName, owners } = applicationLevelFields || {};
  const tabData = {
    corporateInformationSection: {
      legalBusinessName
    },
    corporateOwnerListSection: { // CUSTOM SECTION
      ...getOwnerData(
        owners?.controllingOwner || {},
        { ...options, ownerType: 'controllingOwner', useAddressAutocomplete: true }
      ),
      beneficialOwners: !isEmpty(owners?.beneficialOwners)
        ? owners.beneficialOwners.map(
          beneficialOwner => getOwnerData(
            beneficialOwner || {},
            {
              ...options,
              ownerType: 'beneficialOwners',
              useAddressAutocomplete: true
            }
          )
        )
        : []
    }
  };
  return tabData;
};

const getAddress = addressData => ({ // formats BE address data to FE
  addressLine1: addressData.addressLine1,
  addressLine2: addressData.addressLine2,
  city: addressData.city,
  state: addressData.state,
  postalCode: addressData.postalCode,
  country: !isEmpty(addressData.country) ? addressData.country.toUpperCase() : addressData.country
});

export const buildAddendumsTabData = (backendData = {}, options) => {
  const { digitizedAddendums = {} } = backendData;
  const { corvia = {}, netevia = {} } = digitizedAddendums || {};
  const { relationship } = options || {};
  const tabData = {
    alwaysShownControls: {
      usingNobAddendum: corvia?.usingNobAddendum === true,
      usingCorviaProductCountryCompliance:
      corvia?.usingCorviaProductCountryCompliance === true,
      usingCorviaReserveForm: corvia?.usingCorviaReserveForm === true
    },
    outsideLegalReviewFormFields: {
      usingOutsideLegalReviewForm: corvia?.usingOutsideLegalReviewForm === true,
      nameOfAttorney: corvia?.outsideLegalReviewFormFields?.nameOfAttorney,
      nameOfLawFirm: corvia?.outsideLegalReviewFormFields?.nameOfLawFirm,
      name: corvia?.outsideLegalReviewFormFields?.name,
      title: corvia?.outsideLegalReviewFormFields?.title
    },
    neteviaReserveFormFields: {
      usingNeteviaReserveForm: relationship?.processName === 'netevia',
      initialCheckOrWitholdingRadio: undefined,
      ...(!isEmpty(netevia?.neteviaReserveFormFields?.initialCheckAmount) && {
        initialCheckOrWitholdingRadio: 'initialCheckAmountRadio'
      }),
      ...(!isEmpty(netevia?.neteviaReserveFormFields?.withholdingRatio) && {
        initialCheckOrWitholdingRadio: 'withholdingRatioRadio'
      }),
      initialCheckAmount: netevia?.neteviaReserveFormFields?.initialCheckAmount,
      withholdingRatio: netevia?.neteviaReserveFormFields?.withholdingRatio,
      ownershipType: netevia?.neteviaReserveFormFields?.ownershipType
    }
  };
  return tabData;
};

export const buildUploadsTabData = (backendData = {}, options) => {
  const {
    tabFormFields
  } = options || {};
  const { uploads: uploadsTabFormFields = {} } = tabFormFields || {};
  const hasSignatureField = !isEmpty(uploadsTabFormFields?.signatureSection?.signatureType?.id);
  const tabData = {
    formSections: {
      ...(hasSignatureField && {
        signatureSection: {
          signatureType: backendData?.signatureType || options?.signatureType
        }
      })
    },
    currentFiles: backendData?.currentFiles ? backendData?.currentFiles : []
  };
  return tabData;
};

export const formatWaiveFees = (backendData, pricingType = 'flatPricing') => { // BE to FE
  const { waiveCardBrandFees, waiveDuesAndAssessments } = backendData || {};
  const map = {
    cash_discount_pricing: 'cashDiscountPricing',
    cashDiscountPricing: 'cashDiscountPricing',
    flat_pricing: 'flatPricing',
    flatPricing: 'flatPricing'
  };
  const pricingTypeKey = map[pricingType];
  if (!isBool(waiveCardBrandFees) && !isBool(waiveDuesAndAssessments)) {
    return { [`${pricingTypeKey}WaiveFees`]: null };
  }
  if (waiveCardBrandFees === true && waiveDuesAndAssessments === true) {
    return { [`${pricingTypeKey}WaiveFees`]: 'waiveBoth' };
  }
  if (waiveCardBrandFees === false && waiveDuesAndAssessments === false) {
    return { [`${pricingTypeKey}WaiveFees`]: 'waiveNeither' };
  }
  return {
    [`${pricingTypeKey}WaiveFees`]: waiveCardBrandFees === true ? 'waiveCardBrandFees' : 'waiveDuesAndAssessments'
  };
};

export const buildFeesTabData = (backendData = {}, options) => {
  const {
    relationship = {},
    tabFormFields,
    feesKey, // processor-specific key for the fees form fields object
    appType
  } = options || {};
  const feesFieldsKey = feesKey || 'fees';
  const { [feesFieldsKey]: feesTabFormFields = {} } = tabFormFields || {};
  const {
    ratesAndFees
  } = backendData || {};
  const { processName } = getFormattedRelationship(relationship || {});
  const isNetevia = processName === 'netevia';
  const isRepay = processName === 'repay';
  const getPricingRates = (pricingData, keys) => {
    const flattenedPricingRates = keys.reduce((acc, key) => {
      const dataItem = pricingData?.[key] || {};
      const useWaiveFees = isRepay && ['flatPricing', 'cashDiscountPricing'].includes(key);
      return {
        ...acc,
        [`${key}_volumeRatio`]: dataItem.volumeRatio,
        [`${key}_perTransaction`]: dataItem.perTransaction,
        ...(key === 'pinDebitFees' && {
          [`${key}_monthlyFee`]: dataItem.monthlyFee
        }),
        ...(useWaiveFees && { ...formatWaiveFees(dataItem, key) })
      };
    }, {});
    return flattenedPricingRates;
  };
  const getTieredRates = (tieredData, parentKeys) => {
    const subKeys = [ // should match BE key names
      'qualifiedRate',
      'midQualifiedRate',
      'nonQualifiedRate'
    ];
    const flatttenedTieredRates = parentKeys.reduce((parentAcc, parentKey) => {
      const flattenedObject = subKeys.reduce((subAcc, subKey) => {
        const dataItem = isNetevia && parentKey === 'discover'
          // if Netevia & discover, use visaMastercard values
          ? tieredData?.visaMastercard?.[subKey] || {}
          : tieredData?.[parentKey]?.[subKey] || {};
        return {
          ...subAcc,
          [`${parentKey}_${subKey}_volumeRatio`]: dataItem.volumeRatio,
          [`${parentKey}_${subKey}_perTransaction`]: dataItem.perTransaction
        };
      }, {});
      return { ...parentAcc, ...flattenedObject };
    }, {});
    return flatttenedTieredRates;
  };
  const otherFeesTabData = {
    otherFeesSection: {
      annualFee: ratesAndFees?.annualFee,
      ...(appType === 'lowRisk' && {
        batchFee: ratesAndFees?.batchFee,
        chargebacks: ratesAndFees?.chargebacks,
        retrievals: ratesAndFees?.retrievals,
        perAchReject: ratesAndFees?.perAchReject,
        voiceAuthorizationTransaction: ratesAndFees?.voiceAuthorizationTransaction,
        tinMismatchMonthly: ratesAndFees?.tinMismatchMonthly,
        avsTransaction: ratesAndFees?.avsTransaction,
        minimumMonthlyFee: ratesAndFees?.minimumMonthlyFee,
        pciComplianceMonthly: ratesAndFees?.pciComplianceMonthly,
        pciNonComplianceFeeMonthly: ratesAndFees?.pciNonComplianceFeeMonthly,
        statementFee: ratesAndFees?.statementFee,
        webMonitoring: ratesAndFees?.webMonitoring,
        monthlyFee: ratesAndFees?.monthlyFee,
        nextDayFundingBatchFee: ratesAndFees?.nextDayFundingBatchFee,
        ...(isNetevia ? {} : {
          bankSponsorFee: ratesAndFees?.bankSponsorFee,
          verifiAlert: ratesAndFees?.verifiAlert,
          onlinePortalAccessMonthly: ratesAndFees?.onlinePortalAccessMonthly,
          amexSponsorFee: ratesAndFees?.amexSponsorFee,
          rapidDisputeResolution: ratesAndFees?.rapidDisputeResolution,
          declineTransaction: ratesAndFees?.declineTransaction,
          terminalFee: ratesAndFees?.terminalFee,
          governmentCompliance: ratesAndFees?.governmentCompliance,
          setupFee: ratesAndFees?.setupFee,
          verifiedByVisa: ratesAndFees?.verifiedByVisa,
          reversal: ratesAndFees?.reversal,
          refundTransaction: ratesAndFees?.refundTransaction,
          posFee: ratesAndFees?.posFee,
          ethocaAlert: ratesAndFees?.ethocaAlert,
          preArbitration: ratesAndFees?.preArbitration,
          mastercardSecureCode: ratesAndFees?.mastercardSecureCode,
          arbitration: ratesAndFees?.arbitration,
          cardBrandRegistration: ratesAndFees?.cardBrandRegistration,
          monthlyRegistrationFee: ratesAndFees?.monthlyRegistrationFee,
          riskAnalysis: ratesAndFees?.riskAnalysis
        })
      })
    }
  };
  const accountUpdaterTabData = isNetevia ? {} : {
    accountUpdaterSection: {
      accountUpdaterEnabled: ratesAndFees?.accountUpdaterEnabled,
      setup: ratesAndFees?.accountUpdater?.setup,
      monthly: ratesAndFees?.accountUpdater?.monthly,
      ...(appType === 'lowRisk' && {
        perTransaction: ratesAndFees?.accountUpdater?.perTransaction
      })
    }
  };
  const mobileProcessingTabData = isNetevia ? {} : {
    mobileProcessingSection: {
      mobileProcessingEnabled: ratesAndFees?.mobileProcessingEnabled,
      setup: ratesAndFees?.mobileProcessing?.setup,
      monthly: ratesAndFees?.mobileProcessing?.monthly,
      ...(appType === 'lowRisk' && {
        perTransaction: ratesAndFees?.mobileProcessing?.perTransaction
      }),
      perUser: ratesAndFees?.mobileProcessing?.perUser
    }
  };
  const gatewayTabData = {
    gatewaySection: {
      gatewayEnabled: ratesAndFees?.gatewayEnabled,
      monthly: ratesAndFees?.gateway?.monthly,
      perTransaction: ratesAndFees?.gateway?.perTransaction,
      ...(isNetevia
        ? {}
        : {
          setup: ratesAndFees?.gateway?.setup,
          gatewayName: ratesAndFees?.gateway?.gatewayName,
          gatewayVaultTokenizationMonthly: ratesAndFees?.gateway?.gatewayVaultTokenizationMonthly,
          gatewayVaultTokenizationPerTransaction:
            ratesAndFees?.gateway?.gatewayVaultTokenizationPerTransaction
        })
    }
  };
  const wirelessProcessingTabData = {
    wirelessProcessingSection: {
      wirelessProcessingEnabled: ratesAndFees?.wirelessProcessingEnabled,
      setup: ratesAndFees?.wirelessProcessing?.setup,
      monthly: ratesAndFees?.wirelessProcessing?.monthly,
      ...(appType === 'lowRisk' && {
        perTransaction: ratesAndFees?.wirelessProcessing?.perTransaction
      })
    }
  };
  const declineRecoveryTabData = isNetevia ? {} : {
    declineRecoverySection: {
      ...(appType === 'lowRisk' && {
        declineRecoveryEnabled: ratesAndFees?.declineRecoveryEnabled,
        recoveryFee: ratesAndFees?.declineRecovery?.recoveryFee,
        transactionFee: ratesAndFees?.declineRecovery?.transactionFee,
        monthlyFee: ratesAndFees?.declineRecovery?.monthlyFee,
        other: ratesAndFees?.declineRecovery?.other
      })
    }
  };
  const selectedPricingType = ignoreCase(ratesAndFees?.pricing?.pricingType || '');
  const ratesAndFeesTabData = {
    ratesAndFeesSection: {
      ...(isNetevia
        ? { pricingType: ['bundled', 'cash_discount_pricing', 'flat_pricing'].includes(selectedPricingType) ? undefined : selectedPricingType }
        : { pricingType: selectedPricingType }),
      ...(appType === 'lowRisk' && { // eslint-disable-next-line max-len
        // creates nested objects under `ratesAndFees.pricing`, `ratesAndFees.cashDiscountPricing`, `ratesAndFees.flatPricing`
        ...getPricingRates(
          { ...ratesAndFees?.pricing },
          [
            'visaMastercardRate',
            'amexRate',
            ...(isNetevia ? [] : ['discoverRate']),
            ...(isNetevia ? [] : ['cashDiscountPricing', 'flatPricing'])
          ]
        )
      })
    }
  };
  const pinDebitFeesData = {
    pinDebitFeesSection: {
      pinBasedDebitEnabled: !isEmpty(ratesAndFees?.pricing?.pinDebitFees?.volumeRatio) ||
        !isEmpty(ratesAndFees?.pricing?.pinDebitFees?.perTransaction) ||
        !isEmpty(ratesAndFees?.pricing?.pinDebitFees?.monthlyFee),
      ...getPricingRates({ ...ratesAndFees?.pricing }, ['pinDebitFees'])
    }
  };
  const tieredRatesTabData = {
    ...(feesTabFormFields?.tieredRatesSection && {
      tieredRatesSection: { // creates nested objects under `ratesAndFees.pricing.tieredRates`
        ...getTieredRates(ratesAndFees?.pricing?.tieredRates, [
          'visaMastercard',
          'amex',
          'discover',
          'debit'
        ])
      }
    })
  };
  const tabData = {
    ...otherFeesTabData,
    ...accountUpdaterTabData,
    ...mobileProcessingTabData,
    ...gatewayTabData,
    ...wirelessProcessingTabData,
    ...declineRecoveryTabData,
    ...ratesAndFeesTabData,
    ...pinDebitFeesData,
    ...tieredRatesTabData
  };
  return tabData;
};

const getOwnerAddressData = (currentDataObj, ownerType, options) => {
  const { toFrontend, useAddressAutocomplete } = options || {};
  const dataKeyMap = {
    controllingOwner: 'controllingOwnerAddressAutocomplete',
    beneficialOwners: 'beneficialOwnersAddressAutocomplete'
  };
  const dataKey = useAddressAutocomplete ? dataKeyMap[ownerType] : null;
  const addressObj = (!isEmpty(dataKey) && currentDataObj[dataKey]) || currentDataObj || {};
  const addressData = {
    streetAddress: addressObj?.streetAddress || null,
    city: addressObj?.city || null,
    state: addressObj?.state || null,
    zip: addressObj?.zip || null
  };
  return {
    ...addressData,
    ...(toFrontend && !isEmpty(dataKey) && {
      [dataKey]: { ...addressData, valuesForBackend: addressData }
    })
  };
};

export const getOwnerData = (ownerData, options) => {
  const { relationship, ownerType } = options || {};
  const { processName } = relationship || {};
  const isPriority = processName === 'priority';
  const ownerAddressData = getOwnerAddressData(ownerData, ownerType, {
    ...options,
    toFrontend: true
  });
  return {
    firstName: ownerData?.firstName,
    lastName: ownerData?.lastName,
    dob: ownerData?.dob,
    ssn: ownerData?.ssn,
    ownershipPercentage: ownerData?.ownershipPercentage,
    ...ownerAddressData,
    ...(ownerData?.businessPhoneNumber && { phoneNumber: 'businessPhoneNumberOnly' }),
    ...(ownerData?.mobileNumber && { phoneNumber: 'mobileNumberOnly' }),
    ...(ownerData?.businessPhoneNumber && ownerData?.mobileNumber && { phoneNumber: 'businessAndMobileNumber' }),
    ...(isEmpty(ownerData?.businessPhoneNumber) &&
      isEmpty(ownerData?.mobileNumber) && { phoneNumber: undefined }),
    emailAddress: ownerData?.emailAddress,
    businessPhoneNumber: ownerData?.businessPhoneNumber,
    mobileNumber: ownerData?.mobileNumber,
    county: ownerData?.county,
    type: ownerData?.type,
    fullName: ownerData?.fullName,
    title: ownerData?.title,
    ...(isPriority && {
      controlPersonPriority: ownerData?.priorityOwnerFields?.controlPerson,
      guarantorPriority: ownerData?.priorityOwnerFields?.guarantor,
      pullCreditReportPriority: ownerData?.priorityOwnerFields?.pullCreditReport,
      usCitizenPriority: ownerData?.priorityOwnerFields?.usCitizen,
      countryPriority: ownerData?.priorityOwnerFields?.country,
      idTypePriority: ownerData?.priorityOwnerFields?.governmentIssuedId?.idType,
      stateIssuedPriority: ownerData?.priorityOwnerFields?.governmentIssuedId?.stateIssued,
      idNumberPriority: ownerData?.priorityOwnerFields?.governmentIssuedId?.idNumber
    })
  };
};

export const buildBusinessTabData = (backendData = {}, options) => {
  const {
    tabFormFields,
    relationshipId,
    relationshipList = [],
    relationship
  } = options || {};
  const { business: businessTabFormFields = {} } = tabFormFields || {};
  const {
    // These Sections need to be named the same between HR & LR
    businessInformationSection,
    paymentProcessingAndSalesSection
  } = businessTabFormFields;
  const {
    businessInformation,
    paymentProcessingAndSales,
    achInfo,
    legalBusinessName
  } = backendData || {};
  const formattedRelationship = !isEmpty(relationship)
    ? getFormattedRelationship(relationship)
    : getFormattedRelationship(relationshipList.find(r => r.value === relationshipId));
  const { riskProfile } = formattedRelationship || {};
  const businessStreetAddressData = getAddress(businessInformation?.businessStreetAddress || {});
  const billingAddressData = getAddress(businessInformation?.billingAddress || {});
  const billingSameAsBusiness = isEmpty(billingAddressData.addressLine1) ||
    (!isEmpty(businessInformation?.businessStreetAddress) &&
      isEqual(businessStreetAddressData, billingAddressData));
  const nameOnAccountSameAsLegal = !isEmpty(legalBusinessName) &&
      !isEmpty(achInfo?.creditAndDebit?.nameOnAccount)
    ? legalBusinessName === achInfo?.creditAndDebit?.nameOnAccount
    : undefined;
  const tabData = {
    businessInformationSection: {
      dbaName: businessInformation?.dbaName,
      descriptor: businessInformation?.descriptor,
      telephoneNumber: businessInformation?.telephoneNumber,
      faxNumber: businessInformation?.faxNumber,
      merchantWebsite: businessInformation?.merchantWebsite,
      typeOfProductsOrServicesSold: businessInformation?.typeOfProductsOrServicesSold,
      ...(businessInformationSection?.yearsOfOperations && { // HR Only
        yearsOfOperations: businessInformation?.yearsOfOperations
      }),
      legalSameAsDba: isEmpty(businessInformation?.legalBusinessName) || (
        !isEmpty(businessInformation?.legalBusinessName) &&
        isEqual(businessInformation?.legalBusinessName, businessInformation?.dbaName)
      ),
      legalBusinessName: businessInformation?.legalBusinessName,
      taxpayerIdentificationNumber: businessInformation?.taxpayerIdentificationNumber,
      ...(businessInformationSection?.establishedYear && { // LR Only
        establishedYear: businessInformation?.establishedYear
      }),
      ...(businessInformationSection?.contactFullName && { // LR Only
        contactFullName: businessInformation?.contactFullName
      }),
      contactEmailAddress: businessInformation?.contactEmailAddress,
      organizationalStructure: businessInformation?.organizationalStructure,
      ...(businessInformationSection?.businessType && { // HR Only
        businessType: businessInformation?.businessType
      }),
      ...(businessInformationSection?.businessSubType && { // HR Only
        businessSubType: businessInformation?.businessSubType
      }),
      ...(businessInformationSection?.businessSubSubType && { // HR Only
        businessSubSubType: businessInformation?.businessSubSubType
      }),
      ...(businessInformationSection?.publicCompany && { // LR Only
        publicCompany: businessInformation?.publicCompany,
        stockSymbol: businessInformation?.publicCompanyInfo?.stockSymbol,
        market: businessInformation?.publicCompanyInfo?.market
      }),
      preferredMccCode: businessInformation?.preferredMccCode
    },
    businessStreetAddressSection: {
      merchantBusinessAddressAutocomplete: businessStreetAddressData || {}
    },
    billingAddressSection: {
      billingAddressSameAs: billingSameAsBusiness,
      merchantBillingAddressAutocomplete: billingSameAsBusiness
        ? businessStreetAddressData
        : billingAddressData
    },
    paymentProcessingAndSalesSection: {
      ...(paymentProcessingAndSalesSection?.monthlyTotalProcessingVolume && { // LR Only
        monthlyTotalProcessingVolume: paymentProcessingAndSales?.monthlyTotalProcessingVolume
      }),
      ...(paymentProcessingAndSalesSection?.monthlyAmexProcessingVolume && { // LR Only
        monthlyAmexProcessingVolume: paymentProcessingAndSales?.monthlyAmexProcessingVolume
      }),
      averageTransactionAmount: paymentProcessingAndSales?.averageTransactionAmount,
      highestTransactionAmount: paymentProcessingAndSales?.highestTransactionAmount,
      ...(paymentProcessingAndSalesSection?.averageMonthlyVolume && { // HR Only
        averageMonthlyVolume: paymentProcessingAndSales?.averageMonthlyVolume
      }),
      ...(paymentProcessingAndSalesSection?.highestMonthlyVolume && { // HR Only
        highestMonthlyVolume: paymentProcessingAndSales?.highestMonthlyVolume
      }),
      ...(paymentProcessingAndSalesSection?.currentlyAllowedMonthlyValue && { // HR Only
        currentlyAllowedMonthlyValue: paymentProcessingAndSales?.currentlyAllowedMonthlyValue
      }),
      seasonalBusiness: paymentProcessingAndSales?.seasonalBusiness,
      seasonMonths: paymentProcessingAndSales?.seasonMonths
        ? Object.entries(paymentProcessingAndSales.seasonMonths).map(([month, isChecked]) => ({
          [month]: isChecked || false
        }))
        : seasonalBusinessMonthFields
    },
    orderChannelSection: { // CUSTOM SECTION
      emvOrCardPresentSwiped: paymentProcessingAndSales?.orderChannel?.emvOrCardPresentSwiped,
      telephoneOrder: paymentProcessingAndSales?.orderChannel?.telephoneOrder,
      eCommerce: paymentProcessingAndSales?.orderChannel?.eCommerce
    },
    businessAccountNumberSection: {
      routingNumber: achInfo?.creditAndDebit?.routingNumber,
      accountNumber: achInfo?.creditAndDebit?.accountNumber
    },
    achInformationAndFundingChoicesSection: { // creditAndDebit
      nameOnAccountSameAsLegalName: nameOnAccountSameAsLegal,
      nameOnAccount: achInfo?.creditAndDebit?.nameOnAccount,
      bankName: achInfo?.creditAndDebit?.bankName,
      fundingChoice: ['standard', 'elevated'].includes(riskProfile) ? 'standard' : achInfo?.fundingChoices?.fundingChoice,
      nextDayFundingBatchFee: formatFeeValue(achInfo?.fundingChoices?.nextDayFundingBatchFee)
    }
  };
  return tabData;
};

const getPaymentMethodsAcceptedFrontend = paymentCardInfo => ({
  paymentMethodsAcceptedSection: {
    debitCardsOnly: paymentCardInfo?.debitCardsOnly || false,
    paymentCardsAccepted: paymentCardInfo !== undefined // undefined on new app
      ? [ // FE-only-key, converts BE data to FE checkboxList with these fields
        { visa: paymentCardInfo?.visa || false },
        { mastercard: paymentCardInfo?.mastercard || false },
        { discover: paymentCardInfo?.discover || false },
        { amexOptBlue: paymentCardInfo?.amexOptBlue || false }
      ]
      : [
        { visa: true },
        { mastercard: true },
        { discover: true },
        { amexOptBlue: true }
      ],
    amexRetainedESA: paymentCardInfo?.amexRetainedESA || false,
    amexNumber: paymentCardInfo?.amexNumber,
    electronicBenefitsTransfer: paymentCardInfo?.electronicBenefitsTransfer || false,
    fnsEbtNumber: paymentCardInfo?.fnsEbtNumber
  }
});

export const buildNeteviaTabData = (backendData = {}, options) => {
  const { neteviaFields } = backendData || {};
  const {
    onlyUseFeeData
  } = options || {};
  const {
    paymentCardAcceptance,
    ratesAndFees,
    cardNotPresent,
    fees
  } = neteviaFields || {};
  const {
    webHosting,
    domainRegistration,
    webPageDesign,
    auction,
    internetServiceGateway,
    sellingDigitalService,
    advertisement,
    sellingHardGoods,
    other
  } = cardNotPresent?.internetBusinessType || {};
  const formatOptions = { ...options, feesKey: 'netevia', appType: 'lowRisk' };
  let paymentCardAcceptanceSection = {};
  if (!onlyUseFeeData) {
    // Sections only shown if viewing full mpa details
    paymentCardAcceptanceSection = getPaymentMethodsAcceptedFrontend(
      paymentCardAcceptance,
      formatOptions
    );
  }
  const neteviaOnlySections = onlyUseFeeData ? {
  } : {
    // Sections only shown if viewing full mpa details
    neteviaCardNotPresentSection: {
      internetBusinessType: cardNotPresent !== undefined // undefined on new app
        ? [ // Converts BE data to FE checkboxList with these fields
          { webHosting: webHosting || false },
          { domainRegistration: domainRegistration || false },
          { webPageDesign: webPageDesign || false },
          { auction: auction || false },
          { internetServiceGateway: internetServiceGateway || false },
          { sellingDigitalService: sellingDigitalService || false },
          { advertisement: advertisement || false },
          { sellingHardGoods: sellingHardGoods || false },
          { other: other || false }
        ]
        // must be `undefined` for value to be unset on mount
        : undefined,
      internetBusinessTypeOther: cardNotPresent?.internetBusinessTypeOther,
      encryptionVendorsAndControls: cardNotPresent?.encryptionVendorsAndControls,
      advertisingAndPromotionMethod: cardNotPresent?.advertisingAndPromotionMethod,
      vendors: cardNotPresent?.vendors,
      fulfillmentVendor: cardNotPresent?.fulfillmentVendor,
      saleProcessDescription: cardNotPresent?.saleProcessDescription
    },
    neteviaBillingMethodsSection: {
      monthly: cardNotPresent?.billingMethods?.monthly,
      yearly: cardNotPresent?.billingMethods?.yearly,
      quarterly: cardNotPresent?.billingMethods?.quarterly,
      oneTime: cardNotPresent?.billingMethods?.oneTime,
      hourly: cardNotPresent?.billingMethods?.hourly
    }
  };
  const neteviaFeesOnlySections = { // Fees sections are always shown
    ...buildFeesTabData({ ratesAndFees }, formatOptions),
    neteviaFeesSection: {
      authVisaMastercardDiscover: fees?.authVisaMastercardDiscover,
      authAmex: fees?.authAmex,
      pciAnnual: fees?.pciAnnual,
      reporting1099K: fees?.reporting1099K,
      earlyTermination: fees?.earlyTermination,
      ebtPerTxn: fees?.ebtPerTxn,
      regulatoryAssistancePerMonth: fees?.regulatoryAssistancePerMonth,
      webMonitoringSetup: fees?.webMonitoringSetup
    }
  };
  const tabData = {
    ...paymentCardAcceptanceSection,
    ...neteviaOnlySections,
    ...neteviaFeesOnlySections
  };
  return tabData;
};

export const buildRepayTabData = (backendData = {}, options) => {
  const { repayFields } = backendData || {};
  const { onlyUseFeeData } = options || {};
  const {
    achInfoAndFunding,
    paymentCardAcceptance,
    ratesAndFees
  } = repayFields || {};
  const formatOptions = { ...options, feesKey: 'repay', appType: 'lowRisk' };
  const paymentCardAcceptanceSection = onlyUseFeeData
    ? {}
    // Section only shown if viewing full mpa details
    : getPaymentMethodsAcceptedFrontend(
      paymentCardAcceptance,
      formatOptions
    );
  const ratesAndFeesSection = buildFeesTabData( // Fees section is always shown
    { ratesAndFees },
    formatOptions
  );
  const repayGeneralSection = onlyUseFeeData
    ? {}
    : {
      generalSection: {
        chargebackNotificationEmailAddress: repayFields?.chargebackNotificationEmailAddress,
        statementDistributionMethod: repayFields?.statementDistributionMethod,
        statementDestination: repayFields?.statementDestination,
        suppressStatement: repayFields?.suppressStatement,
        combinedAch: repayFields?.combinedAch,
        discountType: repayFields?.discountType,
        netAch: repayFields?.netAch,
        terminalName: repayFields?.terminalName,
        serviceLevel: repayFields?.serviceLevel
      }
    };
  const repayAchInfoAndFundingSection = onlyUseFeeData
    ? {}
    : getRepayAchInfoAndFundingTabData(achInfoAndFunding, options);
  const tabData = {
    ...paymentCardAcceptanceSection,
    ...ratesAndFeesSection,
    ...repayGeneralSection,
    ...repayAchInfoAndFundingSection
  };
  return tabData;
};

const getRepayAchInfoAndFundingTabData = (backendData, options) => {
  const { debitOnly, chargebackOnly } = backendData || {};
  const { relationship } = options || {};
  const useAchAccountRadio = useRepayAchAccountRadio(relationship);
  const hasDebitOnlyData = dataExists(debitOnly);
  const hasDdaForChargebacksData = dataExists(chargebackOnly);
  const formattedRepayAchInfoAndFundingSection = useAchAccountRadio
    ? {
      selectAchAccountRadio: hasDebitOnlyData || hasDdaForChargebacksData
        ? (hasDdaForChargebacksData && 'use_dda_for_chargebacks_only') ||
          (hasDebitOnlyData && 'use_debit_only')
        : undefined,
      // chargebackOnly
      nameOnAccountDdaForChargebacks: chargebackOnly?.nameOnAccount,
      bankNameDdaForChargebacks: chargebackOnly?.bankName,
      // debitOnly
      nameOnAccountDebitOnly: debitOnly?.nameOnAccount,
      bankNameDebitOnly: debitOnly?.bankName
    }
    : { // only `debitOnly` is allowed
      repayUseDebitCheckbox: hasDebitOnlyData,
      nameOnAccount: debitOnly?.nameOnAccount,
      bankName: debitOnly?.bankName
    };
  const formattedRepayAchInfoConfirmSection = useAchAccountRadio
    ? {
      routingNumber: hasDdaForChargebacksData
        ? chargebackOnly?.routingNumber
        : debitOnly?.routingNumber,
      accountNumber: hasDdaForChargebacksData
        ? chargebackOnly?.accountNumber
        : debitOnly?.accountNumber
    }
    : { // only `debitOnly` is allowed
      routingNumber: debitOnly?.routingNumber,
      accountNumber: debitOnly?.accountNumber
    };
  return {
    repayAchInfoAndFundingSection: formattedRepayAchInfoAndFundingSection,
    repayAchInfoConfirmSection: formattedRepayAchInfoConfirmSection
  };
};

export const formatRiskExposureInputsFe = riskExposureInputs => ({
  creditReturnRatio: toFrontendValue(riskExposureInputs.creditReturnRatio),
  chargebackRatio: toFrontendValue(riskExposureInputs.chargebackRatio),
  nonDeliveryRiskDays: riskExposureInputs.nonDeliveryRiskDays,
  creditTime: riskExposureInputs.creditTime,
  monthlyVolume: riskExposureInputs.monthlyVolume,
  percentKeyed: toFrontendValue(riskExposureInputs.percentKeyed, { type: 'percent', fieldType: 'input', isRatio: true }),
  mccCode: riskExposureInputs.mccCode
});

export const formatRiskExposureInputsBe = riskExposureInputs => ({
  creditReturnRatio: toBackendValue(riskExposureInputs.creditReturnRatio, { type: 'ratioSevenPrecision', fieldType: 'input' }),
  chargebackRatio: toBackendValue(riskExposureInputs.chargebackRatio, { type: 'ratioSevenPrecision', fieldType: 'input' }),
  nonDeliveryRiskDays: toBackendValue(riskExposureInputs.nonDeliveryRiskDays, { type: 'rationalNumber', fieldType: 'input' }),
  creditTime: toBackendValue(riskExposureInputs.creditTime, { type: 'rationalNumber', fieldType: 'input' }),
  monthlyVolume: toBackendValue(riskExposureInputs.monthlyVolume, { type: 'rationalNumber', fieldType: 'input' }),
  percentKeyed: toBackendValue(riskExposureInputs.percentKeyed, { type: 'percent', fieldType: 'input', isRatio: true }),
  mccCode: toBackendValue(riskExposureInputs.mccCode, { type: 'string', fieldType: 'combobox' })
});

export default sharedBoardingTemplate;
