import { spring, presets } from '@serprex/react-motion';
import {
  borderRadius,
  transition,
  boxshadow,
  noselect
} from './_globals';
import {
  icons,
  deleteItem,
  magnify,
  listMore,
  arrowRight,
  arrowLeft,
  backToTop
} from '../images/_icons';
import {
  dinoDab
} from '../images/_svgs';

export const footerCSS = {
  wrap: {
    clear: 'both',
    overflow: 'hidden',
    position: 'relative',
    zIndex: '9',
    background: 'var(--color-secondary)',
    padding: '0 10px',
    color: 'white',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    fontSize: '1.2rem',
    minHeight: 'var(--height-footer)'
  },
  innerWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '10px',
    width: '100%'
  }
};

// Radio Button Styles
export const radioSwitchCSS = {
  position: 'relative',
  display: 'inline-block',
  height: '30px'
};
export const radioSwitchCSSBackground = {
  content: '  ',
  position: 'absolute',
  left: '0',
  top: '0',
  zIndex: '0',
  width: '100%',
  height: '30px',
  backgroundColor: 'var(--color-disabled)',
  ...borderRadius('15px')
};
export const radioSwitcCSSWrap = {
  display: 'block',
  flex: '1',
  color: 'var(--color-primary)',
  padding: '0 10px',
  height: '30px',
  lineHeight: '30px',
  textAlign: 'center',
  cursor: 'pointer',
  position: 'relative',
  zIndex: '2',
  WebkitTransition: 'color 200ms ease-out',
  MozTransition: 'color 200ms ease-out',
  transition: 'color 200ms ease-out'
};
export const radioSwitcCSSWrapHover = {
  ...radioSwitcCSSWrap
};
export const label = {
  display: 'block',
  cursor: 'pointer',
  whiteSpace: 'nowrap'
};
export const labelCSSActive = {
  color: 'var(--color-whiteish)',
  whiteSpace: 'nowrap'
};
export const radioSwitchCSSIndicator = {
  opacity: '0',
  height: '30px',
  position: 'absolute',
  top: '0',
  left: '0',
  zIndex: '1',
  backgroundColor: 'var(--color-primary)',
  WebkitTransition: 'all 600ms cubic-bezier(.02,.94,.09,.97), background 300ms cubic-bezier(.17,.67,.14,1.03)',
  MozTransition: 'all 600ms cubic-bezier(.02,.94,.09,.97), background 300ms cubic-bezier(.17,.67,.14,1.03)',
  transition: 'all 600ms cubic-bezier(.02,.94,.09,.97), background 300ms cubic-bezier(.17,.67,.14,1.03)',
  WebkitTransform: 'translate3d(0,0,0)',
  MozTransform: 'translate3d(0,0,0)',
  transform: 'translate3d(0,0,0)',
  ...borderRadius('15px')
};
export const radioSwitchCSSIndicatorActive = {
  ...radioSwitchCSSIndicator,
  opacity: '1'
};
export const radio = {
  display: 'none'
};
export const badge = {
  position: 'absolute',
  backgroundColor: 'var(--color-warning)',
  color: 'var(--color-whiteish)',
  ...borderRadius('12px'),
  lineHeight: '24px',
  minWidth: '24px',
  margin: 'auto',
  padding: ('0px 6px'),
  textAlign: 'center',
  top: '-18px',
  fontSize: '1em',
  left: '50%',
  transform: 'translateX(-50%)'
};
export const rs = {
  radioSwitchCSS,
  radioSwitchCSSBackground,
  radioSwitcCSSWrap,
  radioSwitcCSSWrapHover,
  radio,
  label,
  badge,
  labelCSSActive,
  radioSwitchCSSIndicator,
  radioSwitchCSSIndicatorActive
};
// END Radio Button Styles

// Checkboxes
const checkboxCSSBase = {
  minHeight: '24px',
  lineHeight: '1.3',
  cursor: 'pointer',
  display: 'inline-block',
  paddingLeft: '38px',
  fontSize: '1.2em',
  fontWeight: '300',
  position: 'relative'
};
const checkboxCSSBox = {
  width: '24px',
  height: '24px',
  borderColor: 'var(--color-grey)',
  borderWidth: '1px',
  borderStyle: 'solid',
  backgroundColor: 'var(--color-bg)',
  position: 'absolute',
  left: '0',
  WebkitTransition: 'all .3s ease',
  MozTransition: 'all .3s ease',
  transition: 'all .3s ease'
};
export const checkbox = {
  input: {
    opacity: '0',
    position: 'absolute',
    zIndex: '-100'
  },
  label: {
    ...checkboxCSSBase
  },
  label_before: {
    backgroundColor: 'transparent',
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='12' %3E%3Cpath fill='%23c09737' d='M6.452,10.087l-5.029,-5.09l-1.423,1.406l5.029,5.089l1.423,-1.405l0,0Z' /%3E%3Cpath fill='%23c09737' d='M5.029,11.492l10.191,-10.069l-1.405,-1.423l-10.192,10.07l1.406,1.422Z' /%3E%3C/svg%3E")`,
    backgroundPosition: 'left center',
    backgroundRepeat: 'no-repeat',
    content: '',
    width: '0',
    height: '24px',
    position: 'absolute',
    top: '-2px',
    left: '2px',
    WebkitTransition: 'all .3s ease',
    MozTransition: 'all .3s ease',
    transition: 'all 0.3s'
  },
  label_before_checked: {
    backgroundColor: 'transparent',
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='12' %3E%3Cpath fill='%23c09737' d='M6.452,10.087l-5.029,-5.09l-1.423,1.406l5.029,5.089l1.423,-1.405l0,0Z' /%3E%3Cpath fill='%23c09737' d='M5.029,11.492l10.191,-10.069l-1.405,-1.423l-10.192,10.07l1.406,1.422Z' /%3E%3C/svg%3E")`,
    backgroundPosition: 'left center',
    backgroundRepeat: 'no-repeat',
    content: '',
    height: '24px',
    position: 'absolute',
    top: '-2px',
    left: '4px',
    WebkitTransition: 'all .3s ease',
    MozTransition: 'all .3s ease',
    transition: 'all 0.3s',
    width: '32px',
    backgroundSize: '80%'
  },
  label_before_checked_mini: {
    backgroundColor: 'transparent',
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='12' %3E%3Cpath fill='%23c09737' d='M6.452,10.087l-5.029,-5.09l-1.423,1.406l5.029,5.089l1.423,-1.405l0,0Z' /%3E%3Cpath fill='%23c09737' d='M5.029,11.492l10.191,-10.069l-1.405,-1.423l-10.192,10.07l1.406,1.422Z' /%3E%3C/svg%3E")`,
    backgroundPosition: 'left center',
    backgroundRepeat: 'no-repeat',
    content: '',
    height: '12px',
    position: 'absolute',
    top: '2px',
    left: '3px',
    WebkitTransition: 'all .3s ease',
    MozTransition: 'all .3s ease',
    transition: 'all 0.3s',
    width: '16px',
    backgroundSize: '80%'
  },
  label_before_checked_disabled: {
    backgroundColor: 'transparent',
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='12' %3E%3Cpath fill='%23666666' d='M6.452,10.087l-5.029,-5.09l-1.423,1.406l5.029,5.089l1.423,-1.405l0,0Z' /%3E%3Cpath fill='%23666666' d='M5.029,11.492l10.191,-10.069l-1.405,-1.423l-10.192,10.07l1.406,1.422Z' /%3E%3C/svg%3E")`
  },
  label_before_checked_mini_disabled: {
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='12' %3E%3Cpath fill='%23666666' d='M6.452,10.087l-5.029,-5.09l-1.423,1.406l5.029,5.089l1.423,-1.405l0,0Z' /%3E%3Cpath fill='%23666666' d='M5.029,11.492l10.191,-10.069l-1.405,-1.423l-10.192,10.07l1.406,1.422Z' /%3E%3C/svg%3E")`
  },
  label_disabled: {
    color: 'var(--color-light-label)'
  },
  labelCSSActive: {
    color: 'var(--color-status-in-progress)'
  },
  label_focus: {
    color: 'var(--color-status-in-progress)'
  },
  checked: {
    ...checkboxCSSBox,
    borderColor: 'var(--color-hr)',
    animation: 'squareToCircle .5s 0s 1 cubic-bezier(0.42,0,0.58,1) normal forwards'
  },
  unchecked: {
    ...checkboxCSSBox,
    borderColor: 'var(--color-grey)',
    animation: 'circleToSquare .5s 0s 1 cubic-bezier(0.42,0,0.58,1) normal forwards'
  },
  hover: {
    borderColor: 'hsl(var(--color-hr-hue), var(--color-hr-saturation), calc(var(--color-hr-lightness) - 10%))'
  },
  disabled: {
    borderColor: 'var(--color-disabled)'
  },
  active: {
    borderColor: 'var(--color-status-in-progress)'
  },
  focus: {
    borderColor: 'var(--color-status-in-progress)'
  }
};
// END Checkboxes

export const radioCSS = {
  innerWrap: {
    margin: '-1px',
    position: 'relative',
    borderColor: '#000000',
    borderWidth: '1px',
    borderStyle: 'solid',
    overflow: 'hidden',
    backgroundColor: 'white'
  },
  label: {
    display: 'block',
    position: 'relative',
    paddingLeft: '30px',
    marginBottom: '12px',
    cursor: 'pointer',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    MsUserSelect: 'none',
    userSelect: 'none',
    ...transition()
  },
  labelHorizontal: {
    float: 'left'
  },
  labelSmall: {
    marginBottom: '6px',
    minHeight: '16px',
    lineHeight: '1.3',
    fontSize: '1.2rem',
    paddingLeft: '15px'
  },
  labelChecked: {
    color: 'var(--color-primary)',
    fontWeight: 'normal',
    cursor: 'default',
    ...transition()
  },
  labelHover: {
    color: '#000000',
    ...transition()
  },
  labelDisabledInside: {
    color: 'var(--color-light-label)',
    fontWeight: 'normal',
    cursor: 'default'
  },
  labelDisabled: {
    color: 'var(--color-disabled)',
    fontWeight: 'normal',
    cursor: 'default'
  },
  input: {
    position: 'absolute',
    opacity: '0',
    cursor: 'pointer',
    height: '0',
    width: '0'
  },
  checkmark: {
    position: 'absolute',
    top: '2px',
    left: '0',
    height: '24px',
    width: '24px',
    backgroundColor: 'var(--color-hr)',
    ...transition()
  },
  circle: {
    borderRadius: '50%'
  },
  checkmarkSmall: {
    height: '12px',
    width: '12px'
  },
  checkmarkHover: {
    backgroundColor: 'var(--color-grey)',
    ...transition()
  },
  checkmarkChecked: {
    backgroundColor: 'var(--color-primary)',
    ...transition()
  },
  check: {
    position: 'absolute',
    opacity: '0',
    backgroundColor: 'var(--color-grey)',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '24px',
    height: '24px',
    ...transition('all', '.4s', 'ease')
  },
  checkSmall: {
    width: '10px',
    height: '10px'
  },
  checkChecked: {
    width: '8px',
    height: '8px',
    opacity: '1',
    backgroundColor: 'var(--color-bg)',
    ...transition()
  },
  checkCheckedSmall: {
    width: '4px',
    height: '4px'
  },
  checkHover: {
    opacity: '1',
    width: '14px',
    height: '14px',
    backgroundColor: 'var(--color-bg)',
    ...transition('all', '.4s', 'ease')
  },
  checkHoverSmall: {
    width: '6px',
    height: '6px'
  },
  disabled: {
    backgroundColor: 'var(--color-disabled)'
  },
  disabledInside: {
    backgroundColor: 'var(--color-bg)'
  }
};

// Inputs
const inputCSSBase = {
  outline: 'none',
  display: 'block',
  fontSize: '1.6rem',
  color: 'var(--color-text)',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'var(--color-hr)',
  padding: '9px 12px 11px',
  width: '100%',
  marginTop: '0',
  marginBottom: '0',
  marginLeft: '0',
  marginRight: '0',
  WebkitAppearance: 'textfield',
  MozAppearance: 'textfield',
  backgroundColor: 'var(--color-bg)',
  ...borderRadius()
};

export const comboBoxColors = {
  newValue: {
    color: 'var(--color-forest)',
    backgroundColor: 'hsl(calc(var(--color-heading-hue) - 35), calc(var(--color-heading-saturation) - 60%), calc(var(--color-heading-lightness) + 68%))'
  },
  multiValueNotClearable: {
    color: 'hsl(var(--color-warning-hue), calc(var(--color-warning-saturation) - 25%), var(--color-warning-lightness))',
    backgroundColor: 'hsl(var(--color-warning-hue), var(--color-warning-saturation), calc(var(--color-warning-lightness) + 60%))'
  },
  internalOnly: {
    color: 'hsl(var(--color-primary-hue), calc(var(--color-primary-saturation) - 40%), var(--color-primary-lightness))',
    backgroundColor: 'hsl(var(--color-primary-hue), var(--color-primary-saturation), calc(var(--color-primary-lightness) + 43%))'
  }
};

export const comboBox = {
  error: {
    wrapper: {
      borderColor: 'var(--color-warning)'
    }
  },
  labelInside: {
    position: 'absolute',
    zIndex: 1,
    padding: '2px',
    top: -6,
    left: 3,
    fontSize: '1.2rem',
    color: 'hsl(var(--color-text-hue), var(--color-text-saturation), calc(var(--color-text-lightness) + 25%))'
  },
  newValue: {
    backgroundColor: comboBoxColors.newValue.backgroundColor
  },
  newValueLabel: {
    color: comboBoxColors.newValue.color
  },
  multiValueNotClearable: {
    backgroundColor: comboBoxColors.multiValueNotClearable.backgroundColor
  },
  multiLabelNotClearable: {
    color: comboBoxColors.multiValueNotClearable.color,
    paddingRight: '6px'
  },
  internalOnly: {
    backgroundColor: comboBoxColors.internalOnly.backgroundColor
  },
  internalOnlyLabel: {
    color: comboBoxColors.internalOnly.color
  }
};

export const input = {
  default: {
    backgroundColor: 'var(--color-bg)',
    backgroundImage: 'inherit'
  },
  wrap: {
    position: 'relative',
    width: '100%'
  },
  innerWrap: {
    position: 'relative',
    borderColor: '#000000',
    borderWidth: '1px',
    borderStyle: 'solid',
    backgroundColor: 'var(--color-bg)'
  },
  feedback: {
    maxHeight: '0',
    opacity: '0',
    overflow: 'hidden',
    fontSize: '0',
    ...transition()
  },
  feedback_error: {
    maxHeight: '100px',
    opacity: '1',
    fontSize: '1.2rem',
    padding: '0 4px',
    color: 'var(--color-warning)'
  },
  feedback_error_in: {
    lineHeight: '1.5',
    backgroundColor: 'var(--color-warning)',
    color: 'white'
  },
  feedback_success: {
    position: 'absolute',
    right: '-10px',
    bottom: '13px',
    maxHeight: '20px',
    height: '20px',
    width: '20px',
    opacity: '1',
    backgroundImage: icons.checkmark.src_color,
    backgroundPosition: 'center',
    backgroundRepeat: icons.info.repeat,
    color: 'var(--color-primary)'
  },
  filtered: {
    borderColor: 'var(--color-status-in-progress)'
  },
  input: {
    ...inputCSSBase
  },
  inputInside: {
    padding: '5px',
    borderStyle: 'none',
    ...borderRadius(0)
  },
  prefix: {
    position: 'absolute',
    left: '5px',
    color: `hsl(var(--color-text-hue), var(--color-text-saturation), calc(var(--color-text-lightness) + 25%))`
  },
  suffix: {
    position: 'absolute',
    left: '12px',
    color: `hsl(var(--color-text-hue), var(--color-text-saturation), calc(var(--color-text-lightness) + 25%))`
  },
  hover: {
    backgroundColor: 'hsl(var(--color-secondary-hue), calc(var(--color-secondary-saturation) - 20%), calc(var(--color-secondary-lightness) + 80%))'
  },
  active: {
    borderColor: 'var(--color-status-in-progress)'
  },
  disabled: {
    borderColor: 'var(--color-hr)',
    backgroundColor: 'var(--color-disabled)',
    color: 'var(--color-light-label)'
  },
  innerWrapDisabled: {
    backgroundColor: 'var(--color-disabled)',
    color: 'var(--color-light-label)'
  },
  success: {
    borderColor: 'var(--color-healthy)'
  },
  focus: {
    borderColor: 'var(--color-status-in-progress)'
  },
  error: {
    borderColor: 'var(--color-warning)'
  },
  label_required: {
    color: 'var(--color-warning)'
  },
  textarea: {
    ...inputCSSBase,
    resize: 'none',
    overflow: 'hidden',
    height: '100%',
    wordWrap: 'break-word',
    padding: '10px',
    width: '100%'
  },
  textAreaShadow: {
    wordWrap: 'break-word',
    padding: '2px',
    width: '100%',
    visibility: 'hidden',
    height: 0
  },
  textareaWrap: {
    height: '100%',
    position: 'relative',
    minHeight: '45px',
    maxHeight: '100%'
  },
  label: {
    display: 'block',
    fontSize: '1.2rem',
    color: 'var(--color-label)',
    height: '14px',
    lineHeight: '20px',
    marginLeft: '0',
    marginRight: '0',
    marginTop: '5px',
    marginBottom: '5px'
  },
  labelInside: {
    display: 'block',
    minHeight: '14px',
    color: `hsl(var(--color-text-hue), var(--color-text-saturation), calc(var(--color-text-lightness) + 25%))`,
    lineHeight: '1',
    fontSize: '1.2rem',
    padding: '4px',
    marginLeft: '0',
    marginRight: '0',
    marginTop: '0',
    marginBottom: '0'
  },
  timestampTooltip: {
    position: 'relative',
    content: '',
    display: 'inline-block',
    width: '15px',
    marginLeft: '.5rem',
    height: '10px'
  },
  errorShake: {
    animation: 'shake 0.82s cubic-bezier(.36,.07,.19,.97) both',
    transform: 'translate3d(0, 0, 0)',
    backfaceVisibility: 'hidden',
    perspective: '1000px'
  }
};
// END Inputs

const toggleSwitchTrack = {
  verticalAlign: 'middle',
  width: '43px',
  height: '23px',
  float: 'left',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'var(--color-grey)',
  backgroundColor: 'var(--color-hr)',
  transitionDuration: '.4s',
  transitionProperty: 'background-color, box-shadow',
  boxShadow: 'inset 0 0 0 0px rgba(0,0,0,0.4)',
  margin: '0 1.2rem 0 0',
  ...borderRadius('999px')
};
const knobBase = {
  float: 'left',
  width: '19px',
  height: '19px',
  backgroundColor: 'var(--color-whiteish)',
  WebkitTransitionTimingFunction: 'cubic-bezier(.54,1.85,.5,1)',
  MozTransitionTimingFunction: 'cubic-bezier(.54,1.85,.5,1)',
  transitionTimingFunction: 'cubic-bezier(.54,1.85,.5,1)',
  WebkitTransitionDuration: '0.4s',
  MozTransitionDuration: '0.4s',
  transitionDuration: '0.4s',
  WebkitTransitionProperty: 'transform, background-color, box-shadow',
  MozTionProperty: 'transform, background-color, box-shadow',
  transitionProperty: 'transform, background-color, box-shadow',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.3), 0px 0px 0 1px rgba(0, 0, 0, 0.4)',
  pointerEvents: 'none',
  marginTop: '1px',
  marginLeft: '1px',
  ...borderRadius('inherit')
};
export const toggleSwitch = {
  input: {
    position: 'absolute',
    opacity: '0'
  },
  track: {
    ...toggleSwitchTrack
  },
  trackChecked: {
    ...toggleSwitchTrack,
    width: '43px',
    backgroundPosition: '0 0',
    backgroundColor: 'var(--color-primary)',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'var(--color-primary)',
    boxShadow: `inset 0 0 0 10px var(--color-primary)`
  },
  knob: {
    ...knobBase
  },
  knobChecked: {
    ...knobBase,
    WebkitTransform: 'translate3d(20px, 0, 0)',
    MozTransform: 'translate3d(20px, 0, 0)',
    transform: 'translate3d(20px, 0, 0)',
    backgroundColor: 'var(--color-whiteish)',
    borderColor: 'var(--color-primary)',
    boxShadow: `0px 2px 5px #555555, 0 0 0 1px var(--color-primary)`
  },
  knob_focus: {
    borderColor: 'var(--color-status-in-progress)',
    boxShadow: `0px 2px 5px blue, 0 0 0 1px blue`
  },
  label_default: {
    display: 'inline-block',
    height: '23px',
    lineHeight: '23px',
    cursor: 'pointer'
  },
  label_disabled: {
    color: 'var(--color-disabled)'
  },
  label_focus: {
    color: 'var(--color-status-in-progress)'
  },
  disabled: {
    borderColor: 'var(--color-disabled)'
  },
  hover: {
  },
  focus: {
    borderColor: 'var(--color-status-in-progress)'
  }
};

const alertBarCSSBase = {
  padding: '10px 40px 10px',
  color: 'var(--color-whiteish)',
  position: 'absolute',
  margin: '0 auto',
  top: 'calc(100% + 10px)',
  width: 'calc(100% - 40px)', // 20px left margin + 20px right margin
  left: '0',
  right: '0',
  maxHeight: '400px',
  fontSize: '1.6rem',
  opacity: '1',
  WebkitTransition: 'all .3s ease-out',
  MozTransition: 'all .3s ease-out',
  transition: 'all .3s ease-out',
  ...borderRadius()
};

const sharedWarnStyle = {
  position: 'absolute',
  backgroundColor: 'var(--color-white)',
  opacity: '.9',
  zIndex: '2',
  left: 0
};

export const alertBarCSS = { // full page
  wrap: {
    ...alertBarCSSBase
  },
  warning: {
    ...alertBarCSSBase,
    backgroundColor: 'var(--color-warning)'
  },
  notice: {
    ...alertBarCSSBase,
    backgroundColor: 'var(--color-notice)'
  },
  success: {
    ...alertBarCSSBase,
    backgroundColor: 'var(--color-success)'
  },
  icon: {
    position: 'absolute',
    left: '10px',
    top: '50%',
    transform: 'translate(0, -50%)'
  },
  closed: {
    fontSize: '0',
    fontColor: 'transparent',
    maxHeight: '0px',
    margin: '0',
    padding: '0',
    opacity: '0'
  },
  warningStyle: {
    ...sharedWarnStyle,
    width: '100vw',
    height: '100vh',
    top: 'var(--height-header)'
  },
  warningStyleInnerAlertBar: {
    ...sharedWarnStyle,
    width: 'auto',
    height: '100%',
    top: 0
  },
  closeButton: {
    width: '20px',
    height: '20px',
    right: '10px',
    top: '10px',
    position: 'absolute',
    cursor: 'pointer'
  }
};

const innerAlertBarCSSBase = {
  padding: '10px 40px 10px',
  color: 'var(--color-whiteish)',
  position: 'absolute',
  width: 'auto',
  maxHeight: '400px',
  fontSize: '1.6rem',
  right: '0px',
  left: '0px',
  opacity: '1',
  WebkitTransition: 'all .3s ease-out',
  MozTransition: 'all .3s ease-out',
  transition: 'all .3s ease-out',
  display: 'flex',
  ...borderRadius()
};

export const innerAlertBarCSS = { // using an alert bar within a component
  wrap: {
    ...innerAlertBarCSSBase
  },
  closed: {
    ...alertBarCSS.closed
  },
  warningStyle: {
    ...alertBarCSS.warningStyle,
    zIndex: '11',
    left: '0',
    right: '0',
    top: '0'
  },
  closeButton: {
    ...alertBarCSS.closeButton
  }
};

export const loader = {
  wrap: {
    position: 'fixed',
    opacity: '0',
    top: 'calc(50px + var(--padding-header) + var(--padding-header))',
    left: '0',
    width: '100vw',
    height: 'calc(100vh - var(--padding-header) - var(--padding-header))',
    zIndex: '-1',
    background: 'var(--color-loader-overlay)',
    ...transition()
  },
  spinnerWrap: {
    position: 'absolute',
    opacity: '0',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: '-1',
    background: 'var(--color-loader-overlay)',
    ...transition()
  },
  spinnerLoading: {
    opacity: '1',
    zIndex: '5'
  },
  loading: {
    opacity: '1',
    zIndex: '97'
  },
  graphic: {
    position: 'absolute',
    width: '40px',
    height: '40px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'var(--color-warning)'
  },
  loader: {
    width: '40px',
    height: '40px',
    margin: '40px',
    position: 'absolute',
    top: 'calc(50% - 60px)',
    left: 'calc(50% - 60px)',
    transform: 'translate(-50%, -50% )',
    animation: 'rotate 1.4s infinite ease-in-out, background 1.4s infinite ease-in-out alternate',
    ...borderRadius()
  }
};

export const paginationCSS = {
  base: {
    fontSize: '1.2rem',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '1em'
  },
  span: {
    height: '30px',
    lineHeight: '1',
    cursor: 'pointer',
    color: 'black',
    float: 'left',
    paddingTop: '3px',
    paddingBottom: '3px',
    paddingLeft: '6px',
    paddingRight: '6px',
    margin: '0 2px',
    textDecoration: 'none',
    transition: 'background-color .3s',
    border: '1px solid #ddd',
    ...borderRadius()
  },
  active: {
    backgroundColor: '#0099FF',
    color: 'white',
    border: '1px solid #0099FF'
  },
  showing: {
    height: '42px',
    lineHeight: '42px',
    fontSize: '1.2rem',
    minWidth: '150px'
  }
};

export const hamburgerCSS = {
  DEFAULT: {
    cR2: 255,
    cG2: 255,
    cB2: 255,
    cA2: 1,

    // DEEP-GOLD
    cR: 168,
    cG: 137,
    cB: 66,
    cA: 1,

    mt1: 10.014,
    mt2: 3.87,
    mt3: -0.017,
    mt4: -0.021,
    mt5: 2.087,
    mt6: 0,
    mt7: 2.174,
    mt8: 0.022,
    mt9: 3.02,
    mt10: 0.077,
    mt11: 5.946,
    mt12: 0,
    mt13: 2.606,
    mt14: -0.067,
    mt15: 2.602,
    mt16: -3.9,
    mt17: 0,
    mt18: -3.865,
    mt19: -5.634,
    mt20: 0.077,
    mt21: -8.037,
    mt22: 0,
    mt23: -8.037,
    mt24: 0,
    mt25: 0,
    mt26: 0,
    mt27: -2.743,
    mt28: 0.023,
    mt29: -7.988,
    mt30: 0,
    mt31: -2.613,
    mt32: -0.011,
    mt33: -2.778,
    mt34: 3.822,
    mt35: 0,
    mt36: 3.865,
    mt37: 2.792,
    mt38: 0.044,
    mt39: 3.549,
    mt40: 0.036,
    mt41: 5.673,
    mt42: 0,
    mt43: 2.048,
    mt44: -0.034,
    mt45: 2.319,
    mt46: 0,
    mt47: 2.319,
    mt48: 0,

    mm1: 10.022,
    mm2: 11.983,
    mm3: 5.939,
    mm4: 8.166,
    mm5: -8.166,
    mm6: -8.166,
    mm7: -8.141,
    mm8: -2.508,
    mm9: -2.509,
    mm10: 8.141,
    mm11: 8.141,

    mb1: 10.01,
    mb2: 16.13,
    mb3: -0.039,
    mb4: 0.049,
    mb5: 2.071,
    mb6: 0,
    mb7: 1.841,
    mb8: -0.042,
    mb9: 3.12,
    mb10: -0.055,
    mb11: 5.966,
    mb12: 0,
    mb13: 2.607,
    mb14: 0.051,
    mb15: 2.601,
    mb16: 3.887,
    mb17: 0,
    mb18: 3.867,
    mb19: -5.646,
    mb20: -0.042,
    mb21: -8.038,
    mb22: 0,
    mb23: -8.038,
    mb24: 0,
    mb25: -2.871,
    mb26: 0.006,
    mb27: -7.987,
    mb28: 0,
    mb29: -2.613,
    mb30: -0.003,
    mb31: -2.778,
    mb32: -3.819,
    mb33: 0,
    mb34: -3.867,
    mb35: 2.778,
    mb36: -0.048,
    mb37: 3.352,
    mb38: 0.024,
    mb39: 5.931,
    mb40: 0,
    mb41: 2.067,
    mb42: -0.018,
    mb43: 2.057,
    mb44: 0,
    mb45: 2.057,
    mb46: 0
  },
  SECONDARY: {
    cR: 77,
    cG: 77,
    cB: 77,
    cA: 1,

    cR2: 77,
    cG2: 77,
    cB2: 77,
    cA2: 1,

    mt1: 9.936,
    mt2: 12.661,
    mt3: 1.407,
    mt4: -1.335,
    mt5: 2.805,
    mt6: -2.743,
    mt7: 1.827,
    mt8: -1.84,
    mt9: 4.662,
    mt10: -4.665,
    mt11: 6.273,
    mt12: -6.274,
    mt13: 2.306,
    mt14: -2.303,
    mt15: -0.621,
    mt16: -5.041,
    mt17: -2.822,
    mt18: -2.822,
    mt19: -2.746,
    mt20: 2.768,
    mt21: -6.258,
    mt22: 6.319,
    mt23: -6.258,
    mt24: 6.319,
    mt25: 0,
    mt26: 0,
    mt27: -3.297,
    mt28: -3.323,
    mt29: -6.222,
    mt30: -6.254,
    mt31: -2.346,
    mt32: -2.35,
    mt33: -5.188,
    mt34: 0.453,
    mt35: -2.822,
    mt36: 2.823,
    mt37: 1.509,
    mt38: 1.512,
    mt39: 4.365,
    mt40: 4.37,
    mt41: 6.204,
    mt42: 6.21,
    mt43: 1.376,
    mt44: 1.376,
    mt45: 2.842,
    mt46: 2.741,
    mt47: 2.842,
    mt48: 2.741,

    mm1: 9.983,
    mm2: 11.867,
    mm3: -1.989,
    mm4: 0.236,
    mm5: -0.236,
    mm6: -0.236,
    mm7: -0.237,
    mm8: -2.51,
    mm9: -2.511,
    mm10: 0.237,
    mm11: 0.237,

    mb1: 9.939,
    mb2: 7.339,
    mb3: 1.407,
    mb4: 1.335,
    mb5: 2.806,
    mb6: 2.744,
    mb7: 1.826,
    mb8: 1.839,
    mb9: 4.662,
    mb10: 4.664,
    mb11: 6.272,
    mb12: 6.274,
    mb13: 2.307,
    mb14: 2.302,
    mb15: -0.62,
    mb16: 5.04,
    mb17: -2.822,
    mb18: 2.821,
    mb19: -2.746,
    mb20: -2.768,
    mb21: -6.257,
    mb22: -6.318,
    mb23: -6.257,
    mb24: -6.318,
    mb25: -3.298,
    mb26: 3.323,
    mb27: -6.223,
    mb28: 6.253,
    mb29: -2.346,
    mb30: 2.351,
    mb31: -5.187,
    mb32: -0.452,
    mb33: -2.822,
    mb34: -2.822,
    mb35: 1.509,
    mb36: -1.512,
    mb37: 4.365,
    mb38: -4.37,
    mb39: 6.204,
    mb40: -6.21,
    mb41: 1.376,
    mb42: -1.377,
    mb43: 2.842,
    mb44: -2.742,
    mb45: 2.842,
    mb46: -2.742
  }
};

export const hamburgerSVGCSS = destination => ({
  cR: spring(destination.cR, presets.wobbly),
  cG: spring(destination.cG, presets.wobbly),
  cB: spring(destination.cB, presets.wobbly),
  cA: spring(destination.cA, presets.wobbly),

  cR2: spring(destination.cR2, presets.wobbly),
  cG2: spring(destination.cG2, presets.wobbly),
  cB2: spring(destination.cB2, presets.wobbly),
  cA2: spring(destination.cA2, presets.wobbly),

  mt1: spring(destination.mt1, presets.gentle),
  mt2: spring(destination.mt2, presets.gentle),
  mt3: spring(destination.mt3, presets.gentle),
  mt4: spring(destination.mt4, presets.gentle),
  mt5: spring(destination.mt5, presets.gentle),
  mt6: spring(destination.mt6, presets.gentle),
  mt7: spring(destination.mt7, presets.gentle),
  mt8: spring(destination.mt8, presets.gentle),
  mt9: spring(destination.mt9, presets.gentle),
  mt10: spring(destination.mt10, presets.gentle),
  mt11: spring(destination.mt11, presets.gentle),
  mt12: spring(destination.mt12, presets.gentle),
  mt13: spring(destination.mt13, presets.gentle),
  mt14: spring(destination.mt14, presets.gentle),
  mt15: spring(destination.mt15, presets.gentle),
  mt16: spring(destination.mt16, presets.gentle),
  mt17: spring(destination.mt17, presets.gentle),
  mt18: spring(destination.mt18, presets.gentle),
  mt19: spring(destination.mt19, presets.gentle),
  mt20: spring(destination.mt20, presets.gentle),
  mt21: spring(destination.mt21, presets.gentle),
  mt22: spring(destination.mt22, presets.gentle),
  mt23: spring(destination.mt23, presets.gentle),
  mt24: spring(destination.mt24, presets.gentle),
  mt25: spring(destination.mt25, presets.gentle),
  mt26: spring(destination.mt26, presets.gentle),
  mt27: spring(destination.mt27, presets.gentle),
  mt28: spring(destination.mt28, presets.gentle),
  mt29: spring(destination.mt29, presets.gentle),
  mt30: spring(destination.mt30, presets.gentle),
  mt31: spring(destination.mt31, presets.gentle),
  mt32: spring(destination.mt32, presets.gentle),
  mt33: spring(destination.mt33, presets.gentle),
  mt34: spring(destination.mt34, presets.gentle),
  mt35: spring(destination.mt35, presets.gentle),
  mt36: spring(destination.mt36, presets.gentle),
  mt37: spring(destination.mt37, presets.gentle),
  mt38: spring(destination.mt38, presets.gentle),
  mt39: spring(destination.mt39, presets.gentle),
  mt40: spring(destination.mt40, presets.gentle),
  mt41: spring(destination.mt41, presets.gentle),
  mt42: spring(destination.mt42, presets.gentle),
  mt43: spring(destination.mt43, presets.gentle),
  mt44: spring(destination.mt44, presets.gentle),
  mt45: spring(destination.mt45, presets.gentle),
  mt46: spring(destination.mt46, presets.gentle),
  mt47: spring(destination.mt47, presets.gentle),
  mt48: spring(destination.mt48, presets.gentle),

  mm1: spring(destination.mm1, presets.wobbly),
  mm2: spring(destination.mm2, presets.wobbly),
  mm3: spring(destination.mm3, presets.wobbly),
  mm4: spring(destination.mm4, presets.wobbly),
  mm5: spring(destination.mm5, presets.wobbly),
  mm6: spring(destination.mm6, presets.wobbly),
  mm7: spring(destination.mm7, presets.wobbly),
  mm8: spring(destination.mm8, presets.wobbly),
  mm9: spring(destination.mm9, presets.wobbly),
  mm10: spring(destination.mm10, presets.wobbly),
  mm11: spring(destination.mm11, presets.wobbly),

  mb1: spring(destination.mb1, presets.gentle),
  mb2: spring(destination.mb2, presets.gentle),
  mb3: spring(destination.mb3, presets.gentle),
  mb4: spring(destination.mb4, presets.gentle),
  mb5: spring(destination.mb5, presets.gentle),
  mb6: spring(destination.mb6, presets.gentle),
  mb7: spring(destination.mb7, presets.gentle),
  mb8: spring(destination.mb8, presets.gentle),
  mb9: spring(destination.mb9, presets.gentle),
  mb10: spring(destination.mb10, presets.gentle),
  mb11: spring(destination.mb11, presets.gentle),
  mb12: spring(destination.mb12, presets.gentle),
  mb13: spring(destination.mb13, presets.gentle),
  mb14: spring(destination.mb14, presets.gentle),
  mb15: spring(destination.mb15, presets.gentle),
  mb16: spring(destination.mb16, presets.gentle),
  mb17: spring(destination.mb17, presets.gentle),
  mb18: spring(destination.mb18, presets.gentle),
  mb19: spring(destination.mb19, presets.gentle),
  mb20: spring(destination.mb20, presets.gentle),
  mb21: spring(destination.mb21, presets.gentle),
  mb22: spring(destination.mb22, presets.gentle),
  mb23: spring(destination.mb23, presets.gentle),
  mb24: spring(destination.mb24, presets.gentle),
  mb25: spring(destination.mb25, presets.gentle),
  mb26: spring(destination.mb26, presets.gentle),
  mb27: spring(destination.mb27, presets.gentle),
  mb28: spring(destination.mb28, presets.gentle),
  mb29: spring(destination.mb29, presets.gentle),
  mb30: spring(destination.mb30, presets.gentle),
  mb31: spring(destination.mb31, presets.gentle),
  mb32: spring(destination.mb32, presets.gentle),
  mb33: spring(destination.mb33, presets.gentle),
  mb34: spring(destination.mb34, presets.gentle),
  mb35: spring(destination.mb35, presets.gentle),
  mb36: spring(destination.mb36, presets.gentle),
  mb37: spring(destination.mb37, presets.gentle),
  mb38: spring(destination.mb38, presets.gentle),
  mb39: spring(destination.mb39, presets.gentle),
  mb40: spring(destination.mb40, presets.gentle),
  mb41: spring(destination.mb41, presets.gentle),
  mb42: spring(destination.mb42, presets.gentle),
  mb43: spring(destination.mb43, presets.gentle),
  mb44: spring(destination.mb44, presets.gentle),
  mb45: spring(destination.mb45, presets.gentle),
  mb46: spring(destination.mb46, presets.gentle)
});

export const tooltipCSS = (data) => {
  const {
    backgroundColor = 'var(--color-tooltip)'
  } = data || {};
  return {
    base: {
      position: 'fixed',
      zIndex: '200',
      left: '-9999px',
      top: '0px',
      fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif',
      fontStyle: 'normal',
      fontWeight: '400',
      letterSpacing: 'normal',
      lineHeight: '1.42857143',
      textAlign: 'left',
      textShadow: 'none',
      textTransform: 'none',
      whiteSpace: 'normal',
      wordBreak: 'normal',
      wordSpacing: 'normal',
      wordWrap: 'normal',
      fontSize: '12px',
      display: 'inline-block'
    },
    inner: {
      padding: '5px 10px',
      color: 'var(--color-white)',
      textAlign: 'left',
      backgroundColor,
      maxWidth: '320px',
      ...borderRadius()
    },
    trigger: {
      display: 'inline-block'
    },
    on: {
      opacity: '1',
      zIndex: '11',
      WebkitTransition: 'opacity 0.3s ease-in-out',
      MozTransition: 'opacity 0.3s ease-in-out',
      MsTransition: 'opacity 0.3s ease-in-out',
      OTransition: 'opacity 0.3s ease-in-out',
      transition: 'opacity 0.3s ease-in-out'
    },
    off: {
      opacity: '0',
      zIndex: '-1',
      left: '-9999px'
    },
    right: {
      marginLeft: '5px'
    },
    left: {
      marginLeft: '-5px'
    },
    top: {
      marginTop: '-5px'
    },
    bottom: {
      marginTop: '5px'
    },
    arrow: {
      base: {
        top: '50%',
        left: '0',
        marginTop: '-5px',
        borderWidth: '5px 5px 5px 0',
        position: 'absolute',
        width: '0',
        height: '0',
        borderRightColor: 'transparent',
        borderTopColor: 'transparent',
        borderLeftColor: 'transparent',
        borderBottomColor: 'transparent',
        borderStyle: 'solid'
      },
      right: {
        top: '50%',
        left: 'auto',
        marginLeft: '-5px',
        borderWidth: '5px 5px 5px 0',
        borderRightColor: backgroundColor
      },
      top: {
        top: 'auto',
        bottom: '-5px',
        left: '50%',
        marginLeft: '-5px',
        borderWidth: '5px 5px 0',
        borderTopColor: backgroundColor
      },
      topright: {
        top: 'auto',
        bottom: '-3px',
        left: 'auto',
        marginLeft: '-3px',
        borderWidth: '8px 4px 0',
        borderTopColor: backgroundColor,
        transform: 'rotate(45deg)'
      },
      righttop: {
        top: 'auto',
        bottom: '-3px',
        left: 'auto',
        marginLeft: '-3px',
        borderWidth: '8px 4px 0',
        borderTopColor: backgroundColor,
        transform: 'rotate(45deg)'
      },
      left: {
        top: '50%',
        marginTop: '-5px',
        borderWidth: '5px 0 5px 5px',
        borderLeftColor: backgroundColor,
        right: '-5px',
        left: 'auto'
      },
      topleft: {
        top: 'auto',
        bottom: '-3px',
        left: 'auto',
        right: '-3px',
        marginLeft: '-3px',
        borderWidth: '8px 4px 0',
        borderTopColor: backgroundColor,
        transform: 'rotate(-45deg)'
      },
      lefttop: {
        top: 'auto',
        bottom: '-3px',
        left: 'auto',
        right: '-3px',
        marginLeft: '-3px',
        borderWidth: '8px 4px 0',
        borderTopColor: backgroundColor,
        transform: 'rotate(-45deg)'
      },
      bottom: {
        top: '0',
        left: '50%',
        marginLeft: '-5px',
        borderWidth: '0 5px 5px',
        borderBottomColor: backgroundColor
      },
      bottomleft: {
        top: '1px',
        left: 'auto',
        bottom: 'auto',
        right: '-3px',
        marginLeft: '-3px',
        borderWidth: '0 4px 8px',
        borderBottomColor: backgroundColor,
        transform: 'rotate(45deg)'
      },
      leftbottom: {
        top: '1px',
        left: 'auto',
        bottom: 'auto',
        right: '-3px',
        marginLeft: '-3px',
        borderWidth: '0 4px 8px',
        borderBottomColor: backgroundColor,
        transform: 'rotate(45deg)'
      },
      bottomright: {
        top: '1px',
        left: '-3px',
        bottom: 'auto',
        right: 'auto',
        marginRight: '-3px',
        borderWidth: '0 4px 8px',
        borderBottomColor: backgroundColor,
        transform: 'rotate(-45deg)'
      },
      rightbottom: {
        top: '1px',
        left: '-3px',
        bottom: 'auto',
        right: 'auto',
        marginRight: '-3px',
        borderWidth: '0 4px 8px',
        borderBottomColor: backgroundColor,
        transform: 'rotate(-45deg)'
      }
    },
    label: {
      maxWidth: '200px',
      padding: '3px 8px',
      color: 'var(--color-white)',
      textAlign: 'center',
      backgroundColor,
      ...borderRadius()
    },
    htmlWrap: {
      background: 'white',
      border: `1px solid ${backgroundColor}`,
      ...borderRadius()
    }
  };
};

export const reportCSS = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: '20px'
};

export const reportBarCSS = {
  container: { // CRM specific
    position: 'relative'
  },
  wrapper: {
    width: 'calc(100% - 10px)',
    margin: '-1px 5px 0',
    position: 'static'
  },
  base: {
    color: 'var(--color-warning)',
    maxHeight: '140px',
    overflow: 'hidden',
    opacity: '1',
    width: 'calc(100% - 10px)',
    margin: '0 -5px',
    position: 'absolute',
    left: '0',
    top: 'calc(100% - 1px)',
    background: 'var(--color-whiteish)',
    padding: '0 0 10px',
    borderBottom: `1px solid hsl(var(--color-grey-hue), var(--color-grey-saturation), calc(var(--color-grey-lightness) + 30%))`,
    ...boxshadow('0px', '7px', '5px', '-5px', 'rgba(0,0,0,0.05)'),
    ...transition()
  },
  stuck: {
    background: 'var(--color-bg)'
  },
  hidden: {
    opacity: '0',
    maxHeight: '0',
    margin: '0',
    padding: '0'
  },
  wrap: {
    display: 'flex',
    maxWidth: '1200px',
    margin: '0 auto',
    flexWrap: 'wrap'
  },
  sectionWrap: {
    display: 'flex',
    maxWidth: '600px',
    margin: '0 auto',
    flexWrap: 'nowrap',
    flexGrow: '1'
  },
  static: {
    position: 'static',
    marginTop: '-60px',
    borderBottom: 'none',
    ...boxshadow('0', '0', '0', '0', 'rgba(0,0,0,0.0)')
  }
};

export const reportMiniCSS = {
  base: {
    flex: '1',
    margin: '10px 0.5%',
    minWidth: '71px',
    textAlign: 'left',
    lineHeight: '1'
  },
  title: {
    color: 'var(--color-text)',
    fontSize: '1.4rem',
    height: '33px',
    lineHeight: '33px',
    paddingBottom: '10px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  titleActive: {
    color: 'var(--color-primary)'
  },
  box: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    fontSize: 'calc(0.9rem + 0.2vw)',
    position: 'relative',
    cursor: 'pointer',
    height: '60px',
    padding: '10px',
    border: '2px solid transparent',
    color: 'var(--color-primary)',
    background: 'var(--color-bg)',
    ...borderRadius(),
    ...boxshadow('0px', '1px', '5px', '0', 'rgba(0,0,0,0.05)')
  },
  boxActive: {
    border: `2px solid var(--color-primary)`
  },
  value: {
    fontSize: 'calc(2.3rem + 0.2vw)',
    color: 'hsl(var(--color-secondary-hue), var(--color-secondary-saturation), calc(var(--color-secondary-lightness) + 12%))',
    verticalAlign: 'middle',
    textAlign: 'center'
  },
  create: {
    minWidth: '50px',
    fontSize: '7rem',
    lineHeight: '1'
  },
  decrease: {
    color: 'var(--color-success)'
  },
  range: {
    margin: '3px auto',
    color: 'var(--color-light-label)',
    whiteSpace: 'nowrap'
  },
  valueChange: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center'
  },
  partnerTitle: {
    color: 'var(--color-text)',
    fontSize: 'calc(1.5rem + 0.2vw)',
    lineHeight: '1.5'
  }
};

export const nestedMenuCSS = {
  wrap: {
    position: 'relative',
    minWidth: '130px',
    maxWidth: '300px',
    flexGrow: '1'
  },
  mainmenuWrap: {
    display: 'none',
    zIndex: '10',
    margin: '0',
    padding: '0 0 30px 0',
    position: 'absolute',
    top: '54px',
    left: '0',
    overflow: 'hidden',
    background: 'var(--color-bg)',
    ...transition()
  },
  inverted: {
    top: 'auto',
    bottom: '0'
  },
  fullHeight: {
    position: 'fixed',
    top: 'calc(0px + var(--height-header)',
    bottom: '5px',
    left: 'inherit',
    maxHeight: 'calc(100vh - 10px)'
  },
  innerScroll: {
    maxHeight: '370px',
    width: '100%',
    overflowX: 'hidden',
    overFlowY: 'scroll'
  },
  inputBox: {
    cursor: 'pointer',
    fontSize: '1.2rem',
    height: '30px',
    lineHeight: '1',
    paddingRight: '30px',
    backgroundImage: listMore.src_color,
    backgroundPosition: 'right 5px center',
    backgroundRepeat: 'no-repeat',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  menuOpen: {
    display: 'block',
    width: '300px',
    maxHeight: '400px',
    border: '1px solid var(--color-data-border)',
    ...boxshadow()
  },
  ulMain: {
    float: 'right',
    width: '300px',
    overflowX: 'hidden'
  },
  ulMainMin: {
    position: 'absolute',
    width: '0',
    height: '0',
    overflowX: 'hidden'
  },
  ulSub: {
    width: '0',
    height: '0',
    float: 'right',
    maxHeight: '370px',
    overflowX: 'hidden',
    overFlowY: 'auto',
    background: 'var(--color-bg)',
    ...boxshadow(),
    ...transition()
  },
  subActive: {
    width: '300px',
    height: 'auto'
  },
  backToTop: {
    fontWeight: 'bold',
    paddingLeft: '25px',
    fontSize: '1.2rem',
    height: '30px',
    lineHeight: '1.7',
    backgroundImage: backToTop.src_grey,
    backgroundPosition: 'left 5px center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '15px',
    cursor: 'pointer',
    borderBottom: '1px solid var(--color-data-border)',
    outline: 'none'
  },
  backOne: {
    fontWeight: 'bold',
    paddingLeft: '25px',
    fontSize: '1.2rem',
    minHeight: '30px',
    lineHeight: '1.7',
    backgroundImage: arrowLeft.src_grey,
    backgroundPosition: 'left 5px center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '15px',
    cursor: 'pointer',
    borderBottom: '1px solid var(--color-data-border)',
    outline: 'none'
  },
  ul: {
    margin: '0',
    padding: '0'
  },
  li: {
    clear: 'both',
    fontSize: '1.4rem',
    lineHeight: '1.4',
    overflow: 'hidden'
  },
  liLink: {
    padding: '5px',
    display: 'block',
    cursor: 'pointer',
    overflow: 'hidden',
    whiteSpace: 'no-wrap',
    textOverflow: 'ellipsis',
    outline: 'none',
    ...noselect
  },
  liLinkWithSub: {
    cursor: 'default',
    float: 'left',
    width: 'calc(100% - 90px)',
    outline: 'none',
    paddingLeft: '25px',
    backgroundImage: icons.user().src_color,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left 5px top 6px',
    backgroundSize: '15px'
  },
  explore: {
    cursor: 'pointer',
    borderLeft: '1px solid var(--color-data-border)',
    fontSize: '1.1rem',
    display: 'block',
    width: '90px',
    float: 'left',
    padding: '1px 5px',
    margin: '2px 0',
    backgroundImage: arrowRight.src_color,
    backgroundPosition: 'right 5px center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '15%',
    outline: 'none'
  },
  active: {
    color: 'white',
    cursor: 'default',
    backgroundColor: 'hsl(var(--color-secondary-hue), var(--color-secondary-saturation), calc(var(--color-secondary-lightness) + 12%))'
  },
  hover: {
    backgroundColor: 'hsl(var(--color-secondary-hue), calc(var(--color-secondary-saturation) - 49%), calc(var(--color-secondary-lightness) + 59%))'
  },
  submenuHover: {
    backgroundColor: 'var(--color-menu-hover)'
  },
  exploreHover: {
    color: 'white',
    backgroundColor: 'var(--color-primary)',
    backgroundImage: arrowRight.src_white
  },
  cancelBox: {
    position: 'absolute',
    bottom: '0',
    left: '0',
    right: '0',
    width: '100%',
    height: '30px',
    backgroundColor: 'var(--color-data-border)'
  },
  cancelLink: {
    position: 'absolute',
    left: '5px',
    top: '36px'
  },
  clearSearch: {
    height: '22px',
    lineHeight: '22px',
    position: 'absolute',
    right: '5px',
    top: '6px',
    padding: '0'
  },
  noItems: {
    fontSize: '1.4rem',
    lineHeight: '1.4',
    padding: '5px'
  }
};

export const modalCSS = {
  outerWrap: {
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    margin: '0',
    height: '100%',
    background: 'white',
    padding: '0px',
    boxSizing: 'border-box',
    zIndex: '15',
    overflowY: 'auto',
    opacity: '0',
    display: 'none',
    ...transition()
  },
  innerWrap: {
    padding: '0 10px 10px',
    boxSizing: 'border-box',
    minHeight: '100%',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    zIndex: '12',
    opacity: '1',
    background: 'var(--color-bg)',
    border: '1px solid var(--color-data-border)',
    ...boxshadow(0, 0, '5px'),
    ...borderRadius(),
    ...transition()
  },
  header: {
    borderBottom: '1px solid var(--color-hr)',
    minHeight: '62px',
    lineHeight: '62px',
    fontWeight: 'bold',
    paddingTop: '5px',
    position: 'relative',
    width: '100%'
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 -10px -5px'
  },
  close: {
    cursor: 'pointer',
    width: '42px',
    height: '42px',
    padding: '6px',
    zIndex: '10',
    position: 'absolute',
    top: '10px',
    right: '10px',
    maskSize: '70%',
    outline: 'none',
    ...borderRadius()
  },
  active: {
    opacity: '1',
    display: 'block'
  }
};

export const tabContentCSS = {
  tabContent: {
    clear: 'both',
    display: 'block',
    padding: '10px'
  },
  section: {
    display: 'flex',
    flexDirection: 'column'
  }
};

export const pageHeaderCSS = {
  wrap: {
    display: 'flex',
    maxWidth: '100vw',
    flexDirection: 'column',
    position: 'static',
    marginBottom: '20px'
  },

  titleWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-end',
    padding: '25px 10px ',
    justifyContent: 'space-between'
  },
  title: {
    padding: 0,
    margin: 0
  },
  heading: {
    display: 'flex',
    alignItems: 'center'
  },
  addButtonWrapper: {
    padding: 0,
    lineHeight: 1.5
  },
  childrenWrapper: {
    display: 'flex',
    flexDirection: 'column'
  }
};

export const pageContentCSS = {
  base: {
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '20px 10px 40px'
  },
  databoxWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  }
};

export const dropzoneCSS = {
  wrap: {
    flex: '1',
    display: 'flex',
    flexWrap: 'wrap'
  },
  box: {
    height: '200px',
    minWidth: '220px',
    backgroundColor: 'var(--color-bg)',
    border: '2px dashed var(--color-hr)',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '16px',
    ...borderRadius()
  },
  icon: {
    opacity: '0.3',
    height: '64px',
    width: '64px'
  },
  fileInput: {
    display: 'none'
  },
  highlight: {
    backgroundColor: 'rgb(188, 185, 236)'
  },
  wrapSmall: {
    flex: 'unset',
    width: '100%',
    height: '0',
    boxSizing: 'border-box',
    paddingTop: 'calc(100% - 4px)',
    position: 'relative',
    borderColor: 'var(--color-hr)',
    borderStyle: 'solid',
    borderWidth: '1px',
    backgroundColor: `hsl(var(--color-hr-hue), var(--color-hr-saturation), calc(var(--color-hr-lightness) + 5%))`,
    ...borderRadius()
  },
  boxSmall: {
    minWidth: 'unset',
    flex: 'unset',
    height: 'inherit',
    width: 'inherit',
    backgroundColor: 'unset',
    borderColor: 'none',
    borderRadius: 'unset',
    borderWidth: '0',
    borderStyle: 'none',
    marginBottom: 'calc(100% * -1)'
  },
  textSmall: {
    position: 'absolute',
    left: '0',
    top: '0',
    width: '100%',
    height: '100%',
    fontSize: '1.2em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'var(--color-light-label)'
  }
};

export const dropzoneFileListCSS = {
  wrapperSmall: {
    margin: '5px 0'
  },
  contentWrapperSmall: {
    paddingBottom: 'unset',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  fileNameWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  fileNameSmall: {
    paddingRight: '0',
    fontSize: '1.3rem'
  },
  fileRowWrapperSmall: {
    paddingLeft: '5px',
    marginLeft: '5px',
    backgroundColor: 'hsl(var(--color-primary-hue), var(--color-primary-saturation), calc(var(--color-primary-lightness) + 40%))',
    flex: 'unset',
    flexDirection: 'row',
    paddingRight: '0',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: '3px',
    height: 'auto',
    lineHeight: '2',
    ...borderRadius()
  },
  titleSmall: {
    paddingLeft: '0',
    fontWeight: 'bold',
    marginBottom: '3px'
  },
  deleteItemWrapper: {
    zIndex: '2',
    cursor: 'pointer',
    ...icons.base24,
    backgroundColor: 'transparent',
    height: '1.2em',
    width: '1.2em'
  },
  deleteItemIcon: deleteItem()
};

export const uploadCSS = {
  wrap: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: '1',
    alignItems: 'flex-start',
    textAlign: 'left',
    overflow: 'hidden',
    position: 'relative',
    height: 'fit-content'
  },
  title: {
    marginBottom: '32px',
    color: '#555',
    flex: '100%'
  },
  content: {
    flex: '1',
    boxSizing: 'border-box',
    width: '100%',
    minWidth: '220px',
    position: 'relative',
    paddingBottom: '40px'
  },
  files: {
    width: '100%',
    overflowY: 'auto'
  },
  actions: {
    position: 'absolute',
    bottom: '0',
    left: '0',
    width: '100%'
  },
  filename: {
    zIndex: '1',
    fontSize: '1.4rem',
    color: '#555',
    paddingRight: '34px'
  },
  entryTitle: {
    fontWeight: 'bold',
    paddingLeft: '10px'
  },
  row: {
    paddingLeft: '10px',
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '32px',
    overflow: 'hidden',
    boxSizing: 'border-box'
  },
  odd: {
    background: 'var(--color-data-border)'
  }
};

export const autosaveBox = {
  wrap: {
    width: '180px',
    height: '100%',
    display: 'flex'
  }
};

export const hideShowButtonCSS = {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  margin: '0 auto'
};

export const defaultTransitionCSS = {
  transition: `opacity 500ms ease-in-out, transform 500ms ease-in-out`,
  opacity: 0,
  transform: 'scaleX(0)',
  transformOrigin: 'left center'
};

export const transitionGroupStateCSS = {
  entering: { opacity: 1, transform: 'scaleX(1)' },
  entered: { opacity: 1, transform: 'scaleX(1)', zIndex: '2' },
  exiting: { opacity: 0 },
  exited: {
    opacity: 0,
    transform: 'scaleX(0)',
    position: 'absolute'
  }
};

export const gojiCSS = {
  bg: {
    background: 'hsl(var(--color-secondary-hue), var(--color-secondary-saturation), calc(var(--color-secondary-lightness) + 30%))',
    padding: '20px 0 0',
    position: 'relative'
  },
  wrap: {
    margin: '0 auto',
    maxWidth: '1200px'
  },
  graphic: {
    width: '100%',
    paddingBottom: '40%',
    margin: '0 auto',
    position: 'relative'
  },
  rubble: {
    position: 'absolute',
    bottom: '0',
    left: '0',
    width: '100%'
  },
  signPost: {
    position: 'absolute',
    width: '15%',
    paddingBottom: '21.2%',
    bottom: '0',
    left: '15%',
    MozTransform: 'rotate(15deg)',
    WebkitTransform: 'rotate(15deg)',
    OTransform: 'rotate(15deg)',
    MsTransform: 'rotate(15deg)',
    transform: 'rotate(15deg)'
  },
  signText: {
    position: 'absolute',
    width: '100%',
    height: '42%',
    textAlign: 'center',
    paddingTop: '10%',
    fontSize: '10px',
    fontWeight: 'bold',
    lineHeight: '1'
  }
};

export const dinoDabCSS = {
  bg: {
    background: '#052e2a',
    padding: '0',
    position: 'relative'
  },
  wrap: {
    margin: '0 auto',
    maxWidth: '1200px'
  },
  graphic: {
    ...dinoDab,
    height: '100%',
    paddingBottom: '40%',
    margin: '0 auto',
    position: 'relative'
  }
};

export const customApiError = {
  image: {
    backgroundImage: icons.hazard.src_grey,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    position: 'center',
    height: '120px',
    width: '100%'
  },
  code: {
    color: 'var(--color-light-label)',
    textAlign: 'center',
    margin: '10px',
    fontSize: '1.4rem',
    lineHeight: '1.5'
  }
};

export const dataBox = {
  box: {
    flex: '1',
    margin: '5px',
    minWidth: '300px',
    padding: '20px',
    border: '2px solid transparent',
    background: 'var(--color-databox-bg)',
    position: 'relative',
    ...borderRadius('var(--radius-large)')
  },
  title: {
    color: 'var(--color-text)',
    fontWeight: 'bold',
    fontSize: '1.4rem',
    marginBottom: '10px'
  },
  subtitle: {
    color: 'var(--color-light-label)',
    paddingLeft: '10px',
    fontWeight: '100',
    fontSize: '1.4rem'
  },
  bigTitle: {
    color: 'var(--color-text)',
    fontSize: '4rem',
    fontWeight: '100',
    lineHeight: '1'
  },
  subText: {
    fontWeight: '100',
    fontStyle: 'italic',
    fontSize: '1.1rem',
    lineHeight: '1.5'
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

export const notesCSS = {
  stickyNote: {
    color: 'var(--color-text)',
    padding: '0 1em',
    fontSize: '0.9em'
  },
  stickyDate: {
    backgroundColor: 'var(--color-note-header)',
    border: '1px solid var(--color-hr)',
    fontWeight: '800',
    padding: '0.2em 0.5em 0 0.5em',
    width: 'max-content',
    lineHeight: '1.7em',
    ...borderRadius()
  },
  stickyArrowContainer: {
    borderLeftWidth: '1px',
    borderLeftStyle: 'solid',
    borderLeftColor: 'var(--color-hr)',
    margin: '0 0 0 1.5em',
    padding: '0.6em 0 0.6em 1em',
    position: 'relative'
  },
  stickyArrow: {
    color: 'var(--color-hr)',
    position: 'absolute',
    left: '-0.5em',
    top: '-0.75em'
  },
  stickyDescription: {
    backgroundColor: 'var(--color-bg-alt)',
    border: '1px solid var(--color-hr)',
    fontSize: '0.9em',
    padding: '0 0.5em',
    lineHeight: '1.7em',
    wordBreak: 'break-word',
    ...borderRadius()
  },
  showMore: {
    fontSize: '0.9em',
    display: 'flex',
    justifyContent: 'space-evenly'
  }
};

export const sidebarCSS = {
  wrapper: {
    height: '100%',
    position: 'fixed',
    zIndex: '99',
    top: '0',
    transition: 'background-color .25s ease-in-out'
  },
  contentWrapper: {
    height: '100%',
    position: 'absolute',
    zIndex: '10',
    top: '0',
    backgroundColor: 'var(--color-sidebar-bg)',
    transition: '0.4s',
    boxShadow: 'var(--sidebar-shadow)',
    display: 'flex',
    flexDirection: 'column'
  },
  close: {
    cursor: 'pointer',
    width: '42px',
    height: '42px',
    zIndex: '10',
    backgroundImage: icons.close.src_color,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '70%',
    outline: 'none',
    position: 'absolute',
    ...borderRadius()
  },
  backWrap: {
    paddingTop: '.5rem',
    zIndex: '10',
    cursor: 'pointer',
    color: 'var(--color-primary)',
    display: 'inline-flex',
    alignItems: 'center'
  },
  back: {
    width: '35px',
    height: '35px',
    backgroundImage: icons.arrowLeft.src_color,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '70%',
    outline: 'none'
  },
  content: {
    padding: '10px'
  },
  contentAnimation: {
    animation: 'slide 1s forwards'
  }
};

export const multiSelect = {
  container: {
    display: 'flex',
    flexFlow: 'column wrap',
    position: 'relative'
  },
  labelLeft: {
    marginTop: '0',
    marginBottom: '0',
    marginLeft: '0',
    marginRight: '0'
  },
  labelRight: {
    minHeight: '14px',
    lineHeight: '20px',
    marginTop: '5px',
    marginBottom: '5px',
    marginLeft: '0',
    marginRight: '0'
  },
  wrap: {
    userSelect: 'none',
    position: 'relative',
    marginLeft: '1px',
    marginRight: '1px',
    marginTop: '0',
    marginBottom: '0',
    overflow: 'visible',
    outline: 'none',
    display: 'flex',
    flexDirection: 'column'
  },
  innerWrap: {
    position: 'relative',
    borderColor: '#000000',
    borderWidth: '1px',
    borderStyle: 'solid',
    backgroundColor: 'white'
  },
  header: {
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    lineHeight: '38px',
    border: `1px solid var(--color-hr)`,
    cursor: 'pointer',
    position: 'relative',
    backgroundColor: 'var(--color-bg)',
    ...borderRadius()
  },
  innerHeader: {
    display: 'block',
    fontSize: '1.6rem',
    color: 'rgb(45, 41, 38)',
    padding: '0',
    width: '100%',
    marginTop: '0',
    marginBottom: '0',
    marginLeft: '0',
    marginRight: '0',
    WebkitAppearance: 'textfield',
    backgroundImage: 'inherit',
    outline: 'none'
  },
  filtered: {
    border: '1px solid blue'
  },
  title: {
    fontWeight: '300',
    margin: '2px 0px 2px 10px',
    whiteSpace: 'nowrap',
    outline: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minWidth: '24px'
  },
  list: {
    marginTop: '0',
    marginBottom: '0',
    marginLeft: '0',
    marginRight: '0',
    zIndex: '10',
    width: '100%',
    border: `1px solid var(--color-hr)`,
    ...borderRadius(0),
    backgroundColor: 'var(--color-whiteish)',
    boxShadow: `0 2px 5px -1px var(--color-hr)`,
    padding: '15px 0',
    overflowY: 'scroll',
    overflowScrolling: 'touch'
  },
  listInverted: {
    WebkitBorderTopRightRadius: '4px',
    MozBorderTopRightRadius: '4px',
    borderTopRightRadius: '4px',
    WebkitBorderTopLeftRadius: '4px',
    MpzBorderTopLeftRadius: '4px',
    borderTopLeftRadius: '4px'
  },
  inverted: {
    top: 'auto',
    bottom: '0'
  },
  item: {
    width: '100%',
    fontSize: '1.5rem',
    padding: '8px 10px',
    lineHeight: '1.6rem',
    cursor: 'pointer',
    overflow: 'hidden',
    display: 'block',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    outline: 'none'
  },
  item_selected: {
    color: 'var(--color-whiteish)',
    fontWeight: '700',
    backgroundColor: 'hsl(var(--color-secondary-hue), var(--color-secondary-saturation), calc(var(--color-secondary-lightness) + 12%))'
  },
  item_hover: {
    backgroundColor: 'hsl(var(--color-secondary-hue), calc(var(--color-secondary-saturation) - 49%), calc(var(--color-secondary-lightness) + 59%))'
  },
  listWrapper: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '51px',
    minWidth: 'inherit',
    width: '100%'
  },
  bottomBar: {
    zIndex: '10',
    width: '100%',
    border: `1px solid var(--color-hr)`,
    ...borderRadius(0, 0, 'var(--radius-main)', 'var(--radius-main)'),
    boxShadow: `0 2px 5px -1px var(--color-hr)`,
    overflowScrolling: 'touch',
    padding: '0',
    borderTop: '0',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    overflowY: 'unset',
    backgroundColor: 'var(--color-data-border)',
    height: '100%',
    margin: 'auto 0'
  },
  bottomBarInverted: {
    boxShadow: 'none',
    ...borderRadius(0)
  },
  menuSearchWrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
    height: 'inherit',
    alignItems: 'center',
    flex: '1',
    margin: '0 5px 0 15px'
  },
  menuSearchNoResults: {
    paddingLeft: '18px',
    fontSize: '1.2rem',
    lineHeight: '1.3'
  },
  clearSearch: {
    padding: '0',
    margin: '0',
    fontSize: '1rem',
    lineHeight: '1.5'
  }
};

export const checkboxListStyle = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    border: '1px solid black',
    backgroundColor: 'white'
  },
  labelLeft: {
    marginTop: '0',
    marginBottom: '0',
    marginLeft: '0',
    marginRight: '0',
    paddingLeft: '4px'
  },
  labelRight: {
    minHeight: '14px',
    lineHeight: '20px',
    marginTop: '5px',
    marginBottom: '5px',
    marginLeft: '0',
    marginRight: '0'
  },
  wrap: {
    userSelect: 'none',
    position: 'relative',
    marginLeft: '1px',
    marginRight: '1px',
    marginTop: '0',
    marginBottom: '0',
    overflow: 'visible',
    outline: 'none',
    display: 'flex',
    flexDirection: 'column'
  },
  innerWrap: {
    position: 'relative',
    border: '1px solid black',
    backgroundColor: 'white'
  },
  header: {
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    lineHeight: '38px',
    border: `1px solid var(--color-hr)`,
    cursor: 'pointer',
    position: 'relative',
    backgroundColor: 'var(--color-bg)',
    ...borderRadius()
  },
  innerHeader: {
    display: 'block',
    fontSize: '1.6rem',
    color: 'rgb(45, 41, 38)',
    padding: '0',
    width: '100%',
    marginTop: '0',
    marginBottom: '0',
    marginLeft: '0',
    marginRight: '0',
    WebkitAppearance: 'textfield',
    backgroundImage: 'inherit',
    outline: 'none'
  },
  filtered: {
    border: '1px solid blue'
  },
  title: {
    fontWeight: '300',
    margin: '2px 0px 2px 10px',
    whiteSpace: 'nowrap',
    outline: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minWidth: '24px'
  },
  list: {
    marginTop: '0',
    marginBottom: '0',
    marginLeft: '0',
    marginRight: '0',
    zIndex: '10',
    width: '100%',
    border: `1px solid var(--color-hr)`,
    ...borderRadius(0),
    backgroundColor: 'var(--color-whiteish)',
    boxShadow: `0 2px 5px -1px var(--color-hr)`,
    padding: '15px 0',
    overflowY: 'scroll',
    overflowScrolling: 'touch'
  },
  listInverted: {
    WebkitBorderTopRightRadius: '4px',
    MozBorderTopRightRadius: '4px',
    borderTopRightRadius: '4px',
    WebkitBorderTopLeftRadius: '4px',
    MpzBorderTopLeftRadius: '4px',
    borderTopLeftRadius: '4px'
  },
  inverted: {
    top: 'auto',
    bottom: '0'
  },
  listWrapper: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '51px',
    minWidth: 'inherit',
    width: '100%'
  }
};

export const noDataCSS = {
  wrap: {},
  message: {
    color: 'var(--color-text)'
  },
  successMessage: { color: 'var(--color-success)' },
  errorMessage: { color: 'var(--color-warning)' },
  details: {
    color: 'var(--color-light-label)'
  }
};

export const filterBarCSS = {
  wrapper: {
    border: 0,
    width: 'auto',
    backgroundColor: 'var(--color-filter-bar)',
    ...borderRadius(),
    padding: '10px',
    marginBottom: '10px'
  },
  filters: {
    display: 'flex',
    gap: '5px',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  dropdown: {
    minWidth: '150px',
    width: 'auto',
    flex: '1'
  },
  multiSelect: {
    flex: '1',
    minWidth: '240px'
  },
  searchInput: {
    minWidth: '150px',
    width: 'auto',
    flex: '1',
    marginLeft: 'auto'
  },
  searchIcon: magnify,
  clearButton: {
    fontSize: '1.1rem',
    lineHeight: '1.5',
    padding: '2px',
    margin: '0 5px',
    alignSelf: 'flex-end'
  }
};

export const inlineFilesFormSectionCSS = {
  contentWrapper: {
    borderColor: 'black',
    borderWidth: '1px',
    borderStyle: 'solid',
    margin: '-1px',
    flex: '100%',
    position: 'relative'
  },
  requiredSection: {
    display: 'flex',
    flexDirection: 'column',
    margin: '5px'
  },
  requiredRow: {
    minHeight: '30px',
    position: 'relative',
    padding: '0 5px',
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '1.2rem'
  },
  requiredRowEven: {
    backgroundColor: 'hsl(calc(var(--color-grey-hue) - 210), calc(var(--color-grey-saturation) - 2%), calc(var(--color-grey-lightness) + 10%))'
  },
  dropzoneFileListWrapper: {
    flex: 'auto',
    height: 'fit-content'
  },
  dropzoneWrapper: {
    display: 'flex',
    margin: '5px'
  },
  dropzone: {
    dropBoxWrapper: {
      margin: '0'
    },
    dropAreaText: {
      fontSize: '1.4rem',
      lineHeight: '1.5',
      padding: '0 3px'
    },
    wrapper: {
      marginRight: '5px',
      flex: 'unset',
      flexDirection: 'column'
    }
  },
  dropzoneFileList: {
    contentWrapper: {
      paddingBottom: '0'
    },
    fileName: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '1.2rem',
      paddingRight: '0'
    },
    fileRowWrapper: {
      padding: '5px',
      height: 'unset',
      overflow: 'unset',
      justifyContent: 'unset'
    }
  },
  setFileNameWrapper: {
    display: 'flex',
    flex: '1',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px'
  },
  setFileTypeWrapper: {
    display: 'flex',
    flex: '1',
    justifyContent: 'end'
  },
  setFileTypeDropdown: {
    width: '200px'
  },
  setFileTypeButton: {
    height: 'fit-content',
    alignSelf: 'flex-end',
    paddingLeft: '5px',
    paddingRight: '5px',
    fontSize: '1.4rem',
    lineHeight: '1.5'
  },
  fileReqWrapper: {
    flex: '100%',
    fontSize: '1.3rem',
    margin: '5px'
  },
  fileReqUl: {
    paddingLeft: '25px',
    margin: '5px 0'
  },
  fileReqLi: {
    lineHeight: '1.5'
  }
};

export const filesWithTagsCSS = {
  imageFileName: {
    maxWidth: 'calc(100% - 23px)',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    position: 'absolute',
    top: 2,
    left: 2,
    color: 'var(--color-whiteish)',
    lineHeight: 1.5,
    fontSize: '0.7em',
    backgroundColor: 'rgb(0 0 0 / 50%)',
    borderRadius: 'var(--radius-small)',
    padding: '0 2px'
  },
  tagsActionsWrapper: {
    position: 'absolute',
    bottom: 2,
    left: 2,
    right: 2,
    width: 'calc(100% - 4px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  fileRow: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    overflow: 'hidden'
  },
  fileLink: {
    fontSize: '1.3rem',
    marginRight: '0.3em',
    display: 'flex',
    alignItems: 'center'
  }
};
export const filesTagListCSS = {
  tagList: {
    width: '100%',
    display: 'flex',
    gap: '1px'
  },
  tagItem: {
    color: 'hsl(0, 0%, 20%)',
    fontSize: '0.7em',
    lineHeight: 1.5,
    backgroundColor: 'hsl(0, 0%, 90%)',
    padding: '1.5px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    ...borderRadius()
  },
  viewTagsWrapper: {
    margin: '1em',
    fontSize: '1.3rem',
    display: 'flex',
    flexDirection: 'column'
  }
};
export const filesActionBarCSS = {
  imageActionBar: {
    height: '20px',
    lineHeight: 1.5,
    display: 'flex',
    backgroundColor: 'var(--color-stone)',
    padding: '0 0 0 3px',
    position: 'absolute',
    right: 0,
    bottom: 0,
    ...borderRadius()
  },
  actionIcon: {
    marginRight: '0.1em',
    height: '13px',
    width: '13px'
  }
};
export const fileFinderCSS = {
  fileFinder: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    width: '100%'
  },
  pathBar: {
    height: '32px',
    display: 'flex',
    flex: '0 1 auto',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  pathBarButton: {
    fontSize: '1.2rem',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '0',
    paddingRight: '0',
    paddingTop: '0',
    paddingBottom: '0'
  },
  columns: {
    display: 'flex',
    flexFlow: 'row nowrap',
    width: '100%',
    padding: '0 0 10px 0',
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  },
  columnWrapper: {
    background: 'var(--color-databox-bg)',
    borderRadius: 'var(--radius-main)',
    padding: '0.4em',
    height: '100%',
    position: 'relative'
  },
  column: {
    width: 'calc(100% - 0.6em)',
    height: 'calc(100% - 0.6em)',
    position: 'absolute',
    overflow: 'auto'
  },
  lastColumnOnlyFiles: {
    top: '0',
    left: '0',
    padding: '0.2em',
    background: 'var(--color-databox-bg)',
    borderRadius: 'var(--radius-main)',
    width: 'inherit',
    height: '100%'
  },
  rowWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '30px',
    width: 'calc(100% - 0.2em)',
    position: 'relative'
  },
  rowFolderButton: {
    fontSize: '1.3rem',
    height: 'auto',
    padding: '0.6em',
    width: 'calc(100% - 0.8em)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  rowPathName: {
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  fileRowWrapper: {
    fontSize: '1.3rem',
    height: '30px',
    padding: '0.6em',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  fileRowLink: {
    color: 'var(--color-secondary)',
    display: 'flex',
    alignItems: 'center',
    height: 'inherit',
    width: '100%'
  },
  fileRowName: {
    fontSize: '1.3rem',
    flexGrow: '1',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
};

export const supportDataTableFilter = {
  wrap: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '0 10px',
    border: 0,
    width: 'auto',
    backgroundColor: 'var(--color-filter-bar)',
    borderRadius: 'var(--radius-main)',
    borderTop: '1px solid var(--color-data-border)',
    borderBottom: '1px solid var(--color-data-border)',
    fontSize: '1.3rem',
    margin: '10px 0'
  },
  filters: {
    flex: '1',
    display: 'flex',
    alignItems: 'center',
    margin: '5px 0'
  }
};
