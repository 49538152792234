import { merchantGuids } from '../guids';

const mockLookupBySecondaryFieldGetRes = {
  merchantGuidList: [
    merchantGuids.default,
    merchantGuids.closed,
    merchantGuids.merchant01,
    merchantGuids.merchant03
  ]
};

export default mockLookupBySecondaryFieldGetRes;
