import { riskCategoryGuids } from '../../../../../guids';

let startDate = new Date();
const resetStartDate = () => {
  startDate = new Date();
  startDate.setDate(startDate.getDate() - 14);
  return startDate.toLocaleDateString();
};
const incrementDate = () => {
  startDate.setDate(startDate.getDate() + 1);
  return startDate.toLocaleDateString();
};

export const mockRiskSubcategoryMetricGet = {
  [riskCategoryGuids.default]: {
    header: {
      label: 'day',
      lines: ['total'],
      biaxial: ['total'],
      currency: null,
      biaxialCurrency: null
    },
    data: [
      {
        total: 4,
        CrV2GetRiskSubCatgMetricFTRCID2V1: 3,
        CrV2GetRiskSubCatgMetricFTRCID1V1subCategory: 1,
        day: resetStartDate()
      },
      {
        total: 0,
        CrV2GetRiskSubCatgMetricFTRCID2V1: 0,
        CrV2GetRiskSubCatgMetricFTRCID1V1subCategory: 0,
        day: incrementDate()
      },
      {
        total: 5,
        CrV2GetRiskSubCatgMetricFTRCID2V1: 2,
        CrV2GetRiskSubCatgMetricFTRCID1V1subCategory: 3,
        day: incrementDate()
      },
      {
        total: 0,
        CrV2GetRiskSubCatgMetricFTRCID2V1: 0,
        CrV2GetRiskSubCatgMetricFTRCID1V1subCategory: 0,
        day: incrementDate()
      },
      {
        total: 0,
        CrV2GetRiskSubCatgMetricFTRCID2V1: 0,
        CrV2GetRiskSubCatgMetricFTRCID1V1subCategory: 0,
        day: incrementDate()
      },
      {
        total: 0,
        CrV2GetRiskSubCatgMetricFTRCID2V1: 0,
        CrV2GetRiskSubCatgMetricFTRCID1V1subCategory: 0,
        day: incrementDate()
      },
      {
        total: 0,
        CrV2GetRiskSubCatgMetricFTRCID2V1: 0,
        CrV2GetRiskSubCatgMetricFTRCID1V1subCategory: 0,
        day: incrementDate()
      },
      {
        total: 0,
        CrV2GetRiskSubCatgMetricFTRCID2V1: 0,
        CrV2GetRiskSubCatgMetricFTRCID1V1subCategory: 0,
        day: incrementDate()
      },
      {
        total: 0,
        CrV2GetRiskSubCatgMetricFTRCID2V1: 0,
        CrV2GetRiskSubCatgMetricFTRCID1V1subCategory: 0,
        day: incrementDate()
      },
      {
        total: 0,
        CrV2GetRiskSubCatgMetricFTRCID2V1: 0,
        CrV2GetRiskSubCatgMetricFTRCID1V1subCategory: 0,
        day: incrementDate()
      },
      {
        total: 0,
        CrV2GetRiskSubCatgMetricFTRCID2V1: 0,
        CrV2GetRiskSubCatgMetricFTRCID1V1subCategory: 0,
        day: incrementDate()
      },
      {
        total: 0,
        CrV2GetRiskSubCatgMetricFTRCID2V1: 0,
        CrV2GetRiskSubCatgMetricFTRCID1V1subCategory: 0,
        day: incrementDate()
      },
      {
        total: 0,
        CrV2GetRiskSubCatgMetricFTRCID2V1: 0,
        CrV2GetRiskSubCatgMetricFTRCID1V1subCategory: 0,
        day: incrementDate()
      },
      {
        total: 2,
        CrV2GetRiskSubCatgMetricFTRCID2V1: 0,
        CrV2GetRiskSubCatgMetricFTRCID1V1subCategory: 2,
        day: incrementDate()
      }
    ]
  },
  [riskCategoryGuids.riskCategory01]: {
    header: {
      label: 'day',
      lines: ['total'],
      biaxial: ['total'],
      currency: null,
      biaxialCurrency: null
    },
    data: [
      {
        total: 4,
        Subcategory1: 3,
        Subcategory2: 1,
        day: resetStartDate()
      },
      {
        total: 0,
        Subcategory1: 0,
        Subcategory2: 0,
        day: incrementDate()
      },
      {
        total: 0,
        Subcategory1: 0,
        Subcategory2: 0,
        day: incrementDate()
      },
      {
        total: 0,
        Subcategory1: 0,
        Subcategory2: 0,
        day: incrementDate()
      },
      {
        total: 0,
        Subcategory1: 0,
        Subcategory2: 0,
        day: incrementDate()
      },
      {
        total: 0,
        Subcategory1: 0,
        Subcategory2: 0,
        day: incrementDate()
      },
      {
        total: 0,
        Subcategory1: 0,
        Subcategory2: 0,
        day: incrementDate()
      },
      {
        total: 0,
        Subcategory1: 0,
        Subcategory2: 0,
        day: incrementDate()
      },
      {
        total: 0,
        Subcategory1: 0,
        Subcategory2: 0,
        day: incrementDate()
      },
      {
        total: 0,
        Subcategory1: 0,
        Subcategory2: 0,
        day: incrementDate()
      },
      {
        total: 0,
        Subcategory1: 0,
        Subcategory2: 0,
        day: incrementDate()
      },
      {
        total: 0,
        Subcategory1: 0,
        Subcategory2: 0,
        day: incrementDate()
      },
      {
        total: 0,
        Subcategory1: 0,
        Subcategory2: 0,
        day: incrementDate()
      },
      {
        total: 0,
        Subcategory1: 0,
        Subcategory2: 0,
        day: incrementDate()
      }
    ]
  }
};

export default mockRiskSubcategoryMetricGet;
