import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from './_helpers';
import { input } from './_styles';
import { Input } from './Input';
import { ToolTip } from './ToolTip';
import { Button } from './Button';
import { ErrorBox } from '../css/_styledFormComponents';
import validations, { allowedDates } from './_validations';

class InputTimestamp extends React.Component {
  constructor (props) {
    super(props);
    this.mounted = false;
    this.state = {
      dateInput: '',
      timeInput: '',
      dateInputIsValid: false,
      timeInputIsValid: false,
      disableTimeInput: false, // when date is empty or invalid
      showUpdateButton: false,
      showError: false
    };
  }

  componentDidMount () {
    this.mounted = true;
    this.prefillTimestamp();
  }

  componentDidUpdate (prevProps, prevState) {
    const { noValidate, required, value } = this.props;
    const { dateInput, timeInput } = this.state;
    if (value !== prevProps.value && (isEmpty(dateInput) || isEmpty(timeInput))) {
      this.prefillTimestamp();
    } else if (value !== prevProps.value && isEmpty(value)) {
      // incoming value is empty (clearing the field)
      this.updateState({
        dateInput: '',
        dateInputIsValid: noValidate || (!required && isEmpty(value)) || false,
        disableTimeInput: true,
        timeInput: '',
        timeInputIsValid: false,
        showError: noValidate ? false : required
      });
    }
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  updateState = (state, callback = null) => {
    this.mounted && this.setState(state, callback);
  }

  prefillTimestamp = () => {
    const { useLocal, value } = this.props;
    const timestampValid = RegExp(/^\d{4}-?\d{2}-?\d{2}T\d{2}:?\d{2}/g).test(value);
    if (timestampValid) {
      const dateOnly = value.split('T')[0];
      const dateObj = new Date(value);
      this.updateState({
        testOnChange: true,
        disableTimeInput: false,
        dateInputIsValid: true,
        timeInputIsValid: true,
        dateInput: dateOnly,
        timeInput: dateObj.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
          timeZone: useLocal ? Intl.DateTimeFormat().resolvedOptions().timeZone : 'America/Chicago'
        })
      });
    } else {
      this.updateState({
        ...(isEmpty(value) && { disableTimeInput: true })
      });
    }
  }

  handleChangeInput = (type, value, valid) => {
    this.updateState(prevState => ({
      [type]: value,
      [`${type}IsValid`]: valid,
      testOnChange: true,
      disableTimeInput: false,
      ...(type === 'dateInput' && (isEmpty(value) || !isEmpty(prevState.timeInput)) && {
        timeInput: '',
        timeInputIsValid: false
      }),
      ...(type === 'timeInput' && isEmpty(prevState.dateInput) && {
        timeInputIsValid: false,
        dateInputIsValid: false
      })
    }), this.handleValidate);
  }

  handleValidate = () => {
    const { autoUpdate, noValidate, required } = this.props;
    const timestampValid = this.isTimestampValid();
    this.updateState((prevState) => {
      const hasBothInputs = !isEmpty(prevState.timeInput) && !isEmpty(prevState.dateInput);
      const hasNoInputs = isEmpty(prevState.timeInput) && isEmpty(prevState.dateInput);
      return {
        ...(!autoUpdate && (hasBothInputs || hasNoInputs) && { showUpdateButton: true }),
        showError: !timestampValid || (prevState.testOnChange && (!hasBothInputs || hasNoInputs)),
        testOnChange: false,
        ...(((!required && hasNoInputs) || noValidate) && { showError: false })
      };
    });
    autoUpdate && this.handleCallback(timestampValid);
  }

  isTimestampValid = () => {
    const { dateInput, timeInput } = this.state;
    const {
      allowToday,
      businessHoursOnly,
      dateType,
      roundMinutes
    } = this.props;
    const dateValidationType = dateType || 'date';
    const validationOptions = {
      allowToday,
      businessHoursOnly,
      date: dateInput,
      roundMinutes
    };
    const dateValid = validations[dateValidationType].test(dateInput, validationOptions);
    const timeType = dateType === 'futureDate' ? 'futureTime' : 'time';
    const timeValid = validations[timeType].test(timeInput, validationOptions);
    return dateValid && timeValid;
  }

  handleClickUpdate = () => {
    const { dateInput, timeInput } = this.state;
    const { autoUpdate, required } = this.props;
    if (!autoUpdate) {
      const emptyTimestamp = isEmpty(dateInput) && isEmpty(timeInput);
      const newValid = (!required && emptyTimestamp) || !emptyTimestamp;
      this.handleCallback(newValid);
    }
  }

  getCallbackValue = (timestampValid, date, time) => {
    const { required } = this.props;
    if (timestampValid) {
      if (!isEmpty(date) && !isEmpty(time)) {
        const dateObj = timestampValid ? new Date(`${date} ${time}`) : '';
        return dateObj.toISOString();
      }
      if (!required && isEmpty(date) && isEmpty(time)) {
        return null;
      }
    }
    // timestamp is not valid, but field has one or both values
    if (!isEmpty(date) || !isEmpty(time)) {
      if (isEmpty(time) && !isEmpty(date)) { return date; }
      if (!isEmpty(time) && isEmpty(date)) { return time; }
      // has both, but one or both are invalid
      const timeValue = !isEmpty(time) ? ` ${time}` : '';
      const dateObj = !isEmpty(date) ? new Date(`${date}${timeValue}`) : '';
      const dateObjString = dateObj.toString().toLowerCase();
      const invalidDateObject = dateObjString === 'invalid date' || dateObjString === 'invalid time value' ||
        dateObjString === 'provided date is not in valid range' || isEmpty(dateObjString);
      return invalidDateObject ? date : dateObj.toISOString();
    }
    return null;
  }

  handleCallback = (timestampValid = false) => {
    const { dateInput, timeInput } = this.state;
    const { id, callback, autoUpdate } = this.props;
    const value = this.getCallbackValue(timestampValid, dateInput, timeInput);
    const options = { date: dateInput, time: timeInput };
    callback && callback(id, value, timestampValid, options);
    this.updateState({ ...(!autoUpdate && { showUpdateButton: false }) });
  }

  render () {
    const {
      id,
      label,
      boxStyle,
      required,
      wrapperStyle,
      childStyles,
      autoUpdate,
      disabled,
      allowToday,
      businessHoursOnly,
      dateErrorMessage,
      dateType,
      roundMinutes,
      timeErrorMessage,
      timeList,
      tooltip,
      customInputStyle,
      useFieldWrapperStyle,
      useTimeValidationMessage,
      weekdaysOnly
    } = this.props;
    const {
      dateInput,
      dateInputIsValid,
      disableTimeInput,
      timeInput,
      timeInputIsValid,
      showUpdateButton,
      showError
    } = this.state;
    const inputStyle = {
      ...(boxStyle === 'inside' && { borderRadius: 0 }),
      borderColor: 'none',
      borderWidth: 0,
      borderStyle: 'none',
      padding: '5px',
      ...customInputStyle
    };
    const inputErrorStyle = {
      ...(boxStyle === 'inside' && { marginBottom: '-2px', marginLeft: '-1px' }),
      backgroundColor: 'var(--color-warning-bg)',
      borderColor: 'var(--color-warning)',
      borderWidth: '1px',
      borderStyle: 'solid',
      color: 'var(--color-warning)',
      zIndex: 2
    };
    const inputValidStyle = {
      backgroundColor: 'var(--color-healthy-bg)'
    };
    const timestampValid = !showError && !isEmpty(timeInput) && !isEmpty(dateInput);
    return (
      <div
        className={id ? (`${id}Field`) : `inputField`}
        style={{
          ...(boxStyle === 'inside'
            ? { ...input.innerWrap, ...(!disabled && timestampValid && { backgroundColor: 'var(--color-healthy-bg)' }) }
            : input.wrap),
          ...wrapperStyle,
          ...(disabled && boxStyle === 'inside' && input.innerWrapDisabled),
          ...(showError && {
            backgroundColor: 'var(--color-warning-bg)',
            borderColor: 'var(--color-warning)',
            borderWidth: '1px',
            borderStyle: 'solid',
            zIndex: 2
          })
        }}
      >
        {label && (
          <label
            style={{
              ...(boxStyle === 'inside' ? { ...input.labelInside, ...(showError && !disabled && { color: 'var(--color-warning)' }) } : input.label),
              ...(!autoUpdate && { padding: '5px' })
            }}
            htmlFor={id}
          >
            {required && (
              <span style={input.label_required}>* </span>
            )}
            {label}
            {tooltip && !isEmpty(tooltip) && (
              <span style={input.timestampTooltip}>
                <ToolTip
                  infoTip
                  infoTipDisplay={{
                    top: '-3px',
                    margin: 0
                  }}
                >
                  {tooltip}
                </ToolTip>
              </span>
            )}
            {!autoUpdate && showUpdateButton && (
              <Button
                id="update-timestamp-button"
                size="sm"
                style={{ float: 'right', margin: '-2px 0 0 0' }}
                onClick={this.handleClickUpdate}
                disabled={showError}
              >
                Update
              </Button>
            )}
          </label>
        )}
        <div style={{
          display: 'flex',
          width: '100%',
          flexWrap: 'wrap',
          ...(boxStyle === 'inside' && !disabled && {
            ...(showError && { color: 'var(--color-warning)' }),
            ...(timestampValid && { color: 'hsl(var(--color-healthy-hue),var(--color-healthy-saturation),calc(var(--color-healthy-lightness) + 10%))' })
          })
        }}
        >
          <Input
            id={`${id}InputTimestampDate`}
            type={dateType}
            allowToday={allowToday}
            {...(dateType === 'futureDate' && allowToday && {
              minDate: allowedDates({ futureDatesOnly: true, allowToday: true }).min,
              maxDate: allowedDates({ futureDatesOnly: true, allowToday: true }).max
            })}
            className={`${id} inputTimestamp`}
            inputStyle={{
              ...inputStyle,
              ...(boxStyle === 'inside' && !disabled && { color: 'inherit', backgroundColor: 'transparent' }),
              ...childStyles.date?.fontSize && { fontSize: childStyles.date?.fontSize }
            }}
            wrapperStyle={{
              flex: '1',
              minWidth: '160px',
              ...(!disabled && useFieldWrapperStyle && {
                ...(((!isEmpty(dateInput) && !dateInputIsValid) ||
                  (!isEmpty(timeInput) && isEmpty(dateInput))) && inputErrorStyle),
                ...(!isEmpty(dateInput) && dateInputIsValid && inputValidStyle)
              }),
              ...childStyles.date
            }}
            weekdaysOnly={weekdaysOnly}
            errorMessage={dateErrorMessage}
            required={required}
            callback={(inputId, value, valid) => this.handleChangeInput('dateInput', value, valid)}
            value={dateInput}
            disabled={disabled}
          />
          <Input
            id={`${id}InputTimestampTime`}
            allowToday={allowToday}
            type={dateType === 'futureDate' ? 'futureTime' : 'time'}
            dateInput={dateInput}
            className={`${id} inputTimestamp`}
            inputStyle={{
              ...inputStyle,
              ...(boxStyle === 'inside' && !disabled && { color: 'inherit', backgroundColor: 'transparent' }),
              ...((disableTimeInput || (!isEmpty(dateInput) && !dateInputIsValid)) && boxStyle === 'inside' && input.innerWrapDisabled),
              ...childStyles.time?.fontSize && { fontSize: childStyles.time?.fontSize }
            }}
            wrapperStyle={{
              flex: '1',
              ...(!disabled && useFieldWrapperStyle && {
                ...(!isEmpty(timeInput) && !timeInputIsValid && inputErrorStyle),
                ...(!isEmpty(timeInput) && timeInputIsValid && inputValidStyle)
              }),
              ...childStyles.time
            }}
            businessHoursOnly={businessHoursOnly}
            roundMinutes={roundMinutes}
            errorMessage={timeErrorMessage}
            useValidationMessage={useTimeValidationMessage}
            required={required}
            callback={(inputId, value, valid) => this.handleChangeInput('timeInput', value, valid)}
            value={timeInput}
            disabled={disabled || disableTimeInput || (!isEmpty(dateInput) && !dateInputIsValid)}
            suggestedTimeList={timeList}
          />
        </div>
        <ErrorBox
          className="errors"
          id={`${id}-error`}
          error={showError}
          $boxStyle={boxStyle}
        >
          Must be a valid date/time &#40;
          {dateType === 'futureDate' ? 'not in past' : 'not in future'}
          &#41;
        </ErrorBox>
      </div>
    );
  }
}

InputTimestamp.propTypes = {
  dateType: PropTypes.string,
  timeList: PropTypes.oneOfType([PropTypes.array]),
  noValidate: PropTypes.bool,
  useLocal: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  boxStyle: PropTypes.string,
  wrapperStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  customInputStyle: PropTypes.oneOfType([PropTypes.object]),
  childStyles: PropTypes.shape({
    date: PropTypes.oneOfType([PropTypes.object]),
    time: PropTypes.oneOfType([PropTypes.object])
  }),
  autoUpdate: PropTypes.bool,
  required: PropTypes.bool,
  callback: PropTypes.func,
  value: PropTypes.string,
  timeErrorMessage: PropTypes.string,
  dateErrorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  useFieldWrapperStyle: PropTypes.bool,
  tooltip: PropTypes.string,
  allowToday: PropTypes.bool,
  businessHoursOnly: PropTypes.bool,
  roundMinutes: PropTypes.bool,
  useTimeValidationMessage: PropTypes.bool,
  weekdaysOnly: PropTypes.bool
};

InputTimestamp.defaultProps = {
  id: '',
  label: '',
  boxStyle: null,
  wrapperStyle: {},
  customInputStyle: {},
  childStyles: {
    date: {},
    time: {}
  },
  autoUpdate: true, // send `false` to delay callback until triggered by 'Update' button
  required: false,
  value: '',
  callback: null,
  disabled: false,
  dateType: 'date',
  allowToday: false, // If using `futureDate`
  businessHoursOnly: false,
  dateErrorMessage: null, // Custom error message for the date field
  noValidate: false,
  timeErrorMessage: null, // Custom error message for the time field
  timeList: [],
  tooltip: null,
  roundMinutes: false,
  /**
   * useLocal: If using the user's local time (instead of CT)
   * to ensure if they select 9 AM, it shows as 9 AM local time in the calender selector,
   * instead of its converted Central Time (eg, converting from MT to CT is 10 AM).
   * This component handles converting the actual timestamp to CT.
   */
  useLocal: false,
  useFieldWrapperStyle: false,
  useTimeValidationMessage: false,
  weekdaysOnly: false
};

export default InputTimestamp;
