import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { merchantGuids } from '../guids';

export const mockGetRelationshipListMerchant = (body, params, options) => {
  const { guid = '' } = options || {};
  const mockData = {
    open: Array.from({ length: 5 }).map(() => ({
      merchantGuid: merchantGuids.default,
      mid: getMockValue({ type: 'mid' }),
      dbaName: getMockValue({ type: 'businessName' }),
      approvedDate: getMockValue({ type: 'date' })
    })),
    closed: Array.from({ length: 5 }).map(() => ({
      merchantGuid: merchantGuids.closed,
      mid: getMockValue({ type: 'mid' }),
      dbaName: getMockValue({ type: 'businessName' }),
      approvedDate: getMockValue({ type: 'date' }),
      closedDate: getMockValue({ type: 'date' })
    }))
  };
  return { [guid]: mockData };
};

export default mockGetRelationshipListMerchant;
