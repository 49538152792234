import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Icon } from '../css/_styledComponents';
import { icons } from '../images/_icons';
import { input, comboBox } from './_styles';
import {
  getType,
  isBool,
  ignoreCase,
  isEmpty,
  isEqual,
  comboboxDirection
} from './_helpers';
import validations from './_validations';
import { ToolTip } from './ToolTip';
import { ErrorBox } from '../css/_styledFormComponents';
import { ComboBoxMenu } from './ComboBoxMenu';

const {
  DropdownIndicator,
  SelectContainer
} = components;

const CustomDropdownIndicator = ({ ...props }) => {
  const { selectProps = {}, useBlockForm } = props;
  const { menuIsOpen } = selectProps;
  const stateCSS = menuIsOpen ? icons.listLess.src_white : icons.listMore.src_white;
  return (
    <DropdownIndicator {...props}>
      <Icon
        icon={stateCSS}
        color="var(--color-light-label)"
        $useMask
        style={{
          marginTop: useBlockForm ? '1em' : '0',
          marginRight: '-1px',
          ...(stateCSS.paddingLeft && { left: '2px' }),
          ...(stateCSS.paddingRight && { right: '2px' })
        }}
      />
    </DropdownIndicator>
  );
};
CustomDropdownIndicator.propTypes = {
  useBlockForm: PropTypes.bool,
  selectProps: PropTypes.oneOfType([PropTypes.object])
};
CustomDropdownIndicator.defaultProps = {
  useBlockForm: false,
  selectProps: {}
};

const CustomSelectContainer = ({ children, ...props }) => (
  <SelectContainer {...props}>
    { children }
  </SelectContainer>
);
CustomSelectContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]),
  props: PropTypes.oneOfType([PropTypes.object])
};
CustomSelectContainer.defaultProps = {
  children: null,
  props: {}
};

/*
    <ComboBox
      label="Risk Category" // text to appear above the dropdown
      id="riskCategory" // unique identifier -- helpful for onChange handler
      placeholder="select something from this dropdown" // provide helpful text to user
      list={[{ title: 'text to show user', value: 'valueToSendToBE' }]} // an array of options
      selected={'valueToSendToBE'} // the controlled selected value
      callback={this.handleDropdownChange} // change handler function - returns value or object
      type="anything" // legacy dropdown behavior -- callback will receive an object if non-empty
      required // include this to make the field required
      disabled={boolean expression} // set to disable the dropdown list
      useBlockForm // set this prop to display as a white block with black borders for sidepanel
      formField // include to allow users to clear the dropdown selection
      wrapperStyle={styleObject} // style the dropdown's container
      displaySearch // include to allow type-ahead selection
      editable // allow user to input new entries into the dropdown
      isMulti // allow user to select more than one entry
    />
 */

export class ComboBox extends React.Component {
  constructor (props) {
    super(props);
    this.mounted = false;
    this.comboBoxRef = React.createRef();
    const { editable, listDirection } = props;
    this.defaultDropHeaderClassName = editable ? 'editableDropMenu' : 'dropMenu';
    this.state = {
      currentSelection: null,
      currentOptions: [],
      isValid: false,
      menuPlacement: listDirection || 'auto',
      menuHeight: -1,
      showMenu: false
    };
  }

  componentDidMount () {
    this.mounted = true;
    this.convertOptions();
  }

  componentDidUpdate (prevProps) {
    const { list, selected } = this.props;
    if (JSON.stringify(prevProps?.list) !== JSON.stringify(list) ||
      !isEqual(prevProps?.selected, selected)) {
      this.convertOptions();
    }
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  updateState = (state, callback) => {
    this.mounted && this.setState(state, callback);
  }

  convertOptions = () => {
    const {
      editable,
      list,
      selected,
      isMulti
    } = this.props;
    const { currentSelection, isValid } = this.state;
    const { value, __isNew__: isNew } = currentSelection || {};
    const selectedIsNewOption = isNew && !isMulti && !isEmpty(selected)
      ? value === selected
      : false;
    const convertedOptions = !isEmpty(list) ? list.map((item) => {
      const option = { ...item };
      if (item.title && !item.label) {
        option.label = item.title;
      }
      return option;
    }) : [];
    const isSelectedValid = this.checkIsValid({ value: selected });
    const selectedInList = convertedOptions.find(listItem => listItem?.value === selected);
    const initialValueIsNew = value === undefined &&
      !isEmpty(selected) && isEmpty(selectedInList) && !isMulti;
    this.updateState({
      currentOptions: [...new Set([
        ...(editable && ((selectedIsNewOption && isValid) || (initialValueIsNew && isSelectedValid))
          // If `selected` is newly added value & valid, add to options list
          ? [{
            label: selected,
            title: selected,
            value: selected,
            __isNew__: true
          }] : []),
        ...convertedOptions
      ])]
    }, () => this.convertSelectedValue({ selectedIsNewOption }));
  }

  convertSelectedValue = (options) => {
    const { selectedIsNewOption } = options || {};
    const {
      selected,
      useBlockForm,
      validationActivated,
      isMulti
    } = this.props;
    const { currentOptions } = this.state;
    let convertedSelected = !isEmpty(selected?.label)
      ? selected : currentOptions.find(item => item?.value === selected);
    if (isMulti && Array.isArray(selected) && !isEmpty(selected)) {
      convertedSelected = selected.map((selectedItem) => {
        if (!isEmpty(selectedItem?.label)) { return selectedItem; }
        const match = currentOptions.find(item => item?.value === selectedItem?.value);
        return match || { ...selectedItem, label: selectedItem?.title || selectedItem?.value };
      });
    }
    if (isEmpty(convertedSelected)) { convertedSelected = null; }
    this.updateState({
      ...(!selectedIsNewOption && { currentSelection: convertedSelected }),
      ...(useBlockForm || validationActivated || (isMulti && !isEmpty(convertedSelected)) ||
      (!useBlockForm && !isEmpty(convertedSelected))) && {
        isValid: this.checkIsValid(selectedIsNewOption && isEmpty(convertedSelected)
          ? { value: selected }
          : convertedSelected)
      }
    });
  }

  onChangeConverter = (onChange, actionMeta) => {
    const {
      required,
      callback,
      id,
      isMulti,
      editable,
      type
    } = this.props;
    const { currentSelection } = this.state;
    if (isMulti) {
      let onChangeList = [...onChange];
      if (editable) {
        const isRemoving = ['pop-value', 'remove-value', 'clear'].includes(actionMeta.action);
        const hasFixed = !isEmpty(currentSelection) ? currentSelection.some(s => s.isFixed) : false;
        const fixedValues = hasFixed ? currentSelection.filter(s => s.isFixed) : [];
        if (isRemoving && !isEmpty(fixedValues)) {
          const nonFixedValues = onChange.filter(newValue => !newValue.isFixed);
          onChangeList = [...fixedValues, ...nonFixedValues];
        }
      }
      const validCheck = this.checkIsValid(onChangeList);
      this.updateState({
        currentSelection: onChangeList,
        isValid: validCheck
      });
      const callbackValue = { value: onChangeList, valid: validCheck, id };
      callback && callback(callbackValue);
    } else {
      const changeValue = !isEmpty(type) ? {
        ...onChange,
        title: onChange?.label,
        value: onChange?.value,
        valid: this.checkIsValid(onChange),
        id
      } : onChange?.value;
      this.updateState({
        currentSelection: onChange,
        isValid: isBool(changeValue?.valid)
          ? changeValue?.valid
          : !isEmpty(onChange?.value) || (!required && isEmpty(onChange?.value))
      });
      callback && callback(changeValue);
    }
  }

  checkIsValid = (options) => {
    const { value } = options || {};
    const {
      isMulti,
      required,
      validationType
    } = this.props;
    if (isMulti) {
      const multiArray = getType(options) === 'array' ? options : value || [];
      let allValid = required ? !isEmpty(multiArray) : true;
      if (!isEmpty(validationType) && !isEmpty(multiArray)) {
        allValid = multiArray.every(item => validations[validationType].test(item.value));
      }
      return allValid;
    }
    const okToBeBlank = (isEmpty(value) && !required);
    return (!validationType
      ? !required || !isEmpty(value)
      : validations[validationType].test(value) || okToBeBlank);
  }

  onInputChange = (changes) => {
    this.updateState({ inputValue: changes });
  }

  menuStyles = (provided, state) => {
    const { useBlockForm } = this.props;
    const { menuHeight, menuPlacement, showMenu } = this.state;
    return {
      ...provided,
      fontSize: '1.4rem',
      lineHeight: '1.6',
      cursor: 'default',
      zIndex: '10',
      visibility: showMenu ? 'visible' : 'hidden',
      ...(!isEmpty(menuHeight) && menuHeight > 0 && { maxHeight: `${menuHeight}px` }),
      ...(useBlockForm && {
        width: 'calc(100% - 2px)',
        marginBottom: '0',
        marginTop: '0',
        marginLeft: '1px'
      }),
      display: 'flex',
      flexDirection: menuPlacement === 'top' ? 'column-reverse' : 'column'
    };
  }

  menuListStyles = (provided, state) => ({
    ...provided,
    width: '100%' // so menu list is not wider than menu header
  })

  inputStyles = (provided, state) => {
    const { useBlockForm } = this.props;
    return { ...provided, marginTop: useBlockForm ? '1em' : 0 };
  }

  customMenu = ({ ...props }) => (<ComboBoxMenu {...props} />)

  handleFilterBySearchKey = (option, searchText) => {
    const { data, label } = option || {};
    const { searchKeys } = this.props;
    if (!isEmpty(searchText)) {
      const labelMatch = ignoreCase(label).includes(ignoreCase(searchText));
      return searchKeys.some((searchItem) => {
        const match = !isEmpty(data) &&
            ignoreCase(data[searchItem.value]).includes(ignoreCase(searchText));
        return match;
      }) || labelMatch;
    }
    return true;
  }

  updateDirection = () => {
    this.updateState({
      showMenu: false
    }, this.setMenuDirection);
  }

  setMenuDirection = () => {
    const { listDirection, useBlockForm } = this.props;
    setTimeout(() => {
      if (this.comboBoxRef.current) {
        const menuHeader = this.comboBoxRef.current.querySelector(
          `.${this.defaultDropHeaderClassName}` // excludes label height when NOT using block form
        );
        const menuList = this.comboBoxRef.current.querySelector('.dropList__menu-list');
        const menu = menuList
          ? menuList.parentElement // includes "search by" bar
          : null;
        const { direction, newHeight } = comboboxDirection({ menuHeader, menu, useBlockForm });
        this.updateState({
          menuPlacement: listDirection || direction,
          menuHeight: newHeight
        }, () => this.updateState({ showMenu: true }));
      }
    }, 1);
  }

  resetMenu = () => {
    this.updateState({
      menuPlacement: 'bottom',
      menuHeight: -1
    });
  }

  render () {
    const {
      currentOptions,
      currentSelection,
      inputValue,
      isValid,
      menuPlacement
    } = this.state;
    const {
      errorMessage,
      useTagColors,
      disabled,
      displaySearch,
      editable,
      formField,
      id,
      className,
      isMulti,
      label,
      labelPos,
      containerStyles,
      placeholder,
      required,
      selected,
      searchKeys,
      isHtmlTooltip,
      tooltip,
      tooltipWidth,
      useBlockForm,
      boxStyles,
      validationActivated,
      validationType,
      valueContainerStyles,
      wrapperStyle
    } = this.props;
    const isClearable = required ? false : formField;
    const { height: boxHeight } = wrapperStyle || {};
    const tooltipText = !isEmpty(tooltip) && getType(tooltip) === 'string' && tooltip?.includes('\n') ? tooltip.split('\n').map(tip => tip) : tooltip;
    return (
      <div
        ref={this.comboBoxRef}
        className="combo-label-wrapper"
        style={{
          position: 'relative',
          ...(labelPos === 'left' && {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            gap: '0.4em'
          }),
          ...wrapperStyle,
          ...(!isEmpty(boxHeight) && !useBlockForm && label && { height: 'auto', minHeight: boxHeight })
        }}
      >
        {label && (
          <span>
            <label
              style={{
                ...(useBlockForm ? comboBox.labelInside : {
                  ...input.label,
                  height: '24px',
                  lineHeight: '24px',
                  margin: '0'
                })
              }}
              htmlFor={id}
            >
              { required && (
                <span style={input.label_required}>* </span>
              )}
              {label}
              {tooltip && (
                <ToolTip
                  infoTip
                  isHtml={isHtmlTooltip}
                  inline={isHtmlTooltip}
                  {...tooltipWidth && { width: tooltipWidth }}
                  infoTipDisplay={isHtmlTooltip ? {} : { right: useBlockForm ? '-20px' : '3px', top: useBlockForm ? '9px' : '3px' }}
                >
                  {tooltipText}
                </ToolTip>
              )}
            </label>
          </span>
        )}
        {React.createElement(editable ? CreatableSelect : Select, {
          classNamePrefix: 'dropList',
          isClearable,
          ...(editable && isMulti && selected?.some(s => s?.isFixed) && { isClearable: false }),
          isDisabled: disabled,
          isSearchable: displaySearch,
          name: id,
          'aria-label': label || id,
          placeholder: placeholder || null,
          isValid,
          components: {
            ...(displaySearch && { Menu: this.customMenu }),
            DropdownIndicator: CustomDropdownIndicator,
            ...editable && { SelectContainer: CustomSelectContainer }
          },
          menuPlacement,
          onMenuOpen: this.updateDirection,
          onMenuClose: this.resetMenu,
          menuShouldScrollIntoView: false,
          styles: {
            multiValue: /* istanbul ignore next */ (provided, state) => ({
              ...provided,
              ...(state.data.markAsYou && { backgroundColor: 'var(--color-sage)' }),
              ...(useTagColors && {
                ...(editable && isMulti && state.data.isFixed && comboBox.multiValueNotClearable),
                // eslint-disable-next-line no-underscore-dangle
                ...(state.data.__isNew__ && comboBox.newValue),
                ...(state.data.isInternal && comboBox.internalOnly)
              }),
              borderRadius: 'var(--radius-main)',
              ...(disabled && { backgroundColor: 'hsl(var(--color-disabled-hue),var(--color-disabled-saturation),calc(var(--color-disabled-lightness) + 7%))' })
            }),
            multiValueLabel: /* istanbul ignore next */ (provided, state) => ({
              ...provided,
              ...(useTagColors && {
                ...(editable && isMulti && state.data.isFixed && comboBox.multiLabelNotClearable),
                // eslint-disable-next-line no-underscore-dangle
                ...(state.data.__isNew__ && comboBox.newValueLabel),
                ...(state.data.isInternal && comboBox.internalOnlyLabel)
              }),
              ...(disabled && { color: 'var(--color-light-label)' }),
              whiteSpace: 'normal'
            }),
            multiValueRemove: /* istanbul ignore next */ (provided, state) => ({
              ...provided,
              ...(((editable && isMulti && state.data.isFixed) || disabled) && { display: 'none' }),
              borderRadius: 'var(--radius-main)'
            }),
            control: (provided, state) => ({
              ...provided,
              borderSize: '1px',
              borderStyle: 'solid',
              borderColor: 'var(--color-hr)',
              borderRadius: useBlockForm ? 0 : 'var(--radius-main)',
              ...(useBlockForm) && { minHeight: '64px' },
              ...(isValid && { borderColor: 'hsl(var(--color-healthy-hue),var(--color-healthy-saturation),calc(var(--color-healthy-lightness) + 10%))', backgroundColor: 'var(--color-healthy-bg)' }),
              ...(((validationActivated && required && isEmpty(currentSelection)) ||
            (required && !isEmpty(currentSelection) && !isValid) ||
            (!isEmpty(validationType) && !isEmpty(currentSelection) && !isValid)) && {
                color: 'var(--color-warning)', borderColor: 'var(--color-warning)', backgroundColor: 'var(--color-warning-bg)'
              }),
              ...(useBlockForm) && { borderColor: '#000' },
              ...(!isEmpty(boxHeight) && { minHeight: boxHeight }),
              ...(disabled && { backgroundColor: 'var(--color-disabled)', borderColor: 'var(--color-light-label)' }),
              ...(boxStyles && boxStyles)
            }),
            container: (provided, state) => ({
              ...provided,
              boxSizing: 'content-box',
              marginTop: '0',
              borderRadius: useBlockForm ? 0 : 'var(--radius-main)',
              alignItems: useBlockForm ? 'stretch' : 'auto',
              width: '100%',
              ...containerStyles
            }),
            valueContainer: (provided, state) => ({
              ...provided,
              ...(isValid && { backgroundColor: 'var(--color-healthy-bg)' }),
              ...(validationActivated && required && isEmpty(currentSelection) && { color: 'var(--color-warning)', borderColor: 'var(--color-warning)', backgroundColor: 'var(--color-warning-bg)' }),
              ...((isMulti && useBlockForm) && { paddingTop: '1em' }),
              borderRadius: useBlockForm ? 0 : 'var(--radius-main)',
              whiteSpace: 'normal',
              minHeight: useBlockForm ? '50px' : '30px',
              boxSizing: 'border-box',
              lineHeight: '1em',
              overflowWrap: 'break-word',
              ...(disabled && { backgroundColor: 'var(--color-disabled)' }),
              ...valueContainerStyles
            }),
            placeholder: (provided, state) => ({
              ...provided,
              fontSize: useBlockForm ? '1.6rem' : '1.2rem',
              marginTop: useBlockForm ? '1em' : 0
            }),
            indicatorSeparator: (provided, state) => ({
              display: 'none'
            }),
            dropdownIndicator: (provided, state) => ({
              ...provided,
              ...(isClearable && { paddingLeft: '4px' })
            }),
            clearIndicator: (provided, state) => ({
              ...provided,
              padding: '8px 4px 8px 0'
            }),
            indicatorsContainer: (provided, state) => ({
              ...provided,
              ...(!useBlockForm && { height: boxHeight || '30px' })
            }),
            singleValue: /* istanbul ignore next */ (provided, state) => ({
              ...provided,
              fontSize: useBlockForm ? '1.6rem' : '1.2rem',
              alignSelf: 'center',
              marginTop: useBlockForm ? '1em' : 0,
              ...(disabled && { color: 'var(--color-light-label)' }),
              whiteSpace: 'normal'
            }),
            menu: this.menuStyles,
            menuList: this.menuListStyles,
            option: /* istanbul ignore next */ (provided, { data }) => {
              const {
                allowInactiveSelection = true,
                depth,
                isDisabled,
                markAsYou
              } = data;
              return ({
                ...provided,
                overflowWrap: 'break-word',
                cursor: 'pointer',
                ...(markAsYou && { borderBottom: '1px solid var(--color-hr)' }),
                ...(isDisabled && { cursor: 'not-allowed' }),
                ...(depth && depth >= 1 && {
                  // 8 = original padding left
                  // 20 = offset
                  paddingLeft: `${((depth || 0) * 20) + 8}px`
                }),
                ...(data?.inactive && !allowInactiveSelection && {
                  color: 'var(--color-disabled)'
                })
              });
            },
            input: this.inputStyles
          },
          theme: theme => ({
            ...theme,
            borderRadius: useBlockForm ? 0 : 'var(--radius-main)',
            colors: {
              ...theme.colors,
              neutral0: 'var(--color-bg)', // selected item text color & component bg
              primary: 'hsl(var(--color-secondary-hue), var(--color-secondary-saturation), calc(var(--color-secondary-lightness) + 12%))', // selected bg
              primary25: 'hsl(var(--color-secondary-hue), calc(var(--color-secondary-saturation) - 49%), calc(var(--color-secondary-lightness) + 59%))' // focused bg
            },
            spacing: {
              baseUnit: 4,
              controlHeight: 30,
              menuGutter: 2
            }
          }),
          ...this.props,
          className: [this.defaultDropHeaderClassName, ...(!isEmpty(className) ? [className] : [])].join(' '),
          options: currentOptions,
          onChange: this.onChangeConverter,
          ...editable && { onInputChange: this.onInputChange },
          value: editable ? (currentSelection || { value: selected || '', label: selected || '' }) : currentSelection,
          ...editable && { inputValue },
          ...(editable && isMulti && isEmpty(currentSelection) && { value: '' }),
          ...editable && { isSearchable: true },
          ...(!isEmpty(searchKeys) && { filterOption: this.handleFilterBySearchKey })
        })}
        <ErrorBox
          className="errors"
          id={`${id}-error`}
          error={(!isEmpty(currentSelection) && !isValid) || (validationActivated && !isValid)}
          $boxStyle={useBlockForm ? 'inside' : null}
          style={{ ...(!validationActivated && isEmpty(currentSelection) && !isValid && { height: '0' }) }}
        >
          {errorMessage || validations?.[validationType]?.message || (required && 'Must be completed') || null}
        </ErrorBox>
      </div>
    );
  }
}

ComboBox.propTypes = {
  callback: PropTypes.func,
  useTagColors: PropTypes.bool,
  listDirection: PropTypes.string,
  className: PropTypes.string,
  errorMessage: PropTypes.string, // Custom error message
  disabled: PropTypes.bool,
  displaySearch: PropTypes.bool,
  editable: PropTypes.bool,
  formField: PropTypes.bool,
  id: PropTypes.string,
  isMulti: PropTypes.bool,
  label: PropTypes.string,
  labelPos: PropTypes.string,
  list: PropTypes.oneOfType([PropTypes.array]),
  searchKeys: PropTypes.oneOfType([PropTypes.array]),
  options: PropTypes.oneOfType([PropTypes.array]),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  selected: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.array // for isMulti === true, this is an array of { title, value }
  ]),
  isHtmlTooltip: PropTypes.bool,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  tooltipWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  type: PropTypes.string,
  useBlockForm: PropTypes.bool,
  containerStyles: PropTypes.oneOfType([PropTypes.object]),
  wrapperStyle: PropTypes.oneOfType([PropTypes.object]),
  boxStyles: PropTypes.oneOfType([PropTypes.object]),
  valueContainerStyles: PropTypes.oneOfType([PropTypes.object]),
  validationActivated: PropTypes.bool,
  validationType: PropTypes.string
};

ComboBox.defaultProps = {
  callback: () => {},
  listDirection: null, // 'bottom', 'top', 'auto'
  className: null,
  errorMessage: null,
  useTagColors: false,
  disabled: false,
  displaySearch: true,
  editable: false,
  formField: false,
  id: '',
  isMulti: false,
  label: '',
  labelPos: null,
  list: [],
  searchKeys: [], // array of { title, value }
  options: [],
  placeholder: '',
  required: false,
  selected: null,
  isHtmlTooltip: false,
  tooltip: null,
  tooltipWidth: null,
  type: '',
  useBlockForm: false,
  containerStyles: {},
  wrapperStyle: {},
  boxStyles: {},
  valueContainerStyles: {},
  validationActivated: false,
  validationType: ''
};

export default ComboBox;
