import { crmEndpoint } from '../../utilsEndpoints';

const mockAchDetailsPostRes = (body, params, options) => ({
  fileInfo: {
    fileName: 'mockAchFile_timestampHere',
    absolutePath: '/root/newMerchant',
    url: crmEndpoint.file.v2DummyUploadUrl
  }
});

export default mockAchDetailsPostRes;
