import { deleteItem, edit } from '../images/_icons';
import {
  legendCSS,
  borderRadius
} from './_globals';

export const formStylesCSS = {
  formWrapper: {
    position: 'relative',
    width: '100%'
  },
  form: {
    margin: '15px 0',
    padding: '10px',
    borderStyle: 'solid',
    borderColor: 'transparent',
    borderWidth: '2px',
    backgroundColor: 'var(--color-bg)',
    ...borderRadius()
  },
  formSection: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    position: 'relative'
  },
  formSectionHeader: {
    ...legendCSS.wrap,
    flex: '100%',
    fontSize: '1.8rem',
    margin: '-1px'
  },
  formSectionHeaderText: {
    ...legendCSS.text,
    minWidth: 'unset'
  },
  nestedSubSection: {
    backgroundColor: 'var(--color-bg-alt)',
    border: '1px solid black',
    lineHeight: '2',
    fontSize: '1.1rem',
    textTransform: 'uppercase'
  },
  formSubSection: {
    lineHeight: '2',
    borderRight: '1px solid black',
    borderLeft: '1px solid black',
    fontSize: '1.5rem',
    backgroundColor: 'var(--color-form-subheader-bg)'
  },
  formSubSubSection: {
    lineHeight: '2',
    border: '1px solid black',
    fontSize: '1.5rem',
    backgroundColor: 'var(--color-form-subheader-bg)'
  },
  formSubSubSectionText: {
    color: 'black'
  },
  formSubSectionText: {
    color: 'black'
  },
  formContentWrapper: {
    borderColor: 'black',
    borderWidth: '1px',
    borderStyle: 'solid',
    margin: '-1px',
    flex: '100%',
    position: 'relative'
  },
  addButtonWrapper: { // a text link button to add a form section
    paddingLeft: '5px',
    lineHeight: '2',
    fontSize: '1.2rem',
    fontWeight: 'bold'
  },
  removeButtonWrapper: { // styling for a delete button in a form section
    backgroundColor: 'transparent',
    minHeight: '20px', // set mins so the icon is visible
    minWidth: '20px',
    height: '1.2em',
    width: '1.2em'
  },
  editButton: edit(),
  removeButtonIcon: deleteItem()
};

export default formStylesCSS;
