// Mixins
export const borderRadius = (topL = 'var(--radius-main)', topR, bottomR, bottomL) => ({
  MozBoderRadiusTopleft: topL,
  WebkitBorderTopLeftRadius: topL,
  borderTopLeftRadius: topL,
  MozBorderRadiusTopright: topR || topL,
  WebkitBorderTopRightRadius: topR || topL,
  borderTopRightRadius: topR || topL,
  MozBorderRadiusBottomright: bottomR || topL,
  WebkitBorderBottomRightRadius: bottomR || topL,
  borderBottomRightRadius: bottomR || topL,
  MozBorderRadiusBottomleft: bottomL || topL,
  WebkitBorderBottomLeftRadius: bottomL || topL,
  borderBottomLeftRadius: bottomL || topL
});
export const transition = (property = 'all', duration = '0.2s', timing = 'ease') => ({
  WebkitTransition: `${property} ${duration} ${timing}`,
  MozTransition: `${property} ${duration} ${timing}`,
  OTransition: `${property} ${duration} ${timing}`,
  MsTransition: `${property} ${duration} ${timing}`,
  transition: `${property} ${duration} ${timing}`
});
export const boxshadow = (x = '2px', y = '2px', blur = '2px', spread = '0', color = 'rgba(0, 0, 0, 0.1)', inset = '') => ({
  WebkitBoxShadow: `${x} ${y} ${blur} ${spread} ${color} ${inset}`,
  MozBoxShadow: `${x} ${y} ${blur} ${spread} ${color} ${inset}`,
  boxShadow: `${x} ${y} ${blur} ${spread} ${color} ${inset}`
});
export const textshadow = (data) => {
  const {
    x = '1px', y = '1px', blur = '1px', color = 'rgba(0, 0, 0, 0.3)'
  } = data;
  return {
    WebkitTextShadow: `${x} ${y} ${blur} ${color}`,
    MozTextShadow: `${x} ${y} ${blur} ${color}`,
    textShadow: `${x} ${y} ${blur} ${color}`
  };
};
export const noselect = {
  WebkitTouchCallout: 'none',
  WebkitUserSelect: 'none',
  KhtmlUserSelect: 'none',
  MozUserSelect: 'none',
  MsUserSelect: 'none',
  userSelect: 'none'
};
export const gradient = (data) => {
  /* eslint-disable no-dupe-keys */
  const {
    first = primary, second = secondary, third = null, fourth = null
  } = data;
  if (fourth != null) {
    return {
      backgroundColor: `${second}`,
      background: `-webkit-linear-gradient(top,    ${first} 0%, ${second} 50%, ${third} 51%, ${fourth} 100%)`,
      background: `-moz-linear-gradient(top,    ${first} 0%, ${second} 50%, ${third} 51%, ${fourth} 100%)`,
      background: `-ms-linear-gradient(top,    ${first} 0%, ${fourth} 50%, ${third} 51%, ${fourth} 100%))`,
      background: `-o-linear-gradient(top,    ${first} 0%, ${fourth} 50%, ${third} 51%, ${fourth} 100%))`,
      background: `linear-gradient(to bottom, ${first} 0%, ${fourth} 50%, ${third} 51%, ${fourth} 100%))`,
      filter: `progid:DXImageTransform.Microsoft.gradient(startColorstr='${first}',endColorstr='${fourth}')`,
      zoom: `1`
    };
  }
  if (third != null) {
    return {
      backgroundColor: `${second}`,
      background: `webkit-linear-gradient(top,    ${first} 0%, ${second} 50%, ${third} 100%)`,
      background: `-moz-linear-gradient(top,    ${first} 0%, ${second} 50%, ${third} 100%)`,
      background: `-ms-linear-gradient(top,    ${first} 0%, ${second} 50%, ${third} 100%))`,
      background: `-o-linear-gradient(top,    ${first} 0%, ${second} 50%, ${third} 100%))`,
      background: `linear-gradient(to bottom, ${first} 0%, ${second} 50%, ${third}) 100%)`,
      filter: `progid:DXImageTransform.Microsoft.gradient(startColorstr='${first}',endColorstr='${third}')`,
      zoom: `1`
    };
  }
  return {
    background: `${second}`,
    background: `-webkit-linear-gradient(top, ${first} 0%, ${second} 100%)`,
    background: `-moz-linear-gradient(top, ${first} 0%, ${second} 100%)`,
    background: `-ms-linear-gradient(top, ${first} 0%, ${second} 100%)`,
    background: `-o-linear-gradient(top, ${first} 0%, ${second} 100%)`,
    background: `linear-gradient(to bottom, ${first} 0%, ${second} 100%)`,
    filter: `progid:DXImageTransform.Microsoft.gradient(startColorstr='${first}',endColorstr='${second}')`,
    zoom: `1`
  };
};

export const bgBlur = (data) => {
  const { amount = '.6px' } = data || {};
  return {
    WebkitFilter: `blur(${amount})`,
    MozFilter: `blur(${amount})`,
    OFilter: `blur(${amount})`,
    MsFilter: `blur(${amount})`,
    filter: `blur(${amount})`
  };
};
// END Mixins

// COLORS
const document = typeof window === 'undefined' ? null : window.document;
const html = document ? document?.querySelector('html') || null : null;
const theme = html ? html.getAttribute('data-theme') || 'f1' : 'f1';

const primary = {
  name: theme === 'corvia' ? 'DEEP GOLD' : 'Jade',
  rgb: theme === 'corvia' ? 'rgb(175, 137, 51)' : 'rgb(1, 175, 102)',
  cmyk: theme === 'corvia' ? 'cmyk(30, 42, 97, 7)' : 'cmyk(99, 0, 42, 31)',
  hex: theme === 'corvia' ? '#af8933' : '#01af66',
  pms: theme === 'corvia' ? 'PANTONE 4021 C' : '3405 C'
};
const triadic1 = {
  name: theme === 'corvia' ? 'Green Pea' : 'Jacarta',
  rgb: theme === 'corvia' ? 'rgb(35, 112, 83)' : 'rgb(64, 58, 96)',
  cmyk: theme === 'corvia' ? 'cmyk(30, 0, 11, 56)' : 'cmyk(33, 40, 0, 62)',
  hex: theme === 'corvia' ? '#237053' : '#403a60',
  pms: theme === 'corvia' ? '555 C' : '5265 C'
};
const triadic2 = {
  name: theme === 'corvia' ? 'Mulled Wine' : 'Golden Tainoi',
  rgb: theme === 'corvia' ? 'rgb(91, 68, 106)' : 'rgb(255, 198, 88)',
  cmyk: theme === 'corvia' ? 'cmyk(6, 15, 0, 58)' : 'cmyk(0, 22, 65, 0)',
  hex: theme === 'corvia' ? '#5b446a' : '#ffc658',
  pms: theme === 'corvia' ? '7447 C' : '135 C'
};
const secondary = {
  name: theme === 'corvia' ? 'Forest' : 'Blumine',
  rgb: theme === 'corvia' ? 'rgb(0, 68, 59)' : 'rgb(21, 92, 125)',
  cmyk: theme === 'corvia' ? 'cmyk(91, 48, 67, 44)' : 'cmyk(83, 26, 0, 51)',
  hex: theme === 'corvia' ? '#003d38' : '#155c7d',
  pms: theme === 'corvia' ? 'PANTONE 3302 C' : '7700 C'
};
const quinary = {
  name: 'Double Colonial White',
  rgb: 'rgb(240, 230, 178)',
  cmyk: 'cmyk(0, 4, 26, 6)',
  hex: '#f0e6b2',
  pms: '7499 C'
};
const link = {
  name: theme === 'corvia' ? 'Light Gold' : 'Crusta',
  rgb: theme === 'corvia' ? 'rgb(189, 173, 129)' : 'rgb(250, 127, 50)',
  cmyk: theme === 'corvia' ? 'cmyk(28, 27, 55, 0)' : 'cmyk(0, 50, 80, 0)',
  hex: theme === 'corvia' ? '#bdad81' : '#ff7f32',
  pms: theme === 'corvia' ? 'PANTONE 4241 C' : '1575 C'
};
const grey = {
  name: theme === 'corvia' ? 'Stone' : 'Aluminum',
  rgb: theme === 'corvia' ? 'rgb(222, 219, 210)' : 'rgb(151, 153, 155)',
  cmyk: theme === 'corvia' ? 'cmyk(12, 10, 15, 0)' : 'cmyk(3, 1, 0, 39)',
  hex: theme === 'corvia' ? '#dedbd2' : '#97999b',
  pms: theme === 'corvia' ? 'PANTONE Cool Gray 1 C' : 'Cool Gray 7 C'
};
const disabled = {
  name: 'Iron',
  rgb: 'rgb(202, 202, 202)',
  cmyk: 'cmyk(19, 13, 11, 0)',
  hex: '#cacaca',
  pms: '427 C'
};
const black = {
  name: theme === 'corvia' ? 'Dusk' : 'Bokara Gray',
  rgb: theme === 'corvia' ? 'rgb(15, 38, 36)' : 'rgb(45, 41, 38)',
  cmyk: theme === 'corvia' ? 'cmyk(83, 60, 67, 71)' : 'cmyk(0, 9, 16, 82)',
  hex: theme === 'corvia' ? '#0f2624' : '#2d2926',
  pms: theme === 'corvia' ? 'PANTONE 7547 C' : 'Black C'
};
const white = {
  name: 'White',
  rgb: 'rgb(255, 255, 255)',
  cmyk: 'cmyk(0, 0, 0, 0)',
  hex: '#ffffff',
  pms: null
};
const whiteish = {
  name: theme === 'corvia' ? 'Air' : 'Alabaster',
  rgb: theme === 'corvia' ? 'rgb(245, 247, 245)' : 'rgb(249, 251, 250)',
  cmyk: theme === 'corvia' ? 'cmyk(3, 1, 2, 0)' : 'cmyk(1, 0, 0, 2)',
  hex: theme === 'corvia' ? '#f5f7f5' : '#f9fbfa',
  pms: theme === 'corvia' ? 'PANTONE 656 C' : null
};
const warning = { // bad
  name: theme === 'corvia' ? 'Warning Red' : 'Cg Red',
  rgb: theme === 'corvia' ? 'rgb(234, 51, 35)' : 'rgb(224, 61, 49)',
  cmyk: theme === 'corvia' ? 'cmyk(0, 100, 100, 0)' : 'cmyk(0, 73, 78, 12)',
  hex: theme === 'corvia' ? '#ea3323' : '#e03d31',
  pms: '179 C'
};
const notice = {
  name: 'Golden Tainoi',
  rgb: 'rgb(255, 198, 88)',
  cmyk: 'cmyk(0, 22, 65, 0)',
  hex: '#ffc658',
  pms: '135 C'
};
const success = {
  name: 'Boston Blue',
  rgb: 'rgb(65, 152, 181)',
  cmyk: 'cmyk(64, 16, 0, 29)',
  hex: '#4198b5',
  pms: '7459 C'
};
const critical = {
  name: 'Milano Red',
  rgb: 'rgb(158, 43, 47)',
  cmyk: 'cmyk(0.11, 0.91, 0.80, 0.22)',
  hex: '#a83632',
  pms: '704 C'
};
const danger = {
  name: 'Persimmon',
  rgb: 'rgb(245, 95, 4)',
  cmyk: 'cmyk(0.00, 0.68, 0.92, 0.01)',
  hex: '#f55f04',
  pms: 'Bright Orange C'
};
const risky = {
  name: 'UCLA Gold',
  rgb: 'rgb(249, 181, 1)',
  cmyk: 'cmyk(0.00, 0.27, 0.94, 0.01)',
  hex: '#f9b501',
  pms: '7549 C'
};
const healthy = {
  name: 'Jade',
  rgb: 'rgb(1, 175, 102)',
  cmyk: 'cmyk(99, 0, 42, 31)',
  hex: '#01af66',
  pms: '3405 C'
};

const stoplight = {
  red: { // errors
    name: 'Cinnabar',
    rgb: '224 60 49',
    hex: '#E03C31',
    cmyk: '0 88 85 0',
    pms: 'PANTONE 179 C'
  },
  orange: {
    name: 'West Side',
    rgb: '255 143 28',
    hex: '#FF8F1C',
    cmyk: '0 49 96 0',
    pms: 'PANTONE 1495 C'
  },
  yellow: { // warning
    name: 'Supernova',
    rgb: '255 198 0',
    hex: '#FFC600',
    cmyk: '0 11 100 0',
    pms: 'PANTONE 7548 C'
  },
  chartreuse: {
    name: 'Rio Grande',
    rgb: '210 207 5',
    hex: '#D2CF05',
    cmyk: '15 1 95 2',
    pms: 'PANTONE 4232 C'
  },
  green: { // success, complete
    name: 'Pistachio',
    rgb: '183 191 16',
    hex: '#AED000',
    cmyk: '24 0 99 6',
    pms: 'PANTONE 583 C'
  }
};

const priority = {
  very_low: {
    hex: '#00B3F2'
  },
  low: {
    hex: '#4197b5'
  },
  medium: {
    hex: '#9c6ad4'
  },
  high: {
    hex: '#d5447e'
  },
  very_high: {
    hex: '#f40166'
  },
  unprioritized: {
    hex: '#c7c7c7'
  },
  default: {
    hex: '#e3e1e1'
  }
};

const status = {
  open: {
    hex: '#dfe1e6'
  },
  in_progress: {
    hex: '#0061F2'
  },
  completed: {
    hex: '#AED000'
  },
  will_not_complete: {
    hex: '#FFAB3E'
  },
  duplicate: {
    hex: '#e03e32'
  },
  closed: {
    hex: '#b0b0b0'
  }
};

export const f1Colors = {
  primary,
  secondary,
  triadic1,
  triadic2,
  quinary,
  link,
  grey,
  disabled,
  black,
  white,
  whiteish,
  warning,
  notice,
  success,
  critical,
  danger,
  risky,
  healthy,
  priority,
  status,
  stoplight
};
// END COLORS

// Image Grids
export const imageGrid = {
  wrap: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden'
  },
  imageWrap: {
    flex: '1',
    minWidth: '200px',
    position: 'relative',
    overflow: 'hidden'
  },
  image: {
    position: 'relative',
    marginTop: '-66px',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    WebkitFilter: 'sepia(70%) hue-rotate(40deg)',
    filter: 'sepia(70%) hue-rotate(40deg)',
    transform: 'scale(1.2) rotateZ(0deg)',
    WebkitTransition: 'all 0.3s ease-in-out, top 0s linear',
    MozTransition: 'all 0.3s ease-in-out, top 0s linear',
    MsTransition: 'all 0.3s ease-in-out, top 0s linear',
    OTransition: 'all 0.3s ease-in-out, top 0s linear',
    transition: 'all 0.3s ease-in-out, top 0s linear'
  },
  imageHover: {
    WebkitFilter: 'sepia(0) hue-rotate(0deg)',
    filter: 'sepia(0) hue-rotate(0deg)',
    transform: 'scale(1.3) rotateZ(-1.1deg) translate(0, 30px)',
    WebkitTransition: 'all 0.3s ease-in-out, transform 0.6s ease-in-out, top 0s linear',
    MozTransition: 'all 0.3s ease-in-out, transform 0.6s ease-in-out, top 0s linear',
    MsTransition: 'all 0.3s ease-in-out, transform 0.6s ease-in-out, top 0s linear',
    OTransition: 'all 0.3s ease-in-out, transform 0.6s ease-in-out, top 0s linear',
    transition: 'all 0.3s ease-in-out, transform 0.6s ease-in-out, top 0s linear'
  }
};
// END Image Grids

// Flexbox base
export const flexboxCSS = {
  wrap: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    position: 'relative'
  },
  innerWrap: {
    flex: '1',
    marginRight: '-1px'
  },
  box: (data) => {
    const {
      flex = '1',
      margin = '10px',
      width = '400px',
      maxWidth = '400px'
    } = data || {};
    return {
      flex,
      margin,
      width,
      maxWidth,
      padding: '20px',
      minWidth: '280px'
    };
  }
};
// END Flexbox base
export const radioAppProps = {
  direction: 'horizontal',
  shape: 'square',
  size: 'sm',
  boxStyle: 'inside',
  wrapperStyle: {
    ...flexboxCSS.innerWrap,
    flex: '50%',
    minWidth: '150px'
  },
  required: true
};

export const inputAppProps = {
  wrapperStyle: {
    ...flexboxCSS.innerWrap,
    flex: '50%'
  },
  boxStyle: 'inside',
  required: true,
  clearAutofillOnMount: true
};
export const totalAppProps = {
  wrapperStyle: {
    ...flexboxCSS.innerWrap,
    flex: '33.33%',
    minWidth: '150px',
    backgroundColor: 'var(--color-disabled)',
    color: 'var(--color-light-label)',
    cursor: 'default'
  },
  boxStyle: 'inside',
  prefix: '$'
};
export const checkboxAppHeader = {
  type: 'mini',
  wrapperStyle: {
    float: 'left',
    marginRight: '10px'
  },
  labelStyle: {
    color: 'white',
    fontWeight: 'bold',
    marginTop: '2px',
    whiteSpace: 'nowrap'
  }
};
export const checkboxAppProps = {
  type: 'mini'
};
export const dropdownAppProps = {
  wrapperStyle: {
    ...flexboxCSS.innerWrap,
    flex: '50%',
    minWidth: '150px'
  },
  boxStyle: 'inside',
  required: true
};
export const legendCSS = {
  wrap: {
    minHeight: '24px',
    lineHeight: '1.5',
    width: '100%',
    background: 'black',
    color: 'white',
    padding: '0 5px 0 0',
    fontWeight: 'bold',
    display: 'flex',
    flexWrap: 'wrap'
  },
  text: {
    flex: '1',
    minWidth: '300px',
    paddingLeft: '5px'
  },
  note: {
    fontSize: '1.2rem',
    fontWeight: 'normal',
    flex: '1',
    padding: '3px 0 0 5px',
    minWidth: '300px'
  }
};

export const fieldsetCSS = {
  border: '1px solid black',
  margin: '10px 0',
  position: 'relative'
};
export const passwordErrorMessages = [
  'Password must contain at least 10 characters',
  'Password must contain a number',
  'Password must contain a special character',
  'Password must contain an upper case letter',
  'Password must contain a lower case letter'
];

export const tooltip = {
  wrap: {
    background: 'white',
    padding: '0 15px',
    border: `1px solid var(--color-hr)`,
    ...borderRadius()
  }
};

export const styleIncrease = (data) => {
  if (data === 'chargeback') {
    return {
      color: 'var(--color-warning)'
    };
  }
  return {
    color: 'var(--color-success)'
  };
};

export const styleDecrease = (data) => {
  if (data === 'chargeback') {
    return {
      color: 'var(--color-success)'
    };
  }
  return {
    color: 'var(--color-warning)'
  };
};

export const coreValues = {
  hope: `A feeling of expectation and desire for a certain thing to happen is what we expect from our Customers, Partners, and Family. A feeling of trust.`,
  integrity: `Our foundation is built on honesty and strong moral principles - the state of being whole and undivided. Our mission statement is about being ONE Family.`,
  guide: `We will help form an opinion and make decisions and/or show the way to others.`,
  humble: `We put clients' and customers' needs first.`,
  engaged: `We attract and involve employees, partners, and clients who are fully engaged in our mission.`,
  relevant: `We strive to be relevant in our partners' and customers' lives.  If we are relevant, we can influence.`
};

export const expandingDiv = {
  wrap: {
    width: '100%',
    border: '1px solid #eaeaea',
    background: 'white',
    padding: '10px',
    marginTop: '10px',
    ...borderRadius()
  },
  closed: {
    maxHeight: '0'
  },
  open: {
    maxHeight: '10000px'
  }
};
