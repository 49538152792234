export const mockReserveHistory = {
  reserveBalance: {
    dataType: 'dollar',
    yesterday: 11000,
    lastSevenDays: 77000,
    monthToDate: 81000,
    previousMonth: 121000,
    twoMonthsAgo: 143000,
    threeMonthsAgo: 211000,
    fourMonthsAgo: 97000,
    fiveMonthsAgo: 168000,
    sixMonthsAgo: 66000
  }
};

export const mockReserveHistoryGetRes = (body, params, options) => {
  const { guid = ['empty'] } = options;
  return {
    [guid]: mockReserveHistory
  };
};

export default mockReserveHistory;
