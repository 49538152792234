import React from 'react';
import PropTypes from 'prop-types';
import { checkHolidays } from '@f1/shared/src/_checkHolidays';

class Main extends React.PureComponent {
  componentDidMount () {
    const holidays = checkHolidays() || {};
    const {
      daysUntilNextHoliday,
      nextHoliday,
      today
    } = holidays;
    const body = document.querySelector('body');
    if (today?.className) {
      body.classList.add(today.className);
    } else if (daysUntilNextHoliday < 7) {
      body.classList.add(nextHoliday.className);
    }
  }

  render () {
    const { children } = this.props;
    return (
      <div
        id="main"
        style={{
          // add padding to account for fixed header
          paddingTop: 'calc(50px + var(--padding-header) + var(--padding-header))',
          // use flex to ensure the page content fits 100%
          display: 'flex',
          // page content area, styles used to sticky footer
          flexGrow: '1',
          flexShrink: '0',
          flexBasis: 'auto',
          background: 'var(--color-bg)'
        }}
      >
        {React.cloneElement(children)}
      </div>
    );
  }
}

Main.propTypes = {
  children: PropTypes.element
};

Main.defaultProps = {
  children: React.createElement('div')
};

export default Main;
