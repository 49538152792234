import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { partnerGuids } from '../guids';
import { mockRelationshipGetFormatted } from '../relationship/root';

export const mockPartnerNotifications = {
  [partnerGuids.default]: {
    noStarts: [{
      mid: 'mid0',
      legalName: 'testEntsLLC',
      dbaName: 'Test Enterprises',
      merchantEmail: 'testent@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Treebeard',
      partnerEmail: 'lastMarchOfTheEnts@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'ZkER7ZJBtC7FVSE',
      approvalDate: '01/01/2001'
    }, {
      mid: 'mid1',
      legalName: 'DwarfHair',
      dbaName: 'Gimli Salons',
      merchantEmail: 'andMyAxe@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Gimli - Son of Gloin',
      partnerEmail: 'threeStrands@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'ZkER7ZJBtC7FVSE',
      approvalDate: '01/01/2001'
    }, {
      mid: 'mid2',
      legalName: 'Elephant Training',
      dbaName: 'Elephants with Legolas',
      merchantEmail: 'oliphants@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Team Murkwood',
      partnerEmail: 'easterlings@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'MdKo1q9wEz5Dn0r',
      approvalDate: '01/01/2001'
    }, {
      mid: 'mid3',
      legalName: 'Laundrettes The White',
      dbaName: 'Bleaching with Gandalf',
      merchantEmail: 'youshallnotstain@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Balrog of Morgoth',
      partnerEmail: 'robesbewhite@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: '40qMgpgQQX13fro',
      approvalDate: '01/01/2001'
    }, {
      mid: 'mid4',
      legalName: 'Door Removal Services',
      dbaName: 'Gone-Door',
      merchantEmail: 'sorry@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'King of Gone-Door',
      partnerEmail: 'aragonElessar@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'UPWAmRQ5miXNrxK',
      approvalDate: '01/01/2001'
    }, {
      mid: 'mid5',
      legalName: 'Samwise Gardeners',
      dbaName: 'Landscaping Your Hobbit Hole',
      merchantEmail: 'samandrosie@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Rosie Gamgee',
      partnerEmail: 'rosieAndSam@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: '3XlJiI6VpBXndFq',
      approvalDate: '01/01/2001'
    }, {
      mid: 'mid6',
      legalName: 'Rings of Power',
      dbaName: 'Smithin Sauron',
      merchantEmail: 'shouldaputaringonit@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Sauron',
      partnerEmail: 'onetorulethem@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'flm1fp75Km2W35v',
      approvalDate: '01/01/2001'
    }, {
      mid: 'mid7',
      legalName: 'Hand of Saruman',
      dbaName: 'White Manicures',
      merchantEmail: 'lurtz@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Saruman the White',
      partnerEmail: 'gandalfthefool@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'COVLqlxNFNKiIep',
      approvalDate: '01/01/2001'
    }, {
      mid: 'mid8',
      legalName: 'Rohan Horses LLC',
      dbaName: 'Theoden\'s Rein',
      merchantEmail: 'youhavenopowerhere@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Rohirrim',
      partnerEmail: 'neigh@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'c92xFPBRiQVPq9i',
      approvalDate: '01/01/2001'
    }, {
      mid: 'mid9',
      legalName: 'Baggins Businesses',
      dbaName: 'Frodo\'s Froyos',
      merchantEmail: 'itsquitecool@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Bilbo Baggins',
      partnerEmail: 'thereandbackagain@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'H4DJ09n79mSjyXn',
      approvalDate: '01/01/2001'
    }, {
      mid: 'mid10',
      legalName: 'BrandyTook',
      dbaName: 'Be Merry and Pip!',
      merchantEmail: 'merryandpippin@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Merry\'s Dad',
      partnerEmail: 'merrysdad@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'oM4x08yKkcHVIrl',
      approvalDate: '01/01/2001'
    }, {
      mid: 'mid11',
      legalName: 'Metlife TM',
      dbaName: 'MetLife',
      merchantEmail: 'metliving@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Kozey, Labadie and Bogan',
      partnerEmail: 'kolabo@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'gF07p8GQ7mMBJ6c',
      approvalDate: '01/01/2001'
    }, {
      mid: 'mid12',
      legalName: 'Turcotte PLC',
      dbaName: 'Turtle Sanctuaries',
      merchantEmail: 'turtlesarecool@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Shell Corporation',
      partnerEmail: 'turtlejokes@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'Y2KqGvpnfcQt6bx',
      approvalDate: '01/01/2001'
    }, {
      mid: 'mid11',
      legalName: 'Ortiz PLC',
      dbaName: 'Ortiz and Otis',
      merchantEmail: 'otisthebusman@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Ortizman',
      partnerEmail: 'ortiz@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'IRfMZPAkM6tNUUL',
      approvalDate: '01/01/2001'
    }, {
      mid: 'mid12',
      legalName: 'Klein-Windler',
      dbaName: 'Not Calvin',
      merchantEmail: 'wedontsellunderwear@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Kalvin Klein-Windler',
      partnerEmail: 'kalklewin@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'VD965GSkMnu1Akv',
      approvalDate: '01/01/2001'
    }, {
      mid: 'mid13',
      legalName: 'Grimes-Sauer',
      dbaName: 'Thorough Cleaners',
      merchantEmail: 'sauthroughgrime@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Elon Musk',
      partnerEmail: 'tesla4evr@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: '8wmCc6dQqYgNRlA',
      approvalDate: '01/01/2001'
    }, {
      mid: 'mid14',
      legalName: 'Money Savers',
      dbaName: 'MoneySaversStore',
      merchantEmail: 'lessthanabuck@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Effertz-Price',
      partnerEmail: 'moneyislife@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'QwX9YdwfGRPYYtH',
      approvalDate: '01/01/2001'
    }, {
      mid: 'mid15',
      legalName: 'Leech Removals',
      dbaName: 'Leech Removal Team',
      merchantEmail: 'leechbegone@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Lesch-Turner',
      partnerEmail: 'lestur@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'avIxo2CFHN6ER5T',
      approvalDate: '01/01/2001'
    }, {
      mid: 'mid16',
      legalName: 'Singing Carp LLC',
      dbaName: 'Billy Bass Pro Shop',
      merchantEmail: 'takemetotheriver@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Fisher-Will',
      partnerEmail: 'willIFish@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: '0nn7ZyOBhU7xoA9',
      approvalDate: '01/01/2001'
    }, {
      mid: 'mid17',
      legalName: 'Window Fixtures',
      dbaName: 'Glass Repairs and No Scares',
      merchantEmail: 'windowrepair@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Stehr and Sons',
      partnerEmail: 'itsrudetostehr@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'KaCNmIwthh4eqVq',
      approvalDate: '01/01/2001'
    }, {
      mid: 'mid18',
      legalName: 'Apple Foods',
      dbaName: 'More than Fruit',
      merchantEmail: 'pipsandall@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'FruitsAndThings',
      partnerEmail: 'fandt@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'qEnMFSPy69xKoWN',
      approvalDate: '01/01/2001'
    }, {
      mid: 'mid19',
      legalName: 'Drift Electronics',
      dbaName: 'The Fast and The Intelligent',
      merchantEmail: 'sidewaysbrain@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'FF Franchise',
      partnerEmail: 'ff@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 's3XnUqcqA4vP2zR',
      approvalDate: '01/01/2001'
    }, {
      mid: 'mid20',
      legalName: 'Maze Intelligence',
      dbaName: 'aMaze-ing',
      merchantEmail: 'sodumb@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'IP Protectors',
      partnerEmail: 'ipp@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: '8pxWbleefhCx7SR',
      approvalDate: '01/01/2001'
    }, {
      mid: 'mid21',
      legalName: 'Sharkfinetworks',
      dbaName: 'Shark Fin Networks',
      merchantEmail: 'jaws@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Bruce & Co.',
      partnerEmail: 'sharkweek@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'lS3EfNPJuiQsfFp',
      approvalDate: '01/01/2001'
    }, {
      mid: 'mid22',
      legalName: 'Triumphoods',
      dbaName: 'Crown Jackets',
      merchantEmail: 'crownsonhoods@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Winners',
      partnerEmail: 'wedontlose@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'hefRmR5dkVqHKOo',
      approvalDate: '01/01/2001'
    }, {
      mid: 'mid23',
      legalName: 'Typhoonsun',
      dbaName: 'Typhoons',
      merchantEmail: 'support@typhoons.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Mother Nature',
      partnerEmail: 'mnature@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'PylM0eTgz3Bx5Tb',
      approvalDate: '01/01/2001'
    }, {
      mid: 'mid24',
      legalName: 'GreendaleWI',
      dbaName: 'Greendale\'s Time',
      merchantEmail: 'gwitime@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Watches Galore',
      partnerEmail: 'watch@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'fIo2pOBFYxa2yS7',
      approvalDate: '01/01/2001'
    }, {
      mid: 'mid25',
      legalName: 'Primal Productions',
      dbaName: 'Primal Studios',
      merchantEmail: 'primerib@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Primal',
      partnerEmail: 'primesteak@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'HnUDv7BofCl9avX',
      approvalDate: '01/01/2001'
    }, {
      mid: 'mid26',
      legalName: 'Herb Corp',
      dbaName: 'Herbie\'s Herbs',
      merchantEmail: 'itsoregano@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Herbs R Us',
      partnerEmail: 'herbsRUs@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'Sf1gPZZEYBKyqlS',
      approvalDate: '01/01/2001'
    }, {
      mid: 'mid27',
      legalName: 'Gold Media',
      dbaName: 'AU Yeah',
      merchantEmail: 'aulikeoh@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Gold Media',
      partnerEmail: 'goldstandard@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'yS5tFS9DuWCP5zu',
      approvalDate: '01/01/2001'
    }],
    noRealProcessing: [{
      mid: 'mid28',
      legalName: 'Galerprises',
      dbaName: 'Gail the Snail',
      merchantEmail: 'nosaltplease@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Frank Reynolds',
      partnerEmail: 'warthog@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'WyyipqWZ5EGlED8',
      approvalDate: '01/01/2001'
    }, {
      mid: 'mid29',
      legalName: 'Alpite',
      dbaName: 'Alpite Stores',
      merchantEmail: 'alpite@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Alpite',
      partnerEmail: 'alpitepartners@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: '7R4EU0s4v5XkKRM',
      approvalDate: '01/01/2001'
    }, {
      mid: 'mid30',
      legalName: 'Woodmobile',
      dbaName: 'Woodmobile Auto Garage',
      merchantEmail: 'aretheysafe@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Tree Cars',
      partnerEmail: 'trees@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'MM8bc7T3UaS294H',
      approvalDate: '01/01/2001'
    }],
    stoppedProcessing: [{
      mid: 'mid31',
      legalName: 'Cubehive',
      dbaName: 'Cubehives For Sale',
      merchantEmail: 'squarebees@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'No More Hexagons',
      partnerEmail: 'squaresarebetter@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'II5mkM2SfqpDeXL',
      lastBatchDate: '01/01/2001',
      prior30DaySalesVolume: '1',
      prior30DaySalesCount: '2',
      prior30DayAverageTicket: '2'
    }, {
      mid: 'mid32',
      legalName: 'Redshack',
      dbaName: 'Redshack',
      merchantEmail: 'redshack@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Coloured Housing',
      partnerEmail: 'redvsblue@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'YSyR2pzWMzThTp7',
      lastBatchDate: '01/01/2001',
      prior30DaySalesVolume: '1',
      prior30DaySalesCount: '2',
      prior30DayAverageTicket: '2'
    }, {
      mid: 'mid33',
      legalName: 'Elite Co.',
      dbaName: 'Wort Wort Wort',
      merchantEmail: 'wort@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Sangheili',
      partnerEmail: 'sangheili@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'rlyKuX1MFkfuwze',
      lastBatchDate: '01/01/2001',
      prior30DaySalesVolume: '1',
      prior30DaySalesCount: '2',
      prior30DayAverageTicket: '2'
    }, {
      mid: 'mid34',
      legalName: 'Imagination Microsystems',
      dbaName: 'Microstore',
      merchantEmail: 'micro@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Microsystem Partners',
      partnerEmail: 'micropart@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'Cwm3jRfELXLVEGy',
      lastBatchDate: '01/01/2001',
      prior30DaySalesVolume: '1',
      prior30DaySalesCount: '2',
      prior30DayAverageTicket: '2'
    }, {
      mid: 'mid35',
      legalName: 'Hog Intelligence',
      dbaName: 'Clever Pig Shop',
      merchantEmail: 'hogintelligence@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Swineclue',
      partnerEmail: 'swineclue@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'WvL0a5o9flelgRb',
      lastBatchDate: '01/01/2001',
      prior30DaySalesVolume: '1',
      prior30DaySalesCount: '2',
      prior30DayAverageTicket: '2'
    }, {
      mid: 'mid36',
      legalName: 'Peach Co',
      dbaName: 'Peachco',
      merchantEmail: 'millionsofpeaches@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Fruits and Pips',
      partnerEmail: 'fruitsandpips@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'jTLHj5XBAmbWshR',
      lastBatchDate: '01/01/2001',
      prior30DaySalesVolume: '1',
      prior30DaySalesCount: '2',
      prior30DayAverageTicket: '2'
    }, {
      mid: 'mid37',
      legalName: 'Cannon Media',
      dbaName: 'Cannon Media',
      merchantEmail: 'boomingtunes@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Naval Warfare',
      partnerEmail: 'inthenavy@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'qWX0il2zGaLpalu',
      lastBatchDate: '01/01/2001',
      prior30DaySalesVolume: '1',
      prior30DaySalesCount: '2',
      prior30DayAverageTicket: '2'
    }, {
      mid: 'mid38',
      legalName: 'Maple Navigations',
      dbaName: 'Sticky Compass',
      merchantEmail: 'stickycompass@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Arborists',
      partnerEmail: 'arborists@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'AEhy1ee4sxAd3RJ',
      lastBatchDate: '01/01/2001',
      prior30DaySalesVolume: '1',
      prior30DaySalesCount: '2',
      prior30DayAverageTicket: '2'
    }, {
      mid: 'mid39',
      legalName: 'Tucan Co.',
      dbaName: 'Tucan Two Cans Bar',
      merchantEmail: 'colorfulbeak@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Birdemic',
      partnerEmail: 'birdemic@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'qyE7TNK8XfclEFt',
      lastBatchDate: '01/01/2001',
      prior30DaySalesVolume: '1',
      prior30DaySalesCount: '2',
      prior30DayAverageTicket: '2'
    }],
    shutdowns: [{
      mid: 'mid40',
      legalName: 'Frostfiretronics',
      dbaName: 'Hot and Cold',
      merchantEmail: 'frostfiretronics@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Ice and Fire Limited',
      partnerEmail: 'iafl@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'T7niUkoHKLVGlVz',
      closedDate: '01/01/2011',
      midLifeSpanInDays: '2'
    }, {
      mid: 'mid41',
      legalName: 'Apexi',
      dbaName: 'Apexi',
      merchantEmail: 'apexi@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Misc Business',
      partnerEmail: 'mis@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'krbK8KIYzXmnERu',
      closedDate: '01/01/2011',
      midLifeSpanInDays: '2'
    }, {
      mid: 'mid42',
      legalName: 'Shadearts',
      dbaName: 'Shadearts',
      merchantEmail: 'shadearts@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Obscure Company',
      partnerEmail: 'huh@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'tmubtWequXz42eJ',
      closedDate: '01/01/2011',
      midLifeSpanInDays: '2'
    }, {
      mid: 'mid43',
      legalName: 'Griffinhouse',
      dbaName: 'Eagle Lion Studios',
      merchantEmail: 'eagionstud@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Mystic Beasts',
      partnerEmail: 'mystbeas@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'T3yH9vNuEHzbv9g',
      closedDate: '01/01/2011',
      midLifeSpanInDays: '2'
    }, {
      mid: 'mid44',
      legalName: 'Pinnacle',
      dbaName: 'Pinnacle',
      merchantEmail: 'pinnacle@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Pinnacle Partners',
      partnerEmail: 'pp@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'ujY6eZrvs19TxlV',
      closedDate: '01/01/2011',
      midLifeSpanInDays: '2'
    }, {
      mid: 'mid45',
      legalName: 'Root Brews',
      dbaName: 'Rootin\' Tootin\' Boozin\'',
      merchantEmail: 'rootbrews@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'RootyTooty',
      partnerEmail: 'rootntoot@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'dunhKJgt4dkNdYO',
      closedDate: '01/01/2011',
      midLifeSpanInDays: '2'
    }, {
      mid: 'mid46',
      legalName: 'Trekords',
      dbaName: 'Trek Lords',
      merchantEmail: 'livelongandprosper@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Picard LTD',
      partnerEmail: 'picard@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'qisXeKlZT1LRTZt',
      closedDate: '01/01/2011',
      midLifeSpanInDays: '2'
    }, {
      mid: 'mid47',
      legalName: 'Oystermart',
      dbaName: 'Oystermart',
      merchantEmail: 'oystermart@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Mussels, Oysters, and Clams',
      partnerEmail: 'moc@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'RvtAch2kMvnFpAA',
      closedDate: '01/01/2011',
      midLifeSpanInDays: '2'
    }, {
      mid: 'mid48',
      legalName: 'Pumpkin Enterprises',
      dbaName: 'PumpKinz',
      merchantEmail: 'coolpumpkins@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Root2U',
      partnerEmail: 'root2u@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: '5Q4FUkrvhXLRIXq',
      closedDate: '01/01/2011',
      midLifeSpanInDays: '2'
    }, {
      mid: 'mid49',
      legalName: 'Butterflyght',
      dbaName: 'Butterflyght',
      merchantEmail: 'howdairy@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Air Butter',
      partnerEmail: 'airbutter@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'fP4XohwWouc8fUW',
      closedDate: '01/01/2011',
      midLifeSpanInDays: '2'
    }, {
      mid: 'mid50',
      legalName: 'Desertpaw',
      dbaName: 'Sandog',
      merchantEmail: 'sandog@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'SaharaPoochie',
      partnerEmail: 'sahapooc@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'wWpTFNueeJjhMTh',
      closedDate: '01/01/2011',
      midLifeSpanInDays: '2'
    }, {
      mid: 'mid51',
      legalName: 'Valkyrie Softwares',
      dbaName: 'Valkyrie Software',
      merchantEmail: 'valk@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Viper PLC',
      partnerEmail: 'theskiesbelongtome@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'mnqMPJFgkn36D0n',
      closedDate: '01/01/2011',
      midLifeSpanInDays: '2'
    }, {
      mid: 'mid52',
      legalName: 'Storm Sports',
      dbaName: 'Storm Sports',
      merchantEmail: 'stormsports@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Rain Team',
      partnerEmail: 'rainteam@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'PKs4SceLgZIXn6o',
      closedDate: '01/01/2011',
      midLifeSpanInDays: '2'
    }, {
      mid: 'mid53',
      legalName: 'Herculentertainment',
      dbaName: 'Hercules Entertainment',
      merchantEmail: 'herculeaneffort@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Zeus',
      partnerEmail: 'zeus@olympus.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: '8l4VH6UmUafQF59',
      closedDate: '01/01/2011',
      midLifeSpanInDays: '2'
    }, {
      mid: 'mid54',
      legalName: 'Thorecords',
      dbaName: 'Thor Records',
      merchantEmail: 'mjǫllnir@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Odin All Father',
      partnerEmail: 'one-eye@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'SFr0JhC0II2YDR8',
      closedDate: '01/01/2011',
      midLifeSpanInDays: '2'
    }, {
      mid: 'mid55',
      legalName: 'Pride Systems',
      dbaName: 'Systems of Pride',
      merchantEmail: 'systems@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Seven Syns',
      partnerEmail: 'seven@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: '6tUJZWMCr2VgeP1',
      closedDate: '01/01/2011',
      midLifeSpanInDays: '2'
    }, {
      mid: 'mid56',
      legalName: 'Sunshine Coms',
      dbaName: 'Sunshine',
      merchantEmail: 'sunny@test.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'Stars and Heat',
      partnerEmail: 'starsinheat@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'kU5UHAaiKsO24Di',
      closedDate: '01/01/2011',
      midLifeSpanInDays: '2'
    }, {
      mid: 'dzGXya0xjaEVbtC',
      legalName: 'MDIj6Ab1gAlajcD',
      dbaName: 'CTUH_ADD_2_closed-0',
      merchantEmail: 'f1paymentsengineering+dzgxya0xjaevbtc@gmail.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
      partnerEmail: 'CTUH_ADD_20@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'aU6vwE7F0Ti5shi',
      closedDate: '01/01/2011',
      midLifeSpanInDays: '2'
    }, {
      mid: 'KaMS2J0RYDnTxXz',
      legalName: '4c5Bo6HfvvRdwFl',
      dbaName: 'CTUH_ADD_2_closed-1',
      merchantEmail: 'f1paymentsengineering+kams2j0rydntxxz@gmail.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
      partnerEmail: 'CTUH_ADD_21@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'wGPPBFipon11OlA',
      closedDate: '01/01/2011',
      midLifeSpanInDays: '2'
    }, {
      mid: 'WgTeIPK0M8EMzry',
      legalName: 'IraSoYcIjoCqZxZ',
      dbaName: 'CTUH_ADD_2_closed-2',
      merchantEmail: 'f1paymentsengineering+wgteipk0m8emzry@gmail.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
      partnerEmail: 'CTUH_ADD_22@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'J1bMLxj8Tp2MNjN',
      closedDate: '01/01/2011',
      midLifeSpanInDays: '2'
    }, {
      mid: 'cPSLzxdZnXgniWh',
      legalName: 'mL9RObP7JHJn38B',
      dbaName: 'CTUH_ADD_2_closed-3',
      merchantEmail: 'f1paymentsengineering+cpslzxdznxgniwh@gmail.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
      partnerEmail: 'CTUH_ADD_23@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'QUQYxoBBXQD7QTF',
      closedDate: '01/01/2011',
      midLifeSpanInDays: '2'
    }, {
      mid: 'UGrCy9x9G0QHIwf',
      legalName: 'NAdgqJWHMXkCd2D',
      dbaName: 'CTUH_ADD_2_closed-4',
      merchantEmail: 'f1paymentsengineering+ugrcy9x9g0qhiwf@gmail.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
      partnerEmail: 'CTUH_ADD_24@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'MsNv2bNEpZ215xO',
      closedDate: '01/01/2011',
      midLifeSpanInDays: '2'
    }, {
      mid: 'ZzHmRJG1RiLMDwE',
      legalName: 'rZGXeNBVgNIZKhq',
      dbaName: 'CTUH_ADD_2_closed-5',
      merchantEmail: 'f1paymentsengineering+zzhmrjg1rilmdwe@gmail.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
      partnerEmail: 'CTUH_ADD_25@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'spwYVTGvYPgZmMx',
      closedDate: '01/01/2011',
      midLifeSpanInDays: '2'
    }, {
      mid: 'v3trVTDOSfRMTbb',
      legalName: 'Mrx4V2kpUtUciCW',
      dbaName: 'CTUH_ADD_2_closed-6',
      merchantEmail: 'f1paymentsengineering+v3trvtdosfrmtbb@gmail.com',
      merchantPhoneNumber: '+15125536624',
      partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
      partnerEmail: 'CTUH_ADD_26@test.com',
      partnerPhoneNumber: '+15125536624',
      relationshipCode: 'sUyCQr7yhyBxvRA',
      closedDate: '01/01/2011',
      midLifeSpanInDays: '2'
    }],
    runningHot: Array.from({ length: getMockValue({ type: 'number', min: 1, max: 20 }) }).map(() => {
      const mockUser = getMockValue({ type: 'user', userType: 'external' });
      const mockRelationship = mockRelationshipGetFormatted[getMockValue({ type: 'number', min: 0, max: mockRelationshipGetFormatted.length })];
      return {
        mid: getMockValue({ type: 'mid' }),
        legalName: getMockValue({ type: 'businessName' }),
        dbaName: getMockValue({ type: 'businessName' }),
        merchantEmail: getMockValue({ type: 'email', userType: 'external' }),
        merchantPhoneNumber: getMockValue({ type: 'bePhone' }),
        partnerName: mockUser.fullName,
        partnerEmail: mockUser.email,
        partnerPhoneNumber: getMockValue({ type: 'bePhone' }),
        relationshipCode: mockRelationship.relationshipCode,
        relationshipName: mockRelationship.title,
        approvalDate: getMockValue({ type: 'date' }),
        monthToDateChargebackCountForLegalEntity: getMockValue({ type: 'number' }),
        monthToDateChargebackRatioForLegalEntity: getMockValue({ type: 'ratio' }),
        currentMonthlyVolume: getMockValue({ type: 'number', min: 0, max: 1000000 }),
        currentUsedVolumeRatio: getMockValue({ type: 'ratio' }),
        projectedMonthlyVolumeAtCurrentRunRate: getMockValue({ type: 'number', min: 0, max: 1000000 }),
        totalAllowedMonthlyVolume: getMockValue({ type: 'number', min: 0, max: 1000000 })
      };
    })
  }
};

export const mockPartnerNotificationsGetRes = (body, params, options) => {
  const { guid = '' } = options;
  const mockData = mockPartnerNotifications[guid] || mockPartnerNotifications[partnerGuids.default];
  // defaults to the "all" tickets data if the guid isn't in the map
  return { [guid]: mockData };
};
