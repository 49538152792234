import React from 'react';
import PropTypes from 'prop-types';
import {
  dedupeList,
  ignoreCase,
  isEmpty,
  isEqual,
  nestedFind,
  sortData
} from './_helpers';
import { Button } from './Button';
import { Input } from './Input';

import { icons } from '../images/_icons';
import { nestedMenuCSS } from './_styles';

export class CustomSearch extends React.Component {
  constructor (props) {
    super(props);
    this.mounted = false;
    this.state = {
      menuSearchValue: '',
      selectedSearchKeys: []
    };
  }

  componentDidMount () {
    const { existingSearchValue, searchKeys } = this.props;
    this.mounted = true;
    if (!isEmpty(searchKeys)) {
      this.updateState({ selectedSearchKeys: searchKeys.map(item => item.value) });
    }
    if (!isEmpty(existingSearchValue)) {
      this.updateState({ menuSearchValue: existingSearchValue });
    } else {
      this.clearSearch();
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { searchKeys } = this.props;
    const { menuSearchValue } = this.state;
    if (!isEqual(prevProps.searchKeys, searchKeys) && !isEmpty(searchKeys)) {
      this.updateState({ selectedSearchKeys: searchKeys.map(item => item.value) });
    }
    if (!isEqual(prevState.menuSearchValue, menuSearchValue) && isEmpty(menuSearchValue)) {
      this.clearSearch({ useCallback: true });
    }
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  updateState = (state, callback) => {
    this.mounted && this.setState(state, callback);
  }

  handleInput = (id, value, valid, options) => {
    const { submitOnEnter } = options || {};
    this.updateState({
      [`${id}Value`]: value
    }, !isEmpty(value) && submitOnEnter ? this.handleSearch : null);
  }

  clearSearch = (options) => {
    const { useCallback } = options || {};
    const { searchCallback, items, list } = this.props;
    if (isEmpty(list)) {
      this.updateState({
        filteredItems: items,
        menuSearchValue: ''
      }, useCallback ? () => searchCallback({ results: items, value: '' }) : null);
    } else {
      this.updateState({
        filteredList: list,
        menuSearchValue: ''
      }, useCallback ? () => searchCallback({ results: list, value: '' }) : null);
    }
    return true;
  }

  handleSearch = (event) => {
    const { list } = this.props;
    const { menuSearchValue } = this.state;
    event && event.preventDefault();
    if (isEmpty(list)) {
      this.nestedSearch(menuSearchValue);
    } else {
      this.flatSearch(menuSearchValue);
    }
  }

  nestedSearch = (value) => {
    const {
      selectedSearchKeys
    } = this.state;
    const {
      searchCallback,
      items,
      subKey,
      merchantKey
    } = this.props;
    const arr = [
      ...(!isEmpty(items) && items[subKey] ? items[subKey] : []),
      ...(!isEmpty(items) && !isEmpty(items[merchantKey]) && items[merchantKey]?.length > 0
        ? items[merchantKey] : [])
    ];
    const options = {
      ignoreKey: 'type',
      ignoreValue: subKey,
      sortBy: {
        title: 'dba',
        value: 'guid'
      }
    };
    const result = nestedFind(arr, selectedSearchKeys, value, options);
    if (result?.length > 0) {
      this.updateState({
        filteredItems: result
      }, () => searchCallback({ results: result, value }));
    } else {
      this.updateState({ filteredItems: {} }, () => searchCallback({ results: {}, value }));
    }
    this.updateState({ menuSearchValue: value });
  }

  flatSearch = (value) => {
    const { list, searchCallback } = this.props;
    const { selectedSearchKeys } = this.state;
    let filteredList = [];
    selectedSearchKeys.forEach((key) => {
      filteredList = filteredList.concat(
        list.filter(item => ignoreCase(item?.[key]).includes(ignoreCase(value)))
      );
    });
    const uniqueList = dedupeList(filteredList);
    const newList = !isEmpty(uniqueList) ? sortData(uniqueList, 'title') : [];
    this.updateState({
      filteredList: newList
    }, () => searchCallback({ results: newList, value }));
  }

  render () {
    const {
      menuSearchValue
    } = this.state;
    const {
      children,
      closeCallback,
      id,
      searchKeys
    } = this.props;
    return (
      <>
        <div id={id} style={{ ...nestedMenuCSS.cancelBox, height: '60px' }}>
          <div id="limited-search-options" style={{ display: 'flex' }}>
            <div style={{ fontSize: '0.8em', paddingLeft: '7px' }}>
              <span>Search by: </span>
              <strong>
                {searchKeys.map((item, index) => (
                  <span key={`${item?.value}_${index.toString()}`}>
                    {`${item?.title}${index < searchKeys.length - 1 ? ', ' : ''}`}
                  </span>
                ))}
              </strong>
            </div>
          </div>
          { !isEmpty(closeCallback) ? (
            <Button
              id="cancelLink"
              size="sm"
              style={nestedMenuCSS.cancelLink}
              onClick={closeCallback || (() => {})}
            >
              Close
            </Button>
          ) : (<>{ children }</>)}
          <div>
            <Input
              type="text"
              id="menuSearch"
              callback={this.handleInput}
              allowSubmitOnEnter={!isEmpty(menuSearchValue)}
              value={menuSearchValue}
              height={20}
              noValidate
              wrapperStyle={{
                position: 'absolute',
                bottom: '3px',
                right: '100px',
                width: 'calc(100% - 160px)'
              }}
            />
          </div>

          <Button
            id="clearLink"
            type="text"
            style={{ ...nestedMenuCSS.clearSearch, top: '36px' }}
            onClick={this.clearSearch}
            disabled={isEmpty(menuSearchValue)}
          >
            Clear
          </Button>
        </div>
        <Button
          id="submitSearchButton"
          type="text"
          onClick={this.handleSearch}
          disabled={menuSearchValue?.length === 0}
          size="sm"
          icon={icons.magnify}
          style={{
            position: 'absolute',
            bottom: '4px',
            right: '43px',
            fontSize: '1.5rem',
            zIndex: 100,
            height: '22px'
          }}
        >
          Go
        </Button>
      </>
    );
  }
}

CustomSearch.propTypes = {
  id: PropTypes.string,
  existingSearchValue: PropTypes.string,
  searchCallback: PropTypes.func,
  /* Used with MultiSelect */
  children: PropTypes.node,
  list: PropTypes.oneOfType([PropTypes.array]),
  /* Used with NestedMenu */
  closeCallback: PropTypes.func,
  items: PropTypes.oneOfType([PropTypes.object]),
  searchKeys: PropTypes.oneOfType([PropTypes.array]),
  subKey: PropTypes.string,
  merchantKey: PropTypes.string
};

CustomSearch.defaultProps = {
  id: null,
  existingSearchValue: '',
  searchCallback: () => {},
  /* Used with MultiSelect */
  children: null,
  list: [],
  /* Used with NestedMenu */
  closeCallback: null,
  items: {},
  searchKeys: [],
  subKey: 'subPartner',
  merchantKey: 'merchant'
};

export default CustomSearch;
