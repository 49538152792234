import React from 'react';
import PropTypes from 'prop-types';
import { customApiError } from './_styles';
import { getErrorMessage } from './_helpers';

import { NoData } from './NoData';

export class CustomApiErr extends React.PureComponent {
  constructor (props) {
    super(props);
    this.state = {};
  }

  render () {
    const {
      customErr,
      status,
      loading,
      customMessage,
      styles
    } = this.props;
    return (
      <React.Fragment>
        { !loading ? (
          <React.Fragment>
            { customErr
              ? (
                <div className="customErr" style={styles.wrapperStyle} aria-label="Error">
                  <div
                    style={{
                      ...customApiError.image,
                      ...styles.imageStyle
                    }}
                  />
                  <div className="errorMessage" style={{ ...customApiError.code, ...styles.messageStyle }}>
                    {getErrorMessage(status)}
                  </div>
                </div>
              )
              : (
                <NoData wrapperStyles={{ textAlign: 'center' }} customMessage={customMessage} />
              )}
          </React.Fragment>
        ) : (
          <NoData
            loading={loading}
            wrapperStyles={{ textAlign: 'center' }}
            customMessage={loading ? null : customMessage}
          />
        )}
      </React.Fragment>
    );
  }
}

CustomApiErr.propTypes = {
  customErr: PropTypes.bool,
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool,
  customMessage: PropTypes.string,
  styles: PropTypes.shape({
    wrapperStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    imageStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    messageStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  })
};

CustomApiErr.defaultProps = {
  customErr: false,
  status: null,
  loading: false,
  customMessage: null,
  styles: {
    wrapperStyle: {},
    imageStyle: {},
    messageStyle: {}
  }
};

export default CustomApiErr;
