import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isEmpty, isEqual } from './_helpers';
import { user, arrowRight } from '../images/_icons';

const ActiveMenuItem = styled.li`
  clear: both;
  display: ${props => (props.isSub ? 'flex' : 'box')};
  font-size: 1.3rem;
  line-height: 1.4;
  overflow: hidden;
  &:hover {
    ${props => (props.isSub
    ? `
      background-color: var(--color-menu-hover);
    `
    : `
    `
  )}
  }
  .sub-more {
    border-left: 1px solid var(--color-data-border);
    font-size: 1.1rem;
    display: block;
    width: 90px;
    float: left;
    padding: 1px 5px;
    margin: 2px 0;
    outline: none;
  }
  .sub-no-data {
    color: var(--color-status-closed);
  }
  .exploreLink {
    cursor: pointer;
    background-image: ${arrowRight.src_color};
    background-position: right 3px top 3px;
    background-repeat: no-repeat;
    background-size: 15%;
    &:hover {
      color: white;
      background-color: var(--color-primary);
      background-image: ${arrowRight.src_white};
    }
  }
`;

const MenuItemLink = styled.div`
  padding: 5px;
  display: block;
  cursor: ${props => (props.isSub ? 'default' : 'pointer')};
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  word-wrap: break-word;
  ${props => (props.isSub
    ? `
      float: left;
      width: calc(100% - 90px);
      padding-left: 25px;
      background-image: ${user().src_color};
      background-repeat: no-repeat;
      background-position: left 5px top 6px;
      background-size: 15px;
    `
    : `
    `
  )}
  &:hover {
    background-color: var(--color-menu-hover);
  }
`;

export class NestedMenuItem extends React.Component {
  constructor (props) {
    super(props);
    this.mounted = false;
    const { initialValue } = props;
    this.state = {
      active: initialValue,
      hasData: false,
      isSub: false,
      totalItems: 0
    };
  }

  componentDidMount () {
    this.mounted = true;
    this.initialize();
  }

  componentDidUpdate (prevProps, prevState) {
    const { initialValue } = this.props;
    if (!isEqual(initialValue, prevProps.initialValue)) {
      this.updateState({ active: initialValue });
    }
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  updateState = (state, callback = null) => {
    this.mounted && this.setState(state, callback);
  }

  initialize = () => {
    const { item, merchantKey, subKey } = this.props;
    const isSub = item.type === subKey;
    let totalItems = 0;
    if (isSub) {
      totalItems = item.totalMerchantCount;
    } else {
      [subKey, merchantKey].forEach((key) => {
        totalItems += (!isEmpty(item) && item[key] ? item[key].length : 0);
      });
    }
    const hasData = (isSub && totalItems > 0) || totalItems > 0;
    this.updateState({ isSub, totalItems, hasData });
  }

  handleClick = () => {
    const { item, value } = this.props;
    this.updateState({
      active: item[value] || ''
    }, this.handleClickCallback);
  }

  handleClickCallback = () => {
    const { clickCallback, item } = this.props;
    clickCallback && clickCallback({}, item);
  }

  handleExplore = (e) => {
    const { exploreCallback, index, item } = this.props;
    e && e.preventDefault();
    exploreCallback && exploreCallback(e, item, index);
  }

  render () {
    const {
      item,
      keyPressCallback,
      title,
      value
    } = this.props;
    const {
      active,
      hasData,
      isSub,
      totalItems
    } = this.state;
    return (
      <ActiveMenuItem isSub={isSub} className="activeMenuItem">
        <MenuItemLink
          className="nm"
          isSub={isSub}
          role="button"
          aria-label={item[title]}
          tabIndex="0"
          onKeyUp={keyPressCallback}
          onClick={!isSub ? this.handleClick : () => {}}
          style={{
            ...(!isSub && active === item[value] && {
              color: 'white',
              cursor: 'default',
              backgroundColor: 'hsl(var(--color-secondary-hue), var(--color-secondary-saturation), calc(var(--color-secondary-lightness) + 12%))'
            })
          }}
        >
          {item[title]}
        </MenuItemLink>
        {isSub && (
          <>
            {hasData
              ? (
                <div
                  className="nm sub-more exploreLink"
                  role="button"
                  aria-label={`More ${item[title]}`}
                  tabIndex="0"
                  onClick={this.handleExplore}
                  onKeyUp={keyPressCallback}
                >
                  {`More (${totalItems})`}
                </div>
              )
              : (
                <div className="nm sub-more sub-no-data">{`(${0})`}</div>
              )}
          </>
        )}
      </ActiveMenuItem>
    );
  }
}

NestedMenuItem.propTypes = {
  clickCallback: PropTypes.func,
  exploreCallback: PropTypes.func,
  index: PropTypes.number,
  initialValue: PropTypes.string,
  item: PropTypes.oneOfType([PropTypes.object]),
  keyPressCallback: PropTypes.func,
  merchantKey: PropTypes.string,
  subKey: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string
};

NestedMenuItem.defaultProps = {
  clickCallback: () => {},
  exploreCallback: () => {},
  index: 0,
  initialValue: '',
  item: {},
  keyPressCallback: () => {},
  merchantKey: 'merchant',
  subKey: 'subPartner',
  title: '',
  value: ''
};

export default NestedMenuItem;
