import { domainName, transformData } from '@f1/shared/src/_helpers';
import fileV2Template from './templates/fileV2Template';

const mockFiles = {
  file01: {
    fileName: 'logo192.png',
    relativePath: '/RISK/recipe/forDestruction/logo192.png',
    url: `http://local.${domainName}.com:3000`
  },
  file02: {
    fileName: 'favicon.ico',
    relativePath: '/COMMON',
    url: `http://local.${domainName}.com:3000`
  },
  file03: {
    fileName: 'robots2.txt',
    relativePath: null,
    tags: [{ tagObject: 'uploaded_by_partner' }, { tagObject: 'employee_group_risk' }, { tagObject: 'merchant_info_form' }],
    url: `http://local.${domainName}.com:3000`
  },
  file04: {
    fileName: 'robots.txt',
    relativePath: '',
    tags: [{ tagObject: 'uploaded_by_employee' }, { tagObject: 'employee_group_all_employees' }, { tagObject: 'fee_file' }],
    url: `http://local.${domainName}.com:3000`
  },
  file05: {
    fileName: 'otherPictures.png',
    relativePath: '/OPERATIONS/recipe/chiffoncake/',
    url: 'https://via.placeholder.com/3000x2000/09f/fff.png'
  },
  file06: {
    fileName: 'pictures2.png',
    relativePath: '/OPERATIONS/recipe/chiffoncake/',
    url: 'https://via.placeholder.com/3000x200/09f/fff.png'
  }
};

export const mockFormattedFiles = transformData({
  data: [mockFiles.file01, mockFiles.file02, mockFiles.file03, mockFiles.file04, mockFiles.file05,
    mockFiles.file06],
  toSchema: 'frontend',
  template: fileV2Template
});

export default mockFiles;
