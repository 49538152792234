import {
  snakeToTitle
} from '@f1/shared/src/_helpers';

// hey you modifying this code, are you adding an employee group?
// if so, please check out this PR to make sure you are adding it everywhere needed
// BIRB 6957. Please add in all places that PR adds to
export const mockEmployeeGroups = {
  analytics: {
    employeeGroupName: 'ANALYTICS',
    employeeGroupId: '763e5634-a205-41a0-bf25-39f68d51c93a',
    triageEmployee: {
      employeeId: null,
      firstName: null,
      lastName: null,
      email: null
    }
  },
  appReview: {
    employeeGroupName: 'APP_REVIEW',
    employeeGroupId: 'b1d12694-0e8d-96ba-062b-6370f2a50e4f',
    triageEmployee: {
      employeeId: null,
      firstName: null,
      lastName: null,
      email: null
    }
  },
  appReviewManager: {
    employeeGroupName: 'APP_REVIEW_MANAGER',
    employeeGroupId: '9456da57-5259-77c8-e27d-e024a4306760',
    triageEmployee: {
      employeeId: null,
      firstName: null,
      lastName: null,
      email: null
    }
  },
  compliance: {
    employeeGroupName: 'COMPLIANCE',
    employeeGroupId: '09cbf010-69b5-4170-b298-8f1b73384976',
    triageEmployee: {
      employeeId: null,
      firstName: null,
      lastName: null,
      email: null
    }
  },
  credit: {
    employeeGroupName: 'CREDIT',
    employeeGroupId: 'e07b0a1a-3bbb-11eb-adc1-0242ac120002',
    triageEmployee: {
      employeeId: null,
      firstName: null,
      lastName: null,
      email: null
    }
  },
  creditManager: {
    employeeGroupId: '55c05612-4c26-4d00-867c-b8931a6d0bfe',
    employeeGroupName: 'CREDIT_MANAGER',
    triageEmployee: {
      employeeId: null,
      firstName: null,
      lastName: null,
      email: null
    }
  },
  crossRiver: {
    employeeGroupName: 'CROSS_RIVER',
    employeeGroupId: '93a61d24-a510-4665-a7fc-319f29ef3df1',
    triageEmployee: {
      employeeId: null,
      firstName: null,
      lastName: null,
      email: null
    }
  },
  cynergy: {
    employeeGroupName: 'CYNERGY',
    employeeGroupId: '7e4d94c5-1bf7-43a6-8c88-72bb994aa936',
    triageEmployee: {
      employeeId: null,
      firstName: null,
      lastName: null,
      email: null
    }
  },
  engineering: {
    employeeGroupName: 'ENGINEERING',
    employeeGroupId: '9c4b4b86-1564-41c3-8d98-b11a6b79755a',
    triageEmployee: {
      employeeId: null,
      firstName: null,
      lastName: null,
      email: null
    }
  },
  esquire: {
    employeeGroupName: 'ESQUIRE',
    employeeGroupId: '3e6399f6-3329-6736-ea7c-743dd2f44e40',
    triageEmployee: {
      employeeId: null,
      firstName: null,
      lastName: null,
      email: null
    }
  },
  fifthThird: {
    employeeGroupName: 'FIFTH_THIRD',
    employeeGroupId: 'bd744b34-d001-4421-9c49-39f4500a4c5e',
    triageEmployee: {
      employeeId: null,
      firstName: null,
      lastName: null,
      email: null
    }
  },
  humanResources: {
    employeeGroupName: 'HUMAN_RESOURCES',
    employeeGroupId: '3593a18c-c96a-4d2e-aa58-ce66b8c478a0',
    triageEmployee: {
      employeeId: null,
      firstName: null,
      lastName: null,
      email: null
    }
  },
  it: {
    employeeGroupName: 'IT',
    employeeGroupId: '29d1db2a-6a9b-4502-86fe-6fe92d62cabe',
    triageEmployee: {
      employeeId: null,
      firstName: null,
      lastName: null,
      email: null
    }
  },
  marketing: {
    employeeGroupName: 'MARKETING',
    employeeGroupId: '52e339d3-f435-4a9b-9b93-dff8797bfe16',
    triageEmployee: {
      employeeId: null,
      firstName: null,
      lastName: null,
      email: null
    }
  },
  operationsLegacy: {
    employeeGroupName: 'OPERATIONS_LEGACY',
    employeeGroupId: 'f1454c4c-852d-487f-9575-8738a33739d9',
    triageEmployee: {
      employeeId: null,
      firstName: null,
      lastName: null,
      email: null
    }
  },
  operationsManager: {
    employeeGroupName: 'OPERATIONS_MANAGER',
    employeeGroupId: 'mock-ops-manager-employee-group-id',
    triageEmployee: {
      employeeId: null,
      firstName: null,
      lastName: null,
      email: null
    }
  },
  product: {
    employeeGroupName: 'PRODUCT',
    employeeGroupId: '3b0baf06-399a-41ca-b927-cd703187566e',
    triageEmployee: {
      employeeId: null,
      firstName: null,
      lastName: null,
      email: null
    }
  },
  project: {
    employeeGroupName: 'PROJECT',
    employeeGroupId: 'd547c4c4-6ef0-4269-8373-37f61e7604b4',
    triageEmployee: {
      employeeId: null,
      firstName: null,
      lastName: null,
      email: null
    }
  },
  residual: {
    employeeGroupName: 'RESIDUAL',
    employeeGroupId: 'e09a2b0f-418b-9460-1dce-5b9dc87f46d6',
    triageEmployee: {
      employeeId: null,
      firstName: null,
      lastName: null,
      email: null
    }
  },
  risk: {
    employeeGroupName: 'RISK',
    employeeGroupId: '2c444626-0a55-44cb-a431-e0ca5b56c734',
    triageEmployee: {
      employeeId: null,
      firstName: null,
      lastName: null,
      email: null
    }
  },
  riskAdmin: {
    employeeGroupName: 'RISK_CLOSURE_ADMIN',
    employeeGroupId: '2c444626-0a55-44cb-a431-e0ca5b56c733',
    triageEmployee: {
      employeeId: null,
      firstName: null,
      lastName: null,
      email: null
    }
  },
  riskManager: {
    employeeGroupName: 'RISK_MANAGER',
    employeeGroupId: 'b6dd3a91-26a6-28d4-4d46-37c806c4e903',
    triageEmployee: {
      employeeId: null,
      firstName: null,
      lastName: null,
      email: null
    }
  },
  sales: {
    employeeGroupName: 'SALES',
    employeeGroupId: '66be3805-d94f-46e0-81f2-ee63a21fa430',
    triageEmployee: {
      employeeId: null,
      firstName: null,
      lastName: null,
      email: null
    }
  },
  operations: {
    employeeGroupName: 'OPERATIONS',
    employeeGroupId: '37320ed1-1e85-42a8-8d9e-1be9ac19ba6c',
    triageEmployee: {
      employeeId: null,
      firstName: null,
      lastName: null,
      email: null
    }
  },
  synovus: {
    employeeGroupName: 'SYNOVUS',
    employeeGroupId: '20a955bc-b660-4f9f-b062-3edb23e67457',
    triageEmployee: {
      employeeId: null,
      firstName: null,
      lastName: null,
      email: null
    }
  },
  vimas: {
    employeeGroupName: 'VIMAS',
    employeeGroupId: '757c42e9-aef6-453e-a03a-d09bc3d3f98b',
    triageEmployee: {
      employeeId: null,
      firstName: null,
      lastName: null,
      email: null
    }
  },
  wellsFargo: {
    employeeGroupName: 'WELLS_FARGO',
    employeeGroupId: 'e3d26a05-a096-4c14-8daf-b3765237c77a',
    triageEmployee: {
      employeeId: null,
      firstName: null,
      lastName: null,
      email: null
    }
  },
  mvb: {
    employeeGroupName: 'MVB',
    employeeGroupId: '78e35d4f-5e84-8580-08c0-8c8f28be8fc9',
    triageEmployee: {
      employeeId: null,
      firstName: null,
      lastName: null,
      email: null
    }
  }
};

export const mockEmployeeGroupGetRes = Object.values(mockEmployeeGroups); // api response

export const mockEmployeeGroupList = mockEmployeeGroupGetRes.map(employee => ({ // deserialized data
  title: snakeToTitle(employee.employeeGroupName),
  value: employee.employeeGroupId
}));
