import { mockRelationships } from '../relationship/mockRelationships';
import { mockPartners } from './mockPartners';

export const mockRelationshipMapping = {
  relationshipMap01: {
    response: {
      relationshipName: mockRelationships.relationship07.response.relationshipName,
      relationshipId: mockRelationships.relationship07.response.relationshipId,
      relationshipCode: mockRelationships.relationship07.response.relationshipCode,
      partnerId: mockPartners.partner02.response.partnerId,
      partnerName: mockPartners.partner02.response.partnerName,
      partnerBusinessCode: mockPartners.partner02.response.partnerCode
    },
    deserialized: {
      title: mockRelationships.relationship07.response.relationshipName,
      value: mockRelationships.relationship07.response.relationshipId,
      partnerId: mockPartners.partner02.response.partnerId,
      partnerName: mockPartners.partner02.response.partnerName
    }
  },
  relationshipMap02: {
    response: {
      relationshipName: mockRelationships.relationship01.response.relationshipName,
      relationshipId: mockRelationships.relationship01.response.relationshipId,
      relationshipCode: mockRelationships.relationship01.response.relationshipCode,
      partnerId: mockPartners.partner01.response.partnerId,
      partnerName: mockPartners.partner01.response.partnerName,
      partnerBusinessCode: mockPartners.partner01.response.partnerCode
    },
    deserialized: {
      title: mockRelationships.relationship01.response.relationshipName,
      value: mockRelationships.relationship01.response.relationshipId,
      partnerId: mockPartners.partner01.response.partnerId,
      partnerName: mockPartners.partner01.response.partnerName
    }
  },
  relationshipMap03: {
    response: {
      relationshipName: mockRelationships.relationship02.response.relationshipName,
      relationshipId: mockRelationships.relationship02.response.relationshipId,
      relationshipCode: mockRelationships.relationship02.response.relationshipCode,
      partnerId: mockPartners.partner04.response.partnerId,
      partnerName: mockPartners.partner04.response.partnerName,
      partnerBusinessCode: mockPartners.partner04.response.partnerCode
    },
    deserialized: {
      title: mockRelationships.relationship02.response.relationshipName,
      value: mockRelationships.relationship02.response.relationshipId,
      partnerId: mockPartners.partner04.response.partnerId,
      partnerName: mockPartners.partner04.response.partnerName
    }
  },
  relationshipMap04: {
    response: {
      relationshipName: mockRelationships.relationship03.response.relationshipName,
      relationshipId: mockRelationships.relationship03.response.relationshipId,
      relationshipCode: mockRelationships.relationship03.response.relationshipCode,
      partnerId: mockPartners.partner05.response.partnerId,
      partnerName: mockPartners.partner04.response.partnerName,
      partnerBusinessCode: mockPartners.partner04.response.partnerCode
    },
    deserialized: {
      title: mockRelationships.relationship03.response.relationshipName,
      value: mockRelationships.relationship03.response.relationshipId,
      partnerId: mockPartners.partner05.response.partnerId,
      partnerName: mockPartners.partner04.response.partnerName
    }
  },
  relationshipMap05: {
    response: {
      relationshipName: mockRelationships.relationship04.response.relationshipName,
      relationshipId: mockRelationships.relationship04.response.relationshipId,
      relationshipCode: mockRelationships.relationship04.response.relationshipCode,
      partnerId: mockPartners.partner06.response.partnerId,
      partnerName: mockPartners.partner06.response.partnerName,
      partnerBusinessCode: mockPartners.partner06.response.partnerCode
    },
    deserialized: {
      title: mockRelationships.relationship04.response.relationshipName,
      value: mockRelationships.relationship04.response.relationshipId,
      partnerId: mockPartners.partner06.response.partnerId,
      partnerName: mockPartners.partner06.response.partnerName
    }
  },
  relationshipMap06: {
    response: {
      relationshipName: mockRelationships.relationship02.response.relationshipName,
      relationshipId: mockRelationships.relationship02.response.relationshipId,
      relationshipCode: mockRelationships.relationship02.response.relationshipCode,
      partnerId: mockPartners.partner04.response.partnerId,
      partnerName: mockPartners.partner04.response.partnerName,
      partnerBusinessCode: mockPartners.partner04.response.partnerCode
    },
    deserialized: {
      title: mockRelationships.relationship02.response.relationshipName,
      value: mockRelationships.relationship02.response.relationshipId,
      partnerId: mockPartners.partner04.response.partnerId,
      partnerName: mockPartners.partner04.response.partnerName
    }
  },
  relationshipMap07: {
    response: {
      relationshipName: mockRelationships.relationship08.response.relationshipName,
      relationshipId: mockRelationships.relationship08.response.relationshipId,
      relationshipCode: mockRelationships.relationship08.response.relationshipCode,
      partnerId: mockPartners.partner07.response.partnerId,
      partnerName: mockPartners.partner07.response.partnerName,
      partnerBusinessCode: mockPartners.partner07.response.partnerCode
    },
    deserialized: {
      title: mockRelationships.relationship08.response.relationshipName,
      value: mockRelationships.relationship08.response.relationshipId,
      partnerId: mockPartners.partner07.response.partnerId,
      partnerName: mockPartners.partner07.response.partnerName
    }
  },
  relationshipMap08: {
    response: {
      relationshipName: mockRelationships.relationship09.response.relationshipName,
      relationshipId: mockRelationships.relationship09.response.relationshipId,
      relationshipCode: mockRelationships.relationship09.response.relationshipCode,
      partnerId: mockPartners.partner07.response.partnerId,
      partnerName: mockPartners.partner07.response.partnerName,
      partnerBusinessCode: mockPartners.partner07.response.partnerCode
    },
    deserialized: {
      title: mockRelationships.relationship09.response.relationshipName,
      value: mockRelationships.relationship09.response.relationshipId,
      partnerId: mockPartners.partner07.response.partnerId,
      partnerName: mockPartners.partner07.response.partnerName
    }
  },
  relationshipMap09: {
    response: {
      relationshipName: mockRelationships.relationship10.response.relationshipName,
      relationshipId: mockRelationships.relationship10.response.relationshipId,
      relationshipCode: mockRelationships.relationship10.response.relationshipCode,
      partnerId: mockPartners.partner07.response.partnerId,
      partnerName: mockPartners.partner07.response.partnerName,
      partnerBusinessCode: mockPartners.partner07.response.partnerCode
    },
    deserialized: {
      title: mockRelationships.relationship10.response.relationshipName,
      value: mockRelationships.relationship10.response.relationshipId,
      partnerId: mockPartners.partner07.response.partnerId,
      partnerName: mockPartners.partner07.response.partnerName
    }
  }
};

export default mockRelationshipMapping;
