import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { getMockEmployee } from '../_mockHelpers';

export const createMockProhibitedEntities = (options) => {
  const { length } = options || {};
  const mockArray = Array.from({ length: length ?? 26 }).map((item, index) => {
    const mockPerson = getMockValue({ type: 'user' });
    const mockAddressSigner = getMockValue({ type: 'address' });
    const mockAddressBusiness = getMockValue({ type: 'address' });
    const mockEmployee = getMockEmployee({ includeManager: true });
    const mockItem = {
      ssn: getMockValue({ type: 'ssn' }),
      ein: getMockValue({ type: 'ssn' }),
      personName: mockPerson.fullName,
      legalName: `Mock Legal Name ${index + 1}`,
      fundingAccount: getMockValue({ type: 'string' }),
      signerAddressObject: {
        address: mockAddressSigner.fullAddress,
        address2: null,
        city: mockAddressSigner.cityName,
        state: mockAddressSigner.stateCode,
        zip: mockAddressSigner.zipCode
      },
      businessAddressObject: {
        address: mockAddressBusiness.fullAddress,
        address2: null,
        city: mockAddressBusiness.cityName,
        state: mockAddressBusiness.stateCode,
        zip: mockAddressBusiness.zipCode
      },
      reasonCode: getMockValue({ type: 'list', list: ['uw01-fraudulent_application', 'uw02-product_or_service_provided_outside_policy', 'uw03-match-listed_entity', 'uw04-ofac-listed_entity', 'uw05-illicit_activity', 'rm01-closed_due_to_fraud', 'rm02-closed_due_to_excessive_chargebacks', 'rm03-match-listed_entity', 'rm04-ofac-listed_entity', 'rm05-illicit_activity', 'rm06-closed_due_to_gateway_non-compliance', 'rm07-transaction_laundering', 'rm08-non-compliant_billing_practices', 'rm11-closed_due_to_bank_risk_request', 'rm12-closed_due_to_cardholder_data_breach', 'rm13-closed_due_to_unpaid_owned_amount', 'rm14-closed_due_to_card_brand_request'] }),
      employeeWhoInserted: {
        email: mockEmployee.email,
        firstName: mockEmployee.firstName,
        lastName: mockEmployee.lastName,
        manager: mockEmployee.manager,
        employeeId: mockEmployee.employeeId
      },
      prohibitedEntityId: `mock-prohibited-entity-id-${index}`
    };
    return mockItem;
  });
  return mockArray;
};

export const mockRiskProhibitedEntitiesGet = (body, params, options) => {
  const mockData = createMockProhibitedEntities();
  return { prohibitedEntitiesList: mockData };
};

export const mockRiskProhibitedEntitiesPut = (body, params, options) => {
  const { guid = '[]' } = options || {};
  return { [guid]: { prohibitedEntityId: `mock-prohibited-entity-id-1` } };
};
