import React from 'react';
import PropTypes from 'prop-types';
import {
  FileImageListWrapper,
  FileImageRowLink,
  FileImageRowWrapper,
  Icon
} from '../css/_styledComponents';
import { isEmpty, isEqual, transformData } from './_helpers';
import { filesWithTagsCSS } from './_styles';
import { icons } from '../images/_icons';
import {
  DropzoneFileList,
  Spinner,
  CustomApiErr
} from '../index';
import { DataBox } from './DataBox';
import filesWithTagsTemplate, { getTagObjects, sortSelectedTags } from './data/sharedBoarding/templates/filesWithTagsTemplate';
import { AlertBar } from './AlertBar';
import { DropzoneModal } from './DropzoneModal';

import { FilesTagList } from './FilesTagList';
import { FilesActionBar } from './FilesActionBar';

export class DataBoxFilesWithTags extends React.Component {
  constructor (props) {
    super(props);
    this.mounted = false;
    this.state = {
      spinnerLoading: false,
      alertBarType: 'closed',
      alertBarMessage: '',
      alertBarTimeout: true,
      files: [],
      err: false,
      status: null,
      onlyImages: [],
      notImages: []
    };
  }

  componentDidMount () {
    this.mounted = true;
    this.setFiles();
  }

  componentDidUpdate (prevProps) {
    const {
      guid,
      disableDelete,
      timestamp,
      hardRefresh
    } = this.props;
    if ((prevProps.timestamp < timestamp &&
      !hardRefresh) ||
      !isEqual(disableDelete, prevProps.disableDelete)) {
      this.setFiles();
    }
    // setFiles constantly appends, guid changes needs a hard reset of file containers
    if ((!isEmpty(guid) && prevProps.guid !== guid) ||
    (prevProps.timestamp < timestamp &&
      hardRefresh)) {
      this.handleOrganizeFiles([], this.setFiles, { resetAlertBar: prevProps.guid !== guid });
    }
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  updateState = (state, callback = null) => {
    this.mounted && this.setState(state, callback);
  }

  setFiles = async (response = {}, newFiles, options) => {
    const { bypassRefreshGetFilesApi } = options || {};
    const {
      guidType,
      guid,
      axiosRequest,
      userType,
      getEndpoint,
      disableDelete,
      hasEditAccess,
      importFiles,
      filesLoadedCallback,
      restrictedTagList
    } = this.props;
    const { files } = this.state;
    const { state = {} } = response;
    const { error = false, errorMessage = '' } = state;
    const allCurrentFiles = [...files] || [];
    if (!error && (!isEmpty(getEndpoint) || Array.isArray(importFiles))) {
      this.closeModal();
      const useGetFilesApi = !Array.isArray(importFiles) && isEmpty(newFiles) &&
        !bypassRefreshGetFilesApi;
      if (!isEmpty(guid) || !Array.isArray(importFiles)) {
        let dataToFormat;
        let apiResponse;
        if (useGetFilesApi) {
          this.updateState({ spinnerLoading: true });
          const axiosOptions = {
            fullPageLoad: false,
            url: getEndpoint,
            method: 'get',
            requestGuid: { [guidType]: guid }
          };
          apiResponse = await axiosRequest(axiosOptions);
          dataToFormat = Array.isArray(apiResponse?.data)
            ? { filesList: apiResponse.data }
            : apiResponse?.data;
        } else {
          dataToFormat = {
            filesList: !isEmpty(newFiles)
              ? [...newFiles]
              : [...(!isEmpty(importFiles) ? importFiles : [])]
          };
        }
        const formatted = await transformData({ // await needed for `importFiles` to properly format
          data: {
            ...dataToFormat,
            userType,
            ...(userType === 'employee' && { disableEdit: hasEditAccess !== true }),
            disableDelete,
            restrictedTagList
          },
          toSchema: 'frontend',
          template: filesWithTagsTemplate
        });
        const oldAndNewFormatted = useGetFilesApi || !isEmpty(newFiles)
          ? [...formatted, ...(!isEmpty(newFiles) ? allCurrentFiles : [])]
          : [...formatted, ...allCurrentFiles].reduce((acc, file, index) => {
            const fileExists = !isEmpty(acc) &&
            acc.find(item => item.uniqueFileId === file.uniqueFileId);
            return fileExists ? acc : acc.concat({ ...file, key: index });
          }, []);
        this.updateState({
          ...apiResponse?.state
        });
        filesLoadedCallback && filesLoadedCallback(formatted);
        this.handleOrganizeFiles(oldAndNewFormatted);
      }
    } else {
      this.closeModal();
      this.updateState({ alertBarType: 'warning', alertBarMessage: errorMessage || 'There was an unexpected error.' });
    }
  }

  addFileHandler = () => {
    const {
      siteModal
    } = this.props;
    const title = 'Upload files';
    const content = this.buildModal();
    siteModal(true, title, content, { closeOnOutsideClick: false });
  }

  uploadComplete = (options = {}) => {
    const { hasFileErrors, filesLoaded = [] } = options || {};
    const { userType } = this.props;
    !hasFileErrors && this.updateState({ alertBarType: 'success', alertBarMessage: 'All files added' });
    const newListWithTags = !isEmpty(filesLoaded) ? filesLoaded.map(aFile => ({
      ...aFile,
      // Add timestamp on FE view only until user refreshes to get the updated file data
      fileCreationTimestamp: (new Date()).toISOString(),
      tags: getTagObjects({
        frontendView: true,
        userType,
        tags: [ // include user-added tags
          ...(!isEmpty(aFile?.tagList) ? aFile?.tagList : [])
        ]
      })
    })) : [];
    const cbOptions = { refreshPendDetails: true, refreshAppFiles: !isEmpty(newListWithTags) };
    this.handleCallback(cbOptions);
    !isEmpty(newListWithTags) && this.setFiles({}, newListWithTags);
    this.closeModal();
  }

  handleCallback = (options) => {
    const { callback } = this.props;
    callback && callback(options);
  }

  closeModal = () => {
    const { siteModal } = this.props;
    siteModal(false);
  }

  buildModal = () => {
    const {
      defaultTagsOnAdd,
      userType,
      allowEditFileName,
      displayedFields,
      requestGuid,
      guidType,
      guid,
      attachToResourceEndpoint,
      cacheUploadEndpoint,
      uploadEndpoint,
      loadMessage,
      axiosRequest
    } = this.props;
    const { files } = this.state;
    // the merchant API uses <type>Guid while all other APIs use <type>Id
    const finalRequestGuid = !isEmpty(requestGuid)
      ? requestGuid
      : { ...(!isEmpty(guidType) && !isEmpty(guid) && { [guidType]: guid }) };
    return (
      <DropzoneModal
        userType={userType}
        allowEditFileName={allowEditFileName}
        callback={this.uploadComplete}
        existingFiles={files}
        requestGuid={finalRequestGuid}
        useNewFormat
        displayedFields={displayedFields}
        uploadEndpoint={uploadEndpoint}
        cacheUploadEndpoint={cacheUploadEndpoint}
        attachToResourceEndpoint={attachToResourceEndpoint}
        axiosRequest={axiosRequest}
        defaultTagsOnAdd={defaultTagsOnAdd}
        loadMessage={loadMessage}
      />
    );
  }

  handleDeleteFile = async (deleteFileOptions) => {
    const { file } = deleteFileOptions || {};
    const {
      useCallback,
      requestGuid,
      axiosRequest,
      deleteEndpoint,
      importFiles
    } = this.props;
    const { files } = this.state;
    const fileMatch = !isEmpty(files)
      ? files.find(f => f?.uniqueFileId === file?.uniqueFileId) || {}
      : {};
    const finalRequestGuid = !isEmpty(fileMatch?.guidValue)
      ? { [fileMatch.guidType]: fileMatch.guidValue }
      : requestGuid;
    if (!isEmpty(finalRequestGuid) && deleteEndpoint && file.userCanDelete) {
      this.updateState({ spinnerLoading: true, alertBarType: 'closed', alertBarMessage: '' });
      const options = {
        fullPageLoad: false,
        url: deleteEndpoint,
        method: 'delete',
        tokenRequired: true,
        requestGuid: finalRequestGuid
      };
      const requestBody = transformData({
        data: { fileNameToDelete: file.name },
        toSchema: 'backendDelete',
        template: filesWithTagsTemplate
      });
      const apiResponse = await axiosRequest(options, requestBody);
      if (apiResponse?.errorDetails instanceof Error) {
        this.updateState({ ...apiResponse.state, alertBarType: 'warning', alertBarMessage: 'An error occurred when trying to delete the file' });
      } else {
        this.updateState({ ...apiResponse.state, alertBarType: 'success', alertBarMessage: 'File deleted successfully' });
        const newFileList = files.filter(f => f?.uniqueFileId !== file?.uniqueFileId);
        const deletedFile = files
          .find(f => (!isEmpty(file?.uniqueFileId) && f?.uniqueFileId === file?.uniqueFileId));
        if (!Array.isArray(importFiles)) {
          this.updateState({
            files: newFileList
          }, () => {
            this.setFiles({}, null, { bypassRefreshGetFilesApi: true });
            useCallback && this.handleCallback({ deletedFile });
          });
        } else {
          this.handleOrganizeFiles(newFileList);
          const cbOptions = { refreshPendDetails: true, refreshAppFiles: true };
          this.handleCallback(cbOptions);
        }
      }
    }
  }

  handleEditFile = (options) => {
    const { useCallback, userType, importFiles } = this.props;
    const { files } = this.state;
    const {
      updatedFile,
      originalFileName,
      newFileName,
      newTagList,
      originalTagList,
      fileNameChanged,
      tagsChanged
    } = options;
    this.updateState({ alertBarType: 'closed', alertBarMessage: '' });
    const updateFiles = files.map((file) => {
      if (!isEmpty(updatedFile?.uniqueFileId) && !isEmpty(file.uniqueFileId) &&
        file.uniqueFileId === updatedFile?.uniqueFileId) {
        return {
          ...file,
          displayName: fileNameChanged ? newFileName : originalFileName,
          name: fileNameChanged ? newFileName : originalFileName,
          tagList: sortSelectedTags(tagsChanged ? newTagList : originalTagList, { userType })
        };
      }
      return file;
    });
    this.handleOrganizeFiles(updateFiles);
    this.closeModal();
    this.updateState({ alertBarType: 'success', alertBarMessage: 'Success! File updated.' });
    const cbOptions = { refreshPendDetails: true, refreshAppFiles: true, updatedFile };
    (Array.isArray(importFiles) || useCallback) && this.handleCallback(cbOptions);
  }

  handleOrganizeFiles = (files, cb = null, options) => {
    const { resetAlertBar } = options || {};
    const onlyImages = files.filter(aFile => aFile.isImage);
    const notImages = files.filter(aFile => !aFile.isImage);
    this.updateState(prevState => ({
      ...(resetAlertBar && cb && prevState.alertBarMessage && {
        alertBarType: 'closed',
        alertBarMessage: ''
      }),
      files,
      onlyImages,
      notImages
    }), cb);
  }

  render () {
    const {
      files,
      spinnerLoading,
      onlyImages,
      notImages,
      err,
      status,
      alertBarType,
      alertBarMessage,
      alertBarTimeout
    } = this.state;
    const {
      id,
      userType,
      wrapperStyle,
      disableAdd,
      type,
      galleryView,
      uploadEndpoint,
      attachToResourceEndpoint,
      hasEditAccess,
      siteModal,
      axiosRequest,
      guidType,
      guid,
      customNoDataMessage,
      subtitle,
      title,
      inset,
      collapsible
    } = this.props;
    const filesList = galleryView ? notImages : files;
    const showAddButton = (!isEmpty(uploadEndpoint) || !isEmpty(attachToResourceEndpoint)) &&
      disableAdd !== true;
    const dataBoxId = id || 'files-with-tags';
    return (
      <DataBox
        type={type}
        id={dataBoxId}
        data-testid={dataBoxId}
        style={{ overflowY: 'auto', ...wrapperStyle }}
        title={title}
        subtitle={subtitle}
        collapsible={collapsible}
        inset={inset}
        {...showAddButton && { buttonCallback: this.addFileHandler }}
      >
        <AlertBar
          options={{
            barStyle: alertBarType,
            message: alertBarMessage,
            timeout: alertBarTimeout,
            customWarnStyle: { width: '100%', height: '100%' }
          }}
          callback={this.updateState}
          useInnerAlertBar
        />
        <Spinner loading={spinnerLoading} />
        <div style={{ maxHeight: 'calc(100vh - var(--height-header) - var(--height-footer)' }}>
          { galleryView && !isEmpty(onlyImages) && onlyImages instanceof Array && (
            <FileImageListWrapper id="files-images-wrapper">
              {onlyImages.map(file => (
                <FileImageRowWrapper
                  className="image-hover-highlight-row"
                  key={file.key}
                  canEdit={hasEditAccess}
                  canDelete={file.userCanDelete}
                  timestamp={file.uploadTimestamp}
                >
                  <FileImageRowLink
                    href={file.url}
                    className="related-file-name"
                    rel="noopener noreferrer"
                    target="_blank"
                    style={{
                      backgroundImage: `url(${file.url})`
                    }}
                  />
                  <div className="file-name" title={file.displayName} style={filesWithTagsCSS.imageFileName}>{file.displayName}</div>
                  <Icon
                    $useMask
                    icon={icons.magnify.src_white}
                    color="var(--color-whiteish)"
                    $hoverColor="var(--color-link)"
                    style={{
                      height: '16px',
                      width: '16px',
                      position: 'absolute',
                      top: 2,
                      right: 0,
                      backgroundColor: 'rgb(0 0 0 / 50%)',
                      borderRadius: 'var(--radius-small)',
                      padding: '2px'
                    }}
                  />
                  <div className="tags-actions-wrapper" style={filesWithTagsCSS.tagsActionsWrapper}>
                    <FilesTagList
                      file={file}
                      galleryView={galleryView}
                      hasEditAccess={hasEditAccess}
                    />
                    <FilesActionBar
                      userType={userType}
                      file={file}
                      hasEditAccess={hasEditAccess}
                      galleryView={galleryView}
                      deleteFileCallback={this.handleDeleteFile}
                      editFileCallback={this.handleEditFile}
                      siteModal={siteModal}
                      axiosRequest={axiosRequest}
                      editFileRequestGuid={{ [guidType]: guid }}
                    />
                  </div>
                </FileImageRowWrapper>
              ))}
            </FileImageListWrapper>
          )}
          { !isEmpty(filesList) && filesList instanceof Array && (
            <DropzoneFileList
              id="files-list-wrapper"
              useTags
              userType={userType}
              displayPath={false}
              addedFiles={filesList}
              callback={this.handleDeleteFile}
              hasEditAccess={hasEditAccess}
              {...(hasEditAccess && {
                editFileRequestGuid: { [guidType]: guid },
                editFileCallback: this.handleEditFile,
                siteModal
              })}
              axiosRequest={axiosRequest}
              galleryView={galleryView}
            />
          )}
          { (!isEmpty(err) || (isEmpty(onlyImages) && isEmpty(notImages))) && (
            <CustomApiErr
              customErr={err}
              status={status}
              loading={spinnerLoading}
              customMessage={customNoDataMessage}
            />
          )}
        </div>
      </DataBox>
    );
  }
}

DataBoxFilesWithTags.propTypes = {
  id: PropTypes.string,
  type: PropTypes.oneOf(['default', 'white']),
  requestGuid: PropTypes.oneOfType([PropTypes.object]),
  guid: PropTypes.string,
  guidType: PropTypes.string,
  userType: PropTypes.oneOf(['partner', 'employee']),
  siteModal: PropTypes.func,
  timestamp: PropTypes.number,
  // will need to pass these utils to use the shared component
  axiosRequest: PropTypes.func,
  getEndpoint: PropTypes.string,
  attachToResourceEndpoint: PropTypes.string,
  cacheUploadEndpoint: PropTypes.string,
  uploadEndpoint: PropTypes.string,
  // optional
  wrapperStyle: PropTypes.oneOfType([PropTypes.object]),
  loadMessage: PropTypes.string,
  customNoDataMessage: PropTypes.string,
  galleryView: PropTypes.bool,
  disableAdd: PropTypes.bool,
  hasEditAccess: PropTypes.bool,
  deleteEndpoint: PropTypes.string,
  disableDelete: PropTypes.bool,
  displayedFields: PropTypes.oneOfType([PropTypes.array]),
  allowEditFileName: PropTypes.bool,
  importFiles: PropTypes.oneOfType([PropTypes.array]),
  defaultTagsOnAdd: PropTypes.oneOfType([PropTypes.array]),
  callback: PropTypes.func,
  filesLoadedCallback: PropTypes.func,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  useCallback: PropTypes.bool,
  hardRefresh: PropTypes.bool,
  collapsible: PropTypes.bool,
  inset: PropTypes.bool,
  restrictedTagList: PropTypes.oneOfType([PropTypes.array])
};

DataBoxFilesWithTags.defaultProps = {
  id: null,
  type: 'default',
  requestGuid: null,
  guid: '',
  guidType: '',
  userType: 'partner',
  siteModal: () => {},
  timestamp: 0,
  // will need to pass these utils to use the shared component
  axiosRequest: () => {},
  getEndpoint: '',
  attachToResourceEndpoint: '', // REQUIRED starting with V3 file endpoints with existing resource
  cacheUploadEndpoint: '', // REQUIRED starting with V3 file endpoints (with or without existing resource)
  uploadEndpoint: '', // Only use if V3+ file endpoints are not supported, or no resource exists
  // optional
  wrapperStyle: {},
  loadMessage: '', // DropzoneModal message on load
  customNoDataMessage: null,
  galleryView: false,
  disableAdd: false,
  hasEditAccess: false,
  deleteEndpoint: '',
  disableDelete: false,
  importFiles: null,
  displayedFields: [ // For add file, and user can add tags/select file type
  // 'tagList',
  // 'selectFileType'
  ],
  allowEditFileName: false, // true = user can edit any dropped file name before uploading
  defaultTagsOnAdd: [], // Any default tags we want added under the covers for new files
  callback: () => {},
  filesLoadedCallback: null,
  subtitle: null,
  title: 'Related Files',
  /**
   * `useCallback` - If using api call to GET files, pass `true` to manually invoke
   *  the callback to send added/edited/deleted file data to parent component
   */
  useCallback: false,
  // hardRefresh is needed if you want timestamp to totally reload the data instead of append it
  hardRefresh: false,
  collapsible: false,
  inset: false,
  restrictedTagList: [] // list of file file tags to specifically disable modification/deletion
};

export default DataBoxFilesWithTags;
