import { domainName } from '@f1/shared/src/_helpers';
import mockFiles from '../../mockFiles';

export const createMockRepayFiles = () => { // BE response
  const mockDataFiles = Object.values(mockFiles).reduce((acc, mockFile) => {
    const formattedFile = {
      fileName: mockFile.fileName,
      relativePath: (mockFile?.relativePath || '').endsWith(mockFile.fileName)
        ? `${mockFile?.relativePath}`.replace(`/${mockFile.fileName}`, '')
        : mockFile.relativePath,
      url: `https://www.dev.${domainName}.com`
    };
    return acc.concat(formattedFile);
  }, []);
  return { availableFiles: mockDataFiles };
};

export const mockFileV2RepayList = (body, params, options) => { // GET
  const mockRepayFiles = createMockRepayFiles();
  return mockRepayFiles;
};

export default mockFileV2RepayList;
