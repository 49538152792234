import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

// import each reducer
import toggleLoader from './toggleLoader';
import alertBar from './alertBar';
import siteModal from './siteModal';
import authenticate from './authenticate';
import appState from './appState';
import guidUpdate from './guidUpdate';
import dataUpdate from './dataUpdate';

const appReducer = combineReducers({
  // ADD each imported reducer
  alertBar,
  appState,
  authenticate,
  dataUpdate,
  guidUpdate,
  siteModal,
  toggleLoader,
  routing: routerReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_STORE') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
