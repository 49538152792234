import styled from 'styled-components';

export const NavBar = styled.nav`
  height: 100%;
  flex: 1 1 0%;
  transition: all 0.2s ease 0s;
  align-self: center;
  .navUl {
    height: 100%;
    z-index: 1;
    padding: 0px;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 50%;
    float: right;
    .navLi {
      line-height: 1;
      flex-grow: 1;
      margin: 0;
      padding: 0 5px;
      list-style: none;
      text-align: center;
      margin-left: 2px;
      height: inherit;
      display: flex;
      &:hover {
        .navSubmenu {
          padding: 0;
          opacity: 1;
          background: var(--color-bg);
          z-index: 10;
          overflow-y: auto;
          max-height: calc(100vh - var(--height-header));
        }
      }
      &.hasSub, &.notifications {
        position: relative;
      }
      &.hasSub:not(.account) {
        &::after {
          position: absolute;
          bottom: 20px;
          left: 50%;
          transform: translate(-50%, 0 );
          content: '';
          display: block;
          background-size: 10px;
          background-repeat: no-repeat;
          transition: clip-path 0.2s;
          width: 15px;
          height: 15px;
          background: var(--color-primary);
          transition: 0.2s;
          clip-path: polygon(50% 100%, 100% 50%, 86% 37%, 50% 75%, 14% 37%, 0 50%);
        }
        &:hover {
          &::after {
            bottom: 0;
            clip-path: polygon(50% 100%, 80% 100%, 100% 100%, 50% 50%, 0 100%, 20% 100%);
          }
        }
      }
      &.notifications {
        min-width: 30px;
        height: 100%;
      }
      &.account {
        min-width: 100px;
        height: 100%;
      }
      .menuItemWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        width: 100%;
        overflow: hidden;
      }
      .mLink {
        display: flex;
        align-items: center;
        z-index: 2;
        text-decoration: none;
        color: var(--color-header);
        cursor: pointer;
        height: inherit;
        transition: var(--transition-base);
        .vAlign {
          vertical-align: middle;
          width: 100%;
        }
      }
    }
  }
  .navSubmenu {
    opacity: 0;
    max-height: 0;
    min-width: 120px;
    overflow: hidden;
    position: absolute;
    padding: 5px;
    background: var(--color-bg);
    list-style: none;
    left: 50%;
    transform: translate(-50%, 0);
    transition: var(--transition-base);
    border-radius: 0 0 6px 6px;
    box-shadow: rgba(0, 0, 0, 0.2) 1px 2px 5px 0px;
    margin-top: calc(50px + var(--padding-header) + var(--padding-header));
    .navSubmenuLi {
      list-style: none;
      padding-left: 0;
      text-align: left;
      &:last-child {
        border-radius: 0 0 6px 6px;
        overflow: hidden;
      }
      .sLink {
        white-space: nowrap;
        display: block;
        padding: 10px;
        color: var(--color-text);
        &:hover {
          background: var(--color-primary);
          color: var(--color-white);
        }
      }
    }
  }
  .account .navSubmenu {
    left: auto;
    right: calc(0PX - var(--padding-header));
    transform: unset;
  }
`;

export default NavBar;
