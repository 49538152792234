import React from 'react';
import PropTypes from 'prop-types';
import {
  isEmpty,
  getType,
  camelToTitle,
  formatDateForFEView
} from './_helpers';
import {
  DataGridColumn
} from '../css/_styledComponents';

class JsonRenderer extends React.PureComponent {
  // Main Box to render things in
  dataElement = data => (
    <div className="dataBox">
      {this.renderType(data)}
    </div>
  );

  // Wrap all arrays in this container
  dataArray = arr => (
    <div
      className="arrayWrap"
    >
      {arr.map((item, i) => (
        <div
          style={{ margin: '5px 0' }}
          key={`${i.toString()}`}
        >
          {this.renderType(item)}
        </div>
      ))}
    </div>
  );

  dataObjectType = (obj) => {
  // this check the properties of the object, to determine if it's a "KNOWN" type
  // I.E. is it just data, or is it defining something special.  which we render differently.
    if (Object.prototype.hasOwnProperty.call(obj, 'code') &&
  (Object.prototype.hasOwnProperty.call(obj, 'definition') || Object.prototype.hasOwnProperty.call(obj, 'name')
  )) {
      return 'definition';
    }
    if (
      Object.prototype.hasOwnProperty.call(obj, 'qualifierCode') &&
    Object.prototype.hasOwnProperty.call(obj, 'qualifierDefinition')
    ) {
      return 'definitionWithValue';
    }
    if (
      Object.keys(obj).length === 2 &&
    Object.prototype.hasOwnProperty.call(obj, 'zip') &&
    Object.prototype.hasOwnProperty.call(obj, 'zipExtension')) {
      return 'zipWithExtension';
    }
    return 'generalData';
  };

  dataObject = obj => (
    <>
      {this.dataObjectType(obj) === 'definition' && (
        <div>
          {!isEmpty(obj.code) ? (
            <>
              {obj.code}
              {(!isEmpty(obj.definition) || !isEmpty(obj.name)) && (
                <span style={{ color: '#aaaaaa', fontSize: '14px' }}>
                  {` (${obj.definition || obj.name})`}
                </span>
              )}
            </>
          ) : (<>-</>)}
        </div>
      )}
      {this.dataObjectType(obj) === 'definitionWithValue' && (
        <div>
          {!isEmpty(obj.qualifierCode) ? (
            <>
              {`${obj.qualifierCode} - `}
              {obj.value && (
                <strong>
                  {obj.value}
                </strong>
              )}
              <span style={{ color: '#aaaaaa', fontSize: '14px' }}>
                {` (${obj.qualifierDefinition})`}
              </span>
            </>
          ) : (<>-</>)}
        </div>
      )}
      {this.dataObjectType(obj) === 'generalData' && (
      // not a known special object, so just render the data in a grid.
        <DataGridColumn>
          { Object.entries(obj).map(([key, val]) => (
            <div key={key} className={this.highlight(key)}>
              <div>{camelToTitle(key)}</div>
              <div style={{ whiteSpace: 'initial' }}>
                {this.renderType(val)}
              </div>
            </div>
          ))}
        </DataGridColumn>
      )}
      {this.dataObjectType(obj) === 'zipWithExtension' && (
        <>
          {(!isEmpty(obj.zip) && obj.zip.length === 5) && (
            <>
              {`${obj.zip}${obj.zipExtension ? `-${obj.zipExtension}` : ''}`}
            </>
          )}
        </>
      )}
    </>
  );

  highlight = key => ([
  // add any ID they want highlighted here, and the field will have a light yellow BG
    'someKeyTheyWantToStandOut'
  ].includes(key) ? 'highlight' : '');

  renderType = (elem) => {
    if (isEmpty(elem)) {
      return '-';
    }
    switch (getType(elem)) {
      case 'boolean':
        return elem ? 'Yes' : 'No';
      case 'array':
        return this.dataArray(elem);
      case 'object':
        return this.dataObject(elem);
      case 'string':
      // check if it is actually a timestamp
        return RegExp(/^\d{4}-?\d{2}-?\d{2}/g).test(elem)
          ? formatDateForFEView(elem, { includeTime: RegExp(/^\d{4}-?\d{2}-?\d{2}T\d{2}:?\d{2}/g).test(elem) })
          : elem;
      default:
        return elem;
    }
  };

  render () {
    const { data } = this.props;
    return (
      <div>
        {this.dataElement(data)}
      </div>
    );
  }
}

JsonRenderer.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array
  ])
};

JsonRenderer.defaultProps = {
  data: {}
};

export default JsonRenderer;
