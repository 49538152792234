import React from 'react';
import PropTypes from 'prop-types';
import '../css/animations.css';
import { loader } from './_styles';

export class LoadingPage extends React.PureComponent {
  constructor (props) {
    super(props);
    this.state = {
    };
  }

  render () {
    const {
      width, height, top, isStatic
    } = this.props;
    return (
      <div
        style={{
          position: isStatic ? 'static' : 'absolute',
          left: '0',
          right: '0',
          top: top ? `${top}` : 'calc(50px + var(--padding-header) + var(--padding-header))',
          bottom: '0',
          width: `${width || '100vw'}`,
          height: `${height || 'calc(100vh - var(--padding-header) - var(--padding-header))'}`,
          zIndex: '97',
          background: 'var(--color-bg)'
        }}
      >
        <div className="boxLoader" style={loader.loader} />
      </div>
    );
  }
}

LoadingPage.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  top: PropTypes.string,
  isStatic: PropTypes.bool
};
LoadingPage.defaultProps = {
  width: null,
  height: null,
  top: 'calc(50px + var(--padding-header) + var(--padding-header))',
  isStatic: false
};

export default LoadingPage;
