import { getMockValue } from '../_mockDataHelpers';

export const createMockVarList = (overridesArray, options) => {
  const { length } = options || {};
  const isPollingVarList = typeof window !== 'undefined' && document && document.querySelector('.varListDataBox.pollingInProgress');
  const arrayLength = overridesArray
    ? overridesArray.length
    : length ?? getMockValue({ min: 2, max: 4 });
  const mockVarList = Array.from({
    length: isPollingVarList ? arrayLength + 1 : arrayLength
  }).map((item, varIndex) => {
    const varOverrides = (overridesArray && overridesArray[varIndex]) || {};
    const mockVarObj = {
      abaNumber: getMockValue({ type: 'ssn' }),
      acquirerBin: getMockValue({
        type: 'number',
        min: 100000,
        max: 999999,
        stringify: true
      }),
      agentBank: getMockValue({
        type: 'number',
        min: 100000,
        max: 999999,
        stringify: true
      }),
      agentChain: getMockValue({
        type: 'number',
        min: 100000,
        max: 999999,
        stringify: true
      }),
      cashBack: getMockValue({ type: 'string' }),
      comments: getMockValue({ type: 'description' }),
      currencyCode: '840',
      countryCode: '840',
      encryption: getMockValue({ type: 'string' }),
      industry: getMockValue({
        type: 'list',
        list: ['unknown', 'auto_rental', 'bank_financial_institution', 'direct_marketing', 'food_restaurant', 'grocery_store_supermarket', 'hotel', 'limited_amount_terminal', 'oil_company_automated_fueling_system', 'passenger_transport', 'retail']
      }),
      hcPosId: getMockValue({ type: 'accountNumber' }),
      hostCaptureParticipantIndicator: getMockValue({ type: 'string' }),
      locationNumber: getMockValue({
        type: 'number',
        min: 10000,
        max: 99999,
        stringify: true
      }),
      networksAndSharingGroups: getMockValue({ type: 'string' }),
      pinPadType: getMockValue({ type: 'string' }),
      reimbursementAttribute: 'Z',
      securityCode: getMockValue({ type: 'string' }),
      settlementAgentFiId: 'V103',
      sharingGroups: getMockValue({ type: 'string' }),
      storeNumber: getMockValue({
        type: 'number',
        min: 1000,
        max: 9999,
        stringify: true
      }),
      terminalNumber: getMockValue({
        type: 'number',
        min: 1000,
        max: 9999,
        stringify: true
      }),
      terminalStatus: getMockValue({ type: 'string' }),
      varCreationTimestamp: getMockValue({ type: 'isoDate', min: 2021, max: 2022 }),
      vitalNumber: getMockValue({
        type: 'number',
        min: 70000000,
        max: 79999999,
        stringify: true
      }),
      varId: `mock-var-id-${varIndex}`,
      posMid: getMockValue({ max: 999999999999, type: 'mid' }),
      ...varOverrides
    };
    return mockVarObj;
  });
  return mockVarList;
};

export default createMockVarList;
