const mockResidualMidMappingPOSTRes = (body, params, options) => {
  const { file } = body;
  return ({
    invalidMids: ['123', '456', '789'],
    file: {
      fileName: file?.fileName,
      absolutePath: file?.absolutePath,
      url: 'mockS3ResponseUrl.com'
    }
  });
};

export default mockResidualMidMappingPOSTRes;
