import { riskRuleGuids } from '../../../guids';

export const mockTriggerAndClosure = {
  [riskRuleGuids.default]: {
    header: {
      label: 'day',
      lines: ['closed'],
      biaxial: [],
      currency: false,
      biaxialCurrency: false,
      showLegend: true
    },
    data: [
      {
        day: '2021-05-17',
        total: '4',
        closed: '2'
      },
      {
        day: '2021-05-18',
        total: '2',
        closed: '1'
      },
      {
        day: '2021-05-19',
        total: '1',
        closed: '0'
      },
      {
        day: '2021-05-20',
        total: '2',
        closed: '1'
      },
      {
        day: '2021-05-21',
        total: '1',
        closed: '1'
      },
      {
        day: '2021-05-22',
        total: '0',
        closed: '0'
      },
      {
        day: '2021-05-23',
        total: '2',
        closed: '1'
      },
      {
        day: '2021-05-24',
        total: '3',
        closed: '1'
      },
      {
        day: '2021-05-25',
        total: '3',
        closed: '2'
      },
      {
        day: '2021-05-26',
        total: '4',
        closed: '1'
      },
      {
        day: '2021-05-27',
        total: '3',
        closed: '2'
      },
      {
        day: '2021-05-28',
        total: '1',
        closed: '1'
      },
      {
        day: '2021-05-29',
        total: '2',
        closed: '1'
      },
      {
        day: '2021-05-30',
        total: '1',
        closed: '1'
      }
    ]
  },
  [riskRuleGuids.riskRule01]: {
    header: {
      label: 'day',
      lines: ['closed'],
      biaxial: [],
      currency: false,
      biaxialCurrency: false,
      showLegend: true
    },
    data: [
      {
        day: '2021-05-17',
        total: '3',
        closed: '0'
      },
      {
        day: '2021-05-18',
        total: '3',
        closed: '1'
      },
      {
        day: '2021-05-19',
        total: '2',
        closed: '1'
      },
      {
        day: '2021-05-20',
        total: '1',
        closed: '0'
      },
      {
        day: '2021-05-21',
        total: '4',
        closed: '2'
      },
      {
        day: '2021-05-22',
        total: '2',
        closed: '2'
      },
      {
        day: '2021-05-23',
        total: '0',
        closed: '0'
      },
      {
        day: '2021-05-24',
        total: '4',
        closed: '3'
      },
      {
        day: '2021-05-25',
        total: '1',
        closed: '1'
      },
      {
        day: '2021-05-26',
        total: '2',
        closed: '2'
      },
      {
        day: '2021-05-27',
        total: '5',
        closed: '1'
      },
      {
        day: '2021-05-28',
        total: '4',
        closed: '3'
      },
      {
        day: '2021-05-29',
        total: '2',
        closed: '1'
      },
      {
        day: '2021-05-30',
        total: '1',
        closed: '0'
      }
    ]
  },
  [riskRuleGuids.riskRule02]: {
    header: {
      label: 'day',
      lines: ['closed'],
      biaxial: [],
      currency: false,
      biaxialCurrency: false,
      showLegend: true
    },
    data: [
      {
        day: '2021-05-17',
        total: '3',
        closed: '0'
      },
      {
        day: '2021-05-18',
        total: '3',
        closed: '1'
      },
      {
        day: '2021-05-19',
        total: '2',
        closed: '1'
      },
      {
        day: '2021-05-20',
        total: '1',
        closed: '0'
      },
      {
        day: '2021-05-21',
        total: '4',
        closed: '2'
      },
      {
        day: '2021-05-22',
        total: '2',
        closed: '2'
      },
      {
        day: '2021-05-23',
        total: '0',
        closed: '0'
      },
      {
        day: '2021-05-24',
        total: '4',
        closed: '3'
      },
      {
        day: '2021-05-25',
        total: '1',
        closed: '1'
      },
      {
        day: '2021-05-26',
        total: '2',
        closed: '2'
      },
      {
        day: '2021-05-27',
        total: '5',
        closed: '1'
      },
      {
        day: '2021-05-28',
        total: '4',
        closed: '3'
      },
      {
        day: '2021-05-29',
        total: '2',
        closed: '1'
      },
      {
        day: '2021-05-30',
        total: '1',
        closed: '0'
      }
    ]
  },
  [riskRuleGuids.riskRule03]: {
    header: {
      label: 'day',
      lines: ['closed'],
      biaxial: [],
      currency: false,
      biaxialCurrency: false,
      showLegend: true
    },
    data: [
      {
        day: '2021-05-17',
        total: '3',
        closed: '0'
      },
      {
        day: '2021-05-18',
        total: '3',
        closed: '1'
      },
      {
        day: '2021-05-19',
        total: '2',
        closed: '1'
      },
      {
        day: '2021-05-20',
        total: '1',
        closed: '0'
      },
      {
        day: '2021-05-21',
        total: '4',
        closed: '2'
      },
      {
        day: '2021-05-22',
        total: '2',
        closed: '2'
      },
      {
        day: '2021-05-23',
        total: '0',
        closed: '0'
      },
      {
        day: '2021-05-24',
        total: '4',
        closed: '3'
      },
      {
        day: '2021-05-25',
        total: '1',
        closed: '1'
      },
      {
        day: '2021-05-26',
        total: '2',
        closed: '2'
      },
      {
        day: '2021-05-27',
        total: '5',
        closed: '1'
      },
      {
        day: '2021-05-28',
        total: '4',
        closed: '3'
      },
      {
        day: '2021-05-29',
        total: '2',
        closed: '1'
      },
      {
        day: '2021-05-30',
        total: '1',
        closed: '0'
      }
    ]
  }

};

export default mockTriggerAndClosure;
