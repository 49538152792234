export const mockTransactionReportAuthorizationGet = (body, params, options) => {
  const { guid = '' } = options || {};
  return {
    [guid]: {
      header: {
        label: 'month',
        lines: ['count'],
        biaxial: ['count'],
        currency: true,
        biaxialCurrency: false
      },
      data: [
        { volume: 0, month: '2020-02', count: 0 },
        { volume: 0, month: '2020-03', count: 0 },
        { volume: 0, month: '2020-04', count: 0 },
        { volume: 0, month: '2020-05', count: 0 },
        { volume: 0, month: '2020-06', count: 0 },
        { volume: 51870, month: '2020-07', count: 30 },
        { volume: 0, month: '2020-08', count: 0 }
      ]
    }
  };
};

export default mockTransactionReportAuthorizationGet;
