/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { envIsDevOrLess } from './_helpers';

const performanceTesting = false;

if (envIsDevOrLess() && performanceTesting) {
  // eslint-disable-next-line global-require
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    trackAllPureComponents: true
  });
}
