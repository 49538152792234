import { getThisYearMonth, pad, sortData } from '../../_helpers';
import { getMockPageData, getMockValue, mockPagingInfo } from '../_mockDataHelpers';

const mockGetAllPagedDetailData = () => sortData(
  Array.from({ length: 30 }).map(() => ({
    mid: getMockValue({ type: 'mid' }),
    legalName: getMockValue({ type: 'businessName' }),
    dbaName: getMockValue({ type: 'businessName' }),
    batchId: getMockValue({
      type: 'number',
      min: 10000,
      max: 99999,
      stringify: true
    }),
    batchDate: getMockValue({ type: 'date' }),
    transactionDate: getMockValue({ type: 'date' }),
    purchaseAmount: getMockValue({ type: 'number', min: -100 }),
    interchangeReimbursementAttribute: getMockValue({ type: 'string' }).slice(0, 2).toUpperCase(),
    interchangeRate: getMockValue({ type: 'ratio' }),
    interchangeRateCost: getMockValue({ type: 'number' }),
    interchangePerItemCost: getMockValue({ type: 'number' }),
    mpaRate: getMockValue({ type: 'ratio' }),
    mpaRateCost: getMockValue({ type: 'number' }),
    mpaPerItemCost: getMockValue({ type: 'number' }),
    expectedProfit: getMockValue({ type: 'number' })
  })),
  'transactionDate',
  { direction: 'desc' }
);

const mockAllPagedDetailData = mockGetAllPagedDetailData();

export const mockTransactionProfitabilityDetail = (body, params, options) => {
  const {
    totalNumberOfRecords = mockPagingInfo.totalNumberOfRecords,
    pageIndex = mockPagingInfo.pageIndex
  } = params || {};
  const { guid, length } = options || {};
  const pageData = getMockPageData({
    allData: length ? mockAllPagedDetailData.slice(0, length + 1) : mockAllPagedDetailData,
    pageIndex,
    pageSize: 25,
    optionsCopy: { ...params, pageSize: 25 }
  });
  return {
    [guid]: {
      transactionList: pageData,
      pagingInfo: { totalNumberOfRecords, pageIndex, pageSize: 25 }
    }
  };
};

const getMockSummaryData = (yearMonth, options) => {
  const { length } = options || {};
  const numberProps = { type: 'number', min: 0, max: 500 };
  const isFeb = `${yearMonth}`.endsWith('-02');
  const isCurrent = yearMonth === '2020-08'; // "now" is 2020-08-02 in mock data/dev;
  const mockDays = isFeb ? 28 : (isCurrent && 2) || (!isCurrent && 30);
  const mockProfitabilityList = Array.from({ length: length || mockDays }).map((item, index) => ({
    purchaseAmountTotal: getMockValue({ ...numberProps, min: -500 }),
    interchangeRateCostTotal: getMockValue(numberProps),
    interchangePerItemCostTotal: getMockValue(numberProps),
    mpaRateCostTotal: getMockValue(numberProps),
    mpaPerItemCostTotal: getMockValue(numberProps),
    expectedProfitTotal: getMockValue(numberProps),
    date: `${yearMonth}-${pad(index + 1)}`
  }));
  const mockOverallProfitability = {
    purchaseAmountTotal: getMockValue({ ...numberProps, min: -500 }),
    interchangeRateCostTotal: getMockValue(numberProps),
    interchangePerItemCostTotal: getMockValue(numberProps),
    mpaRateCostTotal: getMockValue(numberProps),
    mpaPerItemCostTotal: getMockValue(numberProps),
    expectedProfitTotal: getMockValue(numberProps)
  };
  return {
    profitabilityList: sortData(mockProfitabilityList, 'date', { direction: 'desc' }),
    overallProfitability: mockOverallProfitability
  };
};

export const mockTransactionProfitabilitySummary = (body, params, options) => {
  const { yearMonth = getThisYearMonth() } = params || {};
  const { guid } = options || {};
  const mockSummaryData = getMockSummaryData(yearMonth, options);
  return { [guid]: mockSummaryData };
};
