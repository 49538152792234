import { isEmpty, snakeToTitle } from '../../../_helpers';
import { toBackendValue } from '../../../_templateHelpers';
import { lessonPlanFormFields } from '../../../_formFields';

const lessonPlanTemplate = {
  get: (data, version) => {
    if (version === '1.0' && data.lessonPlans instanceof Array) {
      return data.lessonPlans.map(lessonPlan => ({
        details: {
          id: lessonPlan.lessonPlanId,
          title: lessonPlan.name,
          description: lessonPlan.description
        },
        published: lessonPlan.published,
        audiencePermission: lessonPlan.permissions.audience,
        groupPermission: lessonPlan.permissions.group,
        thumbnail: {
          name: lessonPlan.thumbnail.fileName,
          path: lessonPlan.thumbnail.relativePath,
          url: isEmpty(lessonPlan.thumbnail.url) ? 'https://via.placeholder.com/180x160/01AF66/FFF?text=No_Thumbnail' : lessonPlan.thumbnail.url
        },
        videos: formatFiles(lessonPlan.videos.files),
        trainingFiles: formatFiles(lessonPlan.trainingFiles.files)
      }));
    }
    return !isEmpty(data) ? data : [];
  },
  frontend: (data, version) => {
    if (version === '1.0') {
      return {
        audience: {
          title: snakeToTitle(data?.audiencePermission), value: data?.audiencePermission
        },
        group: { title: snakeToTitle(data?.groupPermission), value: data?.groupPermission },
        title: { title: snakeToTitle(data?.details?.title), value: data?.details?.title },
        description: {
          title: snakeToTitle(data?.details?.description), value: data?.details?.description
        },
        thumbnail: [{
          name: data?.thumbnail?.name,
          path: data?.thumbnail?.path
        }]
      };
    }
    return data;
  },
  putPost: (data, version) => {
    if (version === '1.0') {
      return {
        lessonPlan: {
          name: toBackendValue(
            data?.[lessonPlanFormFields.title.id],
            lessonPlanFormFields[lessonPlanFormFields.title.id]
          ),
          published: toBackendValue(data.published, {}),
          permissions: {
            audience: toBackendValue(
              data?.[lessonPlanFormFields.audience.id],
              lessonPlanFormFields[lessonPlanFormFields.audience.id]
            ),
            group: toBackendValue(
              data?.[lessonPlanFormFields.group.id],
              lessonPlanFormFields[lessonPlanFormFields.group.id]
            )
          },
          description: toBackendValue(
            data?.[lessonPlanFormFields.description.id],
            lessonPlanFormFields[lessonPlanFormFields.description.id]
          )
        }
      };
    }
    return data;
  },
  attachToResource: (data, version) => {
    const { thumbnail, videos, trainingFiles } = data;
    if (version === '1.0') {
      return {
        thumbnail: !isEmpty(thumbnail) ? {
          fileName: thumbnail || null
        } : null,
        videos: {
          files: !isEmpty(videos) ? [
            {
              fileName: videos || null
            }
          ] : []
        },
        trainingFiles: {
          files: !isEmpty(trainingFiles) ? [
            {
              fileName: trainingFiles || null
            }
          ] : []
        }
      };
    }
    return data;
  }
};

const formatFiles = (files) => {
  if (isEmpty(files)) { return []; }
  return files.map(file => ({
    name: file.fileName,
    path: file.relativePath,
    url: file.url
  }));
};

export default lessonPlanTemplate;
