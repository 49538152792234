
import { getMockValue } from '../_mockDataHelpers';
import { transformData } from '../../_helpers';
import crabExternalCommunicationTemplate from './templates/crabExternalCommunicationTemplate';
import { formatDescriptionText } from '../../_templateHelpers';

// MOCK DATA & HELPERS START
export const buildMockList = (number = 1, options) => {
  const { userType = 'partner', guid = '1' } = options || {};
  const isApplicationGuid = !['pend-id', 'prevet-id'].includes(guid);
  const isPrevetGuid = guid.includes('prevet-id');
  const list = Array.from({ length: number }).map((item, index) => {
    const userIsPartner = userType === 'partner';
    const mockEmail = userIsPartner
      ? 'createtestusershandler_withalldashboarddata@gmail.com'
      : 'f1paymentsengineering+appreview1@gmail.com';
    const staticData = {
      0: { // External communication from PARTNER tied to a PEND
        communicationId: 'mockCom-1',
        applicationExternalCommunicationCreatedTimestamp: '2019-12-30T20:22:00Z',
        description: `mock message for ${isApplicationGuid ? 'APP' : 'PEND'}`,
        displayedDescription: formatDescriptionText(`mock message for ${isApplicationGuid ? 'APP' : 'PEND'}`),
        ...(isApplicationGuid && {
          // If fetching for application communications, make the newest one related to a PEND
          applicationId: null,
          applicationPendId: 'mock-application-pend-id-1',
          description: 'mock message for PEND',
          displayedDescription: formatDescriptionText('mock message for PEND')
        }),
        ...(isPrevetGuid && {
          applicationId: null,
          applicationPendId: null,
          description: `mock message for PREVET`,
          displayedDescription: formatDescriptionText(`mock message for PREVET`)
        }),
        portalUser: {
          portalUserId: getMockValue({ type: 'guid' }),
          email: userIsPartner ? mockEmail : getMockValue({ type: 'email', userType: 'external' })
        }
      },
      1: { // External communication from EMPLOYEE tied to an APP
        communicationId: 'mockCom-2',
        applicationExternalCommunicationCreatedTimestamp: '2019-12-30T20:21:00Z',
        description: isApplicationGuid ? 'mock message for APP' : 'mock message for PEND',
        displayedDescription: formatDescriptionText(isApplicationGuid ? 'mock message for APP' : 'mock message for PEND'),
        ...(isPrevetGuid && { description: `mock message for PREVET` }),
        employee: {
          employeeId: getMockValue({ type: 'guid' }),
          department: 'app_review',
          firstName: userIsPartner ? null : 'Shingen',
          email: userIsPartner ? null : mockEmail
        }
      }
    };
    const itemData = {
      communicationId: `mockCom-${index + 1}`,
      description: getMockValue({ type: 'description' }),
      displayedDescription: formatDescriptionText(getMockValue({ type: 'description' })),
      applicationExternalCommunicationCreatedTimestamp: getMockValue({ type: 'isoDate' }),
      applicationExternalCommunicationId: `mock-external-communication-id-${index + 1}`,
      applicationId: isApplicationGuid ? guid : null,
      applicationPendId: isApplicationGuid || isPrevetGuid ? null : guid,
      prevetId: isPrevetGuid ? guid : null,
      ...((index % 2 === 0)
        ? {
          employee: {
            employeeId: getMockValue({ type: 'guid' }),
            department: 'app_review',
            firstName: userIsPartner ? null : getMockValue({ type: 'user' }).firstName,
            email: userIsPartner ? null : getMockValue({ type: 'email', userType: 'internal' })
          },
          portalUser: null
        }
        : {
          employee: null,
          portalUser: {
            portalUserId: getMockValue({ type: 'guid' }),
            email: getMockValue({ type: 'email', userType: 'external' })
          }
        }),
      ...staticData[index]
    };
    return itemData;
  });
  return [...list];
};

export const mockCrabExternalCommunicationGetFormatted = data => transformData({
  data: { ...data },
  toSchema: 'frontend',
  version: '1.0',
  template: crabExternalCommunicationTemplate
});
// MOCK DATA & HELPERS END

// MOCK API CALLS
export const mockCrabExternalCommunicationGet = (body, params, options) => {
  const { guid = '', userType = 'partner' } = options || {};
  const data = buildMockList(6, { userType, guid });
  return {
    [guid]: {
      externalCommunicationList: [...data]
    }
  };
};

export const mockCrabExternalCommunicationPut = (body, params, options) => {
  const { guid = '' } = options || {};
  return {
    [guid]: {
      applicationExternalCommunicationId: 'mock-application-external-communication-id'
    }
  };
};
