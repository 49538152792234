import React from 'react';
import PropTypes from 'prop-types';
import { loader } from './_styles';

export class Spinner extends React.PureComponent {
  render () {
    const { loading, inline, wrapperStyle } = this.props;
    return (
      <div
        className="spinner"
        data-testid="spinner"
        style={{
          ...loader.spinnerWrap,
          ...(loading && loader.spinnerLoading),
          ...(inline && {
            maxHeight: '113px',
            top: '49px'
          }),
          ...wrapperStyle,
          ...(!loading && { display: 'none' })
        }}
      >
        <div className="boxLoader" style={loader.loader} />
      </div>
    );
  }
}

Spinner.propTypes = {
  loading: PropTypes.bool,
  inline: PropTypes.bool,
  wrapperStyle: PropTypes.oneOfType([PropTypes.object])
};
Spinner.defaultProps = {
  loading: false,
  inline: false,
  wrapperStyle: {}
};

export default Spinner;
