import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import bigDecimal from 'js-big-decimal';

const getMockMvbBinPerformance = (options) => {
  const preferred = transactionalData();
  const standard = transactionalData();
  const elevated = transactionalData();
  const total = Object.entries(preferred).reduce((acc, [performanceId, performanceData]) => {
    acc[performanceId] = Number(bigDecimal.add((bigDecimal.add(`${performanceData || 0}`, `${standard[performanceId] || 0}`)), `${elevated[performanceId] || 0}`));
    return acc;
  }, {});
  const {
    yearMonth
  } = options;
  const combineAndAddFields = {
    yearMonth,
    transactionalDataList: [
      {
        ...preferred,
        riskProfile: 'preferred',
        chargebackCountRatio: 0.002,
        chargebackCountTriggerRatio: 0.001,
        chargebackCountLimitRatio: 0.0025
      },
      {
        ...standard,
        riskProfile: 'standard',
        chargebackCountRatio: 0.049,
        chargebackCountTriggerRatio: 0.035,
        chargebackCountLimitRatio: 0.05
      },
      {
        ...elevated,
        riskProfile: 'elevated',
        chargebackCountRatio: 0.09,
        chargebackCountTriggerRatio: 0.07,
        chargebackCountLimitRatio: 0.09
      },
      {
        ...total,
        riskProfile: 'total',
        chargebackCountRatio: 0.007,
        chargebackCountTriggerRatio: 0.0045,
        chargebackCountLimitRatio: 0.006
      }
    ]
  };
  return combineAndAddFields;
};

const transactionalData = () => ({
  salesCount: getMockValue({ type: 'number', min: 1, max: 20 }),
  refundCount: getMockValue({ type: 'number', min: 1, max: 20 }),
  chargebackCount: getMockValue({ type: 'number', min: 1, max: 20 }),
  salesVolume: getMockValue({ type: 'number', min: 10000, max: 90000 }),
  refundVolume: getMockValue({ type: 'number', min: 10000, max: 90000 }),
  chargebackVolume: getMockValue({ type: 'number', min: 10000, max: 90000 }),
  chargebackCountRatio: getMockValue({ type: 'ratio', maxDecimalCount: 4 }),
  chargebackVolumeRatio: getMockValue({ type: 'ratio', maxDecimalCount: 4 }),
  refundCountRatio: getMockValue({ type: 'ratio', maxDecimalCount: 4 }),
  refundVolumeRatio: getMockValue({ type: 'ratio', maxDecimalCount: 4 })
});

export const mockMvbBinPerformance = (body, params, options) => {
  const mockYearMonthList = ['2020-08', '2020-07', '2020-06'];
  return {
    mvbBinPerformance: mockYearMonthList
      .map(aYearMonth => getMockMvbBinPerformance({ yearMonth: aYearMonth }))
  };
};

export default mockMvbBinPerformance;
