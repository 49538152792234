import { isEmpty, transformData } from '@f1/shared/src/_helpers';
import { crmEndpoint } from '../../utilsEndpoints';
import {
  merchantGuids,
  partnerGuids,
  relationshipGuids,
  ticketGuids
} from '../guids';

import mockFiles from './mockFiles';
import fileV2Template from './templates/fileV2Template';

export const mockFilesGetRes = {
  [ticketGuids.default]: [
    mockFiles.file01,
    mockFiles.file02,
    mockFiles.file03
  ],
  [ticketGuids.merchantGuid]: [
    mockFiles.file01,
    mockFiles.file02,
    mockFiles.file03,
    mockFiles.file04,
    mockFiles.file05,
    mockFiles.file06
  ],
  [ticketGuids.partnerId]: [
    mockFiles.file02,
    mockFiles.file03
  ],
  [ticketGuids.relationshipId]: [
    mockFiles.file01,
    mockFiles.file03
  ],
  'c4d768ec-3456-4df8-882f-bc65dde39812': [ // Ticket - mock ticket 2
    mockFiles.file02,
    mockFiles.file03,
    mockFiles.file01
  ],
  'c4d7asec-3456-4d08-882f-bc65dde39812': [ // Ticket - mock ticket 3
    mockFiles.file03
  ],
  'c4d768ec-3456-4d08-882f-bc65gee39812': [ // Ticket - mock ticket 4
  ],
  '9a40375c-ae79-4f44-9fa1-87bdfe55537c': [ // Merchant - FE test data should work
    mockFiles.file01,
    mockFiles.file03
  ],
  'a9150b5a-2853-4463-9e8c-5deed64abca1': [ // Merchant - Test Merchant DBA Name
    mockFiles.file02,
    mockFiles.file03
  ],
  '27816767-dfc9-42d1-a08e-7aaf599616fd': [ // Merchant - Test Merchant DBA Name2
    mockFiles.file02,
    mockFiles.file01
  ],
  [partnerGuids.partner02]: [ // Partner - 100
    mockFiles.file01,
    mockFiles.file02,
    mockFiles.file03,
    mockFiles.file04,
    mockFiles.file05,
    mockFiles.file06

  ],
  ChargebackDaoSqlTest_relationshipId: [ // Relationship - 02X60txdbGGHNSJ
    mockFiles.file01,
    mockFiles.file02,
    mockFiles.file03
  ]
};

export const mockFilesGetFormatted = transformData({
  data: mockFilesGetRes[ticketGuids.default],
  toSchema: 'frontend',
  template: fileV2Template
});

export const mockFilesPutRes = (body) => {
  if (isEmpty(body?.files)) {
    return [
      {
        fileName: 'mstile-150x150.png',
        absolutePath: '/',
        url: crmEndpoint.file.v2DummyUploadUrl
      },
      {
        fileName: 'mstile-310x150.png',
        absolutePath: '/',
        url: crmEndpoint.file.v2DummyUploadUrl
      },
      {
        fileName: 'mstile-310x310.png',
        absolutePath: '/',
        url: crmEndpoint.file.v2DummyUploadUrl
      }
    ];
  }
  return body.files.map(file => ({ fileName: file, absolutePath: '/root/newMerchant', url: crmEndpoint.file.v2DummyUploadUrl })) || [];
};

const dynamicPutResponse = (body) => {
  if (isEmpty(body)) {
    return [
      {
        fileName: 'mstile-150x150.png',
        relativePath: '/',
        url: crmEndpoint.file.v2DummyUploadUrl
      },
      {
        fileName: 'mstile-310x150.png',
        relativePath: '/',
        url: crmEndpoint.file.v2DummyUploadUrl
      },
      {
        fileName: 'mstile-310x310.png',
        relativePath: '/',
        url: crmEndpoint.file.v2DummyUploadUrl
      }
    ];
  }
  return body?.files?.map(file => (
    {
      fileName: file.fileName,
      relativePath: isEmpty(file.relativePath) ? '/' : file.relativePath,
      url: crmEndpoint.file.v2DummyUploadUrl
    })) || [];
};

export const mockFilesAttachToResourcePutRes = body => ({
  [relationshipGuids.default]: dynamicPutResponse(body),
  [merchantGuids.default]: dynamicPutResponse(body),
  [partnerGuids.default]: dynamicPutResponse(body),
  [ticketGuids.default]: dynamicPutResponse(body)
});

const mockFilesPaths = {
  paths: {
    validPaths: ['/COMMON', '/COMMON/publicSecret', '/OPS', '/OPS/banana', 'OPS/banana/cake', 'OPS/banana/cake/yummy'],
    invalidPaths: ['/CREDIT', '/RISK', '/STATEMENT', '/1099']
  }
};

export const mockFilesPathRes = {
  [relationshipGuids.default]: mockFilesPaths,
  [merchantGuids.default]: mockFilesPaths,
  [partnerGuids.default]: mockFilesPaths,
  [ticketGuids.default]: mockFilesPaths
};
