import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { Icon } from '../css/_styledComponents';
import { icons } from '../images/_icons';
import {
  isEmpty,
  isInViewport,
  dropdownDirection,
  getTextDimensions
} from './_helpers';
import { ToolTip } from './ToolTip';
import { Checkbox } from './Checkbox';
import { Button } from './Button';
import { multiSelect, input, nestedMenuCSS } from './_styles';
import { CustomSearch } from './CustomSearch';
import { ErrorBox } from '../css/_styledFormComponents';

export class MultiSelect extends React.PureComponent {
  constructor (props) {
    super(props);
    this.mounted = false;
    const { title, allChecked } = this.props;
    /**
     * Unique ID needed for MultiSelect comps that render multiple times (in a list)
     * with the same ID.
     */
    this.multiSelectUniqueId = (`multiSelect${uuidv4()}`).replace(/-/g, ''); // used for internal MultiSelect state only
    this.multiSelectList = React.createRef();
    this.state = {
      useValidationStyles: false,
      isValid: false,
      listOpen: false,
      testOnChange: false,
      headerTitle: title,
      selection: this.findSelected() && this.findSelected().title,
      hovered: null,
      direction: 'down',
      listHeight: 284,
      startY: 0,
      itemMapping: {},
      allItemsChecked: allChecked,
      applyButtonDisabled: false,
      selectionChange: false,
      filteredList: [],
      searchValue: '',
      noTransition: 'noTransition ' // DO NOT REMOVE EXTRA SPACE
    };
  }

  componentDidMount () {
    const { allChecked } = this.props;
    this.mounted = true;
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('click', this.handleClick);
    if (allChecked) {
      this.toggleAll(true);
    }
    this.setSelectedItems();
  }

  componentDidUpdate = async (prevProps, prevState) => {
    const {
      selected,
      list,
      allChecked,
      checkedItems
    } = this.props;
    const { allItemsChecked, selection, itemMapping } = this.state;
    if (prevProps.selected !== selected || prevState.selection !== selection) {
      this.updateState({ selection: selected });
    }
    if (JSON.stringify(prevState.itemMapping) !== JSON.stringify(itemMapping)) {
      this.checkValidSelection();
    }
    if (prevProps.allChecked !== allChecked && allChecked !== allItemsChecked) {
      this.toggleAll(allChecked);
    }
    if (JSON.stringify(prevProps.list) !== JSON.stringify(list) ||
        (JSON.stringify(prevProps.checkedItems) !== JSON.stringify(checkedItems))
    ) {
      this.setSelectedItems();
    }
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('click', this.handleClick);
    this.mounted = false;
  }

  updateState = (state, callback = null) => {
    this.mounted && this.setState(state, callback);
  }

  generateDataList = (filtered) => {
    const {
      list,
      checkedItems
    } = this.props;
    const data = filtered || list;
    const newMapping = data.reduce((acc, listItem) => {
      const items = !isEmpty(checkedItems) ? checkedItems : [];
      const checked = items.find(
        checkedItem => (checkedItem === listItem.value) || (checkedItem.value === listItem.value)
      );
      return ({
        ...acc,
        [listItem.value]: {
          checked: !isEmpty(checked),
          ...(listItem.title && { title: listItem.title }),
          ...(listItem.parent && { parent: listItem.parent }),
          ...(listItem.depth && { depth: listItem.depth }),
          ...(listItem.icon && { icon: listItem.icon })
        }
      });
    }, {});
    // define parent/child relationships
    Object.keys(newMapping).forEach((id) => {
      data.forEach((item) => {
        if (item.parent === id) {
          if (!Object.prototype.hasOwnProperty.call(newMapping[item.parent], 'children')) {
            newMapping[item.parent].children = [];
          }
          newMapping[item.parent].children.push(item.value);
        }
      });
    });
    return newMapping;
  }

  setSelectedItems = async () => {
    const {
      title,
      allChecked,
      isFormField,
      checkedItems
    } = this.props;
    const itemMapping = await this.generateDataList();
    this.updateState({
      ...(isFormField && !isEmpty(checkedItems) && { testOnChange: true }),
      itemMapping,
      filteredList: itemMapping, // on load set filtered list to everything
      headerTitle: title
    });
    if (allChecked) {
      this.toggleAll(true);
    }
  }

  handleValidate = () => {
    const { isFormField, required, validationActivated } = this.props;
    const { testOnChange } = this.state;
    const hasChecked = this.hasSelections();
    this.updateState({
      ...((testOnChange || validationActivated) && {
        ...((isFormField || validationActivated || required) && {
          isValid: hasChecked || !required
        }),
        useValidationStyles: isFormField
      })
    });
  }

  hasSelections = () => {
    const { itemMapping } = this.state;
    const selectedItems = !isEmpty(itemMapping)
      ? Object.values(itemMapping).filter(item => item.checked)
      : [];
    return !isEmpty(selectedItems);
  }

  checkValidSelection = () => {
    const hasChecked = this.hasSelections();
    this.updateState({ applyButtonDisabled: !hasChecked });
  }

  findSelected = () => {
    // On load, get list of selected titles to display in header
    const { selected, list } = this.props;
    return list.find((o, i) => o.value === selected || o.title === selected);
  }

  handleCloseList = () => {
    this.updateState({
      hovered: null,
      listOpen: false,
      direction: 'down',
      selectionChange: false
    });
  }

  toggleVisible = (guid, checkState) => {
    // checkState TRUE === hide the child list item
    const node = document.querySelector(`[data-key="${guid}"]`);
    if (node) {
      if (checkState) {
        node.classList.add('minimized');
        node.classList.remove('maximized');
      } else {
        node.classList.add('maximized');
        node.classList.remove('minimized');
      }
    }
  }

  handleSelectItem = async (guid, checked) => {
    const { itemMapping } = this.state;
    const newData = { ...itemMapping };
    // toggle whatever checkbox was clicked
    await this.toggleItem(guid, checked || !newData[guid].checked);
    // handle if used clicked the "all" option
    if (guid === 'all') {
      this.toggleAll(newData.all.checked);
    } else {
      // always deselect all if something else was checked
      if (newData.all) { newData.all.checked = false; }
      // something other than "all" was selected, handle children/parent selections
      if (newData[guid].children) {
        await this.toggleChildren(guid, newData[guid].checked);
      }
      if (newData[guid].checked && newData[guid].parent) {
        // unselect all parents if a child was selected
        await this.toggleParents(newData[guid].parent);
      }
    }
    this.updateState({
      itemMapping: newData
    }, this.checkValidSelection);
  }

  toggleItem = (guid, checked = null) => {
    const { itemMapping } = this.state;
    const newData = { ...itemMapping };
    newData[guid].checked = checked !== null ? checked : !newData[guid].checked;
    this.updateState({
      itemMapping: newData
    }, this.checkValidSelection);
  }

  toggleChildren = (guid, checked) => {
    const { itemMapping } = this.state;
    // unselect the children since parent was selected
    itemMapping[guid].children.forEach(async (child) => {
      await this.toggleItem(child, false); // always uncheck
      // also toggle their class to disable them since parent was selected
      this.toggleVisible(child, checked);
      if (itemMapping[child].children) {
        await this.toggleChildren(child, checked);
      }
    });
  }

  toggleParents = async (parent) => {
    const { itemMapping } = this.state;
    await this.toggleItem(parent, false);
    if (itemMapping[parent].parent) {
      // unselect all parents if a child was selected
      this.toggleParents(itemMapping[parent].parent);
    }
  }

  handleClick = (e) => {
    const {
      applyButtonDisabled,
      selectionChange,
      useValidationStyles
    } = this.state;
    const insideClick = this.isInsideClick(e);
    const applyButtonClick = this.isApplyClick(e);
    const outsideClick = this.isOutsideClick(e);
    if (insideClick) {
      e && e.preventDefault();
      this.updateState({ listOpen: true });
    } else if (applyButtonClick) {
      e && e.preventDefault();
      this.updateState({ listOpen: false });
    } else if (outsideClick) {
      e && e.preventDefault();
      if ((!applyButtonDisabled || useValidationStyles) && selectionChange) {
        // only apply if there was a selection change
        this.handleApply();
      }
      this.handleCloseList();
    }
  }

  isApplyClick = (e) => { // clicking the apply button
    const { target } = e;
    return e && !isEmpty(this.multiSelectList.current) && !isEmpty(target.id) && target.id === 'applyButton';
  }

  isInsideClick = (e) => {
    const { target } = e;
    const {
      parentElement
    } = target || {};
    const {
      id: parentId,
      classList: parentClassList
    } = parentElement || {};
    if (this.multiSelectList.current) {
      return (
        // clicking within the list
        (e && this.multiSelectList.current.contains(target)) ||
        // // clicking within search bar
        (parentElement && (parentId === 'menuSearchWrapper' ||
        parentClassList.contains('menuSearchField')))
      );
    }
    return false;
  }

  isOutsideClick = (e) => {
    const { id } = this.props;
    const { listOpen } = this.state;
    const container = document.querySelector(`#${id}.${this.multiSelectUniqueId}`);
    if (this.multiSelectList.current && listOpen && e) {
      return container && !container.contains(e.target);
    }
    return false;
  }

  handleHeaderClick = async (element) => {
    const { listOpen, applyButtonDisabled, selectionChange } = this.state;
    const insideClick = await this.isInsideClick(element || {});
    if (listOpen && element && !this.multiSelectList.current.contains(element.target) &&
    !insideClick) {
      if (!applyButtonDisabled && selectionChange) {
        await this.handleApply();
        await this.handleCloseList();
      } else {
        await this.updateState(prevState => ({
          ...prevState,
          listOpen: !prevState.listOpen
        }));
      }
    } else {
      await this.updateState({ listOpen: true });
    }
    return true;
  }

  toggleAll = async (toggleOn) => {
    const { allItemsChecked, itemMapping } = this.state;
    const toggleValue = !!toggleOn === toggleOn ? toggleOn : !allItemsChecked;
    const newData = { ...itemMapping };
    if (toggleValue) {
      // all was clicked and is currently active, so select all top level items
      Object.keys(newData).forEach((key) => {
        newData[key].checked = !newData[key].parent;
      });
    } else {
      // all was clicked and has been deselected, deselect everything
      Object.keys(newData).forEach((key) => {
        newData[key].checked = false;
      });
    }
    this.updateState({
      selectionChange: true,
      itemMapping: newData,
      allItemsChecked: toggleValue
    });
  }

  handleApply = () => {
    const { id, list, callback } = this.props;
    const { itemMapping } = this.state;
    const guidArray = Object.entries(itemMapping)
      .filter(([guid, item]) => item.checked)
      .map(selection => ({
        guid: selection[0],
        dba: selection[1].title
      }));
    callback(guidArray, id);
    this.updateState({ testOnChange: true }, this.handleValidate);
    this.handleSearch({ results: list, value: '' }); // reset search
    this.handleCloseList();
  }

  handleMouseIn = (e) => {
    e && this.updateState({ hovered: e.currentTarget.getAttribute('data-key').toString() });
  }

  handleMouseOut = (e) => {
    this.updateState({ hovered: null });
  }

  toggleList = async (e) => {
    const { ...element } = e;
    this.handleHeaderClick(element || {});
    this.updateState({ direction: 'down' });
    // Need await below to ensure nodes exist before trying to remove noTransition class
    await this.checkDirection();
    // WE don't want these to animate when first opening
    // after they appear, we can remove the noTransition class
    this.updateState({ noTransition: '' });
    this.checkValidSelection();
  }

  checkDirection = () => {
    const { customDropListHeight, id } = this.props;
    const multiSelectList = this.multiSelectList.current;
    const parent = document.querySelector(`#${id}.${this.multiSelectUniqueId}`);
    const listWrapper = parent && parent.querySelector('.listWrapper');
    const footer = document.querySelector('footer');
    if (!isEmpty(multiSelectList) && !isEmpty(parent) && !isEmpty(listWrapper) &&
    !isEmpty(footer)) {
      const newDropdownDirection = dropdownDirection(listWrapper, footer && { footer });
      const multiSelectListHeight = multiSelectList.getBoundingClientRect().height;
      this.updateState({
        direction: (!isEmpty(customDropListHeight) && customDropListHeight <= 68) ? 'up' : newDropdownDirection,
        ...(multiSelectListHeight !== 0 ? { listHeight: multiSelectListHeight } : [])
      });
      this.handleScroll();
    }
  }

  handleScroll = () => {
    const { direction } = this.state;
    const { id, dropListHeight, dropListOptionHeight } = this.props;
    const multiSelectList = this.multiSelectList.current;
    const header = document.querySelector('#siteHeader.header');
    const footer = document.querySelector('footer');
    if (!isEmpty(multiSelectList) && !isEmpty(header) && !isEmpty(footer)) {
      const containerTop = multiSelectList.getBoundingClientRect().top;
      const headerBottom = header.getBoundingClientRect().bottom;
      const footerTop = footer.getBoundingClientRect().top;
      const parent = document.querySelector(`#${id}.${this.multiSelectUniqueId}`);
      const parentViewPort = isInViewport(parent, { fullVisibility: true });
      const maxHeight = direction === 'up'
        ? Math.max(
          Math.min((parentViewPort.elemTop - headerBottom), dropListHeight),
          dropListOptionHeight
        )
        : dropListHeight;
      const pageSpace = footerTop - headerBottom;
      this.updateState({
        listHeight: pageSpace <= maxHeight
          ? Math.max(pageSpace, dropListOptionHeight)
          : maxHeight
      });
      // so list doesn't collide with header & footer on narrow screens
      if (!parentViewPort.isVisible || headerBottom > containerTop) { this.handleCloseList(); }
    }
  }

  handleTouchStart = (e) => {
    const container = this.multiSelectList.current;
    if (!isEmpty(container)) {
      const touch = e.changedTouches[0];
      const startY = touch.clientY;
      this.updateState({ startY });
    }
  }

  handleTouchEnd = (e, guid) => {
    const container = this.multiSelectList.current;
    const { startY } = this.state;
    if (!isEmpty(container)) {
      const touch = e.changedTouches[0];
      const endY = touch.clientY;
      const yDistance = startY - endY;
      if (Math.abs(yDistance) < 30) { // option tapped
        e.preventDefault();
        this.handleSelectItem(guid);
      }
    }
  }

  handleSearch = (options) => {
    const { results, value } = options || {};
    const filteredList = this.generateDataList(results);
    if (!isEmpty(value)) {
      // only if there was a search value, always clear all selections
      this.toggleAll(false); // trigger the deselection of all selected items in master data
      Object.keys(filteredList).forEach((key) => {
        filteredList[key].checked = false;
      });
    }
    this.updateState({
      filteredList, // don't dedupe here, need to use original list
      searchValue: !isEmpty(value) ? value : ''
    });
  }

  setChildClass = (key) => {
    const { itemMapping } = this.state;
    let hasCheckedParent = false;
    const searchParents = (guid) => {
      // does the guid have a parent
      if (itemMapping[guid].checked) {
        hasCheckedParent = true;
      } else if (itemMapping[guid].parent) {
        searchParents(itemMapping[guid].parent);
      }
    };
    itemMapping[key].parent && searchParents(itemMapping[key].parent);
    return hasCheckedParent
      ? 'minimized'
      : 'maximized';
  }

  render () {
    const {
      wrapperStyle,
      label,
      labelPos,
      required,
      disabled,
      height,
      list,
      selected,
      addFilterBorder,
      dropListHeight,
      tooltip,
      boxStyle,
      className,
      id,
      customDropListHeight,
      tooltipWidth,
      infoTipDisplay,
      searchKeys,
      hideSearch
    } = this.props;
    const {
      useValidationStyles,
      isValid,
      listOpen,
      headerTitle,
      selection,
      hovered,
      direction,
      listHeight,
      applyButtonDisabled,
      filteredList,
      itemMapping,
      searchValue,
      noTransition
    } = this.state;
    const stateCSS = listOpen ? icons.listLess : icons.listMore;
    const arrow = {
      ...(stateCSS.paddingLeft && { paddingLeft: '30px' }),
      ...(stateCSS.paddingRight && { paddingRight: '30px' })
    };
    const defaultClassName = (required ? 'multiSelectDropMenu required' : 'multiSelectDropMenu').concat(` ${this.multiSelectUniqueId}`);
    const customClassName = !isEmpty(className) ? ` ${className}` : '';
    const validationWrapperStyles = {
      ...(!disabled && useValidationStyles && {
        ...(isValid && {
          backgroundColor: 'var(--color-healthy-bg)',
          borderColor: 'hsl(var(--color-healthy-hue),var(--color-healthy-saturation),calc(var(--color-healthy-lightness) + 10%))'
        }),
        ...(required && !isValid && {
          backgroundColor: 'var(--color-warning-bg)',
          borderColor: 'var(--color-warning)'
        })
      })
    };
    return (
      <div
        id={id}
        className={`${defaultClassName}${customClassName}`}
        style={{
          ...multiSelect.container,
          ...wrapperStyle,
          ...(disabled && { background: '#ededee' }),
          ...(boxStyle === 'inside'
            ? { ...multiSelect.innerWrap, ...validationWrapperStyles }
            : input.wrap)
        }}
      >
        { label && (
          <label
            className="menuLabel"
            id={`label_${label.toLowerCase().replace(/ /g, '_')}`}
            style={{
              ...(labelPos === 'right' && multiSelect.labelRight),
              ...((labelPos === 'left' && height) && {
                ...multiSelect.labelLeft,
                lineHeight: `${height}px`
              }),
              ...(boxStyle === 'inside' ? input.labelInside : input.label)
            }}
            htmlFor={`input_${label.toLowerCase().replace(/ /g, '_')}`}
          >
            { required && (
              <span style={input.label_required}>* </span>
            )}
            {label}
            {tooltip && (
              <ToolTip
                infoTip
                infoTipDisplay={infoTipDisplay}
                {...tooltipWidth && { width: tooltipWidth }}
              >
                {tooltip}
              </ToolTip>
            )}
          </label>
        )}
        <div
          id={`input_${label.toLowerCase().replace(/ /g, '_')}`}
          className={required ? 'multiSelectMenuHeader required' : 'multiSelectMenuHeader'}
          data-list={JSON.stringify(list)}
          style={{
            ...(boxStyle === 'inside' ? multiSelect.innerHeader : multiSelect.header),
            ...arrow,
            ...(listOpen && multiSelect.active),
            ...({ height: `${height || 30}px` }),
            ...((height && height <= 31) && { fontSize: '1.2rem' }),
            ...(labelPos === 'left' && {}),
            ...(addFilterBorder && !isEmpty(selected) && multiSelect.filtered),
            ...(direction === 'up' && {
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0
            }),
            ...(disabled && input.innerWrapDisabled),
            ...(boxStyle === 'inside' ? {} : { ...validationWrapperStyles }),
            maxWidth: '100%'
          }}
          role="menu"
          aria-label={label ? `${label} multiselect menu` : 'Multiselect menu'}
          tabIndex="0"
          {...!disabled && {
            onClick: this.toggleList,
            onKeyDown: this.toggleList
          }}
        >
          <Icon
            icon={stateCSS.src_white}
            color="var(--color-light-label)"
            $useMask
            style={{
              position: 'absolute',
              ...(stateCSS.paddingLeft && { left: '2px' }),
              ...(stateCSS.paddingRight && { right: '2px' })
            }}
          />
          <div
            className="title"
            style={{
              ...multiSelect.title,
              height: `${height - 4}px`,
              lineHeight: `${height - 4}px`
            }}
          >
            {selection || headerTitle}
          </div>
        </div>
        {listOpen && (
          <div
            className="listWrapper"
            style={{
              ...multiSelect.listWrapper,
              ...(height === 40 && boxStyle !== 'inside' && !isEmpty(label) && { marginTop: '64px' }),
              ...(direction === 'up' && {
                ...multiSelect.inverted,
                bottom: `${height - 1}px`
              })
            }}
          >
            <ul
              ref={this.multiSelectList}
              className="multiSelectList"
              style={{
                ...multiSelect.list,
                maxHeight: `${(direction !== 'up' && listHeight >= dropListHeight) ? dropListHeight : listHeight}px`,
                ...(!isEmpty(customDropListHeight) && customDropListHeight > 68 && {
                  height: customDropListHeight
                }),
                ...(direction === 'up' && multiSelect.listInverted)
              }}
              role="menu"
            >
              {isEmpty(filteredList) && !isEmpty(searchValue)
                ? (
                  <div className="noResults" style={multiSelect.menuSearchNoResults}>
                    No Results
                  </div>
                )
                : (
                  <React.Fragment>
                    {!isEmpty(filteredList) && Object.keys(filteredList).map((key, i) => (
                      <li
                        className={`${noTransition}${this.setChildClass(key)}`}
                        key={`${key}_${i.toString()}`}
                        style={{
                          maxHeight: '80px',
                          ...multiSelect.item,
                          ...(itemMapping[key].checked && multiSelect.item_selected),
                          ...(((key && hovered === key.toString()) && !itemMapping[key].checked) &&
                          multiSelect.item_hover
                          ),
                          ...(itemMapping[key].icon && {
                            ...(itemMapping[key].icon.paddingLeft && { paddingLeft: '30px' }),
                            ...(itemMapping[key].icon.paddingRight && { paddingRight: '40px' })
                          }),
                          minHeight: `${height}px`,
                          ...(height <= 31 && { fontSize: '1.2rem' }),
                          ...(itemMapping[key].parent && isEmpty(searchValue) && {
                            paddingLeft: `${10 + (itemMapping[key].depth * 10)}px`
                          }),
                          ...(itemMapping[key].parent && !isEmpty(searchValue) && { paddingLeft: '10px' }),
                          position: 'relative'
                        }}
                        data-key={key}
                        role="menuitem"
                        aria-label={(itemMapping[key] && itemMapping[key].title) || `Multiselect menu item ${i + 1}`}
                        tabIndex="0"
                        onKeyDown={() => this.handleSelectItem(key)}
                        onClick={() => this.handleSelectItem(key)}
                        onMouseEnter={this.handleMouseIn}
                        onMouseLeave={this.handleMouseOut}
                        onTouchStart={this.handleTouchStart}
                        onTouchEnd={e => this.handleTouchEnd(e, key)}
                      >
                        {getTextDimensions(itemMapping[key].title).width > 334 && (
                          <ToolTip
                            infoTip
                            tipPosition="top"
                            infoTipDisplay={infoTipDisplay}
                            {...tooltipWidth && { width: tooltipWidth }}
                            wrapperStyle={{ position: 'absolute', top: '5px', right: '0' }}
                          >
                            {itemMapping[key].title}
                          </ToolTip>
                        )}
                        <Checkbox
                          type="mini"
                          height="100%"
                          name={key}
                          id={key}
                          label={itemMapping[key].title}
                          value={key}
                          checked={itemMapping[key].checked}
                          labelStyle={{
                            whiteSpace: 'normal',
                            maxWidth: 'calc(100% - 14px)',
                            wordWrap: 'break-word'
                          }}
                        />
                        { itemMapping[key].icon && (
                          <Icon
                            icon={itemMapping[key].icon.src_color}
                            style={{
                              position: 'absolute',
                              top: '50%',
                              right: '5px',
                              transform: 'translate(0, -50%)'
                            }}
                            $useMask
                          />
                        )}
                      </li>
                    ))}
                  </React.Fragment>
                )}
            </ul>
            {!hideSearch && (
              <div
                className="bottomBar"
                style={{
                  ...multiSelect.bottomBar,
                  ...(direction === 'up' && multiSelect.bottomBarInverted),
                  minHeight: `60px`
                }}
              >
                <CustomSearch
                  id="multi-select-search"
                  list={list}
                  searchCallback={this.handleSearch}
                  existingSearchValue={searchValue}
                  searchKeys={searchKeys}
                >
                  <Button
                    id="applyButton"
                    style={nestedMenuCSS.cancelLink}
                    size="sm"
                    disabled={applyButtonDisabled}
                    onClick={this.handleApply}
                  >
                    Apply
                  </Button>
                </CustomSearch>
              </div>
            )}
          </div>
        )}
        <ErrorBox
          className="errors"
          id={`${id}-error`}
          error={useValidationStyles && !isValid}
          $boxStyle={boxStyle}
          style={{ ...(!useValidationStyles && !isValid && { height: '0' }) }}
        >
          {required && 'Must be completed'}
        </ErrorBox>
      </div>
    );
  }
}

MultiSelect.propTypes = {
  title: PropTypes.string,
  callback: PropTypes.func,
  wrapperStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  list: PropTypes.oneOfType([PropTypes.array]),
  checkedItems: PropTypes.oneOfType([PropTypes.array]),
  allChecked: PropTypes.bool,
  label: PropTypes.string,
  labelPos: PropTypes.string,
  required: PropTypes.bool,
  selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  addFilterBorder: PropTypes.bool,
  dropListHeight: PropTypes.number,
  dropListOptionHeight: PropTypes.number,
  tooltip: PropTypes.string,
  boxStyle: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,
  customDropListHeight: PropTypes.number,
  tooltipWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  infoTipDisplay: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  searchKeys: PropTypes.oneOfType([PropTypes.array]),
  isFormField: PropTypes.bool,
  validationActivated: PropTypes.bool,
  hideSearch: PropTypes.bool
};

MultiSelect.defaultProps = {
  title: '',
  callback: () => {},
  wrapperStyle: {},
  height: 60,
  list: [],
  checkedItems: [],
  allChecked: false,
  label: '',
  labelPos: 'top',
  required: false,
  selected: null,
  addFilterBorder: false,
  dropListHeight: 284,
  dropListOptionHeight: 55,
  tooltip: null,
  boxStyle: null,
  disabled: false,
  className: null,
  id: null,
  customDropListHeight: null,
  tooltipWidth: null,
  infoTipDisplay: {},
  searchKeys: [{ title: 'dba', value: 'title' }],
  isFormField: false,
  validationActivated: false,
  hideSearch: false
};

export default MultiSelect;
