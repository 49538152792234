import { inflate, deflate, gzip } from 'pako';
import {
  envIsDevOrLess,
  getType,
  isEmpty,
  logError,
  sharedGetInnerAlertBarState,
  useStubData
} from './_helpers';
import { Storage } from './_dataStoreDb';
import { getParsedJson } from './_templateHelpers';

// For FTs / using mock data locally only
const useLocalStorage = () => (envIsDevOrLess() && ((localStorage && localStorage.getItem('localToken') === 'mockFrontendCsrfToken') || useStubData));

export const base64EncodeData = (header) => {
  let data = null;
  try {
    const isLocal = useLocalStorage();
    const dataString = getType(header) === 'string' ? header : JSON.stringify(header);
    const gzipData = isLocal ? dataString : gzip(dataString);
    const base64Encoded = isLocal ? dataString : Buffer.from(gzipData, 'utf8').toString('base64');
    data = base64Encoded;
  } catch (err) {
    const encodeErr = typeof err === 'string' ? new Error() : err;
    encodeErr.message = typeof err === 'string' ? `Error encoding data: ${err}` : `Error encoding data: ${err.message || 'unknown error'}`;
    return getError(encodeErr);
  }
  return { data };
};

export const base64DecodeData = async (encodedData) => {
  let data = null;
  try {
    const isLocal = useLocalStorage();
    const decodedData = isLocal ? encodedData : Buffer.from(encodedData, 'base64');
    data = decodedData;
  } catch (err) {
    const decodeErr = typeof err === 'string' ? new Error() : err;
    decodeErr.message = typeof err === 'string' ? `Error decoding data: ${err}` : `Error decoding data: ${err.message || 'unknown error'}`;
    return getError(decodeErr);
  }
  return { data };
};

export const compressData = (data, options) => {
  const { stringify } = options || {};
  const compressedData = { data: null };
  const isLocal = useLocalStorage();
  if (isLocal) {
    compressedData.data = stringify ? JSON.stringify(data) : data;
    return compressedData;
  }
  try {
    const compressed = deflate(JSON.stringify(data));
    compressedData.data = compressed;
  } catch (err) {
    return getError(err, options);
  }
  return compressedData;
};

export const decompressData = (compressedData, options) => {
  const { parse } = options || {};
  const decompressedData = { data: null };
  const isLocal = useLocalStorage();
  if (isLocal) {
    decompressedData.data = parse ? getParsedJson(compressedData) : compressedData;
    return decompressedData;
  }
  try {
    const restored = !isEmpty(compressedData)
      ? JSON.parse(inflate(compressedData, { to: 'string' }))
      : null;
    decompressedData.data = restored;
  } catch (err) {
    return getError(err, options);
  }
  return decompressedData;
};

const getError = (err, options) => {
  const { axiosRequest } = options || {};
  const formattedError = err instanceof Error ? err : new Error(err);
  const { alertBarMessage } = sharedGetInnerAlertBarState({
    axiosRequest,
    type: 'warning',
    data: getType(err) === 'string' ? err : formattedError
  });
  return { errorDetails: formattedError, errorMessage: alertBarMessage };
};

export const addToLocalDB = async (key, value, options) => {
  const results = { dataStored: false };
  const isLocal = useLocalStorage();
  if (isLocal) {
    localStorage.setItem(key, JSON.stringify(value));
    results.dataStored = true;
    return results;
  }
  try {
    const compressedData = !isEmpty(value) ? compressData(value) : null;
    const dataString = !isEmpty(compressedData) ? compressedData : null;
    await Storage.set(key, dataString);
    results.dataStored = true;
  } catch (err) {
    results.dataStored = false;
    results.errorDetails = err;
    await handleLogError(err, options);
  }
  return results;
};

export const getFromLocalDB = async (key, options) => {
  const { axiosRequest } = options || {};
  const isLocal = useLocalStorage();
  if (isLocal) {
    const localData = localStorage.getItem(key);
    try {
      const parsed = !isEmpty(localData) ? JSON.parse(localData) : null;
      return parsed;
    } catch (e) {
      return localData || null;
    }
  }
  try {
    const storedData = !isEmpty(key) ? await Storage.get(key) : {};
    const decompressedData = !isEmpty(storedData) && !isEmpty(storedData.data)
      ? decompressData(storedData.data, { axiosRequest })
      : {};
    const { errorDetails, data } = decompressedData || {};
    if (errorDetails instanceof Error) { throw errorDetails; }
    return data ?? null;
  } catch (err) {
    await handleLogError(err, options);
    return null;
  }
};

const clearLocalStorage = () => {
  if (localStorage) {
    // Remove any stored data that should not be saved on sign-out
    localStorage.removeItem('localToken');
    localStorage.removeItem('guidColorMap');
    localStorage.removeItem('nestedMerchants');
    localStorage.removeItem('relationshipForest');
  }
};

export const clearLocalDB = async (options) => { // Clears out any authenticated data on sign out
  const results = { dataCleared: false };
  clearLocalStorage();
  const isLocal = useLocalStorage();
  if (isLocal) {
    results.dataCleared = true;
    return results;
  }
  try {
    const newClearOnSignoutKeys = await Storage.keys();
    !isEmpty(newClearOnSignoutKeys) && newClearOnSignoutKeys.map(key => Storage.del(key));
    results.dataCleared = true;
  } catch (err) {
    results.dataCleared = false;
    results.errorDetails = err;
    await handleLogError(err, options);
  }
  return results;
};

const handleLogError = (err, options) => logError(err, { logLevel: 'WARN', ...options });
