export const mockAchRejectHistory = {
  achRejectList: [
    {
      eventDate: '2021-07-02',
      rejectAmount: 200,
      reasonCode: 'code04',
      reasonDescription: 'Failure to pay on time'
    },
    {
      eventDate: '2021-07-09',
      rejectAmount: 500,
      reasonCode: 'code04',
      reasonDescription: 'Failure to pay on time'
    },
    {
      eventDate: '2021-07-11',
      rejectAmount: 400,
      reasonCode: 'code02',
      reasonDescription: 'Insufficient funds'
    },
    {
      eventDate: '2021-07-14',
      rejectAmount: 3000,
      reasonCode: 'code01',
      reasonDescription: 'Suspicious activity'
    }
  ]
};

export const mockAchRejectHistoryFormatted = [
  {
    date: '07/02/2021',
    amount: 200,
    code: 'code04',
    description: 'Failure to pay on time'
  },
  {
    date: '07/09/2021',
    amount: 500,
    code: 'code04',
    description: 'Failure to pay on time'
  },
  {
    date: '07/11/2021',
    amount: 400,
    code: 'code02',
    description: 'Insufficient funds'
  },
  {
    date: '07/14/2021',
    amount: 3000,
    code: 'code01',
    description: 'Suspicious activity'
  }
];

export const mockAchRejectHistoryGetRes = (body, params, options) => {
  const { guid = ['empty'] } = options;
  return { [guid]: mockAchRejectHistory };
};
