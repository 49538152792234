const riskCategoryTemplate = {
  get: (data, version) => {
    if (version === '1.0') {
      if (!(data?.riskCategories instanceof Array)) return [];
      return data.riskCategories.map(category => ({
        title: category?.riskCategory?.trim(),
        value: category?.riskCategoryId?.trim()
      })) || [];
    }
    return data || [];
  }
};

export default riskCategoryTemplate;
