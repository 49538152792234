import { crabFileTagsPost } from '../../_crabFields';
import { transformData, isEmpty, domainName } from '../../_helpers';
import { getMockValue } from '../_mockDataHelpers';
import filesWithTagsTemplate from './templates/filesWithTagsTemplate';

export const defaultMockUrl = `https://www.dev.${domainName}.com`;

const mockDummyDownloadUrl = `https://api.dev.${domainName}.com/creditAndBoarding/v1/dummyDownloadUrl`;

const mockStaticFiles = {
  onlyElectronicSignedMpa: [{
    fileName: 'signed MPA.pdf',
    tags: [{ tagObject: 'electronic_mpa' }, { tagObject: 'uploaded_by_system' }, { tagObject: 'signed_mpa' }],
    url: defaultMockUrl,
    guidType: 'applicationMpaId',
    guidValue: 'mock-mpa-id-0', // DBA Name 1
    fileCreationTimestamp: '2021-08-05T14:37:16.950'
  }],
  preferredRisk: {
    readyToSubmitWetApp: [ // Ready to submit (With Files) - Wet Signed - Preferred Risk
      { fileName: 'signedMpa_1.pdf', tags: [{ tagObject: 'signed_mpa' }, { tagObject: 'uploaded_by_partner' }], url: defaultMockUrl },
      { fileName: 'voidedCheck_1.pdf', tags: [{ tagObject: 'voided_check_or_bank_letter' }, { tagObject: 'uploaded_by_partner' }], url: defaultMockUrl },
      { fileName: 'voidedCheck_2.pdf', tags: [{ tagObject: 'voided_check_or_bank_letter' }, { tagObject: 'uploaded_by_partner' }], url: defaultMockUrl },
      { fileName: 'giactReport_1.pdf', tags: [{ tagObject: 'giact_report' }, { tagObject: 'uploaded_by_employee' }], url: defaultMockUrl },
      { fileName: 'photoId_1.png', url: 'https://via.placeholder.com/300x400/09f/photoId_1.png', tags: [{ tagObject: 'photo_id' }, { tagObject: 'uploaded_by_partner' }] },
      { fileName: 'other_2.pdf', tags: [{ tagObject: 'other' }, { tagObject: 'terminal_order_form' }, { tagObject: 'Forms' }], url: defaultMockUrl },
      { fileName: 'bankStatements_1.pdf', tags: [{ tagObject: 'bank_statements' }, { tagObject: 'uploaded_by_partner' }], url: defaultMockUrl },
      { fileName: 'other_1.png', url: 'https://via.placeholder.com/200x300/09f/other_1.png', tags: [{ tagObject: 'other' }, { tagObject: 'uploaded_by_employee' }, { tagObject: 'employee_group_credit' }] },
      { fileName: 'merchantInfoForm_1.png', url: 'https://via.placeholder.com/250x350/09f/merchantInfoForm_1.png', tags: [{ tagObject: 'Foobar' }, { tagObject: 'merchant_info_form' }, { tagObject: 'Urgent' }, { tagObject: 'Very Urgent' }, { tagObject: 'uploaded_by_partner' }] }
    ],
    readyToSubmitElectronicApp: [ // Can request signature - Electronic - Preferred
      { fileName: 'voidedCheck_1.pdf', tags: [{ tagObject: 'voided_check_or_bank_letter' }, { tagObject: 'uploaded_by_partner' }], url: defaultMockUrl },
      { fileName: 'giactReport_1.pdf', tags: [{ tagObject: 'giact_report' }, { tagObject: 'uploaded_by_employee' }], url: defaultMockUrl },
      { fileName: 'photoId_1.png', url: 'https://via.placeholder.com/300x400/09f/photoId_1.png', tags: [{ tagObject: 'photo_id' }, { tagObject: 'uploaded_by_partner' }] },
      { fileName: 'other_2.pdf', tags: [{ tagObject: 'other' }, { tagObject: 'terminal_order_form' }, { tagObject: 'Forms' }], url: defaultMockUrl },
      { fileName: 'bankStatements_1.pdf', tags: [{ tagObject: 'bank_statements' }, { tagObject: 'uploaded_by_partner' }], url: defaultMockUrl },
      { fileName: 'other_1.png', url: 'https://via.placeholder.com/200x300/09f/other_1.png', tags: [{ tagObject: 'other' }, { tagObject: 'uploaded_by_employee' }, { tagObject: 'employee_group_credit' }] },
      { fileName: 'merchantInfoForm_1.png', url: 'https://via.placeholder.com/250x350/09f/merchantInfoForm_1.png', tags: [{ tagObject: 'Foobar' }, { tagObject: 'merchant_info_form' }, { tagObject: 'Urgent' }, { tagObject: 'Very Urgent' }, { tagObject: 'uploaded_by_partner' }] }
    ],
    alreadySignedElectronicApp: [ // Already has signed MPAs - Electronic Signed - Preferred
      { fileName: 'signedMpa_1.pdf', tags: [{ tagObject: 'electronic_mpa' }, { tagObject: 'uploaded_by_system' }], url: defaultMockUrl },
      { fileName: 'signedMpa_2.pdf', tags: [{ tagObject: 'electronic_mpa' }, { tagObject: 'uploaded_by_system' }], url: defaultMockUrl },
      { fileName: 'signedMpa_3.pdf', tags: [{ tagObject: 'electronic_mpa' }, { tagObject: 'uploaded_by_system' }], url: defaultMockUrl },
      { fileName: 'voidedCheck_1.pdf', tags: [{ tagObject: 'voided_check_or_bank_letter' }, { tagObject: 'uploaded_by_partner' }], url: defaultMockUrl },
      { fileName: 'giactReport_1.pdf', tags: [{ tagObject: 'giact_report' }, { tagObject: 'uploaded_by_employee' }], url: defaultMockUrl },
      { fileName: 'photoId_1.png', url: 'https://via.placeholder.com/300x400/09f/photoId_1.png', tags: [{ tagObject: 'photo_id' }, { tagObject: 'uploaded_by_partner' }] },
      { fileName: 'other_2.pdf', tags: [{ tagObject: 'other' }, { tagObject: 'terminal_order_form' }, { tagObject: 'Forms' }], url: defaultMockUrl },
      { fileName: 'bankStatements_1.pdf', tags: [{ tagObject: 'bank_statements' }, { tagObject: 'uploaded_by_partner' }], url: defaultMockUrl },
      { fileName: 'other_1.png', url: 'https://via.placeholder.com/200x300/09f/other_1.png', tags: [{ tagObject: 'other' }, { tagObject: 'uploaded_by_employee' }, { tagObject: 'employee_group_credit' }] },
      { fileName: 'merchantInfoForm_1.png', url: 'https://via.placeholder.com/250x350/09f/merchantInfoForm_1.png', tags: [{ tagObject: 'Foobar' }, { tagObject: 'merchant_info_form' }, { tagObject: 'Urgent' }, { tagObject: 'Very Urgent' }, { tagObject: 'uploaded_by_partner' }] }
    ]
  }
};

const staticAppFilesMapPartner = { // (Partner) Static files for specific Applications (Partner)
  7: [ // Ready to submit (With Files) - Wet Signed - Preferred Risk
    ...mockStaticFiles.preferredRisk.readyToSubmitWetApp
  ],
  8: [ // Ready to submit (With Files) - Wet Signed - Preferred Risk - FOR FT
    ...mockStaticFiles.preferredRisk.readyToSubmitWetApp
  ],
  9: [ // App 09 - Waiting On Partner (No Pends) - Wet - Preferred Risk (FT)
    ...mockStaticFiles.preferredRisk.readyToSubmitWetApp
  ],
  13: [ // App 13 - Draft - Electronic - Signed (Missing required files)
    ...mockStaticFiles.onlyElectronicSignedMpa
  ],
  22: [], // App 22 - can request signature only, has NO files
  23: [ // App 23 - can request signature and submit, has required files
    ...mockStaticFiles.preferredRisk.readyToSubmitElectronicApp
  ],
  24: [ // App 24 - ready to go, all required files, but already has signed MPAs
    ...mockStaticFiles.preferredRisk.alreadySignedElectronicApp
  ]
};

const staticAppFilesMapEmployee = { // (Employee) Static files for specific Applications
  4: [ // For MPA Task Validation FT
    {
      fileName: 'DBA Name 1 (MPA file).pdf',
      tags: [{ tagObject: 'uploaded_by_partner' }, { tagObject: 'signed_mpa' }],
      url: defaultMockUrl,
      guidType: 'applicationMpaId', // file attached to MPA
      guidValue: '0', // DBA Name 1
      fileCreationTimestamp: '2021-08-01T14:37:16.950'
    },
    {
      fileName: 'DBA Name 1 (Pend file).pdf',
      tags: [{ tagObject: 'uploaded_by_partner' }, { tagObject: 'signed_mpa' }],
      url: defaultMockUrl,
      guidType: 'applicationPendId', // file attached to PEND
      guidValue: '0', // DBA Name 1
      fileCreationTimestamp: '2021-08-09T14:37:16.950'
    },
    {
      fileName: 'DBA Name 2 (MPA file).pdf',
      tags: [{ tagObject: 'uploaded_by_partner' }, { tagObject: 'signed_mpa' }],
      url: defaultMockUrl,
      guidType: 'applicationMpaId', // file attached to MPA
      guidValue: '1', // DBA Name 2
      fileCreationTimestamp: '2021-08-02T14:37:16.950'
    },
    {
      fileName: 'DBA Name 3 (MPA file).pdf',
      tags: [{ tagObject: 'uploaded_by_partner' }, { tagObject: 'signed_mpa' }],
      url: defaultMockUrl,
      guidType: 'applicationMpaId', // file attached to MPA
      guidValue: '2', // DBA Name 3
      fileCreationTimestamp: '2021-08-03T14:37:16.950'
    }
  ],
  34: [ // App 34 - App for testing Application Files Only (for FT)
    {
      fileName: 'bank statements 2020.pdf',
      tags: [{ tagObject: 'uploaded_by_partner' }, { tagObject: 'bank_statements' }],
      url: defaultMockUrl,
      guidType: 'applicationTaskId',
      guidValue: 'mock-application-task-id-2', // KYC (Customer) task
      fileCreationTimestamp: '2021-08-02T14:35:16.950'
    },
    {
      fileName: 'bank statements 2021.pdf',
      tags: [{ tagObject: 'uploaded_by_partner' }, { tagObject: 'bank_statements' }],
      url: defaultMockUrl,
      guidType: 'applicationTaskId',
      guidValue: 'mock-application-task-id-2', // KYC (Customer) task
      fileCreationTimestamp: '2021-08-02T14:36:16.950'
    },
    {
      fileName: 'bank statements 2021.pdf',
      tags: [{ tagObject: 'uploaded_by_partner' }, { tagObject: 'bank_statements' }],
      url: defaultMockUrl,
      guidType: 'applicationTaskId',
      guidValue: 'mock-application-task-id-1', // KYB (Business) task
      fileCreationTimestamp: '2021-08-03T14:35:16.950'
    },
    {
      fileName: 'signed MPA.pdf',
      tags: [{ tagObject: 'uploaded_by_partner' }, { tagObject: 'signed_mpa' }],
      url: defaultMockUrl,
      guidType: 'applicationMpaId',
      guidValue: 'mock-mpa-id-0', // DBA Name 1
      fileCreationTimestamp: '2021-08-04T14:37:16.950'
    },
    {
      fileName: 'signed MPA.pdf',
      tags: [{ tagObject: 'uploaded_by_partner' }, { tagObject: 'signed_mpa' }],
      url: defaultMockUrl,
      guidType: 'applicationMpaId',
      guidValue: 'mock-mpa-id-1', // DBA Name 2
      fileCreationTimestamp: '2021-08-04T14:35:16.950'
    }
  ],
  19: [ // For Download App Package FT
    {
      fileName: 'signed MPA.pdf',
      tags: [{ tagObject: 'uploaded_by_partner' }, { tagObject: 'signed_mpa' }],
      url: mockDummyDownloadUrl,
      guidType: 'applicationMpaId',
      guidValue: 'mock-mpa-id-1',
      fileCreationTimestamp: '2021-08-04T14:35:16.950'
    }
  ]
};

export const staticMpaFilesMap = { // Static files for specific Associated MPAs
  1: [ // DBA Name 2
    ...mockStaticFiles.preferredRisk.readyToSubmitWetApp
  ]
};

export const staticPendFilesMap = { // Static files for specific Pends
  'mock-application-pend-id-1': [ // 01 - Mock Pend Description - Open Pend
    { fileName: 'bankStatements_1.pdf', tags: [{ tagObject: 'bank_statements' }, { tagObject: 'uploaded_by_partner' }], url: defaultMockUrl },
    { fileName: 'other_1.jpeg', url: 'https://via.placeholder.com/315x315/09f/other_1.jpeg', tags: [{ tagObject: 'Important' }, { tagObject: 'uploaded_by_employee' }] }
  ]
};

export const createMockFile = (fileNum, options) => {
  const { staticFile, guid, guidKey } = options || {};
  const mockFileCreationTimestamp = {
    fileCreationTimestamp: !isEmpty(staticFile) && staticFile.fileCreationTimestamp
      ? staticFile.fileCreationTimestamp
      : getMockValue({ type: 'isoDate', min: 2022 - (fileNum - 1), max: 2022 - (fileNum - 1) })
  };
  const mockFile = !isEmpty(staticFile)
    ? {
      fileName: staticFile.fileName,
      tags: staticFile.tags,
      url: staticFile.url || defaultMockUrl,
      guidType: getMockGuidType(staticFile.guidType || guidKey),
      guidValue: getMockGuidValue(staticFile.guidValue || guid),
      ...mockFileCreationTimestamp
    }
    : createRandomMockFile(fileNum, { ...options, mockFileCreationTimestamp });
  return mockFile;
};

const createRandomMockFile = (fileNum, options) => {
  const {
    guid,
    guidKey,
    mockFileCreationTimestamp,
    userType
  } = options || {};
  const additionalTagsMap = userType === 'partner' // For FTs: { [fileNum]: array of tag_enum }
    ? {
      1: ['merchant_statement'],
      2: ['merchant_1099'],
      3: ['partner_1099']
    }
    : {};
  const isImage = fileNum > 5;
  const mockGuidType = getMockGuidType(guidKey);
  const mockGuidValue = getMockGuidValue(guid);
  const mockDefaultTag = fileNum === 1 ? 'other' : getMockValue({ type: 'list', list: crabFileTagsPost.tagList.list.filter(t => !t.isFixed) });
  const mockUploadedByTag = getMockValue({ type: 'list', list: crabFileTagsPost.tagList.list.filter(t => (t.isFixed && t.value.includes('uploaded_by'))) });
  const allTags = [...new Set([
    mockDefaultTag,
    mockUploadedByTag,
    ...additionalTagsMap[fileNum] ?? []
  ])];
  const mockFile = {
    fileName: `mockFile${fileNum} - ${mockGuidType} - ${mockGuidValue}.${isImage ? 'png' : 'pdf'}`,
    tags: allTags.map(tagEnum => ({ tagObject: tagEnum })),
    url: isImage ? `https://via.placeholder.com/400x500/09f/mockFile${fileNum}.png` : defaultMockUrl,
    guidType: mockGuidType,
    guidValue: mockGuidValue,
    ...mockFileCreationTimestamp
  };
  return mockFile;
};

export const getMockGuidType = (guidKey) => {
  if (typeof guidKey === 'string' && guidKey.includes('List')) {
    return `${guidKey}`.replace('List', '');
  }
  const mockSingleGuidKeys = [
    'applicationId',
    'applicationMpaId',
    'applicationPendId',
    'applicationTaskId',
    'merchantGuid',
    'partnerId',
    'relationshipId',
    'ticketId',
    'residualTaskId',
    'prevetId'
  ];
  return !isEmpty(guidKey) ? guidKey : getMockValue({ type: 'list', list: mockSingleGuidKeys });
};

export const getMockGuidValue = (guid) => {
  if (typeof guid === 'string' && guid.startsWith('[')) { // list of guids
    const parsedGuidList = JSON.parse(guid);
    return getMockValue({ type: 'list', list: parsedGuidList });
  }
  return !isEmpty(guid) ? guid : getMockValue({ type: 'guid' });
};

export const createMockFileList = (options) => {
  const {
    length
  } = options || {};
  const allStaticFiles = getStaticFiles(options);
  const array = !isEmpty(allStaticFiles) ||
    (isEmpty(allStaticFiles) && Array.isArray(allStaticFiles))
    ? allStaticFiles
    : Array.from({ length: length || 9 });
  const mockFiles = array.map((item, index) => {
    const mockFile = createMockFile(index + 1, { ...options, staticFile: item });
    return mockFile;
  });
  return mockFiles;
};

const getStaticFiles = (options) => {
  const {
    guid,
    guidKey,
    staticFiles,
    userType
  } = options || {};
  if (!isEmpty(staticFiles) || (isEmpty(staticFiles) && Array.isArray(staticFiles))) {
    return staticFiles;
  }
  const crabGuidKeys = ['applicationMpaId', 'applicationId', 'applicationPendId'];
  const crabStaticFilesMap = crabGuidKeys.includes(guidKey)
    ? {
      applicationMpaId: staticMpaFilesMap?.[guid],
      applicationId: userType === 'employee' ? staticAppFilesMapEmployee?.[guid] : staticAppFilesMapPartner?.[guid],
      applicationPendId: staticPendFilesMap?.[guid]
    }
    : {};
  return crabGuidKeys.includes(guidKey) ? crabStaticFilesMap[guidKey] : null;
};

export const mockBoardingApplicationFilesGet = (body, params, options) => {
  const { guid = '' } = options || {};
  const mockFiles = createMockFileList(options);
  return {
    [guid]: {
      taggedFilesObjectWithUrl: mockFiles
    }
  };
};

export const mockBoardingApplicationFilesFormatted = transformData({
  data: {
    taggedFilesObjectWithUrl: mockStaticFiles.preferredRisk.readyToSubmitWetApp,
    userType: 'partner'
  },
  toSchema: 'frontend',
  template: filesWithTagsTemplate
});

export const mockBoardingApplicationDownloadUrlGet = (body, params, options) => {
  const mockFile = new File(['mockFile'], 'mockFile.txt', { type: 'text/plain' });
  const mockBuffer = mockFile.arrayBuffer ? mockFile.arrayBuffer() : '';
  const mockBlob = new Blob([new Uint8Array(mockBuffer)], { type: 'text/plain' });
  return mockBlob;
};
