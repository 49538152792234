import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { ToolTip } from './ToolTip';
import { isEmpty } from './_helpers';
import { checkbox } from './_styles';
import { Icon } from '../css/_styledComponents';
import '../css/animations.css';

export class Checkbox extends React.Component {
  constructor (props) {
    super(props);
    this.mounted = false;
    const { checked, id, startState } = props;
    this.checkboxUniqueId = uuidv4(); // used for internal checkbox state only
    this.internalId = `${id}_${this.checkboxUniqueId}`;
    this.state = {
      checked: checked ? this.internalId : '',
      checkboxState: startState || 'default'
    };
  }

  componentDidMount () {
    this.mounted = true;
  }

  componentDidUpdate (prevProps) {
    const { checked } = this.props;
    if (prevProps.checked !== checked) {
      this.updateState({ checked: checked ? this.internalId : '' });
    }
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  updateState = (state, callback = null) => {
    this.mounted && this.setState(state, callback);
  }

  handleCheck = () => {
    const { disabled } = this.props;
    !disabled && this.updateState(prevState => ({
      checked: prevState.checked === this.internalId ? '' : this.internalId
    }), this.handleCallback);
  }

  handleCallback = () => {
    const { id, callback } = this.props;
    const { checked } = this.state;
    if (callback) {
      callback(id, !isEmpty(checked), true);
    }
  }

  handleMouseIn (e) {
    this.setState({ checkboxState: 'hover' });
  }

  handleMouseOut (e) {
    this.setState({ checkboxState: 'default' });
  }

  render () {
    const {
      validationActivated,
      required,
      wrapperStyle,
      labelStyle,
      type,
      name,
      id,
      value,
      ariaLabel,
      label,
      height,
      width,
      disabled,
      tooltip,
      tooltipPos,
      tooltipWidth,
      boxStyle,
      className,
      checkmarkIcons
    } = this.props;
    const { checked, checkboxState } = this.state;
    const labelSize = type === 'mini' ? { fontSize: '1.2rem', paddingLeft: '18px' } : {};
    const checkboxSpanStyle = type === 'mini' ? { width: '12px', height: '12px', top: '2px' } : {};
    const checkStyle = type === 'mini' ? { top: '2px', height: '12px' } : {};
    const checkedStyle = type === 'mini'
      ? {
        ...checkbox.label_before_checked_mini,
        ...(boxStyle !== 'inside' && disabled && checkbox.label_before_checked_mini_disabled)
      }
      : {
        ...checkbox.label_before_checked,
        ...(boxStyle !== 'inside' && disabled && checkbox.label_before_checked_disabled)
      };
    return (
      <div
        id={`${id}_checkbox_wrapper`}
        className="checkbox"
        role="button"
        tabIndex="0"
        key={this.internalId}
        onClick={this.handleCheck}
        onKeyDown={this.handleCheck}
        onFocus={this.handleMouseIn.bind(this)}
        onBlur={this.handleMouseOut.bind(this)}
        onMouseEnter={this.handleMouseIn.bind(this)}
        onMouseLeave={this.handleMouseOut.bind(this)}
        style={{
          cursor: 'pointer',
          height: height || '28px',
          lineHeight: '1',
          ...(validationActivated && required && !checked && { color: 'var(--color-warning)', borderColor: 'var(--color-warning)', backgroundColor: 'var(--color-warning-bg)' }),
          ...wrapperStyle
        }}
      >
        <label
          className={!isEmpty(className) ? `${className}Label` : null}
          style={{
            ...checkbox.label,
            ...labelSize,
            ...labelStyle,
            ...(disabled ? checkbox.label_disabled : checkbox[`label_${checkboxState}`]),
            ...(height && { minHeight: height }),
            ...(width && { minWidth: width })
          }}
          htmlFor={id}
        >
          <input
            key={this.internalId}
            disabled={disabled}
            style={checkbox.input}
            className={!isEmpty(className) ? `${className} ${id}_checkbox_input` : `${id}_checkbox_input`}
            type="checkbox"
            name={`${name}_${this.checkboxUniqueId}`}
            id={this.internalId}
            value={value}
            checked={checked === this.internalId}
            data-checked={checked === this.internalId}
            onChange={this.handleCheck}
            {...isEmpty(label) && ariaLabel && { 'aria-label': ariaLabel }}
          />
          {checkmarkIcons ? (
            <Icon
              icon={checked ? checkmarkIcons.checked : checkmarkIcons.unchecked}
              $useMask
              className="checkmarkCustomIcon"
              $hoverColor="var(--color-link-hover)"
              onClick={() => {}} // for hoverColor to appear
              style={{
                height: '20px', width: '20px', margin: '0', position: 'absolute', left: '0'
              }}
            />
          ) : (
            <>
              <span
                style={{
                  ...(checked ? checkbox.checked : checkbox.unchecked),
                  ...(disabled
                    ? {
                      ...checkbox.disabled,
                      ...(boxStyle !== 'inside' && {
                        borderColor: 'var(--color-dark-grey)',
                        backgroundColor: 'var(--color-disabled)'
                      })
                    }
                    : checkbox[checkboxState]
                  ),
                  ...checkboxSpanStyle
                }}
                className="checkbox_span"
              />
              <span
                style={{ ...(checked ? checkedStyle : checkbox.label_before), ...checkStyle }}
              />
            </>
          )}
          {label}
          {tooltip && (
            <ToolTip
              infoTip
              {...tooltipPos && { tooltipPos }}
              {...tooltipWidth && { width: tooltipWidth }}
              {...disabled && { iconColor: 'var(--color-light-label)' }}
              infoTipDisplay={{
                margin: '0',
                top: '0',
                right: '-20px'
              }}
            >
              {tooltip}
            </ToolTip>
          )}
        </label>
      </div>
    );
  }
}

Checkbox.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  ariaLabel: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  checked: PropTypes.bool,
  startState: PropTypes.string,
  wrapperStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  labelStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  callback: PropTypes.func,
  height: PropTypes.string,
  width: PropTypes.string,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
  tooltipPos: PropTypes.string,
  tooltipWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  validationActivated: PropTypes.bool,
  required: PropTypes.bool,
  boxStyle: PropTypes.string,
  className: PropTypes.string,
  checkmarkIcons: PropTypes.shape({
    unchecked: PropTypes.string,
    checked: PropTypes.string
  })
};

Checkbox.defaultProps = {
  name: '',
  id: '',
  ariaLabel: null, // pass this when there is no `label` prop
  label: '',
  value: '',
  type: '',
  checked: false,
  startState: '',
  wrapperStyle: {},
  labelStyle: {},
  callback: () => {},
  height: '',
  width: null,
  disabled: false,
  tooltip: '',
  tooltipPos: null,
  tooltipWidth: null,
  validationActivated: false,
  required: false,
  boxStyle: null,
  className: '',
  checkmarkIcons: null
};

export default Checkbox;
