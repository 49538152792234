import { generateRandomSentence, generateRandomUser, getMockValue } from '@f1/shared/src/data/_mockDataHelpers';

const randomArrayLength = Array.from({ length: Math.floor(Math.random() * 10) + 6 });

export const mockBinStatusGet = {
  binStatuses: randomArrayLength.flatMap(arr => ({
    binId: getMockValue({ type: 'number', min: 100000, max: 99999999 }),
    prohibited: getMockValue({ type: 'boolean' }),
    description: generateRandomSentence(),
    lastUpdatedTimestamp: getMockValue({ type: 'isoDate' }),
    lastUpdatedEmployee: {
      employeeId: getMockValue({ type: 'number', min: 100000, max: 999999 }),
      firstName: generateRandomUser().firstName,
      lastName: generateRandomUser().lastName,
      email: generateRandomUser().email
    }
  }))
};

export default mockBinStatusGet;
