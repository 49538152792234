const mockCustomSettings = {

};

export const mockCustomSettingsGet = (body, params, options) => {
  const mockData = { userSettingsPayload: JSON.stringify(mockCustomSettings) };
  return mockData;
};

export default mockCustomSettingsGet;
