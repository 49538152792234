import { mockPrevetsGet, createMockPrevets } from '@f1/shared/src/data/crab/mockPrevets';
import { mockRelationshipGetFormatted } from '../relationship/root';
import {
  mockAppReviewEmployeesRandomApps,
  defaultAssignedAppReviewEmployee,
  assignedAppReviewManager,
  mockGuidToRelationshipMap,
  elevatedNeteviaRelationship,
  mockElevatedRepayRelationship
} from './mockEmployeeCrabV1Application';

export const staticApps = {
  0: { // App 00 - Repay/Elevated Risk - Decline
    meta: {
      prevetStatus: 'decline',
      prevetName: 'App 00 - Repay/Elevated Risk - Decline',
      relationship: mockElevatedRepayRelationship,
      prevetSubmittedTimestamp: '2019-12-30T20:20:00Z',
      prevetCompletedTimestamp: '2019-12-31T20:20:00Z',
      employeeOnly: {
        assignedAppReviewEmployee: defaultAssignedAppReviewEmployee
      }
    },
    filesList: []
  },
  1: { // App 01 - Repay/Elevated Risk
    meta: {
      prevetStatus: 'waiting_on_app_review',
      prevetName: 'App 01 - Repay/Elevated Risk',
      relationship: mockElevatedRepayRelationship,
      prevetSubmittedTimestamp: '2019-12-30T20:21:00Z',
      prevetCompletedTimestamp: '2019-12-31T20:21:00Z',
      employeeOnly: {
        riskProfile: 'elevated',
        assignedAppReviewEmployee: defaultAssignedAppReviewEmployee
      }
    },
    json: {
      additionalInfo: {
        averageTicket: 10,
        averagePercentageOfChargebacks: 0.1,
        averageMonthlySalesVolume: 1000,
        averagePercentageOfRefunds: 0.2
      },
      businessInfo: {
        averageTimeToDelivery: 1
      }
    }
  },
  2: { // App 02 - Netevia/Elevated Risk (no risk profile)
    meta: {
      prevetStatus: 'waiting_on_app_review',
      prevetName: 'App 02 - Netevia/Elevated Risk (no risk profile)',
      relationship: elevatedNeteviaRelationship,
      prevetSubmittedTimestamp: '2019-12-30T20:20:00Z',
      prevetCompletedTimestamp: '2019-12-31T20:20:00Z',
      employeeOnly: {
        assignedAppReviewEmployee: defaultAssignedAppReviewEmployee,
        riskProfile: null
      }
    }
  },
  3: { // App 03 - Repay/Elevated Risk - no employee assigned
    meta: {
      prevetStatus: 'waiting_on_app_review',
      prevetName: 'App 03 - Repay/Elevated Risk - Unassigned',
      relationship: mockElevatedRepayRelationship,
      prevetSubmittedTimestamp: '2019-12-30T20:19:00Z',
      prevetCompletedTimestamp: '2019-12-31T20:19:00Z',
      employeeOnly: {
        assignedAppReviewEmployee: {},
        riskProfile: 'elevated'
      }
    }
  },
  4: { // App 04 - Repay/Elevated Risk - Abandoned
    meta: {
      prevetStatus: 'abandoned',
      prevetName: 'App 04 - Repay/Elevated Risk - Abandoned',
      relationship: mockElevatedRepayRelationship,
      prevetSubmittedTimestamp: '2019-12-30T18:19:00Z',
      prevetCompletedTimestamp: '2019-12-31T18:19:00Z',
      employeeOnly: {
        assignedAppReviewEmployee: assignedAppReviewManager,
        riskProfile: 'elevated'
      }
    }
  },
  5: { // App 05 - Repay/Elevated Risk - manager instead of default employee
    meta: {
      prevetStatus: 'waiting_on_app_review',
      prevetName: 'App 05 - Repay/Elevated Risk - Manager',
      relationship: mockElevatedRepayRelationship,
      prevetSubmittedTimestamp: '2019-12-30T17:19:00Z',
      prevetCompletedTimestamp: '2019-12-31T17:19:00Z',
      employeeOnly: {
        assignedAppReviewEmployee: assignedAppReviewManager, // App Review Manager
        riskProfile: 'elevated'
      }
    }
  },
  6: { // App 06 - Netevia/Elevated Risk - Proceed
    meta: {
      prevetStatus: 'proceed',
      prevetName: 'App 06 - Netevia/Elevated Risk - Proceed',
      relationship: elevatedNeteviaRelationship,
      prevetSubmittedTimestamp: '2019-12-30T20:21:00Z',
      prevetCompletedTimestamp: '2019-12-31T20:22:00Z',
      employeeOnly: {
        assignedAppReviewEmployee: defaultAssignedAppReviewEmployee,
        riskProfile: null
      }
    }
  }
};

// Create list of ALL mock applications ONCE so paged data doesn't get recreated multiple times
export const mockAllPrevets = createMockPrevets({
  staticApps,
  userType: 'employee',
  appReviewEmployeeList: mockAppReviewEmployeesRandomApps,
  relationshipList: mockRelationshipGetFormatted,
  mockGuidToRelationshipMap
});

export const mockEmployeePrevetsGet = (body, params, options) => {
  const { guid } = options || {};
  const mockData = mockPrevetsGet(body, params, {
    ...options,
    userType: 'employee',
    mockAllPrevets,
    staticApps
  });
  return { [guid]: mockData };
};

export default mockEmployeePrevetsGet;
