import { isEmpty } from '../../../_helpers';

const ticketsExternalCommunicationTemplate = {
  backend: (schema, version) => {
    if (version === '1.0') {
      const { externalCommunicationDescription } = schema;
      if (isEmpty(externalCommunicationDescription)) return '';
      return {
        externalCommunicationDescription
      };
    }
    return schema;
  }
};

export default ticketsExternalCommunicationTemplate;
