import { partnerGuids, relationshipGuids } from '../guids';
import { mockRelationshipV2DetailGetRes } from './v2/mockRelationshipsV2Get';
import { mockPartners } from '../partner/mockPartners';

export const mockRelationships = {
  relationship01: {
    response: {
      relationshipId: relationshipGuids.default,
      relationshipName: 'Relationship with Transaction Report Data',
      relationshipCode: '20000',
      bankName: mockRelationshipV2DetailGetRes[relationshipGuids.default]
        .relationship.bankName,
      processorName: mockRelationshipV2DetailGetRes[relationshipGuids.default]
        .relationship.processorName,
      riskProfile: 'preferred',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    },
    deserialized: {
      value: relationshipGuids.default,
      title: 'Relationship with Transaction Report Data',
      relationshipCode: '20000',
      bankName: mockRelationshipV2DetailGetRes[relationshipGuids.default]
        .relationship.bankName,
      processorName: mockRelationshipV2DetailGetRes[relationshipGuids.default]
        .relationship.processorName,
      riskProfile: 'preferred',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    }
  },
  relationship02: {
    response: {
      relationshipId: relationshipGuids.relationship02,
      relationshipName: '0CsKQFgXSYEb4XH',
      relationshipCode: '20001',
      bankName: mockRelationshipV2DetailGetRes[relationshipGuids.relationship02]
        .relationship.bankName,
      processorName: mockRelationshipV2DetailGetRes[relationshipGuids.relationship02]
        .relationship.processorName,
      riskProfile: 'standard',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    },
    deserialized: {
      value: relationshipGuids.relationship02,
      title: '0CsKQFgXSYEb4XH',
      relationshipCode: '20001',
      bankName: mockRelationshipV2DetailGetRes[relationshipGuids.relationship02]
        .relationship.bankName,
      processorName: mockRelationshipV2DetailGetRes[relationshipGuids.relationship02]
        .relationship.processorName,
      riskProfile: 'standard',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    }
  },
  relationship03: {
    response: {
      relationshipId: relationshipGuids.relationship03,
      relationshipName: 'VLtC1vgFn7OpZFR',
      relationshipCode: '20002',
      bankName: mockRelationshipV2DetailGetRes[relationshipGuids.relationship03]
        .relationship.bankName,
      processorName: mockRelationshipV2DetailGetRes[relationshipGuids.relationship03]
        .relationship.processorName,
      riskProfile: 'elevated',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    },
    deserialized: {
      value: relationshipGuids.relationship03,
      title: 'VLtC1vgFn7OpZFR',
      relationshipCode: '20002',
      bankName: mockRelationshipV2DetailGetRes[relationshipGuids.relationship03]
        .relationship.bankName,
      processorName: mockRelationshipV2DetailGetRes[relationshipGuids.relationship03]
        .relationship.processorName,
      riskProfile: 'elevated',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    }
  },
  relationship04: {
    response: {
      relationshipId: relationshipGuids.relationship04,
      relationshipName: 'CrmCreateRelationshipFT',
      relationshipCode: '20003',
      bankName: 'CROSS_RIVER',
      processorName: 'PAYROC',
      riskProfile: 'elevated',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    },
    deserialized: {
      value: relationshipGuids.relationship04,
      title: 'CrmCreateRelationshipFT',
      relationshipCode: '20003',
      bankName: 'CROSS_RIVER',
      processorName: 'PAYROC',
      riskProfile: 'elevated',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    }
  },
  relationship05: {
    response: {
      relationshipId: relationshipGuids.relationship05,
      relationshipName: '02X60txdbGGHNSJ',
      relationshipCode: '20004',
      bankName: 'CROSS_RIVER',
      processorName: 'PAYROC',
      riskProfile: 'standard',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    },
    deserialized: {
      value: relationshipGuids.relationship05,
      title: '02X60txdbGGHNSJ',
      relationshipCode: '20004',
      bankName: 'CROSS_RIVER',
      processorName: 'PAYROC',
      riskProfile: 'standard',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    }
  },
  relationship06: {
    response: {
      relationshipId: relationshipGuids.relationship06,
      relationshipName: 'Mock Relationship with Extraordinarily Long Name',
      relationshipCode: '20005',
      bankName: 'CROSS_RIVER',
      processorName: 'PAYROC',
      riskProfile: 'elevated',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    },
    deserialized: {
      value: relationshipGuids.relationship06,
      title: 'Mock Relationship with Extraordinarily Long Name',
      relationshipCode: '20005',
      bankName: 'CROSS_RIVER',
      processorName: 'PAYROC',
      riskProfile: 'elevated',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    }
  },
  relationship07: {
    response: {
      relationshipId: relationshipGuids.relationship07,
      relationshipName: 'ufiHqFshpF',
      relationshipCode: '20006',
      bankName: 'CROSS_RIVER',
      processorName: 'PAYROC',
      riskProfile: 'elevated',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    },
    deserialized: {
      value: relationshipGuids.relationship07,
      title: 'ufiHqFshpF',
      relationshipCode: '20006',
      bankName: 'CROSS_RIVER',
      processorName: 'PAYROC',
      riskProfile: 'elevated',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    }
  },
  relationship08: {
    response: {
      relationshipId: relationshipGuids.relationship08,
      relationshipCode: '20007',
      bankName: 'CROSS_RIVER',
      processorName: 'PAYROC',
      relationshipName: 'CrmV2GetRes_relationshipName_11',
      riskProfile: 'elevated',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    },
    deserialized: {
      value: relationshipGuids.relationship08,
      title: 'CrmV2GetRes_relationshipName_11',
      relationshipCode: '20007',
      bankName: 'CROSS_RIVER',
      processorName: 'PAYROC',
      riskProfile: 'elevated',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    }
  },
  relationship09: {
    response: {
      relationshipId: relationshipGuids.relationship09,
      relationshipCode: '20008',
      bankName: 'CROSS_RIVER',
      processorName: 'PAYROC',
      relationshipName: 'CrmV2GetRes_relationshipName_13',
      riskProfile: 'elevated',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    },
    deserialized: {
      value: relationshipGuids.relationship09,
      title: 'CrmV2GetRes_relationshipName_13',
      relationshipCode: '20008',
      bankName: 'CROSS_RIVER',
      processorName: 'PAYROC',
      riskProfile: 'elevated',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    }
  },
  relationship10: {
    response: {
      relationshipId: relationshipGuids.relationship10,
      relationshipCode: '20009',
      bankName: 'CROSS_RIVER',
      processorName: 'PAYROC',
      relationshipName: 'CrmV2GetRes_relationshipName_12',
      riskProfile: 'elevated',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    },
    deserialized: {
      value: relationshipGuids.relationship10,
      title: 'CrmV2GetRes_relationshipName_12',
      relationshipCode: '20009',
      bankName: 'CROSS_RIVER',
      processorName: 'PAYROC',
      riskProfile: 'elevated',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    }
  },
  relationship11: {
    response: {
      relationshipId: relationshipGuids.relationship11,
      relationshipCode: '20010',
      bankName: 'MVB',
      processorName: 'REPAY',
      relationshipName: 'Mock Preferred Risk - Repay Relationship',
      riskProfile: 'preferred',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    },
    deserialized: {
      value: relationshipGuids.relationship11,
      title: 'Mock Preferred Risk - Repay Relationship',
      relationshipCode: '20010',
      bankName: 'MVB',
      processorName: 'REPAY',
      riskProfile: 'preferred',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    }
  },
  relationship12: {
    response: {
      relationshipId: relationshipGuids.relationship12,
      relationshipCode: '20011',
      bankName: 'EVOLVE',
      processorName: 'NETEVIA',
      relationshipName: 'Mock Netevia Evolve Relationship',
      riskProfile: 'preferred',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    },
    deserialized: {
      value: relationshipGuids.relationship12,
      title: 'Mock Netevia Evolve Relationship',
      relationshipCode: '20011',
      bankName: 'EVOLVE',
      processorName: 'NETEVIA',
      riskProfile: 'preferred',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    }
  },
  relationship13: {
    response: {
      relationshipId: relationshipGuids.relationship13,
      relationshipCode: '20012',
      bankName: 'ESQUIRE',
      processorName: 'NETEVIA',
      relationshipName: 'Mock Netevia Esquire Relationship',
      riskProfile: 'preferred',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    },
    deserialized: {
      value: relationshipGuids.relationship13,
      title: 'Mock Netevia Esquire Relationship',
      relationshipCode: '20012',
      bankName: 'ESQUIRE',
      processorName: 'NETEVIA',
      riskProfile: 'preferred',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    }
  },
  relationship14: {
    response: {
      relationshipId: relationshipGuids.relationship14,
      relationshipCode: '20013',
      bankName: 'MVB',
      processorName: 'REPAY',
      relationshipName: 'Mock Elevated Risk - Repay Relationship',
      riskProfile: 'elevated',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    },
    deserialized: {
      value: relationshipGuids.relationship14,
      title: 'Mock Elevated Risk - Repay Relationship',
      relationshipCode: '20013',
      bankName: 'MVB',
      processorName: 'REPAY',
      riskProfile: 'elevated',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    }
  },
  relationship15: {
    response: {
      relationshipId: relationshipGuids.relationship15,
      relationshipCode: '11116',
      bankName: 'ESQUIRE',
      processorName: 'NETEVIA',
      relationshipName: 'FE - Netevia Elevated',
      riskProfile: 'elevated',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    },
    deserialized: {
      value: relationshipGuids.relationship15,
      title: 'FE - Netevia Elevated',
      relationshipCode: '11116',
      bankName: 'ESQUIRE',
      processorName: 'NETEVIA',
      riskProfile: 'elevated',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    }
  },

  relationship16: {
    response: {
      relationshipId: relationshipGuids.relationship16,
      relationshipCode: '20015',
      bankName: 'MVB',
      processorName: 'REPAY',
      relationshipName: 'Mock Standard Risk - Repay MVB Relationship',
      riskProfile: 'standard',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    },
    deserialized: {
      value: relationshipGuids.relationship16,
      title: 'Mock Preferred Risk - Repay Relationship',
      relationshipCode: '20015',
      bankName: 'MVB',
      processorName: 'REPAY',
      riskProfile: 'preferred',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    }
  },
  relationship17: {
    response: {
      relationshipId: relationshipGuids.relationship17,
      relationshipCode: '20016',
      bankName: 'MVB',
      processorName: 'REPAY',
      relationshipName: 'Mock Preferred Risk - Repay MVB Relationship',
      riskProfile: 'preferred',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    },
    deserialized: {
      value: relationshipGuids.relationship17,
      title: 'Mock Preferred Risk - Repay Relationship',
      relationshipCode: '20016',
      bankName: 'MVB',
      processorName: 'REPAY',
      riskProfile: 'preferred',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    }
  },
  relationship18: {
    response: {
      relationshipId: relationshipGuids.relationship18,
      relationshipCode: '20017',
      bankName: 'MVB',
      processorName: 'REPAY',
      relationshipName: 'Mock Elevated Risk - Repay MVB Relationship',
      riskProfile: 'elevated',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    },
    deserialized: {
      value: relationshipGuids.relationship18,
      title: 'Mock Elevated Risk - Repay MVB Relationship',
      relationshipCode: '20017',
      bankName: 'MVB',
      processorName: 'REPAY',
      riskProfile: 'elevated',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    }
  },
  relationship19: {
    response: {
      relationshipId: relationshipGuids.relationship19,
      relationshipCode: '20018',
      bankName: 'MVB',
      processorName: 'REPAY',
      relationshipName: 'Mock Standard Risk - Repay MVB Relationship',
      riskProfile: 'standard',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    },
    deserialized: {
      value: relationshipGuids.relationship19,
      title: 'Mock Preferred Risk - Repay Relationship',
      relationshipCode: '20018',
      bankName: 'MVB',
      processorName: 'REPAY',
      riskProfile: 'preferred',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    }
  },
  relationship20: {
    response: {
      relationshipId: relationshipGuids.relationship20,
      relationshipCode: '20020',
      bankName: 'CYNERGY',
      processorName: 'REPAY',
      relationshipName: 'FE - Repay Elevated - Fluid Pay',
      riskProfile: 'elevated',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    },
    deserialized: {
      value: relationshipGuids.relationship20,
      title: 'FE - Repay Elevated - Fluid Pay',
      relationshipCode: '20020',
      bankName: 'CYNERGY',
      processorName: 'REPAY',
      riskProfile: 'elevated',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    }
  },
  relationship21: {
    response: {
      relationshipId: relationshipGuids.relationship21,
      relationshipCode: '20021',
      bankName: 'MERRICK',
      processorName: 'MICAMP_PROCESSING_MAVERICK',
      relationshipName: 'FE - Micamp Elevated - Maverick',
      riskProfile: 'elevated',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    },
    deserialized: {
      value: relationshipGuids.relationship21,
      title: 'FE - Micamp Elevated - Maverick',
      relationshipCode: '20021',
      bankName: 'MERRICK',
      processorName: 'MICAMP_PROCESSING_MAVERICK',
      riskProfile: 'elevated',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    }
  },
  relationship22: {
    response: {
      relationshipId: relationshipGuids.relationship22,
      relationshipCode: '232425',
      bankName: 'CYNERGY',
      processorName: 'PRIORITY',
      relationshipName: 'FE - Priority / Elevated',
      riskProfile: 'elevated',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    },
    deserialized: {
      value: relationshipGuids.relationship22,
      title: 'FE - Priority / Elevated',
      relationshipCode: '232425',
      bankName: 'CYNERGY',
      processorName: 'PRIORITY',
      riskProfile: 'elevated',
      childPartnerId: mockPartners.partner01.response.partnerId,
      inactive: false
    }
  },
  relationship23: {
    response: {
      relationshipId: relationshipGuids.relationship23,
      relationshipCode: '244201',
      bankName: 'AXIOM',
      processorName: 'PRIORITY',
      relationshipName: 'FE - Priority / Axiom / Elevated',
      riskProfile: 'elevated',
      childPartnerId: partnerGuids.partner09, // no crab config options
      inactive: false
    },
    deserialized: {
      value: relationshipGuids.relationship23,
      title: 'FE - Priority / Axiom / Elevated',
      relationshipCode: '244201',
      bankName: 'AXIOM',
      processorName: 'PRIORITY',
      riskProfile: 'elevated',
      childPartnerId: partnerGuids.partner09, // no crab config options
      inactive: false
    }
  },
  relationship24: {
    response: {
      relationshipId: relationshipGuids.relationship24,
      relationshipCode: '232426',
      bankName: 'WELLS_FARGO',
      processorName: 'PRIORITY',
      relationshipName: 'FE - Priority / Wells Fargo / Elevated',
      riskProfile: 'elevated',
      childPartnerId: partnerGuids.partner09, // no crab config options
      inactive: false
    },
    deserialized: {
      value: relationshipGuids.relationship24,
      title: 'FE - Priority / Wells Fargo / Elevated',
      relationshipCode: '232426',
      bankName: 'WELLS_FARGO',
      processorName: 'PRIORITY',
      riskProfile: 'elevated',
      childPartnerId: partnerGuids.partner09, // no crab config options
      inactive: false
    }
  },
  relationship25: {
    response: {
      relationshipId: relationshipGuids.relationship25,
      relationshipCode: '22365',
      bankName: 'MVB',
      processorName: 'REPAY',
      relationshipName: 'FE - Repay/Preferred - no crab config options',
      riskProfile: 'preferred',
      childPartnerId: partnerGuids.partner09, // no crab config options
      inactive: false
    },
    deserialized: {
      value: relationshipGuids.relationship25,
      title: 'FE - Repay/Preferred - no crab config options',
      relationshipCode: '22365',
      bankName: 'MVB',
      processorName: 'REPAY',
      riskProfile: 'preferred',
      childPartnerId: partnerGuids.partner09, // no crab config options
      inactive: false
    }
  }
};

export default mockRelationships;
