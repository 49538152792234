import React from 'react';
import PropTypes from 'prop-types';
import { pageHeaderCSS } from './_styles';

export class PageHeader extends React.PureComponent {
  render () {
    const {
      children,
      childrenWrapperStyle,
      wrapperStyle
    } = this.props;
    return (
      <div className="pageHeader" style={{ ...pageHeaderCSS.wrap, ...wrapperStyle }}>
        <div className="children" style={{ ...childrenWrapperStyle }}>
          {children}
        </div>
      </div>
    );
  }
}

PageHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]),
  wrapperStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  childrenWrapperStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object])

};
PageHeader.defaultProps = {
  children: null,
  wrapperStyle: {},
  childrenWrapperStyle: {}
};

export default PageHeader;
