import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isEqual } from './_helpers';
import { comboBoxColors, filesTagListCSS } from './_styles';

import { ToolTip } from './ToolTip';

const TagItem = (props) => {
  const {
    useTagColors,
    tooltip,
    title,
    value,
    __isNew__: isNew,
    isInternal,
    isFixed
  } = props || {};
  return (
    <div
      className="tag-item"
      id={value}
      style={{
        ...filesTagListCSS.tagItem,
        ...(tooltip && { fontSize: '1.1rem', padding: '3px', width: 'fit-content' }),
        ...(useTagColors && {
          ...(isFixed && comboBoxColors.multiValueNotClearable),
          ...(isNew && comboBoxColors.newValue),
          ...(isInternal && comboBoxColors.internalOnly)
        })
      }}
      title={title}
    >
      {title}
    </div>
  );
};

export class FilesTagList extends React.Component {
  constructor (props) {
    super(props);
    this.mounted = false;
    const { maxTagsVisible, galleryView, file } = props;
    const { isImage } = file || {};
    this.tagListRef = React.createRef();
    this.useMaxTags = (galleryView && isImage) || !isEmpty(maxTagsVisible);
    this.state = {
      allTags: [],
      visibleTags: [],
      ellipsisTags: []
    };
  }

  componentDidMount () {
    this.mounted = true;
    this.setTags();
    !this.useMaxTags && window.addEventListener('resize', this.setTags);
  }

  componentDidUpdate (prevProps) {
    const { file } = this.props;
    if (!isEqual(file?.tagList, prevProps.file?.tagList) ||
    !isEqual(file?.name, prevProps.file?.name)) {
      this.setTags();
    }
  }

  componentWillUnmount () {
    !this.useMaxTags && window.removeEventListener('resize', this.setTags);
    this.mounted = false;
  }

  updateState = (state, callback = null) => {
    this.mounted && this.setState(state, callback);
  }

  setTags = () => {
    const { file } = this.props;
    const { tagList } = file || {};
    if (!this.useMaxTags) {
      this.updateState({
        allTags: tagList || [],
        visibleTags: tagList || [],
        ellipsisTags: []
      }, this.resizeTags);
    }
  }

  resizeTags = () => {
    const target = this.mounted && this.tagListRef.current;
    if (target) {
      /**
       * This validates the tags list is visible before setting its width,
       * which is needed if the field has an existing value, loads in tabbed content,
       * and the tab is not yet visible - the parent element seems to throw off
       * the tags list width value
       */
      const observer = new IntersectionObserver(this.intersectionCallback);
      observer.observe(target);
    }
  };

  intersectionCallback = (entries) => {
    if (entries[0].isIntersecting) {
      // timeout is needed here because the field renders before the value is set on mount
      setTimeout(this.handleResizeTags, 300);
    }
  };

  handleResizeTags = () => {
    const { current } = this.tagListRef || {};
    const { allTags } = this.state;
    if (!isEmpty(current)) {
      const { parentElement: fileNameWrapperElem } = current || {};
      const { parentElement: fileRowElem } = fileNameWrapperElem || {};
      const tagElems = current.querySelectorAll('.tag-item');
      const totalWidthAllTags = !isEmpty(tagElems)
        ? Array.from(tagElems).reduce((acc, tagElem) => {
          const tagWidth = tagElem.getBoundingClientRect().width;
          return acc + tagWidth;
        }, 0)
        : 0;
      const fileLinkElem = fileRowElem.querySelector('.file-link');
      const fileLinkWidth = !isEmpty(fileLinkElem) ? fileLinkElem.getBoundingClientRect().width : 0;
      const actionBarElem = fileRowElem.querySelector('.files-action-bar');
      const actionBarWidth = !isEmpty(actionBarElem)
        ? actionBarElem.getBoundingClientRect().width
        : 0;
      const anchorAndActionWidth = fileLinkWidth || 0 + actionBarWidth || 0;
      if (!isEmpty(allTags) && anchorAndActionWidth > 0) {
        const fileRowWidth = fileRowElem.getBoundingClientRect().width;
        const actionBarOffset = actionBarWidth ? 15 : 0;
        const remainingWidthForTags = fileRowWidth - fileLinkWidth -
          (actionBarWidth + actionBarOffset);
        const allTagsFit = remainingWidthForTags >= totalWidthAllTags;
        if (allTagsFit) {
          this.updateState({ visibleTags: allTags, ellipsisTags: [] });
        } else {
          const startingAcc = {
            currentRemainingWidth: remainingWidthForTags,
            currentTagsWidth: 0,
            visibleTags: [],
            ellipsisTags: []
          };
          const mappedTags = Array.from(tagElems).map((tagElement) => {
            const tagMatch = allTags.find(t => t.title === tagElement.title) || {};
            return { ...tagMatch, elem: tagElement };
          });
          const tagObject = mappedTags.reduce((acc, tagItem) => {
            const { elem, ...remainingTagItem } = tagItem || {};
            const showTag = acc.currentRemainingWidth > 0;
            if (showTag) {
              const tagWidth = elem.getBoundingClientRect().width;
              const newRemainingWidth = acc.currentRemainingWidth - tagWidth;
              if (newRemainingWidth > 0) {
                return {
                  ...acc,
                  currentRemainingWidth: newRemainingWidth,
                  currentTagsWidth: acc.currentTagsWidth + tagWidth,
                  visibleTags: acc.visibleTags.concat(remainingTagItem)
                };
              }
            }
            return { ...acc, ellipsisTags: acc.ellipsisTags.concat(remainingTagItem) };
          }, startingAcc);
          this.updateState({
            visibleTags: tagObject.visibleTags,
            ellipsisTags: tagObject.ellipsisTags
          });
        }
      }
    }
  }

  render () {
    const {
      id,
      maxTagsVisible,
      wrapperStyle,
      file
    } = this.props;
    const { visibleTags, ellipsisTags } = this.state;
    const displayedTags = this.useMaxTags
      ? (!isEmpty(file.tagList) && file.tagList.slice(0, maxTagsVisible || 3)) || []
      : visibleTags;
    const showEllipsis = this.useMaxTags
      ? (!isEmpty(file.tagList) && file.tagList.length > (maxTagsVisible || 3)) || false
      : !isEmpty(ellipsisTags);
    const tagsForEllipsis = this.useMaxTags
      ? (!isEmpty(file.tagList) && file.tagList.slice(maxTagsVisible || 3)) || []
      : ellipsisTags;
    return (
      <div
        id={id}
        ref={this.tagListRef}
        className="files-tag-list"
        style={{
          ...(!isEmpty(file.tagList)
            ? {
              ...filesTagListCSS.tagList,
              overflow: 'hidden',
              flexWrap: 'wrap'
            }
            : { height: 0, display: 'none' }),
          ...wrapperStyle
        }}
      >
        {!isEmpty(displayedTags) && displayedTags.map(tagItem => (
          <TagItem key={tagItem.value} {...tagItem} />
        ))}
        {showEllipsis && !isEmpty(tagsForEllipsis) && (
          <ToolTip text="&#8230;" isHtml wrapperStyle={filesTagListCSS.tagItem}>
            <div
              className="ellipsis-remaining-tags"
              style={{
                padding: '0.5em',
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5em'
              }}
            >
              {tagsForEllipsis.map(tagItem => (
                <TagItem key={tagItem.value} {...tagItem} tooltip />
              ))}
            </div>
          </ToolTip>
        )}
      </div>
    );
  }
}

FilesTagList.propTypes = {
  id: PropTypes.string,
  maxTagsVisible: PropTypes.number,
  wrapperStyle: PropTypes.oneOfType([PropTypes.object]),
  hasEditAccess: PropTypes.bool,
  galleryView: PropTypes.bool,
  file: PropTypes.shape({
    isImage: PropTypes.bool,
    tagList: PropTypes.oneOfType([PropTypes.array]),
    name: PropTypes.string,
    uploadTimestamp: PropTypes.string,
    userCanDelete: PropTypes.bool
  })
};

FilesTagList.defaultProps = {
  id: '',
  maxTagsVisible: null,
  wrapperStyle: {},
  hasEditAccess: false,
  galleryView: false,
  file: {
    isImage: false,
    tagList: [],
    name: '',
    uploadTimestamp: null,
    userCanDelete: false
  }
};

export default FilesTagList;
