import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';

export const mockStickyRes = {
  stickyResponse: {
    contractStatus: 'not_signed',
    contract: {
      crmId: '0000-0000-0000-0000-0123',
      startTimestamp: getMockValue('isoDate'),
      firstName: getMockValue({ type: 'user' }).firstName,
      lastName: getMockValue({ type: 'user' }).lastName,
      email: getMockValue({ type: 'email' }),
      companyName: getMockValue({ type: 'url' }),
      billingStreet: getMockValue({ type: 'address' }).fullAddress,
      billingCity: getMockValue({ type: 'address' }).cityName,
      billingState: getMockValue({ type: 'address' }).stateCode,
      billingCountry: 'USA',
      phoneNumber: `+1${getMockValue({ type: 'phone' })}`.replace(/-/g, ''),
      contacts: [
        {
          contactType: getMockValue({ type: 'user' }).jobTitle,
          firstName: getMockValue({ type: 'user' }).firstName,
          lastName: getMockValue({ type: 'user' }).lastName,
          title: getMockValue({ type: 'user' }).title,
          phoneNumber: `+1${getMockValue({ type: 'phone' })}`.replace(/-/g, ''),
          mobilePhone: `+1${getMockValue({ type: 'phone' })}`.replace(/-/g, ''),
          email: getMockValue({ type: 'email' })
        },
        {
          contactType: getMockValue({ type: 'user' }).jobTitle,
          firstName: getMockValue({ type: 'user' }).firstName,
          lastName: getMockValue({ type: 'user' }).lastName,
          title: getMockValue({ type: 'user' }).title,
          phoneNumber: `+1${getMockValue({ type: 'phone' })}`.replace(/-/g, ''),
          mobilePhone: `+1${getMockValue({ type: 'phone' })}`.replace(/-/g, ''),
          email: getMockValue({ type: 'email' })
        }
      ]
    }
  }
};

export default mockStickyRes;
