export const initialState = {
  loading: false
};

function toggleLoader (state = initialState, action) {
  switch (action.type) {
    case 'TOGGLE_LOADER':
      return {
        ...state,
        loading: typeof action.loading === 'boolean' ? action.loading : true
      };
    default:
      return state;
  }
}

export default toggleLoader;
