export const mockTransactionReportMiniGet = (body, params, options) => {
  const { guid = '' } = options || {};
  return {
    [guid]: {
      authorizationMiniReport: { value: 30, percent: null, range: 'Past 30 Days' },
      chargebackMiniReport: { value: 30, percent: null, range: 'Past 30 Days' },
      salesMiniReport: { value: 51870, percent: null, range: 'Past 30 Days' },
      refundMiniReport: { value: -1500, percent: null, range: 'Past 30 Days' }
    }
  };
};

export default mockTransactionReportMiniGet;
