import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withRouter } from './routing/withRouter';
import { HeaderWrap, Logo } from '../css/_styledComponents';

export class Header extends React.PureComponent {
  render () {
    const {
      children,
      logoClick,
      destination
    } = this.props;
    return (
      <HeaderWrap id="siteHeader" className="header">
        <Link id="siteLogo" to={destination} onClick={logoClick}>
          <Logo type="graphic" />
          <Logo type="wordMark" />
        </Link>
        {children}
      </HeaderWrap>
    );
  }
}

Header.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]),
  logoClick: PropTypes.func,
  destination: PropTypes.string
};
Header.defaultProps = {
  children: null,
  logoClick: () => {},
  destination: ''
};

export default withRouter(Header);
