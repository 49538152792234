export const initialState = {
  showModal: false,
  modalTitle: '',
  modalContent: null
};

function siteModal (state = initialState, action) {
  switch (action.type) {
    case 'MODAL':
      return {
        ...state,
        showModal: typeof action.showModal === 'boolean' ? action.showModal : true,
        modalTitle: action.modalTitle,
        modalContent: action.modalContent,
        options: action.options
      };
    default:
      return state;
  }
}

export default siteModal;
