import React from 'react';
import {
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';

// creating a functional component to wrap other components in,
// so we can utilize the new router hooks.
export const withRouter = (Component) => {
  const WithRouter = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return (
      <Component
        {...props}
        location={location}
        navigate={navigate}
        params={params}
      />
    );
  };
  return WithRouter;
};

export default withRouter;
