import { partnerGuids } from '../guids';

export const mockPartners = {
  partner01: {
    response: {
      partnerId: partnerGuids.default,
      partnerCode: '1000',
      partnerName: 'Partner with Transaction Report Data'
    },
    deserialized: {
      value: partnerGuids.default,
      title: 'Partner with Transaction Report Data',
      partnerBusinessCode: '1000'
    }
  },
  partner02: {
    response: {
      partnerId: partnerGuids.partner02,
      partnerCode: '1001',
      partnerName: '100'
    },
    deserialized: {
      value: partnerGuids.partner02,
      title: '100',
      partnerBusinessCode: '1001'
    }
  },
  partner03: {
    response: {
      partnerId: partnerGuids.partner03,
      partnerCode: '1002',
      partnerName: 'Mock Partner with Extraordinarily Long Name'
    },
    deserialized: {
      value: partnerGuids.partner03,
      title: 'Mock Partner with Extraordinarily Long Name',
      partnerBusinessCode: '1002'
    }
  },
  partner04: {
    response: {
      partnerId: partnerGuids.partner04,
      partnerCode: '1003',
      partnerName: 'GetGuidDaoSqlTest_46HFHCVl_0'
    },
    deserialized: {
      value: partnerGuids.partner04,
      title: 'GetGuidDaoSqlTest_46HFHCVl_0',
      partnerBusinessCode: '1003'
    }
  },
  partner05: {
    response: {
      partnerId: partnerGuids.partner05,
      partnerCode: '1004',
      partnerName: 'CrmV1PutRelationshipHandlerDefinitions_bNhaZvnX_6'
    },
    deserialized: {
      value: partnerGuids.partner05,
      title: 'CrmV1PutRelationshipHandlerDefinitions_bNhaZvnX_6',
      partnerBusinessCode: '1004'
    }
  },
  partner06: {
    response: {
      partnerId: partnerGuids.partner06,
      partnerCode: '1005',
      partnerName: 'CrmV1PutRelationshipHandlerDefinitions_bNhaZvnX_5'
    },
    deserialized: {
      value: partnerGuids.partner06,
      title: 'CrmV1PutRelationshipHandlerDefinitions_bNhaZvnX_5',
      partnerBusinessCode: '1005'
    }
  },
  partner07: { // has multiple relationshipMapping
    response: {
      partnerId: partnerGuids.partner07,
      partnerCode: '1006',
      partnerName: 'CrmV2GetReservesHandlerDefinitions_kMT4uqYQ_0'
    },
    deserialized: {
      value: partnerGuids.partner07,
      title: 'CrmV2GetReservesHandlerDefinitions_kMT4uqYQ_0',
      partnerBusinessCode: '1006'
    }
  }
};

export default mockPartners;
