import { createPartnerTree } from '@f1/shared/src/_helpers';
import {
  merchantGuids,
  partnerGuids,
  relationshipGuids
} from '../guids';
import { mockMerchantDetailsGetRes } from './v2/mockMerchantsV2';

export const mockMerchantGet = [{ // api response
  relationshipId: '96be2bda-7249-46af-88db-c4d7822aef8c',
  relationshipName: '0CsKQFgXSYEb4XH', // used in Relationship FT
  relationshipCode: null,
  parentRelation: null,
  childPartner: partnerGuids.partner04,
  payingChild: false,
  showToChild: false,
  merchantGuidToDba: {
    GuidType_sqlTest_merchant: 'ZL2xIwX4Du9R9Bl'
  },
  merchantGuidToLegalName: {
    GuidType_sqlTest_merchant: 'ZL2xIwX4Du9R9Bl inc.'
  },
  merchantGuidToMid: {
    GuidType_sqlTest_merchant: 'CO1VvSvSEZq7H1a'
  },
  merchantGuidToBusinessContactName: {
    GuidType_sqlTest_merchant: 'dr. kANCBuEtDlUhbxT d.c.'
  },
  downlines: []
},
{
  relationshipId: relationshipGuids.relationship15,
  relationshipName: 'Mock Netevia Evolve Relationship',
  relationshipCode: '20011',
  parentRelation: null,
  childPartner: partnerGuids.partner04,
  payingChild: false,
  showToChild: false,
  merchantGuidToDba: {
    'mock-netevia-merchant-elevated': 'Mock Netevia Merchant elevated'
  },
  merchantGuidToLegalName: {
    'mock-netevia-merchant-elevated': 'Mock Netevia Merchant elevated'
  },
  merchantGuidToMid: {
    'mock-netevia-merchant-elevated': '111111111113'
  },
  merchantGuidToBusinessContactName: {
    'mock-netevia-merchant-elevated': 'Ms. Netevia'
  },
  downlines: []
},
{
  relationshipId: 'ft_createRelationship_rid_2',
  relationshipName: 'VLtC1vgFn7OpZFR', // used in Relationship FT
  relationshipCode: null,
  parentRelation: null,
  childPartner: 'ft_createRelationship_ppid_2',
  payingChild: false,
  showToChild: false,
  merchantGuidToDba: {},
  merchantGuidToLegalName: {},
  merchantGuidToMid: {},
  merchantGuidToBusinessContactName: {},
  downlines: [{
    relationshipId: '6179b932-db4a-4ffe-8727-079cb6b9395d',
    relationshipName: 'CrmCreateRelationshipFT',
    relationshipCode: '121729',
    parentRelation: 'ft_createRelationship_rid_2',
    childPartner: 'ft_createRelationship_pid_2',
    payingChild: false,
    showToChild: false,
    merchantGuidToDba: {},
    merchantGuidToLegalName: {},
    merchantGuidToMid: {},
    merchantGuidToBusinessContactName: {},
    downlines: []
  }]
},
{
  relationshipId: relationshipGuids.default,
  relationshipName: 'Relationship with Transaction Report Data',
  relationshipCode: null,
  parentRelation: null,
  childPartner: partnerGuids.default,
  payingChild: false,
  showToChild: false,
  merchantGuidToDba: {
    [merchantGuids.default]: mockMerchantDetailsGetRes[merchantGuids.default].merchant.dbaName,
    [merchantGuids.closed]: mockMerchantDetailsGetRes[merchantGuids.closed].merchant.dbaName,
    [merchantGuids.merchant04]:
      mockMerchantDetailsGetRes[merchantGuids.merchant04].merchant.dbaName,
    [merchantGuids.merchant05]: mockMerchantDetailsGetRes[merchantGuids.merchant05].merchant.dbaName
  },
  merchantGuidToLegalName: {
    [merchantGuids.default]: mockMerchantDetailsGetRes[merchantGuids.default].merchant.legalName,
    [merchantGuids.closed]: mockMerchantDetailsGetRes[merchantGuids.closed].merchant.legalName,
    [merchantGuids.merchant04]:
      mockMerchantDetailsGetRes[merchantGuids.merchant04].merchant.dbaName,
    [merchantGuids.merchant05]: mockMerchantDetailsGetRes[merchantGuids.merchant05].merchant.dbaName
  },
  merchantGuidToMid: {
    [merchantGuids.default]: mockMerchantDetailsGetRes[merchantGuids.default].merchant.mid,
    [merchantGuids.closed]: mockMerchantDetailsGetRes[merchantGuids.closed].merchant.mid,
    [merchantGuids.merchant04]:
      mockMerchantDetailsGetRes[merchantGuids.merchant04].merchant.dbaName,
    [merchantGuids.merchant05]: mockMerchantDetailsGetRes[merchantGuids.merchant05].merchant.dbaName
  },
  merchantGuidToBusinessContactName: {
    [merchantGuids.default]:
        mockMerchantDetailsGetRes[merchantGuids.default].merchant.contactInfo.ownerName,
    [merchantGuids.closed]:
        mockMerchantDetailsGetRes[merchantGuids.closed].merchant.contactInfo.ownerName
  },
  downlines: []
},
{
  relationshipId: relationshipGuids.relationship23,
  relationshipName: 'FE - Priority / Axiom / Elevated',
  relationshipCode: '244201',
  parentRelation: null,
  childPartner: partnerGuids.partner08,
  payingChild: false,
  showToChild: false,
  merchantGuidToDba: {
    'mock-priority-axiom-elevated-merchant': 'Mock Merchant - Priority / Axiom / Elevated'
  },
  merchantGuidToLegalName: {
    'mock-priority-axiom-elevated-merchant': 'Mock Merchant - Priority / Axiom / Elevated'
  },
  merchantGuidToMid: {
    'mock-priority-axiom-elevated-merchant': '111111111114'
  },
  merchantGuidToBusinessContactName: {
    'mock-priority-axiom-elevated-merchant': 'Mr. Axiom'
  },
  downlines: []
},
{
  relationshipId: relationshipGuids.relationship24,
  relationshipName: 'FE - Priority / Wells Fargo / Elevated',
  relationshipCode: '232426',
  parentRelation: null,
  childPartner: partnerGuids.partner08,
  payingChild: false,
  showToChild: false,
  merchantGuidToDba: {
    'mock-priority-wells-fargo-elevated-merchant': 'Mock Merchant - Priority / Wells Fargo / Elevated'
  },
  merchantGuidToLegalName: {
    'mock-priority-wells-fargo-elevated-merchant': 'Mock Merchant - Priority / Wells Fargo / Elevated'
  },
  merchantGuidToMid: {
    'mock-priority-wells-fargo-elevated-merchant': '111111111115'
  },
  merchantGuidToBusinessContactName: {
    'mock-priority-wells-fargo-elevated-merchant': 'Mrs. Fargo'
  },
  downlines: []
},
{
  relationshipId: relationshipGuids.relationship21,
  relationshipName: 'FE - Micamp / Mavric / Elevated',
  relationshipCode: '232427',
  parentRelation: null,
  childPartner: partnerGuids.partner08,
  payingChild: false,
  showToChild: false,
  merchantGuidToDba: {
    'mock-micamp-mavric-elevated-merchant': 'Mock Merchant - Micamp / Mavric / Elevated'
  },
  merchantGuidToLegalName: {
    'mock-micamp-mavric-elevated-merchant': 'Mock Merchant - Micamp / Mavric / Elevated'
  },
  merchantGuidToMid: {
    'mock-micamp-mavric-elevated-merchant': '111111111116'
  },
  merchantGuidToBusinessContactName: {
    'mock-micamp-mavric-elevated-merchant': 'Mrs. Mavric'
  },
  downlines: []
}
];

export const mockMerchantGetFormatted = createPartnerTree(mockMerchantGet); // deserialized
