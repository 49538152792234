import React from 'react';
import PropTypes from 'prop-types';
import { attachCachedS3FilesToResource, isEmpty } from './_helpers';
import { Spinner } from '../index';
import FilesArea from './FilesArea';
import { DropzoneFileList } from './DropzoneFileList';
import { fileAttachToResourceTemplate } from './data/templates/fileAttachToResourceTemplate';

export class DropzoneModal extends React.Component {
  constructor (props) {
    super(props);
    this.mounted = false;
    this.state = {
      spinnerLoading: false
    };
  }

  componentDidMount () {
    this.mounted = true;
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  updateState = (state, callback = null) => {
    this.mounted && this.setState(state, callback);
  }

  handleFilesAdded = async (options) => {
    const {
      allFilesLoaded,
      filesLoaded,
      cacheAndS3ErrorFiles,
      formattedUploadFiles,
      preloadRes,
      filesValid,
      filesError
    } = options || {};
    const {
      attachToResourceEndpoint,
      axiosRequest,
      cacheUploadEndpoint,
      requestGuid
    } = this.props;
    const useAttachToResource = !isEmpty(requestGuid) && !isEmpty(cacheUploadEndpoint) &&
      !isEmpty(attachToResourceEndpoint);
    const cbOptions = useAttachToResource
      ? {
        valid: filesValid,
        value: filesError ? [] : filesLoaded,
        allFilesLoaded,
        formattedUploadFiles,
        filesLoaded,
        preloadRes
      }
      : { ...options };
    const filesResArray = !isEmpty(cbOptions.value) ? [...cbOptions.value] : [];
    const attachToResourceResults = useAttachToResource
      ? await attachCachedS3FilesToResource(filesResArray, {
        cacheAndS3ErrorFiles,
        axiosRequest,
        attachToResourceEndpoint,
        fileAttachToResourceTemplate,
        requestGuid,
        updateState: this.updateState
      })
      : {};
    const { hasUploadErrors } = attachToResourceResults || {};
    const cacheOptions = !isEmpty(cacheUploadEndpoint)
      ? {
        hasFileErrors: !isEmpty(cacheAndS3ErrorFiles) || (useAttachToResource && hasUploadErrors),
        cacheAndS3ErrorFiles
      } : {};
    this.handleCallback({
      ...cbOptions,
      ...cacheOptions
    });
  }

  handleCallback = (options) => {
    const { allFilesLoaded, error } = options || {};
    const { callback, siteModal } = this.props;
    if (allFilesLoaded && isEmpty(error)) { // error is handled internally in FilesArea
      siteModal && siteModal(false);
      callback && callback(options);
    }
  }

  render () {
    const {
      spinnerLoading
    } = this.state;
    const {
      userType,
      allowEditFileName,
      existingFiles,
      axiosRequest,
      displayedFields,
      attachToResourceEndpoint,
      cacheUploadEndpoint,
      uploadEndpoint,
      requestGuid,
      useNewFormat,
      defaultTagsOnAdd,
      fileNameOverride,
      fileRequirements,
      fileNameErrorMessage,
      fileNameCustomValidation,
      loadMessage,
      maxAddFileCount,
      onlyEncodeData
    } = this.props;
    return (
      <div
        id="dropzone-modal"
        style={{
          display: 'flex',
          width: '100%',
          flexWrap: 'wrap',
          position: 'relative',
          ...(displayedFields.includes('tagList') && { minHeight: '400px' })
        }}
      >
        <Spinner loading={spinnerLoading} />
        <FilesArea
          userType={userType}
          allowEditFileName={allowEditFileName}
          fileNameErrorMessage={fileNameErrorMessage}
          fileNameCustomValidation={fileNameCustomValidation}
          fileRequirements={fileRequirements}
          existingFiles={existingFiles}
          callback={this.handleFilesAdded}
          enableDropzone
          axiosRequest={axiosRequest}
          {...(!isEmpty(cacheUploadEndpoint)
            ? {
              useCacheUpload: true,
              cacheUploadEndpoint,
              // If attaching to resource, all file errors are handled in DropzoneModal
              useParentCacheErrorSwal: !isEmpty(requestGuid) && !isEmpty(attachToResourceEndpoint)
            }
            : { preloadEndpoint: uploadEndpoint })
          }
          onlyEncodeData={onlyEncodeData}
          requestGuid={requestGuid}
          useNewFormat={useNewFormat}
          displayedFields={displayedFields}
          fileNameOverride={fileNameOverride}
          defaultTagsOnAdd={defaultTagsOnAdd}
          wrapperStyle={{ padding: '0.3em', width: '100%' }}
          {...loadMessage && { loadMessage }}
          maxAddFileCount={maxAddFileCount}
        />
        <DropzoneFileList
          type="white"
          userType={userType}
          useTags
          id="existingFilesList"
          title="Existing Files:"
          addedFiles={existingFiles}
          styles={{ title: { paddingLeft: '0' }, wrapper: { ...(isEmpty(existingFiles) ? { height: '0' } : { width: '100%', margin: '0.5em' }) } }}
          canDelete={false}
        />
      </div>
    );
  }
}

DropzoneModal.propTypes = {
  userType: PropTypes.string,
  defaultTagsOnAdd: PropTypes.oneOfType([PropTypes.array]),
  existingFiles: PropTypes.oneOfType([PropTypes.array]),
  callback: PropTypes.func,
  displayedFields: PropTypes.oneOfType([PropTypes.array]),
  allowEditFileName: PropTypes.bool,
  requestGuid: PropTypes.oneOfType([PropTypes.object]),
  useNewFormat: PropTypes.bool,
  attachToResourceEndpoint: PropTypes.string,
  cacheUploadEndpoint: PropTypes.string,
  uploadEndpoint: PropTypes.string,
  loadMessage: PropTypes.string,
  siteModal: PropTypes.func,
  axiosRequest: PropTypes.func,
  fileRequirements: PropTypes.oneOfType([PropTypes.array]),
  fileNameCustomValidation: PropTypes.func,
  fileNameErrorMessage: PropTypes.string,
  fileNameOverride: PropTypes.string,
  maxAddFileCount: PropTypes.number,
  onlyEncodeData: PropTypes.bool
};

DropzoneModal.defaultProps = {
  userType: '',
  defaultTagsOnAdd: [], // Any default tags we want added under the covers for new files
  existingFiles: [],
  callback: null,
  displayedFields: [ // For add file, and user can add tags/select file type
  // 'tagList',
  // 'selectFileType'
  ],
  allowEditFileName: false, // true = user can edit any dropped file name before uploading
  requestGuid: {},
  useNewFormat: false,
  attachToResourceEndpoint: '', // REQUIRED starting with V3 file endpoints with existing resource
  cacheUploadEndpoint: '', // REQUIRED starting with V3 file endpoints (with or without existing resource)
  uploadEndpoint: '', // Only use if V3+ file endpoints are not supported, or no resource exists
  loadMessage: null,
  siteModal: null,
  axiosRequest: () => {},
  fileRequirements: [],
  fileNameCustomValidation: null, // if user can rename files, apply validation for all file names
  fileNameErrorMessage: null, // custom error message for `fileNameCustomValidation`
  fileNameOverride: null,
  maxAddFileCount: 0,
  onlyEncodeData: false // No api calls, only return encoded files
};

export default DropzoneModal;
