export const mockTicketStatusOverview = {
  individualTickets: {
    severity_4: '0',
    unclassified: '0',
    severity_2: '0',
    severity_3: '0',
    severity_1: '0',
    severity_5: '0'
  },
  teamTickets: {
    severity_4: '0',
    unclassified: '0',
    severity_2: '2',
    severity_3: '0',
    severity_1: '0',
    severity_5: '0'
  }
};

export const mockTicketOverview = {
  individualTickets: {
    severity_4: '1',
    unclassified: '2',
    severity_2: '4',
    severity_3: '3',
    severity_1: '1',
    severity_5: '5'
  },
  teamTickets: {
    severity_4: '3',
    unclassified: '1',
    severity_2: '0',
    severity_3: '2',
    severity_1: '1',
    severity_5: '5'
  }
};

export const mockSortedTicketOverview = {
  individualTickets: {
    severity_1: '1',
    severity_2: '4',
    severity_3: '3',
    severity_4: '1',
    severity_5: '5',
    unprioritized: '2'
  },
  teamTickets: {
    severity_1: '2',
    severity_2: '0',
    severity_3: '2',
    severity_4: '3',
    severity_5: '5',
    unprioritized: '1'
  }
};
