import { sortData } from '@f1/shared/src/_helpers';
import { mockRelationshipMapping } from './mockRelationshipMapping';
import { partnerGuids } from '../guids';

export const mockRelationshipMappingGetWithGuid = (body, params, options) => {
  const { guid = `"{}"` } = options || null;
  const partnerIdsWithNoRelationshipMap = [
    partnerGuids.partner03
  ];
  if (guid === null) return mockRelationshipMappingGet;

  return {
    [guid]: partnerIdsWithNoRelationshipMap.includes(guid) ? [] : mockRelationshipMappingGet
  };
};

export const mockRelationshipMappingGet = { // api response
  mappingData: [
    mockRelationshipMapping.relationshipMap01.response,
    mockRelationshipMapping.relationshipMap02.response,
    mockRelationshipMapping.relationshipMap03.response,
    mockRelationshipMapping.relationshipMap04.response,
    mockRelationshipMapping.relationshipMap05.response,
    mockRelationshipMapping.relationshipMap06.response,
    mockRelationshipMapping.relationshipMap07.response,
    mockRelationshipMapping.relationshipMap08.response,
    mockRelationshipMapping.relationshipMap09.response
  ]
};

export const mockRelationshipMappingGetFormatted = sortData([ // deserialized
  mockRelationshipMapping.relationshipMap01.deserialized,
  mockRelationshipMapping.relationshipMap02.deserialized,
  mockRelationshipMapping.relationshipMap03.deserialized,
  mockRelationshipMapping.relationshipMap04.deserialized,
  mockRelationshipMapping.relationshipMap05.deserialized,
  mockRelationshipMapping.relationshipMap06.deserialized,
  mockRelationshipMapping.relationshipMap07.deserialized,
  mockRelationshipMapping.relationshipMap08.deserialized,
  mockRelationshipMapping.relationshipMap09.deserialized
], 'title');
