import { createMockPendList } from '@f1/shared/src/data/sharedBoarding/mockBoardingApplicationTaskPend';
import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { mockRelationships } from '../relationship/mockRelationships';
import { mockPartners } from '../partner/mockPartners';

const statusList = [
  'draft',
  'waiting_on_initial_signature',
  'waiting_on_ops',
  'waiting_on_partner',
  'waiting_on_credit',
  'waiting_on_app_review',
  'waiting_on_signature_post_pends',
  'waiting_on_bank',
  'pended_by_bank',
  'boarding',
  'approved',
  'withdrawn',
  'declined',
  'abandoned'
];

const getMockRelationship = () => {
  const keys = Object.keys(mockRelationships);
  const randomKey = keys[Math.floor(Math.random() * keys.length)];
  const randomRelationship = mockRelationships[randomKey].response;
  return {
    relationshipId: randomRelationship.relationshipId,
    relationshipName: randomRelationship.relationshipName,
    relationshipCode: randomRelationship.relationshipCode,
    processorName: randomRelationship.processorName,
    bankName: randomRelationship.bankName,
    riskProfile: randomRelationship.riskProfile
  };
};

const getMockPartner = () => {
  const keys = Object.keys(mockPartners);
  const randomKey = keys[Math.floor(Math.random() * keys.length)];
  const randomPartner = mockPartners[randomKey].response;
  return {
    partnerId: randomPartner.partnerId,
    partnerBusinessCode: randomPartner.partnerCode,
    partnerName: randomPartner.partnerName
  };
};

export const mockApplicationPendReport = () => {
  const mockData = Array.from({ length: 4 }).map((item, index) => ({
    applicationId: `mock-application-id-${index + 1}`,
    applicationBusinessNumber: `${index + 1}`,
    applicationName: `Mock App Name ${index + 1}`,
    dbaNameList: Array.from({ length: getMockValue({ type: 'number', min: 2, max: 3 }) }).map(() => getMockValue({ type: 'businessName' })),
    legalName: getMockValue({ type: 'businessName' }),
    pendList: createMockPendList({ length: getMockValue({ type: 'number', min: 1, max: 10 }) }).map(pend => ({ negativeActionList: pend.negativeActionList })),
    relationship: getMockRelationship(),
    partner: getMockPartner(),
    applicationCreatedTimestamp: getMockValue({ type: 'isoDate' }),
    applicationSubmittedTimestamp: getMockValue({ type: 'isoDate' }),
    applicationCompletedTimestamp: getMockValue({ type: 'isoDate', allowNull: true }),
    applicationStatus: getMockValue({ type: 'list', list: statusList })
  }));
  return {
    applications: mockData,
    pagingInfo: {
      totalNumberOfRecords: 4,
      pageIndex: 0,
      pageSize: 3000
    }
  };
};

export default mockApplicationPendReport;
