/*
using the @react-google-maps/api component:
  https://www.npmjs.com/package/@react-google-maps/api
Documentation on AutoComplete widget:
  https://developers.google.com/maps/documentation/javascript/examples/place-autocomplete-element#maps_place_autocomplete_element-javascript
*/
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, useLoadScript } from '@react-google-maps/api';
import {
  countryCodes,
  dataExists,
  ignoreCase,
  isEmpty,
  isEqual
} from './_helpers';
import { ComboBox, Input, Spinner } from '../index';
import { sharedFormFields } from './_formFields';
import { toBackendValue } from './_templateHelpers';
import { addressAutocompleteValid, getAddressDataObj } from './_formHelpers';
import { addressAutocompleteIdMap, getAddressAutocompleteData, useLocalOnly } from './_addressAutocompleteHelpers';

const placesLibrary = ['places'];

export const AddressAutocomplete = (props) => {
  const {
    baseAddressFields = {
      /**
       * example:
       * addressLine1: {
       *   id: 'addressLine1',
       *   label: 'Address Line 1',
       *   fieldType: 'input',
       *   required: true
       * }
       */
    },
    callback = () => {},
    compOverrides = {},
    data = {},
    disabled = false,
    id = null
  } = props || {};
  const addressFieldsArray = !isEmpty(baseAddressFields) ? Object.values(baseAddressFields) : [];
  const getFormFieldMatch = fieldKey => (
    addressFieldsArray.find(addressField => (
      addressAutocompleteIdMap[fieldKey]
        ? addressAutocompleteIdMap[fieldKey].find(idOption => isEqual(idOption, addressField.id))
        : {}
    )) || {}
  );
  const addressLine1FormField = getFormFieldMatch('addressLine1');
  const addressLine2FormField = getFormFieldMatch('addressLine2');
  const cityFormField = getFormFieldMatch('city');
  const stateFormField = getFormFieldMatch('state');
  const zipCodeFormField = getFormFieldMatch('zipCode');
  const countryFormField = getFormFieldMatch('country');
  const [searchResult, setSearchResult] = useState('Result: none');
  const [addressObj, setAddressObj] = useState({});
  const loadScript = useLocalOnly()
    ? { localOnly: true } // For FTs
    : useLoadScript({
      googleMapsApiKey: 'AIzaSyDz7kyUalV_qj6GGOykqpluYpuxNfID_64',
      libraries: placesLibrary
    });
  const { isLoaded, localOnly, loadError } = loadScript || {};
  const useFallback = localOnly || disabled || !isEmpty(loadError);

  useEffect(() => {
    if ( // Set data on load
      isEmpty(addressObj) && dataExists(data) &&
      (localOnly || disabled || !isEmpty(loadError) || isLoaded)
    ) {
      const addressData = getAddressDataObj(data);
      setAddressObj(addressData);
    }
  }, [data, disabled, isLoaded, loadError]);

  const handleFormChange = (fieldType, fieldOptions) => {
    const useOnPlaceChanged = typeof fieldType === 'undefined';
    const formattedFieldType = useOnPlaceChanged ? undefined : ignoreCase(fieldType);
    if (['input'].includes(formattedFieldType)) {
      handleInputChange(...fieldOptions);
    }
    if (['dropdown', 'combobox'].includes(formattedFieldType)) {
      handleDropdownChange(fieldOptions);
    }
    const onPlaceChangedData = useOnPlaceChanged
      ? getAddressAutocompleteData({
        addressFields: {
          addressLine1FormField,
          addressLine2FormField,
          cityFormField,
          stateFormField,
          zipCodeFormField,
          countryFormField
        },
        data: addressObj,
        searchResult
      })
      : null;
    !isEmpty(onPlaceChangedData) && setAddressObj(onPlaceChangedData);
  };

  const handleInputChange = (inputId, inputValue, inputValid) => {
    const valueChanged = !isEqual(addressObj[inputId], inputValue);
    if (valueChanged || isEmpty(addressObj)) {
      setAddressObj(prevAddressObj => ({ ...prevAddressObj, [inputId]: inputValue }));
    }
  };

  const handleDropdownChange = (options) => {
    const { id: dropdownId, value: dropdownValue } = options || {};
    const valueChanged = !isEqual(addressObj[dropdownId], dropdownValue);
    if (valueChanged || isEmpty(addressObj)) {
      setAddressObj(prevAddressObj => ({ ...prevAddressObj, [dropdownId]: dropdownValue }));
    }
  };

  useEffect(() => {
    if (!isEmpty(addressObj)) {
      const cbData = {
        id,
        value: {
          ...addressObj,
          valuesForBackend: Object.values(baseAddressFields).reduce((acc, addressField) => {
            const { fieldType, id: fieldId } = addressField || {};
            const comp = !isEmpty(fieldType)
              ? { componentType: fieldType, props: addressField }
              : {};
            return {
              ...acc,
              ...(!isEmpty(comp) && { [fieldId]: toBackendValue(addressObj[fieldId], comp) })
            };
          }, {})
        },
        valid: addressAutocompleteValid({ [id]: addressObj }, props)
      };
      callback(cbData);
    }
  }, [addressObj]);

  const addressLine1InputProps = !isEmpty(addressLine1FormField)
    ? {
      ...compOverrides.input,
      ...addressLine1FormField,
      value: addressObj[addressLine1FormField.id],
      callback: (...inputArgs) => handleFormChange('input', inputArgs),
      wrapperStyle: {
        ...(compOverrides.input && compOverrides.input.wrapperStyle),
        ...(addressLine1FormField && addressLine1FormField.wrapperStyle)
      },
      disabled
    }
    : {};
  const showForm = useFallback || isLoaded;
  return (
    <div
      id={id}
      role="article"
      aria-label="Address autocomplete"
      style={{
        minHeight: '40px', // On load, for Spinner
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        position: 'relative'
      }}
    >
      {showForm ? (
        <>
          <div style={{ flex: '100%' }}>
            {useFallback
              // If FTs/form disabled/there's an error loading autocomplete,
              // fallback to just rendering the Input, so user is not blocked from
              // using the form.
              ? <Input {...addressLine1InputProps} />
              : (
                <Autocomplete onPlaceChanged={handleFormChange} onLoad={setSearchResult} className="address-autocomplete">
                  <Input {...addressLine1InputProps} />
                </Autocomplete>
              )}
          </div>
          {!isEmpty(addressLine2FormField)
            ? (
              <Input
                {...compOverrides.input}
                {...addressLine2FormField}
                value={addressObj[addressLine2FormField.id]}
                callback={(...inputArgs) => handleFormChange('input', inputArgs)}
                wrapperStyle={{
                  ...(compOverrides.input && compOverrides.input.wrapperStyle),
                  ...(addressLine2FormField && addressLine2FormField.wrapperStyle),
                  flex: '100%'
                }}
                disabled={disabled}
              />
            )
            : null}
          {!isEmpty(cityFormField)
            ? (
              <Input
                {...compOverrides.input}
                {...cityFormField}
                value={addressObj[cityFormField.id]}
                callback={(...inputArgs) => handleFormChange('input', inputArgs)}
                wrapperStyle={{
                  ...(compOverrides.input && compOverrides.input.wrapperStyle),
                  ...(cityFormField && cityFormField.wrapperStyle)
                }}
                disabled={disabled}
              />
            )
            : null}
          {!isEmpty(stateFormField)
            ? (
              <ComboBox
                {...compOverrides.dropdown}
                {...sharedFormFields.state}
                {...stateFormField}
                type="text"
                selected={addressObj[stateFormField.id]}
                callback={dropdownOpts => handleFormChange('combobox', dropdownOpts)}
                wrapperStyle={{
                  ...(compOverrides.dropdown && compOverrides.dropdown.wrapperStyle),
                  ...(stateFormField && stateFormField.wrapperStyle)
                }}
                disabled={disabled}
              />
            )
            : null}
          {!isEmpty(zipCodeFormField)
            ? (
              <Input
                {...compOverrides.input}
                {...zipCodeFormField}
                value={addressObj[zipCodeFormField.id]}
                callback={(...inputArgs) => handleFormChange('input', inputArgs)}
                wrapperStyle={{
                  ...(compOverrides.input && compOverrides.input.wrapperStyle),
                  ...(zipCodeFormField && zipCodeFormField.wrapperStyle)
                }}
                disabled={disabled}
              />
            )
            : null}
          {!isEmpty(countryFormField)
            ? (
              <ComboBox
                {...compOverrides.dropdown}
                {...countryFormField}
                list={!isEmpty(countryFormField.list) ? countryFormField.list : countryCodes}
                type="text"
                selected={addressObj[countryFormField.id]}
                callback={dropdownOpts => handleFormChange('combobox', dropdownOpts)}
                wrapperStyle={{
                  ...(compOverrides.dropdown && compOverrides.dropdown.wrapperStyle),
                  ...(countryFormField && countryFormField.wrapperStyle)
                }}
                disabled={disabled}
              />
            )
            : null}
        </>
      ) : <Spinner loading />}
    </div>
  );
};

AddressAutocomplete.propTypes = {
  baseAddressFields: PropTypes.oneOfType([PropTypes.object]),
  callback: PropTypes.func.isRequired,
  compOverrides: PropTypes.shape({
    dropdown: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object])
  }),
  data: PropTypes.oneOfType([PropTypes.object]),
  disabled: PropTypes.bool,
  id: PropTypes.string
};

export default AddressAutocomplete;
