import React from 'react';
import PropTypes from 'prop-types';
import {
  ignoreCase,
  isEmpty,
  isEqual,
  pad,
  monthsArray
} from './_helpers';
import { ToolTip, ComboBox } from '../index';
import { ErrorBox } from '../css/_styledFormComponents';
import { input } from './_styles';
import validations from './_validations';

class ComboBoxSelectMonthYear extends React.Component {
  constructor (props) {
    super(props);
    this.mounted = false;
    const { dataGroupId, minDate, maxDate } = props;
    this.allMonthsList = monthsArray.map((m, i) => ({ title: `${pad(i + 1)} - ${m}`, value: m }));
    const { min, max } = validations.date;
    const [minYear] = (!isEmpty(minDate) ? minDate : min).split('-');
    const [maxYear, maxMonthNumber] = (!isEmpty(maxDate) ? maxDate : max).split('-');
    this.monthId = !isEmpty(dataGroupId) ? `${dataGroupId}SelectMonth` : 'selectMonthDropdown';
    this.yearId = !isEmpty(dataGroupId) ? `${dataGroupId}SelectYear` : 'selectYearDropdown';
    this.state = {
      currentMonthNumber: Number(maxMonthNumber),
      minYear,
      maxYear,
      monthsList: this.allMonthsList,
      yearsList: [],
      selectedMonth: '',
      selectedMonthNumber: -1,
      showMonthError: false,
      selectedYear: '',
      showYearError: false,
      monthAndYearValid: false,
      showErrorBox: false
    };
  }

  componentDidMount () {
    this.mounted = true;
    this.prefillData();
  }

  componentDidUpdate (prevProps) {
    const { value } = this.props;
    const { selectedMonth, selectedYear } = this.state;
    if (!isEqual(value, prevProps.value) && (isEmpty(selectedMonth) || isEmpty(selectedYear))) {
      this.prefillData();
    } else if (!isEqual(value, prevProps.value) && isEmpty(value)) {
      // incoming value is empty (clearing the field)
      this.clearSelectedMonthYear();
    }
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  updateState = (state, callback = null) => {
    this.mounted && this.setState(state, callback);
  }

  clearSelectedMonthYear = (callback) => {
    this.updateState({ selectedMonth: '', selectedMonthNumber: -1, selectedYear: '' }, callback ?? null);
  }

  prefillData = () => {
    const { value } = this.props;
    const { minYear, maxYear } = this.state;
    const validFormat = RegExp(/^[0-9]{4}-[0-9]{2}$/).test(value);
    if (validFormat) {
      const [yearValue, monthValue] = value.split('-');
      const monthNumber = !isEmpty(monthValue) ? Number(monthValue) : -1;
      const monthName = monthsArray[monthNumber - 1];
      const selectedMonthIsValid = monthsArray.indexOf(monthName) !== -1;
      const selectedYearIsValid = Number(yearValue) <= Number(maxYear) &&
        Number(yearValue) >= Number(minYear);
      this.updateState({
        ...(selectedMonthIsValid && { selectedMonth: monthName, selectedMonthNumber: monthNumber }),
        ...(selectedYearIsValid && { selectedYear: yearValue })
      }, this.setYearsList);
    } else {
      this.clearSelectedMonthYear(this.setYearsList);
    }
  }

  setYearsList = () => {
    const {
      minYear,
      maxYear,
      selectedYear,
      selectedMonth,
      currentMonthNumber,
      selectedMonthNumber
    } = this.state;
    let currentMaxYear = `${maxYear}`;
    if (!isEmpty(selectedMonth)) {
      const sameYear = this.isSameYear();
      /**
       * Remove current year from years list IF
       * selected year !== current year, OR no selected year yet,
       * AND selected month > current month
       */
      const removeCurrentYear = ((!isEmpty(selectedYear) && !sameYear) ||
        isEmpty(selectedYear)) && selectedMonthNumber > currentMonthNumber;
      if (removeCurrentYear) {
        currentMaxYear = `${Number(maxYear) - 1}`;
      }
    }
    let maxYearNumber = Number(currentMaxYear);
    const yearsList = [];
    while (maxYearNumber >= Number(minYear)) {
      yearsList.push({ title: `${maxYearNumber}`, value: `${maxYearNumber}` });
      maxYearNumber -= 1;
    }
    this.updateState({ yearsList }, this.setMonthsList);
  }

  setMonthsList = () => {
    const allMonths = [...this.allMonthsList];
    const sameYear = this.isSameYear();
    this.updateState(prevState => ({
      monthsList: sameYear ? allMonths.slice(0, (prevState.currentMonthNumber || 0)) : allMonths
    }), this.handleValidate);
  }

  handleChangeDropdown = (options) => {
    const { id, value } = options || {};
    this.updateState(prevState => ({
      ...(ignoreCase(id).includes('month') && {
        ...(isEmpty(value) && !isEmpty(prevState.selectedYear) && { showMonthError: true }),
        ...(isEmpty(value) && isEmpty(prevState.selectedYear) && { showYearError: false }),
        selectedMonth: value,
        selectedMonthNumber: monthsArray.indexOf(value) !== -1
          ? monthsArray.indexOf(value) + 1
          : -1
      }),
      ...(ignoreCase(id).includes('year') && {
        ...(isEmpty(value) && !isEmpty(prevState.selectedMonth) && { showYearError: true }),
        ...(isEmpty(value) && isEmpty(prevState.selectedMonth) && { showMonthError: false }),
        selectedYear: value
      })
    }), this.setYearsList);
  }

  handleValidate = () => {
    const { validationActivated, required, disabled } = this.props;
    const selectedMonthIsValid = this.isSelectedMonthValid();
    const selectedYearIsValid = this.isSelectedYearValid();
    const monthAndYearValid = (selectedMonthIsValid && selectedYearIsValid) || false;
    this.updateState((prevState) => {
      const hasBoth = !isEmpty(prevState.selectedMonth) && !isEmpty(prevState.selectedYear);
      const hasNone = isEmpty(prevState.selectedMonth) && isEmpty(prevState.selectedYear);
      return {
        monthAndYearValid,
        showErrorBox: !disabled && (hasBoth || (validationActivated && hasNone))
          ? !monthAndYearValid
          : false,
        ...(!disabled && (validationActivated || !required) && !hasNone && ({
          ...(isEmpty(prevState.selectedMonth) && { showMonthError: true }),
          ...(isEmpty(prevState.selectedYear) && { showYearError: true })
        })),
        ...(!required && hasNone && { showErrorBox: false })
      };
    }, this.handleCallback);
  }

  isSelectedMonthValid = () => {
    const { currentMonthNumber, selectedMonthNumber } = this.state;
    const sameYear = this.isSameYear();
    if (selectedMonthNumber !== -1) {
      return sameYear ? selectedMonthNumber <= currentMonthNumber : true;
    }
    return false;
  }

  isSelectedYearValid = () => {
    const { minYear, maxYear, selectedYear } = this.state;
    return (!isEmpty(selectedYear) && Number(selectedYear) <= Number(maxYear) &&
      Number(selectedYear) >= Number(minYear)) || false;
  }

  isSameYear = () => {
    const { maxYear, selectedYear } = this.state;
    return !isEmpty(selectedYear) ? Number(selectedYear) === Number(maxYear) : false;
  }

  handleCallback = () => {
    const {
      monthAndYearValid,
      selectedYear,
      selectedMonth,
      selectedMonthNumber
    } = this.state;
    const { id, callback, required } = this.props;
    const cbValue = monthAndYearValid ? `${selectedYear}-${pad(selectedMonthNumber)}` : '';
    const cbOptions = {
      id,
      title: cbValue,
      value: cbValue,
      ...(required
        ? { valid: monthAndYearValid }
        : { valid: isEmpty(selectedMonth) && isEmpty(selectedYear) ? true : monthAndYearValid }
      )
    };
    callback && callback(cbOptions);
  }

  render () {
    const {
      id,
      label,
      useBlockForm,
      required,
      wrapperStyle,
      valueContainerStyles,
      disabled,
      tooltip
    } = this.props;
    const {
      monthsList,
      yearsList,
      monthAndYearValid,
      selectedMonth,
      selectedYear,
      showErrorBox,
      showMonthError,
      showYearError
    } = this.state;
    const sharedComboBoxProps = {
      callback: this.handleChangeDropdown,
      disabled,
      required,
      useBlockForm,
      fieldType: 'combobox',
      type: 'text',
      formField: true,
      valueContainerStyles,
      boxStyles: {
        color: 'initial',
        ...(useBlockForm && {
          minHeight: 'auto',
          borderColor: 'transparent'
        }),
        ...(showErrorBox && {
          color: 'var(--color-warning)',
          backgroundColor: 'var(--color-warning-bg)',
          ...(!useBlockForm && { borderColor: 'var(--color-warning)' })
        })
      },
      wrapperStyle: { minWidth: '150px', flex: '50%' }
    };
    return (
      <div
        className={id ? `${id}SelectMonthYearField` : `selectMonthYearField`}
        style={{
          ...(useBlockForm ? {
            ...input.innerWrap,
            margin: '-1px',
            flex: '1',
            ...(disabled
              ? input.innerWrapDisabled
              : {
                ...(!showErrorBox && (monthAndYearValid || (!required && isEmpty(selectedMonth) && isEmpty(selectedYear))) && { backgroundColor: 'var(--color-healthy-bg)' }),
                ...(showErrorBox && {
                  backgroundColor: 'var(--color-warning-bg)',
                  borderColor: 'var(--color-warning)',
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  zIndex: 2
                })
              })
          } : input.wrap),
          ...wrapperStyle
        }}
      >
        {label && (
          <label
            style={{
              ...(useBlockForm ? input.labelInside : {
                ...input.label,
                height: '24px',
                lineHeight: '24px',
                margin: '0'
              }),
              ...(!disabled && showErrorBox && { color: 'var(--color-warning)' })
            }}
            htmlFor={id}
          >
            {required && (<span style={input.label_required}>* </span>)}
            {label}
            {tooltip && (<span style={input.timestampTooltip}><ToolTip infoTip infoTipDisplay={{ top: '-3px', margin: 0 }}>{tooltip}</ToolTip></span>)}
          </label>
        )}
        <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
          <ComboBox
            {...sharedComboBoxProps}
            placeholder="Month"
            id={this.monthId}
            type={this.monthId}
            list={monthsList}
            selected={selectedMonth}
            validationActivated={showMonthError}
          />
          <ComboBox
            {...sharedComboBoxProps}
            placeholder="Year"
            id={this.yearId}
            type={this.yearId}
            list={yearsList}
            selected={selectedYear}
            validationActivated={showYearError}
          />
        </div>
        <ErrorBox
          className="errors"
          id={`${id}-error`}
          error={showErrorBox}
          $boxStyle={useBlockForm ? 'inside' : null}
        >
          Must be a valid month/year
        </ErrorBox>
      </div>
    );
  }
}

ComboBoxSelectMonthYear.propTypes = {
  id: PropTypes.string,
  dataGroupId: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  useBlockForm: PropTypes.bool,
  validationActivated: PropTypes.bool,
  wrapperStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  valueContainerStyles: PropTypes.oneOfType([PropTypes.object]),
  required: PropTypes.bool,
  callback: PropTypes.func,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string
};

ComboBoxSelectMonthYear.defaultProps = {
  id: '',
  dataGroupId: '', // Makes combobox unique (eg, when you have "to date"/"from date" range)
  label: '',
  value: '', // Format should be YYYY-MM
  minDate: '', // Optional - Format should be YYYY-MM-DD (currently, this comp only uses the YEAR)
  maxDate: '', // Optional - Format should be YYYY-MM-DD (currently, this comp only uses the YEAR)
  useBlockForm: false,
  validationActivated: false,
  wrapperStyle: {},
  valueContainerStyles: {},
  required: false,
  callback: null,
  disabled: false,
  tooltip: null
};

export default ComboBoxSelectMonthYear;
