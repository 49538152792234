import { isEmpty, pad } from '@f1/shared/src/_helpers';
import { mockPagingInfo, getMockPageData, getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { mockSignInUsers, mockUserSignInPost } from '../user/signIn';
import { staticApps as staticPrevetApps } from '../crab/mockEmployeePrevetsGet';
import {
  partnerGuids,
  relationshipGuids,
  residualTaskGuids,
  merchantGuids,
  ticketGuids
} from '../guids';

const useStatic = true; // Default is true for FTs. Update locally to `false` to test paged data

const mockGuidValueMap = { // Guid type for key fields
  merchantGuidList: 'merchant-guid',
  relationshipIdList: 'relationship-id',
  partnerIdList: 'partner-id',
  ticketIdList: 'ticket-id',
  applicationIdList: 'application-id',
  applicationTaskIdList: 'application-task-id',
  residualTaskIdList: 'residual-task-id',
  prevetIdList: 'mock-prevet-id'
};
const mockEmployeeMap = { // For each employee group to have a note
  1: { email: mockSignInUsers.it, group: 'IT' },
  2: { email: mockSignInUsers.accounting, group: 'ACCOUNTING' },
  3: { email: mockSignInUsers.hr, group: 'HR' },
  4: { email: mockSignInUsers.compliance, group: 'COMPLIANCE' },
  5: { email: mockSignInUsers.sales, group: 'SALES' },
  6: { email: mockSignInUsers.riskAdmin, group: 'RISK_ADMIN' },
  7: { email: mockSignInUsers.risk, group: 'RISK' },
  8: { email: mockSignInUsers.operations, group: 'OPERATIONS' },
  9: { email: mockSignInUsers.credit, group: 'CREDIT' },
  10: { email: mockSignInUsers.operationsLegacy, group: 'OPERATIONS_LEGACY' }
};
const {
  all,
  merchantGuid,
  partnerId,
  relationshipId,
  ...ticketIds
} = ticketGuids;
const staticGuids = { // Always return notes for these specific guids (FTs)
  merchantGuidList: Object.values(merchantGuids),
  relationshipIdList: Object.values(relationshipGuids),
  partnerIdList: Object.values(partnerGuids),
  ticketIdList: Object.values(ticketIds),
  residualTaskIdList: Object.values(residualTaskGuids),
  prevetIdList: Object.keys(staticPrevetApps).map(key => `mock-prevet-id-${key}`)
};

export const createMockNoteWithGroup = (noteIndex, options) => {
  const {
    guidType,
    employeeEmail,
    employeeIndex,
    staticGuid,
    staticGuidKey
  } = options || {};
  const mockEmail = useStatic ? employeeEmail : getMockValue({ type: 'list', list: Object.values(mockEmployeeMap).map(emp => emp.email) });
  const { identityToken = {} } = mockUserSignInPost[mockEmail] || {};
  const mockEmployeeMatch = Object.values(mockEmployeeMap)
    .find(emp => emp.email === mockEmail);
  const mockGuidType = staticGuidKey || guidType;
  const mockGuidValue = staticGuid || '';
  const mockNote = {
    noteDescription: `This is a mock note (${mockGuidType}) added by ${mockEmployeeMatch.group}${employeeIndex === 0 ? ' (example of group only note)' : ''}`,
    allowedEmployeeGroup: employeeIndex === 0 ? mockEmployeeMatch.group : null,
    guidType: (`${mockGuidType}`).split('List').shift(),
    guidValue: mockGuidValue || `mock-${mockGuidValueMap[mockGuidType]}-${noteIndex + 1}`,
    ...(mockGuidType.includes('applicationId') && { // mock data for applications are just the app numbers
      guidValue: `${noteIndex + 1}`
    }),
    noteId: `mock-note-id-${mockGuidValueMap[mockGuidType]}-${noteIndex + 1}`,
    createdTimestamp: useStatic ? `2020-12-30T20:${pad(employeeIndex + 1)}:00Z` : getMockValue({ type: 'isoDate' }),
    employee: {
      email: employeeEmail,
      firstName: identityToken.firstName,
      lastName: identityToken.lastName,
      manager: getMockValue({ type: 'guid' }),
      employeeId: getMockValue({ type: 'guid' })
    }
  };
  return mockNote;
};

const staticGuidNotes = Object.entries(staticGuids).reduce((allNotesAcc, [key, guidArray]) => {
  const guidNotes = guidArray.reduce((guidNotesAcc, guid) => {
    const mockEmployee = mockEmployeeMap[8];
    const mockGuidNote = createMockNoteWithGroup(0, {
      staticGuid: guid,
      staticGuidKey: key,
      employeeEmail: mockEmployee?.email,
      employeeIndex: 0
    });
    const mockRandomNotes = Array.from({ length: 4 })
      .map((item, itemIndex) => {
        const randomEmployee = mockEmployeeMap[getMockValue({ min: 1, max: 10 })];
        return createMockNoteWithGroup(itemIndex, {
          staticGuid: guid,
          staticGuidKey: key,
          employeeEmail: randomEmployee?.email,
          employeeIndex: itemIndex
        });
      });
    return guidNotesAcc.concat(mockGuidNote, ...mockRandomNotes);
  }, []);
  return allNotesAcc.concat(guidNotes);
}, []);

export const createMockNotesWithGroups = (options) => {
  const { length } = options || {};
  // Create static notes for each guid type
  const staticNotes = Object.keys(mockGuidValueMap).reduce((acc, guidType) => {
    const arrayToMap = useStatic
      ? Object.values(mockEmployeeMap)
      : Array.from({ length: length || 20 });
    const allMockStaticNotes = arrayToMap.map((employee, employeeIndex) => {
      const mockStaticNote = createMockNoteWithGroup(employeeIndex, {
        guidType,
        employeeEmail: employee?.email,
        employeeIndex
      });
      return mockStaticNote;
    });
    return acc.concat(...allMockStaticNotes);
  }, []);
  return staticNotes.concat(staticGuidNotes);
};

export const mockAllNotes = createMockNotesWithGroups();

export const mockNotesWithGroupsGet = (body, params, options) => {
  const { guid, guidKey, isFuncTest } = options || {};
  const guidArrays = !isEmpty(guid) && typeof guid === 'string' ? JSON.parse(guid) : [];
  const arrayToMap = isFuncTest
    ? guid // for FTs, the guid is already in an array
    : guidArrays;
  const guidValueList = (typeof arrayToMap === 'string' ? guidArrays : arrayToMap)
    .reduce((acc, list) => (Array.isArray(list) ? acc.concat(...list) : acc.concat(list)), []);
  const mockAllData = !isEmpty(guidKey)
    ? mockAllNotes.filter(n => guidValueList.includes(n.guidValue))
    : mockAllNotes;
  const {
    totalNumberOfRecords = mockAllData.length,
    pageIndex = mockPagingInfo.pageIndex,
    pageSize = mockPagingInfo.pageSize
  } = params || {};
  const pageData = getMockPageData({
    allData: mockAllData,
    pageIndex,
    pageSize,
    optionsCopy: { ...params }
  });
  return {
    [guid]: {
      noteList: pageData,
      pagingInfo: {
        totalNumberOfRecords,
        pageIndex,
        pageSize
      }
    }
  };
};

export default mockNotesWithGroupsGet;
