import { isEmpty } from '../../../_helpers';

const crabV1ApplicationSubmitValidationTemplate = {
  frontend: (schema, version) => {
    if (version === '1.0') {
      const { mpas, mpaDetailErrorIds, appStatus } = schema || {};
      const formattedMpas = mpas.map((mpa) => {
        const {
          validMpaFields,
          allRequiredFiles,
          allPendsAddressed,
          mpaId
        } = mpa || {};

        /**
         * Temp FE-only check for new fields FE shows as required, but BE shows as optional
         * to allow time for all new apps to have the required field completed
         * without requiring for already submitted apps. Then BE will make the new fields required.
         */
        const missingMpaFields = (mpaDetailErrorIds || []).includes(mpaId);

        const mpaFormValid = (validMpaFields && allRequiredFiles && !missingMpaFields) ||
          false;
        const mpaValidAndPendsComplete = (mpaFormValid && allPendsAddressed) || false;
        const showHasPendText = appStatus !== 'draft' ? !allPendsAddressed : false;
        const hasValidationText = mpaValidAndPendsComplete || showHasPendText;
        return {
          mpaId,
          missingMpaFields,
          validMpaFields,
          allRequiredFiles,
          allPendsAddressed,
          mpaFormValid,
          mpaValidAndPendsComplete,
          hasValidationText,
          showCompletedText: mpaValidAndPendsComplete,
          showHasPendText,
          showValidateButton: ['waiting_on_partner', 'draft'].includes(appStatus) ? !mpaFormValid : false
        };
      });
      return {
        mpas: formattedMpas,
        allMpaFieldsValid: !isEmpty(formattedMpas)
          ? formattedMpas.every(aMpa => aMpa.validMpaFields === true)
          : false,
        allMpaFormsValid: !isEmpty(formattedMpas)
          ? formattedMpas.every(aMpa => aMpa.mpaFormValid === true)
          : false,
        allPendsAddressed: !isEmpty(formattedMpas) // all pends in addressed or needs_help status
          ? formattedMpas.every(aMpa => aMpa.allPendsAddressed === true)
          : false,
        allMpasValidAndPendsComplete: !isEmpty(formattedMpas)
          ? formattedMpas.every(aMpa => aMpa.mpaValidAndPendsComplete === true)
          : false
      };
    }
    return schema;
  }
};

export default crabV1ApplicationSubmitValidationTemplate;
