import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { createMockGiactRes } from './mockV1GiactPost';

const getMockGiactRequest = () => {
  const mockAddressBusiness = getMockValue({ type: 'address' });
  const mockAddressPersonal = getMockValue({ type: 'address' });
  const mockPerson = getMockValue({ type: 'user' });
  const mockRequest = {
    giactBusinessCustomerRequest: {
      businessName: `Mock DBA Business Name 1: ${getMockValue({ type: 'description' })}`,
      ein: getMockValue({ type: 'ssn' }),
      businessAddress: mockAddressBusiness.fullAddress,
      businessCity: mockAddressBusiness.cityName,
      businessState: mockAddressBusiness.stateCode,
      businessZip: mockAddressBusiness.zipCode,
      routingNumber: '123456789',
      bankAccountNumber: '11122233344455'
    },
    giactPersonalCustomerRequest: {
      firstName: mockPerson.firstName,
      lastName: mockPerson.lastName,
      streetAddress: mockAddressPersonal.fullAddress,
      city: mockAddressPersonal.cityName,
      state: mockAddressPersonal.stateCode,
      zipCode: mockAddressPersonal.zipCode
    }
  };
  return mockRequest;
};

export const createMockGiactGetData = (guid) => {
  const mockAddress = getMockValue({ type: 'address' });
  const mockBody = { businessState: mockAddress.stateCode };
  const { giactResponse: mockGiactResponse } = createMockGiactRes(mockBody);
  const mockGiactData = {
    applicationMpaId: guid,
    giactRequest: getMockGiactRequest(),
    giactResponse: mockGiactResponse
  };
  return mockGiactData;
};

export const mockV1GiactGet = (body, params, options) => {
  const { guid } = options || {};
  return { [guid]: createMockGiactGetData(guid) };
};

export const mockV2GiactPost = (body, params, options) => {
  const { guid } = options || {};
  const { cachePolicyEnum = 'prefer_cache' } = body || {};
  const enumToLengthMap = { cache_only: 2, no_cache: 5, prefer_cache: 1 };
  const mockGroupId1 = getMockValue({ type: 'isoDate' });
  const mockGroupId2 = getMockValue({ type: 'isoDate' });
  const arrayLength = enumToLengthMap[cachePolicyEnum] || enumToLengthMap.cache_only;
  const mockGiactList = Array.from({ length: arrayLength }).map((element, index) => {
    const mockAddress = getMockValue({ type: 'address' });
    const mockBody = { businessState: mockAddress.stateCode };
    const { giactResponse: mockGiactResponse } = createMockGiactRes(mockBody);
    const mockGroupId = index % 2 === 0 ? mockGroupId1 : mockGroupId2;
    return {
      dataPullGroupId: mockGroupId,
      giactRequest: getMockGiactRequest(),
      giactResponse: mockGiactResponse
    };
  });
  return {
    [guid]: {
      applicationMpaId: guid,
      resultList: mockGiactList
    }
  };
};

export const mockGiactMerchantV2 = (body, params, options) => {
  const { guid } = options || {};
  const { [guid]: mockData } = mockV2GiactPost(body, params, options);
  return { [guid]: { resultList: mockData.resultList } };
};
