export const mockApplicationApproveV3 = (body, params, options) => {
  const { guid } = options || {};
  const { approvalDetailsList } = body || {};
  return {
    [guid]: {
      midList: (approvalDetailsList || Array.from({ length: 3 })).map((item, index) => ({
        applicationMpaId: item ? item.applicationMpaId : `mock-application-mpa-id-${index + 1}`,
        discoverMidUsed: `mock-discover-mid-${index + 1}`,
        visaOrMastercardMidUsed: `mock-visa-mc-mid-${index + 1}`
      }))
    }
  };
};

export default mockApplicationApproveV3;
