export const mockTransactionReportSummaryGet = (body, params, options) => {
  const { guid = '' } = options || {};
  return {
    [guid]: {
      authorizationCount: 30,
      authorizationVolume: 51870.00,
      settlementCount: 30,
      settlementVolume: 51870.00,
      chargebackCount: 30,
      chargebackVolume: 51870.00,
      chargebackRatio: 100.00
    }
  };
};

export default mockTransactionReportSummaryGet;
