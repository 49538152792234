import { base64DecodeData, base64EncodeData, decompressData } from './_dataCompression';
import { endpoint, isEmpty, getType } from './_helpers';

// Key/value pairs - the value is the top-level data key for api call
// If using within crm/web components,
// use `mecoPageKeys` or `portalPageKeys` get a global key name mapping.
export const globalSettingsKeys = { // Settings across app (NOT page-specific)
  globalPreferences: 'globalPreferences'
};

export const pageSettingsKeys = { // Standard data keys for every page's sub-object
  // Filters
  dataFiltering: {
    name: 'dataFiltering', // Use this as the target `settingKey` value when saving settings
    defaultDataType: 'object',
    dataTypeValue: { // Key/value pairs only - DO NOT ADD ANY ADDITIONAL LEVEL OF NESTED DATA HERE
    }
  },
  // Databoxes
  databoxesMinimized: { // When databox is collapsible
    name: 'databoxesMinimized', // Use this as the target `settingKey` value when saving settings
    defaultDataType: 'array',
    dataTypeValue: [
      // Keys of databoxes that are minimized on load.
      // DO NOT ADD ANY ADDITIONAL LEVEL OF NESTED DATA HERE
    ]
  },
  databoxesOrder: {
    name: 'databoxesOrder', // Use this as the target `settingKey` value when saving settings
    defaultDataType: 'array',
    dataTypeValue: [
      // Keys of databoxes in the order they should appear on load.
      // DO NOT ADD ANY ADDITIONAL LEVEL OF NESTED DATA HERE
    ]
  },
  // Graphs
  graphsMinimized: { // When there is a toggle to "Hide Graph"
    name: 'graphsMinimized', // Use this as the target `settingKey` value when saving settings
    defaultDataType: 'array',
    dataTypeValue: [
      // Keys of graphs that are minimized on load.
      // DO NOT ADD ANY ADDITIONAL LEVEL OF NESTED DATA HERE
    ]
  },
  // Catch-all for custom page data (eg, portal custom reports page)
  customData: {
    name: 'customData', // Use this as the target `settingKey` value when saving settings
    defaultDataType: 'any',
    // Any type of data (Eg, for portal custom reports page)
    // DO NOT ADD ANY ADDITIONAL LEVEL OF NESTED DATA HERE
    dataTypeValue: null
  }
};

export const getCustomSettingsShared = async (options, utils) => {
  /**
   * Do not use this method directly (unless you are calling within /shared directory),
   * instead, use the portal or meco-equivalent util - ie, `getCustomSettings`.
   * NOTE: Error handling must be handled in parent component.
   */
  const {
    pageKey, // Optional, if you only need data for a specific page
    fullPageLoad
  } = options || {};
  const { // Required
    axiosRequest,
    toggleLoader
  } = utils || {};
  fullPageLoad && toggleLoader();
  const apiRes = await axiosRequest({
    method: 'get',
    url: `${endpoint.custom.settings}`,
    fullPageLoad: false // DO NOT OVERRIDE - `toggleLoader` handles full-page loading
  });
  fullPageLoad && toggleLoader(false);
  if (apiRes?.errorDetails instanceof Error) { return apiRes; }
  const { userSettingsPayload } = (apiRes && apiRes.data) || {};
  const decodedRes = !isEmpty(userSettingsPayload)
    ? await base64DecodeData(userSettingsPayload)
    : {};
  const { data: decodedData, errorDetails: decodeResErrorDetails } = decodedRes || {};
  const decompressedRes = !isEmpty(userSettingsPayload) && decodedData.length > 0
    // decodedData bytes array should have a `length` > 0
    ? await decompressData(decodedData, { parse: true, axiosRequest })
    : {};
  const {
    data: allCustomSettings = {},
    errorDetails: decompressResErrorDetails
  } = decompressedRes || {};
  const currentData = (!isEmpty(pageKey)
    // Get custom settings only for a specific page
    ? !isEmpty(allCustomSettings) && allCustomSettings[pageKey]
    // Else, returns ALL custom settings data
    : allCustomSettings
  ) || {};
  return {
    ...apiRes,
    data: currentData,
    ...(decompressResErrorDetails && {
      errorDetails: decompressResErrorDetails,
      data: null
    }),
    ...(decodeResErrorDetails && {
      errorDetails: decodeResErrorDetails,
      data: null
    })
  };
};

export const getSettingsRequestBody = (currentSettingsApiRes, options, utils, updateData) => {
  const {
    pageKey, // Required, page of settings that need to be updated
    settingKey, // Required for page-specific updates, use existing `pageSettingsKeys` -> `name`
    structure // Required, should be the portal or meco settings structure
  } = options || {};
  const allPagesSettings = {
    // Start with initial portal or meco settings structure
    ...structure,
    // Then override with any existing settings user already set
    ...((currentSettingsApiRes && currentSettingsApiRes.data) || {})
  };
  const dataType = getType(updateData);
  const jsonRequestBody = {
    // ALL other pages' existing settings
    ...allPagesSettings,
    [pageKey]: { // Current page
      ...allPagesSettings[pageKey], // Current page's existing settings
      ...(pageKey !== 'globalPreferences'
        ? {
          // Then override with the update data
          ...(dataType === 'array' && {
            // Data saved as an array should ALWAYS pass the ENTIRE array to save.
            [settingKey]: updateData
          }),
          ...(dataType === 'object' && {
            // Data saved as an object CAN pass the entire object to save,
            // OR just pass as an object with key/value pairs.
            [settingKey]: {
              ...(allPagesSettings[pageKey] && { ...allPagesSettings[pageKey][settingKey] }),
              ...(!isEmpty(updateData) && { ...updateData })
            }
          })
        }
        : { ...updateData }
      )
    }
  };
  return jsonRequestBody;
};

export const saveCustomSettingsShared = async (options, utils, updateData) => {
  /**
   * Do not use this method directly (unless you are calling within /shared directory),
   * instead, use the portal or meco-equivalent util - ie, `saveCustomSettings`
   * NOTE: Error handling must be handled in parent component.
   */
  const {
    pageKey, // Required, page of settings that need to be updated
    settingKey, // Required for page-specific updates, use existing `pageSettingsKeys` -> `name`
    fullPageLoad,
    structure // Required, should be the portal or meco settings structure
  } = options || {};
  const { // Required
    axiosRequest,
    toggleLoader
  } = utils || {};
  if (isEmpty(pageKey) || (pageKey !== 'globalPreferences' && isEmpty(settingKey)) || isEmpty(structure)) {
    const err = new Error();
    err.message = 'ERROR: Missing required data to save custom settings';
    return { errorDetails: err };
  }
  fullPageLoad && toggleLoader();
  const currentSettingsApiRes = await getCustomSettingsShared(
    {
      // DO NOT pass `pageKey` here, we want ALL pages' settings for this request
      fullPageLoad
    },
    utils
  );
  if (currentSettingsApiRes?.errorDetails instanceof Error) {
    fullPageLoad && toggleLoader(false);
    // If error occurred getting current settings, don't proceed with saving
    // or this will potentially override ALL settings.
    return currentSettingsApiRes;
  }
  const requestBodyJson = await getSettingsRequestBody(
    currentSettingsApiRes, options, utils, updateData
  );
  const encodedRes = !isEmpty(requestBodyJson) ? base64EncodeData(requestBodyJson) : {};
  const { data: stringifiedData, errorDetails: encodeResErrorDetails } = encodedRes || {};
  const isEncodeError = encodeResErrorDetails instanceof Error;
  if (isEmpty(stringifiedData) || isEncodeError) {
    fullPageLoad && toggleLoader(false);
    const errorDetails = isEncodeError ? encodeResErrorDetails : new Error();
    if (isEmpty(stringifiedData) && !isEncodeError) {
      // If the encoded data is empty but did not error,
      // something else went wrong, do not proceed with save request.
      // the request body should NEVER be empty if it did not error.
      errorDetails.message = `An error has occurred saving user settings`;
    }
    return { ...encodedRes, errorDetails };
  }
  const requestBody = { userSettingsPayload: stringifiedData };
  const saveSettingsApiRes = await axiosRequest({
    method: 'post',
    url: `${endpoint.custom.settings}`,
    fullPageLoad: false // DO NOT OVERRIDE - `toggleLoader` handles full-page loading
  }, requestBody);
  fullPageLoad && toggleLoader(false);
  return saveSettingsApiRes;
};
