import React from 'react';
import PropTypes from 'prop-types';
import { formatNumber } from './_helpers';

export class TransactionSummaryTable extends React.PureComponent {
  render () {
    const { data, hasRefunds } = this.props;
    const infoTableCSS = {
      table: {
        borderCollapse: 'collapse',
        width: '100%'
      },
      tdTitle: {
        textTransform: 'uppercase',
        fontSize: '1rem',
        color: 'var(--color-light-label)',
        lineHeight: '2.25rem'
      },
      tdValue: {
        wordBreak: 'break-word'
      }
    };
    return (
      <table style={infoTableCSS.table}>
        <thead>
          <tr>
            <td style={infoTableCSS.tdTitle}>&nbsp;</td>
            <td style={{ ...infoTableCSS.tdTitle, width: '60%' }}>Total</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={infoTableCSS.tdTitle}>Authorization Volume</td>
            <td style={{ ...infoTableCSS.tdValue, width: '60%' }}>{formatNumber(data.authorizationVolume, { currency: true })}</td>
          </tr>
          <tr>
            <td style={infoTableCSS.tdTitle}>Authorization Count</td>
            <td style={{ ...infoTableCSS.tdValue, width: '60%' }}>{formatNumber(data.authorizationCount)}</td>
          </tr>
          <tr>
            <td style={infoTableCSS.tdTitle}>Settlement Volume</td>
            <td style={{ ...infoTableCSS.tdValue, width: '60%' }}>{formatNumber(data.settlementVolume, { currency: true })}</td>
          </tr>
          <tr>
            <td style={infoTableCSS.tdTitle}>Settlement Count</td>
            <td style={{ ...infoTableCSS.tdValue, width: '60%' }}>{formatNumber(data.settlementCount)}</td>
          </tr>
          <tr>
            <td style={infoTableCSS.tdTitle}>Chargeback Volume</td>
            <td style={{ ...infoTableCSS.tdValue, width: '60%' }}>{formatNumber(data.chargebackVolume, { currency: true })}</td>
          </tr>
          <tr>
            <td style={infoTableCSS.tdTitle}>Chargeback Count</td>
            <td style={{ ...infoTableCSS.tdValue, width: '60%' }}>{formatNumber(data.chargebackCount)}</td>
          </tr>
          <tr>
            <td style={infoTableCSS.tdTitle}>Chargeback Ratio</td>
            <td style={{ ...infoTableCSS.tdValue, width: '60%' }}>{formatNumber(data.chargebackRatio)}</td>
          </tr>
          {hasRefunds && (
            <React.Fragment>
              <tr>
                <td style={infoTableCSS.tdTitle}>Refund Volume</td>
                <td style={{ ...infoTableCSS.tdValue, width: '60%' }}>{formatNumber(data.refundVolume, { currency: true })}</td>
              </tr>
              <tr>
                <td style={infoTableCSS.tdTitle}>Refund Count</td>
                <td style={{ ...infoTableCSS.tdValue, width: '60%' }}>{formatNumber(data.refundCount)}</td>
              </tr>
            </React.Fragment>
          )}
        </tbody>
      </table>
    );
  }
}

TransactionSummaryTable.propTypes = {
  hasRefunds: PropTypes.bool,
  data: PropTypes.shape({
    authorizationCount: PropTypes.number,
    authorizationVolume: PropTypes.number,
    settlementCount: PropTypes.number,
    settlementVolume: PropTypes.number,
    chargebackCount: PropTypes.number,
    chargebackVolume: PropTypes.number,
    chargebackRatio: PropTypes.number,
    refundCount: PropTypes.number,
    refundVolume: PropTypes.number
  })
};

TransactionSummaryTable.defaultProps = {
  hasRefunds: false,
  data: {
    authorizationCount: 0,
    authorizationVolume: 0,
    settlementCount: 0,
    settlementVolume: 0,
    chargebackCount: 0,
    chargebackVolume: 0,
    chargebackRatio: 0,
    refundCount: 0,
    refundVolume: 0
  }
};

export default TransactionSummaryTable;
