import { domainName, getEnvPrefix } from '@f1/shared/src/_helpers';

export const getBaseApiUrls = () => {
  if (typeof window !== 'undefined') {
    const env = getEnvPrefix();
    return {
      root: `https://api${env}${domainName}.com`,
      crm: `https://api${env}${domainName}.com/crm`,
      training: `https://api${env}${domainName}.com/training`,
      thirdPartyServices: `https://api${env}${domainName}.com/thirdPartyServices`,
      residuals: `https://api${env}${domainName}.com/residuals`,
      infrastructure: `https://api${env}${domainName}.com`
    };
  }
  return {
    // return dev, window doesn't exist for testcafe only
    root: `https://api.dev.${domainName}.com`,
    crm: `https://api.dev.${domainName}.com/crm`,
    training: `https://api.dev.${domainName}.com/training`,
    thirdPartyServices: `https://api.dev.${domainName}.com/thirdPartyServices`,
    residuals: `https://api.dev.${domainName}.com/residuals`,
    infrastructure: `https://api.dev.${domainName}.com`
  };
};

export const crmUrl = getBaseApiUrls().crm;

export const crmEndpoint = { // api endpoints for the CRM
  user: {
    root: `${crmUrl}/user`,
    signIn: `${crmUrl}/user/signIn`,
    signUp: `${crmUrl}/user/signUp`,
    verifyPhone: `${crmUrl}/user/verifyPhone`,
    verifyEmail: `${crmUrl}/user/verifyEmail`,
    sendPhoneVerification: `${crmUrl}/user/sendPhoneVerification`,
    sendEmailVerification: `${crmUrl}/user/sendEmailVerification`,
    forgotPasswordRequest: `${crmUrl}/user/requestPasswordResetCode`,
    forgotPasswordVerify: `${crmUrl}/user/resetPassword`
  },
  ticket: {
    root: `${crmUrl}/ticket`,
    teamSummary: `${crmUrl}/ticket/teamSummary`,
    overview: `${crmUrl}/ticket/overview`,
    sla: `${crmUrl}/ticket/sla`,
    history: `${crmUrl}/ticket/history`,
    approvalRequest: `${crmUrl}/ticket/approvalRequest`,
    bulk: `${crmUrl}/ticket/bulk`,
    externalCommunication: `${crmUrl}/ticket/externalCommunication`
  },
  relationship: {
    root: `${crmUrl}/relationship`,
    v2: `${crmUrl}/relationship/v2`,
    v2Detail: `${crmUrl}/relationship/v2/detail`,
    v1: `${crmUrl}/relationship/v1`,
    v2Health: `${crmUrl}/relationship/v2/health`,
    forest: `${crmUrl}/relationship/forest`,
    health: `${crmUrl}/relationship/health`,
    boarding: `${crmUrl}/relationship/boarding`,
    listMerchant: `${crmUrl}/relationship/listMerchant`,
    v2Fee: `${crmUrl}/relationship/v2/fee`
  },
  partner: {
    root: `${crmUrl}/partner`,
    v2: `${crmUrl}/partner/v2`,
    v2Detail: `${crmUrl}/partner/v2/detail`,
    v2Health: `${crmUrl}/partner/v2/health`,
    v1: `${crmUrl}/partner/v1`,
    v1Detail: `${crmUrl}/partner/v1/detail`,
    health: `${crmUrl}/partner/health`,
    boarding: `${crmUrl}/partner/boarding`,
    relationshipMapping: `${crmUrl}/partner/relationshipMapping`,
    notifications: `${crmUrl}/partner/report/notifications`,
    externalIntegration: `${crmUrl}/partner/externalIntegration`
  },
  merchant: {
    root: `${crmUrl}/merchant`,
    var: `${crmUrl}/merchant/v2/var`, // For POST/PUT only
    v2: `${crmUrl}/merchant/v2`,
    v2Detail: `${crmUrl}/merchant/v2/detail`,
    v2History: `${crmUrl}/merchant/v2/history`,
    v2Fee: `${crmUrl}/merchant/v2/fee`,
    lookupBySecondaryField: `${crmUrl}/merchant/lookupBySecondaryField`,
    v2Closure: `${crmUrl}/merchant/v2/closure`,
    v2bulkEmail: `${crmUrl}/merchant/v2/bulk/email`,
    mpa: `${crmUrl}/merchant/mpa`,
    v2AchDetails: `${crmUrl}/merchant/v2/achDetails`,
    achFunding: `${crmUrl}/merchant/v2/achFunding`,
    achFundingSummery: `${crmUrl}/merchant/v2/achFunding/summary`,
    v2RiskAssessment: `${crmUrl}/merchant/v2/riskAssessment`,
    riskExposure: `${crmUrl}/merchant/v2/riskExposure`,
    ledger: `${crmUrl}/merchant/v2/ledger`,
    profitability: `${crmUrl}/merchant/profitability`,
    addTsysTerminal: `${crmUrl}/merchant/v2/addTsysTerminal`,
    boardToGateway: `${crmUrl}/merchant/v2/boardToGateway`,
    boardToProcessor: `${crmUrl}/merchant/v2/boardToProcessor`
  },
  transactionReport: {
    sales: `${crmUrl}/transactionReport/sales`,
    chargeback: `${crmUrl}/transactionReport/chargeback`,
    dispute: `${crmUrl}/transactionReport/dispute`,
    authorization: `${crmUrl}/transactionReport/authorization`,
    refund: `${crmUrl}/transactionReport/refund`,
    mini: `${crmUrl}/transactionReport/mini`,
    summary: `${crmUrl}/transactionReport/summary`,
    reserve: `${crmUrl}/transactionReport/reserve`,
    reserveDetail: `${crmUrl}/transactionReport/reserve/detail`,
    reserveCurrent: `${crmUrl}/transactionReport/reserve/current`,
    riskPartnerSpecialty: `${crmUrl}/transactionReport/risk/partnerSpecialty`,
    mvbBinPerformance: `${crmUrl}/transactionReport/risk/mvbBinPerformance`,
    transactionDetails: `${crmUrl}/transactionReport/transactionDetails`,
    batchDetails: `${crmUrl}/transactionReport/batchDetails`
  },
  employee: {
    root: `${crmUrl}/employee`,
    detail: `${crmUrl}/employee/detail`,
    employeeGroup: `${crmUrl}/employee/employeeGroup`,
    employeeGroupMember: `${crmUrl}/employee/employeeGroup/member`
  },
  support: {
    userDetails: `${crmUrl}/support/userDetails`,
    resetUser: `${crmUrl}/support/resetUser`
  },
  file: {
    root: `${crmUrl}/file`,
    v2Path: `${crmUrl}/file/v2/path`,
    v2DummyUploadUrl: `${crmUrl}/file/v2/dummyUploadUrl`,
    v2RepayList: `${crmUrl}/file/v2/repay/list`
  },
  note: `${crmUrl}/note`,
  risk: {
    processingHistory: `${crmUrl}/risk/landingPage/history/processingHistory`,
    chargebackHistory: `${crmUrl}/risk/landingPage/history/chargebackHistory`,
    reserveHistory: `${crmUrl}/risk/landingPage/history/reserveHistory`,
    achRejectHistory: `${crmUrl}/risk/landingPage/history/achRejectHistory`,
    allTimeHistoryV2: `${crmUrl}/risk/landingPage/history/allTimeHistory/v2`,
    exposure: `${crmUrl}/risk/landingPage/exposure`,
    alerts: `${crmUrl}/risk/landingPage/alerts`,
    riskCategory: `${crmUrl}/risk/landingPage/alerts/riskCategory`,
    riskCategoryMetric: `${crmUrl}/risk/landingPage/alerts/riskCategory/metric`,
    riskSubcategory: `${crmUrl}/risk/landingPage/alerts/riskSubcategory`,
    riskSubcategoryMetric: `${crmUrl}/risk/landingPage/alerts/riskSubcategory/metric`,
    rule: `${crmUrl}/risk/rule`,
    triggerAndClosure: `${crmUrl}/risk/rule/metric/triggerAndClosure`,
    closingEntity: `${crmUrl}/risk/rule/metric/closingEntity`,
    processFile: `${crmUrl}/risk/vantiv/processFile`,
    binStatus: `${crmUrl}/risk/binStatus`,
    merchantMonitoring: `${crmUrl}/risk/merchantMonitoring`,
    onHold: `${crmUrl}/risk/onHold`,
    prohibitedEntities: `${crmUrl}/risk/prohibitedEntities`,
    repayTransactionHold: `${crmUrl}/risk/repay/transactionHold`,
    repayTransactionHoldConfig: `${crmUrl}/risk/repay/transactionHold/config`,
    repayTransactionHoldQa: `${crmUrl}/risk/repay/transactionHold/qa`
  },
  underwriting: {
    availableMids: `${crmUrl}/underwriting/availableMids`
  },
  repay: {
    toRepaySftp: `${crmUrl}/toRepaySftp`
  }
};

export const thirdPartyServicesUrl = getBaseApiUrls().thirdPartyServices;

export const thirdPartyServicesEndpoint = {
  experianApplicationV3: `${thirdPartyServicesUrl}/experian/application/v3`,
  experianMerchantV3: `${thirdPartyServicesUrl}/experian/merchant/v3`,
  giactUpdate: `${thirdPartyServicesUrl}/giact/application`,
  giactUpdateV2: `${thirdPartyServicesUrl}/giact/application/v2`,
  giactMerchantV2: `${thirdPartyServicesUrl}/giact/merchant/v2`,
  gdsApplicationV3: `${thirdPartyServicesUrl}/gds/application/v3`,
  match: `${thirdPartyServicesUrl}/match/terminationInquiry`,
  matchUpdate: `${thirdPartyServicesUrl}/match/terminationInquiry/application`,
  matchUpdateV2: `${thirdPartyServicesUrl}/match/terminationInquiry/application/v2`,
  matchMerchantV2: `${thirdPartyServicesUrl}/match/terminationInquiry/merchant/v2`,
  rubmapsApplicationV3: `${thirdPartyServicesUrl}/rubmaps/application/v3`,
  sticky: `${thirdPartyServicesUrl}/sticky/contractValidation`
};

export const residualsUrl = getBaseApiUrls().residuals;

export const residualsEndpoint = {
  fileInput: `${residualsUrl}/file/input`,
  fileOutput: `${residualsUrl}/file/output`,
  fileProcess: `${residualsUrl}/file/process`,
  midMapping: `${residualsUrl}/mid/mapping`,
  reconciliationReportMid: `${residualsUrl}/reconciliation/report/mid`,
  reconciliationReportCategory: `${residualsUrl}/reconciliation/report/category`,
  partnerPayout: `${residualsUrl}/partnerResidualReport/partnerPayout`,
  partnerPayoutAlerting: `${residualsUrl}/partnerResidualReport/partnerPayout/alerting`,
  partnerPayoutAlertingSnooze: `${residualsUrl}/partnerResidualReport/partnerPayout/alerting/snooze`,
  v2Task: `${residualsUrl}/v2/task`,
  v2TaskDetail: `${residualsUrl}/v2/task/detail`
};

export const infrastructureUrl = getBaseApiUrls().infrastructure;

export const infrastructureEndpoint = {
  isWhitelistedIp: `${infrastructureUrl}/isWhitelistedIp`
};
